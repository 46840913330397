/* eslint-disable function-name/starts-with-verb */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { cloneElement, forwardRef, useEffect, useImperativeHandle, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import media from "styled-media-query";

import Colors from "../../themes/colors";
import Actions from "../Actions";
import Steps from "../Steps";

const { Step } = Steps;

const DialogFooter = styled(Actions)`
  justify-content: flex-end;
  padding: 1.5rem 0 !important;
  ${media.lessThan("medium")`
    padding: 1.5rem !important;
    width: 100% !important;
    position: fixed !important;
    bottom: 0 !important;
    right: 0 !important;
    border-top: 1px solid ${Colors.lunar_gray};
  `};

  li:first-child {
    margin-right: ${({ hasBackBtn }) => (hasBackBtn ? "auto" : "inherit")} !important;
  }
`;

const Wizard = forwardRef(
  (
    { initialStep, data, children, showSteps, stepsClassName, isMobile, className, onStepChanged },
    ref,
  ) => {
    const [internalData, setInternalData] = useState(data);

    useImperativeHandle(ref, () => ({
      nextStep,
      previousStep,
      navigate,
    }));

    const nextStep = (newData) => {
      if (elements.length - currentStep > 1) {
        const params = {
          nextStep,
          data: newData,
          currentStep: currentStep + 1,
          previousStep,
          navigate,
          resetData,
        };
        elements = elements.map((obj, i) => {
          params.key = i;
          return cloneElement(obj, params);
        });

        setInternalData(newData);
        setCurrentStep(currentStep + 1);
      }
    };

    const previousStep = (newData) => {
      if (currentStep > 0) {
        const params = {
          nextStep,
          data: newData,
          currentStep: currentStep - 1,
          previousStep,
          navigate,
          resetData,
        };

        elements = elements.map((obj, i) => {
          params.key = i;
          return cloneElement(obj, params);
        });

        setInternalData(newData);
        setCurrentStep(currentStep - 1);
      }
    };

    const resetData = (callback) => {
      setInternalData(undefined);
      if (callback) callback();
    };

    const navigate = (toStep) => {
      if (toStep >= 0 && toStep !== currentStep) {
        setCurrentStep(toStep);
      }
    };

    const init = () => {
      const params = {
        nextStep,
        data: internalData,
        currentStep,
        previousStep,
        navigate,
        resetData,
      };
      const allComponents = children.map((obj, i) => {
        params.key = i;
        params.title = obj.title;
        return cloneElement(obj.content, params);
      });
      return allComponents;
    };

    const [currentStep, setCurrentStep] = useState(initialStep || 0);

    let elements = init();
    useEffect(() => {
      if (onStepChanged) {
        onStepChanged(currentStep);
      }
    }, [currentStep, onStepChanged]);

    return (
      <>
        {showSteps && (
          <Steps className={stepsClassName} current={currentStep} progressDot={isMobile}>
            {elements.map((step) => (
              <Step key={step.props.title} title={step.props.title} />
            ))}
          </Steps>
        )}
        <div className={className}>{elements[currentStep]}</div>
      </>
    );
  },
);

Wizard.propTypes = {
  data: PropTypes.any,
  children: PropTypes.array.isRequired,
  onStepChanged: PropTypes.func,
  stepsClassName: PropTypes.string,
  className: PropTypes.string,
  isMobile: PropTypes.bool,
  initialStep: PropTypes.number,
  showSteps: PropTypes.bool,
};

Wizard.defaultProps = {
  showSteps: false,
};

Wizard.DialogFooter = DialogFooter;

export default Wizard;
