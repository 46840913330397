import gql from "graphql-tag";
import _, { toNumber, trim } from "lodash";
import { z } from "zod";

import type { DropdownOption } from "@core/ui/components/Dropdown";

import type { CardFormat } from "../../common/types";
import { placesCountryMap, validateAddress } from "../../common/useGooglePlaces";
import type { AddressInput, Countries } from "./types";

export const DATE_FORMAT = "MMMM Do, YYYY";

const countries = ["USA", "CAN"] as const;
const shippingPreferences = ["central", "individual_unverified", "individual_verified"] as const;
const renewalStatus = ["prep", "completed", "none"] as const;

export const countryValidation = z.enum(countries, {
  errorMap: () => ({ message: "Please choose CAN or USA" }),
});

export const VERIFY_ADDRESS = gql`
  mutation verifyAddress($input: VerifyAddressInput!) {
    verifyAddress(input: $input)
  }
`;

export const addressSchema = z
  .object({
    line1: z.string().trim().min(1, "Please enter an address line"),
    line2: z.string().trim().optional(),
    city: z.string().trim().min(1, "Please enter a city"),
    state: z.string().trim().min(1, "Please enter a state"),
    zipCode: z.string().trim(),
    country: countryValidation,
  })
  .superRefine(({ country, zipCode }, ctx) => {
    if (country === "USA" && !zipCode)
      ctx.addIssue({ path: ["zipCode"], message: "Please enter a zip code", code: "custom" });
    if (country === "CAN" && !zipCode)
      ctx.addIssue({ path: ["zipCode"], message: "Please enter a postal code", code: "custom" });
    if (!country && !zipCode)
      ctx.addIssue({ path: ["zipCode"], message: "Please enter a zip code", code: "custom" });
  });

export const COUNTRY_OPTIONS: DropdownOption<Countries>[] = [
  { label: "USA", value: "USA" },
  { label: "CAN", value: "CAN" },
];

export const EXPIRATION_BANNER_TEXT: Record<CardFormat, string> = {
  physical:
    "All cards, including new cards issued, are expiring on {{Date}}. Note that a replacement card with a new CVC and expiry date will arrive after.",
  virtual:
    "All cards, including new cards issued, are expiring on {{Date}}. Note that virtual cards will auto-renew two months before and have a new CVC and expiry date.",
};

export const VC_AUTORENEWAL_BANNER_DETAILS = {
  "ui-card-renewal-notification": {
    title: "Card auto-renews soon",
    text: "This card will be given a new CVC and expiry date two months before it expires. No further action is required at this point.",
    iconName: "CreditCard",
  },
  "ui-new-expiration-detected": {
    title: "Card has been renewed",
    text: "Please update any subscriptions, reoccurring transactions or accounts on file with the new CVC and expiry date.",
    iconName: "Sparkles",
  },
} as const;

export const NOTIFICATIONS = {
  addressVerified: {
    type: "success",
    message: "Shipping address verified",
  },
  addressVerificationFailed: {
    type: "error",
    message: "Shipping address verification failed",
    description: "An error occurred please try again.",
  },
} as const;

export const validateInternally = (formData: AddressInput) => {
  const result = addressSchema.safeParse(formData);

  if (result.success) return { hasErrors: false };

  const issues = result.error.issues.reduce<Record<string, string>>((acc, issue) => {
    const property = issue.path[0];
    if (_.isString(property)) acc[property] ??= issue.message;
    return acc;
  }, {});

  return { hasErrors: true, issues };
};

export const countriesMap = {
  Canada: "CAN",
  USA: "USA",
} as const;

export const shippingPreferencesSchema = z.enum(shippingPreferences);

export const cardProgramSchema = z.object({
  programID: z.string(),
  renewalStatus: z.enum(renewalStatus),
  expirationYYMM: z.string(),
  expirationDate: z.string(),
  isActive: z.boolean(),
  orgID: z.string(),
  shippingPreference: z
    .object({
      lastUpdated: z.date(),
      completedBy: z.array(z.string()),
      preference: shippingPreferencesSchema,
    })
    .optional(),
  centralAddress: z.object({
    country: z.enum(countries),
    state: z.string(),
    city: z.string(),
    address1: z.string(),
    address2: z.string(),
    postalCode: z.string(),
  }),
});

export const checkSuggestions = async (formData: AddressInput): Promise<AddressInput | null> => {
  const {
    result: {
      address: { postalAddress },
    },
  } = await validateAddress({
    regionCode: placesCountryMap[formData.country!], // Zod validates there's a country input
    locality: formData.city,
    administrativeArea: formData.state,
    addressLines: [`${formData.line1}`, ...(formData.line2 ? [formData.line2] : [])],
  });

  const line2 = formData.line2 ?? "";
  const isLine2aNumber = Number.isFinite(toNumber(formData.line2));
  const line1 = trim(
    postalAddress.addressLines?.[0]?.split(isLine2aNumber ? `#${line2}` : line2)?.[0],
  );

  const suggestedAddress = {
    line1,
    city: postalAddress.locality ?? "",
    state: postalAddress.administrativeArea ?? "",
    zipCode: postalAddress.postalCode ?? "",
    country: formData.country,
  };

  const isAnyComponentMissing = Object.values(suggestedAddress).some((val) => !val);

  return isAnyComponentMissing
    ? null
    : {
        ...suggestedAddress,
        line2: formData.line2,
      };
};
