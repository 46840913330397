import { DatePicker } from "antd";
import styled from "styled-components";

const { RangePicker } = DatePicker;

const RangePickerStyled = styled(RangePicker)`
  .ant-calendar-picker-input {
    &.ant-input-lg {
      line-height: 24px !important;
    }
  }
`;

DatePicker.RangePicker = RangePickerStyled;

// eslint-disable-next-line no-restricted-exports
export { DatePicker as default } from "antd";
