import gql from "graphql-tag";

export const COST_CENTER_FRAGMENT = gql`
  fragment CostCenterFragment on CostCenter {
    ID
    name
    externalID
    parentID
    spenders
    archived
  }
`;

export const COST_CENTER_LIST_FRAGMENT = gql`
  fragment CostCenterListFragment on CostCenter {
    ID
    name
    parentID
    archived
  }
`;

export const COMPONENT_STATE_FRAGMENT = gql`
  fragment ComponentState on ComponentState {
    ID
    state
  }
`;

export const CARD_INFO_FRAGMENT = gql`
  fragment CardInfoFragment on CardInfo {
    ID
    activeState
    isLocked
    isClosed
    isRequested
    canUnlock
  }
`;

export const CARD_SCHEDULED_EVENTS_FRAGMENT = gql`
  fragment CardScheduledEventFragment on CardScheduledEvent {
    id
    type
    effectiveDate
    endDate
    limit
    originalLimit
  }
`;

export const CARD_FRAGMENT = gql`
  fragment CardFragment on Card {
    ID
    displayLabel
    limit
    last4
    cardReferenceID
    cardProgramInstanceID
    reissued {
      reasonCode
    }
    firstName
    lastName
    balance
    expirationYYMM
    cardFormat
    cardBehavior
    cardholderID
    isProvisioning
    isPendingClose
    cardholder {
      firstName
      lastName
    }
    statusCode
    statusLabel
    isActive
    isAdminLocked
    isPendingClose
    typeLabel
    cardInfo {
      ...CardInfoFragment
    }
    scheduledEvents {
      ...CardScheduledEventFragment
    }
  }
  ${CARD_INFO_FRAGMENT}
  ${CARD_SCHEDULED_EVENTS_FRAGMENT}
`;
export const ADDRESS_FRAGMENT = gql`
  fragment AddressFragment on Address {
    address
    address2
    city
    country
    postalCode
    state
    isVerified
    isChanged
    timestamp
  }
`;

export const PAGINATION_FRAGMENT = gql`
  fragment PaginationFragment on Pagination {
    next
    previousOffset
    offset
    pages
    totalItems
  }
`;

export const EVENTS_FRAGMENT = gql`
  fragment EventFragment on ExpenseEvent {
    timeStamp
    type
    values
  }
`;

export const GENERAL_LEDGER_ACCOUNT_FRAGMENT = gql`
  fragment GeneralLedgerAccountFragment on GeneralLedgerAccount {
    ID
    name
    code
    isDeleted
  }
`;

export const EXPENSE_TYPE_FRAGMENT = gql`
  fragment ExpenseTypeFragment on ExpenseType {
    ID
    name
    isDeleted
  }
`;

export const BOOKING_FRAGMENT = gql`
  fragment bookingFragment on BookingField {
    ID
    type
    isUnmanaged
    platform
    sourceIDs {
      sourceName
      locked
      sourceID {
        ID
        orgID
      }
    }
    tmc {
      hasTMC
      tmcName
    }
    transaction {
      hasLevel2
      isSettled
    }
    airfare {
      flightLegs {
        airlineName
        arrivalTime
        carrierCode
        destinationAirportCode
        destinationAirportName
        departureTime
        flightDuration
        flightNumber
        originAirportCode
        originAirportName
        serviceClassCode
        serviceClassName
        travelDate
        departureDate
        arrivalDate
      }
      issueDate
      issuingCarrierName
      passengerName
      ticketNumber
      travelAgencyName
    }
    vehicleRental {
      checkoutDate
      rentalAgreementNumber
      customerServiceNumber
      rentalLocationCity
      rentalLocationState
      rentalLocationCountry
      rentalLocationID
      rentalClassID
      rentalClassName
      rentalRateIndicator
      rentalRate
      renterName
      programCode
      returnCity
      returnState
      returnCountry
      returnDate
      returnLocationID
      totalMiles
      rentalCompany
    }
    hotel {
      folioName
      arrivalDate
      departureDate
      roomRate
      roomTax
      propertyPhoneNumber
      customerServiceNumber
      renterName
      merchantName
      checkInDate
      checkOutDate
      checkInTime
      checkOutTime
      address {
        address
        address1
        address2
        city
        country
        postalCode
        state
      }
    }
  }
`;

export const EVALUATED_EXPENSE_FORM_FIELD_FRAGMENT = gql`
  fragment EvaluatedExpenseFormFieldFragment on EvaluatedExpenseFormField {
    ID
    referenceID
    definitionParams
    isEnabled
    isRequired
    isVisible
    isUserDefined
    _name: name
    name @client
    optionKey @client
    property
    readProperty @client
    style @client
    valueType
    _uiType: uiType
    uiType @client
    visibilityRuleIDs
    _values: values
    values @client
    offset
    type
    totalValuesSize
    visibilityRuleIDs
    expenseID @client
    calculatedProperties {
      value
      property
      rate
      rateCurrencyCode
    }
    _defaultValue: defaultValue
    defaultValue @client
    data {
      title
      subtitle
    }
    isInteractive
  }
`;

export const EVALUATED_EXPENSE_FORM_FIELD_OR_SECTION_FRAGMENT = gql`
  fragment EvaluatedExpenseFormFieldOrSectionFragment on EvaluatedExpenseFormField {
    ...EvaluatedExpenseFormFieldFragment
    fields {
      ...EvaluatedExpenseFormFieldFragment
      fields {
        ...EvaluatedExpenseFormFieldFragment
        fields {
          ...EvaluatedExpenseFormFieldFragment
        }
      }
    }
  }
  ${EVALUATED_EXPENSE_FORM_FIELD_FRAGMENT}
`;

export const EVALUATED_EXPENSE_FORM_FRAGMENT = gql`
  fragment EvaluatedExpenseFormFragment on EvaluatedExpenseForm {
    areSplitsReadyToSubmit {
      ID
      isExpenseReadyToSubmit
      notReadyToSubmitReasons
    }
    notReadyToSubmitReasons
    fields {
      ...EvaluatedExpenseFormFieldOrSectionFragment
      fields {
        ...EvaluatedExpenseFormFieldOrSectionFragment
        fields {
          ...EvaluatedExpenseFormFieldOrSectionFragment
        }
      }
    }
  }
  ${EVALUATED_EXPENSE_FORM_FIELD_OR_SECTION_FRAGMENT}
`;

export const FLAG_FRAGMENT = gql`
  fragment FlagFragment on Flag {
    ID
    description
    title
    name
    group {
      type
      name
    }
  }
`;

export const BUBBLE_FRAGMENT = gql`
  fragment BubbleFragment on Bubble {
    title
    bgColor
    titleColor
    items {
      text
      title
      flagID
      flagType
    }
    groupType
  }
`;

export const EXPORT_INFO_FRAGMENT = gql`
  fragment ExportInfoFragment on ExportInfo {
    errors
    exportDateTime
    status
  }
`;

export const EXPENSE_SENT_BACK_FRAGMENT = gql`
  fragment ExpenseSentBackFragment on SentBack {
    timeStamp
    type
    message
  }
`;

export const BASE_EXPENSE_SPLITS_FRAGMENT = gql`
  fragment BaseExpenseSplitsFragment on Expense {
    ID
    amount
    attendees {
      fullName
      relationship
    }
    dateTime
    customFields
    currencyCode
    costCenterID
    costCenterName
    costCenter @client {
      ID
      name
    }
    expenseTypeID
    expenseTypeName
    expenseType @client {
      ID
      name
      isDeleted
    }
    exportInfo {
      ...ExportInfoFragment
    }
    isPersonal
    merchant {
      ID
      name
      rawName
    }
    flags {
      ...FlagFragment
    }
    bubbles {
      ...BubbleFragment
    }
    customFields
    fields {
      travel {
        tripID
        travelerID
      }
      forex {
        originatingCurrency {
          amountRaw
          amountTotal
          crossBorderFee
          name
          namePlural
          symbol
        }
        billingCurrency {
          amountRaw
          amountTotal
          crossBorderFee
          name
          namePlural
          symbol
        }
        exchangeRate
      }
      reimbursement {
        isReimbursable
        status
        transferID
        scheduledPaymentDateTime
        paymentDateTime
        errorMessage
      }
    }
    notes
    purpose
    postedDateTime
  }
  ${FLAG_FRAGMENT}
  ${BUBBLE_FRAGMENT}
  ${EXPORT_INFO_FRAGMENT}
`;

export const BASE_EXPENSE_SPLITS_FRAGMENT_V2 = gql`
  fragment BaseExpenseSplitsFragmentV2 on Expense {
    ID
    status
    amount
    attendees {
      fullName
      relationship
    }
    dateTime
    customFields: localCustomFields @client
    _customFields: customFields
    currencyCode
    costCenterID
    costCenterName
    costCenter @client {
      ID
      name
    }
    expenseTypeID
    expenseTypeName
    expenseType @client {
      ID
      name
      isDeleted
    }
    exportInfo {
      ...ExportInfoFragment
    }
    isPersonal
    _merchant: merchant {
      ID
      name
      rawName
      categoryCode
    }
    merchant: localMerchant @client
    flags {
      ...FlagFragment
    }
    bubbles {
      ...BubbleFragment
    }
    fields {
      travel {
        tripID
        travelerID
      }
      forex {
        originatingCurrency {
          amountRaw
          amountTotal
          crossBorderFee
          name
          namePlural
          symbol
        }
        billingCurrency {
          amountRaw
          amountTotal
          crossBorderFee
          name
          namePlural
          symbol
        }
        exchangeRate
      }
    }
    notes
    purpose
    postedDateTime
  }
  ${FLAG_FRAGMENT}
  ${BUBBLE_FRAGMENT}
  ${EXPORT_INFO_FRAGMENT}
`;

export const AUTO_DEPOSIT_FRAGMENT = gql`
  fragment AutoDepositFragment on AutoDepositToMFA {
    ID
    thresholdAmount
    fromBankAccountID
  }
`;

// TODO: revisit, it seems we can use BOOKING_FRAGMENT
export const TRAVEL_FIELD_FRAGMENT = gql`
  fragment TravelFieldFragment on TravelField {
    isTravelRelated
    isTrip
    travelerID
    travelerName
    tripID
    booking {
      ID
      type
      isUnmanaged
      platform
      sourceIDs {
        sourceName
        locked
        sourceID {
          ID
          orgID
        }
      }
      tmc {
        hasTMC
        tmcName
      }
      transaction {
        hasLevel2
        isSettled
      }
      airfare {
        flightLegs {
          airlineName
          arrivalTime
          carrierCode
          destinationAirportCode
          destinationAirportName
          departureTime
          flightDuration
          flightNumber
          originAirportCode
          originAirportName
          serviceClassCode
          serviceClassName
          travelDate
          departureDate
          arrivalDate
        }
        issueDate
        issuingCarrierName
        passengerName
        ticketNumber
        travelAgencyName
      }
      vehicleRental {
        checkoutDate
        rentalAgreementNumber
        customerServiceNumber
        rentalLocationCity
        rentalLocationState
        rentalLocationCountry
        rentalLocationID
        rentalClassID
        rentalClassName
        rentalRateIndicator
        rentalRate
        renterName
        programCode
        returnCity
        returnState
        returnCountry
        returnDate
        returnLocationID
        totalMiles
        rentalCompany
      }
      hotel {
        folioName
        arrivalDate
        departureDate
        roomRate
        roomTax
        propertyPhoneNumber
        customerServiceNumber
        renterName
        merchantName
        checkInDate
        checkOutDate
        checkInTime
        checkOutTime
        address {
          address
          address1
          address2
          city
          country
          postalCode
          state
        }
      }
      expenseID
    }
  }
`;

export const EXPENSE_CARD_INFO_FRAGMENT = gql`
  fragment ExpenseCardInfoFragment on ExpenseCardInfo {
    cardFormat
    cardBehavior
    fullName
    last4
  }
`;

// TODO Don't use expense fragment on expense list query
export const EXPENSE_FRAGMENT = gql`
  fragment ExpenseFragment on Expense {
    ID
    userID
    userFullName
    userAvatarBackgroundColor
    user @client {
      ID
      fullName
      avatarBackgroundColor
    }
    isPersonal
    isOOP
    isMileage
    paymentType @client
    isTransactionSettled
    dateTime
    postedDateTime
    approvedDateTime
    rejectedDateTime
    transactionData {
      cardPresence
    }
    merchantData {
      name
      location
      code
      addressLine1
      city
      state
      zip
    }
    sentBack {
      ...ExpenseSentBackFragment
    }
    sentBackDateTime
    costCenterID
    costCenterName
    costCenter @client {
      ID
      name
    }
    expenseTypeID
    expenseTypeName
    expenseType @client {
      ID
      name
      isDeleted
    }
    exportInfo {
      ...ExportInfoFragment
    }
    merchant {
      ID
      name
      rawName
    }
    mileage @client
    policyID
    amount
    status
    purpose
    hasSplits
    hasAutogeneratedReceipt
    hasReceipts
    flags {
      ...FlagFragment
    }
    bubbles {
      ...BubbleFragment
    }
    receiptAffidavit {
      receiptAffidavitAvailable
    }
    requiresAttention
    notes
    attendees {
      fullName
      relationship
    }
    currencyCode
    parent {
      ID
      amount
      postedDateTime
      merchant {
        name
      }
    }
    customFields
    fields {
      forex {
        originatingCurrency {
          amountRaw
          amountTotal
          crossBorderFee
          name
          namePlural
          symbol
        }
        billingCurrency {
          amountRaw
          amountTotal
          crossBorderFee
          name
          namePlural
          symbol
        }
        exchangeRate
      }
      travel {
        ...TravelFieldFragment
      }
      mileage {
        distance
        distanceUnits
        route
        distanceMode
        isRoundTrip
      }
      reimbursement {
        isReimbursable
        status
        transferID
        scheduledPaymentDateTime
        paymentDateTime
        errorMessage
      }
    }
    currentApprover {
      user {
        ID
        fullName
        avatarBackgroundColor
      }
    }
    approvers {
      dateTime
      status
      userID
      userFullName
      avatarBackgroundColor
    }
    typesOfReceipts
    approvedBy {
      userID
      fullName
    }
    rejectedBy {
      userID
      fullName
    }
    card {
      ...ExpenseCardInfoFragment
    }
  }
  ${FLAG_FRAGMENT}
  ${BUBBLE_FRAGMENT}
  ${EXPORT_INFO_FRAGMENT}
  ${EXPENSE_SENT_BACK_FRAGMENT}
  ${TRAVEL_FIELD_FRAGMENT}
  ${EXPENSE_CARD_INFO_FRAGMENT}
`;

export const EXPENSE_FRAGMENT_V2 = gql`
  fragment ExpenseFragmentV2 on Expense {
    ID
    userID
    lockInfo {
      isLocked
    }
    user {
      ID
      fullName
      avatarBackgroundColor
      reimbursementPolicy
      archived
    }
    isPersonal
    isOOP
    isMileage
    paymentType @client
    isTransactionSettled
    dateTime
    postedDateTime
    approvedDateTime
    rejectedDateTime
    transactionData {
      cardPresence
    }
    merchantData {
      name
      location
      code
      addressLine1
      city
      state
      zip
    }
    sentBack {
      ...ExpenseSentBackFragment
    }
    sentBackDateTime
    costCenterID
    costCenterName
    costCenter @client {
      ID
      name
    }
    expenseTypeID
    expenseTypeName
    expenseType @client {
      ID
      name
      isDeleted
    }
    exportInfo {
      ...ExportInfoFragment
    }
    _merchant: merchant {
      ID
      name
      rawName
      categoryCode
    }
    merchant: localMerchant @client
    mileage @client
    policyID
    amount
    status
    purpose
    hasSplits
    hasAutogeneratedReceipt
    hasReceipts
    flags {
      ...FlagFragment
    }
    bubbles {
      ...BubbleFragment
    }
    receiptAffidavit {
      receiptAffidavitAvailable
    }
    requiresAttention
    notes
    attendees {
      fullName
      relationship
    }
    currencyCode
    parent {
      ID
      amount
      postedDateTime
      merchant {
        name
      }
    }
    customFields: localCustomFields @client
    _customFields: customFields
    fields {
      forex {
        originatingCurrency {
          amountRaw
          amountTotal
          crossBorderFee
          name
          namePlural
          symbol
        }
        billingCurrency {
          amountRaw
          amountTotal
          crossBorderFee
          name
          namePlural
          symbol
        }
        exchangeRate
      }
      travel {
        ...TravelFieldFragment
      }
      mileage {
        distance
        distanceUnits
        route
        distanceMode
        isRoundTrip
      }
      reimbursement {
        isReimbursable
        isOrgDirectPolicy
        status
        transferID
        scheduledPaymentDateTime
        paymentDateTime
        errorMessage
      }
    }
    currentApprover {
      user {
        ID
        fullName
        avatarBackgroundColor
      }
    }
    approvers {
      dateTime
      status
      userID
      userFullName
      avatarBackgroundColor
    }
    typesOfReceipts
    approvedBy {
      userID
      fullName
    }
    rejectedBy {
      userID
      fullName
    }
  }
  ${FLAG_FRAGMENT}
  ${BUBBLE_FRAGMENT}
  ${EXPORT_INFO_FRAGMENT}
  ${EXPENSE_SENT_BACK_FRAGMENT}
  ${TRAVEL_FIELD_FRAGMENT}
`;

export const ERP_CONFIGURATION_FRAGMENT = gql`
  fragment ERPConfigurationFragment on ERPConfiguration {
    exportSettings {
      archiveExpenses
      businessExpenses {
        accountToCredit {
          externalID
          name
        }
        exportType
        form {
          ID
          name
        }
        accountTypeFilter
        possibleExportTypes
      }
      personalExpenses {
        accountToCredit {
          externalID
          name
        }
        exportType
        form {
          ID
          name
        }
        accountTypeFilter
        possibleExportTypes
      }
      reimbursableExpenses {
        accountToCredit {
          externalID
          name
        }
        exportType
        form {
          ID
          name
        }
        accountTypeFilter
        possibleExportTypes
      }
    }
    importSettings {
      costCenters {
        possibleSources
        source
      }
      dimensionTypes {
        name
        type
        isMappable
      }
    }
    advancedSettings {
      exportUnsubmitted
      exportSubmitted
      exportReviewed
      exportReadyToPost
    }
  }
`;

export const USER_FRAGMENT = gql`
  fragment UserListFragment on Users {
    items {
      ID
      firstName
      lastName
      fullName
      roles
      emailAddress
      loginID
      archived
      avatarBackgroundColor
      reimbursementPolicy
      archived
      billingAddress {
        ...AddressFragment
      }
      cardItems {
        ...CardFragment
      }
      defaultApprover {
        ID
        name
        ruleID
      }
      defaultApprover @client {
        ID
        name
        ruleID
      }
      defaultApproverSchedule {
        defaultApprover {
          ID
          name
        }
        effectiveDate
        fromEffectiveDate
      }
      defaultCostCenter {
        ID
        name
      }
      emailStatus
      undeliverableReason
      cardOrdering {
        status
        cardApprovalRequestID
      }
      loginRestricted
    }
    pagination {
      next
      previousOffset
      offset
      pages
      totalItems
    }
  }
  ${ADDRESS_FRAGMENT}
  ${CARD_FRAGMENT}
`;

export const POLICY_RULES = gql`
  fragment CustomApprovalRulesListFragment on PolicyRule {
    ID
    description @client
    effectiveDateTime
    status
    statusLabel @client
    evaluation
  }
`;

export const EXPENSE_FIELD_FRAGMENT = gql`
  fragment expenseField on ExpenseField {
    ID
    name
    property
    description
    isDeleted
    isUserDefined
    status @client
    canBeUsedOn
    uiType
    defaultValue
    values {
      ID
      name
    }
    useMultiSetMap
    connectorID
    connectorTypeCode
  }
`;

export const EXPENSE_FIELD_VALUE_FRAGMENT = gql`
  fragment ExpenseFieldValueFragment on ExpenseFieldValue {
    ID
    name
    description
    ruleIDs
    externalID
  }
`;

export const ORG_TRAVEL_MANAGEMENT_COMPANY_PARTNER = gql`
  fragment OrgTMCPartnerFragment on TMCPartner {
    name
    label
    icon
    ssoLink
    orgID
    legalEntityID
    urlPolicyiFrame
  }
`;

export const TMC_NAME_FRAGMENT = gql`
  fragment TmcNameFragment on TmcName {
    family1
    family2
    given
    middle
  }
`;

export const TMC_PHONE_NUMBER_FRAGMENT = gql`
  fragment TmcPhoneNumberFragment on TmcPhoneNumber {
    countryCode
    countryCodeSource
    extension
    isoCountryCode
    italianLeadingZero
    nationalNumber
    numberOfLeadingZeros
    preferredDomesticCarrierCode
    rawInput
    type
  }
`;

export const TMC_ADDRESS_FRAGMENT = gql`
  fragment TmcAddressFragment on TmcAddress {
    addressLines
    administrativeArea
    continentCode
    description
    isDefault
    languageCode
    locality
    locationCode
    organization
    postalCode
    recipients
    regionCode
    regionName
    revision
    sortingCode
    sublocality
    timezone
  }
`;

export const TMC_EMERGENCY_CONTACT_FRAGMENT = gql`
  fragment TmcEmergencyContactInfoFragment on TmcEmergencyContactInfo {
    email
    name {
      ...TmcNameFragment
    }
    phoneNumber {
      ...TmcPhoneNumberFragment
    }
  }
  ${TMC_NAME_FRAGMENT}
  ${TMC_PHONE_NUMBER_FRAGMENT}
`;

export const TMC_IDENTITI_DOCS_FRAGMENT = gql`
  fragment TmcIdentityDocsFragment on TmcIdentityDocs {
    passport {
      docId
      expiryDate {
        iso8601
      }
      issueCountry
      issuedDate {
        iso8601
      }
      nationalityCountry
    }
    ktn {
      number
      issueCountry
    }
    redress {
      number
      issueCountry
    }
  }
`;

export const TMC_TRAVELER_FRAGMENT = gql`
  fragment TmcTravelerFragment on TmcTraveler {
    dob {
      iso8601
    }
    emergencyContactInfo {
      ...TmcEmergencyContactInfoFragment
    }
    gender
    identityDocs {
      ...TmcIdentityDocsFragment
    }
    name {
      ...TmcNameFragment
    }
    title
    addresses {
      ...TmcAddressFragment
    }
    phoneNumbers {
      ...TmcPhoneNumberFragment
    }
    email
  }
  ${TMC_EMERGENCY_CONTACT_FRAGMENT}
  ${TMC_IDENTITI_DOCS_FRAGMENT}
  ${TMC_NAME_FRAGMENT}
  ${TMC_ADDRESS_FRAGMENT}
  ${TMC_PHONE_NUMBER_FRAGMENT}
`;

export const AIR_PREFS_FRAGMENT = gql`
  fragment AirPrefFragment on TmcAirPref {
    airlinePrefs {
      airlines
    }
    alliancePref {
      alliances
    }
    farePref {
      fareTypes
    }
    mealPref {
      exclMealPrefs
      inclMealPrefs
      specialMealDescription
    }
    numStopPref {
      numOfStops
    }
    seatAmenityPref {
      seatAmenityTypes
    }
    seatLocationPrefs {
      cabins
      isBulkHeadPref
      maxFlightDurationInHours
      position
    }
  }
`;

export const EMAIL_PREFS_FRAGMENT = gql`
  fragment EmailPrefFragment on EmailPreferences {
    spotnana {
      confirmationEmailsPreference {
        enabled
        ccEmails
      }
      notificationEmailsPreference {
        enabled
        ccEmails
      }
    }
  }
`;

export const LOYALTY_PROGRAM_FRAGMENT = gql`
  fragment LoyaltyFragment on LoyaltyProgram {
    spotnana {
      id
      issuedBy
      type
      appliedTo
    }
  }
`;

export const LOYALTY_BOOKING_PROGRAM_FRAGMENT = gql`
  fragment LoyaltyBookingFragment on LoyaltyProgramsOption {
    ID
    name
  }
`;

export const CARD_REQUEST_FRAGMENT = gql`
  fragment CardRequestFragment on CardRequest {
    ID
    requestor {
      ID
      name
      avatarBackgroundColor
    }
    requestedFor {
      ID
      name
    }
    limit
    firstName
    lastName
    cardFormat
    status
    requestedDateTime
    processedAt
    shippingAddress {
      address
      address1
      address2
      city
      country
      postalCode
      state
    }
    shippingMethod
    purpose
    cardFormat
    firstName
    lastName
  }
`;

export const DYNAMIC_FORM_FIELD_FRAGMENT = gql`
  fragment DynamicFormFieldFragment on DynamicFormField {
    ID
    name
    isVisible
    isRequired
    isEnabled
    readOnly
    property
    uiType
    valueType
    divider
    action
    properties
    _delimiter: delimiter
    delimiter @client
    _tooltip: tooltip
    tooltip @client
    _validationRules: validationRules
    validationRules @client
    _values: values
    values @client
    _defaultValue: defaultValue
    defaultValue @client
    _options: options
    options @client
    _decorator: decorator
    decorator @client
  }
`;

export const DYNAMIC_FORM_SECTION_OR_FIELD_FRAGMENT = gql`
  fragment DynamicFormSectionOrFieldFragment on DynamicFormField {
    ...DynamicFormFieldFragment
    fields {
      ...DynamicFormFieldFragment
      fields {
        ...DynamicFormFieldFragment
        fields {
          ...DynamicFormFieldFragment
          fields {
            ...DynamicFormFieldFragment
          }
        }
      }
    }
  }
  ${DYNAMIC_FORM_FIELD_FRAGMENT}
`;
