import { useEffect, useState } from "react";
import { Cascader as AntCascader } from "antd";
import PropTypes from "prop-types";
import styled from "styled-components";

import Icon from "../Icon";

const CascaderStyled = styled(AntCascader)`
  &.ant-cascader-picker {
    width: 100%;
    text-transform: none;
    font-weight: normal;

    .ant-cascader-picker-label + .ant-cascader-input,
    .ant-cascader-picker-label:hover + .ant-cascader-input {
      border-color: transparent !important;
      box-shadow: none !important;
    }
  }
`;

const Cascader = ({ getPopupContainer, loading, ...rest }) => {
  const [open, setOpen] = useState(rest.defaultOpen);

  const closeMenu = () => {
    setOpen(false);
  };

  // ComponentDidMount
  useEffect(() => {
    if (getPopupContainer) {
      getPopupContainer().addEventListener("scroll", closeMenu);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ComponentWillUnmount
  useEffect(
    () => () => {
      if (getPopupContainer) {
        getPopupContainer().removeEventListener("scroll", closeMenu);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <CascaderStyled
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      suffixIcon={loading ? <Icon type="loading" /> : undefined}
      popupVisible={open}
      popupClassName="auto-height-first-column"
      onPopupVisibleChange={setOpen}
    />
  );
};

Cascader.propTypes = {
  ...AntCascader.propTypes,
  loading: PropTypes.bool,
  getPopupContainer: PropTypes.func,
  notFoundContent: PropTypes.string,
};

Cascader.defaultProps = {
  ...AntCascader.defaultProps,
  notFoundContent: "No results found",
};

export default Cascader;
