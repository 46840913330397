/* eslint-disable react/jsx-props-no-spreading */
import { memo, useCallback, useMemo } from "react";
import { Table as AntTable } from "antd-virtualized-table";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";
import media from "styled-media-query";

import Colors from "../../themes/colors";
import { generateRandomString } from "../../utils";
import Button from "../Button";
import Empty from "../Empty";
import Heading from "../Heading";
import ExpandIcon from "./ExpandIcon";
import HeaderWithOrderOptions from "./HeaderWithOrderOptions";
import TablePure from "./TablePure";

const ButtonStyled = styled(Button)`
  margin: auto !important;
  display: block !important;
`;

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const borderRadius = keyframes`
  from {
    border-radius: 0%;
  } to {
    border-radius: 50%;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fontFamily = `var(--cen-font-family), 'Whitney A', 'Whitney B', system-ui,
      -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;`;

const TableStyled = styled(AntTable)`
  .ant-table {
    font-family: ${fontFamily};
    font-size: 16px;
    color: ${Colors.midnight};

    --antd-wave-shadow-color: ${Colors.primary};
  }

  .ant-spin-dot-item {
    background-color: ${Colors.primary};
  }

  .checkbox-loading .ant-checkbox-inner {
    position: relative;
    background: conic-gradient(from 0deg at 50% 50%, #fff, ${Colors.midnight});
    animation:
      ${spin} 1.4s linear infinite,
      ${fadeIn} 0.75s forwards,
      ${borderRadius} 0.25s forwards;

    &::before {
      content: "";
      background-color: white;
      position: absolute;
      width: 80%;
      height: 80%;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border: 4px solid white;
    }

    &::after {
      display: none !important;
    }
  }

  /* check TD selector */
  .ant-table-body {
    .ant-table-expanded-row,
    .ant-table-expanded-row:hover,
    .ant-table-expanded-row:hover .ant-table-cell {
      background: transparent !important;
    }
  }

  .ant-table-header {
    background-color: transparent !important;

    table {
      overflow: hidden !important;
    }
  }

  .ant-table-footer {
    background: transparent !important;
    ${media.lessThan("medium")`
         padding-left: 0 !important;
       }
    `};
  }

  thead {
    line-height: 1.125rem;

    th {
      background-color: transparent !important;
      font-weight: 400 !important;
      color: ${Colors.dark_gray} !important;
      padding: 0.5rem !important;
      border-color: #e8e8e8 !important;

      &::before {
        display: none;
      }
    }

    tr {
      background: transparent !important;
    }
  }

  /* Remove hover transitions */
  .ant-table-cell-row-hover {
    background: ${Colors.bg_gray} !important;
    transition: none !important;
  }

  th,
  .ant-table-cell {
    white-space: nowrap;
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
    ${media.lessThan("large")`
      &:first-child {
        padding-left: 0 !important;
      }
      &:last-child {
        padding-right: 0 !important;
      }
    `};
  }

  .ant-table-cell:not(th) {
    border-bottom: 1px solid ${Colors.bg_gray} !important;
    transition: none !important;
    padding: 1.125rem 0.75rem !important;
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
      cursor: pointer !important;
    }
  }

  .ant-table-tbody .ant-checkbox-inner {
    transition: none;
  }

  .ant-checkbox-inner {
    height: 19px;
    width: 19px;
    border-radius: 2px;
  }

  /* Align checkboxes */
  .ant-checkbox-wrapper,
  .ant-table-selection-column {
    display: flex;
    justify-content: center;
  }

  .ant-checkbox {
    margin-top: 0.25rem;
  }

  /* Remove hover transitions on checked checkboxes */
  .ant-checkbox-checked.ant-checkbox:hover {
    .ant-checkbox-inner {
      background-color: ${Colors.primary} !important;
    }
  }

  .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
  .ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
    border-color: ${Colors.primary} !important;
  }

  .ant-checkbox-wrapper.ant-checkbox-wrapper-checked:hover {
    .ant-checkbox-inner {
      background-color: ${Colors.primary} !important;
      border-color: ${Colors.primary} !important;
    }
  }

  /* Remove background hover on selected rows */
  .ant-table-row-selected:not(:hover) .ant-table-cell {
    background: transparent !important;
  }

  .ant-table-row-level-0 {
    ${media.greaterThan("small")`
      box-sizing: border-box !important;
      .ant-table-cell:first-child {
        transition: none;
      }
      :hover {
        .ant-table-cell:first-child {
          transition: none;
        }
      }
    `};
  }

  .ant-table-title {
    font-weight: 700 !important;
    font-size: 1rem;
  }

  .ant-table-column-sorter .ant-table-column-sorter-inner-full {
    margin-top: -0.25rem !important;
  }

  .ant-table-placeholder {
    border-bottom: none !important;
    display: flex;
    justify-content: center;

    .ant-table-cell {
      margin-top: 2rem;
    }

    .ant-empty {
      margin-top: 2.5rem !important;
    }
  }

  .rc-virtual-list-holder-inner {
    margin: 0 !important;
    font-family: ${fontFamily};
  }

  .rc-virtual-list-scrollbar-horizontal {
    display: none !important;
  }

  .ant-table-thead {
    th {
      text-transform: uppercase !important;
      font-weight: 600 !important;
      font-size: 0.625rem !important;
      color: ${Colors.dark_gray};
    }
  }

  /* Hide pagination search */
  .anticon-search {
    display: none !important;
  }

  .ant-select-selector {
    /* Modify pagination dropdown to appear as antd 3 */
    border: none !important;
    background-color: rgb(230 230 230) !important;
    border-radius: 0.25rem !important;
    font-family: ${fontFamily};
    cursor: pointer !important;

    input {
      display: none !important;
    }
  }

  .ant-select-selection-item {
    color: ${Colors.text} !important;
    font-size: 0.75rem;
  }

  .ant-select-arrow {
    color: ${Colors.text} !important;
    display: block;
  }

  .ant-select-item-option {
    font-size: 1rem;
  }

  .ant-select-item-option-active,
  .ant-select-item-option-selected {
    background-color: rgb(240 245 254) !important;

    &:hover {
      font-weight: 600 !important;
    }
  }

  .ant-pagination {
    gap: 2px;
    margin-bottom: 0 !important;

    .ant-pagination-total-text {
      color: ${Colors.monumental} !important;
      font-size: 0.75rem;
    }

    .ant-pagination-item,
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      display: none !important;
    }

    .ant-pagination-prev {
      background-color: rgb(230 230 230);
      margin-right: -1px !important;
      position: relative !important;
      z-index: 1;
      border-radius: 0.25rem 0 0 0.25rem;

      :hover,
      :focus {
        z-index: 2;
      }
    }

    .ant-pagination-next {
      background-color: rgb(230 230 230);
      border-radius: 0 0.25rem 0.25rem 0 !important;
      position: relative !important;
      z-index: 1;

      :hover,
      :focus {
        z-index: 2;
      }
    }

    .ant-pagination-simple-pager {
      input {
        margin-left: 1px !important;
      }
    }
  }

  .ant-pagination:first-child {
    margin-top: 0 !important;
  }

  th.ant-table-sort-title {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  .ant-header-sort-header {
    &:hover {
      background: ${Colors.bg_gray};
    }

    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
  }

  .ant-header-sort-header__title {
    margin-right: 10px;
  }

  .ant-header-sort-header__order {
    margin-right: 7.5px;
  }

  .ant-header-sort-header__order.desc {
    transform: rotate(180deg);
  }

  .ant-header-sort-header__order svg {
    vertical-align: unset;
  }
`;

const VirtualTable = memo(
  ({
    id,
    className,
    title,
    footer,
    onLoadMore,
    fetchingMore,
    dataSource,
    loading,
    pagination,
    onPaginationClick,
    onSortChanged,
    columns,
    sortFieldId,
    sortDirection,
    scrollHeight,
    ...otherProps
  }) => {
    const sortColumnChanged = useCallback(
      (item) => {
        if (onSortChanged) {
          onSortChanged(item);
        }
      },
      [onSortChanged],
    );

    const getColumnHeaderTemplate = useCallback(
      (column) => (
        <HeaderWithOrderOptions
          column={column}
          sortFieldId={sortFieldId}
          sortDirection={sortDirection}
          onSortChanged={sortColumnChanged}
        />
      ),
      [sortColumnChanged, sortDirection, sortFieldId],
    );

    const handlePaginationChange = (newPage) => {
      if (onPaginationClick) {
        onPaginationClick(newPage);
      }
    };

    const renderTitle = useCallback(() => {
      if (typeof title === "string") {
        return <Heading as="h5">{title}</Heading>;
      }
      return title;
    }, [title]);

    const renderFooter = useCallback(() => {
      const showLoadMoreBtn = onLoadMore && dataSource && dataSource.length > 0;
      if (footer || onLoadMore) {
        return (
          <>
            {footer && footer()}
            {showLoadMoreBtn && (
              <ButtonStyled
                id={`${id}-load-more-button`}
                type="primary"
                onClick={onLoadMore}
                loading={fetchingMore}
              >
                Load more
              </ButtonStyled>
            )}
          </>
        );
      }
      return null;
    }, [dataSource, fetchingMore, footer, id, onLoadMore]);

    const isEmpty = dataSource && dataSource.length === 0;

    const components = useMemo(
      () => ({
        table: TablePure,
      }),
      [],
    );

    const mappedColumns = useMemo(
      () =>
        columns?.map((column) => {
          const hasOrderOptions =
            column.sortOrder && column.sortOrder.length && Array.isArray(column?.sortOrder);
          return {
            ...column,
            title: hasOrderOptions ? getColumnHeaderTemplate(column) : column.title,
            className: `${column.className || ""} ${hasOrderOptions && "ant-table-sort-title"}`,
            sortOrder: hasOrderOptions ? undefined : column.sortOrder,
          };
        }),
      [columns, getColumnHeaderTemplate],
    );

    return (
      <TableStyled
        virtual
        scroll={{ y: scrollHeight }}
        id={id}
        className={className}
        locale={{
          ...(otherProps?.emptyStateParams && {
            emptyText: <Empty {...otherProps.emptyStateParams} />,
          }),
        }}
        columns={mappedColumns}
        title={title ? renderTitle : null}
        footer={footer || onLoadMore ? renderFooter : null}
        loading={loading && !fetchingMore}
        dataSource={dataSource}
        showHeader={!isEmpty}
        components={components}
        expandable={{ expandIcon: ExpandIcon }}
        onRow={(_record, index) => ({ index })}
        pagination={
          pagination && {
            ...pagination,
            showQuickJumper: false,
            hideOnSinglePage:
              typeof pagination.hideOnSinglePage === "boolean" ? pagination.hideOnSinglePage : true,
            onChange: handlePaginationChange,
          }
        }
        {...otherProps}
      />
    );
  },
);

VirtualTable.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  columns: PropTypes.any,
  pagination: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  title: PropTypes.any,
  footer: PropTypes.any,
  onLoadMore: PropTypes.func,
  onPaginationClick: PropTypes.func,
  onSortChanged: PropTypes.func,
  fetchingMore: PropTypes.bool,
  dataSource: PropTypes.array,
  loading: PropTypes.bool,
  sortFieldId: PropTypes.string,
  sortDirection: PropTypes.string,
  scrollHeight: PropTypes.number,
};

VirtualTable.defaultProps = {
  id: generateRandomString("table"),
  pagination: false,
};

export default VirtualTable;
