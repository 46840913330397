import { z } from "zod";

export const LOCATION_OPTIONS = ["WORK_LOCATION", "HOME_LOCATION"] as const;
export const FIRST_NAME_OPTIONS = ["FIRST_NAME"] as const;
export const LAST_NAME_OPTIONS = ["LAST_NAME"] as const;
export const EMAIL_OPTIONS = ["WORK_EMAIL", "PERSONAL_EMAIL"] as const;
export const COST_CENTER_OPTIONS = ["GROUPS"] as const;
export const APPROVER_OPTIONS = ["MANAGER"] as const;
const STATUS = ["COMPLETE", "INCOMPLETE", "RELINK_NEEDED"] as const;
const NOTIFICATION_TYPE = ["EMAIL"] as const;

export const UNLINKED_FIELDS = [
  "FIRST_NAME",
  "LAST_NAME",
  "EMAIL_ADDRESS",
  "BILLING_ADDRESS",
  "DEFAULT_APPROVER",
  "DEFAULT_COST_CENTER",
] as const;

export const orgConfigSchema = z.object({
  initialSyncComplete: z.boolean().optional(),
  modifiedBy: z.string().optional(),
  existingUserDefaultUnlinkedFields: z.array(z.enum(UNLINKED_FIELDS)).nullish(),
  fieldMappings: z.object({
    billingAddress: z.enum(LOCATION_OPTIONS),
    defaultApprover: z.enum(APPROVER_OPTIONS),
    defaultCostCenter: z.enum(COST_CENTER_OPTIONS),
    emailAddress: z.enum(EMAIL_OPTIONS),
    firstName: z.enum(FIRST_NAME_OPTIONS),
    lastName: z.enum(LAST_NAME_OPTIONS),
    defaults: z.object({
      defaultApprover: z.string(),
      defaultCostCenter: z.string(),
    }),
  }),
  notificationConfiguration: z
    .array(
      z.object({
        notificationType: z.enum(NOTIFICATION_TYPE),
        to: z.string(),
      }),
    )
    .nullish(),
  createdAt: z.string(),
  orgId: z.string(),
  integrationName: z.string(),
  status: z.enum(STATUS),
  createdBy: z.string(),
});

export type OrgConfig = z.infer<typeof orgConfigSchema>;

export const orgConfigQueryKey = ({ orgId }: { orgId?: string }) => ["orgConfig", orgId];
