import { useCallback, useContext, useState } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { generateMedia } from "styled-media-query";

import { Layout } from "@core/ui-legacy";
import Colors from "@core/ui-legacy/themes/colors";
import { sansProps } from "@core/ui-legacy/utils/styledSansProps";

import { AppBrandingContext } from "../../AppBranding";
import { ReactComponent as Hamburger } from "../../assets/imgs/hamburger.svg";
import { useCardActivationWizardContext } from "../../common/CardActivationWizardContext";
import { useSideNavCollapsed } from "../../common/SideNavCollapseContext";
import ActivateCardDialog from "../../components/ActivateCardDialog";
import CenterLogo from "../../components/CenterLogo";
import HelpButton from "../../components/HelpButton";
import OrganizationSwitch from "../../components/OrganizationSwitch";
import { Desktop, Mobile } from "../../components/Responsive";
import SandboxIndicator from "../../components/SandboxIndicator";
import { totalHeight } from "../../components/TopNav";
import { SetShippingPreferenceBanner } from "../../features/cards-expiration/banners";
import {
  AddressConfirmationModal,
  CardPossessionConfirmationModal,
  ExpirationModal,
  SPAddressVerificationModal,
  ThanksModal,
} from "../../features/cards-expiration/modals";
import {
  ShippingPreferenceSelector,
  SPAddressUpdateSidebar,
} from "../../features/cards-expiration/sidebar";

const media = generateMedia({
  medium: "62.75rem",
});

const HamburgerStyled = styled(Hamburger)`
  width: 1.25rem;
`;

const LayoutStyled = sansProps(styled, { isHeightFixed: true })(Layout)`
  min-height: 100vh !important;
  ${media.greaterThan("medium")`
    ${({ isHeightFixed }) => isHeightFixed && `height:100vh`};
  `};
`;

const HeadersWrapper = styled.div`
  top: 0;
  position: sticky;
  z-index: 3;
`;

const Header = styled(Layout.Header)`
  width: 100% !important;
  display: flex;
  color: ${Colors.white};
  padding: 0 2.5rem !important;
`;

const Banner = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ color }) => color};
  background: ${({ background }) => background};
  padding: 0 1.5rem;
  height: 2.75rem;
  text-align: center;
  cursor: pointer;
`;

const HamburgerBtn = styled.button`
  background-color: transparent;
  border: 0;
  cursor: pointer;
  padding: 0;

  :focus {
    outline: none;
  }
`;

const MobileNavWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
const HeaderMenu = styled.div`
  display: flex;
  flex: 1 1 0;
  ${media.lessThan("medium")`
    display: none !important;
  `};
`;

const Wrapper = styled(Layout)`
  overflow: hidden !important;
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 20%) !important;

  .ant-drawer-content-wrapper {
    margin-top: "unset";
  }

  .receipt-bin-actions {
    margin: "0 -2.5rem" !important;
    padding: 1rem 2.5rem;
  }
`;

const ABLogo = styled.img`
  max-height: 2rem;
  margin-right: 1.5rem;
  height: 100%;
  max-width: 7.5rem;
  object-fit: contain;
`;

const Sider = styled(Layout.Sider)`
  background: ${Colors.bg_gray} !important;

  .ant-menu-inline > .ant-menu-item,
  .ant-menu-inline-collapsed > .ant-menu-item {
    border-left: 0.1875rem solid transparent;
    transition: none;
    margin: 1px 0 !important;
    height: 3.125rem;
    line-height: 3.125rem;
  }

  .ant-menu-inline > .ant-menu-item:hover {
    background: ${Colors.sidecar_blue} !important;
    transition: none;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: ${Colors.sidecar_blue} !important;
    border-left: 0.1875rem solid ${Colors.secondary};
    font-weight: 600;
  }

  .ant-menu-item,
  .ant-menu-submenu-title {
    padding: 0 1.5rem 0 2.5rem !important;
  }

  .ant-menu-sub > .ant-menu-item {
    padding: 0 1.5rem 0 3.25rem !important;
  }
`;

const fixedHeightPageContent = css`
  height: ${totalHeight} !important;
`;

export const CONTAINED_PAGE_CONTENT_REM = 2.5;

export const containedPageContent = css`
  padding: ${CONTAINED_PAGE_CONTENT_REM}rem ${CONTAINED_PAGE_CONTENT_REM}rem 0
    ${CONTAINED_PAGE_CONTENT_REM}rem !important;
`;

const Content = sansProps(styled, { noContainer: true, isHeightFixed: true })(Layout.Content)`
  position: relative !important;
  background-color: ${Colors.white} !important;
  z-index: 1 !important;
  ${({ isHeightFixed }) => isHeightFixed && fixedHeightPageContent};
  ${({ noContainer }) => !noContainer && containedPageContent};
`;

const InsetPageTemplate = ({
  header,
  sider,
  drawer,
  notifications,
  children,
  onOpenDrawer,
  fixedHeight,
  noContainer,
  siderCollapsedWidth,
  me = {},
  ...props
}) => {
  const isSiderCollapsed = useSideNavCollapsed();
  const { cardsToActivate, hasNotActivatedCard } = useCardActivationWizardContext();
  const { appBrandingLogo, logoLinkAddress } = useContext(AppBrandingContext);
  const [isModalOpen, setIsModalOpen] = useState(hasNotActivatedCard);

  const showModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleOpenDrawer = useCallback(() => {
    if (onOpenDrawer) onOpenDrawer();
  }, [onOpenDrawer]);

  return (
    <LayoutStyled isHeightFixed={fixedHeight} {...props}>
      <HeadersWrapper>
        {hasNotActivatedCard && (
          <Banner
            onClick={showModal}
            tabIndex={0}
            color={Colors.white}
            background={Colors.link_blue}
          >
            <span>Your CenterCard is not yet activated. Click to activate it now!</span>
          </Banner>
        )}
        <SetShippingPreferenceBanner />
        <Header>
          <Mobile>
            <MobileNavWrapper>
              <HamburgerBtn type="button" onClick={handleOpenDrawer}>
                <HamburgerStyled role="img" aria-label="Open Site Menu" />
              </HamburgerBtn>
              <OrganizationSwitch {...{ isMobile: true }} />
            </MobileNavWrapper>
          </Mobile>
          <Desktop>
            <a href={logoLinkAddress}>
              {appBrandingLogo ? (
                <ABLogo src={appBrandingLogo} alt="App Branding Logo" />
              ) : (
                <CenterLogo size={2} mr={1.5} color={Colors.white} />
              )}
            </a>
            <SandboxIndicator sandbox={me.organization.sandbox} drawer={false} />
            <HeaderMenu>{header}</HeaderMenu>
          </Desktop>
        </Header>
      </HeadersWrapper>
      <Mobile>{drawer}</Mobile>
      <Wrapper id="affix-receipt-bin-panel">
        {sider && (
          <Desktop>
            <Sider
              width={250}
              defaultCollapsed
              breakpoint="md"
              collapsedWidth={siderCollapsedWidth}
              collapsed={isSiderCollapsed}
            >
              {sider}
            </Sider>
          </Desktop>
        )}
        <Content noContainer={noContainer} isHeightFixed={fixedHeight}>
          {children}
          <HelpButton isHeightFixed={fixedHeight} />
        </Content>
        {notifications}
      </Wrapper>
      <ActivateCardDialog
        userID={me?.ID}
        phone={me?.phone}
        fraudEnrolled={me?.fraudEnrolled}
        cardsToActivate={cardsToActivate}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <ExpirationModal />
      <ShippingPreferenceSelector />
      <SPAddressUpdateSidebar />
      <ThanksModal />
      <AddressConfirmationModal />
      <CardPossessionConfirmationModal />
      <SPAddressVerificationModal />
    </LayoutStyled>
  );
};

InsetPageTemplate.propTypes = {
  header: PropTypes.node.isRequired,
  sider: PropTypes.node,
  drawer: PropTypes.node,
  notifications: PropTypes.node,
  children: PropTypes.any.isRequired,
  onOpenDrawer: PropTypes.func,
  fixedHeight: PropTypes.bool,
  noContainer: PropTypes.bool,
  siderCollapsedWidth: PropTypes.number,
  me: PropTypes.object,
};

InsetPageTemplate.defaultProps = {
  siderCollapsedWidth: 0,
};

export default InsetPageTemplate;
