import classNames from "classnames";

import { cn } from "../../lib/utils";
import * as Icons from "./icons";

export type IconNames = keyof typeof Icons;

type Size = "small" | "medium" | "large" | "default";

export type IconProps = {
  name: IconNames;
  background?: string;
  className?: string;
  color?: string;
  size?: Size;
};

const sizeMapping: { [key in Size]: { container: string; icon: number } } = {
  small: { container: "h-[1.125rem] w-[1.125rem]", icon: 18 },
  medium: { container: "h-8 w-8", icon: 20 },
  large: { container: "h-12 w-12", icon: 24 },
  default: { container: "h-12 w-12", icon: 24 },
};

export const Icon = ({
  name,
  background = "secondary-200",
  color = "success-600",
  size = "default",
  className,
}: IconProps) => {
  // eslint-disable-next-line import/namespace
  const LucideIcon = Icons[name];
  const { container, icon } = sizeMapping[size];
  const colorClass = `text-${color}`;

  return (
    <div
      data-testid="icon-container"
      className={classNames(
        "inline-flex items-center justify-center rounded-full",
        container,
        `bg-${background}`,
        `${colorClass}`,
        className,
      )}
    >
      <LucideIcon className={cn(colorClass, "fill-none")} size={icon} />
    </div>
  );
};
