import { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

import { Heading } from "@core/ui-legacy";

import { ReactComponent as Logo } from "../../assets/imgs/centercardlogo.svg";
import emoji from "../../assets/imgs/emoji1.png";
import CenteredPageTemplate from "../CenteredPageTemplate";

class ErrorBoundary extends PureComponent {
  state = { hasError: false };

  componentDidCatch(error, info) {
    const { onErrorCaught } = this.props;
    // Display fallback UI
    this.setState({ hasError: true });
    if (onErrorCaught) onErrorCaught(error, info);
  }

  render() {
    const { placeHolder, children } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      // You can render any custom fallback UI
      return placeHolder || <ErrorPage />;
    }
    return children;
  }
}

export default withRouter(ErrorBoundary);

const CenteredPageTemplateStyled = styled(CenteredPageTemplate)`
  min-height: 100% !important;
`;

const Wrapper = styled.div`
  max-width: 28.5rem;
  width: 100%;
  text-align: center;
`;

const LogoStyled = styled(Logo)`
  width: 100%;
  height: 2.5rem;
  margin-top: 1.25rem;
`;

const Emoji = styled.img`
  width: 8.75rem;
  margin: 1.25rem 0;
`;

const Text = styled(Heading)`
  margin-bottom: 1rem !important;
`;

export const ErrorPage = () => (
  <CenteredPageTemplateStyled>
    <Wrapper>
      <Heading as="h1">Oh no!</Heading>
      <Emoji alt="Error" src={emoji} />
      <Text as="h4">Something isn&#39;t working right</Text>
      <p>Don&#39;t worry we will put our best team on it.</p>
      <LogoStyled role="img" aria-label="Center" />
    </Wrapper>
  </CenteredPageTemplateStyled>
);
