import { createGlobalStyle } from "styled-components";
import media from "styled-media-query";
import styledSanitize from "styled-sanitize";

import Colors from "@core/ui-legacy/themes/colors";

/* eslint no-unused-expressions: 0 */
const GlobalStyles = createGlobalStyle`
  ${styledSanitize}
  html {
    font-family: var(--cen-font-family), "Whitney A", "Whitney B", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 16px;
    color: ${Colors.midnight};

    --antd-wave-shadow-color: var(--cen-colors-primary-500);
  }

  html,
  body {
    height: 100%;
    width: 100% !important;
    padding: 0 !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #f8f8fA!important;
    font-variant: normal !important;
  }

  * {
  font-variant: normal !important;
  }

.react-query-btn {
  transition: all 0.4s;
  opacity: 0;
}

.react-query-btn:hover {
  opacity: 1;
}

.ant-layout-header {
  background-color: var(--cen-colors-navBackground-500);
}

.ant-calendar-header {
  .ant-calendar-next-month-btn::before,
  .ant-calendar-next-year-btn::before,
  .ant-calendar-next-year-btn::after,
  .ant-calendar-prev-month-btn::before,
  .ant-calendar-prev-year-btn::before,
  .ant-calendar-prev-year-btn::after {
    border-color: ${Colors.placeholder_gray} !important;
    border-radius: 0; 
    border-width: 0.13rem 0 0 0.13rem;
  }

  .ant-calendar-next-month-btn:hover::before,
  .ant-calendar-next-year-btn:hover::before,
  .ant-calendar-next-year-btn:hover::after,
  .ant-calendar-prev-month-btn:hover::before,
  .ant-calendar-prev-year-btn:hover::before,
  .ant-calendar-prev-year-btn:hover::after {
    border-color: ${Colors.monumental} !important;
    border-radius: 0; 
    border-width: 0.13rem 0 0 0.13rem;
  }
}

  body {
    overflow-x: hidden;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: normal;
  }

  #root, .awsappsync {
    height: 100%;
  }

  p,
  label {
    line-height: 1.5em;
  }

  a {
    :focus {
      text-decoration: none;
    }
  }

  ul {
    list-style: none;
    padding: 0;
  }

  .wrap-text {
    white-space: normal !important;
    word-break: normal !important;
  }

  .table-row-grayed-out {
      color: ${Colors.monumental} !important;

      .ant-avatar {
        color: ${Colors.cool_gray} !important;
        border-color: ${Colors.cool_gray} !important;
      }
  }

  input:focus,
  textarea:focus,
  select:focus,
  div[role="combobox"] {
    box-shadow: none !important;
  }

  i.ant-spin-dot-item {
    background-color: var(--cen-colors-primary-500);
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }

  /* This is a hack to override antd styles, there is no currently a way to do at component level */

  .ant-select-dropdown-menu { overflow: initial; }

  .ant-menu-item-selected > a, .ant-menu-item-selected > a:hover {
    color: ${Colors.text};
  }

  .ant-menu-item > a:hover {
    color: ${Colors.text};
  }

  .ant-select-dropdown {
    .ant-select-dropdown-menu {
      max-height: 16.25rem; 
    }

    .ant-select-dropdown-menu-item-group-title {
      font-weight: 700 !important;
      color: ${Colors.text} !important;
    }

    .ant-select-dropdown-menu-item__capitalize {
      text-transform: capitalize !important;
    }

    .ant-select-dropdown-menu-item-active,
    .ant-select-dropdown-menu-item:hover,
    .ant-select-dropdown-menu-item-selected,
    .ant-select-dropdown-menu-item-selected:hover {
      font-weight: 600!important;
    }

    .ant-select-dropdown-menu-item.action-item.last {
      border-bottom: 1px solid ${Colors.fog_gray};
    }

    .ant-select-dropdown-menu-item-selected,
    .ant-select-dropdown-menu-item-active,
    .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
      background-color: ${Colors.sky}!important;
    }
  }

  .ant-btn-primary {
    background-color: var(--cen-colors-primary-500);
    border-color: var(--cen-colors-primary-500);
  }

  .ant-btn-primary:hover {
    opacity: 0.9;
    background-color: var(--cen-colors-primary-500);
    border-color: var(--cen-colors-primary-500);
  }

  /* Override styles for Cascader component */
  li.ant-cascader-menu-item {
    max-width: 18.75rem;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  li.ant-cascader-menu-item.ant-cascader-menu-item-expand {
    padding-right: 28px !important;
  }

  .auto-height-first-column ul.ant-cascader-menu:first-child {
    height: auto;
  }

  /* Date Range Picker for mobile */
  .ant-calendar {
    ${media.lessThan("medium")`
      .ant-calendar-panel {
        background-color: ${Colors.white} !important;
        margin-top: 3rem auto !important;
        width: 99vw !important;
        max-width: 420px !important;
        .ant-calendar-range-left {
            display: block !important;
            width: 100% !important;
            max-width: 412px !important;
            float: unset !important;
        }
        .ant-calendar-range-right {
            display: block !important;
            width: 100% !important;
            max-width: 412px !important;
            float: unset !important;
        }
        .ant-calendar-input  {
          display: none !important;
        }
      }
      .ant-calendar-range {
        width: unset !important;
        background-color: unset !important;
      }
    `};
    .ant-calendar-selected-day:not(.ant-calendar-disabled-cell) .ant-calendar-date {
      background: ${Colors.link_blue} !important;
      color: ${Colors.white} !important;
    }
  }

  .table-row-shadowed {
    box-shadow: 0 9px 0 0 white, 0 2px 8px rgb(0 0 0 / 12%);

    td {
      background: ${Colors.bg_gray}!important;
    }
  }

  .hide-close-x-notification {
    .ant-notification-notice-close {
      display: none;
    }
  }

  .ant-notification-center {
    left: 50%;
    bottom: 90% !important;
    transform: translate(-50%);
    
    .copy-to-clipboard {
      padding: 0.5rem 0.75rem;
      width: 11.1rem;
      border-radius: 0.25rem;
      background: ${Colors.fog_gray};
      box-shadow: 0 0 0.25rem 0 rgb(0 0 0 / 25%);
      margin-left: auto;
      margin-right: auto;
      
      div {
        margin: 0;
        padding: 0;
      }
      
      span {
        font-size: 1rem;
        font-weight: 350;
        color: ${Colors.classic_blue};
      }
      
      svg {
        height: 0.6rem;
      }
    }
  }

  .anticon {
    vertical-align: 2px !important;
  }

  .anticon.anticon-check-circle {
    color: ${Colors.secondary} !important;
  }

  /* ::-webkit-scrollbar-thumb {
    background: ${Colors.cool_gray};
    border-radius: 10px;
  } */

  .ant-tooltip.styled-popup {
    max-width: 550px;

    .ant-tooltip-inner {
      overflow: auto;
      max-height: 50vh !important;
      border-radius: 8px !important;
      box-shadow: 0 2px 10px rgb(0 0 0 / 10%) !important
    }
  }

  .ant-tooltip {
    z-index: 1 !important;

    .ant-tooltip-inner {
      background-color: ${Colors.white} !important;
      color: ${Colors.text} !important;
      padding: 0.875rem;
    }

    .ant-tooltip-arrow {
      width: 15px;
      height: 15px;
      
      &::before {
        background-color: ${Colors.white} !important;
        width: 9px;
        height: 9px;
      }
    }
    
    &.ant-tooltip-placement-topLeft {
      .ant-tooltip-arrow {
        bottom: -6px;
      } 
    }
  }
  
  .filter-items {
    padding: 0.5rem;
    font-size: 1rem;
  }
  
  .filter-items:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  
  .filter-items-active {
    padding: 0.5rem;
    font-size: 1rem;
    color: ${Colors.link_blue}
  }

  .notify-left-button .ant-notification-notice-btn{
    display: block;
    float: none;
    margin: 0;
  }
  
  .popover-alert {
    font-size: 0.75rem !important;
    min-width: 25rem;
    filter: drop-shadow(0 0 8px rgb(0 0 0 / 20%));
    z-index: 8;

    .ant-popover-content {
       background-color: ${Colors.link_blue};
       color: ${Colors.white};
       font-size: 1rem;
       border-radius: 0.5rem;
    }

    .ant-popover-inner {
      background-color: ${Colors.link_blue};
      color: ${Colors.white};
      border-radius: 0.5rem;
      box-shadow: none;
    }

    .ant-popover-title {
      background-color: ${Colors.link_blue};
      border-bottom: 0 !important;
      color: ${Colors.white};
      font-size: 1.125rem;
      border-top-right-radius: 0.5rem;
      border-top-left-radius: 0.5rem;
      padding: 1.3rem 1.5rem 0.375rem;
      font-weight: 700;
    }

    .ant-popover-inner-content {
      border-bottom-right-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
      background-color: ${Colors.link_blue};
      color: ${Colors.white};
      padding: 0 24px 22px;
    }
    
    .ant-popover-arrow {
      background-color: ${Colors.link_blue} !important;
      border-color: ${Colors.link_blue} !important;
      width: 2rem;
      height: 2rem;
      z-index: -1;
    }
    
    .ant-popover-close {
      float: right;
      color: ${Colors.white};
      font-size: 1.5rem !important;
      margin: -0.4rem -1rem 0 0;
    }
  }
  
  .popover-hint {
    font-size: 0.75rem !important;
    min-width: 25rem;
    filter: drop-shadow(0 0 8px rgb(0 0 0 / 20%));

    .ant-popover-content {
       font-size: 1rem;
       border-radius: 0.5rem;
    }

    .ant-popover-inner {
      border-radius: 0.5rem;
      box-shadow: none;
    }

    .ant-popover-inner-content {
      border-radius: 0.5rem;
      padding: 1rem;
    }
    
    .ant-popover-arrow {
      width: 1rem;
      height: 1rem;
      z-index: -1;
      margin-left: 3rem;
    }
    
    .ant-popover-close {
      float: right;
      font-size: 1.5rem !important;
      margin: -0.4rem -1rem 0 0;
    }
  }
  
  .popover-wrapper {
    font-size: 0.75rem !important;
    font-weight: 500 !important;

    .ant-popover-title {
      border: none;
      min-height: 20px !important;
      font-weight: 600;
    }
  
    .ant-popover-inner-content {
      padding: 0 32px 8px 16px;

      .ant-popover-inner-content__ul {
        margin: 0;
        padding: 0 !important;

        .ant-popover-inner-content__ul__li {
          color: ${Colors.monumental};
          margin: 4px 0;
          
          div {
            display: flex;
            align-items: center;
            height: 12px;
            
            div {
              padding-right: 2px;
              margin-left: -3px;
            }
          }
          
          &.pending {
            font-weight: 600;
          }
        }
      }
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--cen-colors-primary-500);
    border-color: var(--cen-colors-primary-500);
  }

  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #EDEFF4;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: var(--cen-colors-primary-500);
  }

  .ant-checkbox-checked::after {
    border: 1px solid var(--cen-colors-primary-500);
  }

  .ant-checkbox-input {
    background: var(--cen-colors-primary-500);
  }

  .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up.on, .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down.on {
    color: var(--cen-colors-primary-500);
}

  body:not(.cz-allowed) #cz_devless_container {
    display: none !important;
    visibility: hidden !important;
    pointer-events: none;
  }
`;

export default GlobalStyles;
