import type { IBaseRule, IConditionInput, LogicalOperator } from "../types";

export enum TravelPolicyType {
  Default = "DEFAULT",
  Group = "GROUP",
  Internal = "INTERNAL",
}

export type ITravelPolicy = IBaseRule<TravelPolicyType>;

export type ITravelGeneratorInput = {
  conditions: IConditionInput[];
  type: TravelPolicyType;
  logicalOperator?: LogicalOperator;
};
