import PropTypes from "prop-types";
import styled from "styled-components";

import { Icon } from "@core/ui-legacy";
import Colors from "@core/ui-legacy/themes/colors";

import Link from "../Link";

const Wrapper = styled.div`
  position: fixed;
  background-color: ${Colors.midnight};
  bottom: 24px;
  left: 24px;
  padding: 0 24px;
  z-index: 9;
`;

const Content = styled.div`
  color: ${Colors.white};
  display: block;
  position: relative;
  height: 100%;
  line-height: 52px;
`;

const LinkStyled = styled(Link)`
  color: ${Colors.cool_gray} !important;
  float: right;

  &:hover {
    color: ${Colors.white} !important;
  }
`;

const SpinIcon = styled(Icon)`
  font-size: 1rem !important;
  color: ${Colors.white} !important;
  display: inline-block;
  margin-right: 20px !important;
`;

const AsyncProgressDialog = ({ message, closeText, onClose }) => (
  <Wrapper>
    <Content>
      <SpinIcon type="loading" />
      {message}
      {closeText && (
        <LinkStyled id="cancel-async-request-btn" onClick={onClose}>
          {closeText}
        </LinkStyled>
      )}
    </Content>
  </Wrapper>
);

AsyncProgressDialog.propTypes = {
  message: PropTypes.string,
  closeText: PropTypes.string,
  onClose: PropTypes.func,
};

AsyncProgressDialog.defaultProps = {
  closeText: "Cancel",
};

export default AsyncProgressDialog;
