import { LogicalOperator } from "./types";

export const all = {
  ID: LogicalOperator.All,
  text: "and",
};

export const any = {
  ID: LogicalOperator.Any,
  text: "or",
};
