import LogRocket from "logrocket";
import ReactDOM from "react-dom";

import "./globals.css";

import { createFeatureFlagsProvider } from "@core/feature-flags/clients";

import App from "./App";
import FronteggProvider from "./common/FronteggProvider";

if (import.meta.env.PROD) {
  LogRocket.init(import.meta.env.REACT_APP_LOGROCKET_KEY, {
    release: import.meta.env.REACT_APP_VERSION,
    network: {
      requestSanitizer: (request) => {
        // We need to parse body as JSON before redacting sensitive data
        if (request.body) {
          try {
            const parsedBody = JSON.parse(request.body);
            request.body = parsedBody;
          } catch {
            // Do nothing
          }
        }

        // Remove noisy datadog requests
        if (request?.url?.toLowerCase().includes("https://browser-intake-us5-datadoghq.com")) {
          return null;
        }

        if (
          request?.url?.toLowerCase().includes("oauth/v3.0/token") ||
          request?.url?.toLowerCase().includes("oauth/v3.0/magic-link-verify")
        ) {
          return null;
        }

        if (request?.body?.operationName === "switchOrg") {
          return null;
        }

        if (request?.headers?.Authorization) {
          request.headers.Authorization = "[REDACTED]";
        }

        if (request?.body?.ein) {
          request.body.ein = "[REDACTED]";
        }

        if (request?.body?.ssn) {
          request.body.ssn = "[REDACTED]";
        }

        if (request?.body?.controller?.ssn) {
          request.body.controller.ssn = "[REDACTED]";
        }

        if (request?.body?.accountNumber) {
          request.body.accountNumber = "[REDACTED]";
        }

        if (request?.body?.routingNumber) {
          request.body.routingNumber = "[REDACTED]";
        }

        if (request?.body?.variables?.input?.spotnana?.dob) {
          request.body.variables.input.spotnana.dob = "[REDACTED]";
        }

        if (request?.body?.variables?.input?.spotnana?.identityDocs?.passport?.docId) {
          request.body.variables.input.spotnana.identityDocs.passport.docId = "[REDACTED]";
        }

        if (request?.body?.variables?.input?.spotnana?.identityDocs?.ktn?.number) {
          request.body.variables.input.spotnana.identityDocs.ktn.number = "[REDACTED]";
        }

        if (
          request?.url?.toLowerCase().includes("resetpassword") ||
          request?.url?.toLowerCase().endsWith("/password")
        ) {
          delete request.body;
        }

        return request;
      },
      responseSanitizer: (response) => {
        if (response?.body?.includes("cardSecurityInfo")) {
          delete response.body;
        }

        if (response?.body?.data?.travelerMe?.spotnana?.dob) {
          response.body.data.travelerMe.spotnana.dob = "[REDACTED]";
        }

        if (response?.body?.data?.travelerMe?.spotnana?.identityDocs?.passport?.docId) {
          response.body.data.travelerMe.spotnana.identityDocs.passport.docId = "[REDACTED]";
        }

        if (response?.body?.data?.travelerMe?.spotnana?.identityDocs?.ktn?.number) {
          response.body.data.travelerMe.spotnana.identityDocs.ktn.number = "[REDACTED]";
        }

        return response;
      },
    },
    dom: {
      // Hiding ant calendar picker because we need to hide user's date of birth
      // but it's not possible to do by applying data-private attr to the input field
      // because ant calendar picker is rendered in a separate element
      privateClassNameBlocklist: [
        "dwolla-customer-ssn",
        "dwolla-customer-ein",
        "ant-calendar-picker-container",
      ],
    },
  });
}

const FeatureFlagsProvider = await createFeatureFlagsProvider({
  clientKey: import.meta.env.REACT_APP_FEATURE_FLAG_CLIENT_ID,
});

ReactDOM.render(
  <FeatureFlagsProvider>
    <FronteggProvider>
      <App />
    </FronteggProvider>
  </FeatureFlagsProvider>,
  document.querySelector("#root"),
);
