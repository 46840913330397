import { useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

import { useFlag } from "@core/feature-flags/clients";
import { Avatar, Badge, Dropdown, Menu } from "@core/ui-legacy";

import withAuthUser from "../../common/core-refactor/withUserAuth";
import { UserRoles } from "../../common/roles";
import { getCZScript } from "../../common/useChurnZero";
import { userHasFeatures } from "../../common/userHasFeatures";
import API from "../../services/rest/api";
import featureFlags from "../../utils/featureFlags";
import * as Routes from "../../utils/routes";
import Storage from "../../utils/storage";

const Wrapper = styled(Dropdown)`
  display: inline-flex;
  height: 4rem;
`;

const BadgeStyled = styled(Badge)`
  sup {
    margin: 0.313rem 0.625rem 0 0;
  }
`;

const UserMenu = (props) => {
  const {
    authUser: {
      ID,
      fullName,
      avatarBackgroundColor,
      roles,
      fraudEnrolled,
      organization: { sandbox, cardPrograms },
      toggles,
      loginID,
      cardItems,
    },
    location,
    history,
  } = props;
  const useCZ = userHasFeatures(toggles, [featureFlags.CHURN_ZERO]);
  const showBadge = !fraudEnrolled && cardItems?.some((item) => item?.statusCode === "Open");
  const useMFA = useFlag("release-mfa");

  useEffect(() => {
    if (useCZ) {
      const { body } = document;
      if (roles.includes(UserRoles.CZ)) {
        body.classList.add("cz-allowed");
      }
      const comdataAccountID = cardPrograms?.[0]?.ID?.split("|")[0];
      if (comdataAccountID && loginID) getCZScript(comdataAccountID, loginID);
    }
  }, [loginID, cardPrograms, roles, useCZ]);

  const handleMenuItemClicked = (item) => {
    switch (item.key) {
      case "logout":
        API.logout();
        break;
      case "help":
        break;
      case "change-password":
        history.push(`${Routes.CHANGE_PASSWORD}?origin=${location.pathname}`);
        break;
      case "settings":
        history.push(Routes.ACCOUNT_INFO_SETTINGS);
        break;
      case "sandbox-tools":
        history.push(Routes.SANDBOX_TOOLS);
        break;
      default:
        break;
    }
  };

  const getMenu = () => {
    const wasUserSignedInWithSSO = Storage.getItem("wasUserSignedInWithSSO") === "true";

    return (
      <Menu onClick={(item) => handleMenuItemClicked(item)}>
        {sandbox && (
          <Menu.Item id="sandbox-tools-link" key="sandbox-tools">
            Sandbox Tools
          </Menu.Item>
        )}
        <Menu.Item id="settings-link" key="settings">
          Settings <BadgeStyled dot={showBadge} />
        </Menu.Item>
        {!wasUserSignedInWithSSO && !useMFA && (
          <Menu.Item id="change-password-link" key="change-password">
            Change Password
          </Menu.Item>
        )}
        <Menu.Item id="help-link" key="help">
          <a href={Routes.CONTACT_SUPPORT} target="_blank" rel="noopener noreferrer">
            <span>Help</span>
          </a>
        </Menu.Item>
        <Menu.Item id="logout-link" key="logout">
          Sign out
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <Wrapper overlay={getMenu()} trigger={["hover"]}>
      <span id="user-menu">
        <Avatar
          name={fullName}
          id="user-menu-avatar"
          userID={ID}
          userColor={avatarBackgroundColor}
          ellipsis={{ length: 15 }}
          showBadge={showBadge}
          styleAvatar={{
            marginRight: 0,
          }}
          styleHeader={{ color: "var(--cen-colors-navText-500)" }}
          styleLabel={{ color: "var(--cen-colors-navText-500)" }}
        />
      </span>
    </Wrapper>
  );
};

UserMenu.propTypes = {
  authUser: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
};

export default withRouter(withAuthUser(UserMenu));
