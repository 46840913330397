import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import { useFlag } from "@core/feature-flags/clients";

import type { CardVersion } from "./types";

dayjs.extend(utc);

export type Timelines =
  | "ui-did-receive-card"
  | "ui-approaching-new-expiration-admin"
  | "ui-set-shipping-preference-edit"
  | "ui-set-shipping-preference-selection"
  | "ui-new-expiration-detected"
  | "ui-card-renewal-notification"
  | "ui-approaching-new-expiration-spender";

export const useExpirationTimeline = ({
  type,
  startDaysDefault,
  endDaysDefault,
  expirationYYMM,
  cardVersion = "old",
}: {
  endDaysDefault: number;
  startDaysDefault: number;
  type: Timelines;
  cardVersion?: CardVersion;
  expirationYYMM?: string;
}) => {
  const isCardReIssuanceEnabled = useFlag("release-card-reissuance-client");
  const timeline = useFlag("configure-card-reissuance-timeline");
  const startDaysBefore = timeline?.[type]?.execute?.daysBeforeExpiration ?? startDaysDefault;
  const endDaysBefore = timeline?.[type]?.dismiss?.daysBeforeExpiration ?? endDaysDefault;

  // Cards with old expirationYYMM. Date references the one we had at
  // the moment when the reissuance process started.
  const endOfMonth = dayjs(expirationYYMM, "YYMM").utc().endOf("month");
  const startDate = endOfMonth.subtract(startDaysBefore, "day");
  const endDate = endOfMonth.subtract(endDaysBefore, "day");
  const now = dayjs();

  // Cards with updated expirationYYMM that has been set to
  // the original + 4y (all VC + PC that were used)
  const endOfMonthPrevExp = endOfMonth.subtract(4, "year");
  const startDatePrevExp = endOfMonthPrevExp.subtract(startDaysBefore, "day");
  const endDatePrevExp = endOfMonthPrevExp.subtract(endDaysBefore, "day");

  let isWithinTimeframe;

  // Card's expiration changes when a physical card is used or when the a virtual
  // card is reissued, so we cannot rely on the current expiration - 10 days
  // because it would be based on a future expiration date. Therefore, we need
  // to compare it against the old expiration date
  switch (cardVersion) {
    case "old":
      isWithinTimeframe = startDate <= now && now < endDate;
      break;
    case "reissued":
      isWithinTimeframe = startDatePrevExp <= now && now < endDatePrevExp;
      break;
    case "both":
      isWithinTimeframe =
        (startDate <= now && now < endDate) || (startDatePrevExp <= now && now < endDatePrevExp);
      break;
    default:
      isWithinTimeframe = false;
  }

  return {
    isWithinTimeline: Boolean(isCardReIssuanceEnabled && isWithinTimeframe),
    startDate,
    endDate,
  };
};
