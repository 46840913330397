import { PureComponent } from "react";
import PropTypes from "prop-types";

class CellPure extends PureComponent {
  render() {
    const { children, ...rest } = this.props;
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <td {...rest}>{children}</td>;
  }
}

CellPure.propTypes = {
  children: PropTypes.any,
};

export default CellPure;
