import { useEffect } from "react";
import { asyncWithLDProvider, useLDClient } from "launchdarkly-react-client-sdk";

import type { FeatureFlag, FeatureFlags } from "../../server/ports/flags";

export const featureFlagsRecord = {
  releaseHrConnect: "release-hr-connect",
  releaseTravelInsights: "release-travel-insights",
  releaseCardManagementEnhancements: "release-card-management-enhancements",
  releaseAdminLock: "release-admin-lock",
} as const satisfies Record<string, FeatureFlag>;

export const featureFlagsSet = new Set<FeatureFlag>(Object.values(featureFlagsRecord));

export const createFeatureFlagsProvider = async ({ clientKey }: { clientKey: string }) =>
  asyncWithLDProvider({
    clientSideID: clientKey,
  });

export const useLazyFlag = () => {
  const ldClient = useLDClient();
  return <T extends FeatureFlag>(key: T) => ldClient?.variation(key) as FeatureFlags[T] | undefined;
};

export const useFlag = <T extends FeatureFlag>(key: T) => {
  const ldClient = useLDClient();
  return ldClient?.variation(key) as FeatureFlags[T] | undefined;
};

export const useIdentifyWithFeatureFlagsProvider = ({
  orgId,
  email,
  orgName,
  userName,
  userId,
  hasTravel,
}: {
  email: string;
  hasTravel: boolean;
  orgId: string;
  orgName: string;
  userId: string;
  userName: string;
}) => {
  const ldClient = useLDClient();
  useEffect(() => {
    void ldClient?.identify({
      kind: "multi",
      user: {
        key: userId,
        name: userName,
        email: email?.toLowerCase(),
      },
      organization: {
        key: orgId,
        name: orgName,
        hasTravel,
      },
    });
  }, [ldClient, orgId, orgName, userId, userName]);
};
