import { useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Colors from "@core/ui-legacy/themes/colors";

import { AppBrandingContext } from "../../AppBranding";
import AuthUserContext from "../../common/contexts/AuthUserContext";
import { getMenuDataWithChildren } from "../../common/menu";
import AppNotificationsIndicator from "../AppNotificationsIndicator";
import NavMenu from "../NavMenu";
import OrganizationSwitch from "../OrganizationSwitch";
import UserMenu from "../UserMenu";

const NavMenuWrapper = styled.div`
  display: flex;
  flex: 1 1 0%;

  .ant-menu-submenu {
    margin-right: 2rem;
    vertical-align: inherit !important;

    .ant-menu-item-selected a {
      color: inherit !important;
    }

    .ant-menu-submenu.ant-menu-submenu-vertical.ant-menu-submenu-selected {
      background-color: ${Colors.sky} !important;

      .ant-menu-submenu-selected .ant-menu-submenu-title {
        font-weight: bold;
      }

      .ant-menu-item-selected,
      .ant-menu-item-active {
        background-color: ${Colors.sky} !important;

        .ant-menu-item-selected {
          font-weight: bold;
        }
      }
    }
  }
`;

const TOP_NAV_HEIGHT_PX = 64;

export const totalHeight = `calc(100vh - ${TOP_NAV_HEIGHT_PX}px)`;

const NavMenuStyled = styled(NavMenu)`
  flex: 1 1 0% !important;
  height: ${TOP_NAV_HEIGHT_PX}px !important;
  line-height: ${TOP_NAV_HEIGHT_PX}px !important;

  > .ant-menu-item {
    top: 0 !important;
    margin-top: 0 !important;
    padding: 0 !important;
    margin-right: 2rem !important;
    vertical-align: inherit !important;
    border-bottom: none !important;

    a {
      color: var(--cen-colors-navText-500) !important;
      padding-left: 0 !important;
      font-size: 1rem !important;
      height: 4rem;

      :hover {
        color: var(--cen-colors-navText-500) !important;
      }
    }

    &.ant-menu-item-selected,
    &.ant-menu-item-active {
      border: none !important;
      background-color: transparent !important;

      a {
        color: var(--cen-colors-navText-500) !important;
        background-image: linear-gradient(
            var(--cen-colors-navActive-500),
            var(--cen-colors-navActive-500)
          ),
          linear-gradient(var(--cen-colors-navActive-500), var(--cen-colors-navActive-500));
        background-size:
          100% 5%,
          0%;
        background-position:
          left bottom,
          1px 0;
      }
    }
  }

  .ant-menu-submenu-popup {
    z-index: 2 !important;
  }

  .ant-menu-submenu {
    top: 0 !important;
    margin-top: 0 !important;
    border-bottom: none !important;
  }

  .ant-menu-submenu-title {
    color: var(--cen-colors-navText-500);
    padding-left: 0 !important;
    font-size: 1rem !important;
    height: 4rem;

    :hover {
      color: var(--cen-colors-navText-500) !important;
    }

    .ant-menu-submenu-arrow {
      color: var(--cen-colors-navText-500);
      transform: translateY(-2px);
      transition:
        transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
        -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      position: absolute;
      top: 2.1875rem;
      right: 0;
      width: 10px;
    }

    .ant-menu-submenu-arrow::before,
    .ant-menu-submenu-arrow::after {
      content: "";
      position: absolute;
      vertical-align: baseline;
      background: var(--cen-colors-navText-500);
      width: 6px;
      height: 1.5px;
      border-radius: 2px;
      transition:
        background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
        transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
        top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
        -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transform: rotate(45deg) translateX(2px);
    }

    .ant-menu-submenu-arrow::before {
      transform: rotate(-45deg) translateX(2px);
    }

    .ant-menu-submenu-arrow::after {
      transform: rotate(45deg) translateX(-2px);
    }
  }

  .ant-menu-submenu-open {
    .ant-menu-submenu-arrow {
      transform: translateY(-4px);
    }

    .ant-menu-submenu-arrow::before {
      transform: rotate(45deg) translateX(2px);
    }

    .ant-menu-submenu-arrow::after {
      transform: rotate(-45deg) translateX(-2px);
    }
  }

  .ant-menu-submenu-selected,
  .ant-menu-submenu-active {
    border: none !important;
    transition: none !important;
    background-image: linear-gradient(
        var(--cen-colors-navActive-500),
        var(--cen-colors-navActive-500)
      ),
      linear-gradient(var(--cen-colors-navActive-500), var(--cen-colors-navActive-500));
    background-size:
      calc(100% - 20px) 5%,
      0%;
    background-position:
      left bottom,
      1px 0;
  }
`;

const NavItemWrapper = styled.span`
  white-space: nowrap;
`;

const TopNav = ({ location, onClose }) => {
  const authUser = useContext(AuthUserContext);
  const { navLinks } = useContext(AppBrandingContext);
  const handleClose = () => {
    if (onClose) onClose();
  };

  return (
    <NavMenuWrapper id="top-nav">
      <NavMenuStyled
        mode="horizontal"
        menuData={getMenuDataWithChildren(navLinks, authUser)}
        location={location}
        openDefault={false}
        onLinkClick={handleClose}
        getPopupContainer={() => document.querySelector("#top-nav")}
      />
      <NavItemWrapper>
        <OrganizationSwitch />
        <AppNotificationsIndicator />
        <UserMenu />
      </NavItemWrapper>
    </NavMenuWrapper>
  );
};

TopNav.propTypes = {
  location: PropTypes.object,
  onClose: PropTypes.func,
};

export default TopNav;
