/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import { Slider as AntdSlider } from "antd";
import { useControls, useTransformEffect } from "react-zoom-pan-pinch";
import styled from "styled-components";

import { ReactComponent as ZoomOutIcon } from "../../assets/imgs/minus.svg";
import { ReactComponent as ZoomInIcon } from "../../assets/imgs/plus.svg";
import Colors from "../../themes/colors";
// eslint-disable-next-line import/no-cycle
import { DEFAULT_SCALE, MAX_SCALE, SCALE_STEP } from "./index";

const SliderWrapper = styled.div`
  position: absolute;
  z-index: 1;
  right: 5%;
  ${({ isSmartReceipt, revertControls }) =>
    isSmartReceipt
      ? `
  right: 45px;
  top: 90px;
  `
      : `
  top: ${revertControls ? "unset" : "3%"};
  bottom: ${revertControls ? "6.875rem" : "unset"};
  `}
  flex-direction: column;
  align-items: center;
  display: flex;
`;

const StyledSlider = styled(AntdSlider)`
  .ant-slider-track {
    background-color: ${Colors.link_blue} !important;
    width: 0.5rem;
  }

  .ant-slider-handle {
    border: solid 0.125rem ${Colors.link_blue} !important;
    width: 1.125rem;
    height: 1.125rem;
    margin-bottom: unset;
    cursor: ${({ isDragging }) => (isDragging ? "grabbing" : "pointer")};
  }

  .ant-slider-rail {
    background-color: ${Colors.medium_gray} !important;
    width: 0.5rem;
  }

  .ant-slider-step {
    width: 0.5rem;
  }

  padding: 0 0.125rem;
  height: 6.25rem;
  cursor: ${({ isDragging }) => (isDragging ? "grabbing" : "pointer")};
`;

const Slider = ({
  value,
  revertControls,
  onScaleChange,
  imageWasReplaced,
  isSmartReceipt = false,
}) => {
  const { centerView } = useControls();

  const [isDragging, setDragging] = useState(false);

  useEffect(() => {
    if (imageWasReplaced) {
      centerView(value, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageWasReplaced]);

  const onScaleChangeHandler = ({ state }) => {
    const { scale } = state;
    let decimal = 0;
    if (scale % 1 !== 0) {
      decimal = 1;
    }
    const updated = +(Math.round(scale * 2) / 2).toFixed(decimal);
    onScaleChange(updated);
  };

  const onChange = (scale) => {
    setDragging(true);
    centerView(scale);
    onScaleChange(scale);
  };

  const onAfterChange = () => setDragging(false);

  const zoomIn = () => {
    const futureScale = value + SCALE_STEP;
    if (futureScale <= MAX_SCALE) {
      centerView(futureScale);
      onScaleChange(futureScale);
    }
  };

  const zoomOut = () => {
    const futureScale = value - SCALE_STEP;
    if (futureScale >= DEFAULT_SCALE) {
      centerView(futureScale);
      onScaleChange(futureScale);
    }
  };

  useTransformEffect(onScaleChangeHandler);

  return (
    <SliderWrapper revertControls={revertControls} isSmartReceipt={isSmartReceipt}>
      <ZoomInIcon
        style={{
          opacity: value === MAX_SCALE ? 0.3 : 1,
          cursor: "pointer",
          marginBottom: "0.5rem",
        }}
        onClick={zoomIn}
      />
      <StyledSlider
        value={value}
        disabled={false}
        onChange={onChange}
        onAfterChange={onAfterChange}
        max={MAX_SCALE}
        step={SCALE_STEP}
        min={DEFAULT_SCALE}
        vertical
        tooltipVisible={false}
        isDragging={isDragging}
      />
      <ZoomOutIcon
        style={{
          opacity: value === DEFAULT_SCALE ? 0.3 : 1,
          cursor: "pointer",
          marginTop: "0.5rem",
        }}
        onClick={zoomOut}
      />
    </SliderWrapper>
  );
};

export default Slider;
