import styled, { css } from "styled-components";

import { Button, Icon } from "@core/ui-legacy";
import Colors from "@core/ui-legacy/themes/colors";

export const Container = styled.div<{
  clickable?: boolean;
  collapsed?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.125rem;
  cursor: ${({ clickable }) => (clickable ? "pointer" : "default")};
  margin-bottom: ${({ collapsed }) => (collapsed ? "0" : "1.5rem")};
  width: ${({ collapsed }) => collapsed && "3.125rem"};
`;

export const ButtonStyled = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 1.5rem);
  max-width: calc(100% - 1.5rem);
  height: 3rem;
  padding: 0.5rem 0.75rem;

  &:not(.ant-btn-background-ghost, [disabled]) {
    background-color: ${Colors.bg_gray} !important;
    border-radius: 0.25rem !important;
    border: 0.0625rem solid ${Colors.fog_gray} !important;
  }
`;

export const IconStyled = styled(Icon)<{ $dropdownVisible: boolean }>`
  color: ${Colors.placeholder_gray};
  font-size: 0.75rem;

  svg {
    transition: transform 0.3s;

    ${({ $dropdownVisible }) =>
      $dropdownVisible &&
      css`
        transform: rotate(180deg);
      `}
  }
`;
