import { Modal as AntModal } from "antd";
import styled from "styled-components";
import media from "styled-media-query";

import Colors from "../../themes/colors";
import { ButtonStyling } from "../Button";

const Modal = styled(AntModal)`
  .ant-modal-title {
    font-size: 1.125rem !important;
    font-weight: 700 !important;
  }

  .ant-modal-header {
    border-bottom: none !important;
    padding-bottom: 0.5rem;
  }

  .ant-modal-footer {
    padding: 1.5rem !important;
    border-top: none !important;
  }

  .ant-modal-body {
    padding: 0 1.5rem !important;
  }

  .ant-btn {
    height: 2.5rem !important;
    border-radius: 4px;
  }

  ${({ overloadModalButtonStyling, okButtonProps }) =>
    overloadModalButtonStyling
      ? `
    .ant-btn-${okButtonProps?.type} {
      ${ButtonStyling({ type: okButtonProps?.type })}
    }
  `
      : ""}

  ${({ overloadModalButtonStyling, cancelButtonProps }) =>
    overloadModalButtonStyling
      ? `
    .ant-btn-${cancelButtonProps?.type} {
      ${ButtonStyling({ type: cancelButtonProps?.type })}
    }
  `
      : ""}

  a.ant-btn {
    line-height: 2.5rem !important;
    margin-left: 0.5rem;
  }

  ${({ overloadModalButtonStyling }) =>
    overloadModalButtonStyling
      ? ""
      : `
    .ant-btn-cancel:not(.ant-btn-background-ghost, [disabled]) {
      border: 0 !important;
      background-color: ${Colors.fog_gray} !important;
      color: ${Colors.midnight} !important;
    }

    .ant-btn-cancel:active {
      opacity: 1 !important;
    }

    .ant-btn-cancel:focus {
      opacity: 0.7 !important;
    }

    .ant-btn-cancel:hover:not([disabled]) {
      opacity: 0.7 !important;
    }

    .ant-btn-danger:not(.ant-btn-background-ghost, :hover) {
      background-color: ${Colors.danger_red} !important;
      color: ${Colors.white} !important;
      border: 1px solid ${Colors.danger_red} !important;
    }

    .ant-btn-danger:focus {
      background-color: ${Colors.danger_red} !important;
      color: ${Colors.white} !important;
    }
  `}

  .sso-instructions-modal:not([disabled]) {
    background-color: transparent !important;
    max-height: 2.125rem;
  }

  .sso-input_with-addon input {
    height: 2.25rem;
    padding: 0.375rem 0.625rem 0.375rem 0.75rem;
  }

  .ant-modal-close {
    visibility: hidden !important;
    top: -0.125rem !important;
    ${media.lessThan("medium")`
         visibility: visible !important;
    `};
  }

  ${media.lessThan("medium")`
    height: 100% !important;
    max-width: 100% !important;
    width: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    top: 0 !important;

    .ant-modal-content {
      height: 100% !important;
      position: relative !important;
    }

    .ant-modal-footer {
      width: 100% !important;
      position: fixed !important;
      bottom: 0 !important;
      right: 0 !important;
      background: ${Colors.white} !important;
      border-top: 1px solid ${Colors.lunar_gray} !important;
    }
  `};
`;

const modalFn = {
  SUCCESS: Modal.success,
  ERROR: Modal.error,
  INFO: Modal.info,
  WARNING: Modal.warning,
};

const onModalFn = ({ type = "INFO", title, content, ...params }) => {
  const TYPE = type.toUpperCase();
  modalFn[TYPE]({
    ...params,
    title,
    content,
  });
};

Modal.fn = onModalFn;

Modal.defaultProps = {
  centered: true,
};

export default Modal;
