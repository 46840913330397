import { Button } from "../Button";
import { ScrollArea } from "../ScrollArea";
import { Separator } from "../Separator";
import { Text } from "../Text";
import { Drawer, DrawerClose, DrawerContent, DrawerFooter, DrawerHeader } from "./drawer";
import { StepIndicator } from "./SeptIndicator";

export type SidebarProps = {
  onClose: () => void;
  open: boolean;
  actionButtons?: React.ReactElement[];
  children?: React.ReactNode;
  currentStep?: number;
  description?: string;
  hideSeparator?: boolean;
  steps?: string[];
  title?: string;
};

export const Sidebar = ({
  title,
  description,
  children,
  steps = [],
  currentStep = 0,
  actionButtons,
  open,
  onClose,
  hideSeparator,
}: SidebarProps) => {
  const hasSteps = !!steps.length;
  return (
    <Drawer direction="right" open={open}>
      <DrawerContent className="max-h-screen px-10 pt-10">
        <DrawerHeader>
          <div className="flex items-center justify-between">
            {hasSteps ? (
              <div id="stepIndicator" className="flex-1">
                <StepIndicator currentStep={currentStep} steps={steps} />
              </div>
            ) : (
              <Text variant="h1" color="text-500" className="flex-1">
                {title}
              </Text>
            )}
            <DrawerClose asChild>
              <Button
                variant="secondary"
                size="medium"
                shape="circle"
                iconName="X"
                iconPosition="only"
                onClick={onClose}
              />
            </DrawerClose>
          </div>
        </DrawerHeader>
        <div className="flex flex-col space-y-[1.125rem] overflow-hidden pt-[1.125rem]">
          {hasSteps && (
            <Text variant="h1" color="text-500">
              {title}
            </Text>
          )}
          <div>
            {description?.split("\n").map((line) => (
              <Text color="text-500" key={line}>
                {line}
              </Text>
            ))}
          </div>
          <ScrollArea>{children}</ScrollArea>
        </div>
        <DrawerFooter className="flex flex-col">
          {hideSeparator ? null : <Separator color="neutrals-200" />}
          <div className="flex justify-between space-x-2 py-4">{actionButtons}</div>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default Sidebar;
