import gql from "graphql-tag";
import { useMutation, useQuery } from "react-apollo";

import { ADDRESS_FRAGMENT } from "../services/graphql/fragments";

export const GET_PAGE_CONTEXT = gql`
  query user($id: ID!) {
    user(id: $id) {
      ID
      fullName
      emailAddress
      phone
      avatarBackgroundColor
      billingAddress {
        ...AddressFragment
      }
    }
  }
  ${ADDRESS_FRAGMENT}
`;

export const UPDATE_USER_PHONE = gql`
  mutation updateUser($id: ID!, $input: UpdateUserInput!) {
    updateUser(id: $id, input: $input) {
      ID
      phone
    }
  }
`;

export const UPDATE_USER_COLOR = gql`
  mutation updateUser($id: ID!, $input: UpdateUserInput!) {
    updateUser(id: $id, input: $input) {
      ID
      avatarBackgroundColor
    }
  }
`;

export const useUpdatePhoneNumber = () => {
  const [updatePhoneNumber, { loading }] = useMutation(UPDATE_USER_PHONE, {
    awaitRefetchQueries: true,
  });

  return {
    updatePhoneNumber,
    loading,
  };
};

export const useUpdateUserColor = () => {
  const [updateUserColor, { loading }] = useMutation(UPDATE_USER_COLOR);

  return {
    updateUserColor,
    loading,
  };
};

export default (id) => {
  const { data, loading, error } = useQuery(GET_PAGE_CONTEXT, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    variables: { id },
  });

  return {
    data: data?.user || {},
    error,
    loading,
  };
};
