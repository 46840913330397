import { useCallback, useState } from "react";

import Storage from "../utils/storage";

// Add keys that use this hook here:

/**
 * Breadcrumb Data Structure
 * {
 *   1: { id: '111', label: 'Software' },
 *   2: { id: '222', label: 'Engineering' },
 *   3: { id: '333', label: 'Timothy Kim' },
 *   4: { id: '444', label: 'Acme' },
 * }
 */
export const KEY_INSIGHTS_BREADCRUMB = "insightsBreadcrumb";
export const DELEGATE_USER = "delegateUser";
export const KEY_ACTIONED_EXPENSE_ITEM = "expenseTableActionedItem";
export const KEY_SELECTED_EXPENSE_ITEM = "expenseTableSelectedItem";
export const EXPENSE_SORT_VALUE = "expenseTableSortValue";
export const MY_EXPENSES_PAGE_SORT_VALUE = "myExpensesPageSortValue";
export const CARD_DETAILS_CARD_REPLACE_COOLDOWN = "cardDetailsCardReplaceCooldown";

// Hook to storage data in session with fallback to localStorage. Based from
// code here: (https://usehooks.com/useLocalStorage/). We modified to use our
// own storage.js which has fallback handling, and changed the error logging.
const usePersistentStorage = <T>(
  key: string,
  initialValue: T,
): [T, (v: T | ((v: T) => T)) => void] => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      // Get from local storage by key
      const item = Storage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue

      console.error(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = useCallback(
    (value: T | ((v: T) => T)) => {
      try {
        // Allow value to be a function so we have same API as useState
        const valueToStore = value instanceof Function ? value(storedValue) : value;
        // Save state
        setStoredValue(valueToStore);
        // Save to local storage
        Storage.setItem(key, JSON.stringify(valueToStore));
      } catch (error) {
        // A more advanced implementation would handle the error case

        console.error(error);
      }
    },
    [key, storedValue],
  );

  return [storedValue, setValue];
};

export default usePersistentStorage;
