import { useCallback, useEffect, useMemo, useState } from "react";
import closeOutlined from "@iconify/icons-ant-design/close-outlined";
import { Icon } from "@iconify/react";
import gql from "graphql-tag";
import { debounce, groupBy, isArray, isEmpty, isEqual, isUndefined, uniqBy } from "lodash";
import PropTypes from "prop-types";
import { useApolloClient, useLazyQuery } from "react-apollo";
import styled from "styled-components";

import { Divider, Select } from "@core/ui-legacy";
import Colors from "@core/ui-legacy/themes/colors";

import { useIsMounted } from "../../common/useIsMounted";
import usePrevious from "../../common/usePrevious";
import API from "../../services/rest/api";
import { hasValue } from "../../utils";

const { Option, OptGroup } = Select;

const FAKE_QUERY = gql`
  {
    fakeQuery
  }
`;

const SelectStyled = styled(Select)`
  .ant-select-selection-selected-value .remove-btn {
    display: none;
  }
`;

const OptionItem = styled.span`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SelectAllDiv = styled.div`
  padding: 0.375rem 0.75rem;
  cursor: pointer;
  font-style: italic;

  :hover {
    background-color: #e6e6e6;
  }
`;

const RemoveIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  width: 16px;
  height: 16px;

  :hover {
    background: ${Colors.placeholder_gray};
  }
`;
const DividerStyled = styled(Divider)`
  margin: 0;
`;

const ActionOptionLabel = styled.span`
  font-weight: lighter;
  font-style: italic;
`;

const OptionValueSubText = styled.div`
  display: flex;
  flex-direction: column;
`;

const OptionSubText = styled.p`
  font-size: 0.75em;
  margin: 0;
  color: ${Colors.monumental};
`;

const OptionValue = styled.p`
  margin: 0;
`;

const NotFoundContentContainer = styled.div`
  display: flex;
  justify-content: center;

  :hover {
    cursor: default !important;
  }
`;

const NotFoundContent = styled.p`
  color: ${Colors.monumental};
  margin: 0;
  padding: 1.125rem 0.5rem;
`;

const getLengthOfFilteredOptions = (allOptions, key) => {
  const uniqOptions = uniqBy(allOptions, key);
  return uniqOptions?.length;
};

const DataSelector = ({
  value,
  size,
  children,
  query,
  variables,
  offset: initialOffset,
  totalValuesSize,
  fetchPolicy,
  nextFetchPolicy,
  queryDataField,
  allowClear,
  notFoundContentWithAction,
  groupKey,
  getOptions,
  filterOptionsKey,
  sort,
  mode,
  searchDisabled,
  serverSearchKey,
  caseSensitiveClientSearch,
  optionKey,
  optionValue,
  parentId,
  scrollableAreaId,
  onAddNew,
  refetchAfterSelect,
  refetchAfterRemove,
  resetOptionsOnUpdate,
  onSelect,
  onSearch,
  labelInValue,
  selectAllMode = false,
  selectAllOptions,
  actionOptions,
  withRemoveButton,
  onChange,
  onBlur,
  formatSubtext,
  onMouseLeavePopup,
  onMouseEnterPopup,
  isFocusOnPopup,
  initialValue,
  isServerMode,
  addNewButton,
  isGrouped,
  addNewOnBlur,
  trimSearchValue,
  customMenuRenderer,
  missingValueRetrieval,
  isNotCallEmptyQuery,
  disabled,
  ...selectProps
}) => {
  const client = useApolloClient();

  const [data, setData] = useState(null);

  const queryOptions = {
    fetchPolicy,
    nextFetchPolicy,
    notifyOnNetworkStatusChange: true,
  };
  // use query to server
  const [fetchValues, { loading, networkStatus, called, data: queryData, refetch }] = useLazyQuery(
    query,
    queryOptions,
  );

  useEffect(() => {
    setData(queryData);
  }, [queryData, setData]);

  // use state
  const [options, setOptions] = useState(children || []);
  const [searchValue, setSearchValue] = useState("");
  const [newItem, setNewItem] = useState(null);
  const [paginationOffset, setPaginationOffset] = useState(initialOffset);
  const [totalItems, setTotalItems] = useState(totalValuesSize || 0);
  const [totalMatches, setTotalMatches] = useState(totalValuesSize || 0);
  const [debouncing, setDebouncing] = useState(false);
  const [isDataFromProps, setIsDataFromProps] = useState(true);
  const prevChildren = usePrevious(children || []);
  const [clearSelection, setClearSelection] = useState(false);
  const prevVariables = usePrevious(variables);
  const prevSourceData = usePrevious(isDataFromProps);
  const isMounted = useIsMounted();
  const isLoading = debouncing || loading || networkStatus === 3 || networkStatus === 4; // fetchMore = 3, refetch = 4

  const response = useMemo(
    () => (isLoading ? null : data?.[queryDataField]),
    [data, isLoading, queryDataField],
  );

  // load values immediately if there are no children in props
  useEffect(() => {
    if (
      query !== FAKE_QUERY &&
      !called &&
      isEmpty(children) &&
      isEmpty(options) &&
      !isNotCallEmptyQuery
    ) {
      setIsDataFromProps(false);
      fetchValues({
        variables: {
          ...variables,
          offset: paginationOffset,
        },
      });
    }
  }, [
    query,
    called,
    children,
    fetchValues,
    variables,
    isLoading,
    options,
    paginationOffset,
    fetchPolicy,
    isNotCallEmptyQuery,
  ]);

  // use new item
  useEffect(() => {
    let hasNewItem = false;
    const preparedSearchValue = trimSearchValue ? searchValue?.trim() : searchValue;
    if (mode === "addNew" && preparedSearchValue) {
      if (caseSensitiveClientSearch) {
        hasNewItem = !options?.some((item) => item[optionValue] === preparedSearchValue);
      } else {
        const lowerCasedSearchValue = preparedSearchValue.toLowerCase();
        hasNewItem = !options?.some(
          (item) => item[optionValue]?.toLowerCase() === lowerCasedSearchValue,
        );
      }
    }
    setNewItem(
      hasNewItem
        ? {
            [optionKey]: preparedSearchValue,
            [optionValue]: preparedSearchValue,
            group: { name: "Add new" },
          }
        : null,
    );
  }, [
    mode,
    options,
    optionKey,
    optionValue,
    searchValue,
    trimSearchValue,
    caseSensitiveClientSearch,
  ]);

  useEffect(() => {
    if (!isEqual(prevVariables, variables) || !isEqual(prevChildren, children || [])) {
      setIsDataFromProps(true);
      setPaginationOffset(initialOffset);
      setOptions(children || []);
      setTotalItems(totalValuesSize);
      setTotalMatches(totalValuesSize);
      setData(null);
    }
  }, [children, prevChildren, initialOffset, prevVariables, totalValuesSize, variables]);
  // use options received from server

  const optionsUseEffectFn = useCallback(async () => {
    if (
      isEqual(prevVariables, variables) &&
      prevSourceData === isDataFromProps &&
      !isLoading &&
      response
    ) {
      // This if section solves IDs in the dropdown with no name if passed the proper props
      if (missingValueRetrieval?.query && response?.items) {
        // parse value
        let keys = [];
        try {
          if (Array.isArray(value)) {
            if (typeof value?.[0] === "object") {
              keys = value.map((val) => val[optionKey]);
            } else if (typeof value?.[0] === "string") {
              keys = value;
            }
          } else if (typeof value === "string") {
            keys = [value];
          }

          // check if the selected values are in the retrieved options already
          let keysNotInOptions = keys.filter(
            (key) => !options?.some((opt) => opt[optionKey] === key),
          );

          // if there are keys with missing data, retrieve their missing data with passed in query
          if (keysNotInOptions.length) {
            setDebouncing(true);
            keysNotInOptions = keysNotInOptions.map(async (key) => {
              const { data: missingData } = await client.query({
                query: missingValueRetrieval.query,
                variables: {
                  [missingValueRetrieval.variableKey]: key,
                },
                fetchPolicy: "network-only",
              });

              return missingData?.[missingValueRetrieval.resultKey];
            });

            const results = await Promise.allSettled(keysNotInOptions);

            keysNotInOptions = results
              .filter((res) => res.status === "fulfilled")
              .map((res) => res.value);
          }

          response.items = [...keysNotInOptions, ...response.items];
        } catch (err) {
          API.logError(API.LogLevel.error, {
            message: "failed trying to retrieve missing keys in dataselector component",
            details: {
              error: err,
            },
          });
        } finally {
          setDebouncing(false);
        }
      }

      const newOptions = getOptions(response);
      if (
        !resetOptionsOnUpdate &&
        newOptions?.some((x) => !options?.some((y) => y[optionKey] === x[optionKey]))
      ) {
        setOptions([...options, ...newOptions]);
        setPaginationOffset(response.pagination?.offset);
        setTotalMatches(response.pagination?.totalItems);
        if (!searchValue && response.pagination?.totalItems) {
          setTotalItems(response.pagination.totalItems);
        }
      }

      if (resetOptionsOnUpdate && !isEqual(newOptions, options)) {
        setOptions(newOptions);
      }
    }
  }, [
    resetOptionsOnUpdate,
    getOptions,
    isDataFromProps,
    isLoading,
    optionKey,
    options,
    prevSourceData,
    prevVariables,
    response,
    searchValue,
    variables,
    value,
    disabled,
  ]);

  useEffect(() => {
    void optionsUseEffectFn();
  }, [optionsUseEffectFn]);

  // determine search mode using props
  const searchMode = useMemo(() => {
    if (searchDisabled) {
      return "none";
    }

    if (isServerMode) {
      return "server";
    }

    const childrenLength = children?.length ?? 0;
    const total = totalItems || childrenLength;

    return childrenLength >= total || options?.length === total ? "client" : "server";
  }, [searchDisabled, isServerMode, children, totalItems, options]);

  const searchOnClient = (input, option) => {
    const v = withRemoveButton
      ? option?.props?.children[0]?.props.children
      : option?.props?.children;

    const preparedInput = trimSearchValue ? input.toLowerCase().trim() : input.toLowerCase();

    return !Array.isArray(v) && `${v}`.toLowerCase().includes(preparedInput);
  };

  const searchOnServer = useMemo(
    () =>
      debounce((input) => {
        setIsDataFromProps(false);
        fetchValues({
          variables: {
            ...variables,
            offset: null,
            [serverSearchKey]: input || null,
          },
        });
        // Wait for request to be sent
        setTimeout(() => setDebouncing(false), 0);
      }, 300),
    [fetchValues, variables, serverSearchKey],
  );

  const handleSearch = useCallback(
    (input) => {
      setSearchValue(input);
      if (searchMode === "server") {
        if (!isUndefined(isNotCallEmptyQuery) && !input) {
          return;
        }
        setOptions([]);
        setPaginationOffset(null);
        setDebouncing(true);
        searchOnServer(input);
        setData(null);
      }
      if (onSearch) {
        onSearch(input);
      }
    },
    [searchMode, searchOnServer, onSearch, isNotCallEmptyQuery],
  );

  const loadMoreItems = useMemo(
    () =>
      debounce(() => {
        // fecth more data only if there is more data
        const optionsLength = filterOptionsKey
          ? getLengthOfFilteredOptions(options, filterOptionsKey)
          : options.length;
        if (totalMatches > optionsLength) {
          setIsDataFromProps(false);
          fetchValues({
            variables: {
              ...variables,
              offset: paginationOffset,
              [serverSearchKey]: searchValue || null,
            },
          });
        }
      }, 300),
    [
      paginationOffset,
      totalMatches,
      options,
      fetchValues,
      variables,
      serverSearchKey,
      searchValue,
      filterOptionsKey,
    ],
  );

  const handleRefetch = useCallback(async () => {
    if (refetchAfterSelect && isMounted()) {
      setIsDataFromProps(false);
      await refetch({
        variables: {
          ...variables,
          offset: paginationOffset,
        },
      });
    }
  }, [refetchAfterSelect, isMounted, refetch, variables, paginationOffset]);

  const handleOnBlur = async (e) => {
    if (onBlur) {
      await onBlur(e, searchValue);
    }
    if (mode === "multiple" && searchValue && query !== FAKE_QUERY) {
      handleSearch("");
    }
  };

  const handleSelect = useCallback(
    async (selectedItem) => {
      if (newItem && newItem[optionKey] === selectedItem && newItem[optionValue] === selectedItem) {
        if (onAddNew) await onAddNew(selectedItem, options);
        setNewItem(null);
      } else if (onSelect) await onSelect(selectedItem, options);

      if (selectedItem !== initialValue) {
        await handleRefetch();
      }
    },
    [handleRefetch, initialValue, newItem, onAddNew, onSelect, optionKey, optionValue, options],
  );

  const handleSelectAll = useCallback(() => {
    let areAllOptionsSelected = false;
    let selectedArray = [];
    if (selectAllOptions?.length) {
      setOptions(selectAllOptions);
      areAllOptionsSelected = value?.length === selectAllOptions?.length;
      selectedArray = areAllOptionsSelected ? [] : selectAllOptions.map((item) => item.ID);
    } else {
      areAllOptionsSelected = value?.length === options?.length;
      selectedArray = areAllOptionsSelected ? [] : options.map((item) => item.ID);
    }

    if (onChange) onChange(selectedArray);
    if (!selectAllOptions?.length) handleSearch("");
    setClearSelection(areAllOptionsSelected);
  }, [handleSearch, onChange, options, value, selectAllOptions]);

  const mapOptions = (items) => {
    const optionKeys = items.map((opt) => opt[optionKey]);
    return items
      .filter((opt, index) => optionKeys.indexOf(opt[optionKey]) === index)
      .map((opt) => {
        if (formatSubtext && !opt.subText) opt.subText = formatSubtext(opt);
        if (customMenuRenderer) {
          const { Renderer, isDisabled } = customMenuRenderer;
          return (
            <Option disabled={isDisabled(opt)} key={opt[optionKey]}>
              <Renderer item={opt} />
            </Option>
          );
        }
        return (
          <Option
            key={opt[optionKey]}
            title={opt[optionValue]}
            disabled={opt.disabled}
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "space-between",
              alignItems: "center",
            }}
          >
            {renderOption(opt)}
          </Option>
        );
      });
  };

  const renderOption = (opt) => {
    if (opt.subText) {
      return `${opt[optionValue]} - ${opt.subText}`;
    }

    if (withRemoveButton) {
      return [
        <OptionItem key={`${opt[optionKey]}-text`}>{opt[optionValue]}</OptionItem>,
        opt.withRemoveButton && (
          <RemoveIcon
            key={`${opt[optionKey]}-icon`}
            className="remove-btn"
            onClick={async (e) => {
              e.stopPropagation();
              await opt.onRemove(opt);
              if (refetchAfterRemove) {
                setIsDataFromProps(false);
                await handleRefetch();
              }
            }}
          >
            <Icon color={Colors.classic_blue} width="7.55px" height="7.54px" icon={closeOutlined} />
          </RemoveIcon>
        ),
      ];
    }

    return opt[optionValue];
  };

  const mapActionOptions = (items) =>
    items.map(({ label, onSelect: onActionSelect, ...rest }, index, { length }) => (
      <Option
        key={label}
        title={label}
        onClick={onActionSelect}
        className={`action-item ${index + 1 === length ? "last" : ""}`}
        {...rest}
      >
        <ActionOptionLabel>{label}</ActionOptionLabel>
      </Option>
    ));

  const renderOptions = () => {
    // add new option
    let allOptions = newItem ? [newItem, ...options] : options;

    allOptions = uniqBy(allOptions, optionKey);
    if (groupKey) {
      const groups = groupBy(allOptions, groupKey);
      return Object.keys(groups).map((key) => (
        <OptGroup key={key} label={key}>
          {mapOptions(groups[key])}
        </OptGroup>
      ));
    }

    if (sort) {
      allOptions = sort(allOptions);
    }

    allOptions = mapOptions(allOptions);

    if (!loading && isArray(actionOptions) && !isEmpty(actionOptions)) {
      allOptions.unshift(...mapActionOptions(actionOptions));
    }

    // renders not found content in cases where only options are action options
    if (allOptions.length === actionOptions?.length && notFoundContentWithAction) {
      allOptions.unshift(
        <Option
          key="not-found-content-with-action"
          title="no-found-content-with-action"
          className="no-found-content-with-action"
          disabled
        >
          <NotFoundContentContainer>
            <NotFoundContent>{notFoundContentWithAction}</NotFoundContent>
          </NotFoundContentContainer>
        </Option>,
      );
    }

    return allOptions;
  };

  const formatValue = (v) => {
    if (!v) {
      return null;
    }

    if (labelInValue) {
      return v;
    }

    let key = v;
    if (v[optionKey]) {
      key = v[optionKey];
    }

    // If key is not in the options list, return value instead
    if (!options?.some((opt) => opt[optionKey] === key)) {
      key = v[optionValue] ?? v;
    }

    if (typeof key === "string") {
      return key;
    }

    if (mode === "addNew") {
      return undefined;
    }

    return null;
  };

  const getValue = () => {
    if (typeof value === "function") {
      return value(options);
    }

    if (!hasValue(value)) {
      return [];
    }

    if (isArray(value)) {
      return value.map(formatValue).filter(Boolean);
    }

    return formatValue(value);
  };

  // Forcing mode to default if it's set to addNew so that antd select won't complain
  const modeProp = mode === "addNew" ? "default" : mode;
  return (
    <SelectStyled
      labelInValue={labelInValue}
      dropdownStyle={{ padding: 0 }}
      {...selectProps}
      disabled={disabled}
      onChange={async (...args) => {
        if (onChange) {
          await onChange(...args);
        }

        // handle case when user clears selection via clear button
        if (allowClear && !args[0]) {
          await handleSelect(undefined);
        }

        // reset search results
        if (searchValue) {
          handleSearch("");
        }
      }}
      onBlur={handleOnBlur}
      size={size}
      mode={modeProp}
      loading={isLoading || selectProps.loading}
      value={getValue()}
      allowClear={allowClear}
      showSearch={!searchDisabled}
      onSearch={handleSearch}
      onSelect={handleSelect}
      loadMoreItems={loadMoreItems}
      clearSelection={clearSelection}
      // getPopupContainer breaks the transfer of focus to next Form.Item, using a Tab. Switching to 4.x antd version should fix this issue.
      // getPopupContainer={trigger => trigger.parentNode}
      getScrollableArea={scrollableAreaId && (() => document.getElementById(scrollableAreaId))}
      filterOption={searchMode === "client" && !onSearch && searchOnClient}
      isFocusOnPopup={isFocusOnPopup}
      initialValue={initialValue}
      optionKey={optionKey}
      isGrouped={isGrouped}
      addNewOnBlur={addNewOnBlur}
      dropdownRender={(menu) => (
        <div
          onMouseLeave={() => {
            if (onMouseLeavePopup) onMouseLeavePopup();
          }}
          onMouseEnter={() => {
            if (onMouseEnterPopup) onMouseEnterPopup();
          }}
        >
          {selectAllMode && !searchValue && (
            <>
              <SelectAllDiv onMouseDown={(e) => e.preventDefault()} onClick={handleSelectAll}>
                {value?.length === options?.length ? "Unselect all" : "Select all"}
              </SelectAllDiv>
              <DividerStyled />
            </>
          )}
          {menu}
          {addNewButton && searchValue && addNewButton(searchValue, options)}
        </div>
      )}
    >
      {renderOptions()}
    </SelectStyled>
  );
};

DataSelector.propTypes = {
  value: PropTypes.any,
  size: PropTypes.string,
  children: PropTypes.any,
  query: PropTypes.any,
  missingValueRetrieval: PropTypes.shape({
    query: PropTypes.any,
    variableKey: PropTypes.string,
    resultKey: PropTypes.string,
  }),
  variables: PropTypes.object,
  offset: PropTypes.string,
  totalValuesSize: PropTypes.number,
  fetchPolicy: PropTypes.string,
  nextFetchPolicy: PropTypes.string,
  queryDataField: PropTypes.string,
  allowClear: PropTypes.bool,
  notFoundContentWithAction: PropTypes.string,
  groupKey: PropTypes.string,
  getOptions: PropTypes.func,
  sort: PropTypes.func,
  mode: PropTypes.oneOf(["default", "multiple", "tags", "addNew"]),
  searchDisabled: PropTypes.bool,
  serverSearchKey: PropTypes.string,
  caseSensitiveClientSearch: PropTypes.bool,
  optionKey: PropTypes.string,
  optionValue: PropTypes.string,
  parentId: PropTypes.string,
  scrollableAreaId: PropTypes.string,
  onAddNew: PropTypes.func,
  refetchAfterSelect: PropTypes.bool,
  refetchAfterRemove: PropTypes.bool,
  withRemoveButton: PropTypes.bool,
  onSelect: PropTypes.func,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  onBlur: PropTypes.func,
  formatSubtext: PropTypes.func,
  labelInValue: PropTypes.bool,
  isServerMode: PropTypes.bool,
  loadMoreItemsOffset: PropTypes.number,
  selectAllMode: PropTypes.bool,
  actionOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any,
      disabled: PropTypes.bool,
      onSelect: PropTypes.func,
    }),
  ),
  selectAllOptions: PropTypes.any,
  addNewButton: PropTypes.func,
  onMouseLeavePopup: PropTypes.func,
  onMouseEnterPopup: PropTypes.func,
  isFocusOnPopup: PropTypes.object,
  initialValue: PropTypes.any,
  isGrouped: PropTypes.bool,
  addNewOnBlur: PropTypes.bool,
  trimSearchValue: PropTypes.bool,
  filterOptionsKey: PropTypes.string,
  customMenuRenderer: PropTypes.shape({
    Renderer: PropTypes.func.isRequired,
    isDisabled: PropTypes.func.isRequired,
  }),
  CustomMenuRenderer: PropTypes.func,
  isNotCallEmptyQuery: PropTypes.bool,
  resetOptionsOnUpdate: PropTypes.bool,
  disabled: PropTypes.bool,
};

DataSelector.defaultProps = {
  mode: "default",
  optionKey: "ID",
  optionValue: "name",
  searchDisabled: false,
  caseSensitiveClientSearch: true,
  refetchAfterSelect: false,
  refetchAfterRemove: false,
  withRemoveButton: false,
  query: FAKE_QUERY,
  getOptions: (x) => (isArray(x) ? x : x?.items),
  loadMoreItemsOffset: 25,
  isGrouped: false,
  addNewOnBlur: false,
  trimSearchValue: false,
  resetOptionsOnUpdate: false,
};

export default DataSelector;
