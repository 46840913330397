import { memo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Colors from "../../themes/colors";
import { styleConstructor } from "../../utils";

const KpiLabelStyled = styled.div`
  color: ${Colors.monumental};
  ${({ styling }) => styling && styleConstructor(styling)};
`;

const KpiLabel = ({ className, label, styling }) => (
  <KpiLabelStyled className={className} styling={styling}>
    {label}
  </KpiLabelStyled>
);

KpiLabel.propTypes = {
  className: PropTypes.string,
  label: PropTypes.any,
  styling: PropTypes.object,
};

export default memo(KpiLabel);
