import { useCallback, type Dispatch, type SetStateAction } from "react";

import { Badge, Icon, Switch, Text } from "@core/ui";

import type { AddressInput, AddressInputErrors, ShippingPreferences } from "../../types";
import { AddressForm } from "./AddressForm";

type ShippingPreferenceProps = {
  confirm: boolean;
  errors: AddressInputErrors;
  formData: AddressInput;
  handleUsedAutocomplete: (boolean) => void;
  preference: ShippingPreferences;
  setConfirm: Dispatch<SetStateAction<boolean>>;
  setErrors: Dispatch<React.SetStateAction<AddressInputErrors>>;
  setFormData: Dispatch<SetStateAction<AddressInput>>;
  setPreference: Dispatch<SetStateAction<ShippingPreferences>>;
};

export const ShippingPreference = ({
  confirm,
  setConfirm,
  preference,
  setPreference,
  formData,
  setFormData,
  errors,
  setErrors,
  handleUsedAutocomplete,
}: ShippingPreferenceProps) => {
  const handlePreferenceSelect = useCallback(
    (type: ShippingPreferences) => () => {
      setPreference(type);
    },
    [setPreference],
  );

  const handleAllowVerification = useCallback(() => {
    setPreference((prev) => {
      if (prev === "individual_unverified") return "individual_verified";
      return "individual_unverified";
    });
  }, [setPreference]);

  const isIndividual = preference !== "central";

  return (
    <>
      <div
        role="checkbox"
        aria-checked={isIndividual}
        tabIndex={0}
        onClick={handlePreferenceSelect("individual_unverified")}
        onKeyDown={handlePreferenceSelect("individual_unverified")}
        className={`grid cursor-pointer grid-cols-[auto,auto,auto] gap-3 rounded border-[1.5px] border-solid ${
          isIndividual ? "border-[#007AE0]" : "border-[#F0F0F0]"
        } px-3 py-4`}
      >
        <Icon
          className="h-auto w-min"
          background="transparent"
          color="[#212121]"
          name="UserRound"
        />
        <div>
          <Text>Mail cards individually to cardholder</Text>
          <Text variant="subtext" className="text-[#606060]">
            Members will be asked to verify addresses and you will be able to review changes before
            the cards ship directly to them.
          </Text>
        </div>
        <Badge text="Default" variant="primary" className="min-w-[4.3rem] self-start" />
      </div>
      <div
        role="checkbox"
        aria-checked={!isIndividual}
        tabIndex={-1}
        onClick={handlePreferenceSelect("central")}
        onKeyDown={handlePreferenceSelect("central")}
        className={`mt-3 grid cursor-pointer grid-cols-[auto,6fr,1fr] gap-3 rounded border-[1.5px] border-solid ${
          isIndividual ? "border-[#F0F0F0]" : "border-[#007AE0]"
        } px-3 py-4`}
      >
        <Icon
          className="h-auto w-min"
          background="transparent"
          color="[#212121]"
          name="Building2"
        />
        <div>
          <Text>Ship in bulk to one address</Text>
          <Text variant="subtext" className="text-[#606060]">
            Set a central address for all cards to be sent to. Cards can then be distributed as you
            see fit.
          </Text>
        </div>
      </div>
      {isIndividual ? (
        <div className="mt-6 grid grid-cols-[12fr,1fr] gap-2 rounded-lg border-[1.5px] border-solid border-[#F0F0F0] bg-[#F8F8F8] px-4 py-3">
          <div>
            <Text>Allow spenders to verify their own address</Text>
            <Text variant="subtext" className="text-[#606060]">
              Members will be asked to verify their shipping addresses <br /> and input their new
              address if it has changed.
            </Text>
          </div>
          <div className="self-center justify-self-end">
            <Switch
              checked={preference === "individual_verified"}
              onCheckedChange={handleAllowVerification}
            />
          </div>
        </div>
      ) : (
        <>
          <Text weight="bold" variant="h2" className="mt-6 leading-6">
            Is this address correct?
          </Text>
          <AddressForm
            formData={formData}
            setFormData={setFormData}
            errors={errors}
            setErrors={setErrors}
            handleUsedAutocomplete={handleUsedAutocomplete}
          />
        </>
      )}
      <div className="mt-4 grid grid-cols-[auto,auto] gap-2 rounded-lg border-[1.5px] border-solid border-[#F0F0F0] bg-[#F8F8F8] px-4 py-3">
        <Text>
          I confirm my shipping preference and am ready to start the rest of the card renewal
          process.
        </Text>
        <Switch checked={confirm} onCheckedChange={setConfirm} />
      </div>
    </>
  );
};
