import PropTypes from "prop-types";
import styled from "styled-components";

import AlertIcon from "../../assets/imgs/alert-circle_fill.svg";
import CheckIcon from "../../assets/imgs/check-circle_fill.svg";
import EditIcon from "../../assets/imgs/file-edit.svg";
import Colors from "../../themes/colors";
import { styleConstructor } from "../../utils";
import Tooltip from "../Tooltip";

const Label = styled.label`
  line-height: 1.25rem;
  color: ${Colors.midnight};
  ${({ styling }) => styling && styleConstructor(styling)};
`;

const Text = styled.div`
  display: block;
  font-size: 1.125rem;
  font-weight: 700;
  color: ${Colors.midnight};
  ${({ styling }) => styling && styleConstructor(styling)};
`;

const OverlineLabelStyled = styled.div`
  display: block;
  text-align: ${({ align }) => align};
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const iconMap = {
  EditIcon,
  CheckIcon,
  AlertIcon,
};

const OverlineLabel = (props) => {
  const { label, text, align, invert, styleLabel, styleText, tooltip, ...rest } = props;

  const labelId = typeof label === "string" ? label : null;
  return invert ? (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <OverlineLabelStyled align={align} {...rest}>
      <Text id={labelId} aria-label={label} styling={styleText}>
        {text}
      </Text>
      <Label htmlFor={labelId} styling={styleLabel}>
        {label}
      </Label>
    </OverlineLabelStyled>
  ) : (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <OverlineLabelStyled align={align} {...rest}>
      <LabelWrapper>
        <Label htmlFor={labelId} styling={styleLabel}>
          {label}
        </Label>
        {tooltip && !!Object.keys(tooltip).length && (
          <Tooltip {...{ title: tooltip.title }}>
            {tooltip.option?.type && (
              <img
                src={iconMap[tooltip.option?.type] ?? AlertIcon}
                alt={tooltip.option?.type}
                className="size-[1.125rem]"
              />
            )}
          </Tooltip>
        )}
      </LabelWrapper>
      <Text id={labelId} aria-label={label} styling={styleText}>
        {text}
      </Text>
    </OverlineLabelStyled>
  );
};

OverlineLabel.propTypes = {
  label: PropTypes.any,
  text: PropTypes.any,
  align: PropTypes.oneOf(["left", "center", "right"]),
  invert: PropTypes.bool,
  styleLabel: PropTypes.object,
  styleText: PropTypes.object,
  tooltip: PropTypes.object,
};

OverlineLabel.defaultProps = {
  align: "left",
  invert: false,
};

OverlineLabel.Label = Label;
OverlineLabel.Text = Text;

export default OverlineLabel;
