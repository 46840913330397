import { Empty as AntEmpty } from "antd";
import PropTypes from "prop-types";
import styled from "styled-components";

import { ReactComponent as EmptyStateImage } from "../../assets/imgs/empty-state-icon.svg";
import Colors from "../../themes/colors";
import { styleConstructor } from "../../utils";

const ErrorStyled = styled(AntEmpty)`
  color: ${Colors.monumental} !important;

  .ant-empty-image {
    margin: 0 !important;
    height: auto !important;
    padding-bottom: 0.75rem !important;

    img {
      width: 1.875rem !important;
      height: auto !important;
    }
  }
  ${({ styling }) => styleConstructor(styling)};
`;

const Empty = ({ description, image, alt, styling, children, ...rest }) => {
  const renderImage = () => {
    if (typeof image === "string") {
      return <img src={image} alt={alt} />;
    }
    return image;
  };
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <ErrorStyled description={description} image={renderImage()} styling={styling} {...rest}>
      {children}
    </ErrorStyled>
  );
};

Empty.propTypes = {
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  alt: PropTypes.string,
  styling: PropTypes.object,
  children: PropTypes.any,
};

Empty.defaultProps = {
  description: "No data",
  image: <EmptyStateImage />,
};

export default Empty;
