import { apiUrlToEnv, graphQlApiandKeys, slotEnvConfig } from "@core/ui-legacy/utils/apiSlotConfig";

const APPSYNC_SLOT_KEY = "appsync-slot";
const CLOUD_SLOT_KEY = "cloud-slot";

const env = apiUrlToEnv[import.meta.env.REACT_APP_BUILD_API];

const getSlotEnv = (slot) => `${slot}-${env}`;
const getStoredSlotEnv = (key) => getSlotEnv(window.sessionStorage.getItem(key));
const stripEnvFromSlot = (slot) => slot?.replace(`-${env}`, "");

const [, pathCloudSlot, pathAppsyncSlot] = window.location.pathname.split("/");
const pathCloudSlotData = slotEnvConfig[getSlotEnv(pathCloudSlot)];
const pathAppsyncSlotData = graphQlApiandKeys[getSlotEnv(pathAppsyncSlot)];

if (pathCloudSlotData || pathAppsyncSlotData) {
  window.sessionStorage.removeItem(CLOUD_SLOT_KEY);
  window.sessionStorage.removeItem(APPSYNC_SLOT_KEY);

  let url = window.location.origin;
  let slots = `/${pathCloudSlot}`;

  slots = pathAppsyncSlotData ? `/${pathCloudSlot}/${pathAppsyncSlot}` : slots;

  url += window.location.href.replace(url, "").replace(slots, "");

  window.history.replaceState(null, document.title, url);

  if (pathCloudSlotData) {
    window.sessionStorage.setItem(CLOUD_SLOT_KEY, pathCloudSlot);
  }

  if (pathAppsyncSlotData) {
    window.sessionStorage.setItem(APPSYNC_SLOT_KEY, pathAppsyncSlot);
  }
}

const storedAppsyncSlot = getStoredSlotEnv(APPSYNC_SLOT_KEY);
const [appsyncSlot, appsyncSlotData] =
  Object.entries(graphQlApiandKeys).find(([gqlSlot]) => gqlSlot === storedAppsyncSlot) ?? [];

export const aSlot = stripEnvFromSlot(appsyncSlot);
export const appsyncSlotResource = appsyncSlotData;

const storedCloudSLot = getStoredSlotEnv(CLOUD_SLOT_KEY);
const [cloudSlot, cloudSlotData] =
  Object.entries(slotEnvConfig).find(([apiSlot]) => apiSlot === storedCloudSLot) ?? [];

export const slot = stripEnvFromSlot(cloudSlot);
export default cloudSlotData;
