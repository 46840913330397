import { useEffect, useMemo, useState } from "react";
import type { QueryOptions } from "apollo-client";
import { useApolloClient } from "react-apollo";
import { useLocation } from "react-router-dom";

import { GET_CARD_REQUESTS, GET_EXPENSES } from "../services/graphql/queries";
import { CARD_REQUEST_STATUS } from "../utils/constants";
import {
  getSideNavDataForLocation,
  MY_APPROVALS_CARDS_ID,
  MY_APPROVALS_EXPENSES_ID,
  type SideNavItem,
} from "./sideNavMenu";

const menuItemIDToQueryOptions = () =>
  ({
    [MY_APPROVALS_CARDS_ID]: {
      query: GET_CARD_REQUESTS,
      variables: {
        statuses: [CARD_REQUEST_STATUS.IN_REVIEW],
      },
    },
    [MY_APPROVALS_EXPENSES_ID]: {
      query: GET_EXPENSES,
      variables: {
        approvalStatus: "pending",
        filter: "status[eq]:submitted;isTransactionSettled[eq]:true",
        limit: 50,
        offset: null,
        prioritizeRole: "EA",
        sort: null,
      },
    },
  }) satisfies Record<string, QueryOptions>;

const menuItemIDToTotalItemsMapper = () => ({
  [MY_APPROVALS_CARDS_ID]: (data) => data.cardRequests.filter((cr) => !cr.processedAt).length,
  [MY_APPROVALS_EXPENSES_ID]: (data) => data.expenses.pagination.totalItems,
});

const useTotalItemsByMenuItemID = (menuData) => {
  const client = useApolloClient();
  const [totalItemsByMenuItemID, setTotalItemsByMenuItemID] = useState<Record<string, number>>({});

  useEffect(() => {
    const subscriptions = menuData
      .map(({ id }) => [id, menuItemIDToQueryOptions()[id]])
      .filter(([, options]) => !!options)
      .map(([id, options]) =>
        client.watchQuery(options).subscribe({
          next: ({ data }) => {
            const mapper = menuItemIDToTotalItemsMapper()[id];
            setTotalItemsByMenuItemID((prevState) => ({
              ...prevState,
              [id]: mapper(data),
            }));
          },
          error: () => {
            setTotalItemsByMenuItemID((prevState) => ({
              ...prevState,
              [id]: 0,
            }));
          },
        }),
      );

    return () => {
      subscriptions.forEach((subscription) => subscription.unsubscribe());
    };
  }, [client, menuData]);

  return totalItemsByMenuItemID;
};

const useSideNavMenuData = (): (SideNavItem & { totalItems?: number })[] => {
  const location = useLocation();
  const baseData = useMemo(() => getSideNavDataForLocation(location), [location]);

  const totalItemsByMenuItemID = useTotalItemsByMenuItemID(baseData);

  return useMemo(
    () =>
      baseData.map((item) => {
        const totalItems = totalItemsByMenuItemID[item.id];
        if (totalItems) {
          return {
            ...item,
            totalItems,
          };
        }

        return item;
      }),
    [baseData, totalItemsByMenuItemID],
  );
};

export default useSideNavMenuData;
