import { useContext } from "react";

import { Button, Modal, Text } from "@core/ui";

import { ReactComponent as SuccessIcon } from "../../../assets/imgs/success-fill.svg";
import { CardExpirationContext } from "../../../common/contexts/CardExpirationProvider";

const text =
  "A member of our support team will reach out to confirm the correct address to send your replacement cards to.";

export const ThanksModal = () => {
  const { showThanksModal, setShowThanksModal } = useContext(CardExpirationContext);
  const onClose = () => {
    setShowThanksModal("hide");
  };

  return (
    <Modal
      open={showThanksModal !== "hide"}
      leadingIcon={<SuccessIcon />}
      iconPosition="center"
      closeModal={() => {}} // User needs to take action
      actionButtons={[
        <Button key="Close" text="Close" size="large" variant="primary" onClick={onClose} />,
      ]}
      className="min-h-[19.125rem] min-w-[36.85rem] auto-rows-min content-end gap-4 pt-[2rem]"
    >
      <div className="flex justify-center">
        <Text variant="h2" weight="bold">
          Thanks for letting us know!
        </Text>
      </div>
      <div className="mb-[3rem] mt-[1rem] ">
        {showThanksModal === "showWithText" && <Text>{text}</Text>}
      </div>
    </Modal>
  );
};
