import { useContext, useEffect, useState } from "react";
import { noop } from "lodash";
import type { Channel } from "pusher-js";

import { PusherContext } from "../PusherContext";

export const channels = {
  expenseBulkActions: "private-;orgID=:orgID;slot=:slot;channel=bulkAction;entityType=expense",
  hrisOrgActions: "private-;orgID=:orgID;entityType=hris;",
} as const;

export const events = {
  bulkActionInfoUpdated: "bulkActionInfoUpdated",
  bulkActionExpenseLockUpdated: "bulkActionExpenseLockUpdated",
  initialSyncComplete: "initialSyncComplete",
  onboardingComplete: "onboardingComplete",
  deactivatingComplete: "deactivatingComplete",
  orgCfgCreated: "orgCfgCreated",
} as const;

type ChannelKeys = keyof typeof channels;

type SubscribedChannel = Channel & {
  bind: (event: keyof typeof events, callback: () => void) => void;
};
type Channels = typeof channels;

type ChannelValue = Channels[keyof Channels];

// This allows us to build a union of options based on the channel string
// that can be of variable length
type Options<T> = Record<
  T extends `${string}:${infer K1};${string}:${infer K2};${string}:${infer K3};${string}`
    ? K1 | K2 | K3
    : T extends `${string}:${infer K1};${string}:${infer K2};${string}:${infer K3}`
    ? K1 | K2 | K3
    : T extends `${string}:${infer K4};${string}:${infer K5};${string}`
    ? K4 | K5
    : T extends `${string}:${infer K4};${string}:${infer K5}`
    ? K4 | K5
    : T extends `${string}:${infer K6};${string}`
    ? K6
    : T extends `${string}:${infer K6}`
    ? K6
    : never,
  string
>;

export const generateChannel = <T extends ChannelValue>(channel: T, options: Options<T>) =>
  channel.split(";").reduce<string>((acc, part) => {
    const [, key] = part.split(":");
    return key ? acc.replace(`:${key}`, options[key as keyof typeof options]) : acc;
  }, channel);

export const useChannel = <T extends ChannelKeys>(
  channelKey: T,
  {
    unsubscribeOnUnmount = true,
    variables,
    isEnabled = true,
  }: {
    isEnabled: boolean;
    variables: Options<Channels[T]>;
    unsubscribeOnUnmount?: boolean;
  },
): SubscribedChannel => {
  const pusher = useContext(PusherContext);
  const channelString = channels[channelKey];
  const channel = generateChannel(channelString, variables);
  const [pusherSubscription, setPusherSubscription] = useState<SubscribedChannel>();

  useEffect(() => {
    if (!pusher) return noop;
    setPusherSubscription(pusher?.subscribe?.(channel));

    return () => {
      if (!unsubscribeOnUnmount) return;
      pusher?.unsubscribe(channel);
      setPusherSubscription(undefined);
    };
  }, [channel, pusher, unsubscribeOnUnmount]);

  const returnNoop = !isEnabled || !pusherSubscription || !pusher;
  if (returnNoop) {
    return { bind: noop, unbind_all: noop } as SubscribedChannel;
  }

  const proxySubscription = new Proxy(pusherSubscription, {
    get: (target: SubscribedChannel, prop: keyof SubscribedChannel) => {
      if (prop === "bind") {
        // Intercept bind and replace canonical event name with the pusher event name
        return (...args: [string, () => void]) => {
          const [eventKey, callback] = args;
          const event = events[eventKey];
          const result = target[prop](event, callback);
          return result;
        };
      }
      // If not accessing bind, return the property as is
      return target[prop];
    },
  });

  return proxySubscription;
};
