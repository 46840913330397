export type Action =
  | "addNote"
  | "approve"
  | "assignApprover"
  | "assignDelegate"
  | "autoApprove"
  | "decrease"
  | "flagExpense"
  | "reject"
  | "sendBack"
  | "sendToSlack"
  | "setApprover"
  | "setField"
  | "set";
// todo: setMap

export type LetterCase = "upper" | "lower" | "capitalize";

export enum LogicalOperator {
  All = "all",
  Any = "any",
  None = "none",
}

export enum ConditionOperator {
  LT = "lt",
  LE = "le",
  EQ = "eq",
  GE = "ge",
  GT = "gt",
  NEQ = "neq",
  OF = "of",
  NOF = "nof",
  GTE = "gte",
  LTE = "lte",
  LEN = "length",
  DAF = "daysAfter",
  DIN = "daysWithin",
  EXISTS = "exists",
  NOTEXISTS = "notexists",
  SW = "startsWith",
  RX = "regex",
  INCLUDES = "includes",
  NOTINCLUDES = "notincludes",
  CONTAINSENTRY = "containsentry", // key-value pair in any object in a list is found
  NOTCONTAINSENTRY = "notcontainsentry", // key-value pair in any object in a list is NOT found
  INOBJSOFLIST = "inObjectOfList", // key is on _every_ object on the list
  NINOBJSOFLIST = "notInObjectsOfList", // key is NOT found on _every_ object on the list
}

export type NullableValue<Type> = Type | null;

export enum RuleStatus {
  Active = "active",
  InActive = "inactive",
}

export type IOperatorDefinition = {
  ID: ConditionOperator;
  evaluate: (entity: any, ruleActionParams: any) => boolean;
  getText: (ruleActionParams?: any) => string;
  text: string;
};

export type IActionDefinition = {
  ID: string;
  execute: (entity: any, ruleActionParams: any) => Promise<void>;
  getText: (ruleActionParams?: any) => string;
  text: string;
};

export type IAction = {
  [key: string]: any;
  operator: string;
  property?: string;
  value?: string | number | boolean;
};

export type IDescribedCondition = {
  operator: ConditionOperator;
  property: IDescribedValue;
  value: IDescribedValue<string | number | boolean | string[]>;
};

export type ICondition = {
  operator: ConditionOperator;
  property: string;
  value: string | number | boolean | string[] | object;
  isExclusion?: boolean;
};

export type IDescribedEvaluation = {
  conditions: IDescribedCondition[];
  if: LogicalOperator;
  actions?: IAction[];
  description?: string;
  isCenterManaged?: boolean;
};

export type IEvaluation = {
  conditions: ICondition[];
  if: NullableValue<LogicalOperator>;
  actions?: IAction[];
  isCenterManaged?: boolean;
};

export type IBaseRule<RuleType> = {
  evaluation: IEvaluation;
  type: NullableValue<RuleType>;
  description?: NullableValue<string>;
};

export type IConditionInput = {
  field: string;
  fieldLabel: string;
  operator: ConditionOperator;
  value: string | number;
  valueLabel: string;
  isExclusion?: boolean;
};

export type IDescribedValue<ValueType = string | number> = {
  description: string;
  value: ValueType;
};

export type IActionInputBase<OperatorType> = {
  field: string;
  fieldLabel: string;
  operator: OperatorType;
  value: string | number | boolean;
  valueLabel: string;
};

export type IActionInputValue<ValueType> = {
  value: string;
  valueLabel: string;
  valueType: ValueType;
};

export type IActionInput<ValueType> = {
  field: string;
  fieldLabel: string;
  values: IActionInputValue<ValueType>[];
};

export enum DescriptionFormat {
  ConditionBeforeAction,
  ActionBeforeCondition,
}

export enum PaymentType {
  Card = "Card",
  Mileage = "Mileage",
  Personal = "Personal",
  Reimbursable = "Reimbursable",
}
