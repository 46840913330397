import { useCallback, useEffect, useMemo } from "react";
import { differenceWith, get } from "lodash";
import _filter from "lodash/filter";
import PropTypes from "prop-types";
import { Query, useApolloClient } from "react-apollo";
import { withRouter } from "react-router-dom";

import { useFlag } from "@core/feature-flags/clients";

import useAsyncJobs from "../../common/useAsyncJobs";
import { useAsyncJobsMessage } from "../../common/useAsyncJobsMessage";
import usePersistentStorage from "../../common/usePersistentStorage";
import AdvancedAsyncProgressDialog from "../../components/AdvancedAsyncProgressDialog";
import AsyncProgressDialog from "../../components/AsyncProgressDialog";
import { GET_JOB } from "../../services/graphql/queries";
import { ASYNC_JOB_PARAMS, ASYNC_JOB_STATUS } from "../../utils/asyncJobs";

const pollInterval = 1500; // 1.5 sec

const {
  Active,
  Pending,
  PendingChanges,
  Complete,
  CompleteWithWarning,
  NoOperation,
  Failed,
  Error,
} = ASYNC_JOB_STATUS;

export const PERSISTED_ADVANCED_ASYNC_JOB = "persistedAdvancedAsyncJobs";

const AsyncJobs = ({ match, onAsyncCallback }) => {
  const client = useApolloClient();
  const hasCardManagementEnhancements = useFlag("release-card-management-enhancements");
  const [persistedJob, setPersistedJob] = usePersistentStorage(PERSISTED_ADVANCED_ASYNC_JOB, null);
  const [{ jobs, hasPendingJob }, upsertAsyncJob, deleteAsyncJob] = useAsyncJobs();
  const pendingJobs = useMemo(
    () => jobs?.filter((job) => job.status === Pending || job.status === PendingChanges) ?? [],
    [jobs],
  );

  const [message, updateMessage] = useAsyncJobsMessage(pendingJobs);

  // If a persistedJob is pending/active add it to list for polling
  if (
    persistedJob &&
    !pendingJobs.find((job) => job.ID === persistedJob.ID) &&
    (persistedJob.status === Pending ||
      persistedJob.status === PendingChanges ||
      persistedJob.status === Active)
  ) {
    const found = pendingJobs.find((j) => j.ID === persistedJob.ID);
    if (!found) {
      pendingJobs.push(persistedJob);
    }
  }

  let errorCount = 0;

  const handleAsyncJobStatusChange = useCallback((job) => {
    upsertAsyncJob(job);
    // https://stackoverflow.com/questions/56399643/react-hooks-dependencies-infinite-loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAsyncJobDelete = useCallback((jobID) => {
    deleteAsyncJob(jobID);
    // https://stackoverflow.com/questions/56399643/react-hooks-dependencies-infinite-loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAsyncJobError = useCallback(({ job, error = true }) => {
    const { jobType } = job;
    if (ASYNC_JOB_PARAMS[jobType].onComplete) {
      ASYNC_JOB_PARAMS[jobType].onComplete({
        job,
        error,
        client,
        onAsyncCallback,
        hasCardManagementEnhancements,
      });
    }
    // https://stackoverflow.com/questions/56399643/react-hooks-dependencies-infinite-loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Will need to be passed to AdvancedAsyncProgressDialog to be called when drawer is closed on completed
  // const handleClearPersistedJob = () => {
  //   setPersistedJob(null);
  // };

  useEffect(() => {
    const [errorJob] = _filter(jobs, { status: Error });
    if (errorJob) {
      const { ID: jobID } = errorJob;
      handleAsyncJobDelete(jobID);
      handleAsyncJobError({ job: errorJob });
    }
  }, [jobs, handleAsyncJobError, handleAsyncJobStatusChange, handleAsyncJobDelete]);

  const renderJobQuery = (job) => {
    const { ID: jobID, pollInterval: jobPollInterval, status: jobStatus, jobType } = job;
    if (`${jobID}`.includes("tempAsyncJob")) return null; // Don't query on temp entries
    const poll =
      jobStatus === Pending || jobStatus === Active ? jobPollInterval || pollInterval : null;
    // TODO Replace Query, useApolloClient with useLazyQuery from react-apollo when available
    // https://www.apollographql.com/docs/react/api/react-hooks/#uselazyquery
    return (
      <Query
        key={jobID}
        query={GET_JOB}
        variables={{ id: jobID }}
        notifyOnNetworkStatusChange
        fetchPolicy="network-only"
        pollInterval={poll}
        onCompleted={(data) => {
          const status = get(data, "job.status", null);
          const error = get(data, "job.warning", null);

          switch (status) {
            case Active:
            case Pending:
            case PendingChanges:
              updateMessage(data.job);
              // Keep Calm and Carry On (continue polling)
              break;
            case Complete:
            case CompleteWithWarning:
            case NoOperation:
            case Failed:
            default:
              if (ASYNC_JOB_PARAMS[jobType].isAdvanced) {
                // will probably need to be moved, for now persist completed job so dialog doesn't disapear
                setPersistedJob({ ...data.job, jobType });
              }
              handleAsyncJobDelete(jobID);
              ASYNC_JOB_PARAMS[jobType].onComplete({
                job: { ...job, status },
                route: match.path,
                data,
                error,
                client,
                onAsyncCallback,
                hasCardManagementEnhancements,
              });
              break;
          }
        }}
        onError={(err) => {
          errorCount += 1;
          if (errorCount > 3) {
            // Fail after 3 polling errors
            handleAsyncJobDelete(jobID);
            handleAsyncJobError({
              job: { ...job, status: Error },
              error: err,
            });
          }
        }}
      >
        {({ data }) => {
          if (!data) {
            return null;
          }
          const config = ASYNC_JOB_PARAMS[jobType];
          if (data && config && config.isAdvanced) {
            // setting state re-triggers everything, so only do if status has changed
            const isChanged = !!differenceWith(
              data.job.operations,
              persistedJob ? persistedJob.operations : [],
            ).length;

            if (isChanged) {
              setPersistedJob({ ...data.job, jobType });
            }
          }

          return null;
        }}
      </Query>
    );
  };

  return (
    <>
      {hasPendingJob && pendingJobs.every((j) => !ASYNC_JOB_PARAMS[j.jobType].isAdvanced) && (
        <AsyncProgressDialog message={message} />
      )}
      {pendingJobs.map(renderJobQuery)}
      {persistedJob && (
        <AdvancedAsyncProgressDialog
          job={persistedJob}
          onCompletedClose={() => setPersistedJob(null)}
          config={ASYNC_JOB_PARAMS[persistedJob.jobType]}
          renderDetails={ASYNC_JOB_PARAMS[persistedJob.jobType].detailsComponent}
        />
      )}
    </>
  );
};

AsyncJobs.propTypes = {
  match: PropTypes.object,
  onAsyncCallback: PropTypes.func,
};

export default withRouter(AsyncJobs);
