import { z } from "zod";

import { API_URL, fetchWithToken, getUriWithCorsProxy } from "./api";

export const handleError = (error: unknown) => {
  if (error instanceof z.ZodError) {
    return Promise.reject(
      new Error(
        `request response invalid: ${error.issues
          .map((issue) => JSON.stringify(issue))
          .join(", ")}`,
      ),
    );
  }

  if (error instanceof Error) {
    const err = `request failed with error: ${error.message}`;
    return Promise.reject(new Error(err));
  }

  window.console.error(error);
  return Promise.reject(new Error("request failed"));
};

type Method = "GET" | "POST" | "PATCH" | "DELETE" | "PUT";

export const wrapRequest = async ({
  data,
  url,
  method,
}: {
  method: Method;
  url: string;
  data?: unknown;
}) => {
  try {
    const response: Response = await fetchWithToken(getUriWithCorsProxy(`${API_URL}${url}`), {
      method,
      headers: { "Content-Type": "application/json" },
      body: data ? JSON.stringify(data) : undefined,
    });

    const streamedRes = await response.json();

    return response.ok
      ? streamedRes
      : Promise.reject(new Error(`errorMessage: ${streamedRes.error}, status: ${response.status}`));
  } catch (error) {
    window.console.error(error);
    return handleError(error);
  }
};
