import PropTypes from "prop-types";
import { Mutation } from "react-apollo";
import MediaQuery from "react-responsive";
import styled from "styled-components";
import { generateMedia } from "styled-media-query";

import { Drawer, Heading } from "@core/ui-legacy";

import { MQBreakPoints } from "../../common/core-refactor/MQBreakPoints";
import { withShowAppNotificationsPanelFlag } from "../../services/graphql/hoc";
import { TOGGLE_SHOW_APP_NOTIFICATIONS_PANEL } from "../../services/graphql/mutations";
import { useHasHRIS } from "../../services/rest/hris";
import AppNotificationsTabs from "./AppNotificationsTabs";

const { MOBILE, DESKTOP } = MQBreakPoints;

const media = generateMedia({
  medium: `${DESKTOP.minWidth}px`,
});

const DrawerStyled = styled(Drawer)`
  .ant-drawer-content {
    height: 100%;
    ${media.greaterThan("medium")`
      padding: 0 !important;
    `};
  }

  .ant-drawer-header {
    padding: 1rem !important;
    ${media.greaterThan("medium")`
      padding: 1rem 3rem 2rem !important;
      margin-top: 2rem !important;
    `};
  }

  .ant-drawer-body {
    padding: 0 !important;
  }

  .ant-drawer-close-x {
    font-size: 1.125rem;
    ${media.greaterThan("medium")`
      margin-top: 2rem !important;
      margin-right: 2rem !important;
    `};
  }
`;

const AppNotificationsPanel = ({ isAppNotificationsPanelVisible, onlyHRIS }) => {
  const handleToggleAppNotifications = (setShowAppNotificationsPanel) => {
    setShowAppNotificationsPanel({
      variables: { visible: !isAppNotificationsPanelVisible },
    });
  };
  const hasHRIS = useHasHRIS();
  return (
    <div id="affix">
      <Mutation mutation={TOGGLE_SHOW_APP_NOTIFICATIONS_PANEL} ignoreResults>
        {(setShowAppNotificationsPanel) => (
          <MediaQuery {...MOBILE}>
            {(isMobile) => (
              <DrawerStyled
                title={<Heading as="h2">Inbox</Heading>}
                placement="right"
                width={isMobile ? "100%" : 481}
                mask={false}
                closable
                destroyOnClose
                zIndex={1}
                onClose={() => {
                  handleToggleAppNotifications(setShowAppNotificationsPanel);
                }}
                visible={isAppNotificationsPanelVisible}
                level={null}
                getContainer={() => document.body}
              >
                <AppNotificationsTabs
                  onlyHRIS={onlyHRIS}
                  hasHRIS={hasHRIS}
                  onClose={() => {
                    handleToggleAppNotifications(setShowAppNotificationsPanel);
                  }}
                />
              </DrawerStyled>
            )}
          </MediaQuery>
        )}
      </Mutation>
    </div>
  );
};

AppNotificationsPanel.propTypes = {
  isAppNotificationsPanelVisible: PropTypes.bool,
};

export default withShowAppNotificationsPanelFlag(AppNotificationsPanel);
