import PropTypes from "prop-types";
import styled from "styled-components";

import { OverlineLabel } from "@core/ui-legacy";
import Colors from "@core/ui-legacy/themes/colors";
import { sansProps } from "@core/ui-legacy/utils/styledSansProps";

const OverlineLabelStyled = sansProps(styled, { required: true })(OverlineLabel)`
  margin-bottom: 1.25rem;
  ${({ required }) =>
    required
      ? `> label:after {
    margin-left: 0;
    margin-right: 0rem !important;
    content: '*' !important;
    font-size: 0.875rem;
    }`
      : null};
`;

const ReadOnlyField = ({ styleLabel, styleText, ...rest }) => {
  const sLabel = {
    fontSize: "0.75rem",
    fontWeight: 700,
    maxWidth: "31.25rem",
    wordBreak: "break-word",
    whiteSpace: "normal",
  };

  const sText = {
    fontSize: "0.875rem",
    fontWeight: "normal",
    color: Colors.midnight,
    maxWidth: "31.25rem",
    wordBreak: "break-word",
    whiteSpace: "normal",
  };
  return (
    <OverlineLabelStyled
      className="overline-label"
      {...rest}
      styleLabel={{ ...sLabel, ...styleLabel }}
      styleText={{ ...sText, ...styleText }}
    />
  );
};

ReadOnlyField.propTypes = {
  styleLabel: PropTypes.object,
  styleText: PropTypes.object,
};

export default ReadOnlyField;
