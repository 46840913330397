import { useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "react-apollo";
import styled from "styled-components";

import { Button } from "@core/ui-legacy";
import Colors from "@core/ui-legacy/themes/colors";

import { useUpdatePhoneNumber } from "../../common/useAccountInfo";
import { FRAUD_ALERT } from "../../services/graphql/mutations";
import { GET_ME_CARDS_AND_FRAUD } from "../../services/graphql/queries";
import PhoneInput from "../PhoneInput";

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 1.625rem;
  padding-bottom: 1.5rem;

  button:not(:last-child) {
    margin-right: 0.5rem;
  }

  button {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
`;
const ModalDescription = styled.div`
  padding-bottom: 0.4rem;
  max-width: 100%;
`;
const Aligner = styled.div`
  display: flex;
  justify-content: center;
  padding: 4.563rem;
`;

const NoteBlock = styled.div`
  color: ${Colors.monumental};
`;

const AddPhone = ({ userID, nextStep, onCancel }) => {
  const [phone, setPhone] = useState("");
  const [validateError, setValidateError] = useState("");

  const { updatePhoneNumber, loading } = useUpdatePhoneNumber();

  const [fraudAlert, { loading: isFraudLoading }] = useMutation(FRAUD_ALERT, {
    awaitRefetchQueries: false,
    refetchQueries: [
      {
        query: GET_ME_CARDS_AND_FRAUD,
        variables: { limit: 25 },
      },
    ],
  });

  const onNextClick = async () => {
    if (phone.length < 10) {
      setValidateError("Please enter a valid number");
      return;
    }
    try {
      await updatePhoneNumber({
        variables: {
          id: userID,
          input: {
            phone,
          },
        },
      });
      await fraudAlert({
        variables: { enable: true },
      });
    } catch {
      setValidateError("Something went wrong. Please try again");
      return;
    }
    nextStep();
  };

  return (
    <>
      <div className="ant-modal-header">
        <div className="ant-modal-title">Enable fraud alerts</div>
      </div>
      <ModalDescription>
        Enter your phone number to receive text alerts when there is a potential fraud
        <br /> associated with one of your Center cards.
        <NoteBlock>You can enable fraud alerts anytime later.</NoteBlock>
      </ModalDescription>
      <Aligner>
        <PhoneInput phone={phone} changePhone={setPhone} validateError={validateError} />
      </Aligner>
      <ActionsWrapper>
        <Button
          id="cancel-dialog-btn"
          key="cancel-dialog-btn"
          data-testid="cancel-dialog-btn"
          type="cancel"
          size="default"
          onClick={onCancel}
        >
          Do it later
        </Button>
        <Button
          id="next-dialog-btn"
          key="next-dialog-btn"
          data-testid="next-dialog-btn"
          type="primary"
          size="default"
          htmlType="submit"
          onClick={onNextClick}
          loading={loading || isFraudLoading}
          disabled={!phone}
        >
          Next
        </Button>
      </ActionsWrapper>
    </>
  );
};

AddPhone.propTypes = {
  userID: PropTypes.string,
  onCancel: PropTypes.func,
  nextStep: PropTypes.func,
};

export default AddPhone;
