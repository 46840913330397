import gql from "graphql-tag";

import {
  ADDRESS_FRAGMENT,
  AUTO_DEPOSIT_FRAGMENT,
  BASE_EXPENSE_SPLITS_FRAGMENT,
  BASE_EXPENSE_SPLITS_FRAGMENT_V2,
  BOOKING_FRAGMENT,
  BUBBLE_FRAGMENT,
  CARD_FRAGMENT,
  CARD_REQUEST_FRAGMENT,
  CARD_SCHEDULED_EVENTS_FRAGMENT,
  COST_CENTER_FRAGMENT,
  COST_CENTER_LIST_FRAGMENT,
  DYNAMIC_FORM_SECTION_OR_FIELD_FRAGMENT,
  EVALUATED_EXPENSE_FORM_FRAGMENT,
  EVENTS_FRAGMENT,
  EXPENSE_CARD_INFO_FRAGMENT,
  EXPENSE_FIELD_FRAGMENT,
  EXPENSE_FIELD_VALUE_FRAGMENT,
  EXPENSE_FRAGMENT,
  EXPENSE_FRAGMENT_V2,
  EXPENSE_SENT_BACK_FRAGMENT,
  EXPENSE_TYPE_FRAGMENT,
  EXPORT_INFO_FRAGMENT,
  FLAG_FRAGMENT,
  GENERAL_LEDGER_ACCOUNT_FRAGMENT,
  ORG_TRAVEL_MANAGEMENT_COMPANY_PARTNER,
  PAGINATION_FRAGMENT,
  USER_FRAGMENT,
} from "./fragments";

export const GET_AUTH = gql`
  query {
    auth @client {
      isAuthenticated
    }
  }
`;

export const GET_BANK_ACCOUNTS = gql`
  query bankAccounts($orgId: ID) {
    bankAccounts(orgId: $orgId) {
      ID
      nickname
      accountNumber
      verification
    }
  }
`;

export const GET_AUTO_DEPOSITS = gql`
  query autoDeposits($orgId: ID) {
    autoDeposits(orgId: $orgId) {
      ...AutoDepositFragment
    }
  }
  ${AUTO_DEPOSIT_FRAGMENT}
`;

export const GET_MY_EXPENSE_FILTERS = gql`
  query myExpenseFilters($delegateOf: ID) {
    myExpenseFilters(delegateOf: $delegateOf) {
      code
      count
    }
  }
`;

export const GET_APP_NOTIFICATIONS_COUNT = gql`
  query alerts {
    alerts {
      alertsCount
      actionsCount
    }
  }
`;

export const GET_SPLIT = gql`
  query split($expenseId: ID!, $id: ID!, $delegateOf: ID, $prioritizeRole: String) {
    split(
      expenseId: $expenseId
      id: $id
      delegateOf: $delegateOf
      prioritizeRole: $prioritizeRole
    ) {
      splits {
        ID
      }
      isASplit
      hasSplits
      ...BaseExpenseSplitsFragmentV2
      form {
        ...EvaluatedExpenseFormFragment
      }
    }
  }
  ${BASE_EXPENSE_SPLITS_FRAGMENT_V2}
  ${EVALUATED_EXPENSE_FORM_FRAGMENT}
`;

export const GET_EXPENSES = gql`
  query expenses(
    $limit: Int
    $offset: String
    $status: String
    $filter: String
    $statusOop: String
    $sort: String
    $prioritizeRole: String
    $approvalStatus: String
    $withoutCheckRoles: Boolean
  ) {
    expenses(
      limit: $limit
      offset: $offset
      status: $status
      statusOop: $statusOop
      filter: $filter
      sort: $sort
      prioritizeRole: $prioritizeRole
      approvalStatus: $approvalStatus
      withoutCheckRoles: $withoutCheckRoles
    ) @connection(key: "expenses", filter: ["status", "filter"]) {
      items {
        ...ExpenseFragment
        splits {
          ...BaseExpenseSplitsFragment
        }
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
  ${EXPENSE_FRAGMENT}
  ${BASE_EXPENSE_SPLITS_FRAGMENT}
  ${PAGINATION_FRAGMENT}
`;

export const GET_EXPENSES_VIEW_ONLY_IDS = gql`
  query expensesViewOnlyIDs(
    $viewName: String!
    $filter: String
    $limit: Int
    $offset: String
    $returnOnlyIDs: Boolean
  ) {
    expensesViewOnlyIDs(
      viewName: $viewName
      filter: $filter
      limit: $limit
      offset: $offset
      returnOnlyIDs: $returnOnlyIDs
    ) {
      ID
      dateTime
      postedDateTime
    }
  }
`;

export const GET_MY_EXPENSES = gql`
  query myExpenses(
    $limit: Int
    $offset: String
    $status: String
    $filter: String
    $sort: String
    $delegateOf: ID
  ) {
    myExpenses(
      limit: $limit
      offset: $offset
      status: $status
      filter: $filter
      sort: $sort
      delegateOf: $delegateOf
    ) @connection(key: "myExpenses", filter: ["status", "filter"]) {
      items {
        ...ExpenseFragment
        hasPersonalSplits
        splits {
          ID
          bubbles {
            ...BubbleFragment
          }
        }
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
  ${BUBBLE_FRAGMENT}
  ${EXPENSE_SENT_BACK_FRAGMENT}
  ${EXPENSE_FRAGMENT}
  ${PAGINATION_FRAGMENT}
`;

// We should Get BUBBLE_FRAGMENT for update UI
export const GET_EXPENSE_EVENTS = gql`
  query expense($id: ID!, $delegateOf: ID) {
    expense(id: $id, delegateOf: $delegateOf) {
      ID
      events {
        ...EventFragment
      }
    }
  }
  ${EVENTS_FRAGMENT}
`;

export const GET_POLICY_RULE = gql`
  query policyRule($ID: ID!) {
    policyRule(id: $ID) {
      ID
      createdDateTime
      creator
      description
      effectiveDateTime
      endDateTime
      evaluation
      isDeleted
      orgID
      status
      type
      uiRepresenation
      updatedDateTime
    }
  }
`;

export const GET_POLICY_RULE_ORDER = gql`
  query policyRuleOrder($type: String!) {
    policyRuleOrder(type: $type) {
      ruleIDs
    }
  }
`;

export const GET_RECEIPTS = gql`
  query receipts($expenseId: ID, $userId: ID, $delegateOf: ID, $getMapScreenshot: Boolean) {
    receipts(
      expenseId: $expenseId
      userId: $userId
      delegateOf: $delegateOf
      getMapScreenshot: $getMapScreenshot
    ) {
      fileID
      URI
      type
      uploadDate
      expires @client
    }
  }
`;

export const GET_RECEIPTS_BIN = gql`
  query receipts($delegateOf: ID) {
    receipts(delegateOf: $delegateOf) {
      fileID
      URI
      hdResThumbURI
      type
      uploadDate
    }
  }
`;

export const DEFAULT_PAGE_LIMIT = 50;

export const MY_CARDS_PAGE_LIMIT = 5;

export const GET_CARDHOLDERS = gql`
  query cardHolders($limit: Int, $offset: String, $prefix: String) {
    users(limit: $limit, offset: $offset, archived: false, prefix: $prefix, showcarditems: true) {
      items {
        ID
        firstName
        lastName
        fullName
        roles
        emailAddress
        loginID
        archived
        avatarBackgroundColor
        reimbursementPolicy
        billingAddress {
          ...AddressFragment
        }
        cardItems {
          ...CardFragment
        }
        defaultApprover {
          ID
          name
          ruleID
        }
        defaultApprover @client {
          ID
          name
          ruleID
        }
        defaultCostCenter {
          ID
          name
        }
        emailStatus
        undeliverableReason
        cardOrdering {
          status
          cardApprovalRequestID
        }
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
  ${ADDRESS_FRAGMENT}
  ${CARD_FRAGMENT}
  ${PAGINATION_FRAGMENT}
`;

export const GET_USERS = gql`
  query users(
    $limit: Int
    $offset: String
    $status: String
    $prefix: String
    $archived: Boolean
    $sortby: String
    $order: String
    $showcarditems: Boolean
    $usertype: String
    $prioritizeRole: String
  ) {
    users(
      limit: $limit
      offset: $offset
      status: $status
      archived: $archived
      prefix: $prefix
      sortby: $sortby
      order: $order
      showcarditems: $showcarditems
      usertype: $usertype
      prioritizeRole: $prioritizeRole
    ) @connection(key: "users") {
      ...UserListFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const GET_TRAVELER_PROFILES = gql`
  query users(
    $roles: String
    $limit: Int
    $sortby: String
    $order: String
    $prefix: String
    $archived: Boolean
    $offset: String
  ) {
    users(
      archived: $archived
      prefix: $prefix
      roles: $roles
      limit: $limit
      sortby: $sortby
      order: $order
      offset: $offset
    ) {
      items {
        ID
        fullName
        defaultCostCenter {
          ID
          name
        }
      }
      pagination {
        next
        previousOffset
        offset
        pages
        totalItems
      }
    }
  }
`;

// export const GET_TRAVELER_PROFILES = gql`
// 	query
// `;

export const GET_USER_FORM = gql`
  query userForm($user: DynamicFormUserInput) {
    userForm(user: $user) @connection(key: "userForm") {
      _user: user
      user @client
      fields {
        ...DynamicFormSectionOrFieldFragment
      }
    }
  }
  ${DYNAMIC_FORM_SECTION_OR_FIELD_FRAGMENT}
`;

export const GET_MY_CARDS = gql`
  query myCards($limit: Int, $offset: String, $status: String, $search: String, $sort: String) {
    myCards(limit: $limit, offset: $offset, status: $status, search: $search, sort: $sort) {
      items {
        ID
        cardReferenceID
        orgID
        cardholderID
        last4
        cardProgramInstanceID
        type
        statusCode
        limit
        cardFormat
        firstName
        lastName
        cardholder {
          firstName
          lastName
        }
        balance
        expirationYYMM
        isProvisioning
        isActive
        isAdminLocked
        isPendingClose
        cardBehavior
        scheduledEvents {
          ...CardScheduledEventFragment
        }
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
  ${PAGINATION_FRAGMENT}
  ${CARD_SCHEDULED_EVENTS_FRAGMENT}
`;

export const GET_ERP_SOURCE_TYPES = gql`
  query erpSourceTypes {
    erpSourceTypes {
      dateTime
      description
      error
      label
      mappingField {
        key
        meta: localMeta @client
        _meta: meta
      }
      mappingName
      sourceField {
        key
        meta: localMeta @client
        _meta: meta
      }
      sourceType
      syncDateTime
      syncInternalID
      scriptID
      synced
      type
      isNameField
      lastSyncCheck
      syncEnabled
      syncEnabledOn
      forceSync
    }
  }
`;

export const UPDATE_ERP_SOURCE_TYPE = gql`
  mutation updateERPSourceType($sourceType: String!, $input: ERPSourceTypeUpdateInput!) {
    updateERPSourceType(sourceType: $sourceType, input: $input) {
      mappingField {
        key
        meta: localMeta @client
        _meta: meta
      }
      mappingName
      sourceType
      scriptID
      isNameField
      lastSyncCheck
      syncEnabled
      syncEnabledOn
      forceSync
    }
  }
`;

export const GET_ERP_DIMENSIONS = gql`
  query erpDimensions($type: String) {
    erpDimensions(type: $type) {
      internalID
      key
      meta: localMeta @client
      _meta: meta
      name
      syncInternalID
      scriptID
      type
    }
  }
`;

export const GET_USER_FULLNAMES = gql`
  query users($limit: Int, $archived: Boolean) {
    users(limit: $limit, archived: $archived) {
      items {
        ID
        fullName
      }
    }
  }
`;

export const GET_COST_CENTERS_WITH_PREFIX = gql`
  query searchCostCenters($searchString: String, $offset: String) {
    searchCostCenters(
      archived: false
      pagination: true
      searchString: $searchString
      offset: $offset
      limit: 25
    ) {
      items {
        ID
        name
      }
      pagination {
        next
        previousOffset
        offset
        pages
        totalItems
      }
    }
  }
`;

export const GET_USER_FULLNAMES_WITH_ROLES = gql`
  query users($limit: Int, $prefix: String, $offset: String) {
    users(limit: $limit, archived: false, prefix: $prefix, offset: $offset) {
      items {
        ID
        fullName
        roles
      }
      pagination {
        next
        previousOffset
        offset
        pages
        totalItems
      }
    }
  }
`;

export const GET_COST_CENTERS = gql`
  query costCenters {
    # Do not add parameters to this query
    costCenters {
      ...CostCenterFragment
    }
  }
  ${COST_CENTER_FRAGMENT}
`;

export const GET_COST_CENTERS_LIST = gql`
  query costCenters($archived: Boolean) {
    costCenters(archived: $archived) {
      spenders
      ...CostCenterListFragment
    }
  }
  ${COST_CENTER_LIST_FRAGMENT}
`;

export const GET_MY_COST_CENTERS = gql`
  query myCostCenters($delegateOf: ID) {
    myCostCenters(delegateOf: $delegateOf) {
      ID
      name
    }
  }
`;

export const GET_PURPOSES = gql`
  query expensePurposes($userID: ID, $returnObjects: Boolean) {
    expensePurposes(userID: $userID, returnObjects: $returnObjects) {
      recent
      suggested
      recentFields {
        ID
        name
        isDeleted
        lastExpenseTimeStamp
      }
      suggestedFields {
        ID
        name
        isDeleted
        lastExpenseTimeStamp
      }
    }
  }
`;

export const GET_CARDS = gql`
  query cards(
    $limit: Int
    $offset: String
    $behavior: String
    $ispendingclose: Boolean
    $search: String
    $format: String
    $status: String
    $includeguestcards: Boolean
    $sort: String
    $userids: String
  ) {
    cards(
      limit: $limit
      offset: $offset
      behavior: $behavior
      ispendingclose: $ispendingclose
      search: $search
      format: $format
      status: $status
      includeguestcards: $includeguestcards
      sort: $sort
      userids: $userids
    ) {
      items {
        ...CardFragment
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
  ${CARD_FRAGMENT}
  ${PAGINATION_FRAGMENT}
`;

export const GET_MERCHANTS = gql`
  query merchants($prefix: String, $limit: Int, $offset: String) {
    merchants(prefix: $prefix, limit: $limit, offset: $offset) {
      items {
        ID
        name
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
  ${PAGINATION_FRAGMENT}
`;

export const GET_EXPENSE_TYPES = gql`
  query expenseTypes($showDeleted: Boolean) {
    expenseTypes(showDeleted: $showDeleted) @connection(key: "expenseTypes") {
      ...ExpenseTypeFragment
    }
  }
  ${EXPENSE_TYPE_FRAGMENT}
`;

export const GET_GENERAL_LEDGER = gql`
  query generalLedgerAccounts($limit: Int, $offset: String) {
    generalLedgerAccounts(limit: $limit, offset: $offset)
      @connection(key: "generalLedgerAccounts") {
      items {
        ...GeneralLedgerAccountFragment
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
  ${GENERAL_LEDGER_ACCOUNT_FRAGMENT}
  ${PAGINATION_FRAGMENT}
`;

/**
 * Dashboards
 */
export const SPEND_BY = gql`
  query spendBy($filter: String, $path: String!, $pivotType: String!) {
    spendBy(drillDownPath: $path, pivotType: $pivotType, filter: $filter) {
      ID
      key
      label
      amount
      expenseCount
      avatarURI
    }
  }
`;

export const SPEND_SUMMARY = gql`
  query spendSummary($filter: String, $itemID: ID!, $path: String!, $pivotType: String!) {
    spendSummary(drillDownPath: $path, pivotType: $pivotType, itemID: $itemID, filter: $filter) {
      ID
      spend
      expenseCount
      average
      projected
    }
  }
`;

export const EXPENSE_BULK_ACTION_INFO = gql`
  query expenseBulkActionInfo($bulkActionInfoID: ID!) {
    expenseBulkActionInfo(bulkActionInfoID: $bulkActionInfoID) {
      ID
      entityType
      completedAtUnix
      executionID
      bulkEntityType
      status
      entities {
        ID
        state
      }
      action
      userID
    }
  }
`;

export const GET_ASYNC_JOBS = gql`
  query {
    asyncJobs @client {
      jobs {
        ID
        jobType
        status
        pollInterval
        count
      }
      hasPendingJob
    }
  }
`;

export const GET_JOBS = gql`
  query jobs($status: String, $jobType: String, $operationType: String) {
    jobs(status: $status, jobType: $jobType, operationType: $operationType, limit: -1) {
      items {
        ID
        status
        jobType
        operationType
      }
    }
  }
`;

export const GET_JOB = gql`
  query job($id: ID!) {
    job(id: $id) {
      ID
      operations {
        ID
        status
      }
      downloadURI
      status
      timeStamp
      warning
      error
      expenseExportProgress {
        type
        gathered
        total
      }
    }
  }
`;

// add type
export const GET_JOB_ITEMS_IMPORT = gql`
  query job($id: ID!) {
    job(id: $id) {
      ID
      operations {
        ID
        status
        response
        name
        progress
      }
      usersCount
      costCentersCount
      status
      timeStamp
      warning
      error
      importListItemsWithVisibilityRules
    }
  }
`;

export const GET_EXPENSE_EXPORTS = gql`
  query expenseExports {
    expenseExports {
      items {
        ID
        endDate
        jobType
        format
        name
        uri
        startDate
        status
        timeStamp
        isOOP
        expenseStatuses
        filterByDate
        changeReports {
          ID
          changeCount
          timeStamp
        }
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
  ${PAGINATION_FRAGMENT}
`;

export const GET_EXPENSE_EXPORT = gql`
  query expenseExport($id: ID!) {
    expenseExport(id: $id) {
      ID
      endDate
      jobType
      format
      name
      uri
      startDate
      status
      timeStamp
      changeReports {
        ID
        timeStamp
        uri
      }
    }
  }
`;

export const GET_CUSTOM_FIELDS = gql`
  query customFields {
    customFields {
      description
      property
    }
  }
`;

export const GET_ERP_CONNECTORS = gql`
  query erpConnectors {
    erpConnectors {
      ID
      connected
      configured
      type
      name
      imageURL
      shortName @client
      logoUri @client
      areCredentialsValid
    }
  }
`;

export const GET_ERP_AUTH_URL = gql`
  query authorizationURL($connectorType: String!, $url: AWSURL!) {
    authorizationURL(connectorType: $connectorType, url: $url) {
      URL
    }
  }
`;

export const GET_ERP_ACCOUNTS = gql`
  query erpAccounts {
    erpAccounts {
      ID
      name
      code
      type
    }
  }
`;

export const GET_ERP_COST_CENTERS = gql`
  query erpCostCenters {
    erpCostCenters(source: "class") {
      ID
      name
      parentID
    }
  }
`;

export const GET_ERP_CHANGE_LIST = gql`
  query erpChangeList($from: AWSDateTime!, $to: AWSDateTime!, $postingDate: AWSDateTime) {
    erpChangeList(from: $from, to: $to, postingDate: $postingDate) {
      total
      changeSets {
        expenseID
        changes {
          data
          target
          entityType
        }
        errors
      }
    }
  }
`;

/**
 * Forms & Fields
 */

export const GET_EXPENSE_FORMS = gql`
  query expenseForms {
    expenseForms {
      ID
      name
      description
    }
  }
`;

export const GET_EXPENSE_FIELDS = gql`
  query expenseFields($sortFields: String, $showDeleted: Boolean, $filter: String) {
    expenseFields(sort: $sortFields, showDeleted: $showDeleted, filter: $filter)
      @connection(key: "expenseFields") {
      ...expenseField
    }
  }
  ${EXPENSE_FIELD_FRAGMENT}
`;

export const GET_EXPENSE_FIELD_VALUES = gql`
  query expenseFieldValues(
    $fieldID: ID!
    $limit: Int
    $offset: String
    $contains: String
    $ruleIDs: String
    $showHidden: Boolean
  ) {
    expenseFieldValues(
      fieldID: $fieldID
      limit: $limit
      offset: $offset
      contains: $contains
      showDeleted: false
      ruleIDs: $ruleIDs
      showHidden: $showHidden
    ) {
      items {
        ...ExpenseFieldValueFragment
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
  ${EXPENSE_FIELD_VALUE_FRAGMENT}
  ${PAGINATION_FRAGMENT}
`;

export const GET_EXPENSE_FIELD = gql`
  query expenseField($id: ID!, $injectusers: Boolean) {
    expenseField(id: $id, injectusers: $injectusers) {
      ID
      connectorID
      connectorTypeCode
      connectorDimension
      createdDateTime
      description
      defaultValue
      isDeleted
      isUserDefined
      mappingRuleID
      name
      property
      uiType
      updatedDateTime
      useMultiSetMap
      visibilityRules {
        ID
        conditions
        if
      }
    }
  }
`;

export const PAGE_SIZE_OPTIONS = ["100", "200", "400", "800", "1000"] as const;

export const FIELD_LIST_VALUES_PAGE_LIMIT = +PAGE_SIZE_OPTIONS[0];

export const GET_FIELD_LIST_VALUES = gql`
  query expenseFieldValues(
    $fieldID: ID!
    $limit: Int
    $offset: String
    $showDeleted: Boolean
    $contains: String
  ) {
    expenseFieldValues(
      fieldID: $fieldID
      limit: $limit
      offset: $offset
      contains: $contains
      showDeleted: $showDeleted
      showHidden: true
    ) @connection(key: "expenseFieldValues") {
      items {
        ...ExpenseFieldValueFragment
        isDeleted
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
  ${EXPENSE_FIELD_VALUE_FRAGMENT}
  ${PAGINATION_FRAGMENT}
`;

export const GET_FIELD_LIST_VALUES_COUNT = gql`
  query expenseFieldValuesCount($fieldID: ID!) {
    expenseFieldValuesCount(fieldID: $fieldID) {
      totalCount
      deactivateItemsCount
    }
  }
`;

export const IMPORT_LIST_ITEMS_CSV = gql`
  mutation importListItems(
    $fieldID: ID!
    $fileName: String!
    $deactivateExistingItems: Boolean!
    $deactivateVisibilityRules: Boolean
    $expenseTypesImport: Boolean
    $importListItemsWithVisibilityRules: Boolean
  ) {
    importListItems(
      fieldID: $fieldID
      fileName: $fileName
      deactivateExistingItems: $deactivateExistingItems
      deactivateVisibilityRules: $deactivateVisibilityRules
      expenseTypesImport: $expenseTypesImport
      importListItemsWithVisibilityRules: $importListItemsWithVisibilityRules
    ) {
      ID
    }
  }
`;

export const IMPORT_USERS = gql`
  mutation importUsers($fileName: String!) {
    importUsers(fileName: $fileName) {
      ID
    }
  }
`;

export const UPDATE_JOB_STATUS = gql`
  mutation updateJobStatus($id: ID!, $status: String!) {
    updateJobStatus(id: $id, status: $status) {
      ID
    }
  }
`;

export const IMPORT_MAPPING_RULES_CSV = gql`
  mutation importMappingRules(
    $ruleID: ID!
    $fileName: String!
    $deactivateExistingItems: Boolean!
  ) {
    importMappingRules(
      ruleID: $ruleID
      fileName: $fileName
      deactivateExistingItems: $deactivateExistingItems
    ) {
      ID
    }
  }
`;

export const IMPORT_COST_CENTERS = gql`
  mutation importCostCenters($fileName: String!, $deactivateExistingItems: Boolean!) {
    importCostCenters(fileName: $fileName, deactivateExistingItems: $deactivateExistingItems) {
      ID
    }
  }
`;
// if users query changes, GET_USER_AVATAR_INFO should revisit
// or it will over fetch
export const GET_ME = gql`
  {
    me {
      ID
      fullName
      roles
      loginID
      avatarBackgroundColor
      reimbursementPolicy
      billingAddress {
        address
        address1
        city
        state
        postalCode
        country
        isVerified
        isChanged
        timestamp
      }
      delegateOf {
        ID
        avatarURI
        firstName
        fullName @client
        lastName
      }
      cardItems {
        ID
        statusCode
        last4
        typeLabel
        reissued {
          reasonCode
        }
        cardInfo {
          ID
          activeState
        }
        cardFormat
      }
      costCenters {
        ID
      }
      toggles
      flags
      fraudEnrolled
      organization {
        ID
        name
        timeZone
        createdOn
        currencyCode
        partnerFields {
          spotnana {
            ...OrgTMCPartnerFragment
          }
          test {
            ...OrgTMCPartnerFragment
          }
        }
        shippingAddress {
          address1
          city
          state
          postalCode
          country
        }
        cardPrograms {
          ID
          name
          active
          issueCards
        }
      }
    }
    costCenters @connection(key: "costCenterList") {
      ID
      name
    }
    expenseTypes {
      ID
      name
      isDeleted
    }
  }
  ${ORG_TRAVEL_MANAGEMENT_COMPANY_PARTNER}
`;

// This query is using for partially update logged user
export const GET_ME_CARDS_AND_FRAUD = gql`
  {
    me {
      ID
      cardItems {
        ID
        statusCode
        last4
        typeLabel
      }
      phone
      fraudEnrolled
    }
  }
`;

export const CONFIGURE_SFTP_ORG = gql`
  query configureSftp {
    configureSftp {
      message
    }
  }
`;

export const SFTP_PAGE_LIMIT = 50;

export const GET_SFTP_FILES = gql`
  query sftpFiles($limit: Int, $offset: String, $sortby: String, $order: String) {
    sftpFiles(limit: $limit, offset: $offset, sortby: $sortby, order: $order) {
      items {
        ID
        date
        fileName
        type
        status
        originalFilePath
        errorFilePath
        hasError
        importLogicType
      }
      pagination {
        next
        previousOffset
        offset
        pages
        totalItems
      }
    }
  }
`;

export const GET_SFTP_KEYS = gql`
  query sftpKeys {
    sftpKeys {
      userName
      serverUrl
      sshPublicKeyIds
    }
  }
`;

export const GET_NOTIFICATIONS_EMAILS = gql`
  query notificationsEmails($withFullName: Boolean) {
    notificationsEmails(withFullName: $withFullName) {
      isEnabled
      recipients
      recipientsWithFullName {
        ID
        fullName
      }
      type
      meta {
        threshold
        daysOfWeek
        frequency
      }
    }
  }
`;

export const POST_SFTP_TEMPLATE = gql`
  query generateTemplateSftp {
    generateTemplateSftp {
      url
    }
  }
`;

export const GET_SFTP_FILE = gql`
  query getSftpFile($filepath: String!) {
    getSftpFile(filepath: $filepath) {
      url
    }
  }
`;

export const EXPENSES_THUMBNAILS = gql`
  query expensesThumbnails($expenses: [ThumbnailInput]!) {
    expensesThumbnails(expenses: $expenses)
  }
`;

export const getNextExpenseIdsQuery = (fields = "") => gql`
    query nextExpenseIDs(
      $searchParams: NextExpenseIDsRequest!
  ) {
    nextExpenseIDs(
      searchParams: $searchParams
    ) {
      ID
      ${fields}
    }
  }
  `;

/**
 * Dwolla
 */
export const GET_DWOLLA_CUSTOMER = gql`
  query dwollaCustomer($customerID: ID!) {
    dwollaCustomer(customerID: $customerID) {
      firstName
      lastName
      email
      type
      status
      businessType
      correlationId
      _links {
        beneficialOwners {
          href
          type
          resourceType
        }
        certifyBeneficialOwnership {
          href
          type
          resourceType
        }
        verifyWithDocument {
          href
          type
          resourceType
        }
        verifyBusinessWithDocument {
          href
          type
          resourceType
        }
        uploadDbaDocument {
          href
          type
          resourceType
        }
        verifyControllerAndBusinessWithDocument {
          href
          type
          resourceType
        }
      }
    }
  }
`;

export const GET_DWOLLA_FUNDING_SOURCES = gql`
  query dwollaFundingSources($customerID: ID!) {
    dwollaFundingSources(customerID: $customerID) {
      ID: id # uppercase ID used in apollo cache
      id
      status
      type
      bankName
      bankAccountType
      name
      removed
      _links {
        initiateMicroDeposits {
          href
          type
          resourceType
        }
        verifyMicroDeposits {
          href
          type
          resourceType
        }
        failedVerificationMicroDeposits {
          href
          type
          resourceType
        }
        microDeposits {
          href
          type
          resourceType
        }
      }
    }
  }
`;

export const GET_ORG_DWOLLA_CUSTOMER_INFO = gql`
  query orgDwollaCustomerInfo($orgID: ID!) {
    orgDwollaCustomerInfo(orgID: $orgID) {
      customerID
      isDailyScheduleAllowed
      isDirectReimbursable
      wasDirectPolicyEverSelected
      fundingSources {
        id
      }
      paymentSchedule {
        cadence
        value
      }
      maxExpenseAmount
    }
  }
`;

export const GET_USER_DWOLLA_CUSTOMER_INFO = gql`
  query userDwollaCustomerInfo($userID: ID!) {
    userDwollaCustomerInfo(userID: $userID) {
      customerID
      fundingSources {
        id
      }
    }
  }
`;

export const GET_DWOLLA_CLIENT_TOKEN = gql`
  query dwollaClientToken($action: String, $links: DwollaClientTokenLinks) {
    dwollaClientToken(action: $action, links: $links) {
      token
    }
  }
`;

export const GET_DWOLLA_TRANSFERS = gql`
  query dwollaTransfers($limit: Int, $offset: Int) {
    dwollaTransfers(limit: $limit, offset: $offset) {
      items {
        status
        totalAmount
        spender {
          firstName
          lastName
          avatar {
            color
            uri
          }
        }
        paymentDateTime
        createdDateTime
        updatedDateTime
      }
      pagination {
        totalItems
        pages
        limit
        offset
      }
    }
  }
`;

export const GET_DWOLLA_BALANCE = gql`
  query dwollaCustomerBalance($customerID: ID!) {
    dwollaCustomerBalance(customerID: $customerID) {
      value
      currency
    }
  }
`;

export const GET_TRANSFER_FOR_PAYMENT = gql`
  query dwollaTransferForPayment($transferID: ID!) {
    dwollaTransferForPayment(transferID: $transferID) {
      ID
      totalAmount
      expenses {
        ID
        amount
      }
    }
  }
`;

export const GET_TRANSFER_BY_EXPENSE = gql`
  query dwollaTransferByExpense($expenseID: ID!) {
    dwollaTransferByExpense(expenseID: $expenseID) {
      ID
      retryOf {
        transferID
        createdDateTime
        retryCount
      }
    }
  }
`;

export const DWOLLA_INITIATE_RETRY_TRANSFER = gql`
  query dwollaInitiateRetryTransfer($transferID: ID!) {
    dwollaInitiateRetryTransfer(transferID: $transferID) {
      ID
      totalAmount
      expenses {
        ID
        amount
      }
    }
  }
`;

export const GET_CONDITION_BUILDER_CONTEXT = gql`
  query {
    costCenters(archived: false) {
      ID
      name
    }
    expenseTypes {
      ID
      name
    }
    expenseFields {
      ...expenseField
      operators @client
      valuesQuery @client
    }
  }
  ${EXPENSE_FIELD_FRAGMENT}
`;

export const GET_TRIP = gql`
  query trip($tripID: ID!, $withExpenses: Boolean) {
    trip(tripID: $tripID, withExpenses: $withExpenses) {
      ID
      name
      type
      purpose
      startDateTime
      endDateTime
      status
      expenses {
        ID
        statuses
        expenseCount
        spent
        bookingTypes
      }
    }
  }
`;

export const GET_TRIPS = gql`
  query trips(
    $userid: ID!
    $search: Boolean
    $name: String
    $sort: String
    $withExpenses: Boolean
  ) {
    trips(userid: $userid, search: $search, name: $name, sort: $sort, withExpenses: $withExpenses) {
      ID
      name
      purpose
      type
      startDateTime
      endDateTime
      groupEventID
      userID
      status
      expenses {
        ID
        statuses
        expenseCount
        spent
        bookingTypes
      }
    }
  }
`;

export const GET_BOOKING = gql`
  query booking($bookingID: ID!) {
    booking(bookingID: $bookingID) {
      ID
      type
      sourceIDs {
        sourceName
        locked
        sourceID {
          ID
        }
      }
      airfare {
        flightLegs {
          airlineName
          arrivalTime
          carrierCode
          destinationAirportCode
          destinationAirportName
          departureTime
          flightDuration
          flightNumber
          originAirportCode
          originAirportName
          serviceClassCode
          serviceClassName
          travelDate
          departureDate
          arrivalDate
        }
        issueDate
        issuingCarrierName
        passengerName
        ticketNumber
        travelAgencyName
      }
      vehicleRental {
        checkoutDate
        rentalAgreementNumber
        customerServiceNumber
        rentalLocationCity
        rentalLocationState
        rentalLocationCountry
        rentalLocationID
        rentalClassID
        rentalClassName
        rentalRateIndicator
        rentalRate
        renterName
        programCode
        returnCity
        returnState
        returnCountry
        returnDate
        returnLocationID
        totalMiles
        rentalCompany
      }
      hotel {
        folioName
        arrivalDate
        departureDate
        roomRate
        renterName
        merchantName
        checkInDate
        checkInTime
        checkOutDate
        checkOutTime
        address {
          address
          address1
          address2
          city
          country
          postalCode
          state
        }
      }
    }
  }
`;

export const DETACH_BOOKING = gql`
  mutation detachBooking($bookingID: ID!, $sourceName: String!) {
    detachBooking(bookingID: $bookingID, sourceName: $sourceName) {
      ...bookingFragment
    }
  }
  ${BOOKING_FRAGMENT}
`;

export const GET_BOOKING_MATCHES = gql`
  query bookingMatches($bookingID: ID!, $tmc: String) {
    bookingMatches(bookingID: $bookingID, tmc: $tmc) {
      matches {
        ...bookingFragment
      }
      matchType
    }
  }
  ${BOOKING_FRAGMENT}
`;

export const GET_EXPENSE = gql`
  query expense($id: ID!, $delegateOf: ID, $prioritizeRole: String) {
    expense(id: $id, delegateOf: $delegateOf, prioritizeRole: $prioritizeRole) {
      ...ExpenseFragmentV2
      splits {
        ID
        amount
        currencyCode
        costCenterName
        expenseTypeName
        purpose
        fields {
          forex {
            originatingCurrency {
              amountRaw
              amountTotal
              crossBorderFee
              name
              namePlural
              symbol
            }
            billingCurrency {
              amountRaw
              amountTotal
              crossBorderFee
              name
              namePlural
              symbol
            }
            exchangeRate
          }
        }
        isPersonal
        flags {
          ...FlagFragment
        }
        bubbles {
          ...BubbleFragment
        }
        exportInfo {
          ...ExportInfoFragment
        }
      }
      form {
        ...EvaluatedExpenseFormFragment
      }
      events {
        ...EventFragment
      }
      card {
        ...ExpenseCardInfoFragment
      }
    }
  }
  ${EVALUATED_EXPENSE_FORM_FRAGMENT}
  ${EVENTS_FRAGMENT}
  ${EXPENSE_FRAGMENT_V2}
  ${EXPORT_INFO_FRAGMENT}
  ${FLAG_FRAGMENT}
  ${BUBBLE_FRAGMENT}
  ${EXPENSE_CARD_INFO_FRAGMENT}
`;

export const GET_FLAGS = gql`
  query me {
    me {
      ID
      flags
    }
  }
`;

export const UPDATE_USER_FLAGS = gql`
  mutation updateUser($id: ID!, $input: UpdateUserInput!) {
    updateUser(id: $id, input: $input) {
      ID
    }
  }
`;

export const GET_CARD_REQUESTS = gql`
  query cardRequests($statuses: [CardRequestStatus!]!) {
    cardRequests(statuses: $statuses) {
      ...CardRequestFragment
    }
  }
  ${CARD_REQUEST_FRAGMENT}
`;

export const GET_CARD_REQUEST = gql`
  query cardRequest($cardRequestID: ID!) {
    cardRequest(cardRequestID: $cardRequestID) {
      ...CardRequestFragment
    }
  }
  ${CARD_REQUEST_FRAGMENT}
`;

export const CARD_REQUEST_SEARCH = gql`
  query cardRequestSearch($input: CardRequestSearch) {
    cardRequestSearch(input: $input) {
      ...CardRequestFragment
    }
  }
  ${CARD_REQUEST_FRAGMENT}
`;

export const GET_USER_INFO = gql`
  query user(
    $id: ID!
    $orgID: ID!
    $withTravelPolicies: Boolean!
    $showcarditems: Boolean
    $withCardholderID: Boolean = false
    $tmc: String = "spotnana"
    $withOffices: Boolean = false
  ) {
    user(id: $id, showcarditems: $showcarditems) {
      ID
      fullName
      firstName
      lastName
      fraudEnrolled
      avatarBackgroundColor
      reimbursementPolicy
      archived
      emailAddress
      emailStatus
      employeeID
      archived
      costCenters {
        ID
        name @client
      }
      defaultApprover {
        ID
        name
        ruleID
      }
      defaultApprover @client {
        ID
        name
        ruleID
      }
      defaultApproverSchedule {
        ID
        defaultApprover {
          ID
          name
        }
        effectiveDate
        fromEffectiveDate
      }
      defaultCostCenter {
        ID
      }
      delegateOf {
        ID
        firstName
        lastName
        fullName @client
      }
      travelArrangerOf {
        ID
        firstName
        lastName
        fullName @client
      }
      travelArrangerOfInfo {
        scope
        userType
        costCenters {
          ID
          name
        }
        users {
          ID
          firstName
          lastName
          fullName @client
        }
      }
      roles
      phone
      billingAddress {
        ...AddressFragment
      }
      cardItems {
        ...CardFragment
        cardholderID @include(if: $withCardholderID)
      }
      cardOrdering {
        status
      }
      insightsViewerOf {
        costCenters {
          ID
          name
        }
      }
      integrationInfo {
        spotnana {
          persona
        }
      }
      undeliverableReason
      travelPolicies @include(if: $withTravelPolicies)
      loginRestricted
      office
    }
    costCenters(archived: false) {
      ID
      name
    }
    travelPolicies(orgID: $orgID) @include(if: $withTravelPolicies) {
      ID
      name
      isDefault
    }
    offices(tmc: $tmc) @include(if: $withOffices) {
      id
      name
    }
  }
  ${CARD_FRAGMENT}
  ${ADDRESS_FRAGMENT}
`;

export const GET_INSIGHTS_VIEWER_OF = gql`
  query userInsightsViewerOf($id: ID!) {
    user(id: $id) {
      insightsViewerOf {
        costCenters {
          ID
          name
        }
      }
    }
  }
`;

export const REACTIVATE_USER = gql`
  mutation reactivateUser($id: ID!) {
    reactivateUser(id: $id) {
      ID
      archived
    }
  }
`;

export const GET_POLICY_GROUPS = gql`
  query travelPolicyGroups {
    travelPolicyGroups {
      ID
      name
      type
      conditions
      sourceIDs {
        sourceName
        sourceID {
          ID
          orgID
        }
      }
    }
  }
`;

export const GET_POLICY_RULE_GROUP = gql`
  query travelPolicyRuleGroup($policyID: ID!) {
    travelPolicyRuleGroup(policyID: $policyID) {
      ID
      name
      type
      tmcType
      policyID
      rules {
        description
        conditions {
          value
          operator
          property
        }
      }
    }
  }
`;

export const GET_CARD_SECURITY_INFO = gql`
  query cardSecurityInfo($ID: ID!) {
    cardSecurityInfo(ID: $ID) {
      unmaskedCardNumber
      securityCode
    }
  }
`;

export const GET_TOKEN_EXCHANGE = gql`
  query tokenExchange($tmc: String!, $refreshToken: String) {
    tokenExchange(tmc: $tmc, refreshToken: $refreshToken) {
      access_token
      refresh_token
      token_type
      expires_in
      scope
    }
  }
`;

export const GET_USER_EDIT_PAGE_CONTEXT = gql`
  query pageContext(
    $userId: ID!
    $orgID: ID!
    $withTravelPolicies: Boolean!
    $showcarditems: Boolean
    $tmc: String
    $withOffices: Boolean!
  ) {
    user(id: $userId, showcarditems: $showcarditems) {
      ID
      firstName
      lastName
      fullName
      avatarBackgroundColor
      reimbursementPolicy
      archived
      emailAddress
      emailStatus
      employeeID
      phone
      costCenters {
        ID
      }
      defaultCostCenter {
        ID
        name
      }
      roles
      travelArrangerOf {
        ID
      }
      travelArrangerOfInfo {
        scope
        userType
        costCenters {
          ID
          name
        }
        users {
          ID
          firstName
          lastName
        }
      }
      billingAddress {
        ...AddressFragment
      }
      cardItems {
        ...CardFragment
      }
      insightsViewerOf {
        costCenters {
          ID
          name
        }
      }
      delegateOf {
        ID
        firstName
        lastName
      }
      defaultApprover {
        ID
        name
        ruleID
      }
      defaultApprover @client {
        ID
        name
        ruleID
      }
      defaultApproverSchedule {
        ID
        defaultApprover {
          ID
          name
        }
        effectiveDate
        fromEffectiveDate
      }
      travelPolicies @include(if: $withTravelPolicies)
      integrationInfo {
        spotnana {
          externalID
          persona
        }
      }
      loginRestricted
      office
    }

    costCenters(archived: false) {
      ID
      name
    }
    travelPolicies(orgID: $orgID) @include(if: $withTravelPolicies) {
      ID
      name
      isDefault
    }
    offices(tmc: $tmc) @include(if: $withOffices) {
      id
      name
    }
  }
  ${CARD_FRAGMENT}
  ${ADDRESS_FRAGMENT}
`;

export const GET_USER = gql`
  query user($id: ID!) {
    user(id: $id) {
      ID
      fullName
    }
  }
`;

export const GET_USER_ORGS = gql`
  query userOrgs($loginID: String) {
    userOrgs(loginID: $loginID) {
      userID
      orgID
      orgName
    }
  }
`;

export const GET_USER_FULLNAMES_WITH_SEARCH = gql`
  query users($limit: Int, $archived: Boolean, $prefix: String, $offset: String) {
    users(limit: $limit, archived: $archived, prefix: $prefix, offset: $offset) {
      items {
        ID
        fullName
      }
      pagination {
        next
        previousOffset
        offset
        pages
        totalItems
      }
    }
  }
`;

export const GET_USER_FULLNAMES_WITH_SEARCH_AND_ROLES = gql`
  query users($limit: Int, $archived: Boolean, $prefix: String, $offset: String) {
    users(limit: $limit, archived: $archived, prefix: $prefix, offset: $offset) {
      items {
        ID
        fullName
        roles
      }
      pagination {
        next
        previousOffset
        offset
        pages
        totalItems
      }
    }
  }
`;

export const GET_USER_ROLES_ADDRESS = gql`
  query users($limit: Int, $prefix: String, $offset: String) {
    users(limit: $limit, archived: false, prefix: $prefix, offset: $offset) {
      items {
        ID
        fullName
        emailAddress
        archived
        roles
      }
      pagination {
        next
        previousOffset
        offset
        pages
        totalItems
      }
    }
  }
`;
