import { memo, useEffect, useState } from "react";
import GoogleMap from "google-map-react";
import _ from "lodash";
import PropTypes from "prop-types";
import styled from "styled-components";

import TripRenderer from "./TripRenderer";

const Wrapper = styled.div`
  height: 100%;
  width: 100%;

  button.gm-ui-hover-effect {
    visibility: hidden;
  }
`;

const defaultBellevueCenter = {
  lat: 47.61898,
  lng: -122.19953,
};

const mapOptions = {
  fullscreenControl: false,
  zoomControl: false,
};

// The main map component, empty key means that it will use the development key
const Map = ({
  location,
  zoom,
  alternativeRouteEnabled = true,
  unitSystem,
  origin,
  destination,
  waypoints,
  isRouteChanged,
  zeroDistanceRoute,
  onChange,
  mapRef,
  apiLoadedCallback,
}) => {
  const [tripRenderer, setTripRenderer] = useState(null);

  // This refreshes stuff whenever any of the inputs change
  useEffect(() => {
    if (tripRenderer) {
      const nonEmptyPoints = waypoints.filter(
        (point) => _.isObject(point.location) || !_.isEmpty(point.location?.trim()),
      );
      if (isRouteChanged) {
        // don't have to wait for map to load tiles if there are only origin and destination and this are the same point
        // because that kind of routes does not provoke tiles loading
        tripRenderer.findAndRenderRoutes(
          {
            origin,
            destination,
            waypoints: nonEmptyPoints,
          },
          {
            waitForTilesLoaded: !zeroDistanceRoute,
          },
        );
      }
    }
  }, [origin, destination, waypoints, tripRenderer, isRouteChanged, zeroDistanceRoute]);

  return (
    <Wrapper data-testid="map-distance-calculator">
      <GoogleMap
        center={location || defaultBellevueCenter}
        zoom={zoom}
        options={mapOptions}
        // This is needed to use Polylines and other features that aren't
        // exposed by google-map-react
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps: mapServices }) => {
          if (!tripRenderer) {
            const renderer = new TripRenderer({
              map,
              mapServices,
              alternativeRouteEnabled,
              unitSystem,
              onChange,
            });
            setTripRenderer(renderer);
            // eslint-disable-next-line no-param-reassign
            mapRef.current = renderer;
          }
          if (apiLoadedCallback && typeof apiLoadedCallback === "function") {
            apiLoadedCallback({ map, mapServices });
          }
        }}
      />
    </Wrapper>
  );
};

Map.propTypes = {
  origin: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
  ]),
  destination: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
  ]),
  waypoints: PropTypes.array,
  zoom: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  location: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  mapOptions: PropTypes.shape({
    fullscreenControl: PropTypes.bool,
    zoomControl: PropTypes.bool,
  }),
  alternativeRouteEnabled: PropTypes.bool,
  unitSystem: PropTypes.string,
  mapRef: PropTypes.object,
  isRouteChanged: PropTypes.bool,
  zeroDistanceRoute: PropTypes.bool,
};

Map.defaultProps = {
  mapOptions: {},
  zoom: 10,
  unitSystem: "mi",
  waypoints: [],
  zeroDistanceRoute: false,
};

export default memo(Map);
