import { forwardRef, type ElementType, type HTMLAttributes } from "react";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "../../lib/utils";

export type Variant = NonNullable<VariantProps<typeof textVariants>["variant"]>;
export type Weight = NonNullable<VariantProps<typeof textVariants>["weight"]>;

const textVariants = cva("m-0 text-base", {
  variants: {
    variant: {
      h1: "text-2xl",
      h2: "text-lg",
      body: "text-base",
      large: "text-xl",
      medium: "text-md",
      subtext: "text-sm",
      small: "text-xs uppercase",
    },
    weight: {
      "extra-bold": "font-bold",
      bold: "font-semibold",
      normal: "font-normal",
      light: "font-[350]",
    },
    ellipsis: {
      true: "overflow-hidden overflow-ellipsis whitespace-nowrap",
    },
  },
  defaultVariants: {
    variant: "body",
    weight: "normal",
    ellipsis: false,
  },
});

type BaseProps = Omit<HTMLAttributes<HTMLElement>, "style">;
export type TextProps = BaseProps &
  VariantProps<typeof textVariants> & {
    as?: ElementType;
  };

const componentMap: Record<Variant, ElementType> = {
  h1: "h1",
  h2: "h2",
  body: "p",
  large: "p",
  medium: "p",
  subtext: "p",
  small: "p",
};

export const Text = forwardRef<HTMLHeadingElement, TextProps>(
  ({ className, variant, weight, color = "default", ellipsis, ...props }, ref) => {
    const Comp = componentMap[variant || "body"];
    const colorClass = color === "default" ? "" : `text-${color}`;
    return (
      <Comp
        className={cn(textVariants({ variant, weight, ellipsis }), colorClass, className)}
        ref={ref}
        {...props}
      />
    );
  },
);
