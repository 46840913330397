/* eslint-disable react/jsx-props-no-spreading */
import { PureComponent } from "react";
import PropTypes from "prop-types";

import ConfigProvider from "../../components/ConfigProvider";
import Empty from "../../components/Empty";

// eslint-disable-next-line function-name/starts-with-verb
export const withEmptyState = (WrappedComponent) => {
  class WithEmptyState extends PureComponent {
    render() {
      const { emptyStateParams, renderEmpty, showDefaultEmptyState, ...rest } = this.props;

      return showDefaultEmptyState ? (
        <WrappedComponent {...rest} />
      ) : (
        <ConfigProvider
          renderEmpty={() => (renderEmpty ? renderEmpty() : <Empty {...emptyStateParams} />)}
        >
          <WrappedComponent {...rest} />
        </ConfigProvider>
      );
    }
  }

  WithEmptyState.propTypes = {
    showDefaultEmptyState: PropTypes.bool,
    emptyStateParams: PropTypes.shape({
      description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      image: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      alt: PropTypes.string,
      styling: PropTypes.object,
    }),
    renderEmpty: PropTypes.func,
  };

  WithEmptyState.defaultProps = {
    showDefaultEmptyState: false,
  };

  return WithEmptyState;
};

export default withEmptyState;
