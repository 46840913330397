import _forEach from "lodash/forEach";
import _get from "lodash/get";
import { useApolloClient, useMutation, useQuery } from "react-apollo";

import { generateRandomString } from "@core/ui-legacy/utils";

import { SET_ASYNC_JOBS } from "../services/graphql/mutations";
import { GET_ASYNC_JOBS } from "../services/graphql/queries";
import API from "../services/rest/api";
import { ASYNC_JOB_PARAMS, ASYNC_JOB_STATUS, processAsyncJobs } from "../utils/asyncJobs";

type ExportReportVariables = {
  end: string;
  filterByDate: string;
  format: string;
  jobType: string;
  name: string;
  start: string;
  expenseStatuses?: string[];
  filePostingDate?: string;
  filter?: string;
  viewName?: string;
};

type JobRecord = {
  ID?: string;
  callback?: () => void;
  jobType?: string;
  noToast?: boolean;
  operationType?: string;
  shouldDelete?: boolean;
  status?: "pending" | "failed" | "complete" | "completeWithWarning";
  timeStamp?: string;
  user?: string;
  variables?:
    | {
        input: {
          operationType: string;
          operations: string[];
        };
      }
    | ExportReportVariables;
};

const useAsyncJobs = (): [
  {
    hasPendingJob: string;
    jobs: JobRecord[];
  },
  (job: JobRecord) => Promise<void>,
  (jobID: string) => Promise<void>,
] => {
  const client = useApolloClient();
  const [setAsyncJobs] = useMutation(SET_ASYNC_JOBS);
  const { data: asyncJobsData = {} } = useQuery(GET_ASYNC_JOBS);
  const jobs: JobRecord[] = _get(asyncJobsData, "asyncJobs.jobs", []);
  const hasPendingJob = _get(asyncJobsData, "asyncJobs.hasPendingJob", false);

  const setAsyncJob = async (job: JobRecord) => {
    const { ID: jobID, jobType, variables, noToast, callback } = job || {};
    const { mutation } = _get(ASYNC_JOB_PARAMS, jobType!) || {};

    if (noToast) {
      return client.mutate({ mutation, variables }).then((res) => {
        if (_get(ASYNC_JOB_PARAMS, jobType!).onComplete) {
          _get(ASYNC_JOB_PARAMS, jobType!).onComplete({
            job,
            callback,
            client,
            data: res.data,
          });
        }
      });
    }

    if (jobID) {
      // Update async job in cache
      await setAsyncJobs({
        variables: {
          asyncJobsData: processAsyncJobs(job, client),
        },
      });
    } else {
      const tempID = generateRandomString("tempAsyncJob");
      const deleteTempJob = () =>
        // Delete temp job
        setAsyncJobs({
          variables: {
            asyncJobsData: processAsyncJobs(
              {
                ID: tempID,
                shouldDelete: true,
              },
              client,
            ),
          },
        });

      // Add temp job immediately to affect UI
      await setAsyncJobs({
        variables: {
          asyncJobsData: processAsyncJobs(
            {
              ID: tempID,
              jobType,
            },
            client,
          ),
        },
      });

      await client
        .mutate({ mutation, variables })
        .then(async ({ data }) => {
          let ID = null;
          // Traverse data response to find job ID (expect 1 level deep)
          _forEach(data, (dataNode) => {
            _forEach(dataNode, (val, key) => {
              if (key === "ID") ID = val;
            });
          });
          if (!ID)
            API.logError(API.LogLevel.error, {
              message: `Unable to get job ID from data on ${jobType} mutation`,
              details: { data },
            });
          await setAsyncJobs({
            variables: {
              asyncJobsData: processAsyncJobs({ ...job, ID }, client),
            },
          });
          await deleteTempJob();
        })
        .catch((err) => {
          setAsyncJobs({
            variables: {
              asyncJobsData: processAsyncJobs(
                {
                  ID: tempID,
                  status: ASYNC_JOB_STATUS.Error,
                  jobType,
                },
                client,
              ),
            },
          });
          API.logError(API.LogLevel.error, {
            message: "GraphQL error in submitJob() mutation",
            details: {
              error: err,
            },
          });
        });
    }
    return Promise.resolve();
  };

  const upsertAsyncJob = setAsyncJob;
  const deleteAsyncJob = (jobID: string) => setAsyncJob({ ID: jobID, shouldDelete: true });

  return [{ jobs, hasPendingJob }, upsertAsyncJob, deleteAsyncJob];
};

export default useAsyncJobs;
