import PropTypes from "prop-types";
import { Link as RouteLink } from "react-router-dom";
import styled, { keyframes } from "styled-components";

import { Icon } from "@core/ui-legacy";
import Colors from "@core/ui-legacy/themes/colors";
import { styleConstructor } from "@core/ui-legacy/utils";

import { ReactComponent as RightArrowImage } from "../../assets/imgs/right-arrow-link.svg";
import { ReactComponent as UpArrowImage } from "../../assets/imgs/up-arrow-link.svg";

const slideRight = keyframes`
  from { transform:translateX(0px); }
  to { transform:translateX(4px); }
`;

const slideUp = keyframes`
  from { transform:translateY(0px); }
  to { transform:translateY(-2px); }
`;

const Wrapper = styled.span`
  display: inline-block;
  color: ${Colors.link_blue};

  :hover {
    opacity: ${({ arrowDirection }) => (arrowDirection ? 1 : 0.8)};
  }
`;

const LinkStyled = styled(RouteLink)`
  ${({ styling }) =>
    styleConstructor({
      fontWeight: 400,
      color: Colors.link_blue,
      ...styling,
    })};
  :hover {
    color: ${Colors.link_blue};
  }
`;

const AnchorStyled = styled.a`
  ${({ styling }) =>
    styleConstructor({
      color: Colors.link_blue,
      ...styling,
    })};
  :hover {
    color: ${Colors.link_blue};
  }
`;

const RightArrow = styled(RightArrowImage)`
  display: inline-block;
  width: 1.125rem;
  height: 1rem;
  margin-left: 6px;
  vertical-align: text-bottom;
  ${Wrapper}:hover & {
    animation: ${slideRight} 0.2s linear forwards;
  }
`;

const UpArrow = styled(UpArrowImage)`
  display: inline-block;
  height: 1.125rem;
  margin-left: 6px;
  ${Wrapper}:hover & {
    animation: ${slideUp} 0.2s linear forwards;
  }
`;

const Button = styled.button`
  ${({ styling }) =>
    styleConstructor({
      backgroundColor: "transparent",
      display: "inherit",
      border: "none",
      cursor: "pointer",
      padding: 0,
      ...styling,
    })};
  :hover {
    opacity: 0.9;
  }

  :focus {
    outline: none;
  }
`;

const LaodingSpin = styled(Icon)`
  margin-right: 0.25rem;
`;

const Link = ({ children, to, href, onClick, arrowDirection, loading, className, ...rest }) => {
  const rightArrow =
    arrowDirection === "right" ? <RightArrow role="img" aria-label="Right Arrow" /> : "";

  const upArrow = arrowDirection === "up" ? <UpArrow role="img" aria-label="Up Arrow" /> : "";

  const renderLink = () => {
    if (to) {
      return (
        <LinkStyled to={to} {...rest}>
          {children}
          {rightArrow}
          {upArrow}
        </LinkStyled>
      );
    }

    if (href) {
      return (
        <AnchorStyled href={href} {...rest}>
          {children}
          {rightArrow}
          {upArrow}
        </AnchorStyled>
      );
    }

    if (onClick) {
      return (
        <>
          {loading && <LaodingSpin type="loading" />}
          <Button onClick={onClick} type="button" {...rest}>
            {children}
            {rightArrow}
            {upArrow}
          </Button>
        </>
      );
    }
    return null;
  };
  return (
    <Wrapper className={className} arrowDirection={arrowDirection}>
      {renderLink()}
    </Wrapper>
  );
};

Link.propTypes = {
  children: PropTypes.node,
  arrowDirection: PropTypes.oneOf(["right", "up"]),
  to: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  className: PropTypes.string,
};

export default Link;
