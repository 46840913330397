import { Identify, metricEvents, type IdentifyProperties } from "@core/metric-events/browser";

import type { IAuthUser } from "./features/UserInfoPage/types";

const prodURL = "my.app-center.com";
const rcURL = "rc.app-center.com";
const stageURL = "stage.app-center.com";

const groupType = "Customer Account";

export const HOST = window.location.host;

// eslint-disable-next-line function-name/starts-with-verb
export const environment = () => {
  if (HOST === prodURL || HOST === rcURL) {
    return "prod";
  }

  if (HOST === stageURL) {
    return "stage";
  }

  return "dev";
};

export const initiateMetrics = (user: IAuthUser) => {
  metricEvents.load({
    client: {
      apiKey: import.meta.env.REACT_APP_METRICS_API_KEY,
      configuration: {
        userId: user.ID,
        defaultTracking: {
          formInteractions: false,
        },
      },
    },
  });

  // Identify User
  const setOnceProperties: IdentifyProperties = {
    "first log-in date": new Date().toISOString(),
  };

  const setOnceIdentify = new Identify();
  Object.entries(setOnceProperties).forEach(([key, value]) => {
    setOnceIdentify.setOnce(key, value);
  });

  metricEvents.client.identify(setOnceIdentify, { user_id: user.ID });

  metricEvents.identify(user.ID, {
    "org id": user.organization.ID,
    "org name": user.organization.name,
    "user roles": user.roles,
  });

  // Identify Group
  const groupIdentify = new Identify();
  groupIdentify.set("org type", user.organization.sandbox ? "sandbox" : "customer");

  metricEvents.client.groupIdentify(groupType, user.organization.ID, groupIdentify);

  // Assign User to Group
  metricEvents.client.setGroup(groupType, user.organization.ID, { user_id: user.ID });
};
