import styled from "styled-components";

import Colors from "@core/ui-legacy/themes/colors";

const SimpleButtonStyled = styled.button`
  background-color: transparent;
  display: inherit;
  border: 0;
  padding: 0;

  :not([disabled]) {
    cursor: pointer;
  }

  :focus {
    outline: none;
  }

  :disabled {
    color: ${Colors.monumental} !important;
  }
`;

const SimpleButton = (props) => <SimpleButtonStyled type="button" {...props} />;

export default SimpleButton;
