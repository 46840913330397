import { PureComponent } from "react";
import PropTypes from "prop-types";

class TablePure extends PureComponent {
  render() {
    const { children, ...rest } = this.props;
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <table {...rest}>{children}</table>;
  }
}

TablePure.propTypes = {
  children: PropTypes.any,
};

export default TablePure;
