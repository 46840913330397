import { lazy, Suspense, useEffect, useMemo } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { createHttpLink } from "apollo-link-http";
import { createAppSyncLink } from "aws-appsync";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import gql from "graphql-tag";
import moment from "moment";
import PropTypes from "prop-types";
import { ApolloProvider, graphql, useMutation } from "react-apollo";
import { Redirect, Route, BrowserRouter as Router, Switch } from "react-router-dom";
import SimpleBar from "simplebar-react";
import styled from "styled-components";

import { featureFlagsRecord, useFlag } from "@core/feature-flags/clients";
import { Notifier } from "@core/ui-legacy";
import { userAccessBlackoutText } from "@core/ui-legacy/utils";

import AppSyncConfig from "./AppSync";
import { AuthRoute, UnauthRoute } from "./common/authorizer";
import { UserRoles } from "./common/roles";
import { getRouterData } from "./common/router";
import { TokenRefreshLink } from "./common/tokenRefreshLink";
import GlobalStyles from "./globalStyles";
import BlankLayout from "./layouts/BlankLayout";
import { DashboardLayout } from "./layouts/DashboardLayout";
import { GET_AUTH } from "./services/graphql/queries";
import localResolvers from "./services/graphql/resolvers";
import API, { queryConstructor } from "./services/rest/api";
import { aSlot, slot } from "./slotConfig";
import FeatureFlags from "./utils/featureFlags";
import * as Routes from "./utils/routes";
import Storage from "./utils/storage";

import "simplebar-react/dist/simplebar.min.css";

import { ContextHolder } from "@frontegg/react";
import LogRocket from "logrocket";

import { Toaster } from "@core/ui";

import { AppBranding } from "./AppBranding";
import OrgSwitcherInterceptor from "./common/OrgSwitcherInterceptor";
import { SET_AUTH } from "./services/graphql/mutations";

dayjs.extend(utc);
dayjs.extend(timezone);

const ReactQueryDevtools = lazy(() => {
  if (import.meta.env.CI || !import.meta.env.DEV) {
    return Promise.resolve({ default: () => null });
  }

  return import("@tanstack/react-query-devtools").then((d) => ({
    default: d.ReactQueryDevtools,
  }));
});

const SlotIndicator = styled("div")`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0;
  border: 0;
  padding: 0;
  background: #ff0;
  opacity: 0.5;
  pointer-events: none;
  text-align: center;
`;
const AppSlotIndicator = styled(SlotIndicator)`
  top: 1.5rem;
  background: #ff00dc;
`;

const StyledSimpleBar = styled(SimpleBar)`
  height: 100vh;
  overflow-x: hidden;

  .simplebar-content {
    height: 100%;
  }
`;

const { ALL, FM, EA, SP, IV, TM, TA, Traveler } = UserRoles;

let authObj = null;
let client = null;

const url = AppSyncConfig.graphqlEndpoint;

export const cache = new InMemoryCache({
  dataIdFromObject: ({
    ID,
    __typename,
    ruleID,
    fileID,
    formID,
    sourceType,
    property,
    expenseID,
    description,
    rawName,
    name,
  }) => {
    if (__typename === "Approver") return `${__typename}:${ruleID}`;
    if (__typename === "CurrentApproverUser") return null;
    if (__typename === "CostCenter") return `${__typename}:${ID}`;
    if (__typename === "Receipt") return `${__typename}:${fileID}`;
    if (__typename === "SpendSummary") return null;
    if (__typename === "SpendData") return null;
    if (__typename === "ERPSourceType") return `${__typename}:${sourceType}`;

    if (__typename === "ExpensePolicyFormField") {
      return `${__typename}:${ID}:${property}:${expenseID}`;
    }
    if (__typename === "EvaluatedExpenseFormField") {
      return `${__typename}:${ID}:${property}:${expenseID}:${formID}`;
    }
    if (__typename === "Flag") return `${__typename}:${ID}:${description}`;
    if (__typename === "Merchant") {
      // TRAV-413: caching by ID makes for wrong tooltip
      return `${__typename}:${ID}:${rawName || name || ""}`;
    }
    if (__typename === "LoyaltyProgramsOption") return `${__typename}:${ID}:${name}`;
    if (ID) return `${__typename}:${ID}`;

    return null;
  },
});

cache.writeData({ data: localResolvers.data });

let recordingURL = "";
LogRocket.getSessionURL((sessionURL) => {
  recordingURL = sessionURL;
});
const fetch = (...args) => {
  try {
    const parsedBody = JSON.parse(args[1]?.body);
    if (parsedBody) {
      const { operationName, query } = parsedBody;
      const [operationType] = query.split(" ");
      // eslint-disable-next-line no-param-reassign
      args[0] += `?operationName=${operationName}&operationType=${operationType}`;
    }
  } catch {
    // Do nothing
  }
  if (recordingURL && args[1]?.headers) {
    // eslint-disable-next-line no-param-reassign
    args[1].headers["X-LogRocket-URL"] = recordingURL;
  }
  return window.fetch(...args);
};

const appSyncLink = createAppSyncLink({
  url,
  region: AppSyncConfig.region,
  resultsFetcherLink: createHttpLink({ uri: url, fetch }),
  auth: {
    type: AppSyncConfig.authenticationType,
    apiKey: AppSyncConfig.apiKey,
  },
});

const httpLink = createHttpLink({ uri: url, fetch });

const refreshTokenLink = new TokenRefreshLink({
  isTokenValidOrUndefined: () => {
    // If there's a Frontegg token, we don't need to refresh the token manually
    if (ContextHolder.getUser()?.accessToken) {
      return true;
    }

    return !authObj || !moment.utc().isAfter(authObj.expires_on);
  },
  fetchAccessToken: () => API.refreshToken(),
  handleFetch: (accessTokenObj) => {
    authObj = accessTokenObj;
    Storage.setItem("auth", JSON.stringify(authObj));
  },
  handleError: (err) => {
    window.console.error(err);
    API.logout();
    authObj = null;
  },
});

const notFoundPageLinks = [{ href: Routes.CONTACT_SUPPORT, label: "Home" }];

const contextLink = setContext((request, previousContext) => {
  const fronteggUser = ContextHolder.getUser();

  return {
    headers: {
      ...previousContext.headers,
      "x-center-cloud-slot": slot || null,
      "x-center-web-package-version": import.meta.env.REACT_APP_VERSION || null,
      Authorization: `Bearer ${fronteggUser?.accessToken || authObj?.access_token}`,
    },
  };
});

const handleForbiddenError = async () => {
  const fronteggUser = ContextHolder.getUser();
  if (fronteggUser?.accessToken) {
    await API.logout();

    return;
  }

  const refreshTokenRequest = await API.refreshToken();

  // if we can't refresh token - there is something really wrong with the session, for example, when we're blocked
  if (refreshTokenRequest.status === 400) {
    API.logout();
  }
};

const errorLink = onError(({ graphQLErrors, networkError, response }) => {
  // Fetching me from cache
  const GET_ME = gql`
    query {
      me @client {
        ID
        fullName
        roles
        loginID
        avatarBackgroundColor
        organization {
          ID
          name
        }
      }
    }
  `;
  let me;
  try {
    me = client.readQuery({ query: GET_ME });
  } catch {
    me = null;
  }
  if (graphQLErrors?.some((x) => x.errorType === "403")) {
    void handleForbiddenError();
  }
  if (graphQLErrors) {
    API.logError(API.LogLevel.error, {
      message: "GraphQL error",
      details: {
        error: graphQLErrors.map((e) => e.message),
        info: graphQLErrors.map(({ locations, path }) => ({
          locations,
          path,
        })),
        user: me,
      },
    });
  }
  if (networkError) {
    API.logError(API.LogLevel.error, {
      message: "Network error",
      details: {
        error: networkError,
        user: me,
      },
    });
  } else {
    // If there's no networkError, then query succeeded with errors in some fields
    // Because of that Apollo client will try to parse data and fail if it is null
    // This happens at least for mutations and prevents user from seeing correct
    // error notification as returned by server
    response.data ??= {};
  }
});

const omitTypename = (key, value) => (key === "__typename" ? undefined : value);
const cleanTypenameLink = new ApolloLink((operation, forward) => {
  if (operation.variables && !operation.variables.file) {
    operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
  }

  return forward(operation);
});

const link = ApolloLink.from([
  refreshTokenLink,
  contextLink,
  cleanTypenameLink,
  errorLink,
  appSyncLink,
  createSubscriptionHandshakeLink(url, httpLink),
]);

client = new ApolloClient({
  cache,
  link,
  resolvers: localResolvers.resolvers,
});

API.setGraphqlClient(client, localResolvers);

const routerData = getRouterData();

const NotFoundPage = routerData[Routes.NOT_FOUND_PAGE].component;
const LoginPage = routerData[Routes.LOGIN].component;
const ForgotPasswordPage = routerData[Routes.FORGOT_PASSWORD].component;
const ResetPasswordPage = routerData[Routes.RESET_PASSWORD].component;
const CreatePasswordPage = routerData[Routes.CREATE_PASSWORD].component;

const App = (props) => {
  const { isAuthenticated, loading } = props;
  const [setAuthMutation] = useMutation(SET_AUTH, {
    ignoreResults: true,
  });
  const shouldSignOutTheUser = useFlag("kill-switch-user-access-blackout");

  useEffect(() => {
    async function ensureUserAccessBlackout() {
      if (shouldSignOutTheUser && isAuthenticated) {
        await API.logout();
        Notifier.notification({
          type: "info",
          description: userAccessBlackoutText,
          duration: 20,
        });
      }
    }
    ensureUserAccessBlackout();
  }, [shouldSignOutTheUser, isAuthenticated]);

  const queryString = useMemo(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const orgID = queryParams.get("orgid");
    return queryConstructor({ orgID });
  }, []);

  if (loading) return null;

  if (!isAuthenticated && Storage.getItem("shouldRunSSOSetAuth") === "true") {
    Storage.removeItem("shouldRunSSOSetAuth");
    setAuthMutation({ variables: { isAuthenticated: true } });
    return null;
  }

  const routesProps = {
    exact: true,
    redirectTo: Routes.LOGIN,
    component: DashboardLayout,
    authenticated: isAuthenticated,
  };

  if (queryString) {
    routesProps.search = queryString;
  }

  return (
    <Router>
      <OrgSwitcherInterceptor isAuthenticated={isAuthenticated}>
        <Switch>
          <AuthRoute {...routesProps} path={Routes.HOME} showSideNav={false} authority={[ALL]} />
          <AuthRoute {...routesProps} path={Routes.MYCARD_OVERVIEW} authority={[SP]} />
          <AuthRoute
            {...routesProps}
            path={Routes.SPEND_CARDS}
            authority={[SP]}
            featureFlags={[FeatureFlags.VIRTUAL_MULTI_CARD]}
          />
          <AuthRoute {...routesProps} path={Routes.MYCARD_EXPENSES} authority={[SP]} />
          <AuthRoute
            {...routesProps}
            path={Routes.SPEND_EXPENSES}
            authority={[SP]}
            featureFlags={[FeatureFlags.VIRTUAL_MULTI_CARD]}
          />
          <AuthRoute
            {...routesProps}
            path={Routes.MYCARD_TRIPS}
            authority={[FM, TM, TA, Traveler]}
            featureFlags={[FeatureFlags.TRIPS_HUB]}
          />
          <AuthRoute
            {...routesProps}
            path={Routes.SPEND_TRIPS}
            authority={[FM, TM, TA, Traveler]}
            featureFlags={[FeatureFlags.TRIPS_HUB, FeatureFlags.VIRTUAL_MULTI_CARD]}
          />
          <AuthRoute
            {...routesProps}
            path={Routes.MYCARD_TRIP_DETAILS}
            authority={[FM, TM, TA, Traveler]}
            featureFlags={[FeatureFlags.TRIPS_HUB]}
          />
          <AuthRoute
            {...routesProps}
            path={Routes.SPEND_TRIP_DETAILS}
            authority={[FM, TM, TA, Traveler]}
            featureFlags={[FeatureFlags.TRIPS_HUB, FeatureFlags.VIRTUAL_MULTI_CARD]}
          />
          <AuthRoute
            {...routesProps}
            fixedHeight
            noContainer
            path={Routes.MYCARD_EXPENSE_DETAILS}
            showSideNav={false}
            authority={[SP]}
          />
          <AuthRoute
            {...routesProps}
            fixedHeight
            noContainer
            path={Routes.SPEND_EXPENSE_DETAILS}
            showSideNav={false}
            authority={[SP]}
            featureFlags={[FeatureFlags.VIRTUAL_MULTI_CARD]}
          />
          <AuthRoute {...routesProps} path={Routes.CENTERACCOUNT_SUMMARY} authority={[FM]} />
          <AuthRoute {...routesProps} path={Routes.CENTERACCOUNT_TRANSACTIONS} authority={[FM]} />
          <AuthRoute {...routesProps} path={Routes.LINKED_ACCOUNTS} authority={[FM]} />
          <AuthRoute {...routesProps} path={Routes.STATEMENTS} authority={[FM]} />

          <AuthRoute
            {...routesProps}
            path={Routes.USER_CREATE}
            noContainer
            authority={[FM, TM, TA]}
          />
          <AuthRoute {...routesProps} path={Routes.USER_EDIT} noContainer authority={[FM]} />
          <AuthRoute {...routesProps} path={Routes.USER_INFO} authority={[FM]} noContainer />
          <AuthRoute {...routesProps} path={Routes.USERS} authority={[FM]} />
          <AuthRoute {...routesProps} path={Routes.COST_CENTERS} authority={[FM]} />
          <AuthRoute {...routesProps} path={Routes.COST_CENTERS_CREATE} authority={[FM]} />
          <AuthRoute {...routesProps} path={Routes.COST_CENTERS_EDIT} authority={[FM]} />
          <AuthRoute {...routesProps} path={Routes.EXPENSE_TYPES} authority={[FM]} />
          <AuthRoute {...routesProps} path={Routes.EXPENSE_TYPES_CREATE} authority={[FM]} />
          <AuthRoute {...routesProps} path={Routes.EXPENSE_TYPES_EDIT} authority={[FM]} />
          <AuthRoute {...routesProps} path={Routes.GENERAL_LEDGER} authority={[FM]} />
          <AuthRoute {...routesProps} path={Routes.GENERAL_LEDGER_CREATE} authority={[FM]} />
          <AuthRoute {...routesProps} path={Routes.GENERAL_LEDGER_EDIT} authority={[FM]} />
          <AuthRoute {...routesProps} path={Routes.EXPENSE_FORMS} authority={[FM]} />
          <AuthRoute {...routesProps} path={Routes.EXPENSE_FORMS_EDIT} authority={[FM]} />
          <AuthRoute {...routesProps} path={Routes.EXPENSE_FIELDS} authority={[FM]} />
          <AuthRoute {...routesProps} path={Routes.EXPENSE_FIELDS_CREATE} authority={[FM]} />
          <AuthRoute {...routesProps} path={Routes.EXPENSE_FIELDS_EDIT} authority={[FM]} />
          <AuthRoute
            {...routesProps}
            path={Routes.EXPENSE_FIELDS_MAPPING_CONFIGURATION}
            authority={[FM]}
          />
          <AuthRoute {...routesProps} path={Routes.INTEGRATIONS} authority={[FM]} />
          <AuthRoute {...routesProps} path={Routes.INTEGRATIONS_CONNECTORS} authority={[FM]} />
          <AuthRoute
            {...routesProps}
            path={Routes.INTEGRATIONS_SETUP}
            showSideNav
            authority={[FM]}
          />
          <AuthRoute
            {...routesProps}
            path={Routes.INTEGRATIONS_IMPORT_SOURCE}
            showSideNav={false}
            authority={[FM]}
          />
          <AuthRoute {...routesProps} path={Routes.INTEGRATIONS_EXPORT_SOURCE} authority={[FM]} />
          <AuthRoute
            {...routesProps}
            path={Routes.EXPENSE_HUB}
            showSideNav={false}
            authority={[FM]}
          />
          <AuthRoute
            {...routesProps}
            path={Routes.MY_APPROVALS}
            showSideNav={false}
            authority={[FM, EA]}
          />
          <AuthRoute
            {...routesProps}
            path={Routes.MY_APPROVALS_CARDS}
            showSideNav
            authority={[FM]}
            featureFlags={[FeatureFlags.TRAVEL_GUEST_TRAVELER]}
          />
          <AuthRoute
            {...routesProps}
            path={Routes.MY_APPROVALS_EXPENSES}
            showSideNav
            authority={[FM]}
            featureFlags={[FeatureFlags.TRAVEL_GUEST_TRAVELER]}
          />
          <AuthRoute {...routesProps} path={Routes.REPORTS} showSideNav={false} authority={[FM]} />
          <AuthRoute
            {...routesProps}
            fixedHeight
            noContainer
            path={Routes.EXPENSEHUB_EXPENSE_DETAILS}
            showSideNav={false}
            authority={[FM]}
          />
          <AuthRoute
            {...routesProps}
            fixedHeight
            noContainer
            path={Routes.INSIGHTS_EXPENSE_DETAILS}
            showSideNav={false}
            authority={[FM, IV]}
          />
          <AuthRoute
            {...routesProps}
            fixedHeight
            noContainer
            path={Routes.MY_APPROVALS_EXPENSE_DETAILS}
            showSideNav={false}
            authority={[FM, EA]}
          />
          <AuthRoute
            {...routesProps}
            path={Routes.INSIGHTS_TRAVEL_TAB}
            showSideNav={false}
            authority={[FM, IV]}
            featureFlags={[featureFlagsRecord.releaseTravelInsights]}
          />
          <AuthRoute
            {...routesProps}
            path={Routes.INSIGHTS}
            showSideNav={false}
            authority={[FM, IV]}
          />
          <AuthRoute
            {...routesProps}
            path={Routes.INSIGHTS_EXPENSE}
            showSideNav={false}
            authority={[FM, IV]}
          />
          <AuthRoute
            {...routesProps}
            path={Routes.INSIGHTS_DRILL_DOWN}
            showSideNav={false}
            authority={[FM, IV]}
          />
          <AuthRoute
            {...routesProps}
            path={Routes.INSIGHTS_EXPENSE_DRILL_DOWN}
            showSideNav={false}
            authority={[FM, IV]}
          />
          <AuthRoute
            {...routesProps}
            path={Routes.RULES_CUSTOM_APPROVAL}
            showSideNav
            authority={[FM]}
          />
          <AuthRoute
            {...routesProps}
            path={Routes.CHANGE_PASSWORD}
            component={BlankLayout}
            authority={[ALL]}
          />
          <AuthRoute
            {...routesProps}
            path={Routes.EXPENSE_FORMS_EDIT}
            showSideNav
            authority={[FM]}
          />
          <AuthRoute {...routesProps} path={Routes.SFTP} authority={[FM]} />
          <AuthRoute
            {...routesProps}
            path={Routes.REIMBURSEMENTS_POLICY}
            authority={[FM]}
            featureFlags={[FeatureFlags.REIMBURSEMENTS]}
          />
          <AuthRoute
            {...routesProps}
            path={Routes.REIMBURSEMENTS_TRANSFERS}
            authority={[FM]}
            featureFlags={[FeatureFlags.REIMBURSEMENTS]}
          />
          <AuthRoute
            {...routesProps}
            path={Routes.REIMBURSEMENTS_SETTINGS}
            authority={[ALL]}
            featureFlags={[FeatureFlags.REIMBURSEMENTS]}
          />
          <AuthRoute {...routesProps} path={Routes.ACCOUNT_INFO_SETTINGS} authority={[ALL]} />
          <AuthRoute
            {...routesProps}
            path={Routes.TRAVEL_INFO_SETTINGS}
            authority={[TM, TA, Traveler]}
            featureFlags={[FeatureFlags.USER_TRAVEL]}
          />
          <AuthRoute
            {...routesProps}
            path={Routes.TRAVEL_INFO_USER_SETTINGS}
            authority={[TM]}
            featureFlags={[FeatureFlags.TRAVELER_PROFILES]}
          />
          <AuthRoute
            {...routesProps}
            path={Routes.TRAVEL_DETAILS}
            authority={[FM, TM]}
            featureFlags={[FeatureFlags.TRAVEL_DETAILS]}
          />
          <AuthRoute
            {...routesProps}
            path={Routes.TRAVEL_POLICY}
            authority={[TM]}
            featureFlags={[FeatureFlags.TRAVEL_POLICY]}
          />
          <AuthRoute
            {...routesProps}
            path={Routes.NEW_TRAVEL_POLICY_GROUP}
            authority={[TM]}
            featureFlags={[FeatureFlags.SPOTNANA_IFRAME_NEW_POLICY]}
          />
          <AuthRoute
            {...routesProps}
            path={Routes.TRAVEL_POLICY_GROUP}
            authority={[TM]}
            featureFlags={[FeatureFlags.TRAVEL_POLICY]}
          />
          <AuthRoute {...routesProps} path={Routes.NOTIFICATIONS} authority={[FM]} />
          <AuthRoute
            {...routesProps}
            path={Routes.EXPORT_CONFIGURATION}
            showSideNav
            authority={[FM]}
          />
          <AuthRoute
            {...routesProps}
            path={Routes.LOGIN_SSO_CONFIGURATION}
            showSideNav
            authority={[FM]}
          />
          <AuthRoute
            {...routesProps}
            path={Routes.HRIS}
            showSideNav
            authority={[FM]}
            featureFlags={[featureFlagsRecord.releaseHrConnect]}
          />
          <AuthRoute
            {...routesProps}
            path={Routes.THEME_CONFIGURATION}
            showSideNav
            authority={[FM]}
          />
          <AuthRoute
            {...routesProps}
            path={Routes.TRIP_TYPES}
            authority={[TM]}
            featureFlags={[FeatureFlags.DEPRECATED]}
          />
          <AuthRoute
            {...routesProps}
            path={Routes.TRAVELER_PROFILES}
            authority={[TM]}
            featureFlags={[FeatureFlags.TRAVELER_PROFILES]}
          />
          <AuthRoute {...routesProps} path={Routes.SSO_REDIRECT} authority={[ALL]} />
          <AuthRoute
            {...routesProps}
            path={Routes.SANDBOX_TOOLS}
            showSideNav={false}
            authority={[FM]}
            sandboxRoute
          />
          <AuthRoute
            {...routesProps}
            path={Routes.SANDBOX_TOOLS_CARDS}
            showSideNav={false}
            authority={[FM]}
            sandboxRoute
          />
          <AuthRoute
            {...routesProps}
            path={Routes.SANDBOX_TOOLS_TRANSACTION}
            showSideNav={false}
            authority={[FM]}
            sandboxRoute
          />
          <UnauthRoute
            {...routesProps}
            path={Routes.FORGOT_PASSWORD}
            redirectTo={Routes.HOME}
            component={ForgotPasswordPage}
          />
          <UnauthRoute
            {...routesProps}
            path={Routes.RESET_PASSWORD}
            redirectTo={Routes.HOME}
            component={ResetPasswordPage}
          />
          <UnauthRoute
            {...routesProps}
            path={Routes.CREATE_PASSWORD}
            redirectTo={Routes.HOME}
            component={CreatePasswordPage}
          />
          <UnauthRoute
            {...routesProps}
            path={Routes.LOGIN}
            redirectTo={Routes.HOME}
            component={LoginPage}
          />
          <AuthRoute
            {...routesProps}
            path={Routes.GUEST_TRAVELERS}
            showSideNav={false}
            authority={[TM, TA]}
          />
          <AuthRoute
            {...routesProps}
            path={Routes.GUEST_TRAVELER_EDIT}
            featureFlags={[FeatureFlags.TRAVEL_GUEST_TRAVELER]}
            authority={[TM, TA]}
          />
          <AuthRoute
            {...routesProps}
            path={Routes.GUEST_TRAVELER_INFO}
            showSideNav={false}
            authority={[TM, TA]}
            noContainer
          />
          <AuthRoute {...routesProps} path={Routes.CARDS} authority={[FM]} />
          <AuthRoute
            {...routesProps}
            path={Routes.ADMIN_CARD_DETAILS}
            featureFlags={[FeatureFlags.VIRTUAL_MULTI_CARD]}
            authority={[FM]}
            noContainer
          />
          <AuthRoute
            {...routesProps}
            path={Routes.SPEND_CARD_DETAILS}
            featureFlags={[FeatureFlags.VIRTUAL_MULTI_CARD]}
            authority={[SP]}
            noContainer
          />
          <Redirect exact from={Routes.MYCARD} to={Routes.MYCARD_OVERVIEW} />
          <Redirect exact from={Routes.SPEND} to={Routes.SPEND_EXPENSES} />
          <Redirect exact from={Routes.CENTERACCOUNT} to={Routes.CENTERACCOUNT_SUMMARY} />
          <Redirect exact from={Routes.ADMINISTRATION} to={Routes.USERS} />
          <Redirect exact from={Routes.RULES} to={Routes.RULES_CUSTOM_APPROVAL} />
          <Redirect exact from="/index.html" to={Routes.LOGIN} />
          <Route
            render={() => (
              <NotFoundPage links={notFoundPageLinks} isAuthenticated={isAuthenticated} />
            )}
          />
        </Switch>
      </OrgSwitcherInterceptor>
    </Router>
  );
};

App.propTypes = {
  isAuthenticated: PropTypes.bool,
  loading: PropTypes.bool,
};

const WithAuthentication = graphql(GET_AUTH, {
  props: ({ data: { loading, error, auth } }) => {
    if (loading) return { loading };
    if (error) return { error };

    const fronteggUser = ContextHolder.getUser();
    if (!fronteggUser) {
      const token = Storage.getItem("auth");
      authObj = token ? JSON.parse(token) : null;
    }

    return {
      loading: false,
      isAuthenticated: !!fronteggUser || auth?.isAuthenticated,
    };
  },
})(App);

const ONE_MINUTE = 1000 * 60;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: ONE_MINUTE,
      retry: false,
    },
  },
});

const WithProvider = () => (
  <>
    <AppBranding>
      <StyledSimpleBar>
        <ApolloProvider client={client}>
          <QueryClientProvider client={queryClient}>
            {slot && <SlotIndicator>{slot.slice(0, 3)}</SlotIndicator>}
            {aSlot && <AppSlotIndicator>{aSlot.slice(0, 3)}</AppSlotIndicator>}
            <GlobalStyles />
            <WithAuthentication />
            <Toaster position="top-right" />
            <Suspense fallback={null}>
              <ReactQueryDevtools
                initialIsOpen={false}
                toggleButtonProps={{ className: "react-query-btn" }}
              />
            </Suspense>
          </QueryClientProvider>
        </ApolloProvider>
      </StyledSimpleBar>
    </AppBranding>
    <Notifier />
  </>
);

export default WithProvider;
