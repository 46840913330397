import { useCallback, useEffect, useState } from "react";

import { ASYNC_JOB_PARAMS } from "../utils/asyncJobs";

type Job = {
  ID: string;
  jobType: string;
};

export const useAsyncJobsMessage = (pendingJobs: Job[]) => {
  const decorateMessage = useCallback(
    (message) => {
      return pendingJobs.length === 1 ? message : `${message} +${pendingJobs.length - 1}`;
    },
    [pendingJobs.length],
  );

  const [message, setMessage] = useState(null);

  useEffect(() => {
    if (pendingJobs.length === 0) {
      setMessage(null);

      return;
    }

    if (message) {
      return;
    }

    const firstJob = pendingJobs[0];
    if (!firstJob) {
      return;
    }

    const { pendingMessage } = ASYNC_JOB_PARAMS[firstJob.jobType];
    const firstJobMessage =
      typeof pendingMessage === "function" ? pendingMessage() : pendingMessage;

    setMessage(decorateMessage(firstJobMessage));
  }, [decorateMessage, message, pendingJobs]);

  const updateMessage = useCallback(
    (job) => {
      const firstJob = pendingJobs[0];
      if (!firstJob || firstJob.ID !== job.ID) {
        return;
      }

      const { pendingMessage } = ASYNC_JOB_PARAMS[firstJob.jobType];
      const firstJobMessage =
        typeof pendingMessage === "function" ? pendingMessage(job) : pendingMessage;

      const updatedMessage = decorateMessage(firstJobMessage);

      setMessage(updatedMessage);
    },
    [decorateMessage, pendingJobs],
  );

  return [message, updateMessage];
};
