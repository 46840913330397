import { ApprovalRuleBuilder } from "../index";
import type { IConditionInput } from "../types";
import {
  ApprovalRuleType,
  type IApprovalRule,
  type IApproverActionInput,
  type IGeneratorInput,
} from "./types";

export const generateApprovalRule = ({
  actions,
  conditions,
  type,
  logicalOperator,
  startDateTime: effectiveDateTime,
  ...parameters
}: IGeneratorInput): IApprovalRule => {
  const ruleBuilder = new ApprovalRuleBuilder(type, {
    effectiveDateTime,
    ...parameters,
  });

  if (logicalOperator) {
    ruleBuilder.setLogicalOperator(logicalOperator);
  }
  buildConditions(ruleBuilder, conditions);
  buildActions(ruleBuilder, type, actions);

  return ruleBuilder.getRule();
};

const buildConditions = (ruleBuilder: ApprovalRuleBuilder, conditions: IConditionInput[]): void => {
  conditions.forEach(({ field, fieldLabel, operator, value, valueLabel, isExclusion }) => {
    ruleBuilder.addCondition({
      property: { value: field, description: fieldLabel },
      operator,
      value: { value, description: valueLabel },
      isExclusion,
    });
  });
};

const buildActions = (
  ruleBuilder: ApprovalRuleBuilder,
  type: ApprovalRuleType,
  actions: IApproverActionInput[] = [],
): void => {
  switch (type) {
    case ApprovalRuleType.Custom:
      actions.forEach(({ values }: IApproverActionInput) => {
        ruleBuilder.addCustomApproverAction(values);
      });
      break;
    case ApprovalRuleType.AutoApprove:
      ruleBuilder.addAutoApproveAction();
      break;
  }
};
