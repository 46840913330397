import { isUndefined } from "lodash";
import toast, { CheckmarkIcon, ErrorIcon, LoaderIcon, Toaster } from "react-hot-toast";
import styled from "styled-components";

import Icon from "../Icon";
import {
  Button,
  CloseIcon,
  Container,
  Content,
  Description,
  IconWrapper,
  InfoIcon,
  InnerContent,
  Message,
  WarningIcon,
} from "./Notifier.styles";

import "./Notifier.css";

const StyledLoader = styled(LoaderIcon)`
  height: 20px;
  width: 20px;
`;

const RightContent = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  padding-right: 1rem;
`;

const hiddenNotificationStyles = {
  visibility: "hidden",
  width: 0,
  height: 0,
  margin: 0,
  padding: 0,
  border: 0,
  top: -200,
};

const plainStyleTypes = new Set(["plain", "loading"]);

const onNotify = ({
  type,
  message,
  description,
  placement = "top-right",
  closeIcon,
  className,
  onClose,
  style,
  duration,
  btn,
  showHidden,
  hideCloseIcon,
  rightContent,
  id,
}) => {
  const iconType = {
    error: <ErrorIcon />,
    success: <CheckmarkIcon />,
    warning: <WarningIcon />,
    info: <InfoIcon />,
    loading: <StyledLoader />,
    plain: null,
  }[type];

  const handleClose = (toastId) => {
    toast.dismiss(toastId);
    onClose?.();
  };

  const toastId = toast(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    ({ id }) => (
      <Container $isPlain={plainStyleTypes.has(type)}>
        <Content>
          <InnerContent>
            <Message type="SUCCESS">{message}</Message>
            {description && <Description>{description}</Description>}
            {btn && <Button>{btn}</Button>}
          </InnerContent>
        </Content>
        {rightContent && <RightContent>{rightContent}</RightContent>}
        {!hideCloseIcon &&
          (closeIcon || (
            <CloseIcon onClick={() => handleClose(id)}>
              <IconWrapper>
                <Icon type="close" />
              </IconWrapper>
            </CloseIcon>
          ))}
      </Container>
    ),
    {
      id,
      position: placement,
      icon: iconType && <div>{iconType}</div>,
      ...(!isUndefined(duration) && {
        duration: duration === 0 ? Number.POSITIVE_INFINITY : duration * 1000,
      }),
      className: `toast-notification-${type} ${className || ""}`,
      style: {
        overflow: "hidden",
        width: "24.1875rem",
        maxWidth: "calc(100vw - 2rem)",
        ...(showHidden && hiddenNotificationStyles),
        ...style,
      },
    },
  );

  return toastId;
};

const Notifier = () => (
  <Toaster
    toastOptions={{
      duration: 4500,
    }}
  />
);

Notifier.notification = onNotify;
Notifier.destroy = toast.dismiss;
Notifier.remove = toast.remove;

export default Notifier;
