import styled from "styled-components";

import { Button, Notifier } from "@core/ui-legacy";

const StyledButton = styled(Button)`
  margin-top: 1rem !important;
  padding: 0 0.75rem !important;
`;

const MoreInfoBtn = () => {
  const handleOnClick = () => {
    Notifier.destroy();
    window.dispatchEvent(new Event("openCardsSidePanel"));
  };

  return (
    <StyledButton {...{ type: "quaternary", size: "small", onClick: handleOnClick }}>
      View more
    </StyledButton>
  );
};

export default MoreInfoBtn;
