import { isObject } from "lodash";
import moment from "moment-timezone";

import placeholderCarrier from "../assets/imgs/carrier-placeholder.svg";
import spotnanaImg from "../assets/imgs/tmc/spotnana.svg";
import { userHasFeatures } from "../common/userHasFeatures";
import featureFlags from "./featureFlags";

export default function showTravelMenu(authUser) {
  const { toggles, organization } = authUser;
  const useTravelPortalLink = userHasFeatures(toggles, [featureFlags.TRAVELER]);
  const { ssoLink, name } = getTravelPartnerInfo(organization);
  return !!useTravelPortalLink && !!ssoLink && !!name;
}

export const getTravelPartnerInfo = (organization) => {
  const tmcs = isObject(organization?.partnerFields)
    ? Object.values(organization?.partnerFields)
    : [];
  const travelPartnerInfo = tmcs.find((tmc) => isObject(tmc) && !!tmc);
  return travelPartnerInfo
    ? {
        ssoLink: travelPartnerInfo.ssoLink,
        name: travelPartnerInfo.name,
        label: travelPartnerInfo.label,
        icon: travelPartnerInfo.icon,
        urlPolicyiFrame: travelPartnerInfo.urlPolicyiFrame,
      }
    : {};
};

export const getCountOfNightsString = (checkInDate, checkOutDate) => {
  if (!checkInDate || !checkOutDate) {
    return null;
  }

  checkInDate = moment(checkInDate);
  checkOutDate = moment(checkOutDate);
  const diff = checkOutDate.diff(checkInDate, "days");

  if (diff <= 0) {
    return null;
  }

  return `${diff} night${diff > 1 ? "s" : ""}`;
};

export const buildAddressString = (address) => {
  let string = "";

  if (address.address) {
    string += address.address;
  }
  if (address.address1) {
    string += ` ${address.address1}`;
  }
  if (address.address2) {
    string += ` ${address.address2}`;
  }
  if (address.city) {
    string += `, ${address.city}`;
  }
  if (address.state) {
    string += `, ${address.state}`;
  }
  if (address.postalCode) {
    string += ` ${address.postalCode}`;
  }
  return string.trim();
};

export const TMC_TYPES = {
  spotnana: "spotnana",
  default: "default",
};

export const tmcLogoURIs = {
  [TMC_TYPES.spotnana]: spotnanaImg,
};

// A map of known Airline logos, or logo uri's
const airlineLogos = {};

export const lookupAirlineLogo = (carrierCode) => airlineLogos[carrierCode] || placeholderCarrier;
