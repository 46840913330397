import { get } from "lodash";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Table as CuiTable, FooterToolbar } from "@core/ui-legacy";

import Multiselect from "./Multiselect";

const FooterToolbarStyled = styled(FooterToolbar)`
  padding: 0 1.5rem !important;
`;

// An extended Table supporting pagination and multiselect functionality
const Table = ({
  actionElement: ActionElement,
  multiselectParams,
  paginationParams,
  resetSelectionsOnPagination,
  rowSelection,
  ...rest
}) => {
  const hasPagination = !!paginationParams;

  const handlePaginationClick = (newPage) => {
    const {
      pagination: { offset, previousOffset, current },
      fetchMore,
      onPageChanged,
    } = paginationParams;
    const nextOffset = offset;
    let cursor;
    // Page next
    if (newPage > current) {
      cursor = nextOffset;
    } else {
      cursor = newPage === 1 ? null : previousOffset;
    }
    if (fetchMore) {
      fetchMore({
        variables: {
          offset: cursor,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return fetchMoreResult;
        },
      });
    }
    if (onPageChanged) onPageChanged({ currentPageNumber: newPage, currentOffset: cursor });
  };

  const getPaginationProps = (onSelect) => {
    const defaultPageSize = paginationParams?.limit ?? 25;

    return hasPagination
      ? {
          pagination: {
            current: get(paginationParams, "pagination.current", 1),
            defaultPageSize,
            total: get(paginationParams, "pagination.totalItems"),
            position: get(paginationParams, "position", "both"),
            showTotal: get(
              paginationParams,
              "showTotal",
              (total, [from, to]) => `${from}-${to} of ${total}`,
            ),
            hideOnSinglePage: get(paginationParams, "pagination.hideOnSinglePage", true),
            pageSize: paginationParams?.pagination?.pageSize ?? defaultPageSize,
            pageSizeOptions: paginationParams?.pagination?.pageSizeOptions,
            showSizeChanger: paginationParams?.pagination?.showSizeChanger,
            onShowSizeChange: paginationParams?.pagination?.onShowSizeChange,
          },
          onPaginationClick: onSelect
            ? (newPage) => {
                if (resetSelectionsOnPagination) {
                  onChange([], []);
                  if (onSelect) onSelect([], []);
                }
                handlePaginationClick(newPage);
              }
            : handlePaginationClick,
        }
      : {};
  };

  const {
    selectedRowKeys,
    selectedItems: selectedRowItems,
    onChange,
    ...restRowSelectionProps
  } = rowSelection || {};
  const renderTable = (params) => <CuiTable {...params} {...rest} />;

  if (multiselectParams) {
    return (
      <Multiselect initialSelectedItems={selectedRowItems} {...multiselectParams}>
        {({
          onSelect,
          onComplete,
          renderCount,
          renderActions,
          selectedItems,
          selectedItemKeys,
        }) => (
          <>
            {renderTable({
              ...getPaginationProps(onSelect),
              rowSelection: {
                selectedRowKeys: selectedRowKeys || selectedItemKeys,
                onChange: (keys, items) => {
                  if (onChange) {
                    // onChange sends the items only from one page
                    // if resetSelectionsOnPagination is false then
                    if (keys.length > items.length) {
                      const itemMap = new Map(
                        [...selectedRowItems, ...items].map((item) => [item.ID, item]),
                      );
                      const mapSelectedItems = keys.map((key) => itemMap.get(key));
                      rowSelection.onChange(keys, mapSelectedItems);
                    } else rowSelection.onChange(keys, items);
                  }
                  onSelect(keys, items);
                },
                ...restRowSelectionProps,
              },
            })}
            {!!selectedItemKeys.length && (
              <ActionElement className="wrap-action-bar">
                {renderCount(selectedRowKeys)}
                {renderActions({
                  selectedItems,
                  onComplete,
                })}
              </ActionElement>
            )}
          </>
        )}
      </Multiselect>
    );
  }
  return renderTable(getPaginationProps());
};

Table.propTypes = {
  // TODO This prop is for serverside pagination only; rename to serverPagination
  paginationParams: PropTypes.shape({
    fetchMore: PropTypes.func,
    limit: PropTypes.number,
    position: PropTypes.string,
    showTotal: PropTypes.func,
    onPageChanged: PropTypes.func,
    pagination: PropTypes.shape({
      offset: PropTypes.string,
      previousOffset: PropTypes.string,
      totalItems: PropTypes.number,
      current: PropTypes.number,
    }),
  }),
  multiselectParams: PropTypes.shape({
    renderActions: PropTypes.func.isRequired,
    onComplete: PropTypes.func,
    selectedLabel: PropTypes.string,
    selectedLabelSingular: PropTypes.string,
    selectedLabelPlural: PropTypes.string,
  }),
  rowSelection: PropTypes.shape({
    selectedItems: PropTypes.array,
    selectedRowKeys: PropTypes.array,
    onChange: PropTypes.func,
  }),
  resetSelectionsOnPagination: PropTypes.bool,
  actionElement: PropTypes.any,
};

Table.defaultProps = {
  resetSelectionsOnPagination: true,
  resetSelectionsOnActionSuccess: true,
  actionElement: FooterToolbarStyled,
};

export default Table;
