import { Check } from "lucide-react";

import { Text } from "../Text";

type StepIndicatorProps = {
  currentStep: number;
  steps: string[];
};

const StepIndicator = ({ currentStep, steps }: StepIndicatorProps) => {
  return (
    <div className="flex space-x-5">
      {steps.map((step, index) => {
        const isCompletedStep = index <= currentStep;
        return (
          <div key={step} className="flex items-center space-x-2">
            <div
              className={`flex size-[1.125rem] items-center justify-center rounded-full ${
                isCompletedStep ? "bg-primary-500" : "bg-neutrals-50 ring-2 ring-primary-500"
              }`}
            >
              <Check
                className={`size-2.5 ${isCompletedStep ? "text-text-50" : "text-primary-500"}`}
                strokeWidth={4}
              />
            </div>
            <Text variant="subtext" color="primary-500" className="font-[375]">
              {step}
            </Text>
          </div>
        );
      })}
    </div>
  );
};

export { StepIndicator };
