import usePlacesAutocomplete, {
  getZipCode as _getZipCode,
  getGeocode,
  getLatLng,
  type GeoArgs,
  type RequestOptions,
} from "use-places-autocomplete";
import { z } from "zod";

import { getUriWithCorsProxy } from "../services/rest/api";

type Coordinates = { lat: number | null; lng: number | null };

// https://developers.google.com/maps/documentation/address-validation/understand-response
const addressVerificationSchema = z.object({
  responseId: z.string().uuid(),
  result: z.object({
    address: z.object({
      missingComponentTypes: z.array(z.string()).optional(),
      postalAddress: z.object({
        addressLines: z.array(z.string()).optional(),
        administrativeArea: z.string().optional(),
        locality: z.string().optional(),
        postalCode: z.string().optional(),
        regionCode: z.string(),
        sublocality: z.string().optional(),
      }),
      unconfirmedComponentTypes: z.array(z.string()).optional(),
    }),
  }),
});

type AddressResult = z.infer<typeof addressVerificationSchema>;

const getLocation = async (address: GeoArgs["address"]) => {
  let coordinates: Coordinates = { lat: null, lng: null };
  try {
    const [results] = await getGeocode({ address });
    if (!results) return coordinates;
    const { lat, lng } = getLatLng(results);

    if (lat && lng) coordinates = { lat, lng };
  } catch (err) {
    window.console.error(err);
  }
  return coordinates;
};

const getZipCode = async (address: GeoArgs["address"]) => {
  try {
    const [result] = await getGeocode({ address });
    if (!result) return null;
    return _getZipCode(result, false);
  } catch (err) {
    window.console.error(err);
  }
  return null;
};

// This is not a secret. The key is only enabled for center domains.
const GOOGLE_API_KEY = "AIzaSyCMnGCtWMb_MUvwfJ3m8JFMYlvFqa-LG0Q";
const GOOGLE_ADDRESS_VERIFICATION_API_URL = `https://addressvalidation.googleapis.com/v1:validateAddress?key=${GOOGLE_API_KEY}`;

export const validateAddress = async (address: {
  addressLines: string[];
  administrativeArea: string;
  locality: string;
  regionCode: string;
}): Promise<AddressResult> => {
  try {
    const res = await fetch(getUriWithCorsProxy(GOOGLE_ADDRESS_VERIFICATION_API_URL), {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ address }),
    });

    const streamed = await res.json();
    return res.ok
      ? addressVerificationSchema.parse(streamed)
      : Promise.reject(new Error(`errorMessage: ${streamed.error.message}, status: ${res.status}`));
  } catch (error) {
    window.console.error(error);
    return Promise.reject(error);
  }
};

export const useGooglePlaces = (requestOptions?: RequestOptions) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
    clearCache,
  } = usePlacesAutocomplete({ debounce: 300, callbackName: "initPlaces", requestOptions });

  return {
    ready,
    value,
    status,
    data,
    getLocation,
    getZipCode,
    setValue,
    clearSuggestions,
    clearCache,
  };
};

export const placesCountryMap = {
  CAN: "CA",
  USA: "US",
} as const;
