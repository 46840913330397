import gql from "graphql-tag";
import _isEqual from "lodash/isEqual";
import { useMutation, useQuery } from "react-apollo";

import usePersistentStorage, { DELEGATE_USER } from "./usePersistentStorage";

export const SET_DELEGATE_USER = gql`
  mutation setDelegateUser($delegateUser: JSON!) {
    setDelegateUser(delegateUser: $delegateUser) @client
  }
`;

export const GET_DELEGATE_USER = gql`
  query {
    delegateUser @client {
      ID
      firstName
      fullName
      lastName
      avatarURI
      avatarBackgroundColor
    }
  }
`;

export type DelegateUser = {
  ID: string;
  firstName: string;
  fullName: string;
  lastName: string;
  avatarBackgroundColor?: string;
  avatarURI?: string;
};

const useDelegateUser = (): [DelegateUser | null, (user: DelegateUser | null) => void] => {
  const [, setDelegateUserStorage] = usePersistentStorage(DELEGATE_USER, null);
  const [setDelegateUser] = useMutation(SET_DELEGATE_USER);
  const { data } = useQuery(GET_DELEGATE_USER);
  const { delegateUser } = data || {};

  const handleSetDelegateUser = (user) => {
    if (_isEqual(delegateUser, user)) return;
    setDelegateUser({
      variables: { delegateUser: user },
    });
    setDelegateUserStorage(user);
  };

  return [delegateUser, handleSetDelegateUser];
};

export default useDelegateUser;
