import { PureComponent } from "react";
import gql from "graphql-tag";
import PropTypes from "prop-types";
import { Mutation } from "react-apollo";
import styled from "styled-components";
import { generateMedia } from "styled-media-query";

import { Tabs } from "@core/ui-legacy";
import Colors from "@core/ui-legacy/themes/colors";

import { MQBreakPoints } from "../../common/core-refactor/MQBreakPoints";
import { GET_APP_NOTIFICATIONS_COUNT } from "../../services/graphql/queries";
import AlertsList from "./AlertsList";
import { HRISAlerts } from "./HRISAlerts";

const { DESKTOP } = MQBreakPoints;
const { TabPane } = Tabs;

const ALERTS_KEY = "alerts";
const UPDATES = "updates";
const LABELS = {
  ALERTS: "Alerts",
  UPDATES: "Updates",
};

const media = generateMedia({
  medium: `${DESKTOP.minWidth}px`,
});

const UPDATE_ALERT_READ_STATUS = gql`
  mutation updateAlertsReadStatus($ids: [ID]!, $isRead: Boolean!) {
    updateAlertsReadStatus(ids: $ids, isRead: $isRead) {
      ID
      isRead
    }
  }
`;

const TabsStyled = styled(Tabs)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0 !important;
  height: calc(100vh - 100px);

  ${media.greaterThan("medium")`
    height: calc(100vh - 220px);
  `};
  .ant-tabs-tab {
    font-weight: 400 !important;
    padding: 0 !important;
    margin: 0 1.5rem 0 0 !important;
  }

  .ant-tabs-bar {
    margin: 1rem !important;
    ${media.greaterThan("medium")`
      margin: 0 3rem !important;
    `};
  }

  .ant-tabs-nav {
    color: ${Colors.monumental};
  }

  .ant-tabs-tab-active {
    color: ${Colors.secondary} !important;
    padding-bottom: 0.5rem !important;
  }

  .ant-tabs-ink-bar {
    background-color: ${Colors.secondary};
  }
`;

const TabPaneStyled = styled(TabPane)`
  flex-grow: 1;
  overflow-y: auto;
  padding: 1rem !important;
  ${media.greaterThan("medium")`
    padding: 1rem 3rem !important;
  `};
`;

class AppNotificationsTabs extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentMenuItem: props.onlyHRIS ? UPDATES : ALERTS_KEY,
    };
  }

  handleMarkRead = (notificationIDs, updateAlertsReadStatus) => {
    updateAlertsReadStatus({
      variables: { ids: notificationIDs, isRead: true },
    });
  };

  renderTab = (params) => {
    const { key, label, content } = params;
    return (
      <TabPaneStyled key={key} tab={label}>
        {content}
      </TabPaneStyled>
    );
  };

  render() {
    const { currentMenuItem } = this.state;
    const { onClose, hasHRIS } = this.props;
    return (
      <Mutation
        mutation={UPDATE_ALERT_READ_STATUS}
        refetchQueries={[{ query: GET_APP_NOTIFICATIONS_COUNT }]}
        ignoreResults
      >
        {(updateAlertsReadStatus) => (
          <TabsStyled defaultActiveKey={currentMenuItem} onChange={this.handleClick}>
            {this.renderTab({
              key: ALERTS_KEY,
              label: LABELS.ALERTS,
              content: (
                <AlertsList
                  onClose={onClose}
                  onMarkRead={(notifications) =>
                    this.handleMarkRead(notifications, updateAlertsReadStatus)
                  }
                />
              ),
            })}
            {hasHRIS &&
              this.renderTab({
                key: UPDATES,
                label: LABELS.UPDATES,
                content: <HRISAlerts onClose={onClose} />,
              })}
          </TabsStyled>
        )}
      </Mutation>
    );
  }
}

AppNotificationsTabs.propTypes = {
  onClose: PropTypes.func,
  hasHRIS: PropTypes.bool,
  onlyHRIS: PropTypes.bool,
};

export default AppNotificationsTabs;
