import { useContext } from "react";

import { CardExpirationContext } from "../../../common/contexts/CardExpirationProvider";

export const SetShippingPreferenceBanner = () => {
  const { setShowSidebar, setShowShippingPreferenceBanner, showShippingPreferenceBanner } =
    useContext(CardExpirationContext);

  if (!showShippingPreferenceBanner) return null;

  const handleClick = () => {
    setShowSidebar(true);
    setShowShippingPreferenceBanner(false);
  };

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={handleClick}
      onKeyDown={handleClick}
      className="flex h-11 cursor-pointer items-center justify-center bg-accent-400 px-6 py-0 text-primary-600"
    >
      <span>All cards will expire soon. Click here to set your shipping preference.</span>
    </div>
  );
};
