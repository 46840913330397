import { createElement } from "react";
import PropTypes from "prop-types";
import { Redirect, Route } from "react-router-dom";

const objectWithoutProperties = (obj, keys) => {
  const target = {};

  for (const i in obj) {
    if (keys.includes(i)) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }

  return target;
};

export const AuthRoute = function AuthRoute(_ref) {
  const Component = _ref.component;
  const { authenticated } = _ref;
  const { redirectTo } = _ref;
  const { search } = _ref;
  const rest = objectWithoutProperties(_ref, [
    "component",
    "authenticated",
    "redirectTo",
    "search",
  ]);

  return createElement(Route, {
    ...rest,
    render: function render(props) {
      return authenticated
        ? createElement(Component, { ...props, ...rest })
        : createElement(Redirect, {
            to: {
              pathname: redirectTo,
              search,
              state: { from: props.location },
            },
          });
    },
  });
};

AuthRoute.propTypes = {
  authenticated: PropTypes.bool,
  redirectTo: PropTypes.string.isRequired,
  component: PropTypes.func.isRequired,
};

AuthRoute.defaultProps = {
  authenticated: false,
};

export const UnauthRoute = function UnauthRoute(_ref) {
  const Component = _ref.component;
  const { authenticated } = _ref;
  const { redirectTo } = _ref;
  const rest = objectWithoutProperties(_ref, ["component", "authenticated", "redirectTo"]);

  return createElement(Route, {
    ...rest,
    render: function render(props) {
      return authenticated
        ? createElement(Redirect, {
            to: {
              pathname: redirectTo,
              state: { from: props.location },
            },
          })
        : createElement(Component, { ...props, ...rest });
    },
  });
};

UnauthRoute.propTypes = {
  authenticated: PropTypes.bool,
  redirectTo: PropTypes.string.isRequired,
  component: PropTypes.func.isRequired,
};

UnauthRoute.defaultProps = {
  authenticated: false,
};
