import type { HTMLAttributes } from "react";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "../../lib/utils";
import { Text } from "../Text";

const badgeVariants = cva(
  "inline-flex items-center rounded-full border border-transparent px-2.5 py-0.5 uppercase transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2",
  {
    variants: {
      variant: {
        success: "bg-success-300 text-success-600",
        error: "bg-danger-300 text-danger-700",
        neutral: "bg-neutrals-200 text-text-400",
        primary: "bg-primary-300 text-primary-550",
        accent: "bg-warning-400 text-text-500",
      },
      type: {
        default: "",
        secondary: "text-text-50",
      },
    },
    compoundVariants: [
      { type: "secondary", variant: "success", className: "bg-success-500" },
      { type: "secondary", variant: "error", className: "bg-danger-600" },
      { type: "secondary", variant: "neutral", className: "bg-neutrals-500" },
      { type: "secondary", variant: "primary", className: "bg-primary-500" },
      { type: "secondary", variant: "accent", className: "bg-accent-500 text-primary-600" },
    ],
    defaultVariants: {
      variant: "neutral",
      type: "default",
    },
  },
);

export type BadgeProps = HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof badgeVariants> & {
    text: string;
  };

const Badge = ({ className, variant, type, text, ...props }: BadgeProps) => {
  return (
    <div className={cn(badgeVariants({ variant, type }), className)} {...props}>
      <Text variant="subtext" weight="bold">
        {text}
      </Text>
    </div>
  );
};

export { Badge };
