import _intersectionWith from "lodash/intersectionWith";

export const UserRoles = {
  ALL: "*",
  SP: "SP", // Spender
  FM: "FM", // Finance Manager
  BM: "BM", // Budget Manager
  EA: "EA", // Approver
  IV: "insightsViewer",
  ED: "expenseDelegate",
  TM: "travelManager",
  TA: "travelArranger",
  CZ: "CZ", // ChurnZero admin
  Traveler: "traveler",
  GuestTraveler: "guestTraveler",
} as const;

type UserRolesMap = typeof UserRoles;
export type UserRole = UserRolesMap[keyof UserRolesMap];

export const isUserInRoles = (userRoles: UserRole[], allowedRoles: UserRole[]) =>
  _intersectionWith(userRoles, allowedRoles, (f, s) => s === UserRoles.ALL || f === s).length > 0;
