/* eslint-disable react/jsx-props-no-spreading */
import { useMemo, useRef } from "react";
import PropTypes from "prop-types";

import { getDelimiters, nbsp } from "../../utils/currency";
import Input from "../Input";
import InputNumber from "../InputNumber";

export const UNLIMITED_DECIMAL_PLACES = "unlimited";

const currencySymbol = "$";

const removeDuplicateDecimalSeparators = (value, decimalSeparator) => {
  const separatorIndex = value.indexOf(decimalSeparator);
  if (separatorIndex === -1) {
    return value;
  }

  return (
    value
      // eslint-disable-next-line unicorn/prefer-spread
      .split("")
      .filter((char, index) => char !== decimalSeparator || index === separatorIndex)
      .join("")
  );
};

const parseInput = (decimalSeparator, maxDecimalPlaces) => (value) => {
  const shouldMultiplyBy1000 = /K$/i.test(value);
  const rawNumberString = value.replace(new RegExp(`[^\\d${decimalSeparator}-]`, "g"), "");

  const unlimitedFilteredValue = removeDuplicateDecimalSeparators(
    rawNumberString,
    decimalSeparator,
  );

  const filteredValue =
    maxDecimalPlaces === UNLIMITED_DECIMAL_PLACES
      ? unlimitedFilteredValue
      : unlimitedFilteredValue.match(
          // Restrict max amount of decimal places
          new RegExp(`.*\\${decimalSeparator}.{${maxDecimalPlaces}}|.*(?!\\${decimalSeparator})`),
        )[0];

  const number = Number.parseFloat(filteredValue);
  if (shouldMultiplyBy1000 && !Number.isNaN(number) && number !== 0) {
    const multiplied = number * 1000;

    return maxDecimalPlaces !== UNLIMITED_DECIMAL_PLACES && multiplied % 1 !== 0
      ? multiplied.toFixed(maxDecimalPlaces)
      : multiplied;
  }

  return filteredValue;
};

export const replaceDelimiters = (num, thousandsSeparator, decimalSeparator) => {
  if (!num) {
    return null;
  }

  // commafy: Source: http://blog.stevenlevithan.com/archives/commafy-numbers
  return `${num}`
    .replace(
      /(^|[^\w.])(\d{4,})/g,
      (_, $1, $2) => $1 + $2.replace(/\d(?=(?:\d\d\d)+(?!\d))/g, `$&${thousandsSeparator}`),
    )
    .replace(/\./, decimalSeparator);
};

const formatInput = (
  value,
  placeholder,
  symbol,
  thousandsSeparator,
  decimalSeparator,
  position,
  caretPosition,
  setCaretPosition,
  showingValueLength,
) => {
  const fVal = replaceDelimiters(value, thousandsSeparator, decimalSeparator);

  if (setCaretPosition && caretPosition === 1 && position === "left" && showingValueLength === 2) {
    setCaretPosition(2);
  }

  if (fVal) {
    return position === "left" ? `${symbol}${fVal}` : `${fVal}${nbsp}${symbol}`;
  }

  return placeholder ? "" : symbol;
};

const InputCurrencyNumber = ({ formatNumberParams, centsOnly, ...props }) => {
  const inputRef = useRef(null);

  const { onBlur, currencySymbolPosition, placeholder } = props;
  const {
    currencyCode,
    localLanguage,
    precision = 2,
    maxDecimalPlaces = UNLIMITED_DECIMAL_PLACES,
    currencyDisplay = "symbol",
  } = formatNumberParams || {};

  /*
    Previous implementation was very US-centric and wouldn't handle foreign currencies nor locale settings.
    This current implementation respect browser locale settings.
    Future implementation should be able to override browser locale settings based on user's input
  */
  const { symbol, thousandsSeparator, decimalSeparator, position } = useMemo(
    () => getDelimiters(currencyCode, localLanguage, currencySymbolPosition, currencyDisplay),
    [currencyCode, currencyDisplay, currencySymbolPosition, localLanguage],
  );

  const parseInputMemoized = useMemo(
    () => parseInput(decimalSeparator, maxDecimalPlaces),
    [decimalSeparator, maxDecimalPlaces],
  );

  if (centsOnly) return <Input type="number" addonBefore={`${currencySymbol}0.`} {...props} />;

  // Patch issue with Antd InputNumber + formatter/parser on onBlur

  let onBlurShim;
  if (onBlur)
    onBlurShim = (e) => {
      onBlur(parseInputMemoized(e.target.value));
    };

  const setCaretPosition = (caretPosition) => {
    if (inputRef?.current?.inputNumberRef?.input) {
      inputRef.current.inputNumberRef.input.setSelectionRange(caretPosition, caretPosition);
    }
  };

  const getCaretPosition = () => inputRef?.current?.inputNumberRef?.input?.selectionStart;

  const getInputValueLength = () => inputRef?.current?.inputNumberRef?.input?.value.length;

  return (
    <InputNumber
      ref={inputRef}
      formatter={(value) =>
        formatInput(
          value,
          placeholder,
          symbol,
          thousandsSeparator,
          decimalSeparator,
          position,
          getCaretPosition(),
          setCaretPosition,
          getInputValueLength(),
        )
      }
      parser={parseInputMemoized}
      precision={precision}
      {...props}
      onBlur={onBlurShim}
    />
  );
};

InputCurrencyNumber.propTypes = {
  centsOnly: PropTypes.bool,
  formatNumberParams: PropTypes.shape({
    currencyCode: PropTypes.string,
    precision: PropTypes.number,
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    localLanguage: PropTypes.string,
    maxDecimalPlaces: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.oneOf([UNLIMITED_DECIMAL_PLACES]),
    ]),
    currencyDisplay: PropTypes.string,
  }),
  placeholder: PropTypes.string,
  currencySymbolPosition: PropTypes.string,
  onBlur: PropTypes.func,
};

InputCurrencyNumber.defaultProps = {
  // TODO: Require parent pass valid currency code
  formatNumberParams: {
    currencyCode: "USD",
    precision: 2,
    prefix: "",
    suffix: "",
    localLanguage: "",
    maxDecimalPlaces: UNLIMITED_DECIMAL_PLACES,
  },
  centsOnly: false,
  placeholder: "",
  currencySymbolPosition: "",
};

export default InputCurrencyNumber;
