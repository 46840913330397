/* eslint-disable tailwindcss/no-custom-classname */
import { PureComponent } from "react";
import { Menu as AntMenu, Dropdown } from "antd";
import PropTypes from "prop-types";
import styled from "styled-components";

import { ReactComponent as ArrowIcon } from "../../assets/imgs/arrow-1.svg";
import { ReactComponent as CheckIcon } from "../../assets/imgs/check.svg";
import { ReactComponent as DropdownIcon } from "../../assets/imgs/dropdown.svg";
import Colors from "../../themes/colors";

const Menu = styled(AntMenu)`
  border-radius: 0.25rem;
  border: 1px solid ${Colors.fog_gray};
  padding: 0.75rem 0;

  .ant-dropdown-menu-item {
    padding: 0.188rem 1.875rem;
    position: relative;
    font-size: 0.75rem;
    line-height: 1.25rem;

    :hover {
      background-color: ${Colors.bg_gray};
    }

    svg {
      position: absolute;
      left: 12px;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }
`;

class HeaderWithOrderOptions extends PureComponent {
  render() {
    const { column, sortFieldId, sortDirection, onSortChanged } = this.props;
    const menuList = [];
    const currentSortOrderIndex = column.sortOrder.findIndex(
      (sortOrder) => sortOrder.fieldId === sortFieldId && sortOrder.direction === sortDirection,
    );
    const currentSortOrder =
      currentSortOrderIndex !== -1 && column.sortOrder[currentSortOrderIndex];
    const direction = currentSortOrder && currentSortOrder.direction;

    // eslint-disable-next-line no-restricted-syntax
    for (const [index, order] of column.sortOrder.entries()) {
      const isActive = index === currentSortOrderIndex;
      menuList.push(
        <Menu.Item
          key={index}
          onClick={() => onSortChanged(order)}
          className={isActive ? "active" : " "}
        >
          {isActive && <CheckIcon role="img" />}
          {order.name}
        </Menu.Item>,
      );
    }
    const menu = <Menu className="ant-header-order-menu">{menuList}</Menu>;

    return (
      <Dropdown overlay={menu} trigger={["click"]}>
        <div className="ant-header-sort-header" data-testid="sort-dropdown">
          <span className="ant-header-sort-header__title">{column.title}</span>
          {direction && (
            <ArrowIcon className={`ant-header-sort-header__order ${direction}`} role="img" />
          )}
          <span className="ant-header-sort-header__dropdown">
            <DropdownIcon role="img" />
          </span>
        </div>
      </Dropdown>
    );
  }
}

HeaderWithOrderOptions.propTypes = {
  column: PropTypes.any,
  sortFieldId: PropTypes.string,
  sortDirection: PropTypes.string,
  onSortChanged: PropTypes.func.isRequired,
};

export default HeaderWithOrderOptions;
