import {
  datadogRum,
  type RumErrorEvent,
  type RumEvent,
  type RumResourceEvent,
} from "@datadog/browser-rum";
import queryString from "query-string";

type UserInfo = {
  ID: string;
  fullName: string;
  loginID: string;
  organization: {
    ID: string;
    name: string;
  };
  roles: string[];
  toggles: string[];
};

/** Variable to indicate if we've initialized datadog */
let ddInitialized = false;

const checkDatadogRumHost = (host: string) => {
  if (!host) {
    return null;
  }
  const sanitizedHost = host.replace(/^https?:\/\//, "");

  if (!sanitizedHost || (sanitizedHost && !sanitizedHost.startsWith("us5"))) {
    return null;
  }

  return sanitizedHost;
};

/**
 * Function that returns true if the error is known to be out of our control,
 * and should not be passed to Datadog, where it would just be noise.
 */
const isIgnorableError = (error: RumErrorEvent["error"]): boolean => {
  const { message, stack } = error;
  if (!message) return false;

  return Boolean(
    message.includes('Uncaught "ResizeObserver loop completed with undelivered notifications.') ||
      message.includes("Cannot read properties of undefined (reading 'contentWindow')") ||
      message.includes("Cannot read property 'contentWindow' of undefined") ||
      stack?.includes("getcenter.my.site.com/ESWCenterChatWebMessag"),
  );
};

export const initDatadogSession = (userInfo: UserInfo) => {
  const hostRegex = /https:\/\/(.*\.)?(centercard|center|app-center)\.(sh|com)(\/.*)?/;
  const rteGatewayRegex = /https:\/\/rte-gateway-(?<stage>\w+)\.center\.sh(\/.*)?/;
  const isProd = window.location.host.includes("app-center.com");
  const dataDogRumHost = checkDatadogRumHost(import.meta.env.REACT_APP_DD_HOST);

  if (!dataDogRumHost) {
    window.console.error("Datadog RUM host is not set. Skipping initialization.");
    return;
  }

  let env = "production";
  let stage = "stable";

  if (!isProd) {
    env = "stage";
    stage = rteGatewayRegex.exec(import.meta.env.REACT_APP_GRQL_API)?.groups?.stage ?? "unknown";
  }

  const tracingContext = {
    user: {
      id: userInfo.ID,
      name: userInfo.fullName,
      email: userInfo.loginID,
      toggles: userInfo.toggles,
      roles: userInfo.roles,
    },
    organization: {
      id: userInfo.organization.ID,
      name: userInfo.organization.name,
    },
    stage,
  };

  datadogRum.init({
    applicationId: import.meta.env.REACT_APP_DD_APP_ID,
    clientToken: import.meta.env.REACT_APP_DD_CLIENT_TOKEN,
    site: dataDogRumHost,
    service: "rte",
    env,
    version: import.meta.env.REACT_APP_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    allowedTracingUrls: [hostRegex],
    // Remove noise from performance monitoring
    excludedActivityUrls: [
      (url: string) => {
        const excludedUrls = [
          "https://r.logr-ingest.com",
          "https://clientstream.launchdarkly.com",
          "https://events.launchdarkly.com",
          "https://app.launchdarkly.com",
          "https://getcenter.my.salesforce-scrt.com",
          "https://getcenter.my.site.com",
        ];

        return excludedUrls.some((excludedUrl) => url.includes(excludedUrl));
      },
    ],
    beforeSend: (event: RumEvent): boolean => {
      // eslint-disable-next-line no-param-reassign
      event.context = { ...event.context, ...tracingContext };

      // Discard noise/errors that are out of our control.
      if (
        event.type === "error" &&
        event.error &&
        isIgnorableError(event.error as RumErrorEvent["error"])
      ) {
        return false;
      }

      if (event.type === "resource" && event.resource) {
        try {
          const resource = event.resource as RumResourceEvent["resource"];
          if (resource.url?.includes("/graphql")) {
            const { operationName, operationType } = queryString.parseUrl(resource.url).query;
            if (
              operationName &&
              typeof operationName === "string" &&
              operationType &&
              typeof operationType === "string"
            ) {
              // Add this context property so we can create reports/facets on the specific graphql operations
              // eslint-disable-next-line no-param-reassign
              event.context.graphql_operation = `${operationType}.${operationName}`;
            }
          }
        } catch {
          // Don't let an error parsing the URL block anything
        }
      }

      return true;
    },
  });

  datadogRum.setUser({
    id: userInfo.ID,
    name: userInfo.fullName,
    email: userInfo.loginID,
    orgID: userInfo.organization.ID,
    orgName: userInfo.organization.name,
  });

  // We are now initialized and can unlock other helper methods
  ddInitialized = true;
};

/**
 * Start a named duration tracker.
 * See https://docs.datadoghq.com/real_user_monitoring/browser/monitoring_page_performance/
 *
 * Returns a reference to the duration that can be passed to stopDuration() instead of the name.
 */
export const startRumDuration = ({
  name,
  context,
}: {
  name: string;
  context?: Record<string, unknown>;
}) => {
  if (ddInitialized) {
    return datadogRum.startDurationVital(name, context);
  }
  return undefined;
};

/**
 * Stop a named duration tracker.
 *
 * Accepts either the string name for the duration, or the reference returned by startDuration.
 */
export const stopRumDuration = ({
  name: nameOrReference,
}: {
  name: string | NonNullable<ReturnType<typeof startRumDuration>>;
}) => {
  if (ddInitialized) {
    datadogRum.stopDurationVital(nameOrReference);
  }
};

/**
 * Logs a named timing event. See documentation for more details about behavior:
 * https://docs.datadoghq.com/real_user_monitoring/browser/monitoring_page_performance/#track-additional-performance-timings
 */
export const addRumTiming = (name: string, timing?: number) => {
  if (ddInitialized) {
    datadogRum.addTiming(name, timing);
  }
};
