import { useMemo, useState } from "react";
import gql from "graphql-tag";
import { get, keyBy } from "lodash";
import PropTypes from "prop-types";
import { useApolloClient } from "react-apollo";
import { useHistory } from "react-router-dom";
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeList } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import styled from "styled-components";

import { Date, Ellipsis, FormattedNumber, List, OverlineLabel, Skeleton } from "@core/ui-legacy";
import Colors from "@core/ui-legacy/themes/colors";

import useConnectedERPConnector from "../../../common/useConnectedERPConnector";
import { ASYNC_JOB_STATUS } from "../../../utils/ASYNC_JOB_STATUS";
import { constructRoute, EXPENSEHUB_EXPENSE_DETAILS } from "../../../utils/routes";
import ExpenseStatus from "../../Expenses/ExpenseStatus";
import { ErrorIcon, LoadingIcon, SuccessIcon } from "../../ProgressStatusIcons";

export const GET_EXPENSES_BY_IDS = gql`
  query expensesByIDs($ids: [ID!]) {
    expensesByIDs(ids: $ids) {
      ID
      postedDateTime
      currencyCode
      amount
      status
      merchant {
        ID
        name
        rawName
      }
      splits {
        ID
      }
    }
  }
`;

const ItemStyled = styled(List.Item)`
  justify-content: space-between;
  flex-wrap: wrap;

  &:hover {
    cursor: pointer;
  }
`;

const ExpenseStatusStyled = styled(ExpenseStatus)`
  border-radius: 1rem !important;
`;

const AmountAndStatusWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 5rem;
`;

const ListContainer = styled.div`
  min-height: 75vh;
`;

const SkeletonStyled = styled(Skeleton)`
  height: 4rem;
`;

const IconContainer = styled.div`
  width: 1rem;
  margin: 0 1rem;
`;

const ErrorMessage = styled.div`
  color: ${Colors.danger_red};
  font-size: 0.75rem;
  line-height: 1.25rem;
  width: 80%;
`;

export const MAX_BATCH_SIZE = 25;

const ExpenseErpExportDetails = ({ job }) => {
  const operations = get(job, "operations", []);
  const client = useApolloClient();
  const history = useHistory();

  const [expenses, setExpenses] = useState([]);

  const { connectedErpConnector } = useConnectedERPConnector();

  const isItemLoaded = (index) => !!expenses[index];

  // only allow to look MAX_BATCH_SIZE ahead before each load
  const itemCount = Math.min(expenses.length + MAX_BATCH_SIZE, operations.length);

  const handleExpenseClick = (id) => {
    const expenseDetailsRoute = constructRoute(EXPENSEHUB_EXPENSE_DETAILS, {
      id,
    });

    history.push(expenseDetailsRoute);
  };

  const loadMoreItems = async (startIndex, stopIndex) => {
    if (stopIndex - startIndex > MAX_BATCH_SIZE) {
      stopIndex = startIndex + MAX_BATCH_SIZE;
    }

    const result = await client.query({
      query: GET_EXPENSES_BY_IDS,
      variables: {
        ids: operations.map((op) => op.ID).slice(startIndex, stopIndex + 1),
      },
    });
    if (result.data) {
      setExpenses((exps) => {
        exps.splice(startIndex, stopIndex + 1 - startIndex, ...result.data.expensesByIDs);
        return [...exps];
      });
    }
  };

  const operationMap = useMemo(() => keyBy(operations, (op) => op.ID), [operations]);

  const renderSyncStatus = (expense) => {
    const { status } = operationMap[expense.ID];
    let syncIcon;

    switch (status) {
      case ASYNC_JOB_STATUS.OperationComplete:
        syncIcon = <SuccessIcon />;
        break;
      case ASYNC_JOB_STATUS.Failed:
        syncIcon = <ErrorIcon />;
        break;
      case ASYNC_JOB_STATUS.OperationActive:
        syncIcon = <LoadingIcon />;
        break;
      default:
        syncIcon = null;
    }

    return <IconContainer>{syncIcon}</IconContainer>;
  };

  const renderErrors = (expense, status) => {
    if (status === ASYNC_JOB_STATUS.Failed || status === ASYNC_JOB_STATUS.CompleteWithWarning) {
      if (!expense.splits || expense.splits.length === 0) {
        return (
          <ErrorMessage>
            Oh no! Something went wrong and this expense has not been synced
            {connectedErpConnector?.shortName ? `with ${connectedErpConnector?.shortName}` : ""}.
          </ErrorMessage>
        );
      }
      // splits message
      return (
        <ErrorMessage>
          Oh no! Something went wrong and one or more of these splits were not synced{" "}
          {connectedErpConnector?.shortName ? `with ${connectedErpConnector?.shortName}` : ""}.
        </ErrorMessage>
      );
    }
    return null;
  };

  const renderExpense = (expense, style) => {
    if (!expense) {
      // render placeholder
      return <SkeletonStyled active paragraph={{ rows: 1 }} title={false} style={style} />;
    }
    const { status } = operationMap[expense.ID];
    return (
      <ItemStyled onClick={() => handleExpenseClick(expense.ID)} key={expense.ID} style={style}>
        <OverlineLabel
          label={
            <Ellipsis lines={1} tooltip={expense.merchant?.name}>
              {expense.merchant?.name}
            </Ellipsis>
          }
          text={
            <span>
              {expense.postedDateTime || expense.dateTime ? (
                <Date
                  date={expense.postedDateTime || expense.dateTime}
                  mode="horizontal"
                  styling={{ fontSize: "0.75rem" }}
                  showYear
                />
              ) : (
                "N/A"
              )}
            </span>
          }
          styleLabel={{ fontWeight: 700 }}
          styleText={{ fontWeight: 400, fontSize: "0.75rem" }}
        />
        <AmountAndStatusWrapper>
          <OverlineLabel
            align="center"
            label={<FormattedNumber value={expense.amount} currencyCode={expense.currencyCode} />}
            text={<ExpenseStatusStyled status={expense.status} />}
            styleLabel={{ fontWeight: 500 }}
            styleText={{
              fontWeight: 400,
              fontSize: "0.75rem",
            }}
          />
          {renderSyncStatus(expense)}
        </AmountAndStatusWrapper>
        {renderErrors(expense, status)}
      </ItemStyled>
    );
  };

  const Row = ({ index, style }) => renderExpense(expenses[index], style);

  // TODO: cv3-5880 Failed operations will need more space for error messages
  const getRowSize = (index) => (operations[index].status === ASYNC_JOB_STATUS.Failed ? 100 : 80);

  return (
    <ListContainer data-testid="expense-export-details-list">
      <AutoSizer>
        {({ height, width }) => (
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={itemCount}
            loadMoreItems={loadMoreItems}
          >
            {({ onItemsRendered, ref }) => (
              <VariableSizeList
                height={height}
                width={width}
                itemCount={operations.length}
                itemSize={getRowSize}
                onItemsRendered={onItemsRendered}
                ref={(list) => {
                  ref(list);
                  // resize items, can optimize based on offset of first error
                  if (list) list.resetAfterIndex(0, false);
                }}
              >
                {Row}
              </VariableSizeList>
            )}
          </InfiniteLoader>
        )}
      </AutoSizer>
    </ListContainer>
  );
};

ExpenseErpExportDetails.propTypes = {
  job: PropTypes.object.isRequired,
};

export default ExpenseErpExportDetails;
