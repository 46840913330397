import { Spin as AntSpin } from "antd";
import styled from "styled-components";

import Colors from "../../themes/colors";
import Icon from "../Icon";

const LoadingOutlined = <Icon type="loading" />;
const Spin = styled(({ blur, bordered, loadingOutlined, ...props }) => {
  // COR-1915 - This is a quick fix to deal with the spinner sometimes persisting the opacity
  // after the loader has completed. Somehow the style attribute is being set with opacity
  // even though thats not a prop it supports passing to that element. This will remove the
  // style attribute from that element. Future fix will be to remove the Antd Spinner
  // and create a new Spinner in the new component library
  document.querySelectorAll(".ant-spin-container").forEach((el) => el.setAttribute("style", ""));

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <AntSpin indicator={loadingOutlined && LoadingOutlined} data-testid="loading-spin" {...props} />
  );
})`
  border: ${({ bordered }) => (bordered ? `1px solid ${Colors.lunar_gray}` : "none")} !important;
  max-height: 100% !important;
  background-color: ${({ blur }) => (blur ? "transparent" : "white !important")};
`;

export default Spin;
