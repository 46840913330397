/* eslint-disable id-length */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/default-param-last */
/* eslint-disable unicorn/prefer-spread */
/* eslint-disable function-name/starts-with-verb */
import { createRef, PureComponent } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { generateRandomString } from "../../utils";
import Tooltip from "../Tooltip";

const EllipsisStyled = styled.span`
  overflow: hidden;
  word-break: break-all;
  width: 100%;

  ${({ lines }) => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    if (lines && isSupportLineClamp) {
      return `
          position: relative;
          text-overflow: ellipsis;
          display: -webkit-box;`;
    }
    return "position: relative;";
  }};
`;

const Shadow = styled.div`
  display: block;
  position: relative;
  color: transparent;
  opacity: 0;
  z-index: -999;
`;

const isSupportLineClamp = document.body.style.webkitLineClamp !== undefined;
const TooltipOverlayStyle = {
  overflowWrap: "break-word",
  wordWrap: "break-word",
};

export const getStrFullLength = (str = "") =>
  str.split("").reduce((pre, cur) => {
    const charCode = cur.codePointAt(0);
    if (charCode >= 0 && charCode <= 128) {
      return pre + 1;
    }
    return pre + 2;
  }, 0);

export const cutStrByFullLength = (str = "", maxLength) => {
  let showLength = 0;
  return str.split("").reduce((pre, cur) => {
    const charCode = cur.codePointAt(0);
    showLength += charCode >= 0 && charCode <= 128 ? 1 : 2;
    if (showLength <= maxLength) {
      return pre + cur;
    }
    return pre;
  }, "");
};

const EllipsisText = ({ text, length, tooltip, fullWidthRecognition, ...other }) => {
  const tooltipText = typeof tooltip === "string" ? tooltip : text;

  const textLength = fullWidthRecognition ? getStrFullLength(text) : text.length;

  if (textLength <= length || length < 0) {
    return <EllipsisStyled {...other}>{text}</EllipsisStyled>;
  }
  const tail = "...";
  let displayText;
  if (length - tail.length <= 0) {
    displayText = "";
  } else {
    displayText = fullWidthRecognition ? cutStrByFullLength(text, length) : text.slice(0, length);
  }

  if (tooltip) {
    return (
      <Tooltip overlayStyle={TooltipOverlayStyle} title={tooltipText}>
        <span>
          {displayText}
          {tail}
        </span>
      </Tooltip>
    );
  }

  return (
    <EllipsisStyled {...other}>
      {displayText}
      {tail}
    </EllipsisStyled>
  );
};

EllipsisText.propTypes = {
  text: PropTypes.string,
  length: PropTypes.number,
  tooltip: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  fullWidthRecognition: PropTypes.bool,
};

class Ellipsis extends PureComponent {
  constructor(props) {
    super(props);
    this.root = createRef();
    this.shadow = createRef();
    this.content = createRef();
    this.node = createRef();
    this.shadowChildren = createRef();
    this.init = false;
  }

  // eslint-disable-next-line react/state-in-constructor
  state = {
    text: "",
    targetCount: 0,
  };

  componentDidMount() {
    window.addEventListener("resize", this.computeLine);
    if (this.node) {
      this.computeLine();
    }
  }

  componentDidUpdate(prevProps) {
    const { lines } = this.props;
    if (lines !== prevProps.lines) {
      this.computeLine();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.computeLine);
  }

  computeLine = () => {
    const { lines } = this.props;
    if (lines && !isSupportLineClamp) {
      // eslint-disable-next-line unicorn/prefer-dom-node-text-content
      const text = this.shadowChildren.innerText || this.shadowChildren.textContent;
      const lineHeight = Number.parseInt(getComputedStyle(this.root).lineHeight, 10);
      const targetHeight = lines * lineHeight;
      this.content.style.height = `${targetHeight}px`;
      const totalHeight = this.shadowChildren.offsetHeight;
      const shadowNode = this.shadow.firstChild;

      if (totalHeight <= targetHeight || !this.init) {
        this.init = true;
        this.setState({
          text,
          targetCount: text.length,
        });
        return;
      }

      // bisection
      const len = text.length;
      const mid = Math.ceil(len / 2);

      const count = this.bisection(targetHeight, mid, 0, len, text, shadowNode);
      this.setState({
        text,
        targetCount: count,
      });
    }
  };

  bisection = (th, m, b, e, text, shadowNode) => {
    const suffix = "...";
    let mid = m;
    let end = e;
    let begin = b;
    shadowNode.innerHTML = text.slice(0, Math.max(0, mid)) + suffix;
    let sh = shadowNode.offsetHeight;

    if (sh <= th) {
      shadowNode.innerHTML = text.slice(0, Math.max(0, mid + 1)) + suffix;
      sh = shadowNode.offsetHeight;
      if (sh > th || mid === begin) {
        return mid;
      }
      begin = mid;
      mid = end - begin === 1 ? 1 + begin : Math.floor((end - begin) / 2) + begin;
      return this.bisection(th, mid, begin, end, text, shadowNode);
    }
    if (mid - 1 < 0) {
      return mid;
    }
    shadowNode.innerHTML = text.slice(0, Math.max(0, mid - 1)) + suffix;
    sh = shadowNode.offsetHeight;
    if (sh <= th) {
      return mid - 1;
    }
    end = mid;
    mid = Math.floor((end - begin) / 2) + begin;
    return this.bisection(th, mid, begin, end, text, shadowNode);
  };

  handleRoot = (n) => {
    this.root = n;
  };

  handleContent = (n) => {
    this.content = n;
  };

  handleNode = (n) => {
    this.node = n;
  };

  handleShadow = (n) => {
    this.shadow = n;
  };

  handleShadowChildren = (n) => {
    this.shadowChildren = n;
  };

  render() {
    const { text, targetCount } = this.state;
    const { children, lines, length, className, tooltip, fullWidthRecognition, ...restProps } =
      this.props;

    const tooltipText = typeof tooltip === "string" ? tooltip : children;

    if (!lines && !length) {
      return <EllipsisStyled {...restProps}>{children}</EllipsisStyled>;
    }

    // length
    if (!lines) {
      return (
        <EllipsisText
          className={className}
          length={length}
          text={children || ""}
          tooltip={tooltip}
          fullWidthRecognition={fullWidthRecognition}
          {...restProps}
        />
      );
    }

    const id = generateRandomString("ellipsis");

    // support document.body.style.webkitLineClamp
    if (isSupportLineClamp) {
      const style = `#${id}{-webkit-line-clamp:${lines};-webkit-box-orient: vertical;}`;
      const node = tooltip ? (
        <Tooltip overlayStyle={TooltipOverlayStyle} title={tooltipText}>
          <style>{style}</style>
          {children}
        </Tooltip>
      ) : (
        <>
          <style>{style}</style>
          {children}
        </>
      );

      return (
        <EllipsisStyled {...restProps} id={id} className={className} lines={lines}>
          {node}
        </EllipsisStyled>
      );
    }

    const childNode = (
      <span ref={this.handleNode}>
        {targetCount > 0 && text.slice(0, Math.max(0, targetCount))}
        {targetCount > 0 && targetCount < text.length && "..."}
      </span>
    );

    return (
      <EllipsisStyled
        {...restProps}
        id={id}
        lines={lines}
        ref={this.handleRoot}
        className={className}
      >
        <div ref={this.handleContent}>
          {tooltip ? (
            <Tooltip overlayStyle={TooltipOverlayStyle} title={tooltipText}>
              {childNode}
            </Tooltip>
          ) : (
            childNode
          )}
          <Shadow ref={this.handleShadowChildren}>{children}</Shadow>
          <Shadow ref={this.handleShadow}>
            <span>{text}</span>
          </Shadow>
        </div>
      </EllipsisStyled>
    );
  }
}

Ellipsis.propTypes = {
  children: PropTypes.any,
  lines: PropTypes.number,
  className: PropTypes.string,
  tooltip: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  length: PropTypes.number,
  fullWidthRecognition: PropTypes.bool,
};

export default Ellipsis;
