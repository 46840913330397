/* eslint-disable react/jsx-props-no-spreading */
import { Card as AntCard } from "antd";
import PropTypes from "prop-types";
import styled from "styled-components";

import Colors from "../../themes/colors";
import Spin from "../Spin";

const CardWrapper = styled(AntCard)`
  border-color: ${Colors.lunar_gray} !important;

  .ant-card-head {
    border-bottom: none;

    .ant-card-head-title {
      font-size: 1rem;
      font-weight: 600;
      padding: 1.5rem 0 0;
    }
  }

  .ant-card-body {
    padding: ${({ bordered }) => (bordered ? "1.5rem" : "0")};
  }
`;

const Card = (props) => {
  const { loading, blur, spinBordered, children, ...cardProps } = props;
  return (
    <Spin data-testid="card-loading-spin" spinning={loading} bordered={spinBordered} blur={blur}>
      <CardWrapper {...cardProps}>{children}</CardWrapper>
    </Spin>
  );
};

Card.propTypes = {
  loading: PropTypes.bool,
  blur: PropTypes.bool,
  children: PropTypes.node,
  bordered: PropTypes.bool,
  spinBordered: PropTypes.bool,
};

Card.defaultProps = {
  loading: false,
  blur: false,
  bordered: true,
  spinBordered: true,
};

export default Card;
