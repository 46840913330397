import PropTypes from "prop-types";
import styled from "styled-components";

import { getElementType } from "../../utils";

const getHeadingStyles = (as) => {
  const styles = {
    "h1-font-size": "1.75rem",
    "h1-font-weight": "600",
    "h1-line-height": "2rem",

    "h2-font-size": "1.75rem",
    "h2-font-weight": "700",
    "h2-line-height": "2.25rem",

    "h3-font-size": "1.125rem",
    "h3-font-weight": "600",
    "h3-line-height": "2rem",

    "h4-font-size": "1.125rem",
    "h4-font-weight": "700",
    "h4-line-height": "1.5rem",

    "h5-font-size": "1rem",
    "h5-font-weight": "600",
    "h5-line-height": "1.25rem",

    "h6-font-size": "0.75rem",
    "h6-font-weight": "700",
    "h6-line-height": "1.67",
  };

  return styles[as];
};

const Heading = styled((props) => {
  const { children, as, ...otherProps } = props;
  const ElementType = getElementType(Heading, props);

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <ElementType {...otherProps}>{children}</ElementType>;
})`
  margin: 0;
  font-size: ${({ as }) => getHeadingStyles(`${as}-font-size`)};
  font-weight: ${({ as }) => getHeadingStyles(`${as}-font-weight`)};
  line-height: ${({ as }) => getHeadingStyles(`${as}-line-height`)};
  letter-spacing: ${({ as }) => getHeadingStyles(`${as}-letter-spacing`)};
`;

Heading.propTypes = {
  children: PropTypes.any,
  as: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6"]),
};

Heading.defaultProps = {
  as: "h2",
};

export default Heading;
