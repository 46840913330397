/* eslint-disable function-name/starts-with-verb */
const maxSlots = 24;

function urlsBySlotName() {
  const data = {
    "stable-dev": "https://dev2-api.centercard.com",
    "stable-stage": "https://stage2-api.centercard.com",
    "stable-prod": "https://api.centercard.com",
    "f1-prod": "https://api.centercard.com",
  };

  for (let i = 1; i <= maxSlots; i++) {
    data[`f${i}-dev`] = `https://f${i}-dev-api.centercard.com`;
    data[`f${i}-stage`] = `https://f${i}-stage-api.centercard.com`;
  }

  return data;
}

function envBySlotUrl() {
  const data = {
    "https://dev2-api.centercard.com": "dev",
    "https://stage2-api.centercard.com": "stage",
    "https://api.centercard.com": "prod",
  };

  for (let i = 1; i <= maxSlots; i++) {
    data[`https://f${i}-dev-api.centercard.com`] = "dev";
    data[`https://f${i}-stage-api.centercard.com`] = "stage";
  }

  return data;
}

export const slotEnvConfig = urlsBySlotName();

export const apiUrlToEnv = envBySlotUrl();

export const graphQlApiandKeys = {
  "stable-dev": {
    url: "https://dzpwo7kkh5g3nn7inlxuyywkvy.appsync-api.us-west-2.amazonaws.com/graphql",
    key: "da2-seerwcjewbaxhlezaaf3iaeu2e",
  },
  "f1-dev": {
    url: "https://4n2pj3p2hnhitaugpheblx5kca.appsync-api.us-west-2.amazonaws.com/graphql",
    key: "da2-snwqbhu7vfa6bio2pdqkep32c4",
  },
  "f2-dev": {
    url: "https://lh5zo5pz6ne4bdt3ulnolnqm6q.appsync-api.us-west-2.amazonaws.com/graphql",
    key: "da2-eawzmccnyjdzndepphqespzqya",
  },
  "f3-dev": {
    url: "https://rw4mkqpoqfhllnv7a6gno3j6hm.appsync-api.us-west-2.amazonaws.com/graphql",
    key: "da2-dyo3gzbgbvd7holjivoqgd3tmq",
  },
  "f4-dev": {
    url: "https://6qpabb5b5zal5dsb7lsksfp2u4.appsync-api.us-west-2.amazonaws.com/graphql",
    key: "da2-kog3znugtnbfrc4navgff2guqu",
  },
  "f5-dev": {
    url: "https://xj5glcekrnbwpbuvdt3sg2h2ve.appsync-api.us-west-2.amazonaws.com/graphql",
    key: "da2-mjeicttnzve6hjhwds7qoxpxgu",
  },
  "f6-dev": {
    url: "https://lzbwhxf7cfcuhfog6pik6maoga.appsync-api.us-west-2.amazonaws.com/graphql",
    key: "da2-afnf6wjgi5anfozeoyxaepl26u",
  },
  "stable-stage": {
    url: "https://r6tqy42zajeerj3si2lmfharke.appsync-api.us-west-2.amazonaws.com/graphql",
    key: "da2-jypvgcd76jbh5gik2ws6bovke4",
  },
  "f1-stage": {
    url: "https://jdfbgbcitbaqzakdsxyx7e6xwu.appsync-api.us-west-2.amazonaws.com/graphql",
    key: "da2-csd3bhxofzcwte46mi6xzwxcwy",
  },
  "f2-stage": {
    url: "https://3khi2meh3vhonbuzjduqbvegzy.appsync-api.us-west-2.amazonaws.com/graphql",
    key: "da2-4cr3n2iw5bd3zaunnek5xktpsu",
  },
  "f3-stage": {
    url: "https://wk36fforwvgn7as4hemsun2fbq.appsync-api.us-west-2.amazonaws.com/graphql",
    key: "da2-mxheuixwjfazzn6qc3afo42tra",
  },
  "f4-stage": {
    url: "https://d6apyyhw5vdjll7cctzhslnen4.appsync-api.us-west-2.amazonaws.com/graphql",
    key: "da2-j7jnct4qsfhybdeoikcwhkgq7i",
  },
  "f5-stage": {
    url: "https://tdxqed7iyrcw5kfdnblzq5nhn4.appsync-api.us-west-2.amazonaws.com/graphql",
    key: "da2-vw5rc2zaajelrch46izmgnhrcq",
  },
  "f6-stage": {
    url: "https://mtvqus3flvhyjaf2hzstgony3m.appsync-api.us-west-2.amazonaws.com/graphql",
    key: "da2-6vfg4zgozvhj5kehpqz26s42oq",
  },
  "f1-prod": {
    url: "https://qmalxoumy5d65dr33bklpq4z6a.appsync-api.us-west-2.amazonaws.com/graphql",
    key: "da2-3fwyhg5spjcqrkobcoaufhlx6y",
  },
};
