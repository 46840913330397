import styled from "styled-components";

import { Dropdown, Menu } from "@core/ui-legacy";
import Colors from "@core/ui-legacy/themes/colors";

import { ReactComponent as SearchIcon } from "../../assets/imgs/search-icon.svg";
import SearchBox from "../SearchBox";
import type { IMenuItemStyled } from "./types";

const { Item } = Menu;

export const MenuStyled = styled(Menu)`
  display: flex;
  width: 17.875rem;
  padding: 0.75rem 0;
  max-height: 29rem;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;

  &:hover {
    overflow-y: auto;
  }

  & > div {
    width: 100%;
  }

  border-radius: 0.25rem;
  border: 1px solid ${Colors.fog_gray};
`;

export const MenuItemContent = styled.div`
  height: 1.5rem;
`;

export const SearchMenuItemStyled = styled(Item)`
  width: 100%;
  padding: 0 1rem 0.75rem;

  &:hover,
  &.ant-dropdown-menu-item-selected,
  &.ant-dropdown-menu-item-active {
    border: none !important;
    background-color: transparent;
  }
`;

export const MenuItemStyled = styled(Item)`
  width: 100%;
  padding: 0.5rem 1rem;
  ${({ isactive }: IMenuItemStyled) =>
    isactive
      ? `
      background-color: ${Colors.sky} !important;
      font-weight: 600 !important;
    `
      : ``}
  cursor: ${({ isactive, notfound }: IMenuItemStyled) =>
    isactive || notfound ? "default" : "pointer"} !important;

  &:hover,
  &.ant-dropdown-menu-item-selected,
  &.ant-dropdown-menu-item-active {
    border: none !important;
    background-color: transparent;
  }
`;

export const DropdownArrow = styled.i`
  cursor: pointer;
  box-sizing: border-box;
  color: var(--cen-colors-navText-500);
  transform: translateY(-2px);
  transition:
    transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: absolute;
  top: 2.1875rem;
  right: 0;
  width: 10px;

  &::before,
  &::after {
    content: "";
    position: absolute;
    background: var(--cen-colors-navText-500);
    width: 6px;
    height: 1.5px;
    border-radius: 2px;
    transition:
      background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
      -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
      top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
      -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition:
      background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
      transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
      top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
      -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition:
      background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
      transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
      top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
      -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transform: rotate(45deg) translateX(2px);
  }

  &::before {
    transform: rotate(-45deg) translateX(2px);
  }

  &::after {
    transform: rotate(45deg) translateX(-2px);
  }
`;

export const CurrentOrg = styled.span`
  position: relative;
  cursor: pointer;
  display: inline-block;
  padding-right: 1.25rem;
`;

export const StyledDropdown = styled(Dropdown)`
  display: inline;

  &:hover {
    & > span {
      & > ${DropdownArrow} {
        transform: translateY(-4px);

        &::after {
          transform: rotate(-45deg) translateX(-2px);
        }

        &::before {
          transform: rotate(45deg) translateX(2px);
        }
      }
    }
  }

  &.ant-dropdown-open {
    & > span {
      & > ${DropdownArrow} {
        transform: translateY(-4px);

        &::after {
          transform: rotate(-45deg) translateX(-2px);
        }

        &::before {
          transform: rotate(45deg) translateX(2px);
        }
      }
    }
  }
`;

export const SearchBoxStyled = styled(SearchBox)`
  .ant-input {
    border-radius: 6.25rem;
    padding-left: 1rem !important;
  }

  .ant-input-suffix {
    right: 1rem !important;
  }
`;

export const Wrapper = styled.span`
  margin-right: ${({ isMobile }: { isMobile?: boolean }) => (isMobile ? "0" : "1rem")};
`;

export const SearchIconStyled = styled(SearchIcon)`
  height: 1rem;
  width: 1rem;
  margin-left: 0.3125rem;
`;
