import { forwardRef, type ComponentPropsWithoutRef, type ElementRef } from "react";
import * as SeparatorPrimitive from "@radix-ui/react-separator";

import { cn } from "../../lib/utils";

type SeparatorProps = {
  color?: string;
} & ComponentPropsWithoutRef<typeof SeparatorPrimitive.Root>;

const Separator = forwardRef<ElementRef<typeof SeparatorPrimitive.Root>, SeparatorProps>(
  (
    { className, orientation = "horizontal", decorative = false, color = "default", ...props },
    ref,
  ) => {
    const colorClass = color === "default" ? "bg-primary-600" : `bg-${color}`;

    return (
      <SeparatorPrimitive.Root
        ref={ref}
        decorative={decorative}
        orientation={orientation}
        className={cn(
          "shrink-0 bg-border",
          orientation === "horizontal" ? "h-[1px] w-full" : "h-full w-[1px]",
          colorClass,
          className,
        )}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    );
  },
);

export { Separator };
