export const ASYNC_JOB_STATUS = {
  Error: "_error",
  Active: "active",
  Paused: "paused",
  Pending: "pending",
  PendingChanges: "pendingChanges",
  Complete: "complete",
  CompleteWithWarning: "completeWithWarning",
  NoOperation: "noOperation",
  Failed: "failed",
  OperationComplete: "completed",
  OperationActive: "in progress",
};
