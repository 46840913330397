/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from "prop-types";
import { Draggable } from "react-beautiful-dnd";
import styled from "styled-components";

const DraggableRow = styled.tr`
  background-color: white;
  display: ${({ isDragging }) => (isDragging ? "table" : undefined)};
`;

// TODO: Refactor this to use `styleConstructor` to generate the css
// instead of using inline styles.
const RowDraggable = ({ children, index, ...rest }) => (
  <Draggable draggableId={`list-${index}`} index={index}>
    {(provided, snapshot) => (
      <DraggableRow
        // eslint-disable-next-line id-length
        ref={(r) => {
          provided.innerRef(r);
        }}
        style={{
          ...provided.draggableProps.style,
        }}
        isDragging={snapshot.isDragging}
        {...rest}
        {...provided.draggableProps}
      >
        {children}
      </DraggableRow>
    )}
  </Draggable>
);

RowDraggable.propTypes = {
  children: PropTypes.any,
  index: PropTypes.number,
};

export default RowDraggable;
