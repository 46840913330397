import { useContext } from "react";
import dayjs from "dayjs";

import { Button, Modal, Text } from "@core/ui";

import { ReactComponent as CalendarIcon } from "../../../assets/imgs/calendar.svg";
import {
  CardExpirationContext,
  EXP_MODAL_STORAGE_KEY,
} from "../../../common/contexts/CardExpirationProvider";
import { DATE_FORMAT } from "../cardsExpiration.utils";

export const ExpirationModal = () => {
  const { setShowSidebar, setShowShippingPreferenceBanner, expirationYYMM } =
    useContext(CardExpirationContext);

  const showModal = localStorage.getItem(EXP_MODAL_STORAGE_KEY) === "true";

  const handleRemindMeLater = () => {
    localStorage.setItem(EXP_MODAL_STORAGE_KEY, "false");
    setShowShippingPreferenceBanner(true);
  };

  const handleContinue = () => {
    localStorage.setItem(EXP_MODAL_STORAGE_KEY, "false");
    setShowSidebar(true);
  };

  const expirationDate = dayjs(expirationYYMM, "YYMM").utc().endOf("month").format(DATE_FORMAT);

  return (
    <Modal
      open={showModal}
      leadingIcon={<CalendarIcon />}
      iconPosition="center"
      closeModal={() => {}} // User needs to take action
      actionButtons={[
        <Button
          key="Reminder"
          variant="ghost"
          text="Remind me later"
          size="large"
          onClick={handleRemindMeLater}
        />,
        <Button key="Continue" text="Continue" size="large" onClick={handleContinue} />,
      ]}
      className="min-h-[22.45rem] min-w-[36.85rem] auto-rows-min content-end gap-4"
    >
      <div className="mb-6 space-y-3 text-center">
        <Text variant="h1" weight="bold">
          All cards will expire soon
        </Text>
        <Text>
          All cards will expire on {expirationDate}. To get started, we need you to choose a
          preferred shipping method for where replacement cards will be sent to.
        </Text>
      </div>
    </Modal>
  );
};
