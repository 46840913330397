export const featureFlags = {
  ERP_NETSUITE_POSTING_DATE: "erpconnectors.netsuite.postingDate",
  OUT_OF_POCKET: "expense.outOfPocket",
  MILEAGE: "expense.mileage",
  MILEAGE_MAP_CALCULATION: "web.ui.mileage.gmaps",
  ALLOW_ADJUST_CARD_LIMIT: "web.ui.allowAdjustCardLimit",
  ANIMATED_LOGO_CUBE: "web.ui.animatedLogo.cube",
  ANIMATED_LOGO_DOTS: "web.ui.animatedLogo.dots",
  FOREX: "web.ui.forex",
  FOREX_SPLITS: "web.ui.forex.splits",
  AIRFARE_BOOKING: "web.ui.booking.airfare",
  CAR_RENTAL_BOOKING: "web.ui.booking.carRental",
  HOTEL_BOOKING: "web.ui.booking.hotel",
  TRAVEL_RELATED: "web.ui.travel.isTravelRelated",
  // TMC_UI_ENABLED: feature flag is mundane if no TMC connected
  TMC_UI_ENABLED: "web.ui.travel.TMC",
  EXPENSE_TRIP_CONTEXT: "forms.travel.tripContext",
  FULLSTORY_SESSION: "web.ui.fullstory.record",
  OOP_FOREX: "forms.oop.forex",
  FORMS_SPLITS_FOREX: "forms.splits.forex",
  OOP_SPLITS: "forms.oop.splits",
  REIMBURSEMENTS: "expenses.reimbursement.direct",
  REIMBURSEMENTS_SCHEDULER: "web.ui.reimbursements.scheduler",
  REIMBURSEMENTS_USER_POLICY: "web.ui.reimbursements.userpolicy",
  USER_TRAVEL: "web.ui.users.travel",
  USER_TRAVEL_PREFS: "web.ui.users.travel.prefs",
  EXPORT_CONFIGURATION: "web.ui.exportConfiguration",
  TRAVEL_DETAILS: "web.ui.travel.details",
  TRAVEL_POLICY: "web.ui.travel.policy",
  CHANGE_AVATAR: "web.ui.change.avatar",
  CHURN_ZERO: "web.ui.churnzero",
  TRIPS_HUB: "web.ui.tripsHub",
  TRAVELER: "web.ui.traveler",
  TRAVEL_GUEST_TRAVELER: "web.ui.travel.guestTraveler",
  BOOKING_MATCHING: "forms.travel.bookingMatching",
  CHATBOT: "chatbot",
  THEME_CONFIG: "web.ui.admin.themeEditor",
  VIRTUAL_MULTI_CARD: "web.ui.virtualMultiCard",
  UNARCHIVE_EXPENSE: "expense.unarchive",
  BULK_UNARCHIVE_EXPENSE: "bulk.expense.unarchive",
  TRAVELER_PROFILES: "web.ui.travel.travelerProfiles",
  SPOTNANA_IFRAME: "web.ui.spotnana.iframe",
  SPOTNANA_IFRAME_NEW_POLICY: "web.ui.spotnana.iframe.newPolicy",
  MULTI_ENTITY: "multiEntity",
  BULK_PDF_V2: "expense.bulk.print.v2",
  EXPENSE_BULK_APPROVE_V2: "expense.bulk.approve.v2",
  EXPENSE_BULK_ARCHIVE_V2: "expense.bulk.archive.v2",
  EXPENSE_BULK_UNARCHIVE_V2: "expense.bulk.unarchive.v2",
  EXPENSE_BULK_MARK_AS_PERSONAL_V2: "expense.bulk.markPersonal.v2",
  DEPRECATED: "deprecated",
  SMART_RECEIPT_GENERATION_ENABLED: "configure-smart-receipts-generation",
  SMART_RECEIPT_TOGGLE_RELEASED: "release-smart-receipts-toggle",
  SMART_RECEIPT_VIEWER_ENHANCEMENTS: "release-receipt-viewer-enhancements",
} as const;

type FeatureFlagsMap = typeof featureFlags;
export type FeatureFlag = FeatureFlagsMap[keyof FeatureFlagsMap];

export default featureFlags;
