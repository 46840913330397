import { toast } from "sonner";

import { ReactComponent as AlertIcon } from "../../assets/imgs/alert_circle_fill.svg";
import { ReactComponent as CloseIcon } from "../../assets/imgs/close.svg";
import { ReactComponent as SuccessIcon } from "../../assets/imgs/success.svg";

export const showSuccessToast = (text: string) => {
  toast.success(text, {
    icon: <SuccessIcon />,
    action: (
      <button
        className="my-0 ml-auto mr-0 flex cursor-pointer items-center justify-center border-none bg-transparent p-0"
        onClick={() => toast.dismiss()}
      >
        <CloseIcon />
      </button>
    ),
    classNames: {
      toast:
        "bg-neutrals-50 border h-14 border-neutrals-200 shadow-lg rounded-lg p-4 flex items-center",
      title: "text-base text-text-500 font-semibold",
    },
  });
};

export const showErrorToast = (text: string) => {
  toast.error(text, {
    icon: <AlertIcon />,
    className: "w-[30rem] !translate-x-[-8rem]",
    action: (
      <button
        className="my-0 ml-auto mr-0 flex cursor-pointer items-center justify-center border-none bg-transparent p-0"
        onClick={() => toast.dismiss()}
      >
        <CloseIcon />
      </button>
    ),
    classNames: {
      toast:
        "bg-neutrals-50 border h-14 border-neutrals-200 shadow-lg rounded-lg p-4 flex items-center",
      title: "text-base text-text-500 font-semibold",
    },
  });
};
