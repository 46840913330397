import { capitalize, filter, find, forEach, omit } from "lodash";

import Colors from "@core/ui-legacy/themes/colors";

import { CardReissuedReasonCode, type CardBehavior, type CardType } from "../common/types";

export const cardStatusCodesEnums = {
  IssuedInactive: "IssuedInactive",
  Open: "Open",
  Lost: "Lost",
  Stolen: "Stolen",
  NoWithdrawals: "NoWithdrawals",
  Closed: "Closed",
  LostCardNotCaptured: "LostCardNotCaptured",
  StolenCardNotCaptured: "StolenCardNotCaptured",
  Inactive: "Inactive",
  Reissued: "Reissued",
  FraudBlocked: "FraudBlocked",
};

export const shippingOptions = {
  STANDARD: "standard",
  PRIORITY: "priority",
};

export const cardBehaviorEnum = {
  STANDARD: "standard",
  SINGLE_TRANSACTION: "singleTransaction",
  NEVER_RESET: "neverReset",
} as const;

export const getStatusColor = (statusCode) => {
  switch (statusCode) {
    case cardStatusCodesEnums.Open:
      return Colors.success_green;
    case cardStatusCodesEnums.FraudBlocked:
    case cardStatusCodesEnums.Inactive:
    case cardStatusCodesEnums.Stolen:
      return Colors.danger_red;
    case cardStatusCodesEnums.IssuedInactive:
    default:
      return Colors.dark_gray;
  }
};

export const getStatusBadgeVariant = (statusCode) => {
  switch (statusCode) {
    case cardStatusCodesEnums.Open:
      return "success";
    case cardStatusCodesEnums.FraudBlocked:
    case cardStatusCodesEnums.Inactive:
    case cardStatusCodesEnums.Stolen:
      return "error";
    case cardStatusCodesEnums.IssuedInactive:
      return "neutral";
    default:
      return "neutral";
  }
};

export const getCardStatus = (statusCode, isAdminLocked = false) => {
  switch (statusCode) {
    case cardStatusCodesEnums.IssuedInactive:
      return "Not Activated";
    case cardStatusCodesEnums.Open:
      return "Active";
    case cardStatusCodesEnums.Inactive:
      return isAdminLocked ? "Admin locked" : "Locked";
    case cardStatusCodesEnums.Stolen:
      return "Stolen";
    case cardStatusCodesEnums.FraudBlocked:
      return "Fraud Blocked";
    default:
      return "Unknown";
  }
};

export const getFirstLatterUppercase = (name) =>
  name
    ? name
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(" ")
    : "";

export const getCardHolderNameUppercase = (name) => {
  if (!name) return "";

  if (name === name.toLocaleLowerCase()) {
    return getFirstLatterUppercase(name);
  }

  return name;
};

export const cardHasCustomName = (card) =>
  card.firstName?.toLowerCase() !== card.cardholder.firstName?.toLowerCase() ||
  card.lastName?.toLowerCase() !== card.cardholder.lastName?.toLowerCase();

const {
  IssuedInactive,
  Open,
  // Lost,
  // Stolen,
  // Closed,
  NoWithdrawals,
  // LostCardNotCaptured,
  // StolenCardNotCaptured,
  Inactive,
  // Reissued,
  // FraudBlocked,
} = cardStatusCodesEnums;

const injectCardProps = (card: CardType) => {
  if (!card.cardInfo) return card;
  let hasReissued = false;
  if (card.reissued?.reasonCode) {
    const { DAMAGED, EXPIRED, LOST } = CardReissuedReasonCode;

    const {
      reissued: { reasonCode },
    } = card;
    switch (reasonCode) {
      case DAMAGED:
      case EXPIRED:
      case LOST:
        hasReissued = true;
        break;
      default:
        break;
    }
  }

  card.cardInfo.hasReissued = hasReissued;
  card.cardInfo.reissuedReason = hasReissued ? capitalize(card.reissued?.reasonCode) : null;
  return card;
};

// Function to get all active cards, injecting a reissued card if necessary
export const getActiveCards = (cards?: CardType[]) => {
  if (!cards || cards.length === 0) return [];
  const primaryCard = getPrimaryCard(cards) as unknown as CardType;
  const activeCards: CardType[] = filter(
    cards.map(injectCardProps),
    (c) =>
      c.cardInfo &&
      c.cardInfo.activeState &&
      !c.reissued &&
      !(primaryCard && primaryCard.ID === c.ID),
  );
  if (primaryCard) return [primaryCard, ...activeCards];
  return activeCards;
};

// Function to get all inactive cards, injecting a reissued card if necessary
export const getInactiveCards = (cards?: CardType[], hasAdminLock?: boolean) => {
  if (!cards || cards.length === 0) return [];

  const inactiveCards: CardType[] = cards
    .map(injectCardProps)
    .filter(
      ({ cardInfo, reissued, statusCode }) =>
        cardInfo &&
        !reissued &&
        (statusCode === IssuedInactive ||
          (hasAdminLock && statusCode === Inactive && !cardInfo?.activeState)),
    );

  return inactiveCards;
};

// Find primary card (if exists), and inject props / flags
export const getPrimaryCard = (cards: CardType[]) => {
  if (!cards || cards.length === 0) return null;
  let primaryCard: CardType | null = null;

  forEach(cards, (card) => {
    if (card) {
      const { statusCode } = card;
      if (!statusCode) {
        console.error("Card does not have statusCode to find primary card", card);
        return;
      }
      if (statusCode === Open) {
        primaryCard = injectCardProps(card);
      } else if (
        ((statusCode === IssuedInactive && !card.reissued) ||
          (statusCode === Inactive && !card.reissued) ||
          statusCode === NoWithdrawals) &&
        !primaryCard
      )
        primaryCard = injectCardProps(card);
    }
  });
  return primaryCard;
};

export const getCardProgram = (cardPrograms, isActive = true, canIssueCards = true) => {
  if (!cardPrograms || cardPrograms.length === 0) return null;
  const foundProgram = find(
    cardPrograms,
    (p) => p.active === isActive && p.issueCards === canIssueCards,
  );
  return foundProgram || null;
};

export const validateCardLimitInput = (rule, value, callback) => {
  if (!value || value < 1 || value > 9999999 || value % 1 !== 0) {
    callback("Card limit cannot exceed $9,999,999, be less than $1, or contain decimals");
  } else {
    callback();
  }
};

export const isCredit = (cardProgram) => {
  return cardProgram?.name.toLowerCase().includes("credit");
};

export const canIssueCards = (cardPrograms, cards) => {
  const activeProgram = getCardProgram(cardPrograms);
  if (!activeProgram) return false;
  const hasCard = find(cards, (card) => card && card.cardProgramInstanceID === activeProgram.ID);

  if (hasCard) return false;

  return true;
};

export const canReissueCards = (cardPrograms, card) => {
  const activeProgram = getCardProgram(cardPrograms);
  if (!activeProgram || !card) return false;
  return card.cardProgramInstanceID === activeProgram.ID;
};

export const allowCharactersInCards = new RegExp(/^[a-z' -]+$/i);

export const isNameValid = (str) => !allowCharactersInCards.test(str) || !str.trim();

export const getShippingAddressForCard = (
  shippingAddress,
  selectedCardProgram,
  userBillingAddress,
) => {
  if (userBillingAddress) {
    return userBillingAddress;
  }
  return {
    ...omit(shippingAddress, "address1"),
    address: shippingAddress.address1,
  };
};

export const getExpiresAt = (expiresAtYYMM) => {
  if (!expiresAtYYMM) return null;
  const year = expiresAtYYMM.slice(0, 2);
  const month = expiresAtYYMM.slice(2, 4);
  return `${month}/${year}`;
};

export default {
  getActiveCards,
  getPrimaryCard,
  getCardProgram,
  canIssueCards,
  canReissueCards,
  isCredit,
  isNameValid,
  getExpiresAt,
};
