import { useContext } from "react";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";

import { Button, Modal, Text } from "@core/ui";

import { ReactComponent as MailIcon } from "../../../assets/imgs/mail-open.svg";
import { CardExpirationContext } from "../../../common/contexts/CardExpirationProvider";

const SEND_CARD_NOT_RECEIVED_MAIL = gql`
  mutation sendCardNotReceivedMail($input: SendCardNotReceivedInput!) {
    sendCardNotReceivedMail(input: $input)
  }
`;

export const AddressConfirmationModal = () => {
  const [cardNotReceived] = useMutation(SEND_CARD_NOT_RECEIVED_MAIL);
  const {
    userAddress,
    setShowThanksModal,
    showAddressConfirmationModal,
    setShowAddressConfirmationModal,
  } = useContext(CardExpirationContext);

  const handleYes = () => {
    setShowAddressConfirmationModal(false);
    setShowThanksModal("showWithText");
    void cardNotReceived({
      variables: { input: { isAddressCorrect: true } },
    });
    localStorage.setItem("ui-did-receive-card", "true");
  };

  const handleNo = () => {
    setShowAddressConfirmationModal(false);
    setShowThanksModal("showWithText");
    void cardNotReceived({
      variables: { input: { isAddressCorrect: false } },
    });
    localStorage.setItem("ui-did-receive-card", "true");
  };

  const { address, address2, city, state, postalCode, country } = userAddress ?? {};

  return (
    <Modal
      open={showAddressConfirmationModal}
      leadingIcon={<MailIcon />}
      iconPosition="center"
      closeModal={() => {}}
      actionButtons={[
        <Button
          key="No"
          variant="secondary"
          text="No, wrong address"
          size="large"
          onClick={handleNo}
        />,
        <Button key="Yes" text="Yes, that’s correct" size="large" onClick={handleYes} />,
      ]}
      className="min-h-[22.45rem] min-w-[36.85rem] auto-rows-min content-end gap-4"
    >
      <div className="mb-[1rem] flex justify-center">
        <Text variant="h2" weight="bold">
          Is this the right address?
        </Text>
      </div>
      <div className="mb-2">
        <Text>Your replacement cards were shipped to the following address:</Text>
      </div>
      <div className="mb-[3rem] rounded-lg bg-neutral-100 p-4">
        {userAddress && (
          <>
            <Text>{address ?? address2}</Text>
            <Text>
              {city}, {state} {postalCode}, {country}
            </Text>
          </>
        )}
      </div>
    </Modal>
  );
};
