import _ from "lodash";
import moment from "moment";

import { BaseRuleBuilder } from "../BaseRuleBuilder";
import { LogicalOperator, RuleStatus } from "../types";
import {
  ActionValueType,
  type ApprovalRuleType,
  type IApprovalRuleInputParameters,
  type IApprovalRuleParameters,
  type IApproverAction,
  type IApproverInputValue,
} from "./types";

export class ApprovalRuleBuilder extends BaseRuleBuilder<ApprovalRuleType> {
  private _parameters: IApprovalRuleParameters;

  constructor(type?: ApprovalRuleType, inputParameters?: IApprovalRuleInputParameters) {
    super(type);
    const defaultParameters = {
      effectiveDateTime: moment.utc().toISOString(),
      endDateTime: null,
      isDeleted: false,
      status: RuleStatus.Active,
    };

    this._parameters = _.merge(defaultParameters, inputParameters);
    this.setLogicalOperator(LogicalOperator.All);
  }

  public addCustomApproverAction(values: IApproverInputValue[]) {
    const operator = "setApprover";
    const parameters = values.map(({ value, valueLabel, valueType }) => {
      let approverItem: { ID: string; fullName: string } | null = null;
      switch (valueType) {
        case ActionValueType.Approver:
          approverItem = { ID: value, fullName: valueLabel };
          break;
        case ActionValueType.DefaultApprover:
          // TODO
          break;
        default:
          break;
      }
      return approverItem;
    });
    this.addAction(operator, parameters, "approvers");
  }

  public addAutoApproveAction() {
    this.addAction("autoApprove");
  }

  public getRule() {
    return {
      ...this._parameters,
      ...super.getRule(),
    };
  }

  protected getActionText(): string | undefined {
    for (const action of this._actions) {
      switch (action.operator) {
        case "setApprover": {
          const approversString = Array.isArray((action as IApproverAction).approvers)
            ? (action as IApproverAction).approvers.map(({ fullName }) => fullName).join(" then ")
            : "...";
          return ` set ${approversString || "..."} to be an approver`;
        }

        case "autoApprove":
          return " will be automatically approved";
        default:
          throw new Error("Undefined Action in the Rule");
      }
    }
  }
}
