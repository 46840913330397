import { z } from "zod";

import { API_URL, fetchWithToken, getUriWithCorsProxy } from "../api";
import { handleError } from "../utils";
import { viewSchema, type View } from "./views";

const getViewsServiceUrl = () =>
  getUriWithCorsProxy(`${API_URL}/insightsviewconfigurator/v3.0/views`);

const getViewServiceUrlById = (viewId: string) =>
  getUriWithCorsProxy(`${API_URL}/insightsviewconfigurator/v3.0/views/${viewId}`);

type Method = "GET" | "POST" | "PATCH" | "DELETE" | "PUT";

const defaultRequest = (method: Method, data?: Record<string, unknown>) => ({
  method,
  headers: { "Content-Type": "application/json" },
  body: data ? JSON.stringify(data) : undefined,
});

const errorMessage = (err: string, status: number) => ({
  errorMessage: err,
  status,
});

export const getViews = async (): Promise<View[]> => {
  try {
    const response: Response = await fetchWithToken(getViewsServiceUrl(), defaultRequest("GET"));

    if (!response.ok) {
      const { error }: { error: string } = await response.json();
      return Promise.reject(errorMessage(error, response.status));
    }

    const res = await response.json();
    return z.array(viewSchema).parse(res);
  } catch (error) {
    return handleError(error);
  }
};

export const addView = async (view: View): Promise<void> => {
  try {
    const response: Response = await fetchWithToken(
      getViewsServiceUrl(),
      defaultRequest("POST", view),
    );

    if (!response.ok) {
      const { error }: { error: string } = await response.json();
      return Promise.reject(errorMessage(error, response.status));
    }
    return Promise.resolve();
  } catch (error) {
    return handleError(error);
  }
};

export const updateView = async (viewId: string, view: View): Promise<void> => {
  try {
    const response: Response = await fetchWithToken(
      getViewServiceUrlById(viewId),
      defaultRequest("PUT", view),
    );

    if (!response.ok) {
      const { error }: { error: string } = await response.json();
      return Promise.reject(errorMessage(error, response.status));
    }
    return Promise.resolve();
  } catch (error) {
    return handleError(error);
  }
};

export const deleteView = async (viewId: string): Promise<void> => {
  try {
    const response: Response = await fetchWithToken(
      getViewServiceUrlById(viewId),
      defaultRequest("DELETE"),
    );

    if (!response.ok) {
      const { error }: { error: string } = await response.json();
      return Promise.reject(errorMessage(error, response.status));
    }
    return Promise.resolve();
  } catch (error) {
    return handleError(error);
  }
};

export const getViewQueryKey = ({ orgId, userId }: { orgId: string; userId: string }): string[] => {
  return ["views", orgId, userId];
};
