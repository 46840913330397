import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { pathToRegexp } from "path-to-regexp";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import styled, { css } from "styled-components";

import { featureFlagsSet, useLazyFlag } from "@core/feature-flags/clients";
import { Badge, Icon, Menu } from "@core/ui-legacy";
import Colors from "@core/ui-legacy/themes/colors";

import AuthUserContext from "../../common/contexts/AuthUserContext";
import { urlToList, userHasFeatures } from "../../common/core-refactor/utils";
import { menuFlags } from "../../common/menu";
import { isUserInRoles } from "../../common/roles";
import useDelegateUser from "../../common/useDelegateUser";
import { featureFlags } from "../../utils/featureFlags";

const { SubMenu, Item } = Menu;

const MenuStyled = styled(Menu)`
  background: transparent !important;
  border: none !important;

  &.ant-menu-inline-collapsed > .ant-menu-item {
    padding: 0 !important;
    width: 54px;
    display: flex;
    justify-content: center;
  }
`;

const SubMenuStyled = styled(SubMenu)`
  margin: 0 !important;

  &.ant-menu-submenu-open,
  .ant-menu-submenu-active {
    background-color: ${Colors.sky} !important;
  }

  div {
    margin: 0 !important;
    padding: 0 1.5rem !important;
  }

  .ant-menu,
  .ant-menu-sub,
  .ant-menu-vertical {
    margin: 0 -1.75rem !important;
  }

  .ant-menu-submenu.ant-menu-submenu-popup.ant-menu-light.top-nav-popup-submenu {
    .ant-menu-submenu-title {
      font-weight: bold !important;
    }
  }
`;

const SideNavSubMenuStyled = styled(SubMenu)`
  .ant-menu {
    background-color: ${Colors.bg_gray};
  }

  li {
    padding-left: 2.25rem !important;
  }

  .ant-menu-inline .ant-menu-submenu > div.ant-menu-submenu-title {
    padding: 0 !important;
    margin: 0 !important;
    font-size: 1rem !important;
  }
`;

const MenuItemStyled = styled(Item)`
  padding: 0 1.5rem !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;

  .ant-menu-item {
    padding: 0 1.5rem !important;
    margin: 0 !important;
    transition: none !important;
  }

  .ant-menu-item-selected,
  .ant-menu-item-active {
    background-color: ${Colors.lunar_gray}!important;
  }

  &.ant-menu-item-selected::after {
    border-right: none !important;
  }

  &.ant-menu-item::after {
    border-right: none !important;
  }
`;

const IconStyled = styled(Icon)`
  line-height: unset !important;
  margin-right: unset !important;
`;

const LinkNameStyled = styled.span`
  ${({ withMarginLeft }) =>
    withMarginLeft &&
    css`
      margin-left: 10px;
    `};
`;

export const getFlatMenuKeys = (menu) =>
  menu.reduce((keys, item) => {
    if (item.path) {
      keys.push(item.path);
      if (item.children) {
        return [...keys, getFlatMenuKeys(item.children)];
      }
    }
    return keys;
  }, []);

export const getMenuMatchKeys = (flatMenuKeys, paths) =>
  paths.reduce(
    (matchKeys, path) =>
      path && [
        ...matchKeys,
        flatMenuKeys.filter((item) => {
          try {
            return pathToRegexp(item).test(path);
          } catch {
            return false;
          }
        }),
      ],
    [],
  );

const convertPath = (path) => {
  if (path && (path.indexOf("http") === 0 || path.indexOf("https") === 0)) {
    return path;
  }
  return `/${path || ""}`.replace(/\/+/g, "/");
};

const getNavMenuItems = (menuData, authUser, delegateUser, hasFlag) => {
  if (!menuData) {
    return [];
  }

  return menuData.filter((item) => {
    if (item.customAccess) {
      return item.customAccess(authUser, delegateUser);
    }

    if (!item.name || !isUserInRoles(authUser.roles, item.authority)) {
      return false;
    }

    const { enabledForFlags, enabledForMdFlags } = item.enableForFeatureFlags?.reduce(
      (acc, flag) => {
        if (featureFlagsSet.has(flag)) {
          acc.enabledForFlags.push(flag);
          return acc;
        }
        acc.enabledForMdFlags.push(flag);
        return acc;
      },
      { enabledForFlags: [], enabledForMdFlags: [] },
    ) ?? {
      enabledForFlags: [],
      enabledForMdFlags: [],
    };

    const isEnabledByFeatureFlags = enabledForFlags.every(hasFlag);
    const ldFlags = menuFlags.filter(hasFlag);
    const isDisabledByFeatureFlags =
      item.disableForFeatureFlags &&
      userHasFeatures([...(authUser.toggles ?? []), ...ldFlags], item.disableForFeatureFlags);

    return (
      !isDisabledByFeatureFlags &&
      isEnabledByFeatureFlags &&
      userHasFeatures(authUser.toggles, enabledForMdFlags)
    );
  });
};

const MenuItemPath = ({ item, collapsed, onLinkClick }) => {
  const { toggles, organization } = useContext(AuthUserContext);
  const spotnanaOrgID = organization?.partnerFields?.spotnana?.orgID;
  const isOrgConnectToSpotnana = !!spotnanaOrgID;
  const isMultiEntityFeatureEnabled = userHasFeatures(toggles, [featureFlags.MULTI_ENTITY]);
  const location = useLocation();
  const itemPath = useMemo(() => convertPath(item.path), [item.path]);
  const { id, name, onClick, target, totalItems } = item;

  // Is it a spotnana book travel link
  if (name === "Book Travel") {
    let ssoLink = organization?.partnerFields?.spotnana?.ssoLink;
    if (
      isMultiEntityFeatureEnabled &&
      isOrgConnectToSpotnana &&
      ssoLink &&
      typeof ssoLink === "string" &&
      typeof spotnanaOrgID === "string"
    ) {
      const queryParams = new URLSearchParams(ssoLink);
      const tmcId = queryParams.get("tmcId");
      ssoLink = `${ssoLink}&sn_tmc_id=${tmcId}&sn_org_id=${organization.partnerFields.spotnana.orgID}`;
    }
    return (
      <a {...{ href: ssoLink, target }}>
        <span>{name}</span>
      </a>
    );
  }
  // Is it a http link
  if (/^https?:\/\//.test(itemPath)) {
    return (
      <a {...{ href: itemPath, target }}>
        <span>{name}</span>
      </a>
    );
  }

  let icon = null;

  if (item.icon) {
    icon =
      typeof item.icon === "string" ? (
        <IconStyled {...{ type: item.icon }} />
      ) : (
        <IconStyled {...{ component: item.icon }} />
      );
  }

  const hasTotalItems = totalItems > 0;

  return (
    <Link
      {...{
        id,
        to: itemPath,
        replace: itemPath === location.pathname,
        onClick: (e) => {
          if (onClick) onClick(e);
          if (onLinkClick) onLinkClick(e);
        },
      }}
    >
      {hasTotalItems ? <Badge {...{ dot: true }}>{icon}</Badge> : icon}
      <LinkNameStyled {...{ withMarginLeft: !!item.icon }}>{collapsed ? "" : name}</LinkNameStyled>
      {!collapsed && hasTotalItems && <span>&nbsp;({totalItems})</span>}
    </Link>
  );
};

MenuItemPath.propTypes = {
  item: PropTypes.object,
  collapsed: PropTypes.bool,
  onLinkClick: PropTypes.func,
};

const renderSubMenuOrItem = (
  item,
  mode,
  collapsed,
  onLinkClick,
  authUser,
  delegateUser,
  hasFlag,
) => {
  if (item.children?.some((child) => child.name)) {
    const childrenItems = getNavMenuItems(item.children, authUser, delegateUser, hasFlag);

    const childrenMenuItems = childrenItems.map((child) =>
      renderSubMenuOrItem(child, mode, collapsed, onLinkClick, authUser, delegateUser, hasFlag),
    );

    if (childrenItems && childrenItems.length > 0) {
      if (mode === "inline") {
        return (
          <SideNavSubMenuStyled {...{ id: item.id, title: item.name, key: item.path }}>
            {childrenMenuItems}
          </SideNavSubMenuStyled>
        );
      }

      // Applies CSS to nested TopNavMenu SubMenus Items
      if (
        item.name !== "My Card" &&
        item.name !== "Spend" &&
        item.name !== "Insights" &&
        item.name !== "Account" &&
        item.name !== "Admin" &&
        item.name !== "My Approvals" &&
        item.id !== "travel-link"
      ) {
        return (
          <SubMenuStyled
            {...{
              id: item.id,
              title: item.name,
              key: item.path,
              mode: "vertical",
            }}
          >
            {childrenMenuItems}
          </SubMenuStyled>
        );
      }

      return (
        <SubMenu
          {...{
            id: item.id,
            title: item.name,
            key: item.path,
            popupClassName: "top-nav-popup-submenu",
          }}
        >
          {childrenMenuItems}
        </SubMenu>
      );
    }

    return null;
  }

  return (
    <MenuItemStyled {...{ key: item.path || item.id, title: item.name }}>
      {item.path || item.onClick ? (
        <MenuItemPath {...{ item, collapsed, onLinkClick }} />
      ) : (
        item.name
      )}
    </MenuItemStyled>
  );
};

const NavMenu = ({ openDefault, menuData, ...props }) => {
  const location = useLocation();
  const authUser = useContext(AuthUserContext);
  const [delegateUser] = useDelegateUser();
  const selectedKeys = useMemo(() => {
    const flatMenuKeys = getFlatMenuKeys(menuData);
    const urlAsList = urlToList(location.pathname);

    return getMenuMatchKeys(flatMenuKeys, urlAsList);
  }, [location.pathname, menuData]);

  const [openKeys, setOpenKeys] = useState(() => (openDefault ? selectedKeys : []));

  useEffect(() => {
    setOpenKeys(openDefault ? selectedKeys : []);
  }, [openDefault, selectedKeys]);

  const handleOpenChange = useCallback((newOpenKeys) => {
    setOpenKeys([...newOpenKeys]);
  }, []);

  const hasFlag = useLazyFlag();
  const navMenuItems = getNavMenuItems(menuData, authUser, delegateUser, hasFlag);

  const { collapsed, mode, onLinkClick, totalItems, ...menuProps } = props;
  return (
    <MenuStyled
      mode={mode}
      {...menuProps}
      {...{
        selectedKeys,
        openKeys,
        triggerSubMenuAction: mode === "inline" ? "click" : "hover",
        onOpenChange: handleOpenChange,
      }}
    >
      {navMenuItems.map((item) =>
        renderSubMenuOrItem(item, mode, collapsed, onLinkClick, authUser, delegateUser, hasFlag),
      )}
    </MenuStyled>
  );
};

NavMenu.propTypes = {
  mode: PropTypes.string,
  menuData: PropTypes.array.isRequired,
  openDefault: PropTypes.bool,
  onLinkClick: PropTypes.func,
  totalItems: PropTypes.object,
  collapsed: PropTypes.bool,
};

NavMenu.defaultProps = {
  openDefault: true,
  totalItems: {},
};

export default NavMenu;
