/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef } from "react";
import { Form as AntForm } from "antd";
import PropTypes from "prop-types";
import styled from "styled-components";

import { getElementType } from "../../utils";
import { sansProps } from "../../utils/styledSansProps";

let Element = ({ as, ...rest }, ref) => {
  const ElementType = getElementType(as, { as });

  return (
    <span ref={ref}>
      <ElementType {...rest} aria-label={rest.label} />
    </span>
  );
};

const Form = styled(AntForm)`
  .ant-form-item {
    margin-bottom: 0.5rem !important;
  }

  &.ant-form-vertical {
    .ant-form-item-label {
      line-height: 1.5rem;
      padding: 0;
    }
  }

  .ant-form-item-label {
    label {
      font-size: 0.75rem;
      letter-spacing: -0.0125rem;
    }

    .ant-form-item-required::before {
      content: "" !important;
      margin: 0 !important;
    }

    .ant-form-item-required::after {
      display: ${({ hideRequiredMark }) => (hideRequiredMark ? "none" : "inline-block")};
      margin-right: 4px;
      font-size: 16px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: "*";
    }
  }

  &.disabled {
    .ant-form-item-label .ant-form-item-required::after {
      opacity: 0.3;
    }
  }
`;

const LabelDisabled = styled.label`
  opacity: 0.3;
`;

const FormItemStyled = sansProps(styled, { showError: true })(Form.Item)`
  .ant-form-explain {
    ${({ showError }) => !showError && "display: none;"}
  }
`;

Element = forwardRef(Element);

const Field = ({
  as,
  element,
  options,
  item: { label, form, showError = true, validateStatus, ...otherItem },
  ...rest
}) => {
  const { id, ...otherOptions } = options;
  const { disabled } = element || {};

  return (
    <FormItemStyled
      {...rest}
      label={disabled && label ? <LabelDisabled>{label}</LabelDisabled> : label}
      colon={false}
      showError={showError}
      validateStatus={validateStatus || showError ? validateStatus : ""}
      {...otherItem}
      // eslint-disable-next-line tailwindcss/no-custom-classname
      className={disabled ? "center-form-item-disabled" : ""}
    >
      {form.getFieldDecorator(id, { ...otherOptions })(
        <Element as={as} {...element} label={label} />,
      )}
    </FormItemStyled>
  );
};

Field.propTypes = {
  as: PropTypes.any.isRequired,
  element: PropTypes.object,
  item: PropTypes.object,
  options: PropTypes.shape({
    id: PropTypes.string,
    rules: PropTypes.array,
  }),
};

Form.Field = Field;

Form.defaultProps = {
  layout: "vertical",
};

export default Form;
