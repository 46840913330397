import { forEach } from "lodash";

export const HOME = "/";
export const LOGIN = "/login";
export const NOT_FOUND_PAGE = "/notfound";

// Card Management
export const MYCARD = "/mycard";
export const MYCARD_OVERVIEW = "/mycard/overview";
export const MYCARD_EXPENSES = "/mycard/expenses";
export const MYCARD_EXPENSE_DETAILS = "/mycard/expenses/:id";
export const MYCARD_TRIPS = "/mycard/trips";
export const MYCARD_TRIP_DETAILS = "/mycard/trips/:id";

// Card Management V2
export const SPEND = "/spend";
export const SPEND_CARDS = "/spend/cards";
export const SPEND_CARD_DETAILS = "/spend/cards/:id";
export const SPEND_EXPENSES = "/spend/expenses";
export const SPEND_EXPENSE_DETAILS = "/spend/expenses/:id";
export const SPEND_TRIPS = "/spend/trips";
export const SPEND_TRIP_DETAILS = "/spend/trips/:id";

// Center Account
export const CENTERACCOUNT = "/centeraccount";
export const CENTERACCOUNT_SUMMARY = "/centeraccount/summary";
export const CENTERACCOUNT_TRANSACTIONS = "/centeraccount/transactions";
export const LINKED_ACCOUNTS = "/centeraccount/summary/accounts";
export const STATEMENTS = "/centeraccount/statements";

// Users
export const ADMINISTRATION = "/admin";
export const USERS = "/admin/users";
export const USER_CREATE = "/admin/users/create";
export const USER_INFO = "/admin/users/:id/view";
export const USER_EDIT = "/admin/users/:id/edit";

// Cost Centers
export const COST_CENTERS = "/admin/costcenters";
export const COST_CENTERS_CREATE = "/admin/costcenters/create";
export const COST_CENTERS_EDIT = "/admin/costcenters/:id/edit";

// Expense Types
export const EXPENSE_TYPES = "/admin/expensetypes";
export const EXPENSE_TYPES_CREATE = "/admin/expensetypes/create";
export const EXPENSE_TYPES_EDIT = "/admin/expensetypes/:id/edit";

// General Ledger
export const GENERAL_LEDGER = "/admin/glaccounts";
export const GENERAL_LEDGER_CREATE = "/admin/glaccounts/create";
export const GENERAL_LEDGER_EDIT = "/admin/glaccounts/:id/edit";

// Expense Forms
export const EXPENSE_FORMS = "/admin/expenseforms";
export const EXPENSE_FORMS_EDIT = "/admin/expenseforms/:id/edit";
export const EXPENSE_FIELDS = "/admin/expensefields";
export const EXPENSE_FIELDS_CREATE = "/admin/expensefields/create";
export const EXPENSE_FIELDS_EDIT = "/admin/expensefields/:id/edit";
export const EXPENSE_FIELDS_MAPPING_CONFIGURATION = "/admin/expensefields/:id/mappingconfiguration";

// SFTP
export const SFTP = "/admin/sftp";

// Reimbursements
export const REIMBURSEMENTS_POLICY = "/admin/reimbursements/policy";
export const REIMBURSEMENTS_TRANSFERS = "/admin/reimbursements/transfers";
export const REIMBURSEMENTS_SETTINGS = "/settings/reimbursements";

// Account info
export const ACCOUNT_INFO_SETTINGS = "/settings/accountinfo";

// Traveler info
export const TRAVEL_INFO_SETTINGS = "/settings/travelinfo";

// Travel user info
export const TRAVEL_INFO_USER_SETTINGS = "/admin/travel/travelerprofiles/:id";

// Travel details
export const TRAVEL_DETAILS = "/admin/travel/traveldetails";
// Travel Policy
export const TRAVEL_POLICY = "/admin/travel/travelpolicy";
export const TRAVEL_POLICY_GROUP = "/admin/travel/travelpolicy/:id";
export const NEW_TRAVEL_POLICY_GROUP = "/admin/travel/travelpolicy/new";

// Notifications
export const NOTIFICATIONS = "/admin/notifications";

// Trip Types
export const TRIP_TYPES = "/admin/travel/triptypes";

// Traveler Profiles
export const TRAVELER_PROFILES = "/admin/travel/travelerprofiles";

// Integration
export const INTEGRATIONS = "/admin/integrations";
export const INTEGRATIONS_CONNECTORS = "/admin/integrations/connectors";
export const INTEGRATIONS_SETUP = "/admin/integrations/:erpType/setup";
export const INTEGRATIONS_IMPORT_SOURCE = "/admin/integrations/:erpType/import/:source";
export const INTEGRATIONS_EXPORT_SOURCE = "/admin/integrations/:erpType/export/:source";

// Expense Hub
export const EXPENSE_HUB = "/expensehub";
export const EXPENSEHUB_EXPENSE_DETAILS = "/expensehub/:id";
export const REPORTS = "/expensehub/reports";
export const INSIGHTS_EXPENSE_DETAILS = "/insight/expenses/:id";

// My Approvals
export const MY_APPROVALS = "/myapprovals";
export const MY_APPROVALS_CARDS = "/myapprovals/cards";
export const MY_APPROVALS_EXPENSES = "/myapprovals/expenses";
export const MY_APPROVALS_EXPENSE_DETAILS = "/myapprovals/expenses/:id";

// Password Management
export const CHANGE_PASSWORD = "/password/change";
export const FORGOT_PASSWORD = "/password/forgot";
export const RESET_PASSWORD = "/password/reset";
export const CREATE_PASSWORD = "/password/create";

// External Links
export const CONTACT_SUPPORT = "https://getcenter.force.com/s/";
export const CX_SUPPORT = "https://getcenter.my.site.com/s/contactsupport?language=en_US";

// Insights
export const INSIGHTS = "/insights";
export const INSIGHTS_DRILL_DOWN = "/insights/detail/:id";
export const INSIGHTS_EXPENSE = "/insight/expenses";
export const INSIGHTS_EXPENSE_DRILL_DOWN = "/insight/expenses/detail/:id";
export const INSIGHTS_TRAVEL_TAB = "/insight/travel/:tab";

// Policy Rules
export const RULES = "/admin/rules";
export const RULES_CUSTOM_APPROVAL = "/admin/rules/customapprovals";

// Export Configuration
export const EXPORT_CONFIGURATION = "/admin/exportconfiguration";

// Login SSO Configuration
export const LOGIN_SSO_CONFIGURATION = "/admin/loginsso";

// Theme Configuration
export const THEME_CONFIGURATION = "/admin/theme";

// HRIS
export const HRIS = "/admin/hrconnect";

// SSO
export const SSO_REDIRECT = "/ssoredirect/relayState/:rs/SAMLRequest/:saml";

// Travel
export const GUEST_TRAVELERS = "/travel/guesttravelers";
export const GUEST_TRAVELER_INFO = "/travel/guesttravelers/:id/view";
export const GUEST_TRAVELER_EDIT = "/travel/guesttravelers/:id/edit";

// Sandbox Tools
export const SANDBOX_TOOLS = "/tools";
export const SANDBOX_TOOLS_TRANSACTION = "/tools/transaction";
export const SANDBOX_TOOLS_CARDS = "/tools/cards";

// Cards
export const CARDS = "/admin/cards";
export const ADMIN_CARD_DETAILS = "/admin/cards/:id";

// Construct route with path params
export const constructRoute = (route: string, params: Record<string, string>) => {
  if (!route || !params) {
    return "";
  }
  let constructedRoute = route;
  forEach(params, (val, key) => {
    constructedRoute = constructedRoute.replace(`:${key}`, val);
  });

  return constructedRoute;
};
