import PropTypes from "prop-types";

import { Tooltip } from "@core/ui-legacy";

import { ErrorIcon as ErrorIconStyled } from "./StyledComponents";

const ErrorIcon = ({ message, placement, containerId, ...rest }) => {
  const el = document.getElementById(containerId);
  return (
    <Tooltip
      title={message}
      placement={placement}
      getPopupContainer={containerId && (() => el || document.body)}
    >
      <ErrorIconStyled {...rest} type="exclamation-circle" theme="filled" />
    </Tooltip>
  );
};

ErrorIcon.propTypes = {
  message: PropTypes.string,
  containerId: PropTypes.string,
  placement: PropTypes.string,
};

ErrorIcon.defaultProps = {
  placement: "left",
};

export default ErrorIcon;
