export const ERP_TYPE_CODE = {
  mock: "mock",
  netsuite: "netsuite",
  quickbooks: "quickbooks",
  quickbooksnp: "quickbooksnp",
  intacct: "intacct",
};

export const ERP_SOURCE_TYPES = {
  costcenters: "costcenters",
  expensetypes: "expensetypes",
  settings: "settings",
};

export const REQUIRED_IMPORTS = {
  expensetypes: true,
};

export const allowCustomLists = (erpType) => erpType === ERP_TYPE_CODE.netsuite;

export const isExternalAuth = (erpType) =>
  erpType === ERP_TYPE_CODE.quickbooks || erpType === ERP_TYPE_CODE.quickbooksnp;

// TODO: This function is specific to NetSuite and should be removed. Instead,
//  use the isMappable property on dimension types to determine if a dimension
//  type is mappable. We're adding an exception for classes and locations here
//  as a temporary fix until isMappable is fully implemented.
export const isMappableSourceType = (field = {}) =>
  (+(field.sourceField?.meta?.internalID ?? field.syncInternalID) < 0 ||
    field.type === "customList" ||
    field.type === "name") &&
  !["class", "classes", "location", "locations"].includes(field.sourceType);

export const isQuickbooks = (erpType) =>
  erpType === ERP_TYPE_CODE.quickbooks || erpType === ERP_TYPE_CODE.quickbooksnp;

export const getConnector = (connectors, erpType) =>
  erpType && ERP_TYPE_CODE[erpType] && connectors?.find((c) => c.type === erpType);

export const ExtractGQLErrorMessage = ({ error }) => {
  const errorMessage = error.message
    .replace("GraphQL error:", "")
    .replace("Invalid request.", "")
    .replace("Not found.", "");
  return errorMessage;
};

export default {
  ERP_TYPE_CODE,
  allowCustomLists,
  isExternalAuth,
  isQuickbooks,
  getConnector,
};
