import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { Button } from "@core/ui-legacy";
import Colors from "@core/ui-legacy/themes/colors";

import { SANDBOX_TOOLS } from "../../utils/routes";

const ButtonStyled = styled(Button)`
  border: var(--cen-colors-navText-500);
  color: var(--cen-colors-navText-500) !important;
  background-color: var(--cen-colors-navActive-500);

  &:active {
    background-color: var(--cen-colors-navActive-500);
  }

  &:focus {
    background-color: var(--cen-colors-navActive-500);
  }

  &:hover {
    background-color: var(--cen-colors-navActive-500);
  }

  margin-right: 2rem;
  margin-top: 20px;

  && {
    font-size: 0.75rem;
    line-height: 1;
    font-weight: 600;
  }
`;

const DrawerButtonStyled = styled(Button)`
  :disabled {
    border: 1px solid ${Colors.center_yellow};
    color: ${Colors.center_yellow} !important;
    background-color: transparent;
    cursor: pointer;
    pointer-events: none;
    margin-left: 1em;
    margin-bottom: 10px;
  }
`;

type SandboxIndicatorProps = {
  drawer?: boolean;
  sandbox?: boolean;
};

const SandboxIndicator = ({ sandbox, drawer }: SandboxIndicatorProps) => {
  const { push } = useHistory();
  const handleClick = useCallback(() => {
    push(SANDBOX_TOOLS);
  }, [push]);
  if (!sandbox) return null;
  return drawer ? (
    <DrawerButtonStyled shape="round" size="small" disabled>
      SANDBOX
    </DrawerButtonStyled>
  ) : (
    <ButtonStyled shape="round" size="small" onClick={handleClick}>
      SANDBOX
    </ButtonStyled>
  );
};

export default SandboxIndicator;
