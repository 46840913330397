import { z } from "zod";

const viewFilter = z.object({
  property: z.string(),
  values: z.array(z.string()),
  name: z.string(),
  ID: z.string(),
});

export const viewSchema = z.object({
  ID: z.string(),
  name: z.string(),
  startDate: z.string(),
  endDate: z.string(),
  filters: z.array(viewFilter),
});

export type View = z.infer<typeof viewSchema>;
export type ViewFilter = z.infer<typeof viewFilter>;
