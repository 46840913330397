import { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Icon, Input } from "@core/ui-legacy";
import Colors from "@core/ui-legacy/themes/colors";
import { generateRandomString } from "@core/ui-legacy/utils";

const containerID = generateRandomString("search-box");

const SearchStyled = styled(Input)`
  .ant-input {
    background-color: ${Colors.bg_gray} !important;
    border-color: ${Colors.bg_gray};
  }
`;

const SearchBox = ({
  onSearch,
  delay,
  onAllowClear,
  iconOption = "prefix",
  value,
  dependency,
  iconComponent,
  placeholder,
  ...rest
}) => {
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const search = useCallback(
    debounce((searchValue) => {
      if (onSearch) {
        onSearch(searchValue);
      }
    }, delay),
    [dependency],
  );

  const handleSearch = (evt) => {
    if (search.cancel) {
      search.cancel();
    }
    setLocalValue(evt.target.value);
    search(evt.target.value);
  };

  const { virtualMultiCardEnabled, ...searchProps } = rest;

  return (
    <SearchStyled
      id={containerID}
      placeholder={placeholder || "Search"}
      size="large"
      allowClear={onAllowClear}
      {...(iconOption === "prefix" ? { prefix: iconComponent } : { suffix: iconComponent })}
      onChange={handleSearch}
      value={localValue}
      {...searchProps}
    />
  );
};

SearchBox.propTypes = {
  delay: PropTypes.number, // milliseconds
  onSearch: PropTypes.func,
  onAllowClear: PropTypes.bool,
  iconOption: PropTypes.string,
  value: PropTypes.string,
  dependency: PropTypes.any,
  iconComponent: PropTypes.node,
  placeholder: PropTypes.string,
};

SearchBox.defaultProps = {
  delay: 0,
  onAllowClear: true,
  iconComponent: <Icon type="search" />,
};

export default SearchBox;
