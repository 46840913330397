import Select, { type SingleValue, type StylesConfig } from "react-select";
import CreatableSelect from "react-select/creatable";

import { Text } from "../Text";

export type DropdownOption<T = string> = SingleValue<{
  label: string;
  value: T;
}>;

type DropdownProps<T> = {
  onChange: (newValue: DropdownOption<T>) => void;
  options: DropdownOption<T>[];
  value: DropdownOption<T>;
  error?: string;
  hideDropdownIndicator?: boolean;
  isClearable?: boolean;
  isCreatable?: boolean;
  onInputChange?: (newValue: string) => void;
  placeholder?: string;
};

const scrollbarStyles = {
  "&::-webkit-scrollbar": {
    width: "0.625rem",
    height: "2.5rem",
  },
  "&::-webkit-scrollbar-track": {
    background: "transparent",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#B9C4D3",
    borderRadius: "2.5rem",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#999999",
  },
};

const setCustomStyles: <T>(hasError: boolean) => StylesConfig<DropdownOption<T>, false> = (
  hasError,
) => ({
  control: (provided, state) => ({
    ...provided,
    borderRadius: "0.25rem",
    display: "flex",
    margin: "0 auto",
    borderColor: hasError ? "#AF2712" : "#F0F0F0",
    boxShadow: "none",
    "&:hover": {
      borderColor: "#93C5FD",
    },
    overflow: state.hasValue ? "auto" : "hidden",
    position: "relative",
    ...scrollbarStyles,
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#212121",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "#212121",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#B2B2B2",
  }),
});

export const Dropdown = <T extends string>({
  options,
  value,
  onChange,
  placeholder,
  hideDropdownIndicator,
  onInputChange,
  isCreatable,
  isClearable,
  error,
}: DropdownProps<T>) => {
  const DropdownComponent = isCreatable ? CreatableSelect : Select;
  const hasError = !!error;
  return (
    <>
      <DropdownComponent<DropdownOption<T>>
        options={options}
        value={value}
        onChange={onChange}
        onInputChange={onInputChange}
        styles={setCustomStyles<T>(hasError)}
        components={{
          ClearIndicator: () => null,
          IndicatorSeparator: () => null,
          ...(hideDropdownIndicator ? { DropdownIndicator: () => null } : {}),
        }}
        placeholder={placeholder}
        isClearable={isClearable}
        formatCreateLabel={(val) => val}
      />
      {hasError && (
        <Text className="leading-5 text-[#AF2712]" variant="subtext" weight="light">
          {error}
        </Text>
      )}
    </>
  );
};
