import { PureComponent } from "react";
import PropTypes from "prop-types";

class RowPure extends PureComponent {
  render() {
    const { children, ...rest } = this.props;
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <tr {...rest}>{children}</tr>;
  }
}

RowPure.propTypes = {
  children: PropTypes.any,
};

export default RowPure;
