import { useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Checkbox, Icon } from "@core/ui-legacy";

import useSelectedRows from "./useSelectedRows";

const SelectedCount = styled.span`
  margin-right: 2rem;
  display: inline-block;
  min-width: 10.5rem;
`;

const IconCount = styled(Icon)`
  margin-right: 0.75rem;
`;

const CheckboxStyled = styled(Checkbox)`
  margin-right: 0.75rem;
`;

// TODO Refactor this code into Table
const Multiselect = ({
  children,
  initialSelectedItems,
  renderActions,
  onComplete,
  selectedLabel,
  selectedLabelSingular,
  selectedLabelPlural,
  showSelectCount,
  selectCountIcon,
  resetSelectedItemsOnComplete,
  onDeselectAll,
}) => {
  const [{ selectedItems, selectedItemKeys }, setSelectedItems] = useSelectedRows([]);

  useEffect(
    () => {
      if (initialSelectedItems) {
        setSelectedItems(initialSelectedItems);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialSelectedItems],
  );

  const handleSelect = (keys, items) => {
    setSelectedItems(items);
  };

  const handleComplete = ({ newSelectedKeys, itemKey = "ID" }) => {
    // Passing newSelectedKeys array retains selections of those rows onComplete
    // (eg in partial success scenario, keep failed rows checked)
    if (Array.isArray(newSelectedKeys)) {
      const newSelectedItems = [];
      selectedItems.forEach((item) => {
        if (newSelectedKeys.includes(item[itemKey])) newSelectedItems.push(item);
      });
      handleSelect(newSelectedKeys, newSelectedItems);
    } else if (resetSelectedItemsOnComplete) {
      // Else we check the resetSelectedItemsOnComplete prop to clear all selections
      handleSelect([], []);
    }
    if (onComplete) {
      onComplete({ selectedItems, setSelectedItems });
    }
  };

  const renderCount = (selectedKeys) => {
    const selected = selectedKeys || selectedItems;
    const selectedCountLabel = `${selected.length} ${
      selected.length === 1
        ? selectedLabelSingular || selectedLabel
        : selectedLabelPlural || selectedLabel
    }`;

    if (!showSelectCount) {
      return null;
    }

    if (onDeselectAll) {
      return (
        <CheckboxStyled defaultChecked onChange={onDeselectAll}>
          {selectedCountLabel}
        </CheckboxStyled>
      );
    }
    return (
      <SelectedCount>
        <IconCount type={selectCountIcon} />
        {selectedCountLabel}
      </SelectedCount>
    );
  };

  return (
    <>
      {children?.({
        selectedItems,
        selectedItemKeys,
        renderCount,
        renderActions,
        onSelect: handleSelect,
        onComplete: handleComplete,
      })}
    </>
  );
};

Multiselect.propTypes = {
  children: PropTypes.func.isRequired,
  initialSelectedItems: PropTypes.array,
  renderActions: PropTypes.func.isRequired,
  onComplete: PropTypes.func,
  selectedLabel: PropTypes.any,
  selectedLabelSingular: PropTypes.any,
  selectedLabelPlural: PropTypes.any,
  showSelectCount: PropTypes.bool,
  selectCountIcon: PropTypes.string,
  resetSelectedItemsOnComplete: PropTypes.bool,
  onDeselectAll: PropTypes.func,
};

Multiselect.defaultProps = {
  selectedLabel: "selected",
  selectCountIcon: "minus-square",
  showSelectCount: true,
  resetSelectedItemsOnComplete: true,
};

export default Multiselect;
