import PropTypes from "prop-types";
import styled from "styled-components";

import { Button } from "@core/ui-legacy";

import cardImg from "../../assets/imgs/CenterCard_Front.svg";

const CenterCardImg = styled.img`
  width: 13.25rem;
`;
const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 1.625rem;
  padding-bottom: 1.5rem;

  button:not(:last-child) {
    margin-right: 0.5rem;
  }

  button {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
`;
const ModalDescription = styled.div`
  padding-bottom: 0.5rem;
  max-width: 89%;
`;
const Aligner = styled.div`
  display: flex;
  justify-content: center;
  padding: 2.8125rem 0;
`;

const ActivateCard = ({ nextStep, onCancel }) => (
  <>
    <div className="ant-modal-header">
      <div className="ant-modal-title">Activate CenterCard?</div>
    </div>
    <ModalDescription>You will need your CenterCard nearby to reference.</ModalDescription>
    <Aligner>
      <CenterCardImg alt="User CenterCard" src={cardImg} width="214" height="133" />
    </Aligner>
    <ActionsWrapper>
      <Button
        id="cancel-dialog-btn"
        key="cancel-dialog-btn"
        data-testid="cancel-dialog-btn"
        type="cancel"
        size="default"
        onClick={onCancel}
      >
        Cancel
      </Button>
      <Button
        id="next-dialog-btn"
        key="next-dialog-btn"
        data-testid="next-dialog-btn"
        type="primary"
        size="default"
        htmlType="submit"
        onClick={nextStep}
      >
        Next
      </Button>
    </ActionsWrapper>
  </>
);

ActivateCard.propTypes = {
  nextStep: PropTypes.func,
  onCancel: PropTypes.func,
};

export default ActivateCard;
