import { useLayoutEffect, useState, type ReactNode } from "react";
import { FronteggProvider as ActualFronteggProvider } from "@frontegg/react";
import styled from "styled-components";

import { Spin } from "@core/ui-legacy";

const FRONTEGG_LOADING_CSS_CLASS = "frontegg-loading";

const SpinStyled = styled(Spin)`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const FronteggProvider = ({ children }: { children: ReactNode }) => {
  const [isLoading, setIsLoading] = useState(false);

  useLayoutEffect(() => {
    const mutationObserver = new MutationObserver(() => {
      setIsLoading(document.body.classList.contains(FRONTEGG_LOADING_CSS_CLASS));
    });

    setIsLoading(document.body.classList.contains(FRONTEGG_LOADING_CSS_CLASS));
    mutationObserver.observe(document.body, {
      attributeFilter: ["class"],
      attributeOldValue: false,
    });

    return () => {
      mutationObserver.disconnect();
    };
  }, []);

  return (
    <>
      <ActualFronteggProvider
        contextOptions={{
          baseUrl: import.meta.env.REACT_APP_FRONTEGG_BASE_URL,
          clientId: import.meta.env.REACT_APP_FRONTEGG_CLIENT_ID,
        }}
        hostedLoginBox
        authOptions={{
          keepSessionAlive: true,
          enableSessionPerTenant: true,
          hostedLoginOptions: {
            loadUserOnFirstLoad: true,
          },
        }}
      >
        {children}
      </ActualFronteggProvider>
      {isLoading && <SpinStyled spinning size="large" />}
    </>
  );
};

export default FronteggProvider;
