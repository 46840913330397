import _ from "lodash";

import customizedConditionDescription, {
  type IConditionDescriptionContext,
} from "./customizedConditionDescription";
import * as logicalOperators from "./logicalOperators";
import { allConditions } from "./operators";
import {
  DescriptionFormat,
  PaymentType,
  type Action,
  type ConditionOperator,
  type IAction,
  type ICondition,
  type IDescribedCondition,
  type IDescribedValue,
  type IEvaluation,
  type IOperatorDefinition,
  type LogicalOperator,
  type NullableValue,
} from "./types";

export abstract class BaseRuleBuilder<RuleType> {
  protected _actions: IAction[];
  protected _conditions: IDescribedCondition[];
  protected _evaluation: IEvaluation;
  protected _operators: Record<string, IOperatorDefinition>;
  protected _type: NullableValue<RuleType>;
  protected _defaultDescription: NullableValue<string>;
  protected _conditionDescriptionContext: IConditionDescriptionContext;

  protected constructor(ruleType?: NullableValue<RuleType>) {
    this._type = ruleType || null;
    this._actions = [];
    this._conditions = [];
    this._defaultDescription = null;
    this._evaluation = {
      actions: [],
      conditions: [],
      if: null,
    };
    this._operators = allConditions;
    this._conditionDescriptionContext = {
      paymentTypes: [
        PaymentType.Card,
        PaymentType.Mileage,
        PaymentType.Personal,
        PaymentType.Reimbursable,
      ],
    };
  }

  public setDefaultDescription(value: NullableValue<string>): void {
    this._defaultDescription = value;
  }

  public setLogicalOperator(logicalOperator: LogicalOperator): void {
    this._evaluation.if = logicalOperator;
  }

  public addAction(operator: Action, parameters?: any, parameterName?: string): void {
    const newAction: IAction = {
      operator,
      [parameterName || "parameters"]: parameters,
    };
    this._actions.push(newAction);
    this._evaluation.actions?.push(newAction);
  }

  public addCondition({
    property,
    operator,
    value,
    isExclusion = false,
  }: {
    operator: ConditionOperator;
    property: IDescribedValue;
    value: IDescribedValue;
    isExclusion?: boolean;
  }): void {
    const describedCondition: IDescribedCondition = {
      operator,
      property,
      value,
    };

    const condition: ICondition = {
      operator,
      property: property.value as string,
      value: value.value,
      isExclusion,
    };

    this._conditions.push(describedCondition);
    this._evaluation.conditions.push(condition);
  }

  public getRule(descriptionForms = DescriptionFormat.ConditionBeforeAction) {
    return {
      type: this._type,
      description: this.getDescription(descriptionForms),
      evaluation: this._evaluation,
    };
  }

  protected abstract getActionText(): string | undefined;

  protected actionsToString(): string {
    if (!this._actions.length) {
      return "...";
    }
    return this.getActionText() || "";
  }

  protected conditionToString(condition: IDescribedCondition): string {
    const specificFieldCondition = customizedConditionDescription({
      property: condition.property.value as string,
      operator: condition.operator,
      value: condition.value.value,
      context: this._conditionDescriptionContext,
    });
    if (specificFieldCondition) {
      return specificFieldCondition;
    }
    const actionDefinition: IOperatorDefinition = this.getOperator(condition);
    return `${condition.property.description} ${actionDefinition.text} ${condition.value.description}`;
  }

  protected conditionsToString(conditions: IDescribedCondition[]): string {
    if (!conditions.length) {
      return "...";
    }
    let conditionsStr = "";

    for (let i = 0; i < conditions.length; i++) {
      conditionsStr += this.conditionToString(conditions[i]!);

      if (conditions.length > 1 && i != conditions.length - 1) {
        let logicalOperator;
        if (this._evaluation.if === null) {
          return (conditionsStr += "...");
        } else {
          logicalOperator = this.getMatchingLogicalOperator(this._evaluation.if);
          if (!logicalOperator) throw new Error("Logical operator not found");
        }
        conditionsStr += ` ${logicalOperator.text} `;
      }
    }

    return conditionsStr;
  }

  protected getDescription(descriptionFormat: DescriptionFormat): NullableValue<string> {
    const representation = "When ";

    if (this._conditions.length + this._actions.length === 0) {
      return this._defaultDescription;
    }

    const conditionPart = representation + this.conditionsToString(this._conditions);
    const actionPart = this.actionsToString();

    return String(this.getFormattedDescription(descriptionFormat, conditionPart, actionPart));
  }

  private getMatchingLogicalOperator(logicalOperator: LogicalOperator) {
    const matchingLogicalOperator = _.find(logicalOperators, (o) => o.ID === logicalOperator);

    if (!matchingLogicalOperator) throw new Error("Logical operator not found");

    return matchingLogicalOperator;
  }

  private getOperator(expressionMetadata: IDescribedCondition): IOperatorDefinition {
    const operator = this._operators[expressionMetadata.operator];

    if (!operator) throw new Error("Operator not found.");

    return operator;
  }

  private getFormattedDescription(
    descriptionFormat: DescriptionFormat,
    conditionStr: string,
    actionStr: string,
  ): string {
    if (descriptionFormat === DescriptionFormat.ConditionBeforeAction) {
      return `${conditionStr}${actionStr}${this._actions.length ? "." : ""}`;
    }

    const actionPart = `${actionStr.trim().charAt(0).toUpperCase()}${actionStr.trim().slice(1)}`;
    const conditionPart = `${conditionStr.charAt(0).toLowerCase()}${conditionStr.slice(1)}`;

    return `${actionPart} ${conditionPart}${this._conditions.length ? "." : ""}`;
  }
}
