/* eslint-disable tailwindcss/no-custom-classname */
import PropTypes from "prop-types";
import styled from "styled-components";

import Colors from "../../themes/colors";
import Icon from "../Icon";

const IconWrapper = styled.div`
  border: none !important;
  background: transparent !important;

  i {
    color: ${Colors.placeholder_gray} !important;
  }
`;

const ExpandIcon = ({ expanded, expandable, onExpand, needIndentSpaced }) => {
  if (!expandable && !needIndentSpaced) return null;
  if (!expandable) return <span className="ant-table-row-expand-icon ant-table-row-spaced" />;
  let type;
  let ariaLabel;
  if (expanded) {
    type = "caret-down";
    ariaLabel = "Collapse row";
  } else {
    type = "caret-right";
    ariaLabel = "Expand row";
  }
  return (
    <IconWrapper onClick={onExpand} className="ant-table-row-expand-icon" ariaLabel={ariaLabel}>
      <Icon type={type} />
    </IconWrapper>
  );
};

ExpandIcon.propTypes = {
  expanded: PropTypes.bool,
  expandable: PropTypes.bool,
  needIndentSpaced: PropTypes.bool,
  onExpand: PropTypes.func,
};

export default ExpandIcon;
