import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { useMutation } from "react-apollo";

import { Button, Sidebar } from "@core/ui";
import { Notifier } from "@core/ui-legacy";

import { CardExpirationContext } from "../../../common/contexts/CardExpirationProvider";
import { GET_ME } from "../../../services/graphql/queries";
import {
  checkSuggestions,
  NOTIFICATIONS,
  validateInternally,
  VERIFY_ADDRESS,
} from "../cardsExpiration.utils";
import { InvalidAddressModal } from "../modals/InvalidAddressModal";
import type { AddressInput, AddressInputErrors, Step } from "../types";
import { Suggestion } from "./steps";
import { AddressForm } from "./steps/AddressForm";

type Steps = "input" | "suggestion";

export const SPAddressUpdateSidebar = () => {
  const {
    showSPAddressUpdateSidebar,
    handleSPAddressVerification,
    userAddress,
    setShowSPAddressUpdateSidebar,
  } = useContext(CardExpirationContext);
  const [selection, setSelection] = useState<"suggested" | "original">("suggested");
  const [showInvalidAddressModal, setShowInvalidAddressModal] = useState(false);
  const [currentStep, setCurrentStep] = useState<Steps>("input");
  const suggestedAddress = useRef<AddressInput>();
  const usedAutoComplete = useRef(false);

  const defaultAddress: AddressInput = useMemo(
    () => ({
      line1: userAddress?.address ?? "",
      line2: userAddress?.address2,
      city: userAddress?.city ?? "",
      state: userAddress?.state ?? "",
      zipCode: userAddress?.postalCode ?? "",
      country: userAddress?.country === "CAN" ? "CAN" : "USA",
    }),
    [
      userAddress?.address,
      userAddress?.address2,
      userAddress?.city,
      userAddress?.state,
      userAddress?.postalCode,
      userAddress?.country,
    ],
  );
  const [errors, setErrors] = useState<AddressInputErrors>({});
  const [formData, setFormData] = useState(defaultAddress);
  const [verifyAddress, { loading }] = useMutation(VERIFY_ADDRESS, {
    refetchQueries: [{ query: GET_ME }],
  });

  const fireMutation = useCallback(
    async (address: AddressInput) => {
      await verifyAddress({
        variables: {
          input: {
            address: address.line1,
            address2: address.line2,
            city: address.city,
            state: address.state,
            postalCode: address.zipCode,
            country: address.country,
            isVerified: true,
            isChanged: true,
          },
        },
      });
    },
    [verifyAddress],
  );

  const handleClose = useCallback(() => {
    setShowSPAddressUpdateSidebar(false);
    Notifier.notification(NOTIFICATIONS.addressVerified);
  }, [setShowSPAddressUpdateSidebar]);

  const handleSubmit = useCallback(async () => {
    const { hasErrors, issues } = validateInternally(formData);
    setErrors((prev) => issues || prev);
    if (hasErrors) return;

    try {
      if (!usedAutoComplete.current) {
        const suggestion = await checkSuggestions(formData);
        if (suggestion) {
          suggestedAddress.current = suggestion;
          setCurrentStep("suggestion");
          return;
        }
        setShowInvalidAddressModal(true);
        return;
      }

      await fireMutation(formData);
      handleClose();
    } catch {
      Notifier.notification(NOTIFICATIONS.addressVerificationFailed);
    }
  }, [formData, handleClose, fireMutation]);

  const reset = useCallback(() => {
    handleSPAddressVerification();
  }, [handleSPAddressVerification]);

  const handleSuggestion = useCallback(async () => {
    if (!suggestedAddress.current) return;
    const chosenAddress = selection === "suggested" ? suggestedAddress.current : formData;
    await fireMutation(chosenAddress);
    setFormData(chosenAddress);
  }, [fireMutation, formData, selection]);

  const handleUsedAutocomplete = (used: boolean) => {
    usedAutoComplete.current = used;
  };

  const handleVerifyInvalidAddress = useCallback(async () => {
    await fireMutation(formData);
    setShowInvalidAddressModal(false);
    handleClose();
  }, [handleClose, formData, fireMutation]);

  const steps: Record<Steps, Step> = {
    input: {
      title: "Enter your new shipping address",
      description:
        "Your cards are expiring soon.\nIs this the correct address to send your replacement cards to?",
      content: (
        <div className="!mt-0 flex items-center gap-2">
          <AddressForm
            formData={formData}
            setFormData={setFormData}
            errors={errors}
            setErrors={setErrors}
            handleUsedAutocomplete={handleUsedAutocomplete}
          />
        </div>
      ),
      actions: [
        <div key="placeholder" />, // buttons render left to right
        <Button
          key="submit"
          text="Submit"
          size="large"
          isLoading={loading}
          onClick={handleSubmit}
        />,
      ],
    },
    suggestion: {
      title: "Enter your new shipping address",
      description:
        "There was an issue with the address that you've entered, we've come up with a suggestion below. Please choose the version that you would like to use. You can also return to the previous stage to edit the address.",
      content: (
        <Suggestion
          originalAddress={formData}
          suggestedAddress={suggestedAddress.current}
          selection={selection}
          setSelection={setSelection}
        />
      ),
      actions: [
        <Button
          key="back"
          text="Back"
          variant="secondary"
          onClick={() => setCurrentStep("input")}
        />,
        <Button key="Submit" text="Submit" onClick={handleSuggestion} isLoading={loading} />,
      ],
    },
  };

  const { title, content, actions, description } = steps[currentStep] ?? {};

  return (
    <>
      <Sidebar
        title={title}
        description={description}
        open={showSPAddressUpdateSidebar}
        onClose={reset}
        actionButtons={actions}
      >
        {content}
      </Sidebar>
      <InvalidAddressModal
        line1={formData.line1}
        line2={formData.line2}
        city={formData.city}
        zipCode={formData.zipCode}
        state={formData.state}
        country={formData.country}
        showInvalidAddressModal={showInvalidAddressModal}
        setShowInvalidAddressModal={setShowInvalidAddressModal}
        handleVerifyInvalidAddress={handleVerifyInvalidAddress}
      />
    </>
  );
};
