/* eslint-disable react/jsx-props-no-spreading */
import { createRef, useEffect } from "react";
import { Carousel as AntCarousel } from "antd";
import _get from "lodash/get";
import PropTypes from "prop-types";
import styled from "styled-components";

import { ReactComponent as Left } from "../../assets/imgs/left.svg";
import { ReactComponent as Right } from "../../assets/imgs/right.svg";

const CarouselWrapper = styled.div`
  position: relative;
  height: 100%;

  .slick-list,
  .slick-track,
  .slick-slide,
  .slick-slider,
  .ant-carousel {
    height: 100% !important;
  }

  color: lightgray;

  @supports (filter: drop-shadow(0 2px 8px rgba(0, 0, 0, 0.3))) {
    color: #fff;
  }

  .slick-slide > div {
    height: 100%;
  }
`;

const ImagePanel = styled.div`
  height: ${({ height }) => height};
  background-image: url(${({ img }) => img});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
`;

const CarouselLeftButton = styled(Left)`
  position: absolute;
  top: calc(50% - 1.312rem);
  font-size: 2.625rem;
  border: none !important;
  border-radius: 50px;
  z-index: 1;
  cursor: pointer;
`;

const CarouselRightButton = styled(Right)`
  position: absolute;
  top: calc(50% - 1.312rem);
  font-size: 2.625rem;
  border: none !important;
  border-radius: 50px;
  z-index: 1;
  cursor: pointer;
`;

const PrevButton = styled(CarouselLeftButton)`
  left: 1rem;
`;

const NextButton = styled(CarouselRightButton)`
  right: 1rem;
`;

const ImageCarousel = (props) => {
  // props
  const { imageUrls, height, items, activeIndex, ...rest } = props;
  // a reference to the AntCarousel
  const antCarousel = createRef();

  useEffect(() => {
    if (activeIndex) {
      antCarousel.current.slick.slickGoTo(activeIndex, true);
    }
  });

  const handlePrevClick = () => {
    antCarousel.current.slick.slickPrev();
  };

  const handleNextClick = () => {
    antCarousel.current.slick.slickNext();
  };

  const showPrevNext = _get(imageUrls, "length", 0) > 1 || _get(items, "length", 0) > 1;

  return (
    <CarouselWrapper>
      {showPrevNext && (
        <>
          <PrevButton type="left-circle" onClick={handlePrevClick} />
          <NextButton type="right-circle" onClick={handleNextClick} />
        </>
      )}

      <AntCarousel ref={antCarousel} {...rest}>
        {imageUrls && imageUrls.map((url) => <ImagePanel key={url} img={url} height={height} />)}
        {items && items}
      </AntCarousel>
    </CarouselWrapper>
  );
};

ImageCarousel.propTypes = {
  imageUrls: PropTypes.arrayOf(PropTypes.string),
  height: PropTypes.number,
  items: PropTypes.array,
  dots: PropTypes.bool,
  activeIndex: PropTypes.number,
};

ImageCarousel.defaultProps = {
  dots: true,
};

export default ImageCarousel;
