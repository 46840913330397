import { useState } from "react";
import MaskedInput from "antd-mask-input";
import PropTypes from "prop-types";
import Select from "react-select";
import styled from "styled-components";

import { Button } from "@core/ui-legacy";
import Colors from "@core/ui-legacy/themes/colors";

import { ReactComponent as BottomArrowSvg } from "../../assets/imgs/bottom-arrow.svg";
import { ReactComponent as CanadaFlag } from "../../assets/imgs/Flagged_Canada.svg";
import { ReactComponent as USAFlag } from "../../assets/imgs/Flagged_USA.svg";
import { ReactComponent as SearchIconSvg } from "../../assets/imgs/search-icon.svg";

const DataSelectorStyled = styled(Select)`
  max-width: 18.75rem !important;
  width: 100% !important;
  text-transform: capitalize !important;

  &#country-select-id > div {
    flex-direction: row-reverse !important;
  }
`;

const OptionStyled = styled.div`
  display: flex;
  align-items: center;
`;

const OptionNameStyled = styled.span`
  padding-left: 0.5rem;
`;
const OptionPhoneStyled = styled.span`
  margin-left: auto;
  color: ${Colors.placeholder_gray};
`;

const SearchIconStyled = styled.div`
  margin-left: 0.5rem;
`;

const PhoneBlockStyled = styled.div`
  display: flex;
`;

const ButtonStyled = styled(Button)`
  &#select-country-btn {
    display: flex;
    height: 2.438rem;
    width: 3.75rem;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.625rem;
    background: ${Colors.bg_gray} !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border: 1px solid ${Colors.fog_gray} !important;

    :focus,
    :hover {
      background: ${Colors.sky} !important;
      opacity: 1 !important;

      svg:last-child {
        transform: rotate(180deg);

        path {
          fill: ${Colors.midnight};
        }
      }
    }

    svg:last-child {
      transition: transform 0.2s;
    }

    :not(.ant-btn-background-ghost, [disabled]) {
      height: 2.438rem !important;
    }
  }
`;

const InputBlockStyled = styled.div`
  height: 2.438rem;
  width: 15rem;
  position: relative;
  border-color: ${Colors.fog_gray};

  ::after {
    position: absolute;
    inset: 0;
    padding-left: 0.688rem;
    line-height: 2.438rem;
    pointer-events: none;
    content: "+1";
  }
`;

const InputStyled = styled(MaskedInput)`
  height: 2.438rem;
  border-radius: 0;
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
  padding-left: 1.688rem;

  :focus,
  :hover {
    border-color: ${Colors.cool_gray} !important;
  }
`;

const ErrorStyled = styled.div`
  position: absolute;
  font-size: 0.75rem;
  color: ${Colors.danger_red};
`;

const optionsData = [
  {
    value: "Canada",
    label: (
      <OptionStyled>
        <CanadaFlag />
        <OptionNameStyled>Canada</OptionNameStyled>
        <OptionPhoneStyled>+1</OptionPhoneStyled>
      </OptionStyled>
    ),
  },
  {
    value: "United States",
    label: (
      <OptionStyled>
        <USAFlag />
        <OptionNameStyled>United States</OptionNameStyled>
        <OptionPhoneStyled>+1</OptionPhoneStyled>
      </OptionStyled>
    ),
  },
];

const PhoneInput = ({ phone, changePhone, validateError }) => {
  const [inputMode, setMode] = useState(true);
  const [country, setCountry] = useState("United States");

  const parse = (value) => value.replace(/\(|\)|_|\+|\s|-/g, "");
  const onChangePhone = (e) => changePhone(parse(e.target.value));

  const onClick = () => setMode(false);

  const onChangeCountry = (item) => {
    setCountry(item.value);
    setMode(true);
  };

  const renderSearchIcon = () => (
    <SearchIconStyled>
      <SearchIconSvg />
    </SearchIconStyled>
  );

  const renderPhoneMode = () => {
    const label = country === "United States" ? <USAFlag /> : <CanadaFlag />;
    return (
      <div>
        <PhoneBlockStyled id="phone-country-block">
          <ButtonStyled
            id="select-country-btn"
            key="select-country-btn"
            data-testid="select-country-btn"
            type="cancel"
            size="default"
            onClick={onClick}
          >
            {label}
            <BottomArrowSvg />
          </ButtonStyled>
          <InputBlockStyled>
            <InputStyled
              id="phone-input-id"
              key="phone-input"
              data-testid="phone-input"
              mask="(000) 000 0000"
              value={phone}
              onChange={onChangePhone}
              autoFocus
            />
          </InputBlockStyled>
        </PhoneBlockStyled>
        {validateError.length ? <ErrorStyled>{validateError}</ErrorStyled> : null}
      </div>
    );
  };

  const renderSelectMode = () => (
    <DataSelectorStyled
      id="country-select-id"
      key="country-select"
      data-testid="country-select"
      components={{
        DropdownIndicator: renderSearchIcon,
        IndicatorSeparator: null,
      }}
      menuIsOpen
      onChange={onChangeCountry}
      options={optionsData}
      placeholder="Search for a country"
      tabSelectsValue={false}
      value={1}
    />
  );

  return <>{inputMode ? renderPhoneMode() : renderSelectMode()}</>;
};

PhoneInput.propTypes = {
  phone: PropTypes.string,
  changePhone: PropTypes.func,
  validateError: PropTypes.string,
};

export default PhoneInput;
