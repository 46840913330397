import gql from "graphql-tag";

import {
  ADDRESS_FRAGMENT,
  CARD_FRAGMENT,
  CARD_INFO_FRAGMENT,
  CARD_REQUEST_FRAGMENT,
  ERP_CONFIGURATION_FRAGMENT,
  EVALUATED_EXPENSE_FORM_FRAGMENT,
  EVENTS_FRAGMENT,
} from "./fragments";

export const SET_AUTH = gql`
  mutation setAuth($isAuthenticated: Auth) {
    setAuth(isAuthenticated: $isAuthenticated) @client
  }
`;

export const SET_CARD_STATUS = gql`
  mutation updateCardStatus($id: ID!, $statusCode: String!, $isAdminLocked: Boolean) {
    updateCardStatus(id: $id, statusCode: $statusCode, isAdminLocked: $isAdminLocked) {
      ID
      statusCode
      statusLabel
      cardInfo {
        ...CardInfoFragment
      }
    }
  }
  ${CARD_INFO_FRAGMENT}
`;

export const ACTIVATE_CARD = gql`
  mutation activateCard($cardID: ID!, $last4: String!) {
    activateCard(cardID: $cardID, last4: $last4)
  }
`;

export const FRAUD_ALERT = gql`
  mutation fraudAlert($enable: Boolean!) {
    fraudAlert(enable: $enable)
  }
`;

export const TOGGLE_SHOW_APP_NOTIFICATIONS_PANEL = gql`
  mutation setShowAppNotificationsPanel($visible: Boolean, $onlyHRIS: Boolean) {
    setShowAppNotificationsPanel(visible: $visible, onlyHRIS: $onlyHRIS) @client
  }
`;

export const TOGGLE_SHOW_RECEIPTS_BIN_PANEL = gql`
  mutation setShowReceiptsBinPanel($visible: Boolean) {
    setShowReceiptsBinPanel(visible: $visible) @client
  }
`;

export const ATTACH_RECEIPTS_TO_EXPENSE = gql`
  mutation attachReceiptsToExpense($expenseId: ID!, $fileIds: [ID!], $userId: ID, $delegateOf: ID) {
    attachReceiptsToExpense(
      expenseId: $expenseId
      fileIds: $fileIds
      userId: $userId
      delegateOf: $delegateOf
    ) {
      fileID
      URI
      type
      uploadDate
    }
  }
`;

export const DETACH_RECEIPTS_FROM_EXPENSE = gql`
  mutation detachReceiptsFromExpense(
    $expenseId: ID!
    $fileIds: [ID!]
    $spenderId: ID
    $delegateOf: ID
  ) {
    detachReceiptsFromExpense(
      expenseId: $expenseId
      fileIds: $fileIds
      spenderId: $spenderId
      delegateOf: $delegateOf
    ) {
      fileID
      URI
      type
      uploadDate
    }
  }
`;

export const getUpdateExpenseStatusMutation = (awaitRefetch) => {
  const updateExpenseStatusFragment = awaitRefetch
    ? ""
    : `
  status
  approvers {
    dateTime
    status
    userID
    userFullName
    avatarBackgroundColor
  }
`;
  return gql`
    mutation updateExpenseStatus(
      $id: ID!
      $status: String!
      $delegateOf: ID
      $prioritizeRole: String
    ) {
      updateExpenseStatus(
        id: $id
        status: $status
        delegateOf: $delegateOf
        prioritizeRole: $prioritizeRole
      ) {
        ID
        ${updateExpenseStatusFragment}
      }
    }
  `;
};

export const UPDATE_EXPENSE_STATUS = getUpdateExpenseStatusMutation();

export const UPDATE_EXPENSES_STATUS = gql`
  mutation updateExpensesStatus(
    $ids: [ID!]
    $status: String!
    $delegateOf: ID
    $prioritizeRole: String
  ) {
    updateExpensesStatus(
      ids: $ids
      status: $status
      delegateOf: $delegateOf
      prioritizeRole: $prioritizeRole
    ) {
      ID
      status
      approvers {
        dateTime
        status
        userID
        userFullName
        avatarBackgroundColor
      }
    }
  }
`;

export const CREATE_EXPENSE_BULK_ACTION = gql`
  mutation createExpenseBulkAction(
    $bulkAction: ExpenseBulkActionStatus!
    $entities: [String!]!
    $prioritizeRole: String
  ) {
    createExpenseBulkAction(
      bulkAction: $bulkAction
      entities: $entities
      prioritizeRole: $prioritizeRole
    ) {
      ID
      executionID
    }
  }
`;

export const CANCEL_EXPENSE_BULK_ACTION = gql`
  mutation cancelExpenseBulkAction($bulkActionInfoID: String!, $bulkActionExecutionID: String!) {
    cancelExpenseBulkAction(
      bulkActionInfoID: $bulkActionInfoID
      bulkActionExecutionID: $bulkActionExecutionID
    ) {
      deleted
    }
  }
`;

export const SEND_BACK_EXPENSE = gql`
  mutation sendBackExpense($id: ID!, $comment: String!) {
    sendBackExpense(id: $id, comment: $comment) {
      ID
      approvers {
        dateTime
        status
        userID
        userFullName
        avatarBackgroundColor
      }
    }
  }
`;

export const UNARCHIVE_EXPENSE = gql`
  mutation unarchiveExpense($id: ID!) {
    unarchiveExpense(id: $id) {
      ID
    }
  }
`;

export const ADD_COMMENT_TO_EXPENSE = gql`
  mutation addCommentToExpense($id: ID!, $comment: String!, $delegateOf: ID) {
    addCommentToExpense(id: $id, comment: $comment, delegateOf: $delegateOf) {
      values
      timeStamp
      type
    }
  }
`;

export const UPDATE_HAS_RECEIPTS = gql`
  mutation updateHasReceipts($id: ID!, $input: ExpenseInput!, $delegateOf: ID) {
    updateExpense(id: $id, input: $input, delegateOf: $delegateOf) {
      ID
      hasAutogeneratedReceipt
      hasReceipts
      receiptAffidavit {
        receiptAffidavitAvailable
      }
      status
      form {
        ...EvaluatedExpenseFormFragment
      }
    }
  }
  ${EVALUATED_EXPENSE_FORM_FRAGMENT}
`;

export const getUpdateExpenseMutation = (fields = "") => {
  let requestedFields = fields;
  if (fields.includes("expenseTypeID")) {
    requestedFields = "";
  }
  const UPDATE_EXPENSE = gql`
    mutation updateExpense($id: ID!, $input: ExpenseInput!, $delegateOf: ID, $prioritizeRole: String) {
      updateExpense(id: $id, input: $input, delegateOf: $delegateOf, prioritizeRole: $prioritizeRole) {
        ID
        amount
        ${requestedFields}
        expenseTypeID
        expenseTypeName
        expenseType {
          ID
          name
          isDeleted
        }
        customFields
        form {
          ...EvaluatedExpenseFormFragment
        }
        events {
          ...EventFragment
        }
        isMileage
        mileage @client
        fields {
          reimbursement {
            isReimbursable
          }
        }
      }
    }
    ${EVALUATED_EXPENSE_FORM_FRAGMENT}
    ${EVENTS_FRAGMENT}
  `;
  return UPDATE_EXPENSE;
};

export const getUpdateExpensesMutation = (fields = "") => {
  const UPDATE_EXPENSE = gql`
    mutation updateExpenses($ids: [ID!], $input: ExpenseInput!) {
      updateExpenses(ids: $ids, input: $input) {
        ID
        ${fields}
      }
    }
  `;
  return UPDATE_EXPENSE;
};

export const SET_ASYNC_JOBS = gql`
  mutation setAsyncJobs($asyncJobsData: JSON!) {
    setAsyncJobs(asyncJobsData: $asyncJobsData) @client
  }
`;

export const SET_COMPONENT_STATE = gql`
  mutation setComponentState($id: String!, $state: JSON!) {
    setComponentState(id: $id, state: $state) @client
  }
`;

export const DELETE_OUT_OF_POCKET_EXPENSE = gql`
  mutation deleteOutOfPocket($id: ID!, $delegateOf: ID) {
    deleteOutOfPocket(id: $id, delegateOf: $delegateOf) {
      ID
    }
  }
`;

export const DELETE_EXPENSE_EXPORT = gql`
  mutation deleteExpenseExport($id: ID!) {
    deleteExpenseExport(id: $id) {
      ID
    }
  }
`;

export const APPROVE_ALL_EXPENSES = gql`
  mutation approveAllExpenses {
    approveAllExpenses {
      ID
    }
  }
`;

export const ARCHIVE_ALL_EXPENSES = gql`
  mutation archiveAllExpenses {
    archiveAllExpenses {
      ID
    }
  }
`;

export const CREATE_EXPENSE_EXPORT = gql`
  mutation createExpenseExport(
    $name: String
    $start: AWSDateTime!
    $end: AWSDateTime!
    $filterByDate: String
    $expenseStatuses: [String]
    $format: String
    $isOOP: Boolean
    $filePostingDate: String
    $filter: String
    $viewName: String
    $jobType: String
  ) {
    createExpenseExport(
      name: $name
      start: $start
      end: $end
      filterByDate: $filterByDate
      expenseStatuses: $expenseStatuses
      format: $format
      filter: $filter
      jobType: $jobType
      isOOP: $isOOP
      filePostingDate: $filePostingDate
      viewName: $viewName
    ) {
      ID
    }
  }
`;

export const CREATE_EXPENSE_EXPORT_CHANGES = gql`
  mutation createExpenseExportChanges($expenseExportId: ID!) {
    createExpenseExportChanges(expenseExportId: $expenseExportId) {
      ID
    }
  }
`;

export const SET_ERP_CONFIGURATION = gql`
  mutation updateErpConfiguration($input: ERPConfigurationInput) {
    updateErpConfiguration(input: $input) {
      ...ERPConfigurationFragment
    }
  }
  ${ERP_CONFIGURATION_FRAGMENT}
`;

export const CREATE_BULK_JOB = gql`
  mutation createBulkJob($input: JobBulkInput) {
    createBulkJob(input: $input) {
      ID
    }
  }
`;

export const DELETE_POLICY_RULE = gql`
  mutation deletePolicyRule($id: ID!) {
    deletePolicyRule(id: $id) {
      ID
    }
  }
`;

export const DEACTIVATE_EXPENSE_TYPE = gql`
  mutation deactivateExpenseType($id: ID!) {
    deactivateExpenseType(id: $id) {
      ID
      isDeleted
    }
  }
`;

export const DEACTIVATE_COST_CENTER = gql`
  mutation deactivateCostCenter($id: ID!) {
    deactivateCostCenter(id: $id) {
      ID
      archived
    }
  }
`;

export const UPDATE_COST_CENTER = gql`
  mutation updateCostCenter($id: ID!, $input: CostCenterInput!) {
    updateCostCenter(id: $id, input: $input) {
      ID
      name
      parentID
      archived
      parent @client {
        name
      }
      spenders
    }
  }
`;

export const DEACTIVATE_USER = gql`
  mutation deactivateUser($id: ID!) {
    deactivateUser(id: $id) {
      ID
      archived
    }
  }
`;

export const CREATE_EXPENSE_FIELD = gql`
  mutation createExpenseField($input: ExpenseFieldInput!) {
    createExpenseField(input: $input) {
      description
      name
      uiType
      values {
        name
      }
    }
  }
`;

export const UPDATE_EXPENSE_FIELD = gql`
  mutation updateExpenseField($id: ID!, $input: ExpenseFieldUpdateInput!) {
    updateExpenseField(id: $id, input: $input) {
      ID
      description
      name
      isDeleted
      values {
        name
      }
      mappingRuleID
      defaultValue
      calculatedProperties {
        property
        value
        rate
      }
    }
  }
`;

export const UPDATE_DEFAULT_VALUE_EXPENSE_FIELD = gql`
  mutation updateExpenseField($id: ID!, $input: ExpenseFieldUpdateInput!) {
    updateExpenseField(id: $id, input: $input) {
      ID
      defaultValue
    }
  }
`;

export const DEACTIVATE_EXPENSE_FIELD = gql`
  mutation deactivateExpenseField($id: ID!) {
    deactivateExpenseField(id: $id) {
      ID
      isDeleted
    }
  }
`;

export const UPDATE_SFTP_KEY = gql`
  mutation updateSftpKey($sshPublicKey: String!) {
    updateSftpKey(sshPublicKey: $sshPublicKey) {
      sshPublicKey
    }
  }
`;

export const DELETE_SFTP_KEY = gql`
  mutation deleteSftpKey($sshPublicKeyId: String!) {
    deleteSftpKey(sshPublicKeyId: $sshPublicKeyId) {
      sshPublicKeyId
    }
  }
`;

export const DELETE_SFTP_FILES = gql`
  mutation deleteSftpFiles($originalFilePath: String!, $errorFilePath: String) {
    deleteSftpFiles(originalFilePath: $originalFilePath, errorFilePath: $errorFilePath) {
      message
    }
  }
`;

export const UPDATE_NOTIFICATIONS_EMAILS = gql`
  mutation updateNotificationsEmails($notificationsEmails: [NotificationsEmailInput]) {
    updateNotificationsEmails(notificationsEmails: $notificationsEmails) {
      isEnabled
      recipients
      type
      meta {
        threshold
        daysOfWeek
        frequency
      }
    }
  }
`;

/** Dwolla */

export const CREATE_DWOLLA_CUSTOMER = gql`
  mutation createDwollaCustomer(
    $firstName: String!
    $lastName: String!
    $email: String!
    $address1: String
    $address2: String
    $businessClassification: String
    $businessName: String
    $businessType: String
    $city: String
    $dateOfBirth: String
    $doingBusinessAs: String
    $ein: String
    $postalCode: String
    $ssn: String
    $state: String
    $type: String
  ) {
    createDwollaCustomer(
      firstName: $firstName
      lastName: $lastName
      email: $email
      address1: $address1
      address2: $address2
      businessClassification: $businessClassification
      businessName: $businessName
      businessType: $businessType
      city: $city
      dateOfBirth: $dateOfBirth
      doingBusinessAs: $doingBusinessAs
      ein: $ein
      postalCode: $postalCode
      ssn: $ssn
      state: $state
      type: $type
    ) {
      customerID
    }
  }
`;

export const UPDATE_DWOLLA_FUNDING_SOURCE = gql`
  mutation updateDwollaFundingSource($fundingSourceID: ID!, $name: String!) {
    updateDwollaFundingSource(fundingSourceID: $fundingSourceID, name: $name) {
      response
    }
  }
`;

export const DELETE_DWOLLA_FUNDING_SOURCE = gql`
  mutation deleteDwollaFundingSource($fundingSourceID: ID!) {
    deleteDwollaFundingSource(fundingSourceID: $fundingSourceID) {
      response
    }
  }
`;

export const VERIFY_DWOLLA_MICRO_DEPOSITS = gql`
  mutation verifyDwollaMicroDeposits($fundingSourceID: ID!, $amount1: String!, $amount2: String!) {
    verifyDwollaMicroDeposits(
      fundingSourceID: $fundingSourceID
      amount1: $amount1
      amount2: $amount2
    ) {
      status
      code
      description
    }
  }
`;

export const ATTACH_DWOLLA_CUSTOMER_TO_ORG = gql`
  mutation attachDwollaCustomerToOrg($orgID: ID!, $customerID: ID!) {
    attachDwollaCustomerToOrg(orgID: $orgID, customerID: $customerID) {
      response
    }
  }
`;

export const ATTACH_DWOLLA_CUSTOMER_TO_USER = gql`
  mutation attachDwollaCustomerToUser($userID: ID!, $customerID: ID!) {
    attachDwollaCustomerToUser(userID: $userID, customerID: $customerID) {
      response
    }
  }
`;

export const REMOVE_DWOLLA_FUNDING_SOURCE_FROM_ORG = gql`
  mutation removeDwollaFundingSourceFromOrg($orgID: ID!, $fundingSourceID: ID!) {
    removeDwollaFundingSourceFromOrg(orgID: $orgID, fundingSourceID: $fundingSourceID) {
      response
    }
  }
`;

export const UPDATE_DIRECT_REIMBURSEMENT_ORG_POLICY = gql`
  mutation updateDirectReimbursementOrgPolicy(
    $orgID: ID!
    $isDirectReimbursable: Boolean!
    $paymentSchedule: DwollaPaymentScheduleInput
  ) {
    updateDirectReimbursementOrgPolicy(
      orgID: $orgID
      isDirectReimbursable: $isDirectReimbursable
      paymentSchedule: $paymentSchedule
    ) {
      response
    }
  }
`;

export const UPDATE_DWOLLA_TRANSFER = gql`
  mutation updateDwollaTransfer($transferID: ID!, $transferStatus: String) {
    updateDwollaTransfer(transferID: $transferID, transferStatus: $transferStatus) {
      response
    }
  }
`;

export const FINALIZE_RETRY_DWOLLA_TRANSFER = gql`
  mutation finalizeRetryDwollaTransfer($transferID: ID!) {
    finalizeRetryDwollaTransfer(transferID: $transferID) {
      response
    }
  }
`;

export const CREATE_TRIP = gql`
  mutation createTrip($input: TripInput!, $orgID: ID) {
    createTrip(input: $input, orgID: $orgID) {
      ID
      name
      purpose
      type
      startDateTime
      endDateTime
      groupEventID
      userID
    }
  }
`;

export const UPDATE_TRIP = gql`
  mutation updateTrip($id: ID!, $input: [PatchInput]!, $orgID: ID) {
    updateTrip(id: $id, input: $input, orgID: $orgID) {
      ID
      name
      purpose
      type
      startDateTime
      endDateTime
      groupEventID
    }
  }
`;

export const CREATE_USER = gql`
  mutation createUser($input: UserInput!, $flags: Flags) {
    createUser(input: $input, flags: $flags) {
      ID
      costCenters {
        ID
      }
      defaultCostCenter {
        ID
      }
      firstName
      lastName
      fullName
      roles
      insightsViewerOf {
        costCenters {
          ID
          name
        }
      }
      defaultApprover {
        ID
        name
        ruleID
      }
      defaultApprover @client {
        ID
        name
        ruleID
      }
      cardItems {
        ...CardFragment
      }
      cardOrdering {
        status
      }
    }
  }
  ${CARD_FRAGMENT}
`;

export const UPDATE_USER = gql`
  mutation updateUser($id: ID!, $input: UpdateUserInput!, $flags: Flags) {
    updateUser(id: $id, input: $input, flags: $flags) {
      ID
      billingAddress {
        ...AddressFragment
      }
      costCenters {
        ID
        name
      }
      defaultApprover {
        ID
        name
        ruleID
      }
      defaultApprover @client {
        ID
        name
        ruleID
      }
      reimbursementPolicy
      defaultCostCenter {
        ID
        name
      }
      delegateOf {
        ID
        firstName
        lastName
        #fullName @client
      }
      travelArrangerOf {
        ID
        firstName
        lastName
      }
      travelArrangerOfInfo {
        scope
        userType
        costCenters {
          ID
          name
        }
        users {
          ID
          firstName
          lastName
        }
      }
      firstName
      lastName
      fullName
      employeeID
      roles
      flags
      emailStatus
      undeliverableReason
      insightsViewerOf {
        costCenters {
          ID
          name
        }
      }
      cardItems {
        ...CardFragment
      }
    }
  }
  ${CARD_FRAGMENT}
  ${ADDRESS_FRAGMENT}
`;

export const APPROVE_CARD_REQUEST = gql`
  mutation approveCardRequest(
    $cardRequestID: ID!
    $limit: Int
    $cardFormat: String
    $shippingMethod: String
    $firstName: String
    $lastName: String
  ) {
    approveCardRequest(
      cardRequestID: $cardRequestID
      limit: $limit
      cardFormat: $cardFormat
      shippingMethod: $shippingMethod
      firstName: $firstName
      lastName: $lastName
    ) {
      ...CardRequestFragment
    }
  }
  ${CARD_REQUEST_FRAGMENT}
`;

export const DENY_CARD_REQUEST = gql`
  mutation denyCardRequest($cardRequestID: ID!) {
    denyCardRequest(cardRequestID: $cardRequestID) {
      ...CardRequestFragment
    }
  }
  ${CARD_REQUEST_FRAGMENT}
`;

export const SET_CARDS_TO_ASYNC_JOB = gql`
  mutation setCardsAsyncJobs($cardsAsyncJobsData: JSON!, $activeJob: Boolean) {
    setCardsAsyncJobs(cardsAsyncJobsData: $cardsAsyncJobsData, activeJob: $activeJob) @client
  }
`;

export const CREATE_CARD_APPROVAL_REQUEST = gql`
  mutation createCardApprovalRequest($input: CardApprovalRequestInput!) {
    cardApprovalRequest(input: $input) {
      ID
    }
  }
`;

export const UPDATE_CARD_APPROVAL_REQUEST = gql`
  mutation updateCardApprovalRequest($cardRequestID: ID!, $input: CardApprovalRequestInput!) {
    updateCardApprovalRequest(cardRequestID: $cardRequestID, input: $input) {
      ...CardRequestFragment
    }
  }
  ${CARD_REQUEST_FRAGMENT}
`;

export const DELETE_CARD_APPROVAL_REQUEST = gql`
  mutation deleteCardRequest($cardRequestID: ID!) {
    deleteCardRequest(cardRequestID: $cardRequestID) {
      ID
    }
  }
`;
export const SYNC_TRAVEL_POLICIES = gql`
  mutation syncTravelPolicies($tmc: String, $policyID: String, $operation: String) {
    syncTravelPolicies(tmc: $tmc, policyID: $policyID, operation: $operation) {
      tmc
      policyID
      operation
    }
  }
`;

export const CREATE_OUT_OF_POCKET = gql`
  mutation createOutOfPocket($input: OutOfPocketInput, $delegateOf: ID) {
    createOutOfPocket(input: $input, delegateOf: $delegateOf) {
      ID
    }
  }
`;

export const SWITCH_ORG = gql`
  mutation switchOrg($orgID: ID!) {
    switchOrg(orgID: $orgID) {
      access_token
      expires_in
      refresh_token
      token_type
    }
  }
`;
