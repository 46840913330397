import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";

import { useFlag } from "@core/feature-flags/clients";

import AuthUserContext from "../../../common/contexts/AuthUserContext";
import type { DeepPartial } from "../../../common/types";
import type { IAuthUser } from "../../../features/UserInfoPage/types";
import { fetchWithToken } from "../api";
import { handleError } from "../utils";
import {
  employeeSchema,
  employeesToDeactivateSchema,
  employeesToOnboardSchema,
  type Employee,
} from "./employees";
import { linkTokenSchema } from "./linkToken";
import { orgConfigQueryKey, orgConfigSchema, type OrgConfig } from "./orgConfig";

const getHrisServicesURL = (resource: string) =>
  `${import.meta.env.REACT_APP_DEVELOPER_API}/hris/${resource}`;

type Method = "GET" | "POST" | "PATCH" | "DELETE" | "PUT";

const defaultRequest = (method: Method, data?: Record<string, unknown>) => ({
  method,
  headers: { "Content-Type": "application/json" },
  body: data && JSON.stringify(data),
});

const errorMessage = (err: string, status: number) => ({
  errorMessage: err,
  status,
});

export const useHasHRIS = () => {
  return useFlag("release-hr-connect");
};

/** LINK TOKEN */
export const getLinkToken = async (data: { orgName: string; userEmail: string }) => {
  try {
    const response: Response = await fetchWithToken(
      getHrisServicesURL("link-token"),
      defaultRequest("POST", data),
    );

    if (!response.ok) {
      const { error }: { error: string } = await response.json();
      return Promise.reject(errorMessage(error, response.status));
    }

    const res = await response.json();
    const tokenResponse = linkTokenSchema.parse(res);
    return { linkToken: tokenResponse.link_token };
  } catch (error) {
    return handleError(error);
  }
};

/** ORG CONFIG */
export const createOrgConfig = async (data: {
  defaultCostCenter: string;
  publicToken: string;
  userId: string;
}) => {
  try {
    const { publicToken, userId, defaultCostCenter } = data;
    const response: Response = await fetchWithToken(
      getHrisServicesURL("configuration"),
      defaultRequest("POST", {
        defaultCostCenter,
        publicToken,
        createdBy: userId,
      }),
    );
    if (!response.ok) {
      const { error }: { error: string } = await response.json();
      return Promise.reject(errorMessage(error, response.status));
    }
    const res = await response.json();
    const orgConfigResponse = orgConfigSchema.parse(res);
    return orgConfigResponse;
  } catch (error) {
    return handleError(error);
  }
};

export const updateOrgConfig = async (data: DeepPartial<OrgConfig>) => {
  try {
    const response: Response = await fetchWithToken(
      getHrisServicesURL("configuration"),
      defaultRequest("PATCH", data),
    );
    if (!response.ok) {
      const { error }: { error: string } = await response.json();
      return Promise.reject(errorMessage(error, response.status));
    }
    return response.json();
  } catch (error) {
    return handleError(error);
  }
};

export const getOrgConfig = async () => {
  try {
    const response: Response = await fetchWithToken(
      getHrisServicesURL("configuration"),
      defaultRequest("GET"),
    );

    if (response.status === 404) {
      return Promise.resolve(null);
    }

    if (!response.ok) {
      const { error }: { error: string } = await response.json();
      return Promise.reject(errorMessage(error, response.status));
    }
    const res = await response.json();
    const orgConfigResponse = orgConfigSchema.parse(res);
    return orgConfigResponse;
  } catch (error) {
    return handleError(error);
  }
};

/** EMPLOYEES */

export const getEmployeeByUserId = async ({ userId }: { userId: string }) => {
  try {
    const response: Response = await fetchWithToken(
      getHrisServicesURL(`employees?userId=${userId}`),
      defaultRequest("GET"),
    );

    if (!response.ok) {
      const { error }: { error: string } = await response.json();
      return Promise.reject(errorMessage(error, response.status));
    }

    const res = await response.json();

    if (!res) {
      return null;
    }

    return employeeSchema.parse(res);
  } catch (error) {
    return handleError(error);
  }
};

export const getEmployeeByEmployeeId = async ({
  employeeId,
}: {
  employeeId: string;
}): Promise<Employee | null> => {
  try {
    const response: Response = await fetchWithToken(
      getHrisServicesURL(`employees?employeeId=${employeeId}`),
      defaultRequest("GET"),
    );

    if (!response.ok) {
      const { error }: { error: string } = await response.json();
      return Promise.reject(errorMessage(error, response.status));
    }

    const res = await response.json();

    if (!res) {
      return null;
    }

    return employeeSchema.parse(res);
  } catch (error) {
    return handleError(error);
  }
};

export const getEmployeesToOnboard = async () => {
  try {
    const responses = await Promise.allSettled([
      fetchWithToken(
        getHrisServicesURL("employees?processingstatus=READY_TO_ONBOARD"),
        defaultRequest("GET"),
      ),
      fetchWithToken(
        getHrisServicesURL("employees?processingstatus=FAILED_TO_ONBOARD"),
        defaultRequest("GET"),
      ),
    ]);

    const fulfilled = responses.reduce<Response[]>((acc, res) => {
      if (res.status === "fulfilled" && res.value.ok) {
        acc.push(res.value);
      }
      return acc;
    }, []);

    const res = await Promise.all(fulfilled.map((response) => response.json()));
    const filteredRes = res.filter(Boolean);

    if (!filteredRes?.length) {
      return [];
    }

    return employeesToOnboardSchema.parse(filteredRes.flat());
  } catch (error) {
    return handleError(error);
  }
};

export const getEmployeesToDeactivate = async () => {
  try {
    const responses = await Promise.allSettled([
      fetchWithToken(
        getHrisServicesURL("employees?processingstatus=READY_TO_DEACTIVATE"),
        defaultRequest("GET"),
      ),
      fetchWithToken(
        getHrisServicesURL("employees?processingstatus=FAILED_TO_DEACTIVATE"),
        defaultRequest("GET"),
      ),
    ]);

    const fulfilled = responses.reduce<Response[]>((acc, res) => {
      if (res.status === "fulfilled" && res.value.ok) {
        acc.push(res.value);
      }
      return acc;
    }, []);

    const res = await Promise.all(fulfilled.map((response) => response.json()));
    const filteredRes = res.filter(Boolean);

    if (!filteredRes?.length) {
      return [];
    }

    return employeesToDeactivateSchema.parse(filteredRes.flat());
  } catch (error) {
    return handleError(error);
  }
};

export const onboardEmployees = async (data: { employeeIds: string[] }) => {
  try {
    const response: Response = await fetchWithToken(
      getHrisServicesURL("onboard"),
      defaultRequest("POST", data),
    );
    if (!response.ok) {
      const { error }: { error: string } = await response.json();
      return Promise.reject(errorMessage(error, response.status));
    }
    const res = await response.json();
    return res;
  } catch (error) {
    return handleError(error);
  }
};

export const deactivateEmployees = async (data: { employeeIds: string[] }) => {
  try {
    const response: Response = await fetchWithToken(
      getHrisServicesURL("deactivate"),
      defaultRequest("POST", data),
    );
    if (!response.ok) {
      const { error }: { error: string } = await response.json();
      return Promise.reject(errorMessage(error, response.status));
    }
    const res = await response.json();
    return res;
  } catch (error) {
    return handleError(error);
  }
};

export const updateEmployeeUnlinkedFields = async (data: {
  empId: string;
  unlinkedFields: string[];
}) => {
  try {
    const response: Response = await fetchWithToken(
      getHrisServicesURL(`employees/${data.empId}/unlinked-fields`),
      defaultRequest("PUT", { unlinkedFields: data.unlinkedFields }),
    );
    if (!response.ok) {
      const { error }: { error: string } = await response.json();
      return Promise.reject(errorMessage(error, response.status));
    }
    const res = await response.json();
    return res;
  } catch (error) {
    return handleError(error);
  }
};
/** DEACTIVATE */
export const disconnectOrg = async () => {
  try {
    const response: Response = await fetchWithToken(
      getHrisServicesURL("organization"),
      defaultRequest("DELETE"),
    );
    if (!response.ok) {
      const { error }: { error: string } = await response.json();
      return Promise.reject(errorMessage(error, response.status));
    }
    return await response.json();
  } catch (error) {
    return handleError(error);
  }
};

export const useIsHRISLinked = () => {
  const user = useContext<IAuthUser>(AuthUserContext);
  const hasHRIS = useHasHRIS();
  const { data: orgConfig } = useQuery({
    queryKey: orgConfigQueryKey({ orgId: user.organization.ID }),
    enabled: hasHRIS,
    queryFn: getOrgConfig,
  });

  return orgConfig?.status === "COMPLETE";
};
