import { useCallback, useMemo, type ChangeEvent, type Dispatch, type SetStateAction } from "react";

import { Dropdown, Input, Label, Text } from "@core/ui";
import type { DropdownOption } from "@core/ui/components/Dropdown";

import { useGooglePlaces } from "../../../../common/useGooglePlaces";
import { countriesMap, COUNTRY_OPTIONS } from "../../cardsExpiration.utils";
import type { AddressInput, AddressInputErrors } from "../../types";

type AddressFormProps = {
  errors: AddressInputErrors;
  formData: AddressInput;
  handleUsedAutocomplete: (boolean) => void;
  setErrors: Dispatch<React.SetStateAction<AddressInputErrors>>;
  setFormData: Dispatch<SetStateAction<AddressInput>>;
};

export const AddressForm = ({
  formData,
  setFormData,
  errors,
  setErrors,
  handleUsedAutocomplete,
}: AddressFormProps) => {
  const { status, data, setValue, getZipCode } = useGooglePlaces();

  const suggestions = useMemo(
    () =>
      status === "OK" && data
        ? data.map(({ description, place_id }) => ({
            label: description,
            value: place_id,
          }))
        : [],
    [data, status],
  );

  const handleSuggestionSelect = useCallback(
    (e: DropdownOption) => {
      setFormData((prev) => ({ ...prev, line1: e?.label ?? "" }));
      handleUsedAutocomplete(false);
      const place = data.find(({ place_id }) => place_id === e?.value);
      if (!place) return;

      const [city, state, country] = place.structured_formatting.secondary_text.split(", ");

      const setAddress = async () => {
        const zipCode = await getZipCode(e?.label);

        setFormData((prev) => ({
          ...prev,
          line1: place.structured_formatting.main_text ?? "",
          city: city ?? "",
          state: state ?? "",
          zipCode: zipCode ?? "",
          country: countriesMap[country ?? ""] as AddressInput["country"],
        }));
        setErrors({});
        handleUsedAutocomplete(true);
      };

      void setAddress();
    },
    [data, setFormData, getZipCode, setErrors, handleUsedAutocomplete],
  );

  const handleInputChange = useCallback(
    (property: keyof AddressInput) => (e: ChangeEvent<HTMLInputElement>) => {
      setFormData((prev) => ({ ...prev, [property]: e.target.value }));
      if (property !== "line2") handleUsedAutocomplete(false);
    },
    [setFormData, handleUsedAutocomplete],
  );

  const handleCountryChange = useCallback(
    (e: DropdownOption<AddressInput["country"]>) => {
      setFormData((prev) => ({ ...prev, country: e?.value || null }));
      handleUsedAutocomplete(false);
    },
    [setFormData, handleUsedAutocomplete],
  );

  const codeText = formData.country === "CAN" ? "Postal Code" : "Zip Code";

  return (
    <div className="flex flex-row flex-wrap pb-2">
      <div className="w-full">
        <Text weight="extra-bold" className="mb-1 mt-4 text-sm">
          Address line 1*
        </Text>
        <Dropdown
          value={formData.line1 ? { label: formData.line1, value: formData.line1 } : null}
          placeholder="Address line 1"
          options={suggestions}
          onChange={handleSuggestionSelect}
          onInputChange={setValue}
          hideDropdownIndicator
          isCreatable
          isClearable
          error={errors.line1}
        />
      </div>
      <Label htmlFor="shipping-preference-line2" className="mb-1 mt-4">
        Apt., suite, unit number, etc. (optional)
      </Label>
      <Input
        value={formData.line2}
        onChange={handleInputChange("line2")}
        id="shipping-preference-line2"
        className="border-solid placeholder:text-[#B2B2B2]"
        placeholder="Apt., suite, unit number, etc."
      />
      <Label htmlFor="shipping-preference-city" className="mb-1 mt-4">
        City*
      </Label>
      <Input
        value={formData.city}
        onChange={handleInputChange("city")}
        id="shipping-preference-city"
        className="border-solid placeholder:text-[#B2B2B2]"
        placeholder="City"
        error={errors.city}
      />
      <div className="basis-4/6 pr-4">
        <Label htmlFor="shipping-preference-state" className="mb-1 mt-4">
          State*
        </Label>
        <Input
          value={formData.state}
          onChange={handleInputChange("state")}
          id="shipping-preference-state"
          className="border-solid placeholder:text-[#B2B2B2]"
          placeholder="State"
          error={errors.state}
        />
      </div>
      <div className="basis-2/6">
        <Label htmlFor="shipping-preference-zip" className="mb-1 mt-4">
          {codeText}*
        </Label>
        <Input
          value={formData.zipCode}
          onChange={handleInputChange("zipCode")}
          id="shipping-preference-zip"
          className="border-solid placeholder:text-[#B2B2B2]"
          placeholder={codeText}
          error={errors.zipCode}
        />
      </div>
      <div className="w-full">
        <Text weight="extra-bold" className="mb-1 mt-4 text-sm">
          Country*
        </Text>
        <Dropdown
          value={formData.country ? { label: formData.country, value: formData.country } : null}
          options={COUNTRY_OPTIONS}
          onChange={handleCountryChange}
          isClearable
          placeholder="Country"
          error={errors.country}
        />
      </div>
    </div>
  );
};
