import { cloneDeep, find, get, isArray } from "lodash";
import moment from "moment-timezone";

import { generateApprovalRule, LogicalOperator } from "@core/rule-builder";
import { convertToDollars, formatNumber, generateRandomString } from "@core/ui-legacy/utils";

export const ACTION_ENUMS = {
  setApprover: "setApprover",
  deprecatedSetSingleApprover: "setApproverToSpecificUser",
  autoApprove: "autoApprove",
};

export const INVERSE_OPERATOR = {
  eq: "neq",
  neq: "eq",
  gte: "lt",
  lte: "gt",
  gt: "lte",
  lt: "gte",
};

export const flipOperator = ({ operator, ...rest }) => ({
  ...rest,
  operator: INVERSE_OPERATOR[operator],
});

export const RECEIPT_VALUES = [
  {
    ID: "hasReceipts",
    name: "Attached",
  },
  {
    ID: "receiptAffidavit.receiptAffidavitAvailable",
    name: "Marked as missing",
  },
  {
    ID: "receipt",
    name: "Attached or marked as missing",
  },
];

export const isReceiptField = (property) => RECEIPT_VALUES.some(({ ID }) => ID === property);

export const parseIfJSON = (str) => {
  try {
    return JSON.parse(str);
  } catch {
    return str;
  }
};

export const transformActions = (actions) => {
  if (actions.length === 0) return actions;
  const values = actions.map((a) => ({
    ...a,
    valueType: "approver", // TODO Update enum on default approver story
  }));
  return [{ field: "approvers", fieldLabel: "Approvers", values }];
};

export const constructCondition = (condition = {}) => {
  const ID = condition.ID || generateRandomString("visibilityRule");
  const newCondition = {
    ID,
    operator: null,
    value: null,
    ...condition,
  };
  return newCondition;
};

export const constructAction = (action = {}) => {
  const ID = action.ID || generateRandomString("ruleAction");
  return { ID, ...action };
};

export const generateApprovalRuleDescription = (rule, metaData, parseRuleEvaluation) => {
  const processedRule = processRule(rule, metaData, constructCondition, constructAction);
  parseRuleEvaluation.actions = processedRule.actions.length
    ? transformActions(processedRule.actions)
    : [];
  parseRuleEvaluation.conditions = processedRule.conditions;

  const { description } = generateApprovalRule({
    ...parseRuleEvaluation,
    logicalOperator: parseRuleEvaluation.if,
    effectiveDateTime: rule.effectiveDateTime,
    type: processedRule.actions.length
      ? "expenses.approval.setApprover.custom"
      : "expenses.approval.autoApprove",
  });

  return description;
};

export const processRule = (
  rule,
  metaData,
  constructConditionFunc,
  constructActionFunc,
  boldValues,
) => {
  const fields = get(metaData, "fields", []);
  const context = get(metaData, "context", null);

  const getValueWithLabel = (property, value, valuesQuery, isBold) => {
    const isAmount = property === "amount";
    if (isAmount) {
      return {
        value,
        valueLabel: formatNumber({
          currencyCode: metaData.currencyCode,
          style: "currency",
          value: convertToDollars(value),
        }),
      };
    }
    if (isReceiptField(property)) {
      const receiptOpt = RECEIPT_VALUES.find((v) => v.ID === property);
      return {
        value: receiptOpt.ID,
        valueLabel: receiptOpt.name,
      };
    }

    if (property === "userID" && value?.name) {
      return {
        value: value?.ID,
        valueLabel: value?.name,
      };
    }

    if (property === "userID" && value?.length && value?.[0]?.name) {
      const usersIDs = [];
      const usersNames = [];
      value?.forEach((user) => {
        usersIDs.push(user.ID);
        usersNames.push(user.name);
      });
      return {
        value: usersIDs,
        valueLabel: usersNames.join(", "),
      };
    }

    if (!valuesQuery || !context || !value) {
      return {
        value,
        valueLabel: value,
      };
    }

    const updateValuesQuery = cloneDeep(valuesQuery);
    if (property === "expenseTypeID") {
      updateValuesQuery.queryDataField = "expenseTypes";
    }

    const { queryDataField, optionKey, optionValue } = updateValuesQuery;

    const list = get(context, queryDataField);
    const source = isArray(list) ? list : list?.items;

    if (!source) {
      return {
        value,
        valueLabel: value,
      };
    }

    const validValues = (isArray(value) ? value : [value])
      .map((v) => source.find((item) => item[optionKey] === v))
      .filter(Boolean);

    const validValue = validValues?.map((v) => v[optionKey]);

    return {
      value: isArray(value) ? validValue : validValue[0],
      valueLabel:
        validValues
          ?.map((v) => (isBold ? `<strong>${v[optionValue]}</strong>` : v[optionValue]))
          .join(", ") || "...",
    };
  };

  const conditions = [];
  const exclusions = [];
  const actions = [];
  const startDateTime = rule
    ? moment(rule.effectiveDateTime).startOf("day")
    : moment().startOf("day");
  const endDateTime = null;
  const logicalOperator = rule?.evaluation ? rule.evaluation.if : LogicalOperator.All;

  // TODO Consider making a param to process rule action operators to keep hook generic, perhaps with Auto-Approval story
  if (rule.ID && rule.evaluation) {
    rule.evaluation.actions.forEach((action) => {
      const { operator } = action;
      switch (operator) {
        case ACTION_ENUMS.deprecatedSetSingleApprover:
          actions.push(
            constructActionFunc({
              field: "currentApprover.user",
              fieldLabel: "Approver",
              value: action.approver.ID,
              valueLabel:
                action.approver.fullName ||
                `${action.approver.firstName} ${action.approver.lastName}`,
            }),
          );
          break;
        case ACTION_ENUMS.setApprover:
          // loop over approvers and create action for each
          action.approvers.forEach((approver) => {
            actions.push(
              constructActionFunc({
                field: "approvers",
                fieldLabel: "Approver",
                value: approver.ID,
                valueLabel: approver.fullName || `${approver.firstName} ${approver.lastName}`,
              }),
            );
          });
          break;
        case ACTION_ENUMS.autoApprove:
          break;
        default:
          console.warn(`Unsupported rule action.operator: ${operator}`);
      }
    });

    const allowableFields = new Set(fields.map(({ property }) => property));
    rule.evaluation.conditions
      .filter(({ property }) => isReceiptField(property) || allowableFields.has(property))
      .forEach((cond) => {
        const { property, value } = cond;
        const resolvedProperty = isReceiptField(property) ? "receipt" : property;
        const { name, valuesQuery } = find(fields, {
          property: resolvedProperty,
        });
        const valueWithLabel = getValueWithLabel(property, value, valuesQuery, boldValues);
        let field = constructConditionFunc({
          field: resolvedProperty,
          fieldLabel: name,
          operator: cond.operator,
          ...valueWithLabel,
        });
        if (cond.isExclusion) {
          field = flipOperator(field);
          exclusions.push(field);
        } else {
          conditions.push(field);
        }
      });
  }
  return {
    conditions,
    exclusions,
    actions,
    startDateTime,
    endDateTime,
    type: rule.type,
    logicalOperator,
  };
};
