/* eslint-disable react/jsx-props-no-spreading */
import { Component } from "react";
import { Avatar as AntAvatar } from "antd";
import PropTypes from "prop-types";
import styled from "styled-components";

import Colors from "../../themes/colors";
import { styleConstructor } from "../../utils";
import { getColorFromString } from "../../utils/getColorFromString";
import { sansProps } from "../../utils/styledSansProps";
import Badge from "../Badge";
import Ellipsis from "../Ellipsis";
import OverlineLabel from "../OverlineLabel";

const getInitials = (name) => {
  let result = "*";
  try {
    if (!name) return result; // filter out empty strings in the case a user accidentally adds an extra space
    // eslint-disable-next-line id-length
    const words = name.split(" ").filter((n) => n !== "");
    const len = words.length;
    result = words
      .map((nm) => nm[0])
      .join("")
      .slice(0, Math.max(0, len < 5 ? len : 1))
      .toUpperCase();
    return result;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error("Error generating Avatar initials:", { err, name });
    return result;
  }
};

const Wrapper = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
`;

const AvatarStyled = sansProps(styled, {
  bordered: true,
  styling: true,
  userColor: true,
})("span")`
  .ant-avatar {
    background-color: ${({ userColor }) => userColor}; 
    background: ${({ userColor }) => userColor};
    border: ${({ bordered }) => (bordered ? `1px solid ${Colors.white}` : "none")};
    color: ${Colors.white};
    font-size: 0.875rem;
    margin-right: 0.5rem;
    ${({ styling }) => styling && styleConstructor(styling)};
  }
`;

const InlineWrapper = styled.div`
  align-self: center;

  div {
    line-height: 1.3;
  }
`;

const AntAvatarStyled = styled(AntAvatar)`
  .ant-avatar-string {
    /* vertically center avatar */
    top: -0.0625rem;
  }
`;

const BadgeStyled = styled(Badge)`
  sup {
    margin: 0.313rem 0.625rem 0 0;
  }
`;

const Anchor = styled.button`
  background: none !important;
  color: ${Colors.link_blue};
  text-align: left;
  width: 100% !important;
  padding: 0 !important;
  font: inherit;
  border: 0;
  height: 1.3125rem;
  cursor: pointer;

  :focus {
    outline: none;
  }

  :hover {
    opacity: 0.8;
  }
`;

class Avatar extends Component {
  shouldComponentUpdate(nextProps) {
    const { props } = this;
    return (
      nextProps.label !== props.label ||
      nextProps.name !== props.name ||
      nextProps.header !== props.header ||
      nextProps.userID !== props.userID ||
      nextProps.userColor !== props.userColor ||
      nextProps.showBadge !== props.showBadge
    );
  }

  renderEllipsis = (item) => {
    const { ellipsis } = this.props;
    if (ellipsis) {
      return <Ellipsis {...ellipsis}>{item}</Ellipsis>;
    }
    return item;
  };

  renderLabel = () => {
    const { label, header, onClick, styleLabel, styleName, styleHeader, labelid } = this.props;
    const getSpan = (node) => (
      <InlineWrapper id={labelid}>
        <OverlineLabel.Text
          styling={{
            fontSize: "0.875rem",
            width: "100%",
            ...styleName,
          }}
        >
          {this.renderEllipsis(node)}
        </OverlineLabel.Text>
      </InlineWrapper>
    );

    // Make anchor link a pseudo button
    const anchor = <Anchor onClick={onClick}>{this.renderEllipsis(label)}</Anchor>;

    const overlineSpan = (
      <InlineWrapper id={labelid}>
        <OverlineLabel
          label={this.renderEllipsis(header)}
          text={this.renderEllipsis(label)}
          styleLabel={{
            fontSize: "1rem",
            fontWeight: 600,
            ...styleHeader,
          }}
          styleText={{
            fontSize: "0.75rem",
            fontWeight: 500,
            ...styleLabel,
          }}
        />
      </InlineWrapper>
    );

    const overlineAnchor = (
      <InlineWrapper id={labelid}>
        <OverlineLabel
          label={this.renderEllipsis(header)}
          text={anchor}
          styleLabel={{
            fontSize: "0.75rem",
            ...styleHeader,
          }}
          styleText={{
            fontSize: "0.625rem",
            ...styleLabel,
          }}
        />
      </InlineWrapper>
    );

    const result = () => {
      if (label && header && onClick) return overlineAnchor;
      if (label && header) return overlineSpan;
      if (label && onClick) return anchor;
      if (label) return getSpan(label);
      if (header) return getSpan(header);
      return null;
    };
    return result();
  };

  render() {
    const {
      name,
      label,
      header,
      userID,
      src,
      onClick,
      showInitials,
      showBadge,
      ellipsis,
      size,
      userColor,
      styleAvatar,
      styleLabel,
      styleName,
      styleHeader,
      ...otherProps
    } = this.props;
    let avatar = null;
    let avatarColor = null;

    if (userColor == null || userColor === undefined) {
      avatarColor = getColorFromString(userID);
    }

    if (src) {
      avatar = (
        <BadgeStyled dot={showBadge}>
          <AntAvatarStyled icon="user" src={src} size={size} {...otherProps} />
        </BadgeStyled>
      );
    } else if (showInitials) {
      avatar = (
        <BadgeStyled dot={showBadge}>
          <AntAvatarStyled size={size} {...otherProps}>
            {getInitials(name)}
          </AntAvatarStyled>
        </BadgeStyled>
      );
    }

    return (
      <Wrapper>
        <AvatarStyled styling={styleAvatar} bordered={!src} userColor={userColor || avatarColor}>
          {avatar}
        </AvatarStyled>
        {this.renderLabel()}
      </Wrapper>
    );
  }
}

Avatar.propTypes = {
  size: PropTypes.oneOfType([PropTypes.oneOf(["large", "default", "small"]), PropTypes.number]),
  type: PropTypes.string,
  src: PropTypes.any,
  name: PropTypes.string,
  label: PropTypes.node,
  header: PropTypes.node,
  userID: PropTypes.string,
  onClick: PropTypes.func,
  showInitials: PropTypes.bool,
  showBadge: PropTypes.bool,
  ellipsis: PropTypes.object,
  userColor: PropTypes.string,
  styleAvatar: PropTypes.object,
  styleLabel: PropTypes.object,
  styleName: PropTypes.object,
  styleHeader: PropTypes.object,
  labelid: PropTypes.string,
};

Avatar.defaultProps = {
  type: "circle",
  size: "default",
  label: null,
  showInitials: true,
  onClick: null,
};

export default Avatar;
