export const CONTAINER_ID = "notifications-form";
export const NOTIFICATION_SAVE_BTN = "notification-save-btn";
export const NOTIFICATION_CANCEL_BTN = "notification-cancel-btn";
export const NOTIFICATION_LOWCARD_CBX = "notification-lowcard-cbx";
export const NOTIFICATION_LOWCARD_RECIPIENTS = "notification-lowcard-recipients";
export const NOTIFICATION_LOWCARD_THRESHOLD = "notification-lowcard-threshold";
export const NOTIFICATION_LOWFUNDS_CBX = "notification-LOW_PROGRAM_FUNDS-cbx";
export const NOTIFICATION_LOWFUNDS_RECIPIENTS = "notification-LOW_PROGRAM_FUNDS-recipients";
export const NOTIFICATION_LOWFUNDS_THRESHOLD = "notification-LOW_PROGRAM_FUNDS-threshold";
export const NOTIFICATION_DECLINES_CBX = "notification-CARD_DECLINES-cbx";
export const NOTIFICATION_DECLINES_RECIPIENTS = "notification-CARD_DECLINES-recipients";
export const DASHBOARD_LAYOUT_ID = "dashboard_layout_id";

export const NOTIFICATION_TYPES = {
  LOW_CARD_BALANCE: "LOW_CARD_BALANCE",
  LOW_PROGRAM_FUNDS: "LOW_PROGRAM_FUNDS",
  CARD_DECLINES: "CARD_DECLINES",
  SUBMITTAL_REMINDERS: "SUBMITTAL_REMINDERS",
  APPROVAL_REMINDERS: "APPROVAL_REMINDERS",
  REIMBURSEMENTS: "REIMBURSEMENTS",
};

export const DayOfWeekEnum = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const FrequencyEnum = {
  weekly: "weekly",
  lastWeekOfTheMonth: "lastWeekOfTheMonth",
};

export const NOTIFICATION_SUB_REMINDERS_CBX = "notification-sub_reminders-cbx";
export const NOTIFICATION_SUB_REMINDERS_RECIPIENTS = "notification-sub_reminders-recipients";
export const NOTIFICATION_SUB_REMINDERS_DAYSOFWEEK = "notification-sub_reminders-daysOfWeek";
export const NOTIFICATION_SUB_REMINDERS_FREQUENCY_WEEKLY = `NOTIFICATION_${NOTIFICATION_TYPES.SUBMITTAL_REMINDERS}_FREQUENCY_${FrequencyEnum.weekly}`;

export const NOTIFICATION_APR_REMINDERS_CBX = "notification-apr_reminders-cbx";
export const NOTIFICATION_APR_REMINDERS_RECIPIENTS = "notification-apr_reminders-recipients";
export const NOTIFICATION_APR_REMINDERS_DAYSOFWEEK = "notification-apr_reminders-daysOfWeek";
export const NOTIFICATION_APR_REMINDERS_FREQUENCY = "notification-apr_reminders-frequency";
export const NOTIFICATION_APR_REMINDERS_FREQUENCY_LASTWEEK = `NOTIFICATION_${NOTIFICATION_TYPES.APPROVAL_REMINDERS}_FREQUENCY_${FrequencyEnum.lastWeekOfTheMonth}`;
