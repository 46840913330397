import { debounce } from "lodash";
import _forEach from "lodash/forEach";
import _forIn from "lodash/forIn";
import _get from "lodash/get";
import _isArray from "lodash/isArray";
import _map from "lodash/map";
import _merge from "lodash/merge";
import _orderBy from "lodash/orderBy";
import moment from "moment-timezone";

import { UserRoles } from "../common/roles";
import { MIME_TYPES, NESTED_FIELDS } from "./constants";
import { ExpenseStatuses } from "./expense";
import {
  EXPENSEHUB_EXPENSE_DETAILS,
  INSIGHTS_EXPENSE_DETAILS,
  MY_APPROVALS_EXPENSE_DETAILS,
  MYCARD,
  MYCARD_EXPENSE_DETAILS,
  MYCARD_EXPENSES,
  MYCARD_OVERVIEW,
  SPEND_EXPENSE_DETAILS,
  SPEND_EXPENSES,
} from "./routes";

export * from "./cards";

export const sortData = (data, keys, orders) => {
  if (keys && _isArray(data)) {
    return _orderBy(data, keys, orders);
  }
  return data;
};

// Eg: partitionData(items, {status: ['Pending', '* as Posted']})
// …: partitionData(COLLECTION, {ITEM.KEY to match: ARRAY_OF['VALUE of property to partition by', 'OLD_MATCH as NEW_MATCH, or default with *']})
export const partitionData = (data = [], keys = {}) => {
  const result = {};
  const matchKeys = {};
  // Helper function to init vars above
  const setProperty = (str, key) => {
    // Regex to determine if string has 'a as b' pattern
    const re = /^(.*)\sas\s(.*)/gi;
    // const re = /(?<matchString>.*)\sas\s(?<storeString>.*)/gi;
    const p = re.exec(str);
    if (p) {
      // Build minifier can't handle >ES6; adapting here
      const [, matchString, storeString] = p;
      // In this case, we init the result[b] and set the matchKeys as a=b to lookup the result key
      result[storeString] = [];
      matchKeys[key][matchString] = storeString;
    } else {
      // if (!p || (p && !p.groups)) {
      // If not a as b, just init the result[str] and set a key=str on matchKeys
      result[str] = [];
      matchKeys[key][str] = str;
    }
  };
  _forIn(keys, (vals, key) => {
    // Iterate through given keys to init result with structure with arrays to push items to
    matchKeys[key] = {};
    _map(vals, (val) => setProperty(val, key));
  });
  _map(data, (item) => {
    // Iterate through data, pushing matching items to appropriate result bucket
    _forIn(keys, (_, key) => {
      // This is a nested call N*M times, but keys will likely be a small set to find matching fields of a given item
      const itemKey = _get(item, key, null);
      if (itemKey) {
        const matchKeyLookup = _get(_get(matchKeys, key, {}), itemKey, null);
        if (matchKeyLookup) {
          result[matchKeys[key][itemKey]].push(item);
        } else if (matchKeys[key]["*"]) {
          result[matchKeys[key]["*"]].push(item);
        }
      }
    });
  });
  return result;
};
// SOURCE: https://github.com/kennethjiang/js-file-download
export const fileDownload = (data, fileName, mime) => {
  const blob = new Blob(Array.isArray(data) ? data : [data], {
    type: mime || "application/octet-stream",
  });
  if (window.navigator.msSaveBlob !== undefined) {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, fileName);
    return;
  }

  if (typeof window.URL?.createObjectURL !== "function") {
    console.warn("File download is not supported by the browser!");
    return;
  }

  const blobURL = window.URL.createObjectURL(blob);
  const tempLink = document.createElement("a");
  tempLink.style.display = "none";
  tempLink.href = blobURL;
  tempLink.setAttribute("download", fileName);
  // Safari thinks _blank anchor are pop ups. We only want to set _blank
  // target if the browser does not support the HTML5 download attribute.
  // This allows you to download files in desktop safari if pop up blocking
  // is enabled.
  if (tempLink.download === undefined) {
    tempLink.setAttribute("target", "_blank");
  }
  document.body.appendChild(tempLink);
  tempLink.click();
  tempLink.remove();
  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(blobURL);
  }, 100);
};
export const fileDownloadFromURL = (link, fileName) => {
  const tempLink = document.createElement("a");
  tempLink.style.display = "none";
  tempLink.href = link;
  tempLink.setAttribute("download", true);
  if (tempLink.download === undefined) {
    tempLink.setAttribute("target", "_blank");
  }
  document.body.appendChild(tempLink);
  tempLink.download = fileName;
  tempLink.click();
  tempLink.remove();
};

export const getFormattedDate = (date, defaultFormat = "dddd MMM D h:mm A") =>
  moment(date).format(defaultFormat);

export const getHumanizedDate = (date, defaultFormat = "dddd MMM D h:mm A", ignoreTime = false) => {
  const now = moment();
  const mDate = moment(date);
  const elapsed = moment.duration(mDate.diff(now));
  const diffHrs = mDate.diff(now, "hours");
  const within24Hrs = Math.abs(diffHrs) < 24; // 24 hrs
  const calVars = {
    sameDay: "[Today]",
    nextDay: "[Tomorrow]",
    lastDay: "[Yesterday]",
    sameElse: defaultFormat,
  };
  const humanTime =
    within24Hrs && !ignoreTime ? elapsed.humanize(true) : mDate.calendar(null, calVars);
  return humanTime;
};

/* Takes a string template and populates values.
   Supports optional functions to transform values.
   Returns a string unless a supplied function creates a non-string element (eg JSX node), in which case a series of nodes is returned
*/
export const constructLabelTemplate = (string, vars, transformFuncs = {}) => {
  if (!string || !vars) {
    console.error("Cannot construct string:", { string, vars });
    return "";
  }
  let hasNonStringElement = false;
  const re = /\$\{(.+?)\}/g; // Finds all template variables to replace (eg: ${var})
  const varsInString = string.match(re) ? string.match(re).map((v) => v.slice(2, -1)) : []; // Array of keys to loop through
  let constructedString = string;
  const labelElements = string.split(/(\${\b})|(\b)/);
  // Helper funcs
  const isString = (v) => typeof v === "string";
  // For string elements, we strip out [${}] chars
  const processStringElement = (e) => {
    const s = e.replace("${", "").replace("}", "");
    return `${s}`;
  };
  // Process all keys in string template
  _forEach(varsInString, (key) => {
    const val = vars[key];
    const element = transformFuncs[key] ? transformFuncs[key](val, vars) : val;
    let elementIdx = labelElements.indexOf(key);
    if (elementIdx > 0 && labelElements[elementIdx - 1] === "${") elementIdx = -1;
    if (isString(element) && !hasNonStringElement) {
      constructedString = constructedString.replace(`\${${key}}`, element);
    } else {
      hasNonStringElement = true;
    }
    if (elementIdx >= 0) labelElements[elementIdx] = element;
  });
  // Returns either a string or series of nodes
  return hasNonStringElement
    ? labelElements.map((e) => (isString(e) ? processStringElement(e) : e))
    : constructedString;
};

/* Returns a moment array of two moment dates. Default params return start/end times of 1 month prior through 23:59:59 today.
 */
export const getDefaultDateRange = (
  startOn = moment().startOf("day"),
  // Init offset interval to -1 month
  offset = { int: -1, unit: "month" },
) => {
  const startDate = moment(startOn).add(offset.int, offset.unit);
  const endDate = moment(startOn).endOf("day");
  const range = [startDate, endDate];
  // eg return [moment(start of today -1 month), moment(end of today)]
  return range;
};

export const getFormattedDateRange = (dateRange) => {
  const [startDate, endDate] = dateRange;
  return { from: startDate.format(), to: endDate.format() };
};

export const browserDetection = () => {
  const isGoogleBot = navigator.userAgent.toLowerCase().includes("googlebot");

  const isIE = /* @cc_on!@ */ false || !!document.documentMode;
  const isEdge = !isIE && !!window.StyleMedia;
  const isFirefox = typeof InstallTrigger !== "undefined";
  const isOpera =
    (!!window.opr && !!window.opr.addons) ||
    !!window.opera ||
    navigator.userAgent.includes(" OPR/");
  const isChrome =
    !isGoogleBot &&
    !isEdge &&
    !isOpera &&
    !!window.chrome &&
    (!!window.chrome.webstore || navigator.vendor.toLowerCase().includes("google inc."));
  const isSafari = !isChrome && navigator.userAgent.toLowerCase().includes("safari");
  const isBlink = (isChrome || isOpera) && !!window.CSS;
  let browser;

  if (isIE) {
    browser = "IE";
  } else if (isEdge) {
    browser = "Edge";
  } else if (isFirefox) {
    browser = "Firefox";
  } else if (isOpera) {
    browser = "Opera";
  } else if (isChrome) {
    browser = "Chrome";
  } else if (isSafari) {
    browser = "Safari";
  } else if (isBlink) {
    browser = "Blink";
  } else if (isGoogleBot) {
    browser = "Googlebot";
  } else {
    browser = "Unknown";
  }
  return browser;
};

export const paginate = (array, pageNumber, pageSize) =>
  array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);

/**
 * Turns an array of objects into a string
 * @param array
 * @returns {*}
 */
export const stringifyObjectArrayByProperty = (array, objectProperty, separator) => {
  separator = separator || ":";
  return array.reduce(
    (accumulator, currentValue) =>
      accumulator
        ? `${accumulator}${separator}${currentValue[objectProperty]}`
        : `${currentValue[objectProperty]}`,
    "",
  );
};

export const getIsPDF = (mime) => mime === MIME_TYPES.pdf;

export const shouldUseDelegateContext = (path) =>
  path === MYCARD ||
  path === MYCARD_OVERVIEW ||
  path === MYCARD_EXPENSES ||
  path === MYCARD_EXPENSE_DETAILS ||
  path === SPEND_EXPENSES ||
  path === SPEND_EXPENSE_DETAILS;

export const hasValue = (v) => v !== null && v !== undefined && v !== "";

export const getApprovalDateFromEvents = (eventsArray, approvalStatus) =>
  (eventsArray || []).reduce((latestApprovedDate, currEvent) => {
    const eventValue = JSON.parse(currEvent.values);
    const approvers = _get(eventValue, `approvers`, []);
    const status = _get(eventValue, "status", "");

    if (approvalStatus === ExpenseStatuses.Rejected && currEvent.type === "reject")
      return currEvent.timeStamp;
    if (
      currEvent.type === "update" &&
      (status === ExpenseStatuses.Approved ||
        status === ExpenseStatuses.Rejected ||
        status === ExpenseStatuses.ReadyToPost) &&
      approvers.length
    ) {
      return approvers.reduce((acc, curr) => {
        if (curr.status === approvalStatus) {
          // Accumulate the latest rejected date time but only return the latest approved dateTime
          return curr.dateTime;
        }
        if (approvalStatus !== ExpenseStatuses.Rejected && curr.status !== approvalStatus && acc) {
          return "";
        }
        return acc;
      }, "");
    }
    return latestApprovedDate;
  }, "");

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * Denormalize a string to an object
 * @param val any
 * @param path string
 * @returns {object}
 */
export const objectify = (val, path) => {
  const arr = path.split(".").reverse();
  const obj = arr.reduce((acc, current, currentIndex) => {
    if (currentIndex === 0) {
      return { [current]: val };
    }
    return { [current]: acc };
  }, {});
  return obj;
};

/**
 * @param {string}  fieldName - field name from a form
 * @return {number | string} - newValue, provided by the input component
 * @return {object} expenseData - current expense state
 */
export const getNestedCurrencyValues = (fieldName, newValue, expenseData) => {
  const ccyCode = _get(
    expenseData,
    "fields.forex.originatingCurrency.name",
    expenseData.currencyCode,
  );
  const amountRaw = _get(expenseData, "fields.forex.originatingCurrency.amountRaw", 0);
  const existingValues = {
    forex: {
      originatingCurrency: {
        amountRaw: amountRaw || 0,
        name: ccyCode,
        __typename: "CurrencyField",
      },
      __typename: "ForexField",
    },
    __typename: "Fields",
  };
  const newValues = objectify(newValue, fieldName);
  return _merge({ fields: existingValues }, newValues);
};

/**
 * @param {string}  fieldName - field name from a form
 * @return {boolean} - newValue, provided by the input component
 * @return {object} expenseData - current expense state
 */
export const getNestedTravelValues = (fieldName, newValue, expenseData) => {
  // TODO: add traveler and tripID when functional

  const isTravelRelated = _get(expenseData, "fields.travel.isTravelRelated", false);

  const existingValues = {
    travel: {
      isTravelRelated,
      __typename: "TravelField",
    },
    __typename: "Fields",
  };
  const newValues = objectify(newValue, fieldName);
  return _merge({ fields: existingValues }, newValues);
};

/**
 * @param {string}  - field name from a form
 * @return {boolean} - determines if field is nested
 * @example
 *     isNestedCurrencyField('fields.forex.originatingCurrency.amount')
 */
export const isNestedField = (fieldNameToCheck) => {
  if (!fieldNameToCheck) return false;
  const isNested = (listItem) => fieldNameToCheck.includes(listItem);
  return NESTED_FIELDS.some(isNested);
};

export const definePriorityRole = (match) => {
  // dictionary to get priority role by route
  const priorityRoles = {
    [EXPENSEHUB_EXPENSE_DETAILS]: UserRoles.FM,
    [MY_APPROVALS_EXPENSE_DETAILS]: UserRoles.EA,
    [MYCARD_EXPENSE_DETAILS]: UserRoles.SP,
    [SPEND_EXPENSE_DETAILS]: UserRoles.SP,
    [INSIGHTS_EXPENSE_DETAILS]: UserRoles.IV,
  };

  const { path } = match;
  return priorityRoles[path];
};

// Returns the subdomain of a url
// https://foo.bar.com =>  foo
export const getSubdomainFromUrl = () => window.location.hostname.split(".")[0];

const THEME_CONFIG_BLACK_LIST = new Set(["my", "stage"]);
export const isThemeConfigBlockListSubDomain = () =>
  THEME_CONFIG_BLACK_LIST.has(getSubdomainFromUrl());

export const parsePhone = (value) => value?.replace(/\(|\)|_|\+|\s|-/g, "");
export const formatPhone = (value) => {
  let val = Array.from({ length: 10 }).fill("_");
  val = val.map((item, i) => value?.[i] || item).join("");
  return `(${val.slice(0, 3)}) ${val.slice(3, 6)} ${val.slice(6, 10)}`;
};

export const isValidUUID = (ID) =>
  /^([0-9a-fA-F]{8})-(([0-9a-fA-F]{4}-){3})([0-9a-fA-F]{12})$/i.test(ID);

export const debounceReduce = (func, wait) => {
  let allArgs = [];
  const resolveSet = new Set();
  const rejectSet = new Set();

  const wrapper = debounce(() => {
    func(allArgs)
      .then((...result) => {
        resolveSet.forEach((resolve) => resolve(...result));
        resolveSet.clear();
      })
      .catch((...result) => {
        rejectSet.forEach((reject) => reject(...result));
        rejectSet.clear();
      });

    allArgs = [];
  }, wait);

  return (...args) =>
    new Promise((resolve, reject) => {
      allArgs.push(args);
      resolveSet.add(resolve);
      rejectSet.add(reject);

      wrapper();
    });
};

/**
 * This is a copy of isValidEmail from @core/common. See that for detailed documentation.
 * FIXME: Delete this and just use @core/common as soon as it compiles properly here.
 */
export const isValidEmail = (email) => {
  if (!email) {
    return false;
  }

  // See details in @core/common version. The format of an email address is local-part@domain, where the
  // local part may be up to 64 octets long and the domain may have a maximum of 255 octets.

  try {
    const emailParts = email.split("@");
    if (emailParts.length !== 2) {
      return false;
    }

    const [localPart, domainPart] = emailParts;

    if (!localPart || localPart.length > 64) {
      return false;
    }
    if (!domainPart || domainPart.length > 255) {
      return false;
    }

    const domainSegments = domainPart.split(".");
    if (!domainSegments || domainSegments.some((part) => !part || part.length > 63)) {
      return false;
    }
  } catch {
    // The only reason this should happen is if some legacy code passed in an `any` value that isn't a string
    // Since it's not a string, it also can't be an email address.
    return false;
  }

  // Regex from email-validator; see the links in method docstring for its inspiration.
  // It isn't complete, but passes every address we've seen in use so far.
  return /^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/.test(
    email,
  );
};
