import "./typedef";

import { createContext, useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import { CENTER_BRANDING_DEFAULTS } from "./centerBrandingDefaults";
import { organizationBrandingGetBySubdomain } from "./services/rest/developerAPI";
import { isThemeConfigBlockListSubDomain } from "./utils";

/**
 * @type {AppBrandingContextValue}
 */
const defaultContextValue = {
  appBrandingLogo: "",
  navLinks: CENTER_BRANDING_DEFAULTS.navLinks,
};

export const AppBrandingContext = createContext(defaultContextValue);

/**
 * Set CSS properties
 * @typedef CSSProperty
 * @type {object}
 * @property {string} cssProperty - css property to update
 * @property {string} value - new value for the property
 * @param {CSSProperty[]} cssProperties
 */
const propertySetter = (cssProperties) => {
  const root = document.querySelector(":root");
  cssProperties.forEach(({ cssProperty, value }) => root.style.setProperty(cssProperty, value));
};

/**
 * Maps org branding data to CSSProperty
 * @typedef CSSProperty
 * @type {object}
 * @param {object} [data]
 * @returns {CSSProperty[]}
 */
const cssPropertyBrandingMapper = (data) => [
  {
    cssProperty: "--cen-colors-primary-500",
    value: data?.primaryColor || CENTER_BRANDING_DEFAULTS.primaryColor,
  },
  {
    cssProperty: "--cen-colors-secondary-500",
    value: data?.secondaryColor || CENTER_BRANDING_DEFAULTS.secondaryColor,
  },
  {
    cssProperty: "--cen-colors-link-500",
    value: data?.linkColor || CENTER_BRANDING_DEFAULTS.linkColor,
  },
  {
    cssProperty: "--cen-colors-text-500",
    value: data?.textColor || CENTER_BRANDING_DEFAULTS.textColor,
  },
  {
    cssProperty: "--cen-colors-navBackground-500",
    value: data?.navigationBarColors?.backgroundColor || CENTER_BRANDING_DEFAULTS.backgroundColor,
  },
  {
    cssProperty: "--cen-colors-navActive-500",
    value: data?.navigationBarColors?.activeColor || CENTER_BRANDING_DEFAULTS.activeColor,
  },
  {
    cssProperty: "--cen-colors-navText-500",
    value: data?.navigationBarColors?.textElementColor || CENTER_BRANDING_DEFAULTS.textElementColor,
  },
  {
    cssProperty: "--cen-font-family",
    value: `"${data?.font?.name || CENTER_BRANDING_DEFAULTS.mainFont}"`,
  },
];

/**
 * @typedef NavLinks
 * @type {object}
 * @property {string} name
 * @property {string} url
 * @property {('INTERNAL' | 'EXTERNAL')} navLinkType
 * @property {('SELF' | 'BLANK')} target
 *
 * Map BE navlinks to FE
 * @param {object} [data] - data from BE
 * @param {NavLinks[]} [data.navLinks] - data from BE
 * @returns {OrderedMenuLink | undefined}
 */
const navLinksMapper = (data) =>
  data?.navLinks?.map((link) => ({
    name: link.name,
    path: link.url,
    type: link.navLinkType,
    target: link.target,
  }));

/**
 * Sets the html DOC properties based on branding
 * @param {object} [data] - BE data
 * @param {string} [data.pageTitle] - tab title
 * @param {string} [data.favicon]
 */
const setDocProperties = (data) => {
  document.title = data?.pageTitle || "Center Expense";
  document.querySelector("link[rel='shortcut icon']").href =
    data?.favicon || `${window.location.origin}/favicon.ico`;
};

export const AppBranding = ({ children }) => {
  const [brandingOrgId, setBrandingOrgId] = useState("");
  const [mainFont, setMainFont] = useState("");
  const [appBrandingLogo, setAppBrandingLogo] = useState("");
  const [appBrandingFavicon, setAppBrandingFavicon] = useState("");
  const [lastChangedDate, setLastChangedDate] = useState("");
  const [logoLinkAddress, setLogoLinkAddress] = useState("");
  const [lastUpdatedBy, setLastUpdatedBy] = useState("");
  const [fetched, setFetched] = useState(false);
  /**
   * @type {[OrderedMenuLink[] | undefined, function(OrderedMenuLink[] | undefined | function(OrderedMenuLink[] | undefined): OrderedMenuLink[] | undefined):void]}
   */
  const [navLinks, setNavLinks] = useState(CENTER_BRANDING_DEFAULTS.navLinks);

  const updateBranding = useCallback((data) => {
    propertySetter(cssPropertyBrandingMapper(data));
    setAppBrandingLogo(data?.logo ?? "");
    setAppBrandingFavicon(data?.favicon ?? "");
    if (data.logoLinkAddress) setLogoLinkAddress(data.logoLinkAddress);
    setNavLinks(navLinksMapper(data) ?? CENTER_BRANDING_DEFAULTS.navLinks);
    setDocProperties(data);
    setLastChangedDate(data.updatedAt || data.createdAt);
    setLastUpdatedBy(data.updatedBy || data.createdBy);
    setMainFont(data.font?.name || CENTER_BRANDING_DEFAULTS.mainFont);
    if (data.font?.url) {
      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = data.font.url;
      document.head.appendChild(link);
    }
  }, []);

  const setBranding = useCallback(
    (data) => {
      updateBranding(data);
      setFetched(true);
    },
    [updateBranding],
  );

  const fetchOrgBySubdomain = useCallback(async () => {
    let data = {};
    try {
      data = await organizationBrandingGetBySubdomain();
      if (data.id) {
        setBrandingOrgId(data.id);
      }
    } catch (error) {
      window.console.warn(error);
    } finally {
      setBranding(data);
    }
  }, [setBranding]);

  useEffect(() => {
    if (isThemeConfigBlockListSubDomain()) {
      setBranding({});
      return;
    }
    fetchOrgBySubdomain();
  }, [fetchOrgBySubdomain, setBranding]);

  /**
   * @type {AppBrandingContextValue}
   */
  const value = useMemo(
    () => ({
      appBrandingLogo,
      navLinks,
      logoLinkAddress,
      updateBranding,
      lastChangedDate,
      brandingOrgId,
      lastUpdatedBy,
      appBrandingFavicon,
      mainFont,
    }),
    [
      appBrandingLogo,
      navLinks,
      logoLinkAddress,
      updateBranding,
      lastChangedDate,
      lastUpdatedBy,
      appBrandingFavicon,
      mainFont,
      brandingOrgId,
    ],
  );

  return (
    <AppBrandingContext.Provider value={value}>{fetched && children}</AppBrandingContext.Provider>
  );
};

AppBranding.propTypes = {
  children: PropTypes.node.isRequired,
};
