import { useMutation, type QueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import { toast } from "sonner";

import { Icon } from "@core/ui";

import type { PeriodDataSegment } from "../../services/rest/travelInsights/travelInsights";
import { getViewQueryKey, updateView } from "../../services/rest/views/api";
import type { View, ViewFilter } from "../../services/rest/views/views";
import type { SelectedFilter } from "./components/FilterOptions";

const createDateRange = (unit: "day" | "month", value: number) => {
  const today = dayjs();
  return {
    from: today.subtract(value, unit).toDate(),
    to: today.toDate(),
  };
};

export const getDateRanges = () => {
  return [
    {
      label: "Last 7 days",
      values: createDateRange("day", 7),
    },
    {
      label: "Last 30 days",
      values: createDateRange("day", 30),
    },
    {
      label: "Last 3 months",
      values: createDateRange("month", 3),
    },
    {
      label: "Last 6 months",
      values: createDateRange("month", 6),
    },
    {
      label: "Last 12 months",
      values: createDateRange("month", 12),
    },
  ];
};

export const toastStyle = {
  className:
    "bg-neutrals-50 text-[#18181B] h-[3.5rem] border border-neutrals-200 shadow-lg rounded-lg p-4 flex items-center",
  action: {
    label: "X",
    onClick: () => toast.dismiss(),
  },
  classNames: {
    toast: "bg-neutrals-50",
    title: "text-[#18181B]",
    actionButton: "ml-auto bg-transparent size-[1.5rem] text-[#6C7580] hover:text-gray-900",
  },
};

export const showSuccessToast = (message: string) => {
  toast.success(message, {
    icon: <Icon name="Check" size="small" color="text-50" background="secondary-400" />,
    ...toastStyle,
  });
};

export const mapSelectedFilters = (filters: SelectedFilter[]) => {
  return filters.map(({ property, selectedValues: values, name, ID }) => ({
    property,
    values,
    name,
    ID,
  }));
};

type HandleSuccessProps = {
  errorMessage: string;
  orgId: string;
  queryClient: QueryClient;
  successMessage: string;
  userId: string;
};

type UpdateViewMutationProps = {
  idView: string;
  view: View;
};

export const handleSuccess = async (args: HandleSuccessProps) => {
  const { queryClient, successMessage, errorMessage, orgId, userId } = args;
  try {
    await queryClient.invalidateQueries(getViewQueryKey({ orgId, userId }));
    showSuccessToast(successMessage);
  } catch {
    toast.error(errorMessage);
  }
};

export const useUpdateViewMutation = (args: HandleSuccessProps) => {
  return useMutation({
    mutationFn: ({ idView, view }: UpdateViewMutationProps) => updateView(idView, view),
    onSuccess: async () => {
      await handleSuccess(args);
    },
    onError: () => {
      toast.error(args.errorMessage);
    },
  });
};

export const compareFilters = (
  defaultFilters: ViewFilter[],
  currentFilters: SelectedFilter[],
): boolean => {
  if (defaultFilters.length !== currentFilters.length) return true;
  return defaultFilters.some((filter) => {
    const currentFilter = currentFilters.find((currFilter) => currFilter.ID === filter.ID);
    if (!currentFilter) return true;
    return filter.values.sort().join(",") !== currentFilter.selectedValues.sort().join(",");
  });
};

export const mapFilters = (filters: ViewFilter[]): SelectedFilter[] => {
  return filters.map((filter) => ({
    ...filter,
    selectedValues: filter.values || [],
    ID: filter.ID,
    name: filter.name,
  })) as SelectedFilter[];
};

export const mapSegmentData = (data: Record<string, PeriodDataSegment>) => {
  return Object.entries(data).map(([key, value]) => ({
    name: key,
    ...value,
    bgColor: value.bgColor ?? undefined,
    logo: value.logo ?? undefined,
  }));
};

const normalizeDate = (date: Date): string => {
  return dayjs(date).format("YYYY-MM-DD");
};

export const findMatchingDateRange = (startDate: string, endDate: string): string => {
  const dateRangeOptions = getDateRanges();
  const normalizedStartDate = normalizeDate(new Date(startDate));
  const normalizedEndDate = normalizeDate(new Date(endDate));

  const matchingOption = dateRangeOptions.find(
    (option) =>
      normalizeDate(option.values.from) === normalizedStartDate &&
      normalizeDate(option.values.to) === normalizedEndDate,
  );

  if (matchingOption) {
    return matchingOption.label;
  }

  const formattedDateRange = `${dayjs(new Date(startDate)).format("MMM DD")} - ${dayjs(
    new Date(endDate),
  ).format("MMM DD")}`;
  return formattedDateRange;
};
