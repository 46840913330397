import { useContext, useState } from "react";

import { Button, Modal, Text } from "@core/ui";

import { ReactComponent as MailIcon } from "../../../assets/imgs/mail-open.svg";
import { CardExpirationContext } from "../../../common/contexts/CardExpirationProvider";
import { useExpirationTimeline } from "../useExpirationTimeline";

export const CardPossessionConfirmationModal = () => {
  const { setShowThanksModal, setShowAddressConfirmationModal, expirationYYMM, hasPhysicalCards } =
    useContext(CardExpirationContext);
  const isCardPossessionConfirmed = localStorage.getItem("ui-did-receive-card");

  const { isWithinTimeline } = useExpirationTimeline({
    expirationYYMM,
    type: "ui-did-receive-card",
    startDaysDefault: 10,
    endDaysDefault: 0,
    cardVersion: "both",
  });

  const [showModal, setShowModal] = useState(
    Boolean(isWithinTimeline && isCardPossessionConfirmed !== "true" && hasPhysicalCards),
  );

  const handleYes = () => {
    setShowModal(false);
    setShowThanksModal("show");
    localStorage.setItem("ui-did-receive-card", "true");
  };

  const handleNo = () => {
    setShowModal(false);
    setShowAddressConfirmationModal(true);
  };

  return (
    <Modal
      open={showModal}
      leadingIcon={<MailIcon />}
      iconPosition="center"
      closeModal={() => {}} // User needs to take action
      actionButtons={[
        <Button key="No" variant="secondary" text="No, not yet" size="large" onClick={handleNo} />,
        <Button key="Yes" text="Yes, I have" size="large" onClick={handleYes} />,
      ]}
      className="min-h-[19.125rem] min-w-[36.85rem] auto-rows-min content-end gap-4"
    >
      <div className="mb-[3rem] flex justify-center">
        <Text variant="h2" weight="bold">
          Did you receive your replacement cards?
        </Text>
      </div>
    </Modal>
  );
};
