import { identity } from "lodash";

import { Badge, Text, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@core/ui";
import { convertToDollars } from "@core/ui-legacy/utils";

import AlertIcon from "../../assets/imgs/alert_circle_fill.svg";
import InfoIcon from "../../assets/imgs/info-blue.svg";
import { cardStatusCodesEnums, getCardStatus, getStatusColor } from "../../utils";
import { BalanceCellStyled, CurrencyStyled, Status } from "./styledComponents";
import type { BalanceStyledProps } from "./types";

const BalanceStyled = ({ card }: BalanceStyledProps) => {
  const {
    balance,
    isProvisioning,
    isPendingClose,
    cardBehavior,
    statusCode,
    isAdminLocked,
    isActive,
  } = card;

  return (
    <BalanceCellStyled>
      <CurrencyStyled
        {...{
          value: convertToDollars(balance),
          transform: identity,
          decimalPrecision: 2,
          "data-testid": "card-balance",
          $isCardProvisioning: isProvisioning,
        }}
      />
      <div className="flex items-start justify-end gap-1">
        {isPendingClose ? (
          <>
            {cardBehavior === "singleTransaction" && (
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <img src={AlertIcon} alt="info-icon" className="size-[1.125rem]" />
                  </TooltipTrigger>
                  <TooltipContent align="center" className="px-4 py-2">
                    <Text variant="subtext">
                      This One-Time Virtual Card has been used and will close after the transaction
                      settles.
                    </Text>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            )}
            <Badge variant="neutral" text="PENDING CLOSURE" />
          </>
        ) : (
          <>
            {cardBehavior === "singleTransaction" && (
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <img src={InfoIcon} alt="info-icon" className="size-[1.125rem]" />
                  </TooltipTrigger>
                  <TooltipContent align="center" className="px-4 py-2">
                    <Text variant="subtext">
                      This One-Time Virtual Card will close after a single transaction.
                    </Text>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            )}
            <Status color={getStatusColor(statusCode)} data-testid="card-status">
              {getCardStatus(statusCode, isAdminLocked)}
            </Status>
          </>
        )}
        {statusCode === cardStatusCodesEnums.Inactive && isActive === false && (
          <Status color={getStatusColor(cardStatusCodesEnums.IssuedInactive)}>
            {getCardStatus(cardStatusCodesEnums.IssuedInactive)}
          </Status>
        )}
      </div>
    </BalanceCellStyled>
  );
};

export default BalanceStyled;
