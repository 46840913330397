import PropTypes from "prop-types";
import styled from "styled-components";

import { Button } from "@core/ui-legacy";

const ModalDescription = styled.div`
  padding-bottom: 3.25rem;
  max-width: 89%;
`;

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 1.625rem;
  padding-bottom: 1.5rem;

  button:not(:last-child) {
    margin-right: 0.5rem;
  }

  button {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
`;
const FraudInfo = ({ onCancel }) => (
  <>
    <div className="ant-modal-header">
      <div className="ant-modal-title">Enable fraud alerts</div>
    </div>
    <ModalDescription>
      Almost there! To finalize your enrollment in fraud alerts, text <b>“IN”</b> to <b>57911</b>{" "}
      from the phone number that you entered.
    </ModalDescription>
    <ActionsWrapper>
      <Button
        id="finish-dialog-btn"
        key="finish-dialog-btn"
        data-testid="finish-dialog-btn"
        type="primary"
        size="default"
        htmlType="submit"
        onClick={onCancel}
      >
        Finish
      </Button>
    </ActionsWrapper>
  </>
);

FraudInfo.propTypes = {
  onCancel: PropTypes.func,
};

export default FraudInfo;
