import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import _get from "lodash/get";
import PropTypes from "prop-types";
import { Mutation, Query } from "react-apollo";
import styled from "styled-components";

import { Badge } from "@core/ui-legacy";
import Colors from "@core/ui-legacy/themes/colors";

import { ReactComponent as BellImage } from "../../assets/imgs/bell.svg";
import AuthUserContext from "../../common/contexts/AuthUserContext";
import { withShowAppNotificationsPanelFlag } from "../../services/graphql/hoc";
import { TOGGLE_SHOW_APP_NOTIFICATIONS_PANEL } from "../../services/graphql/mutations";
import { GET_APP_NOTIFICATIONS_COUNT } from "../../services/graphql/queries";
import {
  getEmployeesToDeactivate,
  getEmployeesToDeactivateQueryKey,
  getEmployeesToOnboard,
  getEmployeesToOnboardQueryKey,
  useHasHRIS,
} from "../../services/rest/hris";
import { pollInterval } from "../../utils/appNotifications";
import { Desktop, Mobile } from "../Responsive";
import SimpleButton from "../SimpleButton";

const StyledBadge = styled(Badge)`
  sup {
    height: 0.75rem;
    width: 0.75rem;
    left: 0.375rem;
    top: 0.25rem;
    border: 2px solid ${Colors.primary};
  }
`;

const BellButton = styled.button`
  background-color: transparent;
  border: 0;
  margin-right: 1rem;
  cursor: pointer;
  padding: 0;
  font-size: 1.2rem;

  :focus {
    outline: none;
  }

  & g {
    stroke: var(--cen-colors-navText-500);
  }
`;

// isMobile menu
const InboxButton = styled(SimpleButton)`
  background-color: transparent;
  display: inherit;
  border: 0;
  cursor: pointer;
  padding: 0;
  font-size: 1.2rem;

  :focus {
    outline: none;
  }

  text-align: left;
  width: 100%;
`;
const BadgeStyled = styled(Badge)`
  float: right !important;
`;

const AppNotificationsIndicator = (props) => {
  const { isAppNotificationsPanelVisible, onClick } = props;

  const authUser = useContext(AuthUserContext);
  const orgId = authUser.organization.ID;
  const hasHRIS = useHasHRIS();
  const { data: empToOnboard } = useQuery({
    queryKey: getEmployeesToOnboardQueryKey({ orgId }),
    enabled: hasHRIS,
    queryFn: getEmployeesToOnboard,
  });

  const { data: empToDeactivate } = useQuery({
    queryKey: getEmployeesToDeactivateQueryKey({ orgId }),
    enabled: hasHRIS,
    queryFn: getEmployeesToDeactivate,
  });

  const empToOnboardCount = empToOnboard?.length ?? 0;
  const empToDeactivateCount = empToDeactivate?.length ?? 0;
  const hrisCount = empToOnboardCount + empToDeactivateCount;
  const hasHRISActions = Boolean(hrisCount);

  const handleToggleAppNotifications = (setShowAppNotificationsPanel, onlyHRIS) => {
    setShowAppNotificationsPanel({
      variables: { visible: !isAppNotificationsPanelVisible, onlyHRIS },
    });
    if (onClick) onClick();
  };

  const renderWithTogglePanelMutation = (totalCount, showDot, onlyHRIS) => (
    <Mutation mutation={TOGGLE_SHOW_APP_NOTIFICATIONS_PANEL} ignoreResults>
      {(setShowAppNotificationsPanel) => (
        <>
          <Desktop>
            <BellButton
              id="app-notifications-indicator"
              onClick={() => handleToggleAppNotifications(setShowAppNotificationsPanel, onlyHRIS)}
            >
              <StyledBadge count={totalCount} dot={showDot}>
                <BellImage role="img" aria-label="Toggle inbox panel icon" />
              </StyledBadge>
            </BellButton>
          </Desktop>
          <Mobile>
            <InboxButton
              onClick={() => handleToggleAppNotifications(setShowAppNotificationsPanel, onlyHRIS)}
            >
              Inbox
              <BadgeStyled count={totalCount} dot={showDot} offset={showDot ? [0, 16] : [0, 10]} />
            </InboxButton>
          </Mobile>
        </>
      )}
    </Mutation>
  );

  return (
    <Query
      query={GET_APP_NOTIFICATIONS_COUNT}
      notifyOnNetworkStatusChange
      fetchPolicy="network-only"
      pollInterval={isAppNotificationsPanelVisible ? 0 : pollInterval}
      // 0 == stop polling
    >
      {({ data = {}, error }) => {
        if (error) {
          console.error(error.message);
        }
        const alertsCount = _get(data, "alerts.alertsCount", 0);
        const actionsCount = _get(data, "alerts.actionsCount", 0);

        const totalCount = (alertsCount + actionsCount || 0) + hrisCount;

        const showDot = hasHRISActions || (alertsCount > 0 && actionsCount === 0);
        const onlyHRIS = hrisCount === totalCount;
        return renderWithTogglePanelMutation(totalCount, showDot, onlyHRIS);
      }}
    </Query>
  );
};

AppNotificationsIndicator.propTypes = {
  isAppNotificationsPanelVisible: PropTypes.bool,
  onClick: PropTypes.func,
};

export default withShowAppNotificationsPanelFlag(AppNotificationsIndicator);
