import type { ReactElement, ReactNode } from "react";
import { cva, type VariantProps } from "class-variance-authority";

import { Button } from "../Button";
import { Text } from "../Text";
import { Dialog, DialogClose, DialogContent } from "./dialog";
import { StepIndicator } from "./StepIndicator";

const modalVariants = cva(null, {
  variants: {
    iconPosition: {
      start: "mr-auto",
      center: "mx-auto",
      end: "ml-auto",
    },
  },
  defaultVariants: {
    iconPosition: "start",
  },
});

export type ModalProps = {
  closeModal: () => void;
  open: boolean;
  actionButtons?: ReactElement[];
  children?: ReactNode;
  className?: string;
  currentStep?: number;
  leadingIcon?: ReactElement;
  showCloseButton?: boolean;
  steps?: string[];
  subtitle?: string;
  title?: string;
} & VariantProps<typeof modalVariants>;

export const Modal = ({
  title,
  subtitle,
  children,
  showCloseButton = false,
  steps,
  currentStep = 0,
  open,
  closeModal,
  actionButtons,
  className,
  leadingIcon,
  iconPosition,
}: ModalProps) => {
  return (
    <Dialog open={open} onOpenChange={(isOpen) => !isOpen && closeModal()}>
      <DialogContent className={className}>
        <div className="flex items-center justify-between">
          {!!steps?.length && (
            <div id="stepIndicator" className="flex-1">
              <StepIndicator currentStep={currentStep} steps={steps} />
            </div>
          )}
          {showCloseButton && (
            <DialogClose asChild>
              <Button
                variant="secondary"
                size="medium"
                shape="circle"
                iconName="X"
                iconPosition="only"
                onClick={closeModal}
              />
            </DialogClose>
          )}
        </div>
        <div className="modalHeader">
          <div className="flex items-center gap-1">
            {leadingIcon && <div className={modalVariants({ iconPosition })}>{leadingIcon}</div>}
            {title && (
              <Text
                id="modalTitle"
                variant="h2"
                color="primary-600"
                weight="extra-bold"
                className="mt-0 flex-1"
              >
                {title}
              </Text>
            )}
          </div>
          {subtitle && (
            <Text id="modalSubtitle" color="primary-600" className="my-0 mt-1 font-[350]">
              {subtitle}
            </Text>
          )}
        </div>
        <div className="modalBody">{children}</div>
        <div className="modalFooter flex justify-end space-x-2">{actionButtons}</div>
      </DialogContent>
    </Dialog>
  );
};
