import { camelCase, compact, get, isEmpty, isNil, round, uniqBy } from "lodash";

import { convertToDollars, formatNumber } from "@core/ui-legacy/utils";

import { GLOBAL_POLICY_RUN, SYNTHETIC_USER_ID } from "./constants";
import { deepParseJson } from "./deepParseJson";
import { parseIfJSON } from "./rules";

export const GET_EXPENSE_LIMIT = 50;
export const GET_EXPENSES_PAGE_SIZE = ["25", "50", "75", "100"];
export const GET_EXPENSES_BULK_ACTION_PAGE_SIZE = ["50", "100", "200", "300", "400", "500"];

export const ExpensesViewNames = {
  SpToDo: "sp-todo",
  SpDone: "sp-done",
  EAToDo: "ea-todo",
  EADone: "ea-done",
  FMUnsubmitted: "fm-unsubmitted",
  FMInReview: "fm-inreview",
  FMReviewed: "fm-reviewed",
  FMArchived: "fm-archived",
  FMAll: "fm-all",
  InsightsUnsubmitted: "insights-unsubmitted",
  InsightsInReview: "insights-inreview",
  InsightsReviewed: "insights-reviewed",
  InsightsArchived: "insights-archived",
  InsightsAll: "insights-all",
};

export const ApproverStatus = {
  Approved: "approved",
  PendingApproval: "pending",
  Conditional: "conditional",
  Skipped: "skipped",
  Rejected: "rejected",
};

export const ExpenseStatuses = {
  PendingSettlement: "pendingSettlement",
  Approved: "approved",
  Rejected: "rejected",
  ReadyToSubmit: "readyToSubmit",
  ReadyToPost: "readyToPost",
  Submitted: "submitted",
  Unsubmitted: "unsubmitted",
};

export const ExpenseApprovalStatuses = {
  Pending: "pending",
  Approved: "approved",
  Rejected: "rejected",
  Conditional: "conditional",
  Skipped: "skipped",
};

export const MyExpenseFilters = {
  NotSubmitted: "notSubmitted",
  NeedsReview: "needsReview",
  ReadyToSubmit: "readyToSubmit",
  Submitted: "submitted",
};

export const ExpenseValidationErrors = {
  RequiredFieldMissing: "requiredFieldMissing",
  RequiredReceiptMissing: "requiredReceiptMissing",
  SplitAmountAllocationsIncomplete: "splitAmountAllocationsIncomplete",
  SplitHasZeroAmount: "splitHasZeroAmount",
  SplitsZeroAmount: "SplitsZeroAmount",
  SplitOnlyOneSplitAdded: "SplitOnlyOneSplitAdded",
  SplitExpenseGT100K: "SplitExpenseGT100K",
  SplitExpenseLTNeg100K: "SplitExpenseLTNeg100K",
  NotReadyToSubmitReason: "NotReadyToSubmitReason",
};

export const getStatusGroups = (status) => {
  const ExpenseStatusGroups = {
    allStatus: `${ExpenseStatuses.Unsubmitted},${ExpenseStatuses.PendingSettlement},${ExpenseStatuses.Approved},${ExpenseStatuses.Submitted},${ExpenseStatuses.ReadyToSubmit},${ExpenseStatuses.Rejected},${ExpenseStatuses.ReadyToPost}`,
    unsubmitted: `${ExpenseStatuses.Unsubmitted},${ExpenseStatuses.PendingSettlement},${ExpenseStatuses.ReadyToSubmit}`,
    inReview: `${ExpenseStatuses.Submitted}`,
    reviewed: `${ExpenseStatuses.Approved},${ExpenseStatuses.Rejected}`,
    archived: `${ExpenseStatuses.ReadyToPost}`,
  };

  const expenseStatus = get(ExpenseStatusGroups, status, ExpenseStatusGroups.allStatus);

  return expenseStatus;
};

export const getExpenseStatusGroupByTabName = (tab) => {
  const statusGroups = {
    todo: ExpenseStatuses.Submitted,
    done: `${ExpenseStatuses.Approved},${ExpenseStatuses.Rejected}`,
  };

  const expenseStatus = get(statusGroups, tab, statusGroups.done);

  return expenseStatus;
};

// TODO deprecate when My Approval queries are available
export const getApprovalStatusGroupByTabName = (tab) => {
  const ApprovalStatusGroups = {
    todo: ExpenseApprovalStatuses.Pending,
    done: `${ExpenseApprovalStatuses.Approved},${ExpenseApprovalStatuses.Rejected},${ExpenseApprovalStatuses.Skipped}`,
  };

  const expenseStatus = get(ApprovalStatusGroups, tab);

  return expenseStatus;
};

export const mapExpenseGQLTypeToJS = (ex, props = {}) => {
  if (!ex) {
    console.error("mapExpenseGQLTypeToJS missing expense to process");
    return {};
  }
  const newExpense = {
    amount: ex.amount,
    attendees: ex.attendees || [],
    costCenterID: get(ex, "costCenter.ID", null),
    expenseTypeID: get(ex, "expenseType.ID", null),
    exportInfo: ex.exportInfo,
    isPersonal: ex.isPersonal,
    merchantName: get(ex, "merchant.name", null),
    customFields: ex.customFields,
    dateTime: ex.dateTime,
    notes: ex.notes,
    postedDateTime: ex.postedDateTime,
    purpose: ex.purpose,
    ...props,
  };

  const removeEmpty = (obj) => {
    Object.keys(obj).forEach((key) => {
      if (obj[key] && typeof obj[key] === "object") removeEmpty(obj[key]);
      else if (obj[key] === undefined || obj[key] === null) delete obj[key];
    });
    return obj;
  };

  return removeEmpty(newExpense);
};

export const getMileageValues = (expense) => {
  const status = get(expense, "status", "");
  const shouldNotUpdateOnPolicyChange =
    status === ExpenseStatuses.Approved ||
    status === ExpenseStatuses.Rejected ||
    status === ExpenseStatuses.ReadyToPost;
  const form = expense?.form;
  if (!expense?.isMileage || !form) {
    return null;
  }

  const mileageDetailsSection = form.fields.find(
    ({ name, _name }) => (name ?? _name) === "Mileage Details",
  );

  const distanceFieldNames = new Set(["fields.mileage.distance", "customFields.distance"]);

  // use Mileage Section if it exists
  // next use default "Expense Details" section (if fields has single one field and it's section)
  // otherwise use form.fields (there is no sections at all)
  const formToSearchMileageFields =
    mileageDetailsSection ?? (form.fields.length === 1 && form.fields[0]?.type === "Section")
      ? form.fields[0]
      : form;

  const distanceField = formToSearchMileageFields.fields.find((field) =>
    distanceFieldNames.has(field.property ?? field._property),
  );

  const distanceUnitsFieldNames = new Set([
    "fields.mileage.distanceUnits",
    "customFields.distanceUnits",
  ]);

  const distanceUnitsField = formToSearchMileageFields.fields.find((field) =>
    distanceUnitsFieldNames.has(field.property ?? field._property),
  );

  if (!distanceField) {
    console.error(
      "Mileage values were requested on a mileage expense that does not have a distance field.",
      form,
    );
    return null;
  }
  const defaultDistance = get(distanceField, "defaultValue", 0);
  const defaultUnits = get(distanceUnitsField, "defaultValue", "");
  const customFields = expense.customFields
    ? JSON.parse(expense.customFields)
    : JSON.parse(expense._customFields);
  const mode = customFields.distanceMode;
  const amountObj = distanceField.calculatedProperties.find(
    ({ property }) => property === "amount",
  );

  const distance = customFields.distance || defaultDistance || 0;
  let { rate } = amountObj;
  let { amount } = expense;
  const units = customFields.distanceUnits || defaultUnits;

  if (shouldNotUpdateOnPolicyChange) {
    // TODO: historical mileage rate should come from the forms eventStore events
    rate = distance ? (amount / distance) * 100 : 0;
    amount = distance ? Math.round((rate * distance) / 100) : amount;
  }
  return {
    distance: convertToWholeDistance(distance),
    rate,
    units,
    amount,
    mode,
    isRoundTrip: customFields.isRoundTrip,
    route: parseIfJSON(customFields.route),
    __typename: "Mileage",
  };
};

export const convertToHundredth = (distance) => {
  if (!Number.isFinite(Number(distance))) {
    console.error(`Cannot convert value that is not a number to hundreds: ${distance}`);
  }
  const pennyValue = Math.floor((distance * 100).toFixed(0));
  if (!Number.isInteger(pennyValue)) {
    console.error(`Converted value is not an integer: ${distance} => ${pennyValue}`);
  }
  return pennyValue;
};

export const convertToWholeDistance = (distance) => {
  if (!Number.isFinite(Number(distance))) {
    console.error(`Cannot convert value that is not a number to distance: ${distance}`);
  }

  // round to avoid floating point issues
  return round(distance * 0.01, 4);
};

export const autoSendBackCheck = (sentBackType, status) =>
  (sentBackType === "automatic" || sentBackType === "policy") &&
  status === ExpenseStatuses.Unsubmitted;

export const getAirfareValues = (expense) => {
  const fields = deepParseJson(expense.fields);
  let airfares = fields?.travel?.booking?.airfare || [];
  // data is currently an array, even though seems like it would be split into multiple expenses from comps
  if (!Array.isArray(airfares)) airfares = [airfares];
  return airfares;
};

export const getCarRentalValues = (expense) => {
  const fields = deepParseJson(expense.fields);
  let vehicleRentals = fields?.travel?.booking?.vehicleRental || [];
  // data is currently an array, even though seems like it would be split into multiple expenses from comps
  if (!Array.isArray(vehicleRentals)) vehicleRentals = [vehicleRentals];
  return vehicleRentals;
};

export const getForexValues = (expense) => {
  const forexDetails = get(expense, "fields.forex", null);
  const billingCcy = get(forexDetails, "billingCurrency", null);
  const originatingCcy = get(forexDetails, "originatingCurrency", null);
  // const forexDetails = get(deepParseJson(customFields), 'level2Data.forex', {});
  const forexDetailsKeys = forexDetails ? Object.keys(forexDetails) : {};
  if (
    isEmpty(forexDetails) ||
    forexDetailsKeys.every((key) => key === "foreignCurrencyCode" || key === "billingCurrencyCode")
  ) {
    // if we have no forex details, or only details about currencyCode, then we don't have any forex
    return null;
  }

  // Will benamed
  let billingCrossBorderFee; // BE sends 'crossBorderFee'
  let billingAmount; // BE sends 'billedAmount'
  let billingTotalAmount; // BE sends 'postedAmount'

  let foreignAmount = get(originatingCcy, "amountRaw", undefined);
  const billingCurrencyCode = get(billingCcy, "name", undefined);
  const billingCurrencyName = get(billingCcy, "namePlural", undefined);
  const billingCurrencySymbol = get(billingCcy, "symbol", undefined);
  const exchangeRate = get(forexDetails, "exchangeRate", undefined);
  let foreignTotalAmount = get(originatingCcy, "amountTotal", undefined);
  let foreignCrossBorderFee = get(originatingCcy, "crossBorderFee", undefined);
  const foreignCurrencyCode = get(originatingCcy, "name", undefined);
  const foreignCurrencyName = get(originatingCcy, "namePlural", undefined);
  const foreignCurrencySymbol = get(originatingCcy, "symbol", undefined);

  // Renaming...
  billingCrossBorderFee = get(billingCcy, "crossBorderFee", undefined);
  billingTotalAmount = get(billingCcy, "amountTotal", undefined);
  billingAmount = get(billingCcy, "amountRaw", undefined);

  // Format numbers; convert amount from cents

  if (!isNil(billingCrossBorderFee)) {
    billingCrossBorderFee = formatNumberForex(billingCrossBorderFee, billingCurrencyCode);
  }

  if (!isNil(billingAmount)) {
    billingAmount = formatNumberForex(billingAmount, billingCurrencyCode);
  }

  if (!isNil(billingTotalAmount)) {
    billingTotalAmount = formatNumberForex(billingTotalAmount, billingCurrencyCode);
  }

  if (!isNil(foreignCrossBorderFee)) {
    foreignCrossBorderFee = formatNumberForex(foreignCrossBorderFee, foreignCurrencyCode);
  }

  if (!isNil(foreignAmount)) {
    foreignAmount = formatNumberForex(foreignAmount, foreignCurrencyCode);
  }

  if (!isNil(foreignTotalAmount)) {
    foreignTotalAmount = formatNumberForex(foreignTotalAmount, foreignCurrencyCode);
  }

  // Having an undefined foreignAmount indicates no level2data
  const forexProps = isNil(billingAmount)
    ? {}
    : {
        billingAmount,
        billingTotalAmount,
        billingCurrencyCode,
        billingCurrencyName,
        billingCurrencySymbol,
        billingCrossBorderFee,
        exchangeRate,
        foreignAmount,
        foreignTotalAmount,
        foreignCrossBorderFee,
        foreignCurrencyCode,
        foreignCurrencyName,
        foreignCurrencySymbol,
      };

  Object.keys(forexProps).forEach((k) => {
    if (forexProps[k] === undefined) delete forexProps[k];
  });

  return forexProps;
};

export const getForexTable = (forexProps) => {
  if (!forexProps) return null;
  const foreignCurrencyCode = get(forexProps, "foreignCurrencyCode", "");
  const billingCurrencyCode = get(forexProps, "billingCurrencyCode", "");

  const rowOrder = ["billing"];
  if (billingCurrencyCode.toUpperCase() !== foreignCurrencyCode.toUpperCase()) {
    rowOrder.unshift("foreign");
  }

  const columns = ["CurrencyCode", "CrossBorderFee", "TotalAmount", "CurrencyName", "Amount"];

  const forexTable = rowOrder.map((row) => {
    const rowData = {};
    columns.forEach((col) => {
      const key = row + col;
      const val = forexProps[key];
      const currencyCode = forexProps[`${row}CurrencyCode`];
      if (col === "CurrencyName" && val) {
        rowData[camelCase(col)] = val;
      } else if (!isNil(val)) {
        rowData[camelCase(col)] = `${val} ${currencyCode}`;
      }
    });
    return rowData;
  });

  return forexTable;
};

export const currencyConversionString = (originatingCurrency, exchangeRate, billingCurrency) => {
  let exchangeRateTxt;
  // TODO: add an org currency check here
  exchangeRateTxt =
    originatingCurrency.name === "USD" && exchangeRate
      ? ""
      : `1 ${originatingCurrency.name} = ${exchangeRate?.toFixed(5)} ${billingCurrency.name}`;

  return exchangeRateTxt;
};

export const formatNumberForex = (amount, currencyCode) =>
  formatNumber({
    currencyCode,
    value: convertToDollars(amount),
    options: {
      style: "currency",
      currency: currencyCode,
    },
    locales: {
      nu: navigator.language || navigator.userLanguage,
    },
    decimalPrecision: 2,
    displayNegativeSign: true,
    asPercent: false,
    useGrouping: true,
  });

export const getApproversList = (approvers, approvedBy, rejectedBy) => {
  const FMApprovedByExpenseHub = approvers?.some(
    (approver) => approver.userID === approvedBy?.userID && approver.status === "approved",
  );
  const FMDeniedByExpenseHub = approvers?.some(
    (approver) => approver.userID === rejectedBy?.userID && approver.status === "rejected",
  );
  const approverList = approvers?.length ? [...approvers] : [];
  if (approvedBy && !FMApprovedByExpenseHub) {
    approverList.push({
      ...approvedBy,
      userFullName: [SYNTHETIC_USER_ID, GLOBAL_POLICY_RUN].includes(approvedBy.userID)
        ? "Auto-Approved"
        : approvedBy.fullName,
      status: "approved",
    });
  }
  if (rejectedBy && !FMDeniedByExpenseHub) {
    approverList.push({
      ...rejectedBy,
      userFullName: rejectedBy.fullName,
      status: "rejected",
    });
  }
  return approverList;
};

export const TabName = {
  TAB_UNSUBMITTED: "unsubmitted",
  TAB_IN_REVIEW: "inReview",
  TAB_REVIEWED: "reviewed",
  TAB_ARCHIVED: "archived",
  TAB_ALL: "all",
  TAB_TODO: "todo",
  TAB_DONE: "done",
};

// Render views
export const RENDER_VIEW_HUB = "hub";
export const RENDER_VIEW_MY_APPROVALS = "myApprovals";
export const RENDER_VIEW_INSIGHTS = "insights";
// allowed tabs and views for filter by statuses
export const allowedStatusFilteringViews = new Set([RENDER_VIEW_MY_APPROVALS, RENDER_VIEW_HUB]);
export const allowedStatusFilteringTabs = new Set([
  TabName.TAB_DONE,
  TabName.TAB_REVIEWED,
  TabName.TAB_ARCHIVED,
  TabName.TAB_ALL,
]);

export const getTableFilterByID = (id, filter) => {
  const tableFilters = {
    [TabName.TAB_UNSUBMITTED]: {
      id: TabName.TAB_UNSUBMITTED,
      searchQuery: {
        ...filter?.searchQuery, // filters params from searchbars
        ...get(filter, "tileQuery.query", {}), // filters params from KPI tiles
      },
    },
    [TabName.TAB_IN_REVIEW]: {
      id: TabName.TAB_IN_REVIEW,
      searchQuery: {
        ...filter?.searchQuery,
        ...get(filter, "tileQuery.query", {}),
      },
    },
    [TabName.TAB_REVIEWED]: {
      id: TabName.TAB_REVIEWED,
      searchQuery: filter?.searchQuery,
      status: filter?.searchObj?.status,
    },
    [TabName.TAB_ARCHIVED]: {
      id: TabName.TAB_ARCHIVED,
      searchQuery: filter?.searchQuery,
      status: filter?.searchObj?.status,
    },
    [TabName.TAB_ALL]: {
      id: TabName.TAB_ALL,
      searchQuery: filter?.searchQuery,
      status: filter?.searchObj?.status,
    },
    [TabName.TAB_TODO]: {
      id: TabName.TAB_TODO,
      searchQuery: {
        ...filter?.searchQuery,
        ...get(filter, "tileQuery.query", {}),
        status: {
          value: getExpenseStatusGroupByTabName(TabName.TAB_TODO),
          operator: "eq",
        },
        isTransactionSettled: {
          value: "true",
          operator: "eq",
        },
      },
      approvalStatus: getApprovalStatusGroupByTabName(TabName.TAB_TODO),
    },
    [TabName.TAB_DONE]: {
      id: TabName.TAB_DONE,
      status: filter?.searchObj?.status,
      searchQuery: {
        ...filter?.searchQuery,
        ...get(filter, "tileQuery.query", {}),
      },
      approvalStatus: getApprovalStatusGroupByTabName(TabName.TAB_DONE),
    },
  };

  return tableFilters[id];
};

export const isAmountExceedMaxLimit = (value, rate, limit) => {
  if (!rate) {
    rate = 1;
  }
  if (!limit) {
    limit = 10000000;
  }
  const amount = typeof value === "number" ? value : Number.parseFloat(value) || 0;
  return limit < 0 ? amount * rate < limit : amount * rate > limit;
};

const checkboxes = {
  unsubmitted: {
    id: ExpenseStatuses.Unsubmitted,
    label: "Unsubmitted",
  },
  inReview: {
    id: ExpenseStatuses.Submitted,
    label: "In Review",
  },
  approved: {
    id: ExpenseStatuses.Approved,
    label: "Approved",
  },
  denied: {
    id: ExpenseStatuses.Rejected,
    label: "Denied",
  },
};

export const expenseHubCheckboxes = {
  [TabName.TAB_DONE]: [checkboxes.inReview, checkboxes.approved, checkboxes.denied],
  [TabName.TAB_REVIEWED]: [checkboxes.approved, checkboxes.denied],
  [TabName.TAB_ARCHIVED]: [checkboxes.approved, checkboxes.denied],
  [TabName.TAB_ALL]: [
    checkboxes.unsubmitted,
    checkboxes.inReview,
    checkboxes.approved,
    checkboxes.denied,
  ],
};

export const flattenBubbles = (expense) => {
  const parentBubbles = expense.bubbles ?? [];
  const flatSplitBubbles = expense.splits?.flatMap((split) => split.bubbles ?? []) ?? [];

  return compact(uniqBy([...parentBubbles, ...flatSplitBubbles], "groupType"));
};
