import { formatNumber } from "./index";

const number = 1234567.8905;
export const nbsp = String.fromCodePoint(160);

export const convertCurrencyToLocalLanguage = (ccy, localLanguage, currencyDisplay = "symbol") =>
  formatNumber({
    currencyCode: ccy,
    style: "currency",
    currencyDisplay,
    value: number,
    localLanguage,
  });

// CharCode of delimiters  , . ' ', nbsp
const possibleDelimiters = new Set([44, 46, 32, 160]);

/**
 * Determines currency symbol and delimiters based on locale
 * @param  {String} currencyCode
 * @return {Object}
 */
export const getDelimiters = (currency, localLanguage, currencySymbolPosition, currencyDisplay) => {
  let thousandsSeparator;
  let decimalSeparator;
  let position = "left";
  let symbol = "";
  const formattedCcy = convertCurrencyToLocalLanguage(currency, localLanguage, currencyDisplay);
  // remove numbers
  const ccyProperties = formattedCcy.match(/(\D)/g) || "";

  // If 0th index is a delimiter, symbol is positioned at the right side
  position = possibleDelimiters.has(ccyProperties[0].codePointAt(0)) ? "right" : "left";
  if (currencySymbolPosition) {
    position = currencySymbolPosition;
  }
  ccyProperties.forEach((char, index) => {
    if (!possibleDelimiters.has(char.codePointAt(0))) {
      symbol += char; // CA$ or $CA or $ depending on locale settings
    } else if (char === ccyProperties[index - 1]) {
      // thousandsSeparator will be adjacent to one another
      thousandsSeparator = char;
    } else if (thousandsSeparator === ccyProperties[index - 1]) {
      // decimalSeparator is always after the thousandsSeparator
      decimalSeparator = char;
    }
  });

  return {
    thousandsSeparator: thousandsSeparator || ",",
    decimalSeparator: decimalSeparator || ".",
    position,
    symbol,
  };
};
