import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import dayjs from "dayjs";
import { toast } from "sonner";

import { Button, Sidebar } from "@core/ui";

import { CardExpirationContext } from "../../../common/contexts/CardExpirationProvider";
import { useIsHRISLinked } from "../../../services/rest/hris";
import { wrapRequest } from "../../../services/rest/utils";
import { showSuccessToast } from "../../TravelInsightsPage/utils";
import { checkSuggestions, DATE_FORMAT, validateInternally } from "../cardsExpiration.utils";
import { InvalidAddressModal } from "../modals/InvalidAddressModal";
import type { AddressInput, AddressInputErrors, ShippingPreferences, Step, Steps } from "../types";
import { PreferenceReview, ShippingPreference, Suggestion, Unlink } from "./steps";

export const ShippingPreferenceSelector = () => {
  const {
    showSidebar,
    orgAddress,
    expirationYYMM,
    setPreferenceEndDate,
    cardProgramId,
    currentStep,
    setCurrentStep,
    setShowSidebar,
    setShowShippingPreferenceBanner,
    currentPreference,
  } = useContext(CardExpirationContext);
  const [preference, setPreference] = useState<ShippingPreferences>(currentPreference);
  const [selection, setSelection] = useState<"suggested" | "original">("suggested");
  const [showInvalidAddressModal, setShowInvalidAddressModal] = useState(false);
  const [errors, setErrors] = useState<AddressInputErrors>({});
  const [confirm, setConfirm] = useState(false);
  const showToastMessage = useRef(false);
  const suggestedAddress = useRef<AddressInput>();
  const usedAutoComplete = useRef(false);

  const defaultAddress: AddressInput = useMemo(
    () => ({
      line1: orgAddress.address1,
      line2: orgAddress.address2 ?? "",
      city: orgAddress.city,
      state: orgAddress.state,
      zipCode: orgAddress.postalCode,
      country: orgAddress.country,
    }),
    [
      orgAddress.address1,
      orgAddress.address2,
      orgAddress.city,
      orgAddress.state,
      orgAddress.postalCode,
      orgAddress.country,
    ],
  );

  const handleError = useCallback(
    (error: unknown) => {
      window.console.error(error);
      setShowSidebar(false);
      setShowShippingPreferenceBanner(true);
      toast.error("Error updating shipping preference");
    },
    [setShowSidebar, setShowShippingPreferenceBanner],
  );

  const [formData, setFormData] = useState(defaultAddress);

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (data: AddressInput) =>
      wrapRequest({
        method: "PUT",
        url: `/card-reissuance/v3.0/${cardProgramId}/shippingpreference`,
        data: {
          shippingPreference: { preference },
          ...(preference === "central"
            ? {
                centralAddress: {
                  address1: data.line1,
                  address2: data.line2,
                  city: data.city,
                  state: data.state,
                  country: data.country,
                  postalCode: data.zipCode,
                },
              }
            : {}),
        },
      }),
    onSuccess: () => {
      showToastMessage.current = true;
      setCurrentStep("review");
    },
    onError: handleError,
  });

  const handleClose = useCallback(
    (showBanner: boolean) => () => {
      setShowSidebar(false);
      setShowShippingPreferenceBanner(showBanner);
      if (showToastMessage.current) {
        showSuccessToast("Shipping preference selected");
        showToastMessage.current = false;
      }
    },
    [setShowSidebar, setShowShippingPreferenceBanner],
  );

  const isHRISLinked = useIsHRISLinked();

  const onSelectionSubmit = useCallback(async () => {
    if (preference === "individual_verified" && isHRISLinked) {
      setCurrentStep("unlink");
      return;
    }

    if (preference === "central") {
      const { hasErrors, issues } = validateInternally(formData);
      setErrors((prev) => issues || prev);
      if (hasErrors) return;
      if (!usedAutoComplete.current) {
        try {
          const suggestion = await checkSuggestions(formData);
          if (suggestion) {
            suggestedAddress.current = suggestion;
            setCurrentStep("suggestion");
            return;
          }
          setShowInvalidAddressModal(true);
          return;
        } catch (error) {
          handleError(error);
          return;
        }
      }
    }

    void mutateAsync(formData);
  }, [formData, preference, isHRISLinked, mutateAsync, setCurrentStep, handleError]);

  const handleUnlink = useCallback(async () => {
    await mutateAsync(formData);
    setCurrentStep("review");
  }, [formData, mutateAsync, setCurrentStep]);

  const handleSuggestion = useCallback(async () => {
    if (!suggestedAddress.current) return;
    const chosenAddress = selection === "suggested" ? suggestedAddress.current : formData;
    await mutateAsync(chosenAddress);
    setFormData(chosenAddress);
    setCurrentStep("review");
  }, [mutateAsync, setCurrentStep, formData, selection]);

  const handleVerifyInvalidAddress = useCallback(async () => {
    await mutateAsync(formData);
    setShowInvalidAddressModal(false);
    handleClose(false)();
  }, [handleClose, formData, mutateAsync]);

  const expirationDate = dayjs(expirationYYMM, "YYMM").utc().endOf("month").format(DATE_FORMAT);
  const setPreferenceDeadline = setPreferenceEndDate.format(DATE_FORMAT);

  const handleUsedAutocomplete = (used: boolean) => {
    usedAutoComplete.current = used;
  };

  const steps: Record<Steps, Step> = {
    selection: {
      title: "Set your shipping preference",
      description: `All cards will expire on ${expirationDate}. To get started, please set your shipping preference below. You can edit your preference anytime before ${setPreferenceDeadline}.`,
      content: (
        <ShippingPreference
          confirm={confirm}
          setConfirm={setConfirm}
          preference={preference}
          setPreference={setPreference}
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
          handleUsedAutocomplete={handleUsedAutocomplete}
        />
      ),
      actions: [
        <div key="placeholder" />, // buttons render left to right
        <Button
          key="Submit"
          text="Submit"
          onClick={onSelectionSubmit}
          disabled={!confirm}
          isLoading={isLoading}
        />,
      ],
    },
    suggestion: {
      title: "Enter your new shipping address",
      description:
        "There was an issue with the address that you've entered, we've come up with a suggestion below. Please choose the version that you would like to use. You can also return to the previous stage to edit the address.",
      content: (
        <Suggestion
          originalAddress={formData}
          suggestedAddress={suggestedAddress.current}
          selection={selection}
          setSelection={setSelection}
        />
      ),
      actions: [
        <Button
          key="back"
          text="Back"
          variant="secondary"
          onClick={() => setCurrentStep("selection")}
        />,
        <Button key="Submit" text="Submit" onClick={handleSuggestion} isLoading={isLoading} />,
      ],
    },
    unlink: {
      title: "Address field will unlink",
      description: "We noticed that your organization is currently configured with HR Connect.",
      content: <Unlink />,
      actions: [
        <Button
          key="back"
          text="Back"
          variant="secondary"
          onClick={() => setCurrentStep("selection")}
        />,
        <Button key="submit" text="Submit" onClick={handleUnlink} isLoading={isLoading} />,
      ],
    },
    review: {
      title: "Card renewal shipping preference",
      description: `Thank you for submitting your shipping preference. You can change your preference at any time before ${setPreferenceDeadline}.`,
      content: (
        <PreferenceReview
          shippingPreference={preference}
          address={formData}
          setStep={setCurrentStep}
        />
      ),
      actions: [
        <div key="placeholder" />, // buttons render left to right
        <Button key="Close" text="Close" onClick={handleClose(false)} />,
      ],
    },
  };

  const { title, content, actions, description } = steps[currentStep] ?? {};

  return (
    <>
      <Sidebar
        title={title}
        description={description}
        currentStep={0}
        actionButtons={actions}
        open={showSidebar}
        onClose={handleClose(true)}
        hideSeparator
      >
        {content}
      </Sidebar>
      <InvalidAddressModal
        line1={formData.line1}
        line2={formData.line2}
        city={formData.city}
        zipCode={formData.zipCode}
        state={formData.state}
        country={formData.country}
        showInvalidAddressModal={showInvalidAddressModal}
        setShowInvalidAddressModal={setShowInvalidAddressModal}
        handleVerifyInvalidAddress={handleVerifyInvalidAddress}
      />
    </>
  );
};
