import type { FeatureFlag } from "../utils/featureFlags";

export const userHasFeatures = (
  userFeatures: FeatureFlag[] = [],
  featuresRequired: FeatureFlag[] = [],
) => {
  if (!featuresRequired || featuresRequired.length === 0) {
    return true;
  }
  return featuresRequired.every((feature) => userFeatures.includes(feature));
};
