import { useContext } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Form } from "@core/ui-legacy";

import DynamicFormContext from "../../common/DynamicFormContext";
import { UnassignedOption } from "../../features/UserEditPage/styledComponents";
import DataSelector from "../DataSelector";
import ReadOnlyField from "./ReadOnlyField";

export const UNASSIGNED = "Unassigned";
export const renderUnassignedOption = () => <UnassignedOption>{UNASSIGNED}</UnassignedOption>;

const TextBefore = styled.span`
  margin-right: 16px;
  font-weight: 350;
  font-size: 16px;
  line-height: 24px;
  color: #001c3d;
`;

const getChildren = (children, options = {}) => {
  const { addUnassigned, optionValue = "name" } = options || {};
  if (addUnassigned) {
    return [{ ID: UNASSIGNED, [optionValue]: renderUnassignedOption() }, ...children];
  }

  return children;
};

const DropDownListField = ({ entity, field }) => {
  const {
    name,
    property,
    isRequired,
    isEnabled,
    readOnly,
    values: children,
    validationRules: rules,
    options = {},
    defaultValue,
  } = field ?? {};

  const initialValue = _.get(entity, property, defaultValue);

  const { addUnassigned: __, ...fieldOptions } = options || {};

  const { form, onChange, filedRulesNormalizer, canEdit } = useContext(DynamicFormContext);

  if (!canEdit || readOnly) {
    const initialValueText =
      children?.find((c) => c.ID === (initialValue?.ID ?? initialValue))?.name ??
      initialValue?.name ??
      initialValue;

    return (
      <ReadOnlyField
        {...{
          entity,
          field,
          text: initialValueText,
        }}
      />
    );
  }

  return (
    <>
      {fieldOptions.addonBefore && <TextBefore>{fieldOptions.addonBefore}</TextBefore>}
      <Form.Field
        {...{
          as: DataSelector,
          options: {
            id: property,
            rules: filedRulesNormalizer(rules, isRequired),
            initialValue,
            onChange: (value) => {
              onChange(field, { [property]: value });
            },
          },
          item: {
            label: name,
            form,
          },
          element: {
            ...fieldOptions,
            disabled: !isEnabled,
            scrollableAreaId: property,
            children: getChildren(children, options),
            size: "large",
          },
        }}
      />
    </>
  );
};

DropDownListField.propTypes = {
  entity: PropTypes.object,
  field: PropTypes.object,
};

export default DropDownListField;
