import PropTypes from "prop-types";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import styled from "styled-components";

import ListItemDraggable from "./ListItemDraggable";

const ListStyled = styled.div`
  user-select: none;
`;

const ListDraggable = ({ children, onDragEnd, isDropDisabled, ...rest }) => (
  <DragDropContext onDragEnd={onDragEnd}>
    <Droppable droppableId="list" isDropDisabled={isDropDisabled}>
      {(provided) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <ListStyled ref={provided.innerRef} {...provided.droppableProps} {...rest}>
          {children}
          {provided.placeholder}
        </ListStyled>
      )}
    </Droppable>
  </DragDropContext>
);

ListDraggable.propTypes = {
  children: PropTypes.any,
  onDragEnd: PropTypes.func.isRequired,
  isDropDisabled: PropTypes.bool,
};

ListDraggable.Item = ListItemDraggable;

export default ListDraggable;
