import {
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  type ComponentProps,
  type HTMLAttributes,
  type ReactNode,
} from "react";
import useEmblaCarousel, { type UseEmblaCarouselType } from "embla-carousel-react";

import { cn } from "../../lib/utils";
import { Button, buttonVariants, type ButtonVariant } from "../Button";
import { Text } from "../Text";

type CarouselApi = UseEmblaCarouselType[1];
type UseCarouselParameters = Parameters<typeof useEmblaCarousel>;
type CarouselOptions = UseCarouselParameters[0];
type CarouselPlugin = UseCarouselParameters[1];

export type CarouselProps = {
  buttonNextClassName?: string;
  buttonPreviousClassName?: string;
  onChange?: (index: number) => void;
  buttonVariant?: ButtonVariant;
  caption?: string[];
  captionClassName?: string;
  children?: string[] | ReactNode[];
  itemClassName?: string;
  loop?: boolean;
  opts?: CarouselOptions;
  orientation?: "horizontal" | "vertical";
  plugins?: CarouselPlugin;
  setApi?: (api: CarouselApi) => void;
  wrapperClassName?: string;
};

type CarouselContextProps = {
  api: ReturnType<typeof useEmblaCarousel>[1];
  canScrollNext: boolean;
  canScrollPrev: boolean;
  carouselRef: ReturnType<typeof useEmblaCarousel>[0];
  scrollNext: () => void;
  scrollPrev: () => void;
  isHorizontal?: boolean;
} & CarouselProps;

const CarouselContext = createContext<CarouselContextProps | null>(null);

function useCarousel() {
  const context = useContext(CarouselContext);

  if (!context) {
    throw new Error("useCarousel must be used within a <Carousel />");
  }

  return context;
}

const CarouselContent = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    const { carouselRef, isHorizontal } = useCarousel();

    return (
      <div ref={carouselRef} className="overflow-hidden">
        <div
          data-testid="overlay"
          ref={ref}
          className={cn("flex", isHorizontal ? "" : "flex-col", className)}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </div>
    );
  },
);
CarouselContent.displayName = "CarouselContent";

const CarouselItem = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    const { isHorizontal } = useCarousel();

    return (
      <div
        ref={ref}
        role="group"
        aria-roledescription="slide"
        className={cn("min-w-0 shrink-0 grow-0 basis-full", className)}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    );
  },
);
CarouselItem.displayName = "CarouselItem";

const CarouselPrevious = forwardRef<HTMLButtonElement, ComponentProps<typeof Button>>(
  ({ className, variant = "subtle", ...props }, ref) => {
    const { isHorizontal, scrollPrev, canScrollPrev } = useCarousel();
    if (!canScrollPrev) return null;
    return (
      <Button
        ref={ref}
        variant={variant}
        size="medium"
        className={cn(
          "absolute",
          isHorizontal
            ? "-left-12 top-1/2 -translate-y-1/2"
            : "-top-12 left-1/2 -translate-x-1/2 rotate-90",
          buttonVariants({ variant, shape: "circle", icon: "only" }),
          className,
        )}
        onClick={scrollPrev}
        role="button"
        iconName="ArrowLeft"
        iconPosition="only"
        shape="pill"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        <span className="sr-only">Previous slide</span>
      </Button>
    );
  },
);
CarouselPrevious.displayName = "CarouselPrevious";

const CarouselNext = forwardRef<HTMLButtonElement, ComponentProps<typeof Button>>(
  ({ className, variant = "subtle", ...props }, ref) => {
    const { orientation, scrollNext, canScrollNext } = useCarousel();
    if (!canScrollNext) return null;
    return (
      <Button
        ref={ref}
        variant={variant}
        size="medium"
        className={cn(
          "absolute",
          orientation === "horizontal"
            ? "-right-12 top-1/2 -translate-y-1/2"
            : "-bottom-12 left-1/2 -translate-x-1/2 rotate-90",
          buttonVariants({ variant, shape: "circle", icon: "only" }),
          className,
        )}
        onClick={scrollNext}
        iconName="ArrowRight"
        iconPosition="only"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    );
  },
);
CarouselNext.displayName = "CarouselNext";

const Carousel = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement> & CarouselProps>(
  (
    {
      orientation = "horizontal",
      opts,
      setApi,
      plugins,
      wrapperClassName,
      itemClassName,
      children,
      loop,
      caption,
      captionClassName,
      buttonNextClassName,
      buttonPreviousClassName,
      buttonVariant,
      onChange,
      ...props
    },
    ref,
  ) => {
    const isHorizontal = orientation === "horizontal";
    const [carouselRef, api] = useEmblaCarousel(
      {
        ...opts,
        watchDrag: false,
        axis: isHorizontal ? "x" : "y",
      },
      plugins,
    );
    const [canScrollPrev, setCanScrollPrev] = useState(false);
    const [canScrollNext, setCanScrollNext] = useState(false);
    const [current, setCurrent] = useState(0);

    const [, setCount] = useState(0);
    const onSelect = useCallback((carouselApi: CarouselApi) => {
      if (!carouselApi) {
        return;
      }

      setCanScrollPrev(carouselApi.canScrollPrev());
      setCanScrollNext(carouselApi.canScrollNext());
    }, []);

    const handleChange = useCallback(
      (change: number) => {
        setCurrent(change);
        onChange?.(change);
      },
      [onChange],
    );

    const scrollPrev = useCallback(() => {
      api?.scrollPrev();
    }, [api]);

    const scrollNext = useCallback(() => {
      api?.scrollNext();
    }, [api]);

    useEffect(() => {
      if (!api || !setApi) {
        return;
      }

      if (api && setApi) {
        setApi(api);
      }
    }, [api, setApi]);

    useEffect(() => {
      if (!api) {
        return;
      }
      setCount(api.scrollSnapList().length);
      handleChange(api.selectedScrollSnap());
      api.on("select", () => {
        handleChange(api.selectedScrollSnap());
      });
      onSelect(api);
      api.on("reInit", onSelect);
      api.on("select", onSelect);
    }, [api, onSelect, handleChange]);

    const carouselCtx = useMemo(
      () => ({
        carouselRef,
        api,
        opts,
        orientation: orientation || (opts?.axis === "y" ? "vertical" : "horizontal"),
        scrollPrev,
        scrollNext,
        canScrollPrev,
        canScrollNext,
        isHorizontal,
      }),
      [
        carouselRef,
        api,
        opts,
        isHorizontal,
        orientation,
        scrollPrev,
        scrollNext,
        canScrollPrev,
        canScrollNext,
      ],
    );

    return (
      <CarouselContext.Provider value={carouselCtx}>
        <div
          ref={ref}
          className={cn("relative", wrapperClassName)}
          role="region"
          aria-roledescription="carousel"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        >
          <CarouselContent className="h-full">
            {children?.map((child, index) => {
              return (
                <CarouselItem
                  key={`carousel-item-${index + 1}`}
                  className={cn(
                    "flex aspect-square items-center justify-center border bg-card text-card-foreground overflow-hidden",
                    itemClassName,
                  )}
                >
                  {child}
                </CarouselItem>
              );
            })}
          </CarouselContent>
          <CarouselPrevious variant={buttonVariant} className={buttonPreviousClassName} />
          <CarouselNext variant={buttonVariant} className={buttonNextClassName} />
        </div>

        {caption?.length && caption[current] ? (
          <Text variant="subtext" className="py-2 text-center" role="textbox">
            {caption[current]}
          </Text>
        ) : null}
      </CarouselContext.Provider>
    );
  },
);

export {
  type CarouselApi,
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselPrevious,
  CarouselNext,
};
