import { createContext, useContext, useMemo, type ReactNode } from "react";

import { useFlag } from "@core/feature-flags/clients";

import type { IAuthUser } from "../features/UserInfoPage/types";
import { getInactiveCards } from "../utils";
import AuthUserContext from "./contexts/AuthUserContext";
import type { CardType } from "./types";

export type CardActivationWizardContextType = {
  cardsToActivate: CardType[];
  hasNotActivatedCard: boolean;
};

const CardActivationWizardContext = createContext<CardActivationWizardContextType>({
  hasNotActivatedCard: false,
  cardsToActivate: [],
});

export const CardActivationWizardProvider = ({ children }: { children: ReactNode }) => {
  const authUser = useContext<IAuthUser>(AuthUserContext);
  const hasAdminLock = useFlag("release-admin-lock");
  const cardsToActivate = useMemo(
    () => getInactiveCards(authUser.cardItems, hasAdminLock),
    [authUser.cardItems],
  );

  return (
    <CardActivationWizardContext.Provider
      {...{
        value: {
          cardsToActivate,
          hasNotActivatedCard: cardsToActivate.length > 0,
        },
      }}
    >
      {children}
    </CardActivationWizardContext.Provider>
  );
};

export const useCardActivationWizardContext = () => useContext(CardActivationWizardContext);
