/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from "prop-types";
import { Draggable } from "react-beautiful-dnd";
import styled from "styled-components";

const TDStyled = styled.td`
  width: ${({ isDragging, dragWidth }) => (isDragging ? dragWidth : undefined)};
`;

const CellDraggable = ({ children, column, dragHandle, dragWidth, index, ...rest }) =>
  dragHandle ? (
    <Draggable draggableId={`list-${index}`} index={index}>
      {(provided, snapshot) => (
        <TDStyled
          data-testid={`drag-handle-${index}`}
          dragWidth={dragWidth}
          isDragging={snapshot.isDragging}
          ref={provided.innerRef}
          {...rest}
          {...provided.dragHandleProps}
        >
          {children}
        </TDStyled>
      )}
    </Draggable>
  ) : (
    <Draggable draggableId={`list-${index}`} index={index}>
      {(provided, snapshot) => (
        <TDStyled
          ref={provided.innerRef}
          dragWidth={dragWidth}
          isDragging={snapshot.isDragging}
          {...rest}
        >
          {children}
        </TDStyled>
      )}
    </Draggable>
  );
CellDraggable.propTypes = {
  children: PropTypes.any,
  index: PropTypes.number,
  column: PropTypes.any,
  dragWidth: PropTypes.string,
  dragHandle: PropTypes.bool,
};

export default CellDraggable;
