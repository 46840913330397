import { Collapse as AntCollapse } from "antd";
import styled from "styled-components";

const Collapse = styled(AntCollapse)`
  .ant-collapse-arrow {
    line-height: 0 !important;
    margin: 0 !important;
  }
`;

export default Collapse;
