import { useEffect } from "react";
import { useAuth } from "@frontegg/react";

import { reloadCommonRoute } from "../components/OrganizationSwitch/utils";

const useFronteggTenantSwitchInterceptor = (activeOrgID?: string) => {
  const { isAuthenticated, user } = useAuth();
  const tenantSwitchInProgress = isAuthenticated && !!activeOrgID && user?.tenantId !== activeOrgID;

  useEffect(() => {
    if (tenantSwitchInProgress) {
      reloadCommonRoute();
    }
  }, [tenantSwitchInProgress]);

  return {
    tenantSwitchInProgress,
  };
};

export default useFronteggTenantSwitchInterceptor;
