import { Button as AntButton } from "antd";
import styled from "styled-components";

import Colors from "../../themes/colors";

const getTypeStyle = (type) => {
  switch (type) {
    case "primary":
      return `color: ${Colors.white} !important;
              border: 1px solid ${Colors.primary} !important;`;
    case "cancel":
      return `border: 1px solid ${Colors.cool_gray} !important;
              color: ${Colors.monumental} !important;`;
    case "secondary":
      return `border: 1px solid ${Colors.cool_gray} !important;
              background-color: transparent !important;
              color: ${Colors.link_blue} !important;`;
    case "tertiary":
      return `border: 1px solid ${Colors.placeholder_gray} !important;
              color: ${Colors.midnight} !important;`;
    case "quaternary":
      return `border: 0 !important;
              background-color: ${Colors.fog_gray} !important;
              color: ${Colors.midnight} !important;`;
    case "danger":
      return `background-color: ${Colors.danger_red} !important;
              color: ${Colors.white} !important;
              border: 1px solid ${Colors.danger_red} !important;`;
    case "copy-btn":
      return `background-color: transparent !important;
              border: none !important`;
    case "link":
      return `background-color: transparent !important;
              border: none !important;
              color: ${Colors.monumental} !important;`;
    case "blue-link":
      return `background-color: transparent !important;
              border: none !important;
              color: ${Colors.link_blue} !important;`;
    case "danger-link":
      return `background-color: transparent !important;
              border: none !important;
              color: ${Colors.danger_red} !important;`;
    case "danger-btn":
      return `background-color: ${Colors.fog_gray} !important;
              border: none !important;
              color: ${Colors.danger_red} !important;`;
    case "deny":
      return `border: 0 !important;
              background-color: ${Colors.fog_gray} !important;
              color: ${Colors.danger_red} !important;`;
    default:
      return `border: 1px solid ${Colors.midnight} !important;
              color: ${Colors.midnight} !important;`;
  }
};

const getHoverFocusTypeStyle = (type) => {
  switch (type) {
    case "primary":
      return `background-color: ${Colors.primary} !important;
              opacity: 0.8 !important;`;
    case "cancel":
      return `border: 1px solid ${Colors.monumental} !important;
              color: ${Colors.monumental} !important;`;
    case "secondary":
      return `border: 1px solid ${Colors.link_blue} !important;`;
    case "tertiary":
      return `border: 1px solid ${Colors.midnight} !important;
              opacity: 1 !important;
              color: ${Colors.midnight} !important;`;
    case "quaternary":
      return `opacity: 0.8 !important;`;
    default:
      return `opacity: 0.7 !important;`;
  }
};

const getActiveTypeStyle = (type) => {
  switch (type) {
    case "primary":
      return `background-color: ${Colors.primary} !important;
              border: 1px solid ${Colors.primary} !important;
              opacity: 1 !important;`;
    case "cancel":
      return `background-color: ${Colors.lunar_gray} !important;`;
    case "secondary":
      return `border: 1px solid ${Colors.midnight} !important;
              color: ${Colors.midnight} !important;`;
    default:
      return `opacity: 1 !important;`;
  }
};

const getDisableStyle = (type) => {
  switch (type) {
    case "copy-btn":
      return `background-color: transparent !important;
              border: none !important`;
    case "default":
      return `background-color: transparent !important`;
    default:
      return null;
  }
};

export const ButtonStyling = ({ type, shape }) => `
letter-spacing: -0.0187rem;
transition: none !important;
border-radius: ${shape ? "auto" : "4px"} !important;
box-shadow: none !important;

.anticon {
  vertical-align: text-top !important;
}

:not(.ant-btn-background-ghost, [disabled]) {
  ${getTypeStyle(type)};
}

:hover:not([disabled]) {
  ${getHoverFocusTypeStyle(type)};
}

:disabled {
  ${getDisableStyle(type)};
}

:focus {
  ${getHoverFocusTypeStyle(type)};
}

:active {
  ${getActiveTypeStyle(type)};
}
`;

const ButtonStyled = styled(AntButton)`
  letter-spacing: -0.0187rem;
  transition: none !important;
  border-radius: ${({ shape }) => (shape ? "auto" : "4px")} !important;
  box-shadow: none !important;

  .anticon {
    vertical-align: text-top !important;
  }

  :not(.ant-btn-background-ghost, [disabled]) {
    ${({ type }) => getTypeStyle(type)};
  }

  :hover:not([disabled]) {
    ${({ type }) => getHoverFocusTypeStyle(type)};
  }

  :disabled {
    ${({ type }) => getDisableStyle(type)};
  }

  :focus {
    ${({ type }) => getHoverFocusTypeStyle(type)};
  }

  :active {
    ${({ type }) => getActiveTypeStyle(type)};
  }
`;

const Button = ButtonStyled;

export default Button;
