import { useCallback, useEffect, useMemo, useState } from "react";
import gql from "graphql-tag";
import PropTypes from "prop-types";
import Highlighter from "react-highlight-words";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";

import { Dropdown, Menu, Spin, Table } from "@core/ui-legacy";
import Colors from "@core/ui-legacy/themes/colors";

import { ReactComponent as EllipsisImage } from "../../assets/imgs/ellipsis.svg";
import { MQBreakPoints } from "../../common/core-refactor/MQBreakPoints";
import useConnectedERPConnector from "../../common/useConnectedERPConnector";
import ComponentError from "../../components/ComponentError";
import DrillDownLink from "../../components/Dashboards/DrillDownLink";

const { TABLET_LANDSCAPE } = MQBreakPoints;

const ActionBtn = styled.div`
  cursor: pointer;
  width: 2.5rem;
  text-align: center;
`;

const MutedSpan = styled.span`
  color: ${Colors.monumental};
`;

const GeneralLedgerList = (props) => {
  const { data, searchWord, loading, error, onEdit } = props;
  const isDesktopOrLaptop = useMediaQuery({
    query: TABLET_LANDSCAPE.query,
  });

  const [generalLedger, setGeneralLedger] = useState(null);

  const { connectedErpConnector, erpConnectors } = useConnectedERPConnector();

  const lookupErpName = useCallback(
    (account) => {
      let conn = null;
      if (account.connectorID) {
        conn = erpConnectors.find((c) => c.ID === account.connectorID);
      } else if (account.connectorTypeCode) {
        conn = erpConnectors.find((c) => c.type === account.connectorTypeCode);
      }
      return conn ? conn.name : "";
    },
    [erpConnectors],
  );

  const integrationEnabled = useMemo(() => !!connectedErpConnector, [connectedErpConnector]);

  useEffect(() => {
    if (!searchWord) {
      setGeneralLedger(data);
      return;
    }
    const word = searchWord.toLowerCase();
    const searchResult = data.filter(
      (e) =>
        e.name.toLowerCase().includes(word) ||
        e.code.toLowerCase().includes(word) ||
        (integrationEnabled && lookupErpName(e).toLowerCase().includes(word)),
    );
    setGeneralLedger(searchResult);
  }, [data, integrationEnabled, lookupErpName, searchWord]);

  const [codeSortOrder, setCodeSortOrder] = useState("ascend");
  const [nameSortOrder, setNameSortOrder] = useState(undefined);
  const [connectorSortOrder, setConnectorSortOrder] = useState(undefined);

  if (error) return <ComponentError size="large" />;

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.field === "code") {
      setCodeSortOrder(sorter.order || "ascend");
      setNameSortOrder(undefined);
      setConnectorSortOrder(undefined);
    }
    if (sorter.field === "name") {
      setNameSortOrder(sorter.order || "ascend");
      setCodeSortOrder(undefined);
      setConnectorSortOrder(undefined);
    }
    if (sorter.field === "externalID") {
      setConnectorSortOrder(sorter.order || "ascend");
      setNameSortOrder(undefined);
      setCodeSortOrder(undefined);
    }
  };

  const renderHighlighter = (val) => (
    <Highlighter
      highlightStyle={{
        backgroundColor: Colors.highlight_yellow,
        padding: 0,
      }}
      searchWords={[searchWord]}
      autoEscape
      textToHighlight={val}
    />
  );

  const renderTable = () => {
    const columns = () => {
      const renderActions = (rec) => {
        const { ID, name } = rec;
        const getActionItems = () => {
          const menuItems = [];

          menuItems.push({
            actionLabel: "Edit GL account",
            onClick: () => onEdit(ID),
          });

          return menuItems.map((m) => {
            const id = `${m.actionLabel}-${ID}`;
            return (
              <Menu.Item key={id} id={id} disabled={m.disabled} onClick={m.onClick}>
                {m.actionLabel}
              </Menu.Item>
            );
          });
        };
        const actionsMenu = <Menu>{getActionItems()}</Menu>;
        return (
          <Dropdown
            id={`actions-${ID}`}
            overlay={actionsMenu}
            trigger={["click"]}
            placement="bottomRight"
            getPopupContainer={() => document.querySelector("#general-ledger-table")}
          >
            <ActionBtn>
              <EllipsisImage role="img" aria-label={`Actions menu for GL account ${name}`} />
            </ActionBtn>
          </Dropdown>
        );
      };

      const cols = [
        {
          title: "GL code",
          dataIndex: "code",
          key: "code",
          defaultSortOrder: "ascend",
          sortOrder: codeSortOrder,
          sorter: (a, b) => {
            const codeA = a.code.toLowerCase();
            const codeB = b.code.toLowerCase();
            return codeA.localeCompare(codeB);
          },
          render: (val, rec) => (
            <DrillDownLink
              data-testid="gl-code-link"
              onClick={() => {
                onEdit(rec.ID);
              }}
              style={{
                maxWidth: "100px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {renderHighlighter(val)}
            </DrillDownLink>
          ),
        },
        {
          title: "GL name",
          dataIndex: "name",
          key: "name",
          className: "wrap-text",
          sortOrder: nameSortOrder,
          sorter: (a, b) => {
            const nameA = a.name.toLowerCase();
            const nameB = b.name.toLowerCase();
            return nameA.localeCompare(nameB);
          },
          render: (val, rec) => (
            <span
              data-testid="gl-name"
              onClick={() => {
                onEdit(rec.ID);
              }}
            >
              {renderHighlighter(val)}
            </span>
          ),
        },
        {
          title: "Connected ERP",
          dataIndex: "externalID",
          key: "externalID",
          sortOrder: connectorSortOrder,
          sorter: (a, b) => {
            const nameA = lookupErpName(a).toLowerCase();
            const nameB = lookupErpName(b).toLowerCase();
            return nameA.localeCompare(nameB);
          },
          hide: !integrationEnabled,
          render: (val, acc) => {
            const erpName = lookupErpName(acc);
            return erpName ? (
              <span data-testid="gl-connected-erp" onClick={() => onEdit(acc.ID)}>
                {renderHighlighter(erpName)}
              </span>
            ) : (
              <MutedSpan
                data-testid="gl-connected-erp"
                onClick={() => {
                  onEdit(acc.ID);
                }}
              >
                {renderHighlighter("Not linked")}
              </MutedSpan>
            );
          },
        },
        {
          key: "actions",
          width: "4%",
          hideOnSmall: true,
          render: (val, rec) => renderActions(rec),
        },
      ];
      return cols.filter(
        ({ hideOnSmall = false, hide = false }) => !(isDesktopOrLaptop && hideOnSmall) && !hide,
      );
    };

    return (
      <Table
        id="general-ledger-table"
        data-testid="general-ledger-table"
        dataSource={generalLedger}
        columns={columns()}
        rowKey="ID"
        loading={loading}
        emptyStateParams={{ description: "No GL codes yet" }}
        onChange={handleTableChange}
      />
    );
  };

  const isLoading = !data && loading;

  return (
    <Spin spinning={isLoading} bordered>
      {renderTable()}
    </Spin>
  );
};

GeneralLedgerList.fragments = {
  generalLedger: gql`
    fragment GeneralLedgerListFragment on GeneralLedgerAccount {
      ID
      code
      connectorID
      connectorTypeCode
      externalID
      name
    }
  `,
};

GeneralLedgerList.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  data: PropTypes.array,
  searchWord: PropTypes.string,
  onEdit: PropTypes.func,
};

export default GeneralLedgerList;
