import { useCallback, useContext } from "react";
import { useLocation } from "react-router-dom";

import AuthUserContext from "../../common/contexts/AuthUserContext";
import {
  useSideNavCollapsed,
  useToggleSideNavCollapsed,
} from "../../common/SideNavCollapseContext";
import useSideNavMenuData from "../../common/useSideNavMenuData";
import type { IAuthUser } from "../../features/UserInfoPage/types";
import { SPEND_CARDS, SPEND_EXPENSES } from "../../utils/routes";
import NavMenu from "../NavMenu";
import {
  CollapseContainer,
  Container,
  DoubleLeftIconStyled,
  HeadingStyled,
  SimpleBarStyled,
} from "./styles";
import type { SideNavProps, TitleProps } from "./types";

export const SIDE_NAV_CONTAINER_ID = "side-nav-container";

const Title = ({ label }: TitleProps) => {
  if (!label) {
    return null;
  }

  if (typeof label === "string") {
    return <HeadingStyled {...{ as: "h3" }}>{label}</HeadingStyled>;
  }

  return label;
};

const SideNav = ({ sideNavItem: { name, collapsable: itemCollapsable } }: SideNavProps) => {
  const location = useLocation();
  const authUser = useContext<IAuthUser>(AuthUserContext);
  const menuData = useSideNavMenuData();
  const collapsed = useSideNavCollapsed();
  const handleToggle = useToggleSideNavCollapsed();
  const isSpendPage =
    location.pathname.includes(SPEND_CARDS) || location.pathname === SPEND_EXPENSES;

  const handleMouseDown = useCallback((e) => {
    e.preventDefault();
  }, []);

  const collapsable =
    typeof itemCollapsable === "function" ? itemCollapsable(authUser.toggles) : itemCollapsable;

  return (
    <Container
      {...{
        id: SIDE_NAV_CONTAINER_ID,
        $collapsable: collapsable,
        $collapsed: collapsed,
        $overlayDropdown: isSpendPage,
      }}
    >
      <SimpleBarStyled
        {...{
          autoHide: false,
          $collapsable: collapsable,
          $collapsed: collapsed,
        }}
      >
        {collapsable && (
          <CollapseContainer
            {...{
              onClick: handleToggle,
              onMouseDown: handleMouseDown,
              $collapsed: collapsed,
            }}
          >
            <DoubleLeftIconStyled {...{ $collapsed: collapsed }} />
          </CollapseContainer>
        )}
        <Title {...{ label: name }} />
        <NavMenu
          {...{
            mode: "inline",
            menuData,
            location,
            collapsed,
            openDefault: undefined,
          }}
        />
      </SimpleBarStyled>
    </Container>
  );
};

export default SideNav;
