import PropTypes from "prop-types";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import styled from "styled-components";

const TBodyStyled = styled.tbody`
  user-select: none;
`;

const BodyDraggable = ({ children, onDragEnd, ...rest }) => (
  <DragDropContext onDragEnd={onDragEnd}>
    <Droppable droppableId="list">
      {(provided) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <TBodyStyled ref={provided.innerRef} {...provided.droppableProps} {...rest}>
          {children}
          {provided.placeholder}
        </TBodyStyled>
      )}
    </Droppable>
  </DragDropContext>
);

BodyDraggable.propTypes = {
  children: PropTypes.any,
  onDragEnd: PropTypes.func.isRequired,
};

export default BodyDraggable;
