import { useCallback, useContext, useState } from "react";
import { useMutation } from "react-apollo";

import { Button, Modal, Text } from "@core/ui";
import { Notifier } from "@core/ui-legacy";

import { ReactComponent as CalendarIcon } from "../../../assets/imgs/calendar.svg";
import { CardExpirationContext } from "../../../common/contexts/CardExpirationProvider";
import { GET_ME } from "../../../services/graphql/queries";
import { NOTIFICATIONS, VERIFY_ADDRESS } from "../cardsExpiration.utils";

export const SPAddressVerificationModal = () => {
  const {
    userAddress,
    shippingMonth,
    showAddressVerificationUI,
    setShowAddressVerificationUI,
    handleSPAddressVerification,
  } = useContext(CardExpirationContext);
  const [verifyAddress] = useMutation(VERIFY_ADDRESS, { refetchQueries: [{ query: GET_ME }] });

  const { address, address2, city, state, postalCode, country } = userAddress ?? {};

  const [isModalVisible, setIsModalVisible] = useState(Boolean(showAddressVerificationUI));

  const handleYes = async () => {
    try {
      await verifyAddress({
        variables: { input: { isVerified: true } },
      });
      setShowAddressVerificationUI(false);
      setIsModalVisible(false);
      Notifier.notification(NOTIFICATIONS.addressVerified);
    } catch {
      Notifier.notification(NOTIFICATIONS.addressVerificationFailed);
    }
  };

  const handleNo = useCallback(() => {
    setIsModalVisible(false);

    handleSPAddressVerification();
  }, [handleSPAddressVerification]);

  const handleClose = () => {
    setIsModalVisible(false);
  };

  return (
    <Modal
      open={isModalVisible}
      leadingIcon={<CalendarIcon />}
      iconPosition="center"
      closeModal={handleClose}
      showCloseButton
      actionButtons={[
        <Button
          key="No"
          variant="secondary"
          text="No, edit address"
          size="large"
          onClick={handleNo}
        />,
        <Button key="Yes" text="Yes, that’s correct" size="large" onClick={handleYes} />,
      ]}
      className="min-h-[22.45rem] min-w-[36.85rem] auto-rows-min content-end gap-4"
    >
      <div className="mb-[1rem] flex justify-center">
        <Text variant="h2" weight="bold">
          Verify your address
        </Text>
      </div>
      <div className="mb-2 text-center">
        <Text>{`Your cards are expiring soon and will ship out in ${shippingMonth}.`}</Text>
        <Text>Is this the correct address to send your replacement cards to?</Text>
      </div>
      <div className="mb-6 rounded-lg bg-neutral-100 p-4">
        {userAddress && (
          <>
            <Text>{address ?? address2}</Text>
            <Text>
              {city}, {state} {postalCode}, {country}
            </Text>
          </>
        )}
      </div>
    </Modal>
  );
};
