import { ConditionOperator, LogicalOperator } from "@core/rule-builder";

export const AUTH_DATE_LABEL = "Transaction Date";
export const POSTED_DATE_LABEL = "Settle Date";

export const MIME_TYPES = {
  pdf: "application/pdf",
  jpg: "image/jpeg",
  png: "image/png",
  gif: "image/gif",
  csv: "text/csv",
  xml: "text/xml",
  json: "application/json",
  image: "image/*",
  crt: "application/x-x509-ca-cert",
  pem: "application/x-pem-file",
  cer: "application/pkix-cert",
  cert: "application/x-x509-ca-cert",
};

export const SSO_IDP_PUBLIC_CERT_TYPES = {
  [MIME_TYPES.crt]: "crt",
  [MIME_TYPES.pem]: "pem",
  [MIME_TYPES.cer]: "cer",
  [MIME_TYPES.cert]: "cert",
};

// Bad UX with separating by mime types
// https://centerid.atlassian.net/browse/CV3-24289
export const RECEIPT_MIME_TYPES = {
  [MIME_TYPES.image]: [".png", ".gif", ".jpeg", ".jpg", ".pdf"],
  // [MIME_TYPES.pdf]: ['.pdf'],
};

export const CSV_MIME_TYPES = {
  [MIME_TYPES.csv]: [".csv"],
};

export const XML_MIME_TYPES = {
  [MIME_TYPES.xml]: [".xml"],
};

export const EMAIL_REMINDER_TYPES = {
  delinquent: "delinquent",
  pendingApproval: "pendingApproval",
  unsubmitted: "unsubmitted",
};

export const EXPORT_FIELD_UI_TYPES = {
  concatenated: "concatenated-field",
  emptyField: "empty-field",
};

export const FIELD_UI_TYPES = {
  dropdown: "input-dropdown-list",
  dropdownSearch: "input-dropdown-list-search",
  inputDate: "input-date-field",
  inputCurrency: "input-currency-field",
  inputDistance: "input-distance-field",
  inputNumber: "input-numeric-field",
  inputRoute: "input-route-field",
  inputText: "input-text-field",
  switch: "input-switch",
  toggle: "input-toggle",
  section: "section",
  splits: "splits-field",
  message: "message",
};

export const DYNAMIC_FORM_FIELDS_UI_TYPES = {
  avatar: "avatar-field",
  date: "date-field",
  dateRange: "date-range-field",
  datePicker: "date-picker-field",
  inputText: "input-text-field",
  inputEmail: "input-email-field",
  inputNumber: "input-numeric-field",
  inputCurrency: "input-currency-field",
  inputPhone: "input-phone-field",
  switch: "input-switch",
  dropdown: "input-dropdown-field",
  multiCheckbox: "input-multi-checkbox-field",
  checkbox: "input-checkbox-field",
  issueCard: "issue-card-field",
  cardType: "card-type-field",
  concatenate: "concatenate-field",
  section: "section",
  permission: "permission-field",
  button: "button-field",
  text: "text-field",
  banner: "banner-field",
  radio: "radio-field",
  scheduleInfo: "schedule-info-field",
};

export const HARDCODED_FIELD_UI_TYPES = {
  attendees: "attendees-field",
  bookingSection: "booking-section",
  currencyName: "currency-name-field",
  distanceMode: "distance-mode-field",
  merchant: "merchant-field",
  purpose: "purpose-field",
  receipt: "receipt-field",
  traveler: "traveler-field",
  trip: "trip-field",
  istrip: "istrip-field",
  forexSection: "forex-section",
};

export const LOGICAL_OPERATOR_LIST = [
  {
    value: LogicalOperator.All,
    label: "all",
  },
  {
    value: LogicalOperator.Any,
    label: "any",
  },
];

export const FIELD_CAN_BE_USED_ON_VALUES = {
  Export: "export",
  ExpenseDetails: "expenseDetails",
  ApprovalRule: "approvalRule",
  VisibilityRule: "visibilityRule",
  SearchCriteria: "searchCriteria",
  Insights: "insights",
  MappingConfiguration: "mappingConfiguration",
};

export const NESTED_FIELDS = ["fields.forex.originatingCurrency", "fields.travel.isTravelRelated"];

export const IMPORT_CSV_STUCK_ERROR = "Please try again in a few minutes";

export const AMOUNT_ACTION_OPTIONS = [
  {
    name: "Amount equal to zero",
    ID: ConditionOperator.EQ,
  },
  {
    name: "Amount greater than or equal to zero",
    ID: `${ConditionOperator.EQ},${ConditionOperator.GT}`,
  },
  {
    name: "Amount greater than zero",
    ID: ConditionOperator.GT,
  },
  {
    name: "Amount less than or equal to zero",
    ID: `${ConditionOperator.EQ},${ConditionOperator.LT}`,
  },
  {
    name: "Amount less than zero",
    ID: ConditionOperator.LT,
  },
];

export const EmailStatuses = {
  undeliverable: "undeliverable",
  deliverable: "deliverable",
  risky: "risky",
  unknown: "unknown",
};

export const EXPENSE_HUB_VIEW_NAME = {
  unsubmitted: "fm-unsubmitted",
  inReview: "fm-inreview",
  reviewed: "fm-reviewed",
  archived: "fm-archived",
  all: "fm-all",
};

export const DIALOG_KEY = {
  AddVisibilityRule: "addVisibilityRule",
  ReplaceVisibilityRule: "replaceVisibilityRule",
  DeleteVisibilityRules: "deleteVisibilityRules",
};

export const MILEAGE_MODES = {
  byRoute: "Calculate by route",
  manual: "Enter manually",
};

export const nextMileageMode = {
  [MILEAGE_MODES.byRoute]: MILEAGE_MODES.manual,
  [MILEAGE_MODES.manual]: MILEAGE_MODES.byRoute,
};

export const IMPORT_TYPES = {
  users: "Users",
  costCenters: "Cost Centers",
  expenseTypes: "Expense Types",
};

export const CSV_CHAR_ERROR = "CSV_NON_TRIMABLE_CHAR_AFTER_CLOSING_QUOTE";

export const DEFAULT_OPTION_KYE = "key";

export const CARD_REQUEST_STATUS = {
  IN_REVIEW: "inReview",
  APPROVED: "approved",
  DENIED: "denied",
};

export const GUEST_RESTRICTION = {
  noAccess: "noAccess",
  centerAndSpotnana: "centerAndSpotnana",
  center: "center",
};

export const SYNTHETIC_USER_ID = "synthetic-user-id";
export const GLOBAL_POLICY_RUN = "GLOBAL_POLICY_RUN_SERVICE";

export const CARD_TYPE = {
  PHYSICAL: "physical",
  VIRTUAL: "virtual",
  SINGLE_TRANSACTION: "singleTransaction",
} as const;

export const BookingPlatform = {
  spotnana: "Spotnana",
  external: "External",
};

export const DEFAULT_INDEX = 0;

export const SECTION_PROPERTY = {
  airfare: "fields.travel.booking.airfare",
  hotel: "fields.travel.booking.hotel",
  vehicleRental: "fields.travel.booking.vehicleRental",
};

export const NO_VALUE = "No value added";

export enum SortOrderType {
  asc = "asc",
  desc = "desc",
}

export const EMPLOYEE_COLUMN_SORT_SETTINGS = [
  {
    fieldId: "firstName",
    direction: SortOrderType.asc,
    name: "Employee first name A-Z",
  },
  {
    fieldId: "firstName",
    direction: SortOrderType.desc,
    name: "Employee first name Z-A",
  },
  {
    fieldId: "lastName",
    direction: SortOrderType.asc,
    name: "Employee last name A-Z",
  },
  {
    fieldId: "lastName",
    direction: SortOrderType.desc,
    name: "Employee last name Z-A",
  },
];

export const INSIGHT_DRILL_DOWN_ID = "insightDrillDownId";
