import {
  createContext,
  useCallback,
  useMemo,
  useState,
  type Dispatch,
  type ReactNode,
  type SetStateAction,
} from "react";
import { useQuery } from "@tanstack/react-query";
import dayjs, { type Dayjs } from "dayjs";

import type {
  CardPrograms,
  ShippingPreferences,
  Steps,
} from "../../features/cards-expiration/types";
import { useExpirationTimeline } from "../../features/cards-expiration/useExpirationTimeline";
import type { IAuthUser, ShippingAddress } from "../../features/UserInfoPage/types";
import { wrapRequest } from "../../services/rest/utils";
import type { BillingAddress } from "../types";

type CardExpirationContextValue = {
  currentPreference: ShippingPreferences;
  currentStep: Steps;
  handleSPAddressVerification: () => void;
  orgAddress: Omit<ShippingAddress, "address">;
  preferenceWasSelected: boolean;
  setCurrentStep: Dispatch<SetStateAction<Steps>>;
  setPreferenceEndDate: Dayjs;
  setShowAddressConfirmationModal: Dispatch<SetStateAction<boolean>>;
  setShowAddressVerificationUI: Dispatch<SetStateAction<boolean>>;
  setShowSPAddressUpdateSidebar: Dispatch<SetStateAction<boolean>>;
  setShowShippingPreferenceBanner: Dispatch<SetStateAction<boolean>>;
  setShowSidebar: Dispatch<SetStateAction<boolean>>;
  setShowThanksModal: Dispatch<SetStateAction<"hide" | "show" | "showWithText">>;
  showAddressConfirmationModal: boolean;
  showAddressVerificationFMIcon: boolean;
  showAddressVerificationUI: boolean;
  showSPAddressUpdateSidebar: boolean;
  showShippingPreferenceBanner: boolean;
  showSidebar: boolean;
  showThanksModal: "hide" | "show" | "showWithText";
  billingAddress?: BillingAddress;
  cardProgramId?: string;
  expirationYYMM?: string;
  hasPhysicalCards?: boolean;
  orgId?: string;
  shippingMonth?: string;
  userAddress?: BillingAddress;
};

type CardExpirationProviderProps = {
  children: ReactNode;
  me: IAuthUser;
};

export const EXP_MODAL_STORAGE_KEY = "expModal";

export const CardExpirationContext = createContext({} as CardExpirationContextValue);

export const CardExpirationProvider = ({ children, me }: CardExpirationProviderProps) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [currentStep, setCurrentStep] = useState<Steps>("selection");
  const [showAddressConfirmationModal, setShowAddressConfirmationModal] = useState(false);
  const [showThanksModal, setShowThanksModal] = useState<"hide" | "show" | "showWithText">("hide");

  const { expirationYYMM, ID: cardProgramId } = me?.organization.cardPrograms?.[0] ?? {};
  const [showSPAddressUpdateSidebar, setShowSPAddressUpdateSidebar] = useState(false);
  const hasPhysicalCards = me?.cardItems?.some((item) => item.cardFormat === "physical");
  const isAddressAlreadyVerified = me?.billingAddress?.isVerified || me?.billingAddress?.isChanged;
  const shippingMonth = dayjs(expirationYYMM, "YYMM")
    .utc()
    .endOf("month")
    .subtract(45, "day") // Physical cards are reissued ~60 days before expiration and shipped within 10-15 days.
    .format("MMMM");

  const { isWithinTimeline, endDate } = useExpirationTimeline({
    expirationYYMM,
    startDaysDefault: 150,
    endDaysDefault: 120,
    type: "ui-set-shipping-preference-selection",
  });

  const { data } = useQuery<CardPrograms[]>({
    queryKey: ["cardPrograms", cardProgramId],
    queryFn: () =>
      wrapRequest({ url: `/card-reissuance/v3.0?programid=${cardProgramId}`, method: "GET" }),
  });

  const SPAddressVerificationTimeline = useExpirationTimeline({
    type: "ui-approaching-new-expiration-spender",
    startDaysDefault: 120,
    endDaysDefault: 90,
    expirationYYMM,
  });

  const isVerificationAllowed = data?.[0]?.shippingPreference?.preference === "individual_verified";

  const [showAddressVerificationUI, setShowAddressVerificationUI] = useState(
    Boolean(
      // TODO: don't show for users created after prompt started
      isVerificationAllowed &&
        SPAddressVerificationTimeline.isWithinTimeline &&
        hasPhysicalCards &&
        !isAddressAlreadyVerified,
    ),
  );

  const isTheUserFM = me.roles.includes("FM");
  const preferenceWasSelected = Boolean(data?.[0]?.shippingPreference);
  const showExpirationWarnings = isWithinTimeline && !preferenceWasSelected && isTheUserFM;
  const modalStatusInStorage = localStorage.getItem(EXP_MODAL_STORAGE_KEY);
  const showAddressVerificationFMIcon =
    SPAddressVerificationTimeline.isWithinTimeline && isVerificationAllowed;

  if (!modalStatusInStorage && modalStatusInStorage !== `${showExpirationWarnings}`) {
    localStorage.setItem(EXP_MODAL_STORAGE_KEY, `${showExpirationWarnings}`);
  }

  const [showShippingPreferenceBanner, setShowShippingPreferenceBanner] = useState(
    showExpirationWarnings && modalStatusInStorage === "false",
  );

  const currentAddress = data?.[0]?.centralAddress || me.organization.shippingAddress;
  const currentPreference = data?.[0]?.shippingPreference?.preference || "individual_unverified";

  const handleSPAddressVerification = useCallback(() => {
    setShowSPAddressUpdateSidebar((prev) => !prev);
    setShowAddressVerificationUI((prev) => !prev);
  }, []);

  const value = useMemo(
    () => ({
      showSidebar,
      setShowSidebar,
      showShippingPreferenceBanner,
      setShowShippingPreferenceBanner,
      showThanksModal,
      setShowThanksModal,
      showAddressConfirmationModal,
      setShowAddressConfirmationModal,
      userAddress: me?.billingAddress,
      expirationYYMM,
      hasPhysicalCards,
      shippingMonth,
      showAddressVerificationUI,
      setShowAddressVerificationUI,
      orgAddress: currentAddress,
      setPreferenceEndDate: endDate,
      cardProgramId,
      orgId: me.organization.ID,
      preferenceWasSelected,
      currentStep,
      setCurrentStep,
      currentPreference,
      showSPAddressUpdateSidebar,
      handleSPAddressVerification,
      setShowSPAddressUpdateSidebar,
      showAddressVerificationFMIcon,
    }),
    [
      showSidebar,
      showShippingPreferenceBanner,
      showThanksModal,
      setShowThanksModal,
      showAddressConfirmationModal,
      setShowAddressConfirmationModal,
      me?.billingAddress,
      expirationYYMM,
      hasPhysicalCards,
      shippingMonth,
      showAddressVerificationUI,
      setShowAddressVerificationUI,
      endDate,
      cardProgramId,
      me.organization.ID,
      preferenceWasSelected,
      currentStep,
      setCurrentStep,
      currentAddress,
      currentPreference,
      showSPAddressUpdateSidebar,
      handleSPAddressVerification,
      setShowSPAddressUpdateSidebar,
      showAddressVerificationFMIcon,
    ],
  );

  return <CardExpirationContext.Provider value={value}>{children}</CardExpirationContext.Provider>;
};
