import { getMenuDataWithChildren } from "../../common/menu";
import type { INavMenuItem } from "./types";

export const isCommonRoute = () => {
  const isMatchCurrentRoute = (menuItem: INavMenuItem) => {
    if (menuItem.path === window.location.pathname) return true;
    if (menuItem.children) {
      return menuItem.children.some(isMatchCurrentRoute);
    }
    return false;
  };
  const menuData = getMenuDataWithChildren();
  return menuData.some(isMatchCurrentRoute);
};

export const disableEvents = (e) => {
  if (e?.preventDefault) {
    e.preventDefault();
  }
  if (e?.stopPropagation) {
    e.stopPropagation();
  }
};

export const reloadCommonRoute = () => {
  if (isCommonRoute()) {
    window.location.reload();
  } else {
    window.location.replace("/");
  }
};
