import PropTypes from "prop-types";
import styled from "styled-components";

const ActionsStyled = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  margin: 0;
`;

const Action = styled.li`
  padding: 0.125rem 0;
  padding-right: ${({ gutter }) => gutter || "1rem"};

  :last-child {
    padding-right: 0 !important;
  }
`;

const Actions = ({ items, gutter, ...otherProps }) => {
  const renderActions = () => {
    if (items && items.length > 0) {
      // eslint-disable-next-line function-name/starts-with-verb
      const actionsContentItem = (action, i) => {
        if (!action) return null;
        return (
          <Action key={action.key || `item-action-${i}`} gutter={gutter}>
            {action}
          </Action>
        );
      };
      return items.map((action, i) => actionsContentItem(action, i));
    }
    return null;
  };
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <ActionsStyled {...otherProps}>{renderActions()}</ActionsStyled>;
};

Actions.propTypes = {
  items: PropTypes.array,
  gutter: PropTypes.string,
};

export default Actions;
