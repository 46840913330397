import styled from "styled-components";
import media from "styled-media-query";

import { Actions, Row } from "@core/ui-legacy";
import Colors from "@core/ui-legacy/themes/colors";

export const RowStyled = styled(Row)`
  max-width: 31.25rem !important;
  margin-bottom: 1.25rem;
  ${({ decorator }) => decorator ?? ""};
`;

export const ActionsWrapper = styled.div`
  ${media.lessThan("medium")`
    position: fixed;
    bottom: 0;
    right: 0;
    background: ${Colors.white};
    padding: 0.625rem 3%;
    border-top: 1px solid ${Colors.lunar_gray};
    width: 100%;
  `};
`;

export const ActionsStyled = styled(Actions)`
  ${media.lessThan("medium")`
    position: relative;
    float: right;
  `};
`;

export const UnassignedOption = styled.span`
  font-style: italic;
`;
