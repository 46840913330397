import { CookieStorage, isSupported, MemoryStorage } from "local-storage-fallback";

let storage: Storage | StorageFallback;
let isSafariOrMobileBrowser = false;
const { userAgent, maxTouchPoints } = navigator;

// MDN discourages userAgent due to potential user spoofing, so fall back to maxTouchPoints for mobile if not found
// If changing anything here, please also update the same logic in
// se-aws-infrastructure/global/CloudFrontLambdaEdge/cloudfront-lambda-edge/index.js
// and remember to add the step of deploying CloudFrontLambdaEdge to the release deployment meeting doc
// not doing this, will almost surely break sso
if (
  userAgent &&
  (userAgent.includes("iPhone") || userAgent.includes("Mobile") || userAgent.includes("Safari")) &&
  !userAgent.includes("Chrome")
) {
  isSafariOrMobileBrowser = true;
} else if (maxTouchPoints && maxTouchPoints > 1) {
  isSafariOrMobileBrowser = true;
}

// override storage to local for only Safari to enable iOS mobile functionality, session for other browsers
if (isSafariOrMobileBrowser) {
  // If changing anything here, please also update the same logic in
  // se-aws-infrastructure/global/CloudFrontLambdaEdge/cloudfront-lambda-edge/index.js
  // and remember to add the step of deploying CloudFrontLambdaEdge to the release deployment meeting doc
  // not doing this, will almost surely break sso
  storage = window.localStorage;
} else if (isSupported("sessionStorage")) {
  storage = window.sessionStorage;
} else if (isSupported("localStorage")) {
  storage = window.localStorage;
} else if (isSupported("cookieStorage")) {
  storage = new CookieStorage();
} else {
  storage = new MemoryStorage();
}

export default storage;
