import { useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "react-apollo";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { Button } from "@core/ui-legacy";

import { FRAUD_ALERT } from "../../services/graphql/mutations";
import { GET_ME_CARDS_AND_FRAUD } from "../../services/graphql/queries";
import { ACCOUNT_INFO_SETTINGS } from "../../utils/routes";

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 1.625rem;
  padding-bottom: 1.5rem;

  button:not(:last-child) {
    margin-right: 0.5rem;
  }

  button {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
`;
const ModalDescription = styled.div`
  padding-bottom: 0.5rem;
  max-width: 100%;
`;
const Aligner = styled.div`
  display: flex;
  justify-content: center;
  padding: 4.625rem;
`;

const ActiveFraud = ({ phone, onCancel, fraudEnrolled }) => {
  const [buttonName, setButtonName] = useState("");
  const history = useHistory();

  const [fraudAlert, { loading: isFraudLoading }] = useMutation(FRAUD_ALERT, {
    awaitRefetchQueries: false,
    refetchQueries: [
      {
        query: GET_ME_CARDS_AND_FRAUD,
        variables: { limit: 25 },
      },
    ],
  });

  const parse = (value) => value.replace(/\(|\)|_|\+|\s|-/g, "");
  const maskPhone = (phoneNumber) => {
    if (phoneNumber && !isNaN(parse(phoneNumber))) {
      const arr = phoneNumber.split("");
      arr.splice(0, 0, "(");
      arr.splice(4, 0, ")");
      arr.splice(5, 0, " ");
      arr.splice(9, 0, "-");
      return `+1 ${arr.join("")}`;
    }
    return "No number on profile.";
  };

  const onSettingsClick = async () => {
    setButtonName("settingButton");
    if (!fraudEnrolled) {
      await fraudAlert({
        variables: { enable: true },
      });
    }
    onCancel();
    history.push(ACCOUNT_INFO_SETTINGS);
  };

  const onCancelClick = async () => {
    setButtonName("cancelButton");
    if (!fraudEnrolled) {
      await fraudAlert({
        variables: { enable: true },
      });
    }
    onCancel();
  };

  return (
    <>
      <div className="ant-modal-header">
        <div className="ant-modal-title">Enable fraud alerts</div>
      </div>
      <ModalDescription>
        To finalize your enrollment in fraud alerts, text <b>“IN”</b>to <b>57911</b> with the number
        below. If this is not the number you want to receive fraud alerts with, please go into your
        Settings to change your number.
      </ModalDescription>
      <Aligner>{maskPhone(phone)}</Aligner>
      <ActionsWrapper>
        <Button
          id="setting-dialog-btn"
          key="setting-dialog-btn"
          data-testid="setting-dialog-btn"
          type="cancel"
          size="default"
          onClick={onSettingsClick}
          loading={isFraudLoading && buttonName === "settingButton"}
        >
          Go to Settings
        </Button>
        <Button
          id="finish-dialog-btn"
          key="finish-dialog-btn"
          data-testid="finish-dialog-btn"
          type="primary"
          size="default"
          htmlType="submit"
          onClick={onCancelClick}
          loading={isFraudLoading && buttonName === "cancelButton"}
        >
          Finish
        </Button>
      </ActionsWrapper>
    </>
  );
};

ActiveFraud.propTypes = {
  phone: PropTypes.string,
  onCancel: PropTypes.func,
  fraudEnrolled: PropTypes.bool,
};

export default ActiveFraud;
