import { Text } from "@core/ui";

export const Unlink = () => {
  return (
    <Text>
      Please note that the verification process will automatically unlink this field if the
      cardholder provides a new address and this action cannot be undone. You will have to manually
      relink the field afterwards.
    </Text>
  );
};
