import { ApolloLink, Observable } from "apollo-link";
import moment from "moment";

export class OperationQueuing {
  constructor() {
    this.queuedRequests = [];
    this.subscriptions = {};
  }

  enqueueRequest = (request) => {
    const requestCopy = { ...request };

    requestCopy.observable =
      requestCopy.observable ||
      new Observable((observer) => {
        this.queuedRequests.push(requestCopy);

        if (requestCopy.subscriber === undefined) {
          requestCopy.subscriber = {};
        }

        requestCopy.subscriber.next = requestCopy.next || observer.next.bind(observer);
        requestCopy.subscriber.error = requestCopy.error || observer.error.bind(observer);
        requestCopy.subscriber.complete = requestCopy.complete || observer.complete.bind(observer);
      });

    return requestCopy.observable;
  };

  consumeQueue = () => {
    this.queuedRequests.forEach((request) => {
      const key = request.operation.toKey();
      this.subscriptions[key] = request.forward(request.operation).subscribe(request.subscriber);

      return () => {
        this.subscriptions[key].unsubscribe();
      };
    });

    this.queuedRequests = [];
  };
}

const throwServerError = (response, result, message) => {
  const error = new Error(message);

  error.response = response;
  error.statusCode = response.status;
  error.result = result;

  throw error;
};

const parseAndCheckResponse = () => (response) =>
  response
    .text()
    .then((bodyText) => {
      if (typeof bodyText !== "string" || !bodyText.length) {
        // return empty body immediately
        return bodyText || "";
      }

      try {
        return JSON.parse(bodyText);
      } catch (err) {
        const parseError = err;
        parseError.response = response;
        parseError.statusCode = response.status;
        parseError.bodyText = bodyText;
        return Promise.reject(parseError);
      }
    })
    .then((parsedBody) => {
      if (response.status >= 300) {
        // Network error
        throwServerError(
          response,
          parsedBody,
          `Response not successful: Received status code ${response.status}`,
        );
      }

      return parsedBody;
    });

export class TokenRefreshLink extends ApolloLink {
  constructor(params) {
    super();

    this.accessTokenField = params?.accessTokenField || "access_token";
    this.fetching = false;
    this.isTokenValidOrUndefined = params.isTokenValidOrUndefined;
    this.fetchAccessToken = params.fetchAccessToken;
    this.handleFetch = params.handleFetch;
    this.handleResponse = params.handleResponse || parseAndCheckResponse;
    this.handleError =
      typeof params.handleError === "function" ? params.handleError : (err) => console.error(err);

    this.queue = new OperationQueuing();
  }

  request(operation, forward) {
    if (typeof forward !== "function") {
      throw new Error(
        "[Token Refresh Link]: Token Refresh Link is non-terminating link and should not be the last in the composed chain",
      );
    }
    // If token does not exists, which could means that this is a not registered
    // user request, or if it is does not expired -- act as always
    if (this.isTokenValidOrUndefined()) {
      return forward(operation);
    }

    if (!this.fetching) {
      this.fetching = true;
      this.fetchAccessToken()
        .then(this.handleResponse(operation, this.accessTokenField))
        .then((body) => {
          body.expires_on = moment.utc().add(body.expires_in, "seconds").format();
          return body;
        })
        .then(this.handleFetch)
        .then(() => {
          this.fetching = false;
          this.queue.consumeQueue();
        })
        .catch(this.handleError);
    }

    return this.queue.enqueueRequest({
      operation,
      forward,
    });
  }

  /**
   * An attempt to extract token from body.data. This allows us to use apollo query
   * for auth token refreshing
   * @param body {Object} response body
   * @return {string} access token
   */
  extractToken = (body) => {
    if (body.data) {
      return body.data[this.accessTokenField];
    }
    return body[this.accessTokenField];
  };
}
