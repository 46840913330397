import { useIdentifyWithFeatureFlagsProvider } from "@core/feature-flags/clients";

import type { IAuthUser } from "../features/UserInfoPage/types";

type IdentifyFeatureFlagsProviderProps = {
  me?: IAuthUser;
};

export const IdentifyFeatureFlagsProvider = ({ me }: IdentifyFeatureFlagsProviderProps) => {
  useIdentifyWithFeatureFlagsProvider({
    userId: me?.ID ?? "",
    userName: me?.fullName ?? "",
    orgName: me?.organization.name ?? "",
    orgId: me?.organization.ID ?? "",
    email: me?.loginID ?? "",
    hasTravel: !!me?.organization?.partnerFields?.spotnana?.ssoLink,
  });
  return null;
};
