import _forEach from "lodash/forEach";

export { userHasFeatures } from "../userHasFeatures";

export const urlToList = (url) => {
  if (url === "/") return ["/"];
  const urllist = url.split("/").filter(Boolean);
  return urllist.map((urlItem, index) => `/${urllist.slice(0, index + 1).join("/")}`);
};

export const constructFilterQuery = (filters) => {
  const q = [];
  const constructField = (f, o, v) => `${f}[${o}]:${v}`;
  _forEach(filters, (fVal, field) => {
    const { value, operator } = fVal;
    let val;
    if (operator.includes("range")) {
      val = Array.isArray(value) ? value.join(",") : value;
    } else {
      val = Array.isArray(value) ? value.map((item) => `"${item}"`).join(",") : value;
    }
    q.push(constructField(field, operator, val));
  });
  return q.length > 0 ? q.join(";") : undefined;
};
