import type React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAuth, useAuthActions } from "@frontegg/react";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { Notifier, Spin } from "@core/ui-legacy";

import { reloadCommonRoute } from "../components/OrganizationSwitch/utils";
import API, { cacheToken } from "../services/rest/api";

type IProps = {
  children: React.ReactChildren;
  isAuthenticated: boolean;
};

const SpinStyled = styled(Spin)`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  min-height: 100%;
`;

const OrgSwitcherInterceptor = ({ children, isAuthenticated }: IProps) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const orgID = useMemo<string | undefined>(() => {
    if (!isAuthenticated) return undefined;
    const queryParams = queryString.parse(window.location.search);
    const { orgid } = queryParams;

    return orgid as string;
  }, [isAuthenticated]);

  const { switchTenant } = useAuthActions();
  const { isAuthenticated: isAuthenticatedViaFrontegg } = useAuth();

  const [isError, setIsError] = useState(false);

  const handleFronteggTenantSwitch = useCallback(
    (tenantID: string) => {
      setIsLoading(true);
      switchTenant({
        tenantId: tenantID,
        callback: (_data, error) => {
          history.replace({ search: "" });

          if (!error) {
            return;
          }

          setIsLoading(false);
          setIsError(true);
          Notifier.notification({
            type: "error",
            message: "Switch organization",
            description: `Error while switching organization.`,
          });
        },
      });
    },
    [history, switchTenant],
  );

  useEffect(() => {
    if (isAuthenticatedViaFrontegg && orgID && !isError) {
      handleFronteggTenantSwitch(orgID);

      return;
    }

    const allowSwitchOrg = isAuthenticated && orgID && !isError;
    if (allowSwitchOrg) {
      setIsLoading(true);
      API.switchUserOrg(orgID)
        .then((response) => {
          if (!response.access_token && response.errorMessage) {
            throw new Error(response.errorMessage);
          }
          cacheToken(response);
          setIsLoading(false);
          history.replace({ search: "" });
          reloadCommonRoute();
        })
        .catch(() => {
          setIsError(true);
          setIsLoading(false);
          history.replace({ search: "" });
          Notifier.notification({
            type: "error",
            message: "Switch organization",
            description: `Error while switching organization.`,
          });
        });
    }
  }, [
    isAuthenticated,
    history,
    orgID,
    isError,
    isAuthenticatedViaFrontegg,
    handleFronteggTenantSwitch,
  ]);

  if (isLoading) return <SpinStyled spinning size="large" />;

  return <>{children}</>;
};

export default OrgSwitcherInterceptor;
