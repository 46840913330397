import { useCallback, type Dispatch, type SetStateAction } from "react";

import { Button, Separator, Text } from "@core/ui";

import type { AddressInput, ShippingPreferences, Steps } from "../../types";

type PreferenceReviewProps = {
  address: AddressInput;
  setStep: Dispatch<SetStateAction<Steps>>;
  shippingPreference: ShippingPreferences;
};

export const PreferenceReview = ({
  shippingPreference,
  address: { line1, line2, city, state, zipCode },
  setStep,
}: PreferenceReviewProps) => {
  const handleEdit = useCallback(() => {
    setStep("selection");
  }, [setStep]);

  const handleSupportLink = useCallback(() => {
    window.open("https://getcenter.my.site.com/s/?language=en_US", "_blank");
  }, []);

  return (
    <>
      <div className="grid grid-flow-col grid-cols-[auto,auto] grid-rows-[auto,auto] gap-y-4 rounded-lg border-[1.5px] border-solid border-[#F0F0F0] bg-[#F8F8F8] px-4 py-3">
        <div>
          <Text variant="medium" weight="bold" className="text-[#606060]">
            Shipping Preference
          </Text>
          <Text>
            {shippingPreference === "central"
              ? "Ship in bulk to one address"
              : "Mail cards individually to cardholder"}
          </Text>
        </div>
        <div>
          <Text variant="medium" weight="bold" className="text-[#606060]">
            {shippingPreference === "central" ? "Address" : "Allow spenders to verify address?"}
          </Text>
          {shippingPreference === "central" ? (
            <>
              <Text>{line1}</Text>
              {line2 ? <Text>{line2}</Text> : null}
              <Text>
                {city}, {state} {zipCode}
              </Text>
            </>
          ) : (
            <Text>{shippingPreference === "individual_verified" ? "Yes" : "No"}</Text>
          )}
        </div>
        <div className="row-span-2 content-center justify-self-end">
          <Button text="Edit" variant="ghost" onClick={handleEdit} />
        </div>
      </div>
      <Text variant="h2" weight="bold" className="mb-4 mt-6">
        What&rsquo;s next?
      </Text>
      <div className="grid grid-cols-[auto,auto] grid-rows-[auto,auto,auto] gap-x-6 gap-y-4">
        <Text weight="bold" className="text-[#0e4c81]">
          1
        </Text>
        <Text className="text-[#606060]">
          If you haven&rsquo;t already, please close any cards that you do not want renewed when
          they expire.
        </Text>
        <Separator className="col-span-2" color="neutrals-200" />
        <Text weight="bold" className="text-[#0e4c81]">
          2
        </Text>
        <Text className="text-[#606060]">We will notify you when cards will be shipping out.</Text>
      </div>
      <Text weight="bold" className="mb-1 mt-10">
        Need help or have feedback?
      </Text>
      <Button variant="link" text="Get in touch with us" onClick={handleSupportLink} />
    </>
  );
};
