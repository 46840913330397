import _get from "lodash/get";
import memoize from "memoize-one";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Avatar } from "@core/ui-legacy";
import Colors from "@core/ui-legacy/themes/colors";
import { styleConstructor } from "@core/ui-legacy/utils";

import CreditCardIcon from "../../assets/imgs/credit-card-icon.svg";
import { AppNotificationSubjectCodes } from "../../utils/appNotifications";
import { cardStatusCodesEnums } from "../../utils/cards";

const getStatusColor = (statusCode) => {
  let color = Colors.midnight;
  switch (statusCode) {
    case cardStatusCodesEnums.IssuedInactive:
      color = Colors.link_blue;
      break;
    case cardStatusCodesEnums.Open:
      color = Colors.success_green;
      break;
    case cardStatusCodesEnums.Closed:
      color = Colors.monumental;
      break;
    case cardStatusCodesEnums.Inactive:
    case cardStatusCodesEnums.Lost:
    case cardStatusCodesEnums.Stolen:
      color = Colors.danger_red;
      break;
    default:
      break;
  }
  return color;
};

const Wrapper = styled.span`
  img {
    object-fit: none !important;
  }

  .anticon {
    vertical-align: 0.1rem !important;
  }

  .ant-avatar {
    ${({ statusCode }) =>
      styleConstructor({
        border: `1px solid ${getStatusColor(statusCode)}`,
      })};
  }
`;

const NotificationAvatar = ({ subjectCode, size, meta }) => {
  const metaJSON = memoize((m) => JSON.parse(m))(meta);

  const getAvatarIconParams = () => {
    const params = { src: null, icon: null };
    switch (subjectCode) {
      case AppNotificationSubjectCodes.CardStatusChanged:
      case AppNotificationSubjectCodes.ReplacementCardIssued:
        params.src = CreditCardIcon;
        break;
      default:
        params.icon = "notification";
        break;
    }
    return params;
  };

  return (
    <Wrapper statusCode={_get(metaJSON, "statusCode")}>
      <Avatar size={size} {...getAvatarIconParams()} />
    </Wrapper>
  );
};

NotificationAvatar.propTypes = {
  meta: PropTypes.string,
  subjectCode: PropTypes.string,
  size: PropTypes.any,
};

NotificationAvatar.defaultProps = {
  size: 39,
};

export default NotificationAvatar;
