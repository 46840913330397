import { Tabs as AntTabs } from "antd";
import styled from "styled-components";

import Colors from "../../themes/colors";

const Tabs = styled(AntTabs)`
  .ant-tabs-bar {
    margin: 0 0 1.5rem !important;
  }

  .ant-tabs-tab {
    font-size: 1rem;
    font-weight: 600 !important;
    padding: 0 !important;
  }

  .ant-tabs-nav {
    color: ${Colors.monumental};
  }

  .ant-tabs-tab-active {
    color: ${Colors.secondary} !important;
    padding-bottom: 0.5rem !important;
  }

  .ant-tabs-ink-bar {
    background-color: ${Colors.secondary};
  }

  .ant-list-item-meta-description {
    color: ${Colors.monumental} !important;
  }
`;

Tabs.defaultProps = {
  tabBarGutter: 30,
};

export default Tabs;
