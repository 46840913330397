import styled from "styled-components";

import Colors from "../../themes/colors";
import Icon from "../Icon";

export const Container = styled.div.attrs(() => ({
  className: "ant-notification-notice",
}))`
  width: 100%;
  position: relative;
  margin-bottom: unset;
  padding: unset;
  padding: ${(props) => (props.$isPlain ? "0" : "0.25rem")} 0 0 0.25rem;
  overflow: unset;
  line-height: 1.5;
  background: #fff;
  border-radius: 1px;
  box-shadow: unset;
  display: flex;
`;

export const InnerContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const Content = styled.div.attrs(() => ({
  className: "ant-notification-notice-content",
}))`
  width: 100%;
  position: unset;
  margin-bottom: unset;
  padding: unset;
  overflow: unset;
  line-height: unset;
  background: unset;
  border-radius: unset;
  box-shadow: unset;
`;

export const Message = styled.div.attrs(() => ({
  className: "ant-notification-notice-message",
}))`
  display: inline-block;
  margin-bottom: unset;
  color: ${Colors.text};
  line-height: 24px;
  font-weight: 700;
  font-size: 0.875rem;
`;

export const Description = styled.div.attrs(() => ({
  className: "ant-notification-notice-description",
}))`
  font-size: 16px;
`;

export const CloseIcon = styled.a.attrs(() => ({
  className: "ant-notification-notice-close",
}))`
  cursor: pointer;
  position: unset;
  color: rgb(0 0 0 / 45%);
  outline: none;
`;

export const WarningIcon = styled(Icon).attrs(() => ({
  type: "warning",
}))`
  svg {
    fill: #fdb913;
  }
`;

export const InfoIcon = styled(Icon).attrs(() => ({
  type: "info-circle",
}))`
  svg {
    fill: ${Colors.Icon};
  }
`;

export const IconWrapper = styled.span.attrs(() => ({
  className: "ant-notification-close-x",
}))``;

export const Button = styled.span.attrs(() => ({
  className: "ant-notification-notice-btn",
}))`
  margin: unset;
  position: relative;
  left: 1rem;
  align-self: flex-end;
`;
