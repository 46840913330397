import { CENTER_BRANDING_DEFAULTS } from "../../centerBrandingDefaults";
import { getSubdomainFromUrl } from "../../utils";
import { fetchWithToken } from "./api";

export const BRANDING_ASSET_TYPE = { LOGO: "LOGO", FAVICON: "FAVICON" };
const METHODS = { POST: "POST", PUT: "PUT" };
const VERSION = "2023-07-14";

/**
 * Creates an error message object with an error and status property.
 * @param {string} err - The error message.
 * @param {number} status - The HTTP status code.
 * @returns {object} - The error message object.
 */
const errorMessage = (err, status) => ({ errorMessage: err, status });

/**
 * Creates a default request object with the given HTTP method and data.
 * @param {('GET'|'POST'|'PUT'|'DELETE')} method - The HTTP method (e.g., 'GET', 'POST', 'PUT', 'DELETE').
 * @param {object} data - The data to be sent with the request.
 * @returns {object} - The default request object.
 */
const defaultRequest = (method, data) => ({
  method,
  headers: { "Content-Type": "application/json", "Center-Version": VERSION },
  body: JSON.stringify(data),
});

/**
 * Constructs the URL for the given resource in the developer services API.
 * @param {string} resource - The resource path.
 * @returns {string} - The constructed URL.
 */
const developerServicesURL = (resource) => `${import.meta.env.REACT_APP_DEVELOPER_API}/${resource}`;

/**
 * Retrieves the organization branding data based on the subdomain.
 * @returns {Promise<object>} - A promise that resolves to the organization branding data.
 */
export const organizationBrandingGetBySubdomain = async () => {
  const subdomain = getSubdomainFromUrl();
  try {
    const response = await fetch(
      developerServicesURL(`organization/branding?subdomain=${subdomain}`),
      { cache: "no-store", headers: { "Center-Version": VERSION } },
    );

    if (!response.ok) {
      const { error } = await response.json();
      return Promise.reject(errorMessage(error ?? "request failed", response.status));
    }

    return await response.json();
  } catch (error) {
    const err = `Request failed with error: ${error.message}`;
    throw new Error(err);
  }
};

/**
 * Generates a pre-signed URL for uploading an asset to organization branding.
 * @param {string} organizationId - The organization ID.
 * @param {string} assetType - The type of asset to be uploaded.
 * @returns {Promise<object>} - A promise that resolves to the generated upload URL.
 */
export const organizationBrandingGenerateUploadURL = async (organizationId, assetType) => {
  if (!organizationId || !assetType)
    return Promise.reject(new Error("Unable to complete request due to missing requirements"));

  try {
    const response = await fetchWithToken(
      developerServicesURL(`organization/${organizationId}/generate-upload-url`),
      defaultRequest(METHODS.POST, { type: assetType }),
    );
    if (!response.ok) {
      const { error } = await response.json();
      return Promise.reject(errorMessage(error, response.status));
    }

    return response.json();
  } catch (error) {
    const err = `request failed with error: ${error.message}`;
    return Promise.reject(new Error(err));
  }
};

/**
 * Upserts the organization branding data.
 * @param {object} organizationBrandingData - The organization branding data to be upserted.
 * @returns {Promise<object>} - A promise that resolves to the upserted organization branding data.
 */
export const organizationBrandingUpsert = async (organizationBrandingData) => {
  try {
    if (organizationBrandingData.logo) {
      await organizationBrandingUploadAsset(
        organizationBrandingData.presignedURLs?.logoPresignedURL?.presignedUrl,
        organizationBrandingData.logo,
      );
    }

    if (organizationBrandingData.favicon) {
      await organizationBrandingUploadAsset(
        organizationBrandingData.presignedURLs?.faviconPresignedURL?.presignedUrl,
        organizationBrandingData.favicon,
      );
    }

    const response = await fetchWithToken(
      developerServicesURL(`organization/${organizationBrandingData.id}/branding`),
      defaultRequest(
        METHODS.PUT,
        organizationBrandingUpsertRequestMapper(organizationBrandingData),
      ),
    );
    if (!response.ok) {
      const { error } = await response.json();
      return Promise.reject(errorMessage(error, response.status));
    }
    return await response.json();
  } catch (error) {
    const err = `request failed with error: ${error.message}`;
    return Promise.reject(new Error(err));
  }
};

/**
 * Uploads an asset to the organization branding using a pre-signed URL.
 * @param {string} presignedURL - The pre-signed URL for the asset upload.
 * @param {object} fileData - The file data to be uploaded.
 * @returns {Promise<Response>} - A promise that resolves to the response of the upload request.
 */
export const organizationBrandingUploadAsset = async (presignedURL, fileData) => {
  try {
    const uploadRequest = {
      method: METHODS.PUT,
      headers: { "Content-Type": fileData.type },
      body: fileData.originFileObj,
    };

    const response = await fetch(presignedURL, uploadRequest);
    if (!response.ok) {
      const { error } = await response.xml();
      return Promise.reject(errorMessage(error, response.status));
    }
    return response;
  } catch (error) {
    const err = `request failed with error: ${error.message}`;
    return Promise.reject(new Error(err));
  }
};

/**
 * Maps the organization branding data to the required format for upserting.
 * @param {object} data - The organization branding data to be mapped.
 * @returns {object} - The mapped organization branding data.
 */
const organizationBrandingUpsertRequestMapper = (data) => ({
  id: data?.id,
  name: data?.siteTitle,
  primaryColor: data?.primaryColor,
  secondaryColor: data?.secondaryColor,
  textColor: data?.textColor,
  linkColor: data?.linkColor,
  navLinks: data?.links.map((link) => ({
    name: link.name,
    url: link.path,
    navLinkType: link.type,
    target: link.target,
  })),
  pageTitle: data?.siteTitle,
  subdomain: data?.subdomain,
  logoLinkAddress: data?.logoLink || CENTER_BRANDING_DEFAULTS.logoLink,
  navigationBarColors: {
    backgroundColor: data?.backgroundColor,
    activeColor: data?.activeColor,
    textElementColor: data?.textElementColor,
  },
  font: {
    url: data?.font?.url,
    name: data?.font?.name,
  },
  logo: data.logo ? data.presignedURLs?.logoPresignedURL?.assetUrl : data.currentLogo,
  favicon: data.favicon ? data.presignedURLs?.faviconPresignedURL?.assetUrl : data.currentFavicon,
});
