import { createPortal } from "react-dom";
import styled from "styled-components";

import Spin from "../Spin";

const StyledSpin = styled(Spin)`
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999 !important;
`;

// eslint-disable-next-line react/jsx-props-no-spreading
const FullScreenSpin = ({ ...rest }) => createPortal(<StyledSpin {...rest} />, document.body);

export default FullScreenSpin;
