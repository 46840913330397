import type { Dispatch, SetStateAction } from "react";

import { Button, Modal, Text } from "@core/ui";

type InvalidAddressModalProps = {
  city: string;
  country: string | null;
  handleVerifyInvalidAddress: () => Promise<void>;
  line1: string;
  setShowInvalidAddressModal: Dispatch<SetStateAction<boolean>>;
  showInvalidAddressModal: boolean;
  state: string;
  zipCode: string;
  line2?: string;
};

export const InvalidAddressModal = ({
  line1,
  line2,
  city,
  zipCode,
  state,
  country,
  setShowInvalidAddressModal,
  showInvalidAddressModal,
  handleVerifyInvalidAddress,
}: InvalidAddressModalProps) => {
  return (
    <Modal
      open={showInvalidAddressModal}
      title="Is your address correct?"
      subtitle="We can't verify tour address. Please make sure your address is correct."
      closeModal={() => {}} // User needs to take action
      actionButtons={[
        <Button
          key="Close"
          variant="secondary"
          text="Close"
          size="large"
          onClick={() => setShowInvalidAddressModal(false)}
        />,
        <Button
          key="Confirm"
          text="Yes, my address is correct"
          size="large"
          onClick={handleVerifyInvalidAddress}
        />,
      ]}
      className="min-h-[16rem] min-w-[34.3rem]"
    >
      <Text>{line1}</Text>
      {line2 && <Text>{line2}</Text>}
      <Text>
        {city}, {state}, {zipCode}, {country}
      </Text>
    </Modal>
  );
};
