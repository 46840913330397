import styled from "styled-components";

import { Spin } from "@core/ui-legacy";
import Colors from "@core/ui-legacy/themes/colors";

import { ReactComponent as asyncProgressSpin } from "../../assets/imgs/async-progress-spin.svg";

const SimpleProgressBarStyled = styled.div`
  background: ${Colors.fog_gray};
  border-radius: 0.25rem;
  box-shadow: 0 0 0.25rem 0 rgb(0 0 0 / 25%);
  font-size: 1rem;
  color: ${Colors.classic_blue};
  position: fixed;
  left: 1.25rem;
  bottom: 5rem;
`;

const DialogStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.6rem !important;
`;

const SpinStyled = styled(Spin)`
  background-color: transparent !important;
  margin-right: 0.5rem;

  svg {
    width: 0.75rem;
    height: 0.75rem;
  }
`;

type SimpleProgressBarProps = {
  message: string;
};

const SimpleProgressBar = ({ message }: SimpleProgressBarProps) => (
  <SimpleProgressBarStyled>
    <DialogStyled>
      <SpinStyled {...{ loadingOutlined: asyncProgressSpin }} />
      {message}
    </DialogStyled>
  </SimpleProgressBarStyled>
);

export default SimpleProgressBar;
