/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable function-name/starts-with-verb */
import { memo } from "react";
import { Table as AntTable } from "antd";
import PropTypes from "prop-types";
import styled from "styled-components";
import media from "styled-media-query";

import { ReactComponent as DraggableSvg } from "../../assets/imgs/draggable.svg";
import { withEmptyState } from "../../common/hoc";
import Colors from "../../themes/colors";
import { generateRandomString } from "../../utils";
import Button from "../Button";
import Heading from "../Heading";
import BodyDraggable from "./BodyDraggable";
import BodyPure from "./BodyPure";
import CellDraggable from "./CellDraggable";
import CellPure from "./CellPure";
import ExpandIcon from "./ExpandIcon";
import HeaderWithOrderOptions from "./HeaderWithOrderOptions";
import RowDraggable from "./RowDraggable";
import RowPure from "./RowPure";
import TablePure from "./TablePure";

const ButtonStyled = styled(Button)`
  margin: auto !important;
  display: block !important;
`;

const TableStyled = styled(AntTable)`
  .ant-table {
    font-size: 1rem;
  }

  .ant-table-content {
    padding: 0 2px;
    margin: 0 -2px;
  }

  .ant-table-body {
    .ant-table-expanded-row,
    .ant-table-expanded-row:hover,
    .ant-table-expanded-row:hover td {
      background: transparent !important;
    }

    /* Removes gray background when column is sorted */
    .ant-table-row:not(:hover) {
      .ant-table-column-sort {
        background: transparent !important;
      }
    }
  }

  .ant-table-footer {
    background: transparent !important;
    ${media.lessThan("medium")`
         padding-left: 0 !important;
       }
    `};
  }

  thead {
    line-height: 1.125rem;

    th {
      background-color: transparent !important;
      font-weight: 400 !important;
      color: ${Colors.monumental} !important;
      padding: 0.5rem !important;
    }

    tr {
      background: transparent !important;
    }
  }

  thead > tr:hover > td,
  tbody > tr:hover > td {
    background: ${Colors.bg_gray} !important;
    transition: none;
  }

  td,
  th {
    white-space: nowrap;
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
    ${media.lessThan("large")`
      &:first-child {
        padding-left: 0 !important;
      }
      &:last-child {
        padding-right: 0 !important;
      }
    `};
  }

  .ant-table-tbody > tr > td {
    border-bottom: 1px solid ${Colors.bg_gray} !important;
    transition: none;
    padding: 1.125rem 0;
  }

  /* Keep cursor pointer on text elements in Expense Hub list item */
  .ant-table-tbody > tr > td > div > label > span {
    cursor: pointer;
  }

  /* Keep cursor pointer on flags in Expense Hub list item */
  .ant-table-tbody > tr > td > span > span {
    cursor: pointer;
  }

  /* Keep cursor pointer on status in Expense Hub list item */
  .ant-table-tbody > tr > td > div > div > span {
    cursor: pointer;
  }

  .ant-table-tbody .ant-checkbox-inner {
    transition: none;
  }

  .ant-table-row-selected:not(:hover) td {
    background: transparent !important;
  }

  .ant-table-row-level-0 {
    ${media.greaterThan("small")`
      box-sizing: border-box !important;
      td:first-child {
        transition: none;
      }
      :hover {
        td:first-child {
          transition: none;
        }
      }
    `};
  }

  .ant-table-title {
    font-weight: 700 !important;
    font-size: 1rem;
  }

  .ant-table-column-sorter .ant-table-column-sorter-inner-full {
    margin-top: -0.25rem !important;
  }

  .ant-table-placeholder {
    border-bottom: none !important;
  }

  .ant-table-column-title {
    text-transform: uppercase !important;
    font-weight: 600 !important;
    font-size: 0.625rem !important;
    color: ${Colors.dark_gray};
  }

  .ant-pagination {
    .ant-pagination-total-text {
      color: ${Colors.monumental} !important;
      font-size: 0.75rem;
    }

    .ant-pagination-item,
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      display: none !important;
    }

    .ant-pagination-prev {
      margin-right: -1px !important;
      position: relative !important;
      z-index: 1;

      :hover,
      :focus {
        z-index: 2;
      }
    }

    .ant-pagination-next {
      position: relative !important;
      z-index: 1;

      :hover,
      :focus {
        z-index: 2;
      }
    }

    .ant-pagination-simple-pager {
      input {
        margin-left: 1px !important;
      }
    }
  }

  .ant-pagination:first-child {
    margin-top: 0 !important;
  }

  th.ant-table-sort-title {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  .ant-header-sort-header {
    &:hover {
      background: ${Colors.bg_gray};
    }

    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
  }

  .ant-header-sort-header__title {
    margin-right: 10px;
  }

  .ant-header-sort-header__order {
    margin-right: 7.5px;
  }

  .ant-header-sort-header__order.desc {
    transform: rotate(180deg);
  }

  .ant-header-sort-header__order svg {
    vertical-align: unset;
  }
`;

const DragHandle = styled(DraggableSvg)`
  height: 1rem;
`;

const getDraggableCols = (columns) => {
  const dragHandle = {
    title: "",
    key: "dragHandle",
    width: "2rem",
    onCell: (_record, index) => ({
      dragWidth: "2rem",
      index,
      dragHandle: true,
    }),
    render: () => <DragHandle />,
  };

  // eslint-disable-next-line no-param-reassign
  columns = columns.map((col) => {
    const prevOnCell = col.onCell;
    const draggableOnCell = (record, index) => {
      let cellProps = {};
      if (prevOnCell) {
        cellProps = prevOnCell(record, index);
      }

      return { ...cellProps, index, dragWidth: col.width || null };
    };

    // eslint-disable-next-line no-param-reassign
    col.onCell = draggableOnCell;

    return col;
  });

  return [dragHandle, ...columns];
};

const Table = ({
  id,
  className,
  title,
  footer,
  onLoadMore,
  fetchingMore,
  dataSource,
  loading,
  pagination,
  onDragEnd,
  onPaginationClick,
  onSortChanged,
  columns,
  sortFieldId,
  sortDirection,
  ...otherProps
}) => {
  const canDragRow = !!onDragEnd;

  const sortColumnChanged = (item) => {
    if (onSortChanged) {
      onSortChanged(item);
    }
  };

  const getColumnHeaderTemplate = (column) => (
    <HeaderWithOrderOptions
      column={column}
      sortFieldId={sortFieldId}
      sortDirection={sortDirection}
      onSortChanged={sortColumnChanged}
    />
  );

  const mapColumns = (sourceColumns) =>
    sourceColumns?.map((column) => {
      const hasOrderOptions =
        column.sortOrder && column.sortOrder.length && Array.isArray(column?.sortOrder);
      return {
        ...column,
        title: hasOrderOptions ? getColumnHeaderTemplate(column) : column.title,
        className: `${column.className || ""} ${hasOrderOptions && "ant-table-sort-title"}`,
        sortOrder: hasOrderOptions ? undefined : column.sortOrder,
      };
    });

  // eslint-disable-next-line react/no-unstable-nested-components
  const LoadedBodyDraggable = (props) => <BodyDraggable onDragEnd={onDragEnd} {...props} />;
  const handlePaginationChange = (newPage) => {
    if (onPaginationClick) {
      onPaginationClick(newPage);
    }
  };
  const renderTitle = () => {
    if (typeof title === "string") {
      return <Heading as="h5">{title}</Heading>;
    }
    return title;
  };
  const renderFooter = () => {
    const showLoadMoreBtn = onLoadMore && dataSource && dataSource.length > 0;
    if (footer || onLoadMore) {
      return (
        <>
          {footer && footer()}
          {showLoadMoreBtn && (
            <ButtonStyled
              id={`${id}-load-more-button`}
              type="primary"
              onClick={onLoadMore}
              loading={fetchingMore}
            >
              Load more
            </ButtonStyled>
          )}
        </>
      );
    }
    return null;
  };

  const isEmpty = dataSource && dataSource.length === 0;

  const components = {
    table: TablePure,
    body: {
      wrapper: canDragRow ? LoadedBodyDraggable : BodyPure,
      row: canDragRow ? RowDraggable : RowPure,
      cell: canDragRow ? CellDraggable : CellPure,
    },
  };

  // eslint-disable-next-line no-param-reassign
  columns = mapColumns(columns);

  return (
    <TableStyled
      id={id}
      className={className}
      columns={canDragRow ? getDraggableCols(columns) : columns}
      title={title ? () => renderTitle() : null}
      footer={footer || onLoadMore ? () => renderFooter() : null}
      loading={loading && !fetchingMore}
      dataSource={dataSource}
      showHeader={!isEmpty}
      components={components}
      expandIcon={ExpandIcon}
      onRow={(_record, index) => ({ index })}
      pagination={
        pagination && {
          ...pagination,
          hideOnSinglePage:
            typeof pagination.hideOnSinglePage === "boolean" ? pagination.hideOnSinglePage : true,
          onChange: handlePaginationChange,
        }
      }
      {...otherProps}
    />
  );
};

Table.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  columns: PropTypes.any,
  pagination: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  title: PropTypes.any,
  footer: PropTypes.any,
  onLoadMore: PropTypes.func,
  onPaginationClick: PropTypes.func,
  onSortChanged: PropTypes.func,
  onDragEnd: PropTypes.func,
  fetchingMore: PropTypes.bool,
  dataSource: PropTypes.array,
  loading: PropTypes.bool,
  sortFieldId: PropTypes.string,
  sortDirection: PropTypes.string,
};

Table.defaultProps = {
  id: generateRandomString("table"),
  pagination: false,
};

export default withEmptyState(memo(Table));
