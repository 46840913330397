import gql from "graphql-tag";
import _, { differenceBy, isEmpty } from "lodash";
import { css } from "styled-components";

import Colors from "@core/ui-legacy/themes/colors";

import { UserRoles } from "../../common/roles";
import { userHasFeatures } from "../../common/userHasFeatures";
import { renderUnassignedOption, UNASSIGNED } from "../../components/DynamicForm/DropDownListField";
import featureFlags from "../../utils/featureFlags";
import { parseIfJSON } from "../../utils/rules";
import { getPartners } from "../../utils/tmc";
import { ORG_TRAVEL_MANAGEMENT_COMPANY_PARTNER } from "./fragments";
import { GET_COST_CENTERS_WITH_PREFIX, GET_USER_ROLES_ADDRESS } from "./queries";

const decoratorsMap = {
  billingAddressStateStyle: css`
    display: inline-block;
    vertical-align: top;
    width: 21.875rem !important;
    margin-right: 1rem;
    margin-bottom: 0 !important;

    .ant-row {
      margin-bottom: 0 !important;
      padding-bottom: 0 !important;
    }
  `,
  billingAddressPostalCodeStyle: css`
    display: inline-block;
    vertical-align: top;
    width: 12.5rem !important;
  `,
  sendWelcomeEmailStyle: css`
    margin-bottom: 1.5rem !important;
  `,
  textField: css`
    width: 21.875rem !important;
  `,
  personalInfoTextField: css`
    width: 31.25rem !important;
  `,
  selectField: css`
    max-width: 21.875rem !important;
  `,
  customizeCardSection: css`
    & p:last-of-type {
      padding-top: 0.5rem;
    }
  `,
  borderedSection: css`
    & {
      border: 1px solid #e4e7ed;
      border-radius: 8px;
      padding: 0.75rem 1rem;
      position: relative;
      margin-top: 1rem;

      & > .section-header {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
      }

      & li:first-of-type {
        top: 0;
      }
    }
  `,
  toggleableSection: css`
    position: relative;

    & > .section-header {
      margin-bottom: 0 !important;
    }
  `,
  sectionToggle: css`
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
  `,
  issueCardLaterMessage: css`
    div {
      display: block;
      margin-left: 0;
      margin-bottom: 0;
      font-size: 1rem !important;
      opacity: 1 !important;
    }
  `,
  cardLimitField: css`
    width: 18.75rem;
  `,
  cardNameSection: css`
    .section-header {
      margin-bottom: 0 !important;
      margin-top: 0 !important;
    }

    h4 {
      font-size: 0.75rem !important;
      font-weight: 450 !important;
      color: ${Colors.dark_gray};
    }
  `,
  customCardNameField: css`
    display: inline-block;
    vertical-align: top;
    width: 18.75rem;
    margin-bottom: 0 !important;

    &:not(:last-child) {
      margin-right: 0.75rem;
    }
  `,
  travelArrangerSectionStyle: css`
    display: flex;
    flex-direction: column;

    li {
      float: left;
    }
  `,
  travelArrangerUserType: css`
    min-width: 27rem;
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-form-item {
      margin-top: 0 !important;
      flex: 1;
    }
  `,
  travelArrangerScope: css`
    width: 16.25rem;
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 1rem;

    .ant-form-item {
      margin-top: 0 !important;
      flex: 1;
    }
  `,
  travelArrangerSecondaryInputStyle: css`
    width: 100%;

    .ant-form-item {
      margin-top: 0.75rem !important;
    }
  `,
  travelArrangerMultiselectField: css`
    .ant-form-item {
      margin-top: 0 !important;
    }
  `,
  inlineBorderedSection: css`
    & {
      border: 1px solid #e4e7ed;
      border-radius: 8px;
      padding: 0.75rem 1rem;
      position: relative;
      margin-top: 1rem;

      & > .section-header {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
      }

      & > .section-details {
        display: flex;
        justify-content: space-between;
        align-self: stretch;
        gap: 0.875rem;

        li {
          flex: 1;
        }
      }

      & li:first-of-type {
        top: 0;
      }
    }
  `,
};

const oldDecoratorsMap = {
  billingAddressStateStyle: css`
    display: inline-block;
    vertical-align: top;
    width: 66% !important;
    margin-right: 0.625rem;
  `,
  billingAddressPostalCodeStyle: css`
    display: inline-block;
    vertical-align: top;
    width: 30% !important;
    margin-left: 0.625rem;
  `,
  sendWelcomeEmailStyle: css`
    margin-bottom: 1.5rem !important;
  `,
  textField: css`
    max-width: 31.25rem !important;
  `,
  personalInfoTextField: css`
    width: 31.25rem !important;
  `,
  selectField: css`
    max-width: 21.875rem !important;
  `,
  multiselectField: css`
    margin-right: 2.625rem;
  `,
  rolesSectionStyle: css`
    li.section-details__field {
      margin-bottom: 0.5rem !important;
    }
  `,
  travelArrangerSectionStyle: css`
    margin-top: 1.25rem;
    display: flex;

    li {
      float: left;
    }
  `,
  travelArrangerUserType: css`
    min-width: 27rem;
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-form-item {
      margin-top: 0 !important;
      flex: 1;
    }
  `,
  travelArrangerScope: css`
    width: 16.25rem;
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 16px;

    .ant-form-item {
      margin-top: 0 !important;
      flex: 1;
    }
  `,
  travelArrangerSecondaryInputStyle: css`
    width: calc(100vw - 21.125rem);
  `,
};

const lazyLoadingOptionsForUserSelect = {
  query: GET_USER_ROLES_ADDRESS,
  queryDataField: "users",
  serverSearchKey: "prefix",
  optionValue: "fullName",
};

const lazyLoadingOptionsforCC = {
  query: GET_COST_CENTERS_WITH_PREFIX,
  queryDataField: "searchCostCenters",
  serverSearchKey: "searchString",
  optionValue: "name",
};

const getOptionForDataSelector = (user) => ({
  ID: user?.ID,
  fullName: user?.name || user.fullName,
  disabled: user?.archived,
});
const filterArchived = (user) => !user.archived;
const filterByGuestTraveler = (user) => !user?.roles?.includes(UserRoles.GuestTraveler);

const getOptionsForApprover = (response, currentApprover) => {
  const filtered = response?.items.filter(filterArchived).filter(filterByGuestTraveler) || [];
  const unassigned = { ID: UNASSIGNED, fullName: renderUnassignedOption() };
  if (!currentApprover) {
    return [unassigned, ...filtered];
  }
  const withArchivedOption = response?.items.find((user) => user.ID === currentApprover.ID);
  return _.uniqBy(
    [unassigned, getOptionForDataSelector(withArchivedOption || currentApprover), ...filtered],
    "ID",
  );
};

const getOptions = (response, currentUsers) => {
  const filtered = response?.items.filter(filterArchived) || [];
  if (_.isEmpty(currentUsers)) {
    return filtered;
  }
  const delegatesOptions = currentUsers.map((del) => ({
    ID: del.ID,
    fullName: `${del.firstName} ${del.lastName}`,
  }));
  return _.uniqBy([...filtered, ...delegatesOptions], "ID");
};

const getCCOptions = (res, currentCCs) => {
  const items = res?.items || [];
  return _.uniqBy([...currentCCs, ...items], "ID");
};

const recurrentResolver = (field, mapValues, data) => {
  if (field.property === "delegateOf") {
    const mutatedField = field;
    const currentDelegates = data?.user?.delegateOf;
    mutatedField.options = {
      ...field?.options,
      ...lazyLoadingOptionsForUserSelect,
      getOptions: (res) => getOptions(res, currentDelegates),
    };
    mutatedField.values = [];
  } else if (field.property === "insightsViewerOfCostCenters") {
    const mutatedField = field;
    let currentInsights = data?.user?.insightsViewerOf?.costCenters || [];
    if (currentInsights.length) {
      currentInsights = currentInsights.map((item) => ({
        ID: item.ID,
        name: data.costCenters.find((cc) => cc.ID === item.ID)?.name || item.name,
      }));
    }
    mutatedField.options = {
      ...field?.options,
      ...lazyLoadingOptionsforCC,
      isServerMode: true,
      getOptions: (res) => getCCOptions(res, currentInsights),
    };
    mutatedField.values = [];
  } else if (field.fields) {
    field.fields.forEach((fieldElement) => {
      recurrentResolver(fieldElement, mapValues, data);
    });
  } else if (mapValues[field.property]) {
    if (field.property === "travelArrangerOfInfo.costCenters") {
      _.set(
        field,
        "values",
        mapValues[field.property].filter((cc) => field.values?.includes(cc.ID)),
      );
    } else if (["travelArrangerOfInfo.users", "travelArrangerOf"].includes(field.property)) {
      const currentUsers =
        field.property === "travelArrangerOfInfo.users"
          ? data.user?.travelArrangerOfInfo?.users
          : data.user?.travelArrangerOf;
      const options = _.get(field, "options");
      _.set(field, "options", {
        ...options,
        ...lazyLoadingOptionsForUserSelect,
        getOptions: (res) => getOptions(res, currentUsers),
      });
      _.set(field, "values", []);
    } else {
      _.set(field, "values", mapValues[field.property]);
    }
  }

  if (field.decorator) {
    _.set(field, "decorator", decoratorsMap[field.decorator]);
  }
};

const GET_ME = gql`
  {
    me {
      ID
      toggles
      organization {
        ID
        partnerFields {
          spotnana {
            ...OrgTMCPartnerFragment
          }
          test {
            ...OrgTMCPartnerFragment
          }
        }
      }
    }
  }
  ${ORG_TRAVEL_MANAGEMENT_COMPANY_PARTNER}
`;

const getMe = (cache) => {
  const { me } = cache.readQuery({ query: GET_ME });

  return me;
};

const getUserInForm = (cache, toggles) => {
  try {
    const [, , , userID] = window.location.pathname.split("/");
    const virtualCardsEnabled = userHasFeatures(toggles, [featureFlags.VIRTUAL_MULTI_CARD]);

    const data = cache.readQuery({
      query: gql`
        query user($userID: ID!, $showcarditems: Boolean) {
          user(id: $userID, showcarditems: $showcarditems) {
            travelArrangerOfInfo {
              costCenters {
                ID
                name
              }
              users {
                ID
                firstName
                lastName
              }
            }
            travelArrangerOf {
              ID
              firstName
              lastName
            }
            insightsViewerOf {
              costCenters {
                ID
              }
            }
            defaultApprover {
              ID
              name
            }
            defaultApproverSchedule {
              defaultApprover {
                ID
                name
              }
            }
            delegateOf {
              ID
              firstName
              lastName
            }
            costCenters {
              ID
              name
            }
          }
        }
      `,
      variables: {
        userID,
        showcarditems: !virtualCardsEnabled,
      },
    });

    return data.user;
  } catch {
    return null;
  }
};

const getCostCenters = (cache) => {
  try {
    const data = cache.readQuery({
      query: gql`
        query costCenters {
          costCenters(archived: false) {
            ID
            name
          }
        }
      `,
    });

    return data.costCenters;
  } catch {
    return [];
  }
};

const getTravelPolicies = (cache, orgID, partnerFields) => {
  try {
    const withTravelPolicies = !isEmpty(getPartners(partnerFields));

    const data = cache.readQuery({
      query: gql`
        query travelPolicies($orgID: ID!, $withTravelPolicies: Boolean!) {
          travelPolicies(orgID: $orgID) @include(if: $withTravelPolicies) {
            ID
            name
            isDefault
          }
        }
      `,
      variables: {
        orgID,
        withTravelPolicies,
      },
    });

    return data.travelPolicies;
  } catch {
    return [];
  }
};

const getOffices = (cache, partnerFields) => {
  try {
    const data = cache.readQuery({
      query: gql`
        query offices($withOffices: Boolean!) {
          offices(tmc: "spotnana") @include(if: $withOffices) {
            id
            name
          }
        }
      `,
      variables: {
        withOffices: !!partnerFields?.spotnana,
      },
    });

    return data.offices;
  } catch {
    return [];
  }
};

const getPageContext = (cache) => {
  const {
    organization: { ID: orgID, partnerFields },
    toggles,
  } = getMe(cache);

  return {
    user: getUserInForm(cache, toggles),
    costCenters: getCostCenters(cache),
    travelPolicies: getTravelPolicies(cache, orgID, partnerFields),
    offices: getOffices(cache, partnerFields),
  };
};

export const dynamicFormFieldResolverBase = {
  values: ({ property, _values }, _args, { cache }) => {
    const values = parseIfJSON(_values);

    try {
      const data = getPageContext(cache);

      if (property === "defaultCostCenter") {
        return (data?.costCenters ?? []).filter((cc) => values.includes(cc.ID));
      }

      if (property === "delegateOf") {
        return [];
      }

      if (property === "roles") {
        const mapPermissionValues = {};
        _.set(
          mapPermissionValues,
          ["travelArrangerOfInfo.costCenters"],
          _.union(data.costCenters, data.user?.travelArrangerOfInfo?.costCenters || []),
        );
        ["travelArrangerOf", "travelArrangerOfInfo.users"].forEach((path) => {
          _.set(mapPermissionValues, [path], []);
        });
        return values.map((v) => {
          if (v.childNode) {
            recurrentResolver(v.childNode, mapPermissionValues, data);
          }
          return v;
        });
      }

      if (property === "travelPolicies[0]") {
        return data?.travelPolicies ?? [];
      }

      if (property === "office") {
        return (data?.offices ?? []).map((o) => ({ ID: o.id, name: o.name }));
      }
    } catch (e) {
      console.error(e);
    }

    return values;
  },
  decorator: ({ _decorator }, _args, { cache }) => {
    const { toggles } = getMe(cache);

    if (userHasFeatures(toggles, [featureFlags.VIRTUAL_MULTI_CARD])) {
      return decoratorsMap[_decorator];
    }

    return oldDecoratorsMap[_decorator];
  },
  defaultValue: ({ _defaultValue, property }, _args, { cache }) => {
    const defaultValue = parseIfJSON(_defaultValue);

    try {
      const data = getPageContext(cache);

      if (property === "travelPolicies[0]") {
        return data?.travelPolicies?.find((p) => p.isDefault)?.ID;
      }

      if (property === "insightsForAllCostCenters") {
        return (
          differenceBy(data.costCenters, data.user?.insightsViewerOf?.costCenters, "ID").length ===
          0
        );
      }
    } catch (e) {
      console.error(e);
    }

    return defaultValue;
  },
  options: ({ _options, property }, _args, { cache }) => {
    const data = getPageContext(cache);

    if (property === "costCenters") {
      const currentCCs = data?.user?.costCenters || [];
      return {
        ...parseIfJSON(_options),
        ...lazyLoadingOptionsforCC,
        filterOptionsKey: "ID",
        isServerMode: true,
        selectAllOptions: data.costCenters || [],
        getOptions: (res) => getCCOptions(res, currentCCs),
      };
    }

    if (property === "defaultApprover" || property?.endsWith("defaultApprover")) {
      const currentApprover = _.get(data?.user, property);
      return {
        ...parseIfJSON(_options),
        ...lazyLoadingOptionsForUserSelect,
        addUnassigned: false,
        filterOptionsKey: "ID",
        getOptions: (res) => getOptionsForApprover(res, currentApprover),
      };
    }
    if (property === "delegateOf") {
      const currentDelegates = data?.user?.delegateOf;
      return {
        ...parseIfJSON(_options),
        ...lazyLoadingOptionsForUserSelect,
        getOptions: (res) => getOptions(res, currentDelegates),
      };
    }
    if (property === "insightsViewerOfCostCenters") {
      let currentInsights = data?.user?.insightsViewerOf?.costCenters || [];
      if (currentInsights.length) {
        currentInsights = currentInsights.map((item) => ({
          ID: item.ID,
          name: data.costCenters.find((cc) => cc.ID === item.ID)?.name || item.name,
        }));
      }
      return {
        ...parseIfJSON(_options),
        ...lazyLoadingOptionsforCC,
        isServerMode: true,
        getOptions: (res) => getCCOptions(res, currentInsights),
      };
    }
    return parseIfJSON(_options) ?? {};
  },
  validationRules: ({ _validationRules }) => parseIfJSON(_validationRules),
  tooltip: ({ _tooltip }) => parseIfJSON(_tooltip),
  delimiter: ({ _delimiter }) => parseIfJSON(_delimiter),
};

export const userFormResolverBase = {
  user: ({ _user }) => parseIfJSON(_user),
};
