import { ReactComponent as OneTimeCardImage } from "../../assets/imgs/credit-card-icon-one-time.svg";
import { ReactComponent as PhysicalCardImageV2 } from "../../assets/imgs/credit-card-icon-physical.svg";
import { ReactComponent as VirtualCardImageV2 } from "../../assets/imgs/credit-card-icon-virtual.svg";
import { ReactComponent as PhysicalCardImage } from "../../assets/imgs/physical-card-img.svg";
import { ReactComponent as VirtualCardImage } from "../../assets/imgs/virtual-card-img.svg";
import type { CardBehavior, CardFormat, CardType } from "../../common/types";
import { cardBehaviorEnum, getCardHolderNameUppercase } from "../../utils";
import { CARD_TYPE } from "../../utils/constants";
import {
  CardholderCellStyled,
  CardholderNameContainer,
  CardholderNameStyled,
  CardImageWrapper,
  CardNameStyled,
} from "./styledComponents";
import type { CardholderStyledProps } from "./types";

const getCardIcon = (
  cardFormat: CardFormat,
  cardBehavior: CardBehavior = cardBehaviorEnum.STANDARD,
) => {
  if (cardFormat === CARD_TYPE.PHYSICAL) return PhysicalCardImageV2;
  return cardBehavior === cardBehaviorEnum.SINGLE_TRANSACTION
    ? OneTimeCardImage
    : VirtualCardImageV2;
};

const CardImageV2 = ({ card }: { card: CardType }) => {
  const { cardFormat, cardBehavior, isProvisioning } = card;
  const CardIcon = getCardIcon(cardFormat, cardBehavior);
  return (
    <div
      className={`flex size-8 items-center justify-center rounded-3xl border border-solid border-neutrals-300 bg-neutrals-100 ${
        isProvisioning ? "opacity-40" : "opacity-100"
      }`}
    >
      <CardIcon />
    </div>
  );
};

const CardholderStyled = ({
  card,
  hasCustomName,
  hasCardManagementEnhancements,
}: CardholderStyledProps) => (
  <CardholderCellStyled {...{ "data-testid": "cards-list" }}>
    {hasCardManagementEnhancements ? (
      <CardImageV2 card={card} />
    ) : (
      <CardImageWrapper {...{ $isCardProvisioning: card.isProvisioning }}>
        {card.cardFormat === CARD_TYPE.VIRTUAL ? <VirtualCardImage /> : <PhysicalCardImage />}
      </CardImageWrapper>
    )}

    {hasCustomName ? (
      <CardholderNameContainer>
        <CardholderNameStyled
          {...{ $isCardProvisioning: card.isProvisioning }}
        >{`${getCardHolderNameUppercase(card.cardholder?.firstName)} ${getCardHolderNameUppercase(
          card.cardholder?.lastName,
        )}`}</CardholderNameStyled>
        <CardNameStyled>{`${getCardHolderNameUppercase(
          card.firstName,
        )} ${getCardHolderNameUppercase(card.lastName)}`}</CardNameStyled>
      </CardholderNameContainer>
    ) : (
      <CardholderNameStyled
        {...{ $isCardProvisioning: card.isProvisioning }}
      >{`${getCardHolderNameUppercase(card.cardholder?.firstName)} ${getCardHolderNameUppercase(
        card.cardholder?.lastName,
      )}`}</CardholderNameStyled>
    )}
  </CardholderCellStyled>
);

export default CardholderStyled;
