export const getCZScript = (orgData, userData) => {
  if (document) {
    const ChurnZero = window.ChurnZero || [];
    const cz = document.createElement("script");
    cz.type = "text/javascript";
    cz.async = true;
    cz.src = import.meta.env.REACT_APP_CZ_SCRIPT_URL;
    const s = document.querySelectorAll("script")[0];
    s.parentNode.insertBefore(cz, s);
    ChurnZero.push(["setAppKey", import.meta.env.REACT_APP_CZ_APIKEY]);
    // These can NOT be combined into a single `push` as it somehow breaks ChurnZero
    // eslint-disable-next-line unicorn/no-array-push-push
    ChurnZero.push(["setContact", orgData, userData]);
  }
};
