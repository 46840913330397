import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  type ReactNode,
} from "react";
import { ApolloError } from "apollo-client";
import gql from "graphql-tag";
import { noop } from "lodash";
import { useApolloClient } from "react-apollo";

import { Notifier } from "@core/ui-legacy";

import type {
  BulkActionEntity,
  BulkActionInfo,
  BulkActionInfoStatus,
  ExpenseBulkActionStatus,
} from "../components/Expenses/useExpenseBulkActions/types";
import { useIsBulkActionsV2Enabled } from "../components/Expenses/useExpenseBulkActions/useIsBulkActionsEnabled";
import { BUBBLE_FRAGMENT } from "../services/graphql/fragments";
import { EXPENSE_BULK_ACTION_INFO } from "../services/graphql/queries";
import { slot } from "../slotConfig";
import { browserDetection } from "../utils";
import AuthUserContext from "./contexts/AuthUserContext";
import { LockExpenseContext, type BulkActionExpenseMessage } from "./ExpenseLockProvider";
import { useChannel } from "./useChannel/useChannel";

const updateKeys = ["spendKPI", "expenseDetails", "expensesView"] as const;

type UpdateKeys = (typeof updateKeys)[number];

type UseOnBulkUpdateHandler = ({
  key,
  id,
  onUpdate,
  onError,
}: {
  key: UpdateKeys;
  id?: string;
  onError?: () => void;
  onUpdate?: { action: () => void; id: string };
}) => void;

type ExpenseBulkActionContext = {
  useOnBulkUpdateHandler: UseOnBulkUpdateHandler;
};

type UseOnBulkUpdateHandlerParams = Parameters<UseOnBulkUpdateHandler>[number];

type BulkActionInfoPusherMessage = {
  message: {
    ID: string;
    entities: BulkActionEntity[];
    userID: string;
    completedAtUnix?: number;
    status?: BulkActionInfoStatus;
  };
};

export const ExpenseBulkActionContext = createContext({} as ExpenseBulkActionContext);

type UpdateHandlerMap = Map<
  UpdateKeys,
  {
    id?: string;
    onErrorAction?: (entities: BulkActionEntity[]) => void;
    onUpdateActions?: Map<string, (entities?: BulkActionEntity[]) => void>;
  }
>;

const bulkActionToVerbMap: Record<ExpenseBulkActionStatus, { past: string; progressive: string }> =
  {
    APPROVE: {
      past: "approved",
      progressive: "approving expenses",
    },
    ARCHIVE: {
      past: "archived",
      progressive: "archiving expenses",
    },
    UNARCHIVE: {
      past: "unarchived",
      progressive: "unarchiving expenses",
    },
    MARK_AS_PERSONAL: {
      past: "marked as personal",
      progressive: "marking expenses as personal",
    },
    UNMARK_AS_PERSONAL: {
      past: "unmarked as personal",
      progressive: "unmarking expenses as personal",
    },
  };

const isSafari = browserDetection() === "Safari";

const ExpenseBulkActionProvider = ({ children }: { children: ReactNode }) => {
  const onUpdateHandlerMap = useRef<UpdateHandlerMap>(new Map());
  const client = useApolloClient();
  const authUser = useContext(AuthUserContext);
  const isBulkActionsV2Enabled = useIsBulkActionsV2Enabled();
  const { setLockedExpenseMap } = useContext(LockExpenseContext);

  const channel = useChannel("expenseBulkActions", {
    variables: { orgID: authUser?.organization?.ID, slot: slot ?? "stable" },
    isEnabled: isBulkActionsV2Enabled,
    unsubscribeOnUnmount: false,
  });

  const notifyOnResult = ({
    entities,
    action,
  }: {
    action: BulkActionInfo["action"];
    entities: BulkActionInfo["entities"];
  }) => {
    const successCount = entities.filter(({ state }) => state === "SUCCESS").length;

    let isError = false;
    let message: string;
    const description = "";
    if (!successCount) {
      // All expenses failed to update
      isError = true;
      message = `Oops there was a problem ${bulkActionToVerbMap[action].progressive}. Please try again.`;
    } else if (successCount === entities.length) {
      // All expenses updated
      const text = successCount === 1 ? "expense" : "expenses";
      message = `${successCount} ${text} successfully ${bulkActionToVerbMap[action].past}.`;
    } else {
      // Some expense failed to update
      isError = true;
      message = `Oops there was a problem. Only ${successCount} of ${entities.length} expenses successfully ${bulkActionToVerbMap[action].past}.`;
    }

    Notifier.notification({
      type: isError ? "error" : "success",
      message,
      description,
    });
  };

  const handleMarkAsPersonal = useCallback(
    async ({
      isPersonal,
      entities,
    }: {
      entities: BulkActionInfo["entities"];
      isPersonal: boolean;
    }) => {
      entities.forEach(({ ID, state }) => {
        // Only update in cache if entity state is successful
        if (state !== "SUCCESS") return;
        const fragment = gql`
          fragment PersonalFragment on Expense {
            isPersonal
            bubbles {
              ...BubbleFragment
            }
            __typename
          }
          ${BUBBLE_FRAGMENT}
        `;

        const data = client.readFragment({
          id: `Expense:${ID}`,
          fragment,
          fragmentName: "PersonalFragment",
        });

        const doesNotHavePersonalFlag = !data.bubbles.some(
          ({ groupType }) => groupType === "personalGroup",
        );

        if (isPersonal && doesNotHavePersonalFlag) {
          data.bubbles.push({
            title: "Personal",
            bgColor: "#FDB913",
            titleColor: "#001C3D",
            groupType: "personalGroup",
            items: [
              {
                flagType: null,
                title: null,
                flagID: null,
                text: "The expense has been marked as personal.",
                __typename: "BubbleItem",
              },
            ],
            __typename: "Bubble",
          });
        } else {
          data.bubbles = data.bubbles.filter(({ groupType }) => groupType !== "personalGroup");
        }

        client.writeFragment({
          id: `Expense:${ID}`,
          fragment,
          fragmentName: "PersonalFragment",
          data: {
            isPersonal,
            bubbles: data.bubbles,
            __typename: "Expense",
          },
        });
      });
    },
    [client],
  );

  const handleFailedExpensesAction = useCallback((entities: BulkActionInfo["entities"]) => {
    const failedExpenses = entities.filter(({ state }) => state !== "SUCCESS");
    onUpdateHandlerMap.current.get("expensesView")?.onErrorAction?.(failedExpenses);
  }, []);

  const handleArchiveAndApprove = useCallback(
    (entities: BulkActionInfo["entities"]) => {
      (["expensesView", "spendKPI"] as const).forEach((key) => {
        onUpdateHandlerMap.current.get(key)?.onUpdateActions?.forEach((action) => action(entities));
      });
      handleFailedExpensesAction(entities);
    },
    [handleFailedExpensesAction],
  );

  const bulkActionMap: Record<
    ExpenseBulkActionStatus,
    (entities: BulkActionInfo["entities"]) => void
  > = useMemo(
    () => ({
      MARK_AS_PERSONAL: (entities) => {
        // If on Safari, rely on re-query if on expenses view instead of cache update as the singular updates will overload the table. This can be removed once a glitch is fixed with antd virtualized table performance and safari.
        if (isSafari) {
          onUpdateHandlerMap.current
            .get("expensesView")
            ?.onUpdateActions?.forEach((action) => action(entities));
          return;
        }
        handleMarkAsPersonal({ entities, isPersonal: true });
        handleFailedExpensesAction(entities);
      },
      UNMARK_AS_PERSONAL: (entities) => {
        // If on Safari, rely on re-query if on expenses view instead of cache update as the singular updates will overload the table. This can be removed once a glitch is fixed with antd virtualized table performance and safari.
        if (isSafari) {
          onUpdateHandlerMap.current
            .get("expensesView")
            ?.onUpdateActions?.forEach((action) => action(entities));
          return;
        }
        handleMarkAsPersonal({ entities, isPersonal: false });
        handleFailedExpensesAction(entities);
      },
      APPROVE: handleArchiveAndApprove,
      ARCHIVE: handleArchiveAndApprove,
      UNARCHIVE: handleArchiveAndApprove,
    }),
    [handleArchiveAndApprove, handleFailedExpensesAction, handleMarkAsPersonal],
  );

  const unlockAllExpenses = useCallback(
    (entities: BulkActionEntity[]) => {
      setLockedExpenseMap((prevLockedExpenseMap) => {
        const newMap = new Map(prevLockedExpenseMap);
        entities.forEach(({ ID: entityId }) => {
          newMap.set(entityId, { isLocked: false, originatorId: null });
        });
        return newMap;
      });
    },
    [setLockedExpenseMap],
  );

  const onExpenseLockUpdated = useCallback(
    ({ message }: BulkActionExpenseMessage) => {
      const { isLocked, entityType, entityIds, userId } = message;

      if (entityType !== "expense") return;
      entityIds.forEach((entityId) => {
        // Only lock on pusher message, unlocks will trigger upon entire bulk action completion or if on expense details page
        if (isLocked) {
          setLockedExpenseMap((prev) => {
            const newMap = new Map(prev);
            newMap.set(entityId, { isLocked, originatorId: userId ?? null });
            return newMap;
          });
        }
        // If on expense details page, trigger update if entity is unlocked
        const { id, onUpdateActions: onUpdateAction } =
          onUpdateHandlerMap.current.get("expenseDetails") || {};
        if (id === entityId && !isLocked) {
          setLockedExpenseMap((prev) => {
            const newMap = new Map(prev);
            newMap.delete(entityId);
            return newMap;
          });
          onUpdateAction?.forEach((action) => action());
        }
      });
    },
    [setLockedExpenseMap],
  );

  const onBulkActionUpdated = useCallback(
    async ({ message }: BulkActionInfoPusherMessage) => {
      const { ID, userID: pusherUserID, completedAtUnix, status: pusherStatus } = message;
      // If start event ignore
      if (!completedAtUnix && pusherStatus !== "FAILED_TO_START") return;
      try {
        const { data, errors } = await client.query<{
          expenseBulkActionInfo: BulkActionInfo;
        }>({
          query: EXPENSE_BULK_ACTION_INFO,
          variables: { bulkActionInfoID: ID },
          fetchPolicy: "network-only",
        });
        const { action, entities, userID, status } = data.expenseBulkActionInfo;
        if (status === "CANCELLED" || status === "FAILED_TO_START") {
          // If operation cancelled, unlock all expenses
          unlockAllExpenses(entities);
          return;
        }
        if (errors || !data) throw new ApolloError({ graphQLErrors: errors });
        // Trigger the respective actions for action and entities
        bulkActionMap[action]?.(entities);
        unlockAllExpenses(entities);
        // Notify if action initiated by user
        if (authUser.ID === userID) notifyOnResult({ entities, action });
      } catch (error) {
        // If not initiated by user, fail silently
        if (authUser.ID !== pusherUserID) return;
        console.error(error);
        Notifier.notification({
          type: "error",
          message: "Oops there was a problem with your bulk expense update. Please try again.",
        });
      }
    },
    [authUser.ID, bulkActionMap, client, unlockAllExpenses],
  );

  useEffect(() => {
    if (!channel) return noop;
    channel.bind("bulkActionExpenseLockUpdated", onExpenseLockUpdated);
    channel.bind("bulkActionInfoUpdated", onBulkActionUpdated);
    return () => {
      channel.unbind_all();
    };
  }, [channel, onBulkActionUpdated, onExpenseLockUpdated]);

  /**
   * Registers an update handler function to be called when a bulk action completes successfully or fails.
   * @param onUpdate The update handler function to be called on success.
   * @param onError The error handler function to be called on failure.
   */
  const useOnBulkUpdateHandler = ({ key, onUpdate, onError, id }: UseOnBulkUpdateHandlerParams) => {
    useEffect(() => {
      if (!key) return;

      if (onUpdate) {
        const currentOnUpdateAction = onUpdateHandlerMap.current.get(key)?.onUpdateActions;
        if (currentOnUpdateAction) {
          currentOnUpdateAction.set(onUpdate.id, onUpdate.action);
        } else {
          onUpdateHandlerMap.current.set(key, {
            onUpdateActions: new Map([[onUpdate.id, onUpdate.action]]),
          });
        }
      }
      onUpdateHandlerMap.current.set(key, {
        ...onUpdateHandlerMap.current.get(key),
        ...(onError && { onErrorAction: onError }),
        ...(id && { id }),
      });
    }, [onError, onUpdate, key, id]);

    // Remove update handler on unmount of subscribing component
    useEffect(
      () => () => {
        onUpdateHandlerMap.current.delete(key);
      },
      [key],
    );
  };

  const value = {
    useOnBulkUpdateHandler,
  };

  return (
    <ExpenseBulkActionContext.Provider value={value}>{children}</ExpenseBulkActionContext.Provider>
  );
};

export default ExpenseBulkActionProvider;
