import { createElement } from "react";
import { pathToRegexp } from "path-to-regexp";
import Loadable from "react-loadable";

import * as Routes from "../utils/routes";
import { getMenuDataWithChildren } from "./menu";

let routerDataCache;
// wrapper of dynamic
const dynamicWrapper = (component) =>
  Loadable({
    loader: () => {
      if (!routerDataCache) {
        routerDataCache = getRouterData();
      }
      return component()
        .then((raw) => {
          const Component = raw.default || raw;
          return (props) =>
            createElement(Component, {
              ...props,
              routerData: routerDataCache,
            });
        })
        .catch((e) => console.error(e));
    },
    loading: () => null,
  });

const getFlatMenuData = (menus) => {
  let keys = {};
  menus.forEach((item) => {
    if (item.children) {
      keys[item.path] = { ...item };
      keys = { ...keys, ...getFlatMenuData(item.children) };
    } else {
      keys[item.path] = { ...item };
    }
  });
  return keys;
};

export const getRouterData = () => {
  const routerConfig = {
    [Routes.HOME]: {
      component: dynamicWrapper(() => import("../features/HomePage")),
    },
    [Routes.NOT_FOUND_PAGE]: {
      component: dynamicWrapper(() => import("../components/NotFoundPage")),
    },
    [Routes.MYCARD_OVERVIEW]: {
      component: dynamicWrapper(() => import("../features/MyCardSummaryPage")),
      name: "My Card Overview",
    },
    [Routes.SPEND_CARDS]: {
      component: dynamicWrapper(() => import("../features/SpendMyCards")),
      name: "My Cards",
    },
    [Routes.MYCARD_EXPENSES]: {
      component: dynamicWrapper(() => import("../features/MyCardExpensesPage")),
      name: "Expenses",
    },
    [Routes.SPEND_EXPENSES]: {
      component: dynamicWrapper(() => import("../features/MyCardExpensesPage")),
      name: "Expenses",
    },
    [Routes.MYCARD_EXPENSE_DETAILS]: {
      component: dynamicWrapper(() => import("../features/ExpenseDetailsPage")),
      name: "Expense Details",
    },
    [Routes.SPEND_EXPENSE_DETAILS]: {
      component: dynamicWrapper(() => import("../features/ExpenseDetailsPage")),
      name: "Expense Details",
    },
    [Routes.INSIGHTS_EXPENSE_DETAILS]: {
      component: dynamicWrapper(() => import("../features/ExpenseDetailsPage")),
      name: "Expense Details Review",
    },
    [Routes.MYCARD_TRIPS]: {
      component: dynamicWrapper(() => import("../features/MyCardTripsPage")),
      name: "Trips",
    },
    [Routes.SPEND_TRIPS]: {
      component: dynamicWrapper(() => import("../features/MyCardTripsPage")),
      name: "Trips",
    },
    [Routes.MYCARD_TRIP_DETAILS]: {
      component: dynamicWrapper(() => import("../features/MyCardTripDetails")),
      name: "Trip Details",
    },
    [Routes.SPEND_TRIP_DETAILS]: {
      component: dynamicWrapper(() => import("../features/MyCardTripDetails")),
      name: "Trip Details",
    },
    [Routes.LOGIN]: {
      component: dynamicWrapper(() => import("../features/LoginPage")),
    },
    [Routes.CHANGE_PASSWORD]: {
      component: dynamicWrapper(() => import("../features/ChangePasswordPage")),
    },
    [Routes.FORGOT_PASSWORD]: {
      component: dynamicWrapper(() => import("../features/ForgotPasswordPage")),
    },
    [Routes.RESET_PASSWORD]: {
      component: dynamicWrapper(() => import("../features/ResetPasswordPage")),
    },
    [Routes.CREATE_PASSWORD]: {
      component: dynamicWrapper(() => import("../features/CreatePasswordPage")),
    },
    [Routes.CENTERACCOUNT_SUMMARY]: {
      component: dynamicWrapper(() => import("../features/CenterAccountSummaryPage")),
      name: "Center Account Summary",
    },
    [Routes.CENTERACCOUNT_TRANSACTIONS]: {
      component: dynamicWrapper(() => import("../features/CenterAccountTransactionsPage")),
      name: "Center Account Transactions",
    },
    [Routes.STATEMENTS]: {
      component: dynamicWrapper(() => import("../features/StatementsPage")),
      name: "Statements",
    },
    [Routes.LINKED_ACCOUNTS]: {
      component: dynamicWrapper(() => import("../features/LinkedAccountsPage")),
      name: "Linked Accounts",
    },
    [Routes.USERS]: {
      component: dynamicWrapper(() => import("../features/UsersPage")),
      name: "User Management",
    },
    [Routes.USER_CREATE]: {
      component: dynamicWrapper(() => import("../features/UserCreatePage")),
      name: "User Create",
    },
    [Routes.USER_EDIT]: {
      component: dynamicWrapper(() => import("../features/UserEditPage")),
      name: "User Edit",
    },
    [Routes.USER_INFO]: {
      component: dynamicWrapper(() => import("../features/UserInfoPage")),
      name: "User Info",
    },
    [Routes.COST_CENTERS]: {
      component: dynamicWrapper(() => import("../features/CostCentersPage")),
      name: "Cost Centers",
    },
    [Routes.COST_CENTERS_CREATE]: {
      component: dynamicWrapper(() => import("../features/CostCenterCreatePage")),
      name: "Cost Center Create",
    },
    [Routes.COST_CENTERS_EDIT]: {
      component: dynamicWrapper(() => import("../features/CostCenterEditPage")),
      name: "Cost Center Edit",
    },
    [Routes.EXPENSE_TYPES]: {
      component: dynamicWrapper(() => import("../features/ExpenseTypesPage")),
      name: "Expense Types",
    },
    [Routes.EXPENSE_TYPES_CREATE]: {
      component: dynamicWrapper(() => import("../features/ExpenseTypeCreatePage")),
      name: "Expense Types Create",
    },
    [Routes.EXPENSE_TYPES_EDIT]: {
      component: dynamicWrapper(() => import("../features/ExpenseTypeEditPage")),
      name: "Expense Type Edit",
    },
    [Routes.GENERAL_LEDGER]: {
      component: dynamicWrapper(() => import("../features/GeneralLedgerPage")),
      name: "General Ledger Accounts",
    },
    [Routes.GENERAL_LEDGER_CREATE]: {
      component: dynamicWrapper(() => import("../features/GeneralLedgerCreatePage")),
      name: "General Ledger Acccounts Create",
    },
    [Routes.GENERAL_LEDGER_EDIT]: {
      component: dynamicWrapper(() => import("../features/GeneralLedgerEditPage")),
      name: "General Ledger Accounts Edit",
    },
    [Routes.EXPENSE_FORMS]: {
      component: dynamicWrapper(() => import("../features/ExpenseFormsPage")),
      name: "Expense Forms",
    },
    [Routes.EXPENSE_FIELDS]: {
      component: dynamicWrapper(() => import("../features/ExpenseFieldsPage")),
      name: "Expense Fields",
    },
    [Routes.EXPENSE_FIELDS_CREATE]: {
      component: dynamicWrapper(() => import("../features/ExpenseFieldsCreatePage")),
      name: "Expense Fields Create",
    },
    [Routes.EXPENSE_FIELDS_EDIT]: {
      component: dynamicWrapper(() => import("../features/ExpenseFieldsEditPage")),
      name: "Expense Fields Edit",
    },
    [Routes.EXPENSE_FIELDS_MAPPING_CONFIGURATION]: {
      component: dynamicWrapper(() => import("../features/ExpenseFieldsMappingConfigurationPage")),
      name: "Mapping Configuration",
    },
    [Routes.SFTP]: {
      component: dynamicWrapper(() => import("../features/SftpPage")),
      name: "Sftp",
    },
    [Routes.REIMBURSEMENTS_POLICY]: {
      component: dynamicWrapper(() => import("../features/ReimbursementsAddBankPage")),
      name: "Policy",
    },
    [Routes.REIMBURSEMENTS_TRANSFERS]: {
      component: dynamicWrapper(() => import("../features/ReimbursementsTransfersPage")),
      name: "Transfers",
    },
    [Routes.REIMBURSEMENTS_SETTINGS]: {
      component: dynamicWrapper(() => import("../features/ReimbursementsAddBankPage")),
      name: "Reimbursements",
    },
    [Routes.ACCOUNT_INFO_SETTINGS]: {
      component: dynamicWrapper(() => import("../features/AccountInfoPage")),
      name: "Account info",
    },
    [Routes.TRAVEL_INFO_SETTINGS]: {
      component: dynamicWrapper(() => import("../features/TravelInfoPage")),
      name: "Traveler info",
    },
    [Routes.TRAVEL_INFO_USER_SETTINGS]: {
      component: dynamicWrapper(() => import("../features/TravelInfoUserPage")),
      name: "Travel user info",
    },
    [Routes.TRAVEL_INFO_USER_SETTINGS]: {
      component: dynamicWrapper(() => import("../features/TravelInfoUserPage")),
      name: "Travel user info",
    },
    [Routes.TRAVEL_DETAILS]: {
      component: dynamicWrapper(() => import("../features/TravelDetails")),
      name: "Travel Details",
    },
    [Routes.TRAVEL_POLICY]: {
      component: dynamicWrapper(() => import("../features/TravelPolicy")),
      name: "Travel Policy",
    },
    [Routes.TRAVEL_POLICY_GROUP]: {
      component: dynamicWrapper(() => import("../features/TravelPoliciesGroup")),
      name: "Travel Policy Group",
    },
    [Routes.NEW_TRAVEL_POLICY_GROUP]: {
      component: dynamicWrapper(() => import("../features/TravelPoliciesGroup")),
      name: "New Travel Policy Group",
    },
    [Routes.TRAVELER_PROFILES]: {
      component: dynamicWrapper(() => import("../features/TravelerProfilesPage")),
      name: "Traveler Profiles",
    },
    [Routes.NOTIFICATIONS]: {
      component: dynamicWrapper(() => import("../features/NotificationsPage")),
      name: "Notifications",
    },
    [Routes.TRIP_TYPES]: {
      component: dynamicWrapper(() => import("../features/TripTypesPage")),
      name: "Trip Types",
    },
    [Routes.INTEGRATIONS]: {
      component: dynamicWrapper(() => import("../features/IntegrationsPage")),
      name: "Integrations",
    },
    [Routes.INTEGRATIONS_CONNECTORS]: {
      component: dynamicWrapper(() => import("../features/IntegrationsConnectorsPage")),
      name: "Integrations Connectors",
    },
    [Routes.INTEGRATIONS_SETUP]: {
      component: dynamicWrapper(() => import("../features/IntegrationsSetupPage")),
      name: "Integration Setup",
    },
    [Routes.INTEGRATIONS_IMPORT_SOURCE]: {
      component: dynamicWrapper(() => import("../features/IntegrationsImportSourcePage")),
      name: "Integration Settings",
    },
    [Routes.INTEGRATIONS_EXPORT_SOURCE]: {
      component: dynamicWrapper(() => import("../features/IntegrationsExportPage")),
      name: "Integration Export Settings",
    },
    [Routes.EXPENSE_HUB]: {
      component: dynamicWrapper(() => import("../features/ExpenseHubPage")),
      name: "Expense Hub",
    },
    [Routes.MY_APPROVALS]: {
      component: dynamicWrapper(() => import("../features/MyApprovalsPage")),
      name: "My Approvals",
    },
    [Routes.MY_APPROVALS_CARDS]: {
      component: dynamicWrapper(() => import("../features/MyCardRequestApprovalsPage")),
      name: "My Approvals",
    },
    [Routes.MY_APPROVALS_EXPENSES]: {
      component: dynamicWrapper(() => import("../features/MyApprovalsPage")),
      name: "My Approvals",
    },
    [Routes.EXPENSEHUB_EXPENSE_DETAILS]: {
      component: dynamicWrapper(() => import("../features/ExpenseDetailsPage")),
      name: "Expense Details Review",
    },
    [Routes.MY_APPROVALS_EXPENSE_DETAILS]: {
      component: dynamicWrapper(() => import("../features/ExpenseDetailsPage")),
      name: "Expense Details Review",
    },
    [Routes.REPORTS]: {
      component: dynamicWrapper(() => import("../features/ReportsPage")),
      name: "Reports",
    },
    [Routes.INSIGHTS_TRAVEL_TAB]: {
      component: dynamicWrapper(() => import("../features/TravelInsightsPage")),
      name: "Travel Insights",
    },
    [Routes.INSIGHTS]: {
      component: dynamicWrapper(() => import("../features/InsightsPage")),
      name: "Insights",
    },
    [Routes.INSIGHTS_EXPENSE]: {
      component: dynamicWrapper(() => import("../features/InsightsPage")),
      name: "Insights",
    },
    [Routes.RULES_CUSTOM_APPROVAL]: {
      component: dynamicWrapper(() => import("../features/CustomApprovalRulesPage")),
      name: "Approval Rules",
    },
    [Routes.EXPENSE_FORMS_EDIT]: {
      component: dynamicWrapper(() => import("../features/ExpenseFormEditPage")),
      name: "Expense form",
    },
    [Routes.EXPORT_CONFIGURATION]: {
      component: dynamicWrapper(() => import("../features/ExportConfigurationPage")),
      name: "Export Configuration",
    },
    [Routes.LOGIN_SSO_CONFIGURATION]: {
      component: dynamicWrapper(() => import("../features/LoginSSOConfigurationPage")),
      name: "Login/SSO",
    },
    [Routes.HRIS]: {
      component: dynamicWrapper(() => import("../features/HRISPage")),
      name: "HRIS",
    },
    [Routes.THEME_CONFIGURATION]: {
      component: dynamicWrapper(() => import("../features/ThemeConfigurationPage")),
      name: "Theme",
    },
    [Routes.SSO_REDIRECT]: {
      component: dynamicWrapper(() => import("../features/SSOredirectPage")),
      name: "SSO redirect",
    },
    [Routes.GUEST_TRAVELERS]: {
      component: dynamicWrapper(() => import("../features/GuestTravelersPage")),
      name: "Guest Travelers",
    },
    [Routes.GUEST_TRAVELER_EDIT]: {
      component: dynamicWrapper(() => import("../features/UserEditPage")),
      name: "Guest Traveler Edit",
    },
    [Routes.SANDBOX_TOOLS]: {
      component: dynamicWrapper(() => import("../features/SandboxToolsPage")),
      name: "Sandbox Tools",
    },
    [Routes.SANDBOX_TOOLS_CARDS]: {
      component: dynamicWrapper(() => import("../features/SandboxCardsPage")),
      name: "Sandbox Cards Page",
    },
    [Routes.SANDBOX_TOOLS_TRANSACTION]: {
      component: dynamicWrapper(() => import("../features/SyntheticTransactionPage")),
      name: "Synthetic Transaction Page",
    },
    [Routes.GUEST_TRAVELER_INFO]: {
      component: dynamicWrapper(() => import("../features/GuestTravelerInfoPage")),
      name: "Guest Traveler Info",
    },
    [Routes.CARDS]: {
      component: dynamicWrapper(() => import("../features/CardsPage")),
      name: "Cards",
    },
    [Routes.ADMIN_CARD_DETAILS]: {
      component: dynamicWrapper(() => import("../features/CardDetailsPage")),
      name: "Card Details",
    },
    [Routes.SPEND_CARD_DETAILS]: {
      component: dynamicWrapper(() => import("../features/CardDetailsPage")),
      name: "Card Details",
    },
  };

  // Get name from ./menu.js or just set it in the router data.
  const menuData = getFlatMenuData(getMenuDataWithChildren());

  // Route configuration data
  const routerData = {};
  // The route matches the menu
  Object.keys(routerConfig).forEach((path) => {
    // Regular match item name
    // eg.  router /user/:id === /user/chen
    const pathRegexp = pathToRegexp(path);
    const menuKey = Object.keys(menuData).find((key) => pathRegexp.test(`${key}`));
    let menuItem = {};
    // If menuKey is not empty
    if (menuKey) {
      menuItem = menuData[menuKey];
    }
    let router = routerConfig[path];
    router = {
      ...router,
      name: router.name || menuItem.name,
      authority: router.authority || menuItem.authority,
      hideInBreadcrumb: router.hideInBreadcrumb || menuItem.hideInBreadcrumb,
    };
    routerData[path] = router;
  });
  return routerData;
};
