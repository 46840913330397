import { get } from "lodash";
import moment from "moment-timezone";

import { Notifier } from "@core/ui-legacy";

import { constructLabelTemplate, fileDownload } from ".";
import ExpenseErpExportDetails from "../components/AdvancedAsyncProgressDialog/ExpenseErpExportDetails";
import MoreInfoBtn from "../components/AdvancedAsyncProgressDialog/FailedCardsInfo/MoreInfoBtn";
import { showErrorToast, showSuccessToast } from "../components/CardToast";
import {
  APPROVE_ALL_EXPENSES,
  ARCHIVE_ALL_EXPENSES,
  CREATE_BULK_JOB,
  CREATE_EXPENSE_EXPORT,
  CREATE_EXPENSE_EXPORT_CHANGES,
} from "../services/graphql/mutations";
import { GET_ASYNC_JOBS, GET_EXPENSE_EXPORT, GET_JOB } from "../services/graphql/queries";
import API from "../services/rest/api";
import { ASYNC_JOB_STATUS } from "./ASYNC_JOB_STATUS";
import { REPORTS } from "./routes";

export const dateFormat = "MM-DD-Y";
export const comdataTZ = "US/Central";

export const ASYNC_JOB = {
  ApproveAll: "expenses.approveall",
  ArchiveAll: "expenses.archiveall",
  Unarchive: "unarchiveExpense",
  ExpenseERPExport: "erpExpenseExport",
  ExpenseExport: "expenses.export",
  ExpenseExportChanges: "expenses.export.changes",
  ExpensesExportsInsights: "expenses.exports.insights",
  CardsLimitChange: "cards.limit.changes",
  CardsLock: "cards.lock",
  CardsUnlock: "cards.unlock",
  CardsClose: "cards.close",
};

export * from "./ASYNC_JOB_STATUS";

const { Active, Pending, PendingChanges, Complete, CompleteWithWarning, NoOperation, Failed } =
  ASYNC_JOB_STATUS;

const {
  ApproveAll,
  ArchiveAll,
  Unarchive,
  ExpenseERPExport,
  ExpenseExport,
  ExpenseExportChanges,
  ExpensesExportsInsights,
  CardsLimitChange,
  CardsLock,
  CardsUnlock,
  CardsClose,
} = ASYNC_JOB;

/**
 * Creates a new async job to save into Apollo cache
 * @param job
 * @returns {{__typename}}
 */
const makeAsyncJob = (job) => {
  if (!job.__typename) job.__typename = "AsyncJob";
  if (!job.pollInterval) job.pollInterval = 1500;
  if (!job.variables) job.variables = {};
  if (!job.variables.__typename) job.variables.__typename = "AsyncJobVariables";
  if (!job.status) job.status = Pending;
  if (!job.count) job.count = 1;
  return job;
};

/**
 * Insert/Update/Delete job in Apollo cache
 * @param job
 * @param client
 * @returns {{noop: boolean}|{hasPendingJob, jobs}}
 */
export const processAsyncJobs = (job, client) => {
  if (!job || !job.ID) {
    console.error("Missing param in processAsyncJobs(job, client)", {
      job,
      client,
    });
    return { noop: true };
  }
  const { asyncJobs } = client.readQuery({ query: GET_ASYNC_JOBS });
  const jobs = [...asyncJobs.jobs];
  const idx = jobs.findIndex((j) => j.ID === job.ID);
  const shouldInsertJob = idx < 0 && job.jobType;
  const shouldDeleteJob = idx >= 0 && job.shouldDelete;
  const shouldUpdateJob = idx >= 0 && !job.shouldDelete;
  if (shouldInsertJob) {
    jobs.push(makeAsyncJob(job));
  } else if (shouldDeleteJob) {
    jobs.splice(idx, 1);
  } else if (shouldUpdateJob) {
    jobs[idx] = makeAsyncJob({ ...jobs[idx], ...job });
  } else {
    console.warn("No-op in processAsyncJobs():", { job, jobs });
    return { noop: true };
  }
  const pendingJobs = jobs.filter((j) => [Pending, PendingChanges, Active].includes(j.status));
  const hasPendingJob = pendingJobs.length > 0;

  return { jobs, hasPendingJob };
};

/**
 * Convenience method to refetch an array of queries
 * @param queries
 * @param client
 */
const refetchQueries = (queries, client) => {
  const findQueries = (manager, name) => {
    const matching = [];
    manager.queries.forEach((q) => {
      if (q.observableQuery && q.observableQuery.queryName === name) {
        matching.push(q);
      }
    });
    return matching;
  };
  const refetchQueryByName = (name) =>
    Promise.all(findQueries(client.queryManager, name).map((q) => q.observableQuery.refetch()));
  queries.forEach((queryName) => {
    refetchQueryByName(queryName);
  });
};

/**
 * Get job details by jobID after a job is complete
 * @param job
 * @param client
 * @returns {Promise<Promise<*|Promise<any>>|*>}
 */
const queryJobDetails = async (job, client) => {
  const { query } = ASYNC_JOB_PARAMS[job.jobType];
  if (query) {
    return client
      .query({
        query,
        variables: { id: job.ID },
        fetchPolicy: "network-only",
      })
      .then(({ data }) => data);
  }
  return Promise.resolve(null);
};

/**
  Helper func to download file from URI
*/
const downloadFile = (uri, fileName, fileFormat) => {
  API.downloadFileFromURI(uri, fileFormat).then((blob) => {
    fileDownload(blob, fileName, fileFormat);
  });
};

/**
 * Handler for ExpenseExport
 * @param payload
 */
const handleExpenseExport = (payload) => {
  const { job, data, client, route, onAsyncCallback } = payload;
  queryJobDetails(job, client)
    .then((jobDetails) => {
      const jobStatus = get(data, "job.status", null);
      const params = get(ASYNC_JOB_PARAMS[job.jobType].completeMessage, jobStatus, null);

      const { name, format, startDate, endDate, uri } = jobDetails.expenseExport;

      if (params) {
        onAsyncCallback(params);
      }

      switch (jobStatus) {
        case Complete:
          if (route === REPORTS) {
            refetchQueries(["expenseExports"], client);
          }
          if (uri) {
            const st = moment.tz(startDate, comdataTZ).format(dateFormat);
            const end = moment.tz(endDate, comdataTZ).format(dateFormat);
            const correctFormat = format === "xls" ? "xlsx" : format;
            downloadFile(uri, `${name}-Export-${st}-${end}.${correctFormat}`, correctFormat);
          }
          break;
        default:
          break;
      }
    })
    .catch((err) => {
      console.error(err);
    });
};

/**
 * Handler for ExpenseExportChanges
 * @param payload
 */
const handleExpenseExportChanges = (payload) => {
  const { job, data, client, route, onAsyncCallback } = payload;
  queryJobDetails(job, client)
    .then((jobDetails) => {
      const jobStatus = get(data, "job.status", null);
      const params = get(ASYNC_JOB_PARAMS[job.jobType].completeMessage, jobStatus, null);

      const { name, format, timeStamp, changeReports } = jobDetails.expenseExport;

      if (params) {
        if (jobStatus === Complete) {
          onAsyncCallback({
            ...params,
            description: constructLabelTemplate(params.description, { name }),
          });
        } else {
          onAsyncCallback({
            ...params,
          });
        }
      }

      // Get the last change report
      const changeReport = changeReports.length ? changeReports[0] : null;

      switch (jobStatus) {
        case Complete:
          if (route === REPORTS) {
            refetchQueries(["expenseExports"], client);
          }
          if (changeReport && changeReport.uri) {
            const correctFormat = format === "xls" ? "xlsx" : format;
            downloadFile(
              changeReport.uri,
              `${name}-Changes-${moment(timeStamp).format(dateFormat)}.${correctFormat}`,
              correctFormat,
            );
          }
          break;
        default:
          break;
      }
    })
    .catch((err) => {
      console.error(err);
    });
};

/**
 * Handler for Syncing expenses with ERP
 */
const handleExpenseERPSync = (payload) => {
  const { job, data, client } = payload;
  queryJobDetails(job, client)
    .then((jobDetails) => {
      const jobStatus = get(data, "job.status", null);
      const operations = get(jobDetails, "job.operations", []);
      const count = operations.length;

      switch (jobStatus) {
        case Complete:
        case CompleteWithWarning:
        case NoOperation:
        case Failed:
          if (count > 0) {
            refetchQueries(["expenses", "expensesView"], client);
          }

          break;

        default:
          break;
      }
    })
    .catch((err) => console.log(err));
};

const handleCardsChanges = (payload) => {
  const { job, data, client, hasCardManagementEnhancements } = payload;
  queryJobDetails(job, client)
    .then((jobDetails) => {
      const jobStatus = get(data, "job.status", null);
      const operations = get(jobDetails, "job.operations", []);
      const count = operations.length;

      if (count > 0) {
        refetchQueries(["cards"], client);
        const params = get(ASYNC_JOB_PARAMS[job.jobType].completeMessage, jobStatus, null);
        const { updatedCards, failedCards, failedAndUpdatedCards } = operations.reduce(
          (counts, item) => {
            if (item.status === "completed") {
              counts.updatedCards += 1;
            } else if (item.status === "failed") {
              counts.failedCards += 1;
            }
            if (
              (jobStatus === Failed && item.status === "failed") ||
              (jobStatus === Complete && item.status === "completed")
            ) {
              counts.failedAndUpdatedCards += 1;
            }
            return counts;
          },
          { updatedCards: 0, failedCards: 0, failedAndUpdatedCards: 0 },
        );

        if (jobStatus === CompleteWithWarning) {
          Notifier.destroy();
          if (updatedCards !== 0) {
            Notifier.notification({
              type: "success",
              message: constructLabelTemplate(params.successMessage, {
                count: updatedCards,
                cardLabelMulti: updatedCards === 1 ? "" : "s",
              }),
              description: params.successDescription,
            });
          }
          Notifier.notification({
            type: "error",
            message: constructLabelTemplate(params.message, {
              count: failedCards,
              cardLabelMulti: failedCards === 1 ? "" : "s",
            }),
            description: params.description,
            btn: <MoreInfoBtn />,
            duration: null,
          });
        } else {
          const constructedMessage = constructLabelTemplate(params.message, {
            count: failedAndUpdatedCards,
            cardLabelMulti: failedAndUpdatedCards === 1 ? "" : "s",
          });
          if (hasCardManagementEnhancements) {
            const toastFn = params.type === "error" ? showErrorToast : showSuccessToast;
            toastFn(constructedMessage);
          } else {
            Notifier.notification({
              type: params.type,
              message: constructedMessage,
              description: params.description,
            });
          }
        }
      }
    })
    .catch((err) => console.log(err));
};

const handleUnarchive = (payload) => {
  const { job, data, client, callback } = payload;
  let status = "";
  job.ID = data?.createBulkJob?.ID;

  const waitForJobEnd = () => {
    queryJobDetails(job, client)
      .then((jobDetails) => {
        status = get(jobDetails, "job.status", null);
        switch (status) {
          case Active:
            setTimeout(waitForJobEnd, makeAsyncJob(job).pollInterval);
            break;
          case Complete:
          case CompleteWithWarning:
          case NoOperation:
          case Failed:
            callback({ updateExpensesStatus: jobDetails.job.operations });
            refetchQueries(["expenses", "expensesView"], client);
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        callback({ updateExpensesStatus: 0 });
        console.error(err);
      });
  };
  waitForJobEnd();
};

/**
 * Handler for a bulk status change (ApproveAll, ArchiveAll, Unarchive)
 * @param payload
 */
const handleBulkExpenseStatusChange = (payload) => {
  const { job, data, client, onAsyncCallback } = payload;
  queryJobDetails(job, client)
    .then((jobDetails) => {
      const jobStatus = get(data, "job.status", null);
      const params = get(ASYNC_JOB_PARAMS[job.jobType].completeMessage, jobStatus, null);
      const completedIDs = get(jobDetails, "completedIDs", []);
      const count = completedIDs.length;

      switch (jobStatus) {
        case Complete:
        case CompleteWithWarning:
        case NoOperation:
        case Failed:
          if (count > 0) {
            refetchQueries(["expenses", "expensesView", "spendKPI"], client);
          }
          if (params)
            onAsyncCallback({
              ...params,
              description: constructLabelTemplate(params.description, {
                count: count === 0 ? "Zero" : count,
              }),
            });
          break;
        default:
          break;
      }
    })
    .catch((err) => {
      console.error(err);
    });
};

/**
 * Convenience function to check if there are jobs of specific type running
 * @param jobs
 */
export const hasJob = (jobTypes) => (jobs) =>
  jobs.some(
    (job) =>
      (!jobTypes ||
        jobTypes.includes(
          job.jobType === "genericbulkoperation" && job.operationType
            ? job.operationType
            : job.jobType,
        )) &&
      [Pending, PendingChanges, Active].includes(job.status),
  );

export const hasExpenseExportJob = hasJob([ExpenseExport, ExpensesExportsInsights]);
export const hasErpExpenseExportJob = hasJob([ExpenseERPExport]);
export const hasBulkExpenseStatusChangeJob = hasJob([ApproveAll, ArchiveAll]);

const getExpenseExportPendingMessage = (job) => {
  if (job?.expenseExportProgress?.type === "additional_info_gathering") {
    return "Gathering additional info for export";
  }

  if (job?.expenseExportProgress?.type === "expense_gathering") {
    const progress =
      job.expenseExportProgress.gathered && job.expenseExportProgress.total
        ? `(${job.expenseExportProgress.gathered} / ${job.expenseExportProgress.total})`
        : "";

    return ["Gathering expenses for export", progress].filter(Boolean).join(" ");
  }

  return "Generating export";
};

/**
 * Async Job definitions
 * @type {{}}
 */
export const ASYNC_JOB_PARAMS = {
  [ApproveAll]: {
    mutation: APPROVE_ALL_EXPENSES,
    query: null,
    pendingMessage: "Approving expenses",
    completeMessage: {
      [Complete]: {
        type: "success",
        message: "Expenses approved",
        description: `\${count} audit cleared expenses were approved.`,
      },
      [CompleteWithWarning]: {
        type: "success",
        message: "Expenses approved",
        description: `\${count} audit cleared expenses were approved.`,
      },
      [NoOperation]: {
        type: "success",
        message: "Expenses approved",
        description: `\${count} audit cleared expenses were approved.`,
      },
      [Failed]: {
        type: "error",
        message: "Expenses bulk action",
        description: "There was an error updating expenses. Please try again.",
      },
    },
    onComplete: (payload) => {
      handleBulkExpenseStatusChange(payload);
    },
  },
  [ArchiveAll]: {
    mutation: ARCHIVE_ALL_EXPENSES,
    query: null,
    pendingMessage: "Archiving expenses",
    completeMessage: {
      [Complete]: {
        type: "success",
        message: "Expenses archived",
        description: `\${count} audit cleared expenses were archived.`,
      },
      [CompleteWithWarning]: {
        type: "success",
        message: "Expenses archived",
        description: `\${count} audit cleared expenses were archived.`,
      },
      [NoOperation]: {
        type: "success",
        message: "Expenses archived",
        description: `\${count} audit cleared expenses were archived.`,
      },
      [Failed]: {
        type: "error",
        message: "Expenses bulk action",
        description: "There was an error updating expenses. Please try again.",
      },
    },
    onComplete: (payload) => {
      handleBulkExpenseStatusChange(payload);
    },
  },
  [ExpenseExport]: {
    mutation: CREATE_EXPENSE_EXPORT,
    query: GET_EXPENSE_EXPORT,
    pendingMessage: getExpenseExportPendingMessage,
    completeMessage: {
      [Complete]: {
        type: "success",
        message: "Export complete",
        description: "Expenses exported",
      },
      [NoOperation]: {
        type: "success",
        message: "Export file empty",
        description: "There are no items to export for the time range requested.",
      },
      [Failed]: {
        type: "error",
        message: "Export failed",
        description: "There was an error exporting expenses. Please try again.",
      },
    },
    onComplete: (payload) => {
      handleExpenseExport(payload);
    },
  },
  [ExpensesExportsInsights]: {
    mutation: CREATE_EXPENSE_EXPORT,
    query: GET_EXPENSE_EXPORT,
    pendingMessage: getExpenseExportPendingMessage,
    completeMessage: {
      [Complete]: {
        type: "success",
        message: "Export complete",
        description: "Expenses exported",
      },
      [NoOperation]: {
        type: "success",
        message: "Export file empty",
        description: "There are no items to export for the time range requested.",
      },
      [Failed]: {
        type: "error",
        message: "Export failed",
        description: "There was an error exporting expenses. Please try again.",
      },
    },
    onComplete: (payload) => {
      handleExpenseExport(payload);
    },
  },
  [ExpenseERPExport]: {
    mutation: CREATE_BULK_JOB,
    query: GET_JOB,
    isAdvanced: true,
    pendingMessage: "Syncing expenses",
    progressMessage: `\${processed} of \${total} expenses`,
    detailsComponent: (job, stats) => <ExpenseErpExportDetails job={job} stats={stats} />,
    completeMessage: {
      [Complete]: {
        type: "success",
        message: "All selected expenses were synced!",
        description: "Expenses exported",
      },
      [NoOperation]: {
        type: "success",
        message: "Export file empty",
        description: "There are no items to export for the time range requested.",
      },
      [CompleteWithWarning]: {
        type: "error",
        message: `\${errors} expenses weren't synced`,
      },
      [Failed]: {
        type: "error",
        message: `\${errors} expenses weren't synced`,
      },
    },
    onComplete: (payload) => {
      handleExpenseERPSync(payload);
    },
  },
  [CardsLimitChange]: {
    mutation: CREATE_BULK_JOB,
    query: GET_JOB,
    isAdvanced: true,
    pendingMessage: "Changing card limits...",
    isCardAsyncJob: true,
    progressMessage: `\${processed} of \${total} cards`,
    completeMessage: {
      [Complete]: {
        type: "success",
        message: `\${count} limit\${cardLabelMulti} changed`,
        description: "Card limits have been successfully changed.",
      },
      [NoOperation]: {
        type: "success",
        message: `\${count} - No cards to change`,
        description: "There are no items to change.",
      },
      [CompleteWithWarning]: {
        type: "success",
        message: `\${count} card\${cardLabelMulti} remain with the old limit`,
        successMessage: `\${count} limit\${cardLabelMulti} changed`,
        description: "Some limits cannot be changed at this time. Please try again.",
        successDescription: "Card limit have been successfully changed.",
      },
      [Failed]: {
        type: "error",
        message: `\${count} card\${cardLabelMulti} remain with the old limit`,
        description: "Some limits cannot be changed at this time. Please try again.",
      },
    },
    onComplete: (payload) => {
      handleCardsChanges(payload);
    },
  },
  [CardsLock]: {
    mutation: CREATE_BULK_JOB,
    query: GET_JOB,
    isAdvanced: true,
    pendingMessage: "Locking cards...",
    isCardAsyncJob: true,
    progressMessage: `\${processed} of \${total} cards`,
    completeMessage: {
      [Complete]: {
        type: "success",
        message: `\${count} card\${cardLabelMulti} locked`,
        description: "Cards have been locked successfully.",
      },
      [NoOperation]: {
        type: "success",
        message: `\${count} - No cards to lock`,
        description: "There are no items to lock.",
      },
      [CompleteWithWarning]: {
        type: "error",
        message: `\${count} card\${cardLabelMulti} remain not locked`,
        description: "Some cards cannot be locked at this time. Please try again.",
        successMessage: `\${count} card\${cardLabelMulti} locked`,
        successDescription: "Cards have been locked successfully.",
      },
      [Failed]: {
        type: "error",
        message: `\${count} card\${cardLabelMulti} remain not locked`,
        description: "Some cards cannot be locked at this time. Please try again.",
      },
    },
    onComplete: (payload) => {
      handleCardsChanges(payload);
    },
  },
  [CardsUnlock]: {
    mutation: CREATE_BULK_JOB,
    query: GET_JOB,
    isAdvanced: true,
    pendingMessage: "Unlocking cards...",
    isCardAsyncJob: true,
    progressMessage: `\${processed} of \${total} cards`,
    completeMessage: {
      [Complete]: {
        type: `success`,
        message: `\${count} card\${cardLabelMulti} unlocked`,
        description: "Cards have been unlocked successfully.",
      },
      [NoOperation]: {
        type: "success",
        message: `\${count} - No cards to lock`,
        description: "There are no items to unlock.",
      },
      [CompleteWithWarning]: {
        type: "error",
        message: `\${count} card\${cardLabelMulti} remain not unlocked`,
        description: "Some cards cannot be unlocked at this time. Please try again.",
        successMessage: `\${count} card\${cardLabelMulti} unlocked`,
        successDescription: "Cards have been unlocked successfully.",
      },
      [Failed]: {
        type: "error",
        message: `\${count} card\${cardLabelMulti} remain not unlocked`,
        description: "Some cards cannot be unlocked at this time. Please try again.",
      },
    },
    onComplete: (payload) => {
      handleCardsChanges(payload);
    },
  },
  [CardsClose]: {
    mutation: CREATE_BULK_JOB,
    query: GET_JOB,
    isAdvanced: true,
    pendingMessage: `Closing cards...`,
    isCardAsyncJob: true,
    progressMessage: `\${processed} of \${total} cards`,
    completeMessage: {
      [Complete]: {
        type: `success`,
        message: `\${count} card\${cardLabelMulti} closed`,
        description: "Cards have been closed successfully.",
      },
      [NoOperation]: {
        type: "success",
        message: `\${count} - No cards to close`,
        description: "There are no items to close.",
      },
      [CompleteWithWarning]: {
        type: "error",
        message: `\${count} card\${cardLabelMulti} not closed`,
        description: "Some cards cannot be closed at this time. Please try again.",
        successMessage: `\${count} card\${cardLabelMulti} closed`,
        successDescription: "Cards have been closed successfully.",
      },
      [Failed]: {
        type: "error",
        message: `\${count} card\${cardLabelMulti} not closed`,
        description: "Some cards cannot be closed at this time. Please try again.",
      },
    },
    onComplete: (payload) => {
      handleCardsChanges(payload);
    },
  },
  [ExpenseExportChanges]: {
    mutation: CREATE_EXPENSE_EXPORT_CHANGES,
    query: GET_EXPENSE_EXPORT,
    pendingMessage: "Generating change report",
    completeMessage: {
      [Complete]: {
        type: "success",
        message: "Check for changes complete",
        description: `"\${name}"`,
      },
      [CompleteWithWarning]: {
        type: "success",
        message: "Check for changes complete",
        description: "There are no changes.",
      },
      [NoOperation]: {
        type: "success",
        message: "Check for changes complete",
        description: "There are no changes.",
      },
      [Failed]: {
        type: "error",
        message: "Check for changes failed",
        description: "There was an error checking for changes. Please try again.",
      },
    },
    onComplete: (payload) => {
      handleExpenseExportChanges(payload);
    },
  },
  [Unarchive]: {
    mutation: CREATE_BULK_JOB,
    query: GET_JOB,
    onComplete: (payload) => {
      handleUnarchive(payload);
    },
  },
};
