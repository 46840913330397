import { Badge as AntBadge } from "antd";
import styled from "styled-components";

const Badge = styled(AntBadge)`
  sup {
    box-shadow: none !important;
    font-weight: 500;
    padding-top: 0.1rem;
  }
`;

export default Badge;
