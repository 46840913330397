import { memo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Tag } from "@core/ui-legacy";
import Colors from "@core/ui-legacy/themes/colors";
import { styleConstructor } from "@core/ui-legacy/utils";

import { ExpenseStatuses, ExpensesViewNames } from "../../utils/expense";

const TagStyled = styled(Tag)`
  font-size: 0.625rem !important;
  font-weight: 500 !important;
  ${({ styling }) => styling && styleConstructor(styling)};
  line-height: 14px !important;
  padding: 0.0625rem 0.5rem !important;
  border: none;
  border-radius: 0.625rem !important;
  color: ${({ textcolor }) => textcolor} !important;
  letter-spacing: 0 !important;
  margin-right: 0 !important;
  margin-bottom: 0 !important;
  text-transform: uppercase;
`;

const viewNames = new Set([
  ExpensesViewNames.FMReviewed,
  ExpensesViewNames.FMArchived,
  ExpensesViewNames.FMAll,
  ExpensesViewNames.EADone,
]);

const ExpenseStatus = (props) => {
  const { status, viewName, rejectedDateTime, approvedDateTime, isCardExpense, ...otherProps } =
    props;

  const gettextcolor = (viewType, text) => {
    if (viewNames.has(viewType)) {
      switch (text) {
        case "Approved":
          return Colors.success_green;
        case "Denied":
          return Colors.danger_red;
        default:
          break;
      }
    }
    return Colors.monumental;
  };

  const getTextByViewName = (viewType, isApproved, defaultText) => {
    if (viewNames.has(viewType)) {
      return isApproved ? "Approved" : "Denied";
    }
    return defaultText;
  };

  const isExpenseApproved = (isCardExpense, approvedDateTime, rejectedDateTime) =>
    isCardExpense || (approvedDateTime && !rejectedDateTime);

  const getText = (statusCode) => {
    let text = "";
    switch (statusCode) {
      case ExpenseStatuses.PendingSettlement:
        text = "Pending Transaction";
        break;
      case ExpenseStatuses.Approved:
        text = getTextByViewName(
          viewName,
          isExpenseApproved(isCardExpense, approvedDateTime, rejectedDateTime),
          "Reviewed",
        );
        break;
      case ExpenseStatuses.Unsubmitted:
      case ExpenseStatuses.ReadyToSubmit:
        text = "Unsubmitted";
        break;
      case ExpenseStatuses.Submitted:
        text = "In Review";
        break;
      case ExpenseStatuses.ReadyToPost:
        text = getTextByViewName(
          viewName,
          isExpenseApproved(isCardExpense, approvedDateTime, rejectedDateTime),
          "Archived",
        );
        break;
      case ExpenseStatuses.Rejected:
        text = "Denied";
        break;
      default:
        break;
    }
    return text;
  };

  const text = getText(status);

  return (
    <TagStyled {...otherProps} color={Colors.lunar_gray} textcolor={gettextcolor(viewName, text)}>
      {text}
    </TagStyled>
  );
};

ExpenseStatus.propTypes = {
  status: PropTypes.string,
  viewName: PropTypes.string,
  rejectedDateTime: PropTypes.string,
  approvedDateTime: PropTypes.string,
  isCardExpense: PropTypes.bool,
};

export default memo(ExpenseStatus);
