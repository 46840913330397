import { useMemo, useState } from "react";
import _, { mapValues } from "lodash";
import PropTypes from "prop-types";
import { useMutation, useQuery } from "react-apollo";
import styled from "styled-components";

import { Drawer } from "@core/ui-legacy";
import Colors from "@core/ui-legacy/themes/colors";

import { SET_CARDS_TO_ASYNC_JOB } from "../../services/graphql/mutations";
import { constructLabelTemplate } from "../../utils";
import { ASYNC_JOB_STATUS } from "../../utils/ASYNC_JOB_STATUS";
import SyncProgress from "../SyncProgress";
import CardsSidePanel, { GET_CARDS_ASYNC_JOBS } from "./FailedCardsInfo/CardsSidePanel";
import SimpleProgressBar from "./SimpleProgressBar";

const DialogWrapper = styled.div`
  position: fixed;
  bottom: 1.5rem;
  padding: 0 1.5rem;
  z-index: 9;
  pointer-events: none;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const SyncProgressStyled = styled(SyncProgress)`
  position: relative !important;
  height: 100% !important;
  pointer-events: auto !important;
  min-width: 18.75rem !important;
  width: 33% !important;
  border: 1px solid ${Colors.cool_gray} !important;
`;

const {
  Pending,
  Active,
  Complete,
  CompleteWithWarning,
  NoOperation,
  Failed,
  Error: Errored,
  OperationComplete,
} = ASYNC_JOB_STATUS;

const AdvancedAsyncProgressDialog = ({ job, config, onCompletedClose, renderDetails }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [setCardsAsyncJobs] = useMutation(SET_CARDS_TO_ASYNC_JOB);
  const { data: cardsAsyncJobsData } = useQuery(GET_CARDS_ASYNC_JOBS);

  const stats = useMemo(() => {
    const accumulator = {
      errors: 0,
      processed: 0,
      percentage: 0,
      total: 0,
    };

    if (!job || !Array.isArray(job.operations)) {
      return accumulator;
    }

    accumulator.total = job.operations.length;

    job.operations.reduce((acc, op) => {
      switch (op.status) {
        case Complete:
        case OperationComplete: // added as constant OperationComplete in cv3-5852
        case CompleteWithWarning:
        case NoOperation:
          acc.processed += 1;
          break;
        case Failed:
        case Errored:
          acc.processed += 1;
          acc.errors += 1;
          break;
        default:
          break;
      }

      acc.percentage = (acc.processed / acc.total) * 100;
      return acc;
    }, accumulator);

    return accumulator;
  }, [job]);

  const { message, progressText, hasErrors } = useMemo(() => {
    const stringStats = mapValues(stats, (val) => val.toString());
    let err = false;

    if (!config || !job) {
      return { message: "", progressText: "" };
    }

    let msg;

    const { status } = job;

    if (status === Failed || status === Errored) {
      err = true;
      msg = constructLabelTemplate(config.completeMessage[Failed].message, stringStats);
    } else if (status === CompleteWithWarning || (status === Complete && stats.errors)) {
      err = true;
      msg = constructLabelTemplate(
        config.completeMessage[CompleteWithWarning].message,
        stringStats,
      );
      if (config.isCardAsyncJob) {
        const cardsAsyncJobs = _.get(cardsAsyncJobsData, "cardsAsyncJob.cards", []);
        setCardsAsyncJobs({
          variables: { cardsAsyncJobsData: cardsAsyncJobs, activeJob: false },
        });
      }
    } else if (job.status === Complete) {
      msg = config.completeMessage[Complete].message;
      if (config.isCardAsyncJob) {
        const cardsAsyncJobs = _.get(cardsAsyncJobsData, "cardsAsyncJob.cards", []);
        setCardsAsyncJobs({
          variables: { cardsAsyncJobsData: cardsAsyncJobs, activeJob: false },
        });
      }
    } else {
      msg = config.pendingMessage;
    }

    return {
      message: msg,
      hasErrors: err,
      progressText: constructLabelTemplate(config.progressMessage, stringStats),
    };
  }, [cardsAsyncJobsData, config, job, setCardsAsyncJobs, stats]);

  const isInProgress = job.status === Pending || job.status === Active;

  const handleOpenDrawer = () => {
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
    if (!isInProgress && onCompletedClose) {
      onCompletedClose();
    }
  };

  return (
    <>
      {config?.isCardAsyncJob ? (
        <>
          {isInProgress && <SimpleProgressBar {...{ message: config.pendingMessage }} />}
          <CardsSidePanel {...{ job, onCompletedClose }} />
        </>
      ) : (
        <>
          <DialogWrapper>
            {!drawerOpen && (
              <SyncProgressStyled
                data-testid="advanced-async-progress-dialog-sync-progress"
                message={message}
                isError={hasErrors}
                percent={stats.percentage}
                progressText={progressText}
                onViewDetails={handleOpenDrawer}
              />
            )}
          </DialogWrapper>
          <Drawer
            data-testid="advanced-async-progress-dialog-details-drawer"
            visible={drawerOpen}
            onClose={handleCloseDrawer}
            mask={false}
            width={540}
            title={<h2>{isInProgress ? "Sync Progress" : "Sync Complete"}</h2>}
          >
            <SyncProgress
              message={message}
              isError={hasErrors}
              percent={stats.percentage}
              progressText={progressText}
            />
            {renderDetails && renderDetails(job, stats)}
          </Drawer>
        </>
      )}
    </>
  );
};

AdvancedAsyncProgressDialog.propTypes = {
  job: PropTypes.object.isRequired,
  config: PropTypes.object,
  onCompletedClose: PropTypes.func,
  renderDetails: PropTypes.func,
};

export default AdvancedAsyncProgressDialog;
