import PropTypes from "prop-types";
import styled from "styled-components";

import { Button } from "@core/ui-legacy";

const ModalDescription = styled.div`
  padding-bottom: 3.25rem;
  max-width: 89%;

  b {
    font-weight: 600;
  }
`;

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 1.625rem;
  padding-bottom: 1.5rem;

  button:not(:last-child) {
    margin-right: 0.5rem;
  }

  button {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
`;
const SuccessCard = ({ onCancel, navigate, phone, cardsToActivate = [] }) => {
  const getSubTitle = () => {
    if (cardsToActivate.length) {
      return (
        <>
          You may now start using your CenterCard to make transactions.{" "}
          <b>
            You still have {cardsToActivate.length} CenterCard
            {cardsToActivate.length > 1 ? "s " : " "}
            that {cardsToActivate.length > 1 ? "have" : "has"} not been activated yet. Would you
            like to activate now?
          </b>
        </>
      );
    }
    return "You may now start using your CenterCard to make transactions. Continue to enable fraud alerts.";
  };
  const getAction = () => {
    if (cardsToActivate.length) {
      return () => navigate(1);
    }
    if (!phone) {
      return () => navigate(4);
    }
    return onCancel;
  };
  return (
    <>
      <div className="ant-modal-header">
        <div className="ant-modal-title">Card activated successfully!</div>
      </div>
      <ModalDescription>{getSubTitle()}</ModalDescription>
      <ActionsWrapper>
        {(!phone || !!cardsToActivate.length) && (
          <Button
            id="cancel-dialog-btn"
            key="cancel-dialog-btn"
            data-testid="cancel-dialog-btn"
            type="cancel"
            size="default"
            onClick={onCancel}
          >
            Do it later
          </Button>
        )}
        <Button
          id="next-dialog-btn"
          key="next-dialog-btn"
          data-testid="next-dialog-btn"
          type="primary"
          size="default"
          htmlType="submit"
          onClick={getAction()}
        >
          {!phone || !!cardsToActivate.length ? "Next" : "Close"}
        </Button>
      </ActionsWrapper>
    </>
  );
};

SuccessCard.propTypes = {
  onCancel: PropTypes.func,
  navigate: PropTypes.func,
  phone: PropTypes.string,
  cardsToActivate: PropTypes.array,
};

export default SuccessCard;
