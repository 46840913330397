import "./typedef";

import Colors from "@core/ui-legacy/themes/colors";

/**
 * @typedef CENTER_BRANDING_DEFAULTS
 * @type {object}
 * @property {OrderedMenuLink[]} navLinks
 * @property {string} primaryColor
 * @property {string} secondaryColor
 * @property {string} linkColor
 * @property {string} siteTitle
 * @property {string} backgroundColor
 * @property {string} activeColor
 * @property {string} textElementColor
 * @property {string} logoLink
 * @property {string} mainFont
 * @property {string} textColor
 */
export const CENTER_BRANDING_DEFAULTS = {
  primaryColor: "#001c3d",
  secondaryColor: "#1763E5",
  linkColor: "#1763E5",
  siteTitle: "Center Expense",
  backgroundColor: "#001c3d",
  textColor: "#001c3d",
  activeColor: Colors.center_yellow,
  textElementColor: Colors.white,
  logoLink: window.location.origin,
  mainFont: "Whitney A",
  navLinks: [
    {
      path: "expensehub",
      name: "Expense Hub",
      target: "SELF",
      type: "INTERNAL",
    },
    {
      path: "myapprovals",
      name: "My Approvals",
      target: "SELF",
      type: "INTERNAL",
    },
    {
      path: "insights",
      name: "Insights",
      target: "SELF",
      type: "INTERNAL",
    },
    {
      path: "insight",
      name: "Insight",
      target: "SELF",
      type: "INTERNAL",
    },
    {
      path: "mycard",
      name: "My Card",
      target: "SELF",
      type: "INTERNAL",
    },
    {
      path: "spend",
      name: "Spend",
      target: "SELF",
      type: "INTERNAL",
    },
    {
      path: "travel",
      name: "Travel",
      target: "SELF",
      type: "INTERNAL",
    },
    {
      path: "centeraccount",
      name: "Account",
      target: "SELF",
      type: "INTERNAL",
    },
    {
      path: "admin",
      name: "Admin",
      target: "SELF",
      type: "INTERNAL",
    },
  ],
};
