import { useCallback, useContext, useMemo, useState } from "react";
import { orderBy } from "lodash";

import { Avatar, Dropdown, Menu } from "@core/ui-legacy";

import AuthUserContext from "../../common/contexts/AuthUserContext";
import {
  useSideNavCollapsed,
  useToggleSideNavCollapsed,
} from "../../common/SideNavCollapseContext";
import useDelegateUser, { type DelegateUser } from "../../common/useDelegateUser";
import { userHasFeatures } from "../../common/userHasFeatures";
import type { IAuthUser } from "../../features/UserInfoPage/types";
import featureFlags from "../../utils/featureFlags";
import { SIDE_NAV_CONTAINER_ID } from "../SideNav";
import { HeadingStyled } from "../SideNav/styles";
import { ButtonStyled, Container, IconStyled } from "./styles";
import type { DelegateAvatarProps } from "./types";

const DelegateAvatar = ({ user, labelVisible }: DelegateAvatarProps) => (
  <Avatar
    {...{
      name: user.fullName,
      label: labelVisible ? user.fullName : undefined,
      userID: user.ID,
      styleName: { fontWeight: 400 },
      src: user.avatarURI,
      styleAvatar: labelVisible
        ? undefined
        : {
            marginRight: 0,
          },
      userColor: user.avatarBackgroundColor,
    }}
  />
);

const SpendSideNavTitle = () => {
  const authUser = useContext<IAuthUser>(AuthUserContext);
  const collapsed = useSideNavCollapsed();
  const toggleSideNavCollapsed = useToggleSideNavCollapsed();
  const [delegateUser, setDelegateUser] = useDelegateUser();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const virtualMultiCardEnabled = useMemo(
    () => userHasFeatures(authUser.toggles, [featureFlags.VIRTUAL_MULTI_CARD]),
    [authUser.toggles],
  );

  const renderDelegates = useMemo(
    () => orderBy(authUser.delegateOf ?? [], "fullName"),
    [authUser.delegateOf],
  );

  const normalizedDelegateOf = useMemo(() => {
    const authUserAsDelegate = {
      ID: authUser.ID,
      firstName: authUser.firstName,
      lastName: authUser.lastName,
      fullName: authUser.fullName,
      avatarURI: authUser.avatarURI,
      avatarBackgroundColor: authUser.avatarBackgroundColor,
    } satisfies DelegateUser;

    if (!delegateUser) {
      return authUserAsDelegate;
    }

    return authUser.delegateOf?.some((delegate) => delegate.ID === delegateUser.ID)
      ? delegateUser
      : authUserAsDelegate;
  }, [
    authUser.ID,
    authUser.avatarBackgroundColor,
    authUser.avatarURI,
    authUser.delegateOf,
    authUser.firstName,
    authUser.fullName,
    authUser.lastName,
    delegateUser,
  ]);

  const handleCollapsedAvatarClick = useCallback(() => {
    toggleSideNavCollapsed();
    setTimeout(() => {
      setDropdownVisible(true);
    }, 100);
  }, [toggleSideNavCollapsed]);

  const handleDelegateChange = useCallback(
    (newDelegate: DelegateUser) => {
      setDropdownVisible(false);
      setDelegateUser(newDelegate.ID === authUser.ID ? null : newDelegate);
    },
    [authUser.ID, setDelegateUser],
  );

  if (!virtualMultiCardEnabled) {
    return <HeadingStyled {...{ as: "h3" }}>My Card</HeadingStyled>;
  }

  if (renderDelegates.length === 0) {
    return null;
  }

  if (collapsed) {
    return (
      <Container
        {...{
          clickable: true,
          onClick: handleCollapsedAvatarClick,
          collapsed,
        }}
      >
        <DelegateAvatar {...{ user: normalizedDelegateOf, labelVisible: false }} />
      </Container>
    );
  }

  return (
    <Container {...{ collapsed }}>
      <Dropdown
        {...{
          trigger: ["click"],
          visible: dropdownVisible,
          onVisibleChange: setDropdownVisible,
          overlay: (
            <Menu>
              <Menu.Item
                {...{
                  key: authUser.ID,
                  onClick: () => handleDelegateChange(authUser),
                }}
              >
                {authUser.fullName}
              </Menu.Item>
              {renderDelegates.map((delegate) => (
                <Menu.Item
                  {...{
                    key: delegate.ID,
                    onClick: () => handleDelegateChange(delegate),
                  }}
                >
                  {delegate.fullName}
                </Menu.Item>
              ))}
            </Menu>
          ),
          getPopupContainer: () => document.getElementById(SIDE_NAV_CONTAINER_ID)!,
        }}
      >
        <ButtonStyled>
          <DelegateAvatar {...{ user: normalizedDelegateOf, labelVisible: true }} />
          <IconStyled {...{ type: "down", $dropdownVisible: dropdownVisible }} />
        </ButtonStyled>
      </Dropdown>
    </Container>
  );
};
export default SpendSideNavTitle;
