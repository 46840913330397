/* eslint-disable consistent-return */
/* eslint-disable react-hooks/rules-of-hooks */
import { cloneElement, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import styled from "styled-components";

// eslint-disable-next-line import/no-cycle
import Slider from "./Slider";

export const DEFAULT_SCALE = 1;
export const MAX_SCALE = 4;
export const SCALE_STEP = 0.5;

const StyledImageWrap = styled.div`
  canvas {
    ${({ isSmartReceipt }) => (isSmartReceipt ? "padding-top: 72px;" : "")}
    cursor: ${({ isDragging, imageWasZoomed }) =>
      isDragging && imageWasZoomed ? "move !important" : "pointer"};
  }
`;

const ImageZoomer = ({
  children,
  disabled,
  initialScale,
  isModalView,
  isOverrideOnClick,
  imageWasReplaced,
  isSmartReceipt,
}) => {
  if (!children) {
    return null;
  }

  if (disabled) {
    return children;
  }

  const [currentScale, setScale] = useState(DEFAULT_SCALE);
  const [isDragging, setDragging] = useState(false);
  const [imageWasZoomed, setZoomed] = useState(false);

  const zoomRef = useRef();

  useEffect(() => {
    if (initialScale) {
      setScale(initialScale);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentScale === DEFAULT_SCALE) {
      setZoomed(false);
    } else {
      setZoomed(true);
    }
  }, [currentScale]);

  const renderChild = () => {
    if (isOverrideOnClick && children?.props?.onClick) {
      return cloneElement(children, {
        onClick: (...args) => {
          if (currentScale === DEFAULT_SCALE) {
            // call initial onClick handler only if image is not zoomed
            return children.props.onClick(...args);
          }
        },
      });
    }

    return children;
  };

  const onPanningStart = () => setDragging(true);
  const onPanningStop = () => setDragging(false);

  return (
    <TransformWrapper
      maxScale={MAX_SCALE}
      initialScale={initialScale}
      doubleClick={{ disabled }}
      pinch={{ step: SCALE_STEP }}
      onPanningStart={onPanningStart}
      onPanningStop={onPanningStop}
      panning={{ disabled: !imageWasZoomed }}
      wheel={{ disabled: isSmartReceipt, step: SCALE_STEP }}
      ref={zoomRef}
    >
      <Slider
        isSmartReceipt={isSmartReceipt}
        value={currentScale}
        revertControls={isModalView}
        onScaleChange={setScale}
        imageWasReplaced={imageWasReplaced}
      />
      <TransformComponent wrapperStyle={{ width: "100%", overflowY: "auto" }}>
        <StyledImageWrap
          isSmartReceipt={isSmartReceipt}
          isDragging={isDragging}
          imageWasZoomed={imageWasZoomed}
        >
          {renderChild()}
        </StyledImageWrap>
      </TransformComponent>
    </TransformWrapper>
  );
};

ImageZoomer.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  initialScale: PropTypes.number,
  isModalView: PropTypes.bool,
  isOverrideOnClick: PropTypes.bool,
  imageWasReplaced: PropTypes.bool,
  isSmartReceipt: PropTypes.bool,
};

ImageZoomer.defaultProps = {
  initialScale: 1,
  isModalView: false,
  isOverrideOnClick: true,
  imageWasReplaced: false,
  isSmartReceipt: false,
};

export default ImageZoomer;
