import { useEffect, useMemo } from "react";
import gql from "graphql-tag";
import { get } from "lodash";
import { useLazyQuery, useQuery } from "react-apollo";

import { GET_EXPENSE_FIELD } from "../services/graphql/queries";
import useConnectedERPConnector from "./useConnectedERPConnector";

// get expense types, use new logic
export const GET_EXPENSE_TYPE_FIELD_VALUES = gql`
  query expenseTypeFieldValues($fieldID: ID!, $showDeleted: Boolean) {
    expenseFieldValues(
      fieldID: $fieldID
      showDeleted: $showDeleted
      showHidden: true
      limit: 5000
    ) {
      items {
        ID
        name
        isDeleted
        glCodeID
        glCode @client
        ruleIDs
        externalID
      }
    }
  }
`;

// get expense type, use new logic
export const GET_EXPENSE_TYPE_ITEM = gql`
  query expenseType($listItemID: ID!, $fieldID: ID!) {
    expenseFieldValue(listItemID: $listItemID, fieldID: $fieldID) {
      ID
      name
      isDeleted
      glCodeID
    }
  }
`;

export const UPDATE_EXPENSE_TYPE = gql`
  mutation updateExpenseType($fieldID: ID!, $listItemID: ID!, $input: ExpenseFieldValueNameInput!) {
    updateExpenseFieldItemValues(fieldID: $fieldID, input: $input, listItemID: $listItemID) {
      ID
      name
      isDeleted
      glCodeID
      glCode @client
    }
  }
`;

export const GET_PAGE_CONTEXT = gql`
  {
    expenseFields(showDeleted: false) {
      ID
      property
      name
    }
    generalLedgerAccounts(limit: -1) {
      items {
        ID
        name
        code
        glAccount @client
      }
    }
  }
`;

export const useExpenseType = ({ listItemID }) => {
  const {
    data,
    error: expenseTypeFieldError,
    loading: expenseTypeFieldLoading,
  } = useQuery(GET_PAGE_CONTEXT, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });

  const expenseTypeField = useMemo(
    () => data?.expenseFields?.find((item) => item.property === "expenseTypeID"),
    [data],
  );
  // get EXPENSE TYPE
  const [
    getExpenseType,
    { data: expenseTypeData, error: expenseTypeError, loading: expenseTypeLoading },
  ] = useLazyQuery(GET_EXPENSE_TYPE_ITEM, {
    variables: { fieldID: expenseTypeField?.ID, listItemID },
  });

  useEffect(() => {
    if (expenseTypeField && !expenseTypeData) {
      getExpenseType();
    }
  }, [expenseTypeData, expenseTypeField, getExpenseType]);

  return {
    expenseTypeValue: {
      ...expenseTypeData?.expenseFieldValue,
      glCode: {
        ID: expenseTypeData?.expenseFieldValue?.glCodeID,
      },
    },
    generalLedgerAccounts: data?.generalLedgerAccounts,
    expenseTypeFieldID: expenseTypeField?.ID,
    loading: expenseTypeLoading || expenseTypeFieldLoading,
    error: expenseTypeError || expenseTypeFieldError,
  };
};

export default () => {
  const [getExpenseTypes, { data: expenseTypesData, error, loading, refetch: refetchValues }] =
    useLazyQuery(GET_EXPENSE_TYPE_FIELD_VALUES);

  const {
    data,
    loading: pageContextLoading,
    refetch: refetchContext,
  } = useQuery(GET_PAGE_CONTEXT, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });

  const [getExpenseType, { data: expenseType, loading: isLoadingExpenseType }] =
    useLazyQuery(GET_EXPENSE_FIELD);

  const expenseTypeField = data?.expenseFields?.find((item) => item.property === "expenseTypeID");

  useEffect(() => {
    if (expenseTypeField && !expenseTypesData) {
      getExpenseTypes({
        variables: {
          fieldID: expenseTypeField?.ID,
          showDeleted: true,
        },
      });

      getExpenseType({
        variables: {
          id: expenseTypeField?.ID,
          injectusers: true,
        },
      });
    }
  }, [expenseTypeField, expenseTypesData, getExpenseTypes, getExpenseType]);

  const refetch = async () => {
    await refetchContext();
    await refetchValues();
  };

  const { connectedErpConnector } = useConnectedERPConnector();

  return {
    expenseTypes: get(expenseTypesData, "expenseFieldValues.items", []),
    loading: loading || pageContextLoading || isLoadingExpenseType,
    pageContextLoading,
    error,
    expenseType,
    expenseTypeFieldID: expenseTypeField?.ID,
    connectedErpConnector,
    refetch,
  };
};
