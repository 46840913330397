import styled from "styled-components";

import { Alert, Button, Drawer, Form, FormattedNumber, Menu, Modal } from "@core/ui-legacy";
import Colors from "@core/ui-legacy/themes/colors";

import { PaginationCss } from "../../common/styles";
import Table from "../../components/Table";
import type { AlertStyledProps } from "../UserInfoPage/types";

export const Status = styled.div`
  border: none;
  border-radius: 1.5rem;
  font-size: 0.625rem;
  line-height: 0.625rem;
  font-weight: 600;
  padding: 0.25rem 0.625rem;
  margin: 0;
  height: 1.125rem;
  align-self: self-end;
  display: flex;
  cursor: inherit;
  text-transform: uppercase;
  background-color: ${Colors.lunar_gray};
  color: ${({ color }) => color};
`;

export const CardholderCellStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const CardImageWrapper = styled.div<{ $isCardProvisioning: boolean }>`
  opacity: ${({ $isCardProvisioning }) => ($isCardProvisioning ? 0.4 : 1)};
`;

export const CardholderNameStyled = styled.div<{
  $isCardProvisioning: boolean;
}>`
  font-weight: 600;
  font-size: 1rem;
  margin-left: 0.5rem;
  color: ${({ $isCardProvisioning }) => ($isCardProvisioning ? Colors.dark_gray : "inherit")};
`;

export const CardholderNameContainer = styled.span`
  display: flex;
  flex-direction: column;
`;

export const TitleStyled = styled.div<{ $isCardProvisioning: boolean }>`
  display: flex;
  color: ${({ $isCardProvisioning }) => ($isCardProvisioning ? Colors.dark_gray : "inherit")};
`;

export const CurrencyStyled = styled(FormattedNumber)<{
  $isCardProvisioning: boolean;
}>`
  display: block;
  font-size: 1rem !important;
  align-self: self-end;
  color: ${({ $isCardProvisioning }) => ($isCardProvisioning ? Colors.dark_gray : Colors.midnight)};
`;

export const BalanceCellStyled = styled.div`
  display: flex !important;
  flex-direction: column;
  justify-content: flex-start;
`;

export const CardNameStyled = styled.div`
  font-size: 0.75rem;
  font-weight: 600;
  width: 100%;
  margin-left: 0.5rem;
  color: ${Colors.dark_gray};
`;

export const CellStyled = styled.div`
  color: inherit;
`;

export const PageHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const StyledButton = styled(Button)`
  padding: 0.5rem 0.75rem;
  height: 2.5rem;

  i {
    width: 1.125rem;
    height: 1.125rem;
  }
`;

export const PageSubtitle = styled.p`
  font-size: 1rem;
  color: ${Colors.monumental};
  margin-bottom: 1.5rem;
`;

export const TableStyled = styled(Table)<{ virtualMultiCardsEnabled: boolean }>`
  .balance-cell {
    & .ant-table-header-column {
      float: right;
    }
  }

  .ant-table-tbody > tr > td {
    border-bottom: 1px solid ${Colors.fog_gray} !important;
    transition: none;
  }

  .ant-table-tbody > tr {
    cursor: ${({ virtualMultiCardsEnabled }) => (virtualMultiCardsEnabled ? "pointer" : "default")};

    span {
      cursor: inherit !important;
    }
  }

  ${PaginationCss}

  .ant-pagination.ant-table-pagination {
    margin-bottom: 0.25rem;
  }

  .ant-table-thead > tr > th {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: ${Colors.bg_gray};
  }
`;

export const ActionBtn = styled.div<{ $isCardProvisioning: boolean }>`
  cursor: ${({ $isCardProvisioning }) => ($isCardProvisioning ? "not-allowed" : "pointer")};
  width: 2.5rem;
  text-align: center;
  opacity: ${({ $isCardProvisioning }) => ($isCardProvisioning ? 0.4 : 1)};
`;

export const CardInfoSectionStyled = styled.div`
  font-size: 0.75rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
`;

export const CardNameHolder = styled.span`
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-right: 0.25rem;
`;

export const ImgStyled = styled.img`
  margin: 9px;
`;

export const FormStyled = styled(Form)`
  & .ant-form-item {
    width: 12.5rem;
  }

  & .ant-input {
    border-radius: 0.25rem;
  }

  & .ant-form-explain {
    font-size: 0.75rem;
  }
`;

export const TitleFragmentStyled = styled.div`
  display: flex;
  align-items: center;
  padding-top: 1.5rem;
  margin-bottom: 0.5rem;
`;

export const ExclamationCircleImg = styled.img`
  margin-right: 0.5rem;
`;

export const Title = styled.p`
  font-size: 1.125rem;
  font-weight: 700;
  margin-bottom: 0;
`;

export const ModalStyled = styled(Modal)`
  .ant-btn-danger-btn {
    background-color: ${Colors.fog_gray} !important;
    border: none !important;
    color: ${Colors.danger_red} !important;

    &:hover {
      opacity: 0.8;
    }
  }

  .ant-modal-header {
    padding: 1.5em 1.5rem 0.5rem;
  }
`;

export const MenuItemStyled = styled(Menu.Item)<{ $isCloseOption?: boolean }>`
  &:hover {
    background-color: ${Colors.sky}!important;
    font-weight: 600;
  }

  ${({ $isCloseOption }) => ($isCloseOption ? "color: #B73115;" : "")}

  padding: 0.5rem 1rem;
  height: 2.5rem;
`;

export const AlertStyled = styled(Alert)<AlertStyledProps>`
  margin: 1.25rem 0 !important;
`;

export const ItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  border-bottom: 1px solid ${Colors.fog_gray} !important;
`;

export const HeadingStyled = styled.h2`
  font-size: 1.25rem !important;
  font-weight: 600;
  margin-bottom: 0 !important;
`;

export const DrawerStyled = styled(Drawer)`
  .ant-drawer-header {
    padding-bottom: 0 !important;
    padding-top: 1.75rem !important;
  }
`;
