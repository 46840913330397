import { useContext, useMemo } from "react";

import ERPConnectorsContext from "./contexts/ERPConnectorsContext";

const useConnectedERPConnector = () => {
  const { erpConnectors } = useContext(ERPConnectorsContext);

  const connectedErpConnector = useMemo(() => {
    if (erpConnectors) {
      return erpConnectors.find((c) => c.connected);
    }
    return null;
  }, [erpConnectors]);

  return { connectedErpConnector, erpConnectors };
};

export default useConnectedERPConnector;
