import type {
  Action,
  IActionInput,
  IActionInputValue,
  IBaseRule,
  IConditionInput,
  LogicalOperator,
  NullableValue,
  RuleStatus,
} from "../types";

export enum ActionValueType {
  Approver = "approver",
  DefaultApprover = "defaultApprover",
}

export type IApproverInputValue = IActionInputValue<ActionValueType>;

export type IApproverActionInput = IActionInput<ActionValueType>;

export enum ApprovalRuleType {
  Custom = "expenses.approval.setApprover.custom",
  AutoApprove = "expenses.approval.autoApprove",
}

export type IApprovalRuleParameters = {
  effectiveDateTime: string;
  endDateTime: NullableValue<string>;
  isDeleted: boolean;
  status: RuleStatus;
};

export type IApprovalRule = IBaseRule<ApprovalRuleType> & IApprovalRuleParameters;

export type IApprovalRuleInputParameters = {
  effectiveDateTime?: string;
  endDateTime?: NullableValue<string>;
  isDeleted?: boolean;
  status?: RuleStatus;
};

export type IApprover = {
  ID: string;
  fullName: string;
};

export type IApproverAction = {
  approvers: IApprover[];
  operator: Action;
};

export type IGeneratorInput = {
  actions: IApproverActionInput[];
  conditions: IConditionInput[];
  startDateTime: string;
  status: RuleStatus;
  type: ApprovalRuleType;
  endDateTime?: string;
  logicalOperator?: LogicalOperator;
};
