import { ContextHolder } from "@frontegg/react";
import gql from "graphql-tag";
import { capitalize, cloneDeep, get } from "lodash";

import { ConditionOperator, PaymentType } from "@core/rule-builder";

import intacctImg from "../../assets/imgs/intacctLogo.svg";
import netsuiteImg from "../../assets/imgs/netsuite.svg";
import quickbooksImg from "../../assets/imgs/quickbooksLogo.svg";
import { GET_EXPENSE_TYPE_FIELD_VALUES } from "../../common/useExpenseTypes";
import { DELEGATE_USER } from "../../common/usePersistentStorage";
import GeneralLedgerList from "../../features/GeneralLedgerPage/GeneralLedgerList";
import { FIELD_UI_TYPES } from "../../utils/constants";
import { ERP_TYPE_CODE } from "../../utils/erp";
import { getMileageValues } from "../../utils/expense";
import { generateApprovalRuleDescription, parseIfJSON } from "../../utils/rules";
import Storage from "../../utils/storage";
import {
  evaluatedExpenseFormFieldResolverBase,
  evaluatedExpenseFormResolverBase,
} from "./evaluatedExpenseFormResolvers";
import { EXPENSE_FIELD_FRAGMENT } from "./fragments";
import {
  GET_CONDITION_BUILDER_CONTEXT,
  GET_EXPENSE_FIELD_VALUES,
  GET_USER_FULLNAMES_WITH_SEARCH,
} from "./queries";
import { dynamicFormFieldResolverBase, userFormResolverBase } from "./userFormResolver";

const withErrorHandling =
  (resolver) =>
  (entity, ...params) => {
    try {
      if (!entity) return null;
      return resolver(entity, ...params);
    } catch (err) {
      window.console.error("Local resolver error", entity, err);
      return null;
    }
  };

const wrapResolvers = (resolvers) => {
  Object.keys(resolvers).forEach((key) => {
    if (key === "Mutation") return;
    Object.keys(resolvers[key]).forEach((subKey) => {
      resolvers[key][subKey] = withErrorHandling(resolvers[key][subKey]);
    });
  });
  return resolvers;
};

export const getCardHolderFragment = (userID, cache) =>
  cache.readFragment({
    id: `User:${userID}`,
    fragment: gql`
      fragment cardHolder on User {
        ID
        fullName
        avatarBackgroundColor
        firstName
        lastName
        emailAddress
        loginID
        archived
        billingAddress {
          address
          address2
          city
          country
          postalCode
          state
        }
        cardItems {
          ID
          displayLabel
          limit
          last4
          cardReferenceID
          cardProgramInstanceID
          statusCode
          cardFormat
          cardholder {
            firstName
            lastName
          }
          statusLabel
          isActive
          isAdminLocked
          cardInfo {
            ID
            activeState
            isLocked
            isClosed
            isRequested
            canUnlock
          }
        }
        defaultApprover {
          ID
          name
          ruleID
        }
        defaultApprover @client {
          ID
          name
          ruleID
        }
        emailStatus
        undeliverableReason
      }
    `,
  });

const getExpenseFieldFragment = (fieldID, cache) => {
  const fragment = EXPENSE_FIELD_FRAGMENT;
  return cache.readFragment({
    id: `ExpenseField:${fieldID}`,
    fragment,
  });
};

const getData = () => {
  const fronteggUser = ContextHolder.getUser();
  const token = Storage.getItem("auth");
  const delegateUser = Storage.getItem(DELEGATE_USER);
  return {
    auth: { isAuthenticated: !!fronteggUser?.accessToken || !!token, __typename: "Auth" },
    showMFAPendingActivities: {
      visible: true,
      __typename: "ShowMFAPendingActivities",
    },
    showCardPendingActivities: {
      visible: true,
      __typename: "ShowCardPendingActivities",
    },
    showAppNotificationsPanel: {
      visible: false,
      __typename: "ShowAppNotificationsPanel",
    },
    showReceiptsBinPanel: {
      visible: false,
      __typename: "ShowReceiptsBinPanel",
    },
    showReceipt: {
      visible: false,
      receiptViewImagesData: null,
      __typename: "ShowReceipt",
    },
    asyncJobs: {
      jobs: [],
      hasPendingJob: false,
      __typename: "AsyncJobs",
    },
    delegateUser: delegateUser ? JSON.parse(delegateUser) : null,
    componentState: {
      ID: null,
      state: null,
      __typename: "ComponentState",
    },
  };
};

const updateBookingResolver = {
  form: (expense) => ({
    ...expense.form,
    expenseID: expense.ID,
  }),
};

const expenseResolverBase = {
  user: (expense) => {
    if (expense.user) return expense.user;
    if (!expense.userID) {
      return null;
    }
    return {
      ID: expense.userID,
      fullName: expense.userFullName,
      avatarBackgroundColor: expense.userAvatarBackgroundColor,
      __typename: "User",
    };
  },
  costCenter: (expense, _args, { cache }) => {
    const fragment = gql`
      fragment costCenter on CostCenter {
        ID
        name
      }
    `;
    const data = cache.readFragment({
      id: `CostCenter:${expense.costCenterID}`,
      fragment,
    });
    return {
      ID: expense.costCenterID,
      name: data ? data.name : expense.costCenterName,
      __typename: "CostCenter",
    };
  },
  expenseType: (expense, _args, { cache }) => {
    if (!expense.expenseTypeID) {
      return null;
    }
    const fragment = gql`
      fragment expenseType on ExpenseType {
        ID
        name
        isDeleted
      }
    `;
    const data = cache.readFragment({
      id: `ExpenseType:${expense.expenseTypeID}`,
      fragment,
    });
    return {
      ID: expense.expenseTypeID,
      name: data?.name ?? expense.expenseTypeName,
      isDeleted: data?.isDeleted || null,
      __typename: "ExpenseType",
    };
  },
  paymentType: (expense) =>
    // eslint-disable-next-line no-nested-ternary
    expense.isMileage
      ? PaymentType.Mileage
      : expense.isOOP
      ? PaymentType.Reimbursable
      : PaymentType.Card,
  form: (expense) => ({
    ...expense.form,
    expenseID: expense.ID,
  }),
  mileage: getMileageValues,
  localCustomFields: (expense) => {
    try {
      return JSON.parse(expense?._customFields);
    } catch {
      return {};
    }
  },
  localMerchant: (expense) => {
    if (!expense._merchant?.name) {
      return null;
    }
    return {
      ID: expense._merchant?.ID,
      name: expense._merchant?.name,
      rawName: expense._merchant?.rawName,
      categoryCode: expense._merchant?.categoryCode,
    };
  },
};

const costCenterResolverBase = {
  name: (cc, _args, { cache }) => {
    if (!cc.ID) return null;
    if (cc.name) return cc.name;
    const fragment = gql`
      fragment costCenter on CostCenter {
        ID
        name
      }
    `;
    const data = cache.readFragment({
      id: `CostCenter:${cc.ID}`,
      fragment,
    });
    return data ? data.name : null;
  },
  parent: (cc, _args, { cache }) => {
    const fragment = gql`
      fragment costCenter on CostCenter {
        ID
        name
      }
    `;
    const data = cache.readFragment({
      id: `CostCenter:${cc.parentID}`,
      fragment,
    });
    return data;
  },
};

const generalLedgerAccountResolverBase = {
  glAccount: (gl) => `${gl.code} - ${gl.name}`,
};

const delegateResolverBase = {
  fullName: (user) => `${user.firstName} ${user.lastName}`,
};

const erpConnectorShortNames = {
  [ERP_TYPE_CODE.quickbooks]: `QuickBooks`,
  [ERP_TYPE_CODE.quickbooksnp]: `QuickBooks`,
  [ERP_TYPE_CODE.intacct]: `Intacct`,
  [ERP_TYPE_CODE.netsuite]: "NetSuite",
};

const erpConnectorLogoURIs = {
  [ERP_TYPE_CODE.quickbooks]: quickbooksImg,
  [ERP_TYPE_CODE.quickbooksnp]: quickbooksImg,
  [ERP_TYPE_CODE.intacct]: intacctImg,
  [ERP_TYPE_CODE.netsuite]: netsuiteImg,
};

const erpConnectorResolverBase = {
  shortName: (erpConnector) => erpConnectorShortNames[erpConnector.type] || null,
  // current image url in DB actually come from react build, so keeping lookup,
  // but want to be able to just use config
  logoUri: ({ type, imageURL }) => erpConnectorLogoURIs[type] || imageURL || null,
};

const erpDimensionResolverBase = {
  localMeta: (x) => {
    try {
      return JSON.parse(x?._meta);
    } catch {
      return {};
    }
  },
};

const erpExternalFieldResolverBase = {
  localMeta: (x) => {
    try {
      return JSON.parse(x?._meta);
    } catch {
      return {};
    }
  },
};

const currentApproverUserResolverBase = {
  fullName: (user) => {
    if (!user || !user.fullName) return null;
    return user.fullName;
  },
};

const policyRuleResolverBase = {
  statusLabel: (rule) => (rule.status ? capitalize(rule.status) : null),
  description: (item, _args, { cache }) => {
    const rule = cloneDeep(item);
    const parseRuleEvaluation = parseIfJSON(rule.evaluation);
    rule.evaluation = parseRuleEvaluation;
    const { expenseFields: fields, ...context } = cache.readQuery({
      query: GET_CONDITION_BUILDER_CONTEXT,
    });

    const expenseTypes = [];

    parseRuleEvaluation.conditions.forEach((condition) => {
      if (condition.property === "expenseTypeID") {
        let expenseType;
        try {
          expenseType = cache.readFragment({
            id: `ExpenseFieldValue:${condition.value}`,
            fragment: gql`
              fragment expenseFieldValue on ExpenseFieldValue {
                ID
                name
              }
            `,
          });
        } catch (err) {
          window.console.error(err, "err");
        }
        if (!expenseType) {
          try {
            expenseType = cache.readFragment({
              id: `ExpenseType:${condition.value}`,
              fragment: gql`
                fragment expenseType on ExpenseType {
                  ID
                  name
                }
              `,
            });
          } catch (err) {
            window.console.error(err, "err");
          }
        }
        if (expenseType) {
          expenseTypes.push(expenseType);
        }
      }
    });
    return generateApprovalRuleDescription(
      rule,
      { context: { ...context, expenseTypes }, fields },
      parseRuleEvaluation,
    );
  },
};

const expenseFormFieldResolverBase = {
  name: (field, _args, { cache }) => {
    const data = getExpenseFieldFragment(field.referenceID || field.ID, cache);
    return data ? data.name : null;
  },
  property: (field, _args, { cache }) => {
    const data = getExpenseFieldFragment(field.referenceID || field.ID, cache);
    return data ? data.property : null;
  },
  defaultValue: (field, _args, { cache }) => {
    const data = getExpenseFieldFragment(field.referenceID || field.ID, cache);
    const decorations = JSON.parse(field.decorations);
    const formFieldDefaultValue = parseIfJSON(get(decorations, "defaultValue", null));
    if (formFieldDefaultValue) {
      return formFieldDefaultValue;
    }
    return data ? parseIfJSON(data.defaultValue) : null;
  },
  values: (field, _args, { cache }) => {
    const data = getExpenseFieldFragment(field.referenceID || field.ID, cache);
    return data ? data.values : null;
  },
  connectorID: (field, _args, { cache }) => {
    const data = getExpenseFieldFragment(field.referenceID || field.ID, cache);
    return data ? data.connectorID : null;
  },
  connectorTypeCode: (field, _args, { cache }) => {
    const data = getExpenseFieldFragment(field.referenceID || field.ID, cache);
    return data ? data.connectorTypeCode : null;
  },
  decorations: (field) =>
    typeof field.decorations === "string" ? JSON.parse(field.decorations) : field.decorations,
};

const policyFieldResolverBase = {
  field: ({ property }) => property,
  operators: (field) => {
    const { uiType } = field;
    let operators = [ConditionOperator.EQ, ConditionOperator.NEQ];
    switch (uiType) {
      case FIELD_UI_TYPES.inputNumber:
      case FIELD_UI_TYPES.inputCurrency:
        operators = [
          ConditionOperator.GTE,
          ConditionOperator.GT,
          ConditionOperator.LTE,
          ConditionOperator.LT,
          ...operators,
        ];
        break;
      default:
        break;
    }
    return operators;
  },
  valuesQuery: (field) => {
    const { ID, uiType, property, isUserDefined } = field;
    let optionKey = "ID";
    let optionValue = "name";
    let queryDataField = null;
    let query = null;
    let variables = null;
    let serverSearchKey = null;
    switch (property) {
      case "costCenterID": {
        query = gql`
          query costCenters($archived: Boolean) {
            costCenters(archived: $archived) {
              ID
              name
            }
          }
        `;
        variables = { archived: false };
        queryDataField = "costCenters";
        break;
      }
      case "glCode": {
        query = gql`
          query generalLedgerAccounts($limit: Int) {
            generalLedgerAccounts(limit: $limit) {
              items {
                ...GeneralLedgerListFragment
              }
            }
          }
          ${GeneralLedgerList.fragments.generalLedger}
        `;
        variables = { limit: -1 };
        queryDataField = "generalLedgerAccounts";
        break;
      }
      case "userID": {
        query = GET_USER_FULLNAMES_WITH_SEARCH;
        queryDataField = "users";
        optionValue = "fullName";
        optionKey = "ID";
        serverSearchKey = "prefix";
        variables = { limit: 25, archived: false };
        break;
      }
      default: {
        break;
      }
    }
    if (
      (!query && ID && isUserDefined) ||
      property === "paymentType" ||
      property === "expenseTypeID"
    ) {
      switch (uiType) {
        case FIELD_UI_TYPES.dropdownSearch:
        case FIELD_UI_TYPES.dropdown:
          query =
            property === "expenseTypeID" ? GET_EXPENSE_TYPE_FIELD_VALUES : GET_EXPENSE_FIELD_VALUES;
          queryDataField = "expenseFieldValues";
          serverSearchKey = "contains";
          variables = { fieldID: ID, showHidden: true };
          optionKey = property === "expenseTypeID" ? "ID" : "name";
          break;
        default:
          break;
      }
    }
    return query
      ? {
          queryDataField,
          optionKey,
          optionValue,
          query,
          variables,
          serverSearchKey,
        }
      : null;
  },
};

const expenseFieldResolverBase = {
  status: (expenseField) => (expenseField.isDeleted ? "inactive" : "active"),
  ...policyFieldResolverBase,
  operators: (field) => {
    const { uiType } = field;
    let operators = [ConditionOperator.EQ, ConditionOperator.NEQ];
    switch (uiType) {
      case FIELD_UI_TYPES.inputNumber:
      case FIELD_UI_TYPES.inputCurrency:
        operators = [
          ConditionOperator.GT,
          ConditionOperator.GTE,
          ConditionOperator.LT,
          ConditionOperator.LTE,
          ConditionOperator.EQ,
        ];
        break;
      case FIELD_UI_TYPES.dropdownSearch:
      case FIELD_UI_TYPES.dropdown:
        operators = [...operators, ConditionOperator.OF, ConditionOperator.NOF];
        break;
      default:
        break;
    }
    return operators;
  },
};

const receiptResolverBase = {
  expires: ({ URI }) => new URL(URI).searchParams.get("Expires") || "Infinity",
};

const expenseFieldValueResolverBase = {
  glCode: (field, _args, { cache }) => {
    const fragment = gql`
      fragment generalLedgerAccount on GeneralLedgerAccount {
        ID
        name
        code
      }
    `;
    const gl = cache.readFragment({
      id: `GeneralLedgerAccount:${field.glCodeID}`,
      fragment,
    });

    return gl || null;
  },
};

const sectionFieldResolverBase = {
  defaultValue: (field, _args, { cache }) => {
    const data = getExpenseFieldFragment(field.referenceID || field.ID, cache);
    const decorations = JSON.parse(field.decorations);
    const formFieldDefaultValue = parseIfJSON(get(decorations, "defaultValue", null));
    if (formFieldDefaultValue) {
      return formFieldDefaultValue;
    }
    return data ? parseIfJSON(data.defaultValue) : null;
  },
  values: (field, _args, { cache }) => {
    const data = getExpenseFieldFragment(field.referenceID || field.ID, cache);
    return data ? data.values : null;
  },
};

const arrangerUserReferenceResolverBase = {
  fullName: (user) => `${user.firstName} ${user.lastName}`,
};

export const userResolverBase = {
  defaultApprover: (user) => {
    const approver = user?.defaultApprover;
    return (
      approver && {
        ...approver,
        ruleID: approver.ruleID ?? user.ID,
      }
    );
  },
};

export default {
  data: getData(),
  getData,
  resolvers: wrapResolvers({
    Expense: expenseResolverBase,
    CostCenter: costCenterResolverBase,
    GeneralLedgerAccount: generalLedgerAccountResolverBase,
    Delegate: delegateResolverBase,
    ERPConnector: erpConnectorResolverBase,
    ERPDimension: erpDimensionResolverBase,
    ERPExternalField: erpExternalFieldResolverBase,
    ExpenseField: expenseFieldResolverBase,
    CurrentApproverUser: currentApproverUserResolverBase,
    ExpenseFormField: expenseFormFieldResolverBase,
    EvaluatedExpenseForm: evaluatedExpenseFormResolverBase,
    EvaluatedExpenseFormField: evaluatedExpenseFormFieldResolverBase,
    PolicyRule: policyRuleResolverBase,
    PolicyField: policyFieldResolverBase,
    Receipt: receiptResolverBase,
    ExpenseFieldValue: expenseFieldValueResolverBase,
    SectionField: sectionFieldResolverBase,
    UserForm: userFormResolverBase,
    DynamicFormField: dynamicFormFieldResolverBase,
    UpdateBooking: updateBookingResolver,
    ArrangerUserReference: arrangerUserReferenceResolverBase,
    User: userResolverBase,
    Mutation: {
      setAuth: (_, { isAuthenticated }, { cache }) => {
        const data = {
          auth: {
            isAuthenticated,
            __typename: "Auth",
          },
        };
        cache.writeData({ data });
        return null;
      },
      setShowMFAPendingActivities: (_, { visible }, { cache }) => {
        const data = {
          showMFAPendingActivities: {
            visible,
            __typename: "ShowMFAPendingActivities",
          },
        };
        cache.writeData({ data });
        return null;
      },
      setShowCardPendingActivities: (_, { visible }, { cache }) => {
        const data = {
          showCardPendingActivities: {
            visible,
            __typename: "ShowCardPendingActivities",
          },
        };
        cache.writeData({ data });
        return null;
      },
      setShowAppNotificationsPanel: (_, { visible, onlyHRIS }, { cache }) => {
        const data = {
          showAppNotificationsPanel: {
            visible,
            onlyHRIS,
            __typename: "ShowAppNotificationsPanel",
          },
        };
        cache.writeData({ data });
        return null;
      },
      setShowReceiptsBinPanel: (_, { visible }, { cache }) => {
        const data = {
          showReceiptsBinPanel: {
            visible,
            __typename: "ShowReceiptsBinPanel",
          },
        };
        cache.writeData({ data });
        return null;
      },
      setShowReceipt: (_, { visible, receiptViewImagesData }, { cache }) => {
        const data = {
          showReceipt: {
            visible,
            receiptViewImagesData: {
              ...receiptViewImagesData,
              __typename: "receiptViewImagesData",
            },
            __typename: "ShowReceipt",
          },
        };
        cache.writeData({ data });
        return null;
      },
      setAsyncJobs: (_, { asyncJobsData }, { cache }) => {
        const { jobs, hasPendingJob, noop } = asyncJobsData;
        if (noop) {
          window.console.warn("No-op in setAsyncJobs()", { jobs });
          return null;
        }
        const data = {
          asyncJobs: {
            jobs,
            hasPendingJob,
            __typename: "AsyncJobs",
          },
        };
        cache.writeData({ data });
        return null;
      },
      setCardsAsyncJobs: (_, { cardsAsyncJobsData, activeJob }, { cache }) => {
        const data = {
          cardsAsyncJob: {
            cards: cardsAsyncJobsData,
            activeJob,
            __typename: "CardsAsyncJobs",
          },
        };
        cache.writeData({ data });
        return null;
      },
      setDelegateUser: (_, { delegateUser: user }, { cache }) => {
        const data = {
          delegateUser: user
            ? {
                ...user,
                __typename: "DelegateUser",
              }
            : null,
        };
        cache.writeData({ data });
        return null;
      },
      setComponentState: (_, { id, state }, { cache }) => {
        const data = {
          componentState: {
            ID: id,
            state: JSON.stringify(state),
            __typename: "ComponentState",
          },
        };
        cache.writeData({ data });
        return null;
      },
    },
  }),
};
