import { useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { useFlag } from "@core/feature-flags/clients";
import { Badge, Drawer } from "@core/ui-legacy";
import Colors from "@core/ui-legacy/themes/colors";

import AuthUserContext from "../../common/contexts/AuthUserContext";
import { getMenuDataWithChildren } from "../../common/menu";
import { UserRoles } from "../../common/roles";
import API from "../../services/rest/api";
import featureFlags from "../../utils/featureFlags";
import * as Routes from "../../utils/routes";
import { ACCOUNT_INFO_SETTINGS } from "../../utils/routes";
import Storage from "../../utils/storage";
import showTravelyMenu from "../../utils/travel";
import AppNotificationsIndicator from "../AppNotificationsIndicator";
import CenterLogo from "../CenterLogo";
import NavMenu from "../NavMenu";
import SandboxIndicator from "../SandboxIndicator";

const DrawerStyled = styled(Drawer)`
  .ant-drawer-body {
    padding: 0 !important;
  }
`;

const CenterMenuStyled = styled(NavMenu)`
  .ant-menu-submenu-title,
  > .ant-menu-item {
    margin: 0 !important;
    box-shadow: inset 0 -1px 0 #f0f0f0;
    padding: 0.5rem 0 0 1.5rem !important;
    font-size: 1.125rem !important;

    ::after {
      border-right: none !important;
    }
  }

  .ant-menu-item {
    height: 3.625rem;

    i {
      font-size: 1.5rem;
    }

    span {
      font-size: 1rem !important;
      font-weight: 600 !important;
    }
  }

  .ant-menu-item:first-child {
    background-color: ${Colors.bg_gray} !important;
  }
`;

const NavMenuStyled = styled(NavMenu)`
  .ant-menu-submenu-title,
  > .ant-menu-item {
    padding-left: 1.5rem !important;
    font-size: 1.125rem !important;

    ::after {
      border-right: none !important;
    }
  }

  .ant-menu-item {
    ::after {
      border-right: none !important;
    }
  }

  .ant-menu-item:not(:last-child) {
    margin-bottom: 4px !important;
  }
`;

const CenterLogoStyled = styled(CenterLogo)`
  path {
    fill: ${Colors.midnight} !important;
  }
`;

const CenterLogoStyledWithotTravelMenu = styled(CenterLogo)`
  margin: 0 0 10px 24px;
`;

const BadgeStyled = styled(Badge)`
  sup {
    margin-top: 0.313rem;
  }
`;

const DrawerMenu = ({ userFullname, location, visible, onClose, sandbox }) => {
  const authUser = useContext(AuthUserContext);
  const shouldShowTravelMenu = showTravelyMenu(authUser);
  const useMFA = useFlag("release-mfa");

  const showBadge =
    !authUser?.fraudEnrolled && authUser?.cardItems?.some((item) => item?.statusCode === "Open");

  const handleClose = () => {
    if (onClose) onClose();
  };

  const getCenterMenuData = () => {
    const menus = [
      {
        id: "center-link-item",
        name: <CenterLogoStyled isSmall size={2} mr={1.5} color={Colors.midnight} />,
        icon: null,
        path: null,
        authority: [UserRoles.ALL],
        enableForFeatureFlags: [featureFlags.TRAVELER],
      },
    ];
    return menus;
  };

  const getMenuData = () => {
    const menuData = getMenuDataWithChildren();
    menuData.push(
      {
        id: "inbox-link-panel",
        name: <AppNotificationsIndicator onClick={handleClose} />,
        icon: null,
        path: null,
        authority: [UserRoles.ALL],
      },
      {
        id: "user-link-drawer",
        name: userFullname,
        icon: null,
        path: null,
        authority: [UserRoles.ALL],
        children: [
          {
            id: "settings-reimbursements-link",
            name: (
              <>
                Settings <BadgeStyled data-testid="fraud-badge-menu" dot={showBadge} />
              </>
            ),
            icon: null,
            path: ACCOUNT_INFO_SETTINGS,
            authority: [UserRoles.ALL],
          },
          {
            id: "help-link",
            name: "Help",
            icon: null,
            path: Routes.CONTACT_SUPPORT,
            target: "_blank",
            authority: [UserRoles.ALL],
          },
          {
            id: "logout-link",
            name: "Sign out",
            icon: null,
            path: null,
            onClick: API.logout,
            authority: [UserRoles.ALL],
          },
        ],
      },
    );

    if (Storage.getItem("wasUserSignedInWithSSO") !== "true" && !useMFA) {
      menuData.at(-1).children.splice(1, 0, {
        id: "change-password-link",
        name: "Change Password",
        icon: null,
        path: `${Routes.CHANGE_PASSWORD}?origin=${location.pathname}`,
        authority: [UserRoles.ALL],
      });
    }

    return menuData;
  };

  return shouldShowTravelMenu ? (
    <DrawerStyled placement="left" width={320} closable onClose={handleClose} visible={visible}>
      <CenterMenuStyled
        mode="inline"
        menuData={getCenterMenuData()}
        location={location}
        onLinkClick={handleClose}
      />
      <NavMenuStyled
        mode="inline"
        menuData={getMenuData()}
        location={location}
        onLinkClick={handleClose}
      />
    </DrawerStyled>
  ) : (
    <Drawer placement="left" width={320} closable onClose={handleClose} visible={visible}>
      <CenterLogoStyledWithotTravelMenu isSmall size={2} />
      <SandboxIndicator sandbox={sandbox} drawer />
      <NavMenuStyled
        mode="inline"
        menuData={getMenuData()}
        location={location}
        onLinkClick={handleClose}
      />
    </Drawer>
  );
};

DrawerMenu.propTypes = {
  userFullname: PropTypes.string,
  location: PropTypes.object,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  sandbox: PropTypes.bool,
};

export default DrawerMenu;
