import { z } from "zod";

import {
  UNLINKED_FIELDS,
  type APPROVER_OPTIONS,
  type COST_CENTER_OPTIONS,
  type EMAIL_OPTIONS,
  type FIRST_NAME_OPTIONS,
  type LAST_NAME_OPTIONS,
  type LOCATION_OPTIONS,
} from "./orgConfig";

const location = z
  .object({
    city: z.string().nullish(),
    country: z.string().nullish(),
    id: z.string().nullish(),
    name: z.string().nullish(),
    phoneNumber: z.string().nullish(),
    state: z.string().nullish(),
    street1: z.string().nullish(),
    street2: z.string().nullish(),
    zipCode: z.string().nullish(),
  })
  .nullish();

export const employeeSchema = z.object({
  displayFullName: z.string(),
  firstName: z.string().nullish(),
  lastName: z.string().nullish(),
  homeLocation: location,
  workLocation: location,
  managerId: z.string().nullish(),
  groups: z
    .array(
      z.object({
        id: z.string().nullish(),
        name: z.string().nullish(),
        type: z.string().nullish(),
      }),
    )
    .nullish(),
  unlinkedFields: z.array(z.enum(UNLINKED_FIELDS)).nullish(),
  preferredName: z.string().nullish(),
  processingStatus: z.string(),
  id: z.string(),
  personalEmail: z.string().nullish(),
  workEmail: z.string().nullish(),
  userId: z.string().nullish(),
});

export type Employee = z.infer<typeof employeeSchema>;

export const employeesToOnboardSchema = z.array(employeeSchema);

export type EmployeeToOnboard = z.infer<typeof employeeSchema>;

const employeeToDeactivateSchema = employeeSchema.extend({
  userId: z.string().nullish(),
});

export const employeesToDeactivateSchema = z.array(employeeToDeactivateSchema);

export type EmployeeToDeactivate = z.infer<typeof employeeToDeactivateSchema>;

export const firstNameOptionsMap: Record<(typeof FIRST_NAME_OPTIONS)[number], keyof Employee> = {
  FIRST_NAME: "firstName",
} as const;

export const lastNameOptionsMap: Record<(typeof LAST_NAME_OPTIONS)[number], keyof Employee> = {
  LAST_NAME: "lastName",
} as const;

export const emailOptionsMap: Record<(typeof EMAIL_OPTIONS)[number], keyof Employee> = {
  WORK_EMAIL: "workEmail",
  PERSONAL_EMAIL: "personalEmail",
} as const;

export const locationOptionsMap: Record<(typeof LOCATION_OPTIONS)[number], keyof Employee> = {
  WORK_LOCATION: "workLocation",
  HOME_LOCATION: "homeLocation",
} as const;

export const costCenterOptionsMap: Record<(typeof COST_CENTER_OPTIONS)[number], keyof Employee> = {
  GROUPS: "groups",
};

export const approverOptionsMap: Record<(typeof APPROVER_OPTIONS)[number], keyof Employee> = {
  MANAGER: "managerId",
};

export const fieldToUnlinkedField: Partial<
  Record<keyof Employee, (typeof UNLINKED_FIELDS)[number]>
> = {
  preferredName: "FIRST_NAME",
  firstName: "FIRST_NAME",
  lastName: "LAST_NAME",
  workEmail: "EMAIL_ADDRESS",
  personalEmail: "EMAIL_ADDRESS",
  homeLocation: "BILLING_ADDRESS",
  workLocation: "BILLING_ADDRESS",
  managerId: "DEFAULT_APPROVER",
  groups: "DEFAULT_COST_CENTER",
};

export const getEmployeeQueryKey = ({ userId }: { userId: string }) => ["employee", userId];

export const getEmployeeIdQueryKey = ({ employeeId }: { employeeId: string }) => [
  "employee",
  employeeId,
];

export const getEmployeesToDeactivateQueryKey = ({ orgId }: { orgId?: string }) => [
  "employeesToDeactivate",
  orgId,
];

export const getEmployeesToOnboardQueryKey = ({ orgId }: { orgId?: string }) => [
  "employeesToOnboard",
  orgId,
];
