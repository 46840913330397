import { Text } from "../Text";

type StepIndicatorProps = {
  currentStep: number;
  steps: string[];
};

const StepIndicator = ({ currentStep, steps }: StepIndicatorProps) => {
  return (
    <div className="flex space-x-4">
      {steps.map((step, index) => {
        const isCurrentStep = index === currentStep;
        return (
          <div key={step} className="flex items-center space-x-2">
            <div
              className={`flex size-[1.25rem] items-center justify-center rounded-full ${
                isCurrentStep ? "bg-primary-600" : "bg-neutrals-400"
              }`}
            >
              <Text variant="subtext" color="text-50" className="font-[375]">
                {index + 1}
              </Text>
            </div>
            <Text
              variant="subtext"
              color={isCurrentStep ? "primary-600" : "text-300"}
              className="font-[375]"
            >
              {step}
            </Text>
          </div>
        );
      })}
    </div>
  );
};

export { StepIndicator };
