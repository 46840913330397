import { css } from "styled-components";

import Colors from "@core/ui-legacy/themes/colors";

export const PaginationCss = css`
  .ant-pagination-item-link,
  .ant-select-selection {
    color: ${Colors.midnight};
    background-color: ${Colors.fog_gray};
    border: 0.0625rem solid ${Colors.white};
    box-sizing: border-box;
    border-radius: 0.25rem 0 0 0.25rem;
  }

  .ant-pagination-next .ant-pagination-item-link {
    border-radius: 0 0.25rem 0.25rem 0;
  }

  .ant-select-selection--single {
    font-size: 0.75rem;
    border-radius: 0.25rem;
  }

  .ant-select-arrow-icon {
    color: ${Colors.midnight};
  }

  .ant-pagination-options {
    margin-left: 0.3125rem;
  }

  .ant-select-selection-selected-value {
    margin-right: 0.3125rem;
  }

  .ant-select-selection__rendered {
    line-height: 1.8125rem;
  }

  .ant-select-dropdown-menu-item {
    background-color: ${Colors.white};
  }
`;
