import { useCallback, useMemo, useState } from "react";
import { intersection } from "lodash";
import PropTypes from "prop-types";
import { Route, Switch } from "react-router-dom";

import { Notifier } from "@core/ui-legacy";

import { CardActivationWizardProvider } from "../common/CardActivationWizardContext";
import { CardExpirationProvider } from "../common/contexts/CardExpirationProvider";
import { UserRoles } from "../common/roles";
import { getRouterData } from "../common/router";
import { SideNavCollapseContextProvider } from "../common/SideNavCollapseContext";
import { getCurrentSideNavItem } from "../common/sideNavMenu";
import AppNotificationsPanel from "../components/AppNotificationsPanel";
import DrawerMenu from "../components/DrawerMenu";
import ErrorBoundary, { ErrorPage } from "../components/ErrorBoundary";
import SideNav from "../components/SideNav";
import TopNav from "../components/TopNav";
import AsyncJobs from "../features/AsyncJobs";
import { DASHBOARD_LAYOUT_ID } from "../features/NotificationsPage/constants";
import API from "../services/rest/api";
import InsetPageTemplate from "../templates/InsetPageTemplate";
import * as Routes from "../utils/routes";
import BaseLayout from "./BaseLayout";

const routerData = getRouterData();
const genRoutes = () => [
  {
    component: routerData[Routes.HOME].component,
    path: Routes.HOME,
    exact: true,
  },
  {
    component: routerData[Routes.MYCARD_OVERVIEW].component,
    path: Routes.MYCARD_OVERVIEW,
    exact: true,
  },
  {
    component: routerData[Routes.SPEND_CARDS].component,
    path: Routes.SPEND_CARDS,
    exact: true,
  },
  {
    component: routerData[Routes.MYCARD_EXPENSES].component,
    path: Routes.MYCARD_EXPENSES,
    exact: true,
  },
  {
    component: routerData[Routes.SPEND_EXPENSES].component,
    path: Routes.SPEND_EXPENSES,
    exact: true,
  },
  {
    component: routerData[Routes.MYCARD_EXPENSE_DETAILS].component,
    path: Routes.MYCARD_EXPENSE_DETAILS,
    exact: true,
  },
  {
    component: routerData[Routes.INSIGHTS_EXPENSE_DETAILS].component,
    path: Routes.INSIGHTS_EXPENSE_DETAILS,
    exact: true,
  },
  {
    component: routerData[Routes.SPEND_EXPENSE_DETAILS].component,
    path: Routes.SPEND_EXPENSE_DETAILS,
    exact: true,
  },
  {
    component: routerData[Routes.MYCARD_TRIPS].component,
    path: Routes.MYCARD_TRIPS,
    exact: true,
  },
  {
    component: routerData[Routes.SPEND_TRIPS].component,
    path: Routes.SPEND_TRIPS,
    exact: true,
  },
  {
    component: routerData[Routes.MYCARD_TRIP_DETAILS].component,
    path: Routes.MYCARD_TRIP_DETAILS,
    exact: true,
  },
  {
    component: routerData[Routes.SPEND_TRIP_DETAILS].component,
    path: Routes.SPEND_TRIP_DETAILS,
    exact: true,
  },
  {
    component: routerData[Routes.CENTERACCOUNT_SUMMARY].component,
    path: Routes.CENTERACCOUNT_SUMMARY,
    exact: true,
  },
  {
    component: routerData[Routes.CENTERACCOUNT_TRANSACTIONS].component,
    path: Routes.CENTERACCOUNT_TRANSACTIONS,
    exact: true,
  },
  {
    component: routerData[Routes.LINKED_ACCOUNTS].component,
    path: Routes.LINKED_ACCOUNTS,
    exact: true,
  },
  {
    component: routerData[Routes.STATEMENTS].component,
    path: Routes.STATEMENTS,
    exact: true,
  },
  {
    component: routerData[Routes.USER_CREATE].component,
    path: Routes.USER_CREATE,
    exact: true,
  },
  {
    component: routerData[Routes.USER_EDIT].component,
    path: Routes.USER_EDIT,
    exact: true,
  },
  {
    component: routerData[Routes.USER_INFO].component,
    path: Routes.USER_INFO,
    exact: true,
  },
  {
    component: routerData[Routes.USERS].component,
    path: Routes.USERS,
    exact: true,
  },
  {
    component: routerData[Routes.COST_CENTERS].component,
    path: Routes.COST_CENTERS,
    exact: true,
  },
  {
    component: routerData[Routes.COST_CENTERS_CREATE].component,
    path: Routes.COST_CENTERS_CREATE,
    exact: true,
  },
  {
    component: routerData[Routes.COST_CENTERS_EDIT].component,
    path: Routes.COST_CENTERS_EDIT,
    exact: true,
  },
  {
    component: routerData[Routes.EXPENSE_TYPES].component,
    path: Routes.EXPENSE_TYPES,
    exact: true,
  },
  {
    component: routerData[Routes.EXPENSE_TYPES_CREATE].component,
    path: Routes.EXPENSE_TYPES_CREATE,
    exact: true,
  },
  {
    component: routerData[Routes.EXPENSE_TYPES_EDIT].component,
    path: Routes.EXPENSE_TYPES_EDIT,
  },
  {
    component: routerData[Routes.GENERAL_LEDGER].component,
    path: Routes.GENERAL_LEDGER,
    exact: true,
  },
  {
    component: routerData[Routes.EXPENSE_FORMS].component,
    path: Routes.EXPENSE_FORMS,
    exact: true,
  },
  {
    component: routerData[Routes.EXPENSE_FIELDS].component,
    path: Routes.EXPENSE_FIELDS,
    exact: true,
  },
  {
    component: routerData[Routes.EXPENSE_FIELDS_CREATE].component,
    path: Routes.EXPENSE_FIELDS_CREATE,
    exact: true,
  },
  {
    component: routerData[Routes.EXPENSE_FIELDS_EDIT].component,
    path: Routes.EXPENSE_FIELDS_EDIT,
    exact: true,
  },
  {
    component: routerData[Routes.EXPENSE_FIELDS_MAPPING_CONFIGURATION].component,
    path: Routes.EXPENSE_FIELDS_MAPPING_CONFIGURATION,
    exact: false,
  },
  {
    component: routerData[Routes.GENERAL_LEDGER_CREATE].component,
    path: Routes.GENERAL_LEDGER_CREATE,
    exact: true,
  },
  {
    component: routerData[Routes.GENERAL_LEDGER_EDIT].component,
    path: Routes.GENERAL_LEDGER_EDIT,
    exact: true,
  },
  {
    component: routerData[Routes.INTEGRATIONS].component,
    path: Routes.INTEGRATIONS,
    exact: false,
  },
  {
    component: routerData[Routes.SFTP].component,
    path: Routes.SFTP,
    exact: false,
  },
  {
    component: routerData[Routes.REIMBURSEMENTS_POLICY].component,
    path: Routes.REIMBURSEMENTS_POLICY,
    exact: false,
  },
  {
    component: routerData[Routes.REIMBURSEMENTS_TRANSFERS].component,
    path: Routes.REIMBURSEMENTS_TRANSFERS,
    exact: false,
  },
  {
    component: routerData[Routes.REIMBURSEMENTS_SETTINGS].component,
    path: Routes.REIMBURSEMENTS_SETTINGS,
    exact: false,
  },
  {
    component: routerData[Routes.ACCOUNT_INFO_SETTINGS].component,
    path: Routes.ACCOUNT_INFO_SETTINGS,
    exact: false,
  },
  {
    component: routerData[Routes.TRAVEL_INFO_SETTINGS].component,
    path: Routes.TRAVEL_INFO_SETTINGS,
    exact: false,
  },
  {
    component: routerData[Routes.TRAVEL_INFO_USER_SETTINGS].component,
    path: Routes.TRAVEL_INFO_USER_SETTINGS,
    exact: false,
  },
  {
    component: routerData[Routes.TRAVEL_DETAILS].component,
    path: Routes.TRAVEL_DETAILS,
    exact: false,
  },
  {
    component: routerData[Routes.TRAVEL_POLICY].component,
    path: Routes.TRAVEL_POLICY,
    exact: true,
  },
  {
    component: routerData[Routes.TRAVEL_POLICY_GROUP].component,
    path: Routes.TRAVEL_POLICY_GROUP,
    exact: false,
  },
  {
    component: routerData[Routes.NOTIFICATIONS].component,
    path: Routes.NOTIFICATIONS,
    exact: false,
  },
  {
    component: routerData[Routes.TRIP_TYPES].component,
    path: Routes.TRIP_TYPES,
    exact: false,
  },
  {
    component: routerData[Routes.TRAVELER_PROFILES].component,
    path: Routes.TRAVELER_PROFILES,
    exact: false,
  },
  {
    component: routerData[Routes.EXPENSE_HUB].component,
    path: Routes.EXPENSE_HUB,
    exact: true,
  },
  {
    component: routerData[Routes.MY_APPROVALS].component,
    path: Routes.MY_APPROVALS,
    exact: true,
  },
  {
    component: routerData[Routes.MY_APPROVALS_CARDS].component,
    path: Routes.MY_APPROVALS_CARDS,
    exact: true,
  },
  {
    component: routerData[Routes.MY_APPROVALS_EXPENSES].component,
    path: Routes.MY_APPROVALS_EXPENSES,
    exact: true,
  },
  {
    component: routerData[Routes.REPORTS].component,
    path: Routes.REPORTS,
    exact: true,
  },
  {
    component: routerData[Routes.EXPENSEHUB_EXPENSE_DETAILS].component,
    path: Routes.EXPENSEHUB_EXPENSE_DETAILS,
    exact: true,
  },
  {
    component: routerData[Routes.MY_APPROVALS_EXPENSE_DETAILS].component,
    path: Routes.MY_APPROVALS_EXPENSE_DETAILS,
    exact: true,
  },
  {
    component: routerData[Routes.INSIGHTS_TRAVEL_TAB].component,
    path: Routes.INSIGHTS_TRAVEL_TAB,
    exact: false,
  },
  {
    component: routerData[Routes.INSIGHTS].component,
    path: Routes.INSIGHTS,
    exact: false,
  },
  {
    component: routerData[Routes.INSIGHTS_EXPENSE].component,
    path: Routes.INSIGHTS_EXPENSE,
    exact: false,
  },
  {
    component: routerData[Routes.RULES_CUSTOM_APPROVAL].component,
    path: Routes.RULES_CUSTOM_APPROVAL,
    exact: true,
  },
  {
    component: routerData[Routes.EXPENSE_FORMS_EDIT].component,
    path: Routes.EXPENSE_FORMS_EDIT,
    exact: true,
  },
  {
    component: routerData[Routes.EXPORT_CONFIGURATION].component,
    path: Routes.EXPORT_CONFIGURATION,
    exact: true,
  },
  {
    component: routerData[Routes.LOGIN_SSO_CONFIGURATION].component,
    path: Routes.LOGIN_SSO_CONFIGURATION,
    exact: true,
  },
  {
    component: routerData[Routes.HRIS].component,
    path: Routes.HRIS,
    exact: true,
  },
  {
    component: routerData[Routes.THEME_CONFIGURATION].component,
    path: Routes.THEME_CONFIGURATION,
    exact: true,
  },
  {
    component: routerData[Routes.SSO_REDIRECT].component,
    path: Routes.SSO_REDIRECT,
    exact: true,
  },
  {
    component: routerData[Routes.GUEST_TRAVELERS].component,
    path: Routes.GUEST_TRAVELERS,
    exact: true,
  },
  {
    component: routerData[Routes.GUEST_TRAVELER_EDIT].component,
    path: Routes.GUEST_TRAVELER_EDIT,
  },
  {
    component: routerData[Routes.SANDBOX_TOOLS].component,
    path: Routes.SANDBOX_TOOLS,
    exact: true,
  },
  {
    component: routerData[Routes.SANDBOX_TOOLS_CARDS].component,
    path: Routes.SANDBOX_TOOLS_CARDS,
    exact: true,
  },
  {
    component: routerData[Routes.SANDBOX_TOOLS_TRANSACTION].component,
    path: Routes.SANDBOX_TOOLS_TRANSACTION,
    exact: true,
  },
  {
    component: routerData[Routes.GUEST_TRAVELER_INFO].component,
    path: Routes.GUEST_TRAVELER_INFO,
    exact: true,
  },
  {
    component: routerData[Routes.CARDS].component,
    path: Routes.CARDS,
    exact: true,
  },
  {
    component: routerData[Routes.ADMIN_CARD_DETAILS].component,
    path: Routes.ADMIN_CARD_DETAILS,
    exact: true,
  },
  {
    component: routerData[Routes.SPEND_CARD_DETAILS].component,
    path: Routes.SPEND_CARD_DETAILS,
    exact: true,
  },
];

export const SIDER_COLLAPSED_WIDTH_PX = 54;

export const DashboardLayout = ({
  showSideNav,
  match,
  fixedHeight,
  noContainer,
  location,
  ...rest
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleAsyncCallback = useCallback((payload) => {
    Notifier.notification(payload);
  }, []);

  const handleOpenDrawer = useCallback(() => {
    setIsDrawerOpen(true);
  }, []);

  const handleCloseDrawer = useCallback(() => {
    setIsDrawerOpen(false);
  }, []);

  const handleError = useCallback((error, info, { me }) => {
    API.logError(API.LogLevel.error, {
      message: "React error",
      details: { error, info, user: me },
    });
  }, []);

  const sideNavItem = useMemo(() => getCurrentSideNavItem(location), [location]);

  return (
    <BaseLayout {...rest}>
      {(data) => {
        // TODO: remove this, when a separate route is created for the travelers arranger to create guest
        const hiddenSideNavForTravelerArranger =
          match.path === Routes.USER_CREATE &&
          intersection([UserRoles.TM, UserRoles.TA], data.me.roles).length &&
          !data.me.roles.includes(UserRoles.FM);

        return (
          <SideNavCollapseContextProvider sideNavItem={sideNavItem}>
            <CardActivationWizardProvider>
              <CardExpirationProvider me={data?.me}>
                <InsetPageTemplate
                  id={DASHBOARD_LAYOUT_ID}
                  header={<TopNav location={location} />}
                  sider={
                    sideNavItem &&
                    showSideNav &&
                    !hiddenSideNavForTravelerArranger && <SideNav sideNavItem={sideNavItem} />
                  }
                  siderCollapsedWidth={SIDER_COLLAPSED_WIDTH_PX}
                  drawer={
                    <DrawerMenu
                      userFullname={data.me.fullName}
                      location={location}
                      sandbox={data.me.organization.sandbox}
                      visible={isDrawerOpen}
                      onClose={handleCloseDrawer}
                    />
                  }
                  me={data?.me}
                  notifications={<AppNotificationsPanel />}
                  onOpenDrawer={handleOpenDrawer}
                  fixedHeight={fixedHeight}
                  noContainer={noContainer}
                >
                  <ErrorBoundary
                    placeHolder={<ErrorPage />}
                    onErrorCaught={(error, info) => handleError(error, info, data)}
                  >
                    <Switch>
                      {genRoutes(match).map((route) => (
                        <Route key={route.path} {...route} />
                      ))}
                    </Switch>
                  </ErrorBoundary>
                  <AsyncJobs onAsyncCallback={handleAsyncCallback} />
                </InsetPageTemplate>
              </CardExpirationProvider>
            </CardActivationWizardProvider>
          </SideNavCollapseContextProvider>
        );
      }}
    </BaseLayout>
  );
};

DashboardLayout.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
  showSideNav: PropTypes.bool,
  fixedHeight: PropTypes.bool,
  noContainer: PropTypes.bool,
};

DashboardLayout.defaultProps = {
  showSideNav: true,
  fixedHeight: false,
  noContainer: false,
};
