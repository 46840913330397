/* eslint-disable no-console */
import { forEach, kebabCase, round } from "lodash";

export { getElementType } from "./getElementType";
export { childrenUtils } from "./childrenUtils";

// eslint-disable-next-line function-name/starts-with-verb
export const styleConstructor = (params) => {
  if (!params) return "";
  if (typeof params !== "object") {
    console.error("Invalid type supplied to style constructor:", { params });
    return "";
  }
  let styleString = "";
  const styleParam = [];
  const buildStyleString = (val, key) => {
    if (val || val === 0) styleParam.push({ [key]: val });
  };
  forEach(params, buildStyleString);
  if (styleParam.length > 0) {
    const sParams = styleParam.map((param, idx) => {
      const property = Object.keys(styleParam[idx])[0];
      // eslint-disable-next-line id-length
      const s = `${kebabCase(property)}: ${styleParam[idx][property]} !important;\n`;
      return s;
    });
    styleString = `${sParams.join("")}`;
  }
  return styleString;
};

// Number formatter helpers
export const convertToPennies = (value, precision = 0) => {
  if (!Number.isFinite(Number(value))) {
    console.error(`Cannot convert value that is not a number to pennies: ${value}`);
  }
  return round(value * 100, precision);
};

export const convertToDollars = (value, precision = 4) => {
  if (!Number.isFinite(Number(value))) {
    console.error(`Cannot convert value that is not a number to dollars: ${value}`);
  }

  // round to avoid floating point issues
  return round(value * 0.01, precision);
};

export const makeShortNumber = (value) => {
  if (Math.abs(value) > 9999) {
    return { number: value / 1000, suffix: "K" };
  }

  return { number: value, suffix: "" };
};

export const formatNumber = (params) => {
  const {
    decimalPrecision = 2,
    setPrecision = true,
    currencyCode,
    transform,
    style,
    currencyDisplay,
    suffix,
    localLanguage,
    shortFormat = false,
    cardLimitFormat = false,
    ...otherProps
  } = params;
  const locales = localLanguage
    ? { nu: localLanguage }
    : { nu: global?.navigator?.language || global?.navigator?.userLanguage || "en-US" };
  const negateSign = params.value < 0 ? "-" : "";
  const value = Math.abs(params.value);
  const precision = shortFormat ? 0 : decimalPrecision;
  const fVal = new Intl.NumberFormat(cardLimitFormat ? "en-US" : [locales.nu, "en-US"], {
    style,
    currencyDisplay,
    // TODO Falling back to USD to avoid app crash; reconsider better alternatives
    currency: currencyCode || "USD", // Assumes org base currency is USD
    minimumFractionDigits: setPrecision ? precision : 0,
    maximumFractionDigits: setPrecision ? precision : 20,
    ...otherProps,
  });
  // TODO Falling back to USD to avoid app crash; reconsider better alternatives
  if (!currencyCode && style === "currency")
    console.error("Invalid currency code provided to formatNumber():", {
      currencyCode,
    });
  const transformedValue = transform ? transform(value) : value;
  let shortNumber = null;
  if (shortFormat) {
    shortNumber = makeShortNumber(transformedValue);
  }
  return shortFormat
    ? `${negateSign}${fVal.format(shortNumber.number)}${shortNumber.suffix} ${suffix || ""}`
    : `${negateSign}${fVal.format(transformedValue)}${suffix ? ` ${suffix}` : ""}`;
};

export const generateRandomString = (prefix = "random") =>
  `${prefix}-${Math.random().toString(36).slice(2, 15) + Math.random().toString(36).slice(2, 15)}`;

export const userAccessBlackoutText =
  "Please note that access to the system is temporarily unavailable due to system maintenance. We apologize for any inconvenience and appreciate your understanding.";
