import gql from "graphql-tag";
import { graphql } from "react-apollo";

// TOGGLE APP NOTIFICATIONS PANEL
export const GET_SHOW_APP_NOTIFICATIONS_PANEL = gql`
  query {
    showAppNotificationsPanel @client {
      visible
      onlyHRIS
    }
  }
`;

export const withShowAppNotificationsPanelFlag = graphql(GET_SHOW_APP_NOTIFICATIONS_PANEL, {
  props: ({ data: { loading, error, showAppNotificationsPanel } }) => {
    if (loading) return { loading };
    if (error) return { error };
    return {
      loading: false,
      isAppNotificationsPanelVisible: showAppNotificationsPanel?.visible,
      onlyHRIS: showAppNotificationsPanel?.onlyHRIS,
    };
  },
});

// TOGGLE RECEIPTS BIN PANEL
export const GET_SHOW_RECEIPTS_BIN_PANEL = gql`
  query {
    showReceiptsBinPanel @client {
      visible
    }
  }
`;

export const withShowReceiptsBinPanelFlag = graphql(GET_SHOW_RECEIPTS_BIN_PANEL, {
  props: ({ data: { loading, error, showReceiptsBinPanel }, ownProps }) => {
    if (loading) return { loading };
    if (error) return { error };
    return {
      ...ownProps,
      isReceiptsBinPanelVisible: showReceiptsBinPanel.visible,
    };
  },
});
