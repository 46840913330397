import { createContext, useEffect, useState, type ReactNode } from "react";
import Pusher from "pusher-js";

import { useIsBulkActionsV2Enabled } from "../components/Expenses/useExpenseBulkActions/useIsBulkActionsEnabled";
import { retrieveToken } from "../services/rest/api";
import { useHasHRIS } from "../services/rest/hris";

const AUTH_URL = `${import.meta.env.REACT_APP_DEVELOPER_API}/notification/authentication`;
const APP_KEY = import.meta.env.REACT_APP_PUSHER_APP_KEY || "";
const CLUSTER = import.meta.env.REACT_APP_PUSHER_CLUSTER || "";

const useIsPusherEnabled = () => {
  const isBulkActionsV2Enabled = useIsBulkActionsV2Enabled();
  const hasHRIS = useHasHRIS();
  return Boolean(isBulkActionsV2Enabled || hasHRIS);
};

export const PusherContext = createContext<Pusher | undefined>({} as Pusher);

export const PusherProvider = ({ children }: { children: ReactNode }) => {
  const isPusherEnabled = useIsPusherEnabled();
  const [pusher] = useState<Pusher>(() => {
    const pusherClient = new Pusher(APP_KEY, {
      cluster: CLUSTER,
      channelAuthorization: {
        endpoint: AUTH_URL,
        transport: "ajax",
        headers: {
          Authorization: `Bearer ${retrieveToken?.()?.access_token}`,
        },
      },
    });
    return pusherClient;
  });

  // Disconnect pusher on unmount
  useEffect(() => () => pusher.disconnect(), [pusher]);

  // Disconnect pusher client if isPusherEnabled is false
  useEffect(() => {
    if (!isPusherEnabled) pusher?.disconnect();
  }, [pusher, isPusherEnabled]);

  return <PusherContext.Provider value={pusher}>{children}</PusherContext.Provider>;
};
