import { Drawer as AntDrawer } from "antd";
import styled from "styled-components";

import Colors from "../../themes/colors";

const Drawer = styled(AntDrawer)`
  .ant-drawer-close {
    color: ${Colors.midnight} !important;
  }

  .ant-drawer-header {
    border-bottom: 0 !important;
  }
`;

export default Drawer;
