import { ConditionOperator, PaymentType } from "./types";

export type IConditionDescriptionContext = {
  paymentTypes: PaymentType[];
};

const paymentTypeToDescriptionMapping: Record<string, string> = {
  [PaymentType.Card]: "incurs a Centercard Expense",
  [PaymentType.Mileage]: "selects Mileage Expense",
  [PaymentType.Personal]: "marks expense as Personal",
  [PaymentType.Reimbursable]: "creates a Reimbursable Expense",
};

export default ({
  property,
  operator,
  value,
  context,
}: {
  context: IConditionDescriptionContext;
  operator: ConditionOperator;
  property: string;
  value: any;
}) => {
  if (property === "paymentType" && value !== undefined && value !== null) {
    let includedValues: string[] | undefined;
    switch (operator) {
      case ConditionOperator.EQ:
        includedValues = [value];
        break;
      case ConditionOperator.NEQ:
        includedValues = context.paymentTypes.filter((v) => v !== value);
        break;
      case ConditionOperator.OF:
        includedValues = value;
        break;
      case ConditionOperator.NOF:
        includedValues = context.paymentTypes.filter((v) => !value.includes(v));
    }
    if (includedValues?.length) {
      return `user ${includedValues.map((v) => paymentTypeToDescriptionMapping[v]).join(" or ")}`;
    }
  }
};
