import { useContext } from "react";
import _get from "lodash/get";
import styled from "styled-components";

import { Icon, Tooltip } from "@core/ui-legacy";
import Colors from "@core/ui-legacy/themes/colors";

import AuthUserContext from "../../common/contexts/AuthUserContext";
import { userHasFeatures } from "../../common/userHasFeatures";
import type { IAuthUser } from "../../features/UserInfoPage/types";
import featureFlags from "../../utils/featureFlags";
import BaseReadOnlyField from "../ReadOnlyField";
import type { Entity, Field } from "./types";

const ReadOnlyFieldStyled = styled(BaseReadOnlyField)`
  margin-left: ${(vcflag) => (vcflag ? "0" : "0.188rem")};
  font-size: ${(vcflag) => (vcflag ? "16px !important" : "0.75rem !important")};
  font-weight: ${(vcflag) => (vcflag ? "600 !important" : "700 !important")};
`;
export const OverlineLabelWrapper = styled.div`
  margin-bottom: ${(vcflag) => (vcflag ? "0" : "1.5rem ")};
  font-size: ${(vcflag) => (vcflag ? "16px !important" : "0.75rem !important")};
  font-weight: ${(vcflag) => (vcflag ? "600 !important" : "700 !important")};
  display: flex;
  align-items: center;

  & .section-details__field {
    font-size: ${(vcflag) => (vcflag ? "16px !important" : "0.75rem !important")};
  }
`;
export const TooltipWrapper = styled.div`
  margin-bottom: ${(vcflag) => (vcflag ? "0" : "1.6rem ")};
`;

const NoValueLabel = styled.label`
  color: ${Colors.monumental};
`;

type ReadOnlyFieldProps = {
  entity: Entity;
  field: Partial<Field>;
  text?: string;
};

const ReadOnlyField = ({ entity, field, text }: ReadOnlyFieldProps) => {
  const { isRequired, isEnabled, name, property, tooltip, values, defaultValue } = field ?? {};

  let initialValue = text ?? _get(entity, property!, null);

  const mapping = values?.find((value) => value.ID === initialValue);
  if (mapping) {
    initialValue = mapping.name;
  }

  if (typeof initialValue === "boolean") {
    initialValue = initialValue ? "Yes" : "No";
  }

  const { toggles } = useContext<IAuthUser>(AuthUserContext);
  const vcflag = userHasFeatures(toggles, [featureFlags.VIRTUAL_MULTI_CARD]);
  const opacity = isEnabled ? 1 : 0.4;
  return (
    <OverlineLabelWrapper {...{ vcflag: vcflag.toString() }}>
      <ReadOnlyFieldStyled
        {...{
          vcflag,
          required: isRequired,
          label: name ?? "",
          text: initialValue ?? defaultValue ?? <NoValueLabel>No value added</NoValueLabel>,
          disabled: !isEnabled,
          styleLabel: {
            opacity,
          },
          styleText: {
            opacity,
          },
        }}
      />
      {tooltip && !!Object.keys(tooltip).length && (
        <TooltipWrapper>
          <Tooltip {...{ title: tooltip.title }}>
            <Icon {...tooltip.option} />
          </Tooltip>
        </TooltipWrapper>
      )}
    </OverlineLabelWrapper>
  );
};

export default ReadOnlyField;
