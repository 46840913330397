import { forwardRef, type InputHTMLAttributes } from "react";

import { cn } from "../../lib/utils";
import { Text } from "../Text";

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  error?: string;
  icon?: React.ReactNode;
  iconAfter?: React.ReactNode;
};

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, icon, iconAfter, error, ...props }, ref) => {
    const hasError = !!error;

    return (
      <div className="relative w-full">
        {icon && <div className="absolute left-3 top-1/2 translate-y-[-40%] pl-1">{icon}</div>}
        <input
          type={type}
          className={cn(
            `h-[2.25rem] w-full rounded-[0.25rem] border ${
              hasError ? "border-[#AF2712]" : "border-[#E6E6E6]"
            } bg-white px-3 text-base text-text-500 placeholder-text-400 disabled:cursor-not-allowed disabled:opacity-50`,
            icon ? "pl-10" : "",
            iconAfter ? "pr-10" : "",
            className,
          )}
          ref={ref}
          {...props}
        />
        {iconAfter && (
          <div className="absolute right-3 top-1/2 translate-y-[-40%] pr-1">{iconAfter}</div>
        )}
        {hasError && (
          <Text className="leading-5 text-[#AF2712]" variant="subtext" weight="light">
            {error}
          </Text>
        )}
      </div>
    );
  },
);

export { Input };
