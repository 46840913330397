import { useState } from "react";
import _ from "lodash";

type SelectedItemsParams<T> = {
  selectedItemKeys: string[];
  selectedItems: T[];
};

const useSelectedRows = <T>(
  initialSelectedItems: T[] = [],
  keyProp = "ID",
): [SelectedItemsParams<T>, (items: T[]) => void] => {
  const fillState = (items: T[]): SelectedItemsParams<T> => ({
    selectedItems: items,
    selectedItemKeys: items.map((item) => _.get(item, keyProp)),
  });

  const [{ selectedItems, selectedItemKeys }, setSelectedRows] = useState<SelectedItemsParams<T>>(
    fillState(initialSelectedItems),
  );

  const setSelectedItems = (value: T[]) => {
    setSelectedRows(fillState(value));
  };

  return [{ selectedItems, selectedItemKeys }, setSelectedItems];
};

export default useSelectedRows;
