import { createRef, useEffect, useState } from "react";
import gql from "graphql-tag";
import { cloneDeep } from "lodash";
import PropTypes from "prop-types";
import { useApolloClient, useMutation } from "react-apollo";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

import { Input } from "@core/ui-legacy";
import Colors from "@core/ui-legacy/themes/colors";

import cardImg from "../../assets/imgs/CenterCard_Back.svg";
import { ACTIVATE_CARD } from "../../services/graphql/mutations";
import { GET_ME_CARDS_AND_FRAUD } from "../../services/graphql/queries";

const CenterCardImg = styled.img`
  width: 13.25rem;
`;
const ModalDescription = styled.div`
  padding-bottom: 0.5rem;
  max-width: 89%;
`;
const Aligner = styled.div`
  display: flex;
  align-items: center;
  padding: 2.8125rem 0;
  flex-direction: column;
`;

const InputsGroup = styled.div`
  display: flex;
  align-items: center;
  margin: 1.5rem 0 3rem;
  position: relative;

  input {
    font-weight: bolder !important;
    width: 2rem;
    background: ${Colors.bg_gray};
    border-radius: 0.25rem;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }

  input:not(:last-child) {
    margin-right: 0.75rem;
  }

  input:focus,
  input:hover {
    border-color: ${Colors.placeholder_gray};
  }

  &.errored input {
    border-color: ${Colors.danger_red} !important;
  }

  &.errored::after {
    content: "Card number is invalid";
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    right: 0;
    font-family: var(--cen-font-family), "Whitney A", "Whitney B", Arial, Helvetica, sans-serif;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.25rem;
    color: ${Colors.danger_red};
  }
`;

const ConnectCard = ({ nextStep, cardsToActivate = [], navigate, phone, setCardsToActivate }) => {
  const client = useApolloClient();
  const numberOfInputs = 4;
  const [values, setValues] = useState(Array.from({ length: numberOfInputs }));
  const [focusedInputNumber] = useState({ index: null });
  const setFocusedInputNumber = (val) => {
    focusedInputNumber.index = val;
  };
  const [inputRefsArray] = useState(() =>
    Array.from({ length: numberOfInputs }, () => createRef()),
  );

  useEffect(() => {
    // focus the firs input initially
    setTimeout(() => {
      if (inputRefsArray?.[0]?.current) {
        inputRefsArray[0].current.focus();
      }
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isNumbersValid, setNumbersValidation] = useState(true);
  const [activateCard] = useMutation(ACTIVATE_CARD, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_ME_CARDS_AND_FRAUD,
        variables: { limit: 25 },
      },
    ],
  });

  const handleChange = async (e, i) => {
    e.persist();
    const { value } = e.target;
    const pattern = /^[0-9]?$/;
    const newValue = value?.[value.length - 1] || "";
    if (!pattern.test(newValue)) return;
    let newValues = cloneDeep(values);
    if (!isNumbersValid && i === 0) {
      newValues = Array.from({ length: numberOfInputs }).fill("");
      setNumbersValidation(true);
    }
    newValues[i] = newValue ? Number(newValue) : "";
    setValues(newValues);
    let nextIndex = i < numberOfInputs - 1 ? i + 1 : 0;
    if (!newValue) nextIndex = i;
    const isAllValuesSettled = newValues.every((el) => Number.isInteger(el));
    if (isAllValuesSettled) {
      try {
        const last4 = newValues.join("");
        const currentCard =
          cardsToActivate.find((card) => card.last4 === last4) || cardsToActivate[0];

        const response = await activateCard({
          variables: { cardID: currentCard.ID, last4 },
        });
        client.writeFragment({
          id: `CardInfo:${currentCard.ID}`,
          fragment: gql`
            fragment cardInfo on CardInfo {
              isRequested
              isClosed
              activeState
            }
          `,
          data: {
            isRequested: false,
            activeState: true,
            isClosed: false,
          },
        });
        setNumbersValidation(true);
        let remainingCardsForActivate = cardsToActivate;
        if (response?.data?.activateCard === "success") {
          remainingCardsForActivate = remainingCardsForActivate.filter(
            (item) => item?.ID !== currentCard?.ID,
          );
        }

        setCardsToActivate(remainingCardsForActivate);

        if (phone && !remainingCardsForActivate.length) {
          navigate(3);
        } else {
          nextStep();
        }
      } catch (err) {
        console.error("Card activation failed:", err.message);
        setNumbersValidation(false);
      }
    }
    setFocusedInputNumber(nextIndex);
    setTimeout(() => {
      if (inputRefsArray?.[nextIndex]?.current) {
        inputRefsArray[nextIndex].current.focus();
      }
    }, 0);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 8) {
      const newValues = cloneDeep(values);
      newValues[focusedInputNumber.index] = "";
      setValues(newValues);
      const prevIndex =
        focusedInputNumber.index > 0 ? focusedInputNumber.index - 1 : focusedInputNumber.index;
      setFocusedInputNumber(prevIndex);

      setTimeout(() => {
        if (inputRefsArray?.[prevIndex]?.current) {
          inputRefsArray[prevIndex].current.focus();
        }
      }, 0);
    }
  };

  return (
    <>
      <div className="ant-modal-header">
        <div className="ant-modal-title">Connect CenterCard</div>
      </div>
      <ModalDescription>
        Enter the 4 last digits of the card number on the back of your CenterCard.
      </ModalDescription>
      <Aligner>
        <CenterCardImg alt="User CenterCard" src={cardImg} width="212" height="132" />
        <InputsGroup
          data-testid="connect-card-wrapper"
          className={isNumbersValid ? null : "errored"}
        >
          {inputRefsArray.map((ref, i) => (
            <Input
              data-testid={`card-last4-input${i}`}
              ref={ref}
              key={uuidv4()}
              value={values[i]}
              onChange={(e) => handleChange(e, i)}
              onKeyDown={handleKeyDown}
              onFocus={() => setFocusedInputNumber(i)}
              onBlur={() => setFocusedInputNumber(null)}
            />
          ))}
        </InputsGroup>
      </Aligner>
    </>
  );
};

ConnectCard.propTypes = {
  nextStep: PropTypes.func,
  phone: PropTypes.string,
  navigate: PropTypes.func,
  cardsToActivate: PropTypes.array,
  setCardsToActivate: PropTypes.func,
};

export default ConnectCard;
