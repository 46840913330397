import { PureComponent } from "react";
import PropTypes from "prop-types";

class BodyPure extends PureComponent {
  render() {
    const { children, ...rest } = this.props;
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <tbody {...rest}>{children}</tbody>;
  }
}

BodyPure.propTypes = {
  children: PropTypes.any,
};

export default BodyPure;
