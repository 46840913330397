import {
  createContext,
  useMemo,
  useState,
  type Dispatch,
  type ReactNode,
  type SetStateAction,
} from "react";

type EntityType = "expense";

export type BulkActionExpenseMessage = {
  message: {
    entityIds: string[];
    entityType: EntityType;
    isLocked: boolean;
    userId: string;
  };
};

export const ExpenseLockContext = createContext(
  {} as {
    lockedExpenseMap: Map<string, { isLocked: boolean; originatorId: string | null }>;
  },
);

export const LockExpenseContext = createContext(
  {} as {
    setLockedExpenseMap: Dispatch<
      SetStateAction<
        Map<
          string,
          {
            isLocked: boolean;
            originatorId: string | null;
          }
        >
      >
    >;
  },
);

const ExpenseLockProvider = ({ children }: { children: ReactNode }) => {
  const [lockedExpenseMap, setLockedExpenseMap] = useState<
    Map<string, { isLocked: boolean; originatorId: string | null }>
  >(new Map());

  const expenseLockValue = useMemo(
    () => ({
      setLockedExpenseMap,
    }),
    [setLockedExpenseMap],
  );

  const lockedExpensesValue = useMemo(
    () => ({
      lockedExpenseMap,
    }),
    [lockedExpenseMap],
  );

  return (
    <LockExpenseContext.Provider value={expenseLockValue}>
      <ExpenseLockContext.Provider value={lockedExpensesValue}>
        {children}
      </ExpenseLockContext.Provider>
    </LockExpenseContext.Provider>
  );
};

export default ExpenseLockProvider;
