import { memo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { convertToDollars, formatNumber, styleConstructor } from "../../utils";

const Wrapper = styled.span`
  color: ${({ callOut }) => (callOut ? "#AF2712" : "inherit")};
  ${({ styling }) => styling && styleConstructor(styling)};
`;

const FormattedNumber = (props) => {
  const {
    className,
    value,
    currencyCode,
    locales,
    transform,
    decimalPrecision,
    options,
    shortFormat,
    displayNegativeSign,
    callOut,
    styling,
    asPercent,
    useGrouping,
    cardLimitFormat,
    ...dataProps
  } = props;
  const { style, currency, currencyDisplay, ...oProps } = options;

  const fnParams = {
    value: displayNegativeSign ? value : Math.abs(value),
    decimalPrecision,
    currencyCode,
    transform,
    style,
    currencyDisplay,
    locales,
    shortFormat,
    useGrouping,
    cardLimitFormat,
    ...oProps,
  };
  if (asPercent) {
    fnParams.style = "percent";
  }
  const formattedValue = formatNumber(fnParams);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Wrapper className={className} styling={styling} callOut={callOut} {...dataProps}>
      {formattedValue}
    </Wrapper>
  );
};

FormattedNumber.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number.isRequired,
  styling: PropTypes.object,
  currencyCode: PropTypes.string,
  transform: PropTypes.func,
  decimalPrecision: PropTypes.number,
  cardLimitFormat: PropTypes.bool,
  shortFormat: PropTypes.bool,
  options: PropTypes.shape({
    localeMatcher: PropTypes.string,
    style: PropTypes.string,
    currency: PropTypes.string,
    currencyDisplay: PropTypes.string,
    useGrouping: PropTypes.bool,
    minimumIntegerDigits: PropTypes.number,
    minimumFractionDigits: PropTypes.number,
    maximumFractionDigits: PropTypes.number,
    minimumSignificantDigits: PropTypes.number,
    maximumSignificantDigits: PropTypes.number,
  }),
  locales: PropTypes.shape({
    nu: PropTypes.string,
  }),
  displayNegativeSign: PropTypes.bool,
  callOut: PropTypes.bool,
  asPercent: PropTypes.bool,
  useGrouping: PropTypes.bool,
};

FormattedNumber.defaultProps = {
  currencyCode: "USD",
  transform: (value) => convertToDollars(value),
  options: {
    style: "currency",
    currencyDisplay: "symbol",
  },
  locales: {
    nu: navigator.language || navigator.userLanguage,
  },
  decimalPrecision: 2,
  displayNegativeSign: true,
  asPercent: false,
  useGrouping: true,
};

export default memo(FormattedNumber);
