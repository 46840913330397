import { useContext } from "react";

import AuthUserContext from "../../../common/contexts/AuthUserContext";
import { userHasFeatures } from "../../../common/userHasFeatures";
import type { IAuthUser } from "../../../features/UserInfoPage/types";
import FeatureFlags from "../../../utils/featureFlags";

export const useIsBulkActionsV2Enabled = () => {
  const authUser = useContext<IAuthUser>(AuthUserContext);

  const isMarkAsPersonalEnabled = userHasFeatures(authUser.toggles, [
    FeatureFlags.EXPENSE_BULK_MARK_AS_PERSONAL_V2,
  ]);
  const isBulkUnarchiveEnabled = userHasFeatures(authUser.toggles, [
    FeatureFlags.EXPENSE_BULK_UNARCHIVE_V2,
  ]);
  const isBulkArchiveEnabled = userHasFeatures(authUser.toggles, [
    FeatureFlags.EXPENSE_BULK_ARCHIVE_V2,
  ]);
  const isBulkApproveEnabled = userHasFeatures(authUser.toggles, [
    FeatureFlags.EXPENSE_BULK_APPROVE_V2,
  ]);

  const isBulkActionsV2Enabled =
    isMarkAsPersonalEnabled ||
    isBulkUnarchiveEnabled ||
    isBulkArchiveEnabled ||
    isBulkApproveEnabled;

  return isBulkActionsV2Enabled;
};
