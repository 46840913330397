import assert from "assert";
import _ from "lodash";
import moment from "moment";

import {
  ConditionOperator,
  LogicalOperator,
  type ICondition,
  type IDescribedCondition,
  type IDescribedEvaluation,
  type IEvaluation,
  type IOperatorDefinition,
} from "./types";

const parseIfJSON = (value: any) => {
  try {
    return JSON.parse(value);
  } catch {
    return value;
  }
};
const getValue = (x: any) => x?.value ?? x;
const getDescription = (x: any) => x?.description ?? x;

export const eq: IOperatorDefinition = {
  ID: ConditionOperator.EQ,
  text: "is equal to",
  evaluate(entity: object, expressionParams: ICondition | IDescribedCondition) {
    return _.get(entity, getValue(expressionParams.property)) === getValue(expressionParams.value);
  },
  getText(expressionParams: ICondition | IDescribedCondition) {
    return `${getDescription(expressionParams.property)} is equal to ${getDescription(
      expressionParams.value,
    )}`;
  },
};

export const gt: IOperatorDefinition = {
  ID: ConditionOperator.GT,
  text: "is greater than",
  evaluate(entity: object, expressionParams: ICondition | IDescribedCondition) {
    return _.get(entity, getValue(expressionParams.property)) > getValue(expressionParams.value);
  },
  getText(expressionParams: ICondition | IDescribedCondition) {
    return `${getDescription(expressionParams.property)} is greater than ${getDescription(
      expressionParams.value,
    )}`;
  },
};

export const gte: IOperatorDefinition = {
  ID: ConditionOperator.GTE,
  text: "is greater than or equal to",
  evaluate(entity: object, expressionParams: ICondition | IDescribedCondition) {
    return _.get(entity, getValue(expressionParams.property)) >= getValue(expressionParams.value);
  },
  getText(expressionParams: ICondition | IDescribedCondition) {
    return `${getDescription(
      expressionParams.property,
    )} is greater than or equal to ${getDescription(expressionParams.value)}`;
  },
};

export const of: IOperatorDefinition = {
  ID: ConditionOperator.OF,
  text: "is one of",
  evaluate(entity: object, expressionParams: ICondition | IDescribedCondition) {
    const valueOnEntity = _.get(entity, getValue(expressionParams.property));
    return _.includes(getValue(parseIfJSON(expressionParams.value as any)), valueOnEntity);
  },
  getText(expressionParams: ICondition | IDescribedCondition) {
    const value = getDescription(expressionParams.value);
    return `${getDescription(expressionParams.property)} is one of ${
      Array.isArray(value) ? value.join(", ") : value
    }`;
  },
};

export const nof: IOperatorDefinition = {
  ID: ConditionOperator.NOF,
  text: "is not one of",
  evaluate(entity: object, expressionParams: ICondition | IDescribedCondition) {
    const valueOnEntity = _.get(entity, getValue(expressionParams.property));
    return !_.includes(getValue(parseIfJSON(expressionParams.value as any)), valueOnEntity);
  },
  getText(expressionParams: ICondition | IDescribedCondition) {
    const value = getDescription(expressionParams.value);
    return `${getDescription(expressionParams.property)} is not one of ${
      Array.isArray(value) ? value.join(", ") : value
    }`;
  },
};

export const lt: IOperatorDefinition = {
  ID: ConditionOperator.LT,
  text: "is less than",
  evaluate(entity: object, expressionParams: ICondition | IDescribedCondition) {
    return _.get(entity, getValue(expressionParams.property)) < getValue(expressionParams.value);
  },
  getText(expressionParams: ICondition | IDescribedCondition) {
    return `${getDescription(expressionParams.property)} is less than ${getDescription(
      expressionParams.value,
    )}`;
  },
};

export const lte: IOperatorDefinition = {
  ID: ConditionOperator.LTE,
  text: "is less than or equal to",
  evaluate(entity: object, expressionParams: ICondition | IDescribedCondition) {
    return _.get(entity, getValue(expressionParams.property)) <= getValue(expressionParams.value);
  },
  getText(expressionParams: ICondition | IDescribedCondition) {
    return `${getDescription(expressionParams.property)} is less than or equal to ${getDescription(
      expressionParams.value,
    )}`;
  },
};

export const neq: IOperatorDefinition = {
  ID: ConditionOperator.NEQ,
  text: "is not equal to",
  evaluate(entity: object, expressionParams: ICondition | IDescribedCondition) {
    return _.get(entity, getValue(expressionParams.property)) !== getValue(expressionParams.value);
  },
  getText(expressionParams: ICondition | IDescribedCondition) {
    return `${getDescription(expressionParams.property)} is not equal to ${getDescription(
      expressionParams.value,
    )}`;
  },
};

export const length: IOperatorDefinition = {
  ID: ConditionOperator.LEN,
  text: "length is",
  evaluate(entity: object, expressionParams: ICondition | IDescribedCondition) {
    return (
      _.get(entity, [getValue(expressionParams.property), "length"], 0) ===
      getValue(expressionParams.value)
    );
  },
  getText(expressionParams: ICondition | IDescribedCondition) {
    return `${getDescription(expressionParams.property)} has ${getDescription(
      expressionParams.value,
    )} items`;
  },
};

export const startsWith: IOperatorDefinition = {
  ID: ConditionOperator.SW,
  text: "starts with",
  evaluate(entity: object, expressionParams: ICondition | IDescribedCondition) {
    const value: any = _.get(entity, getValue(expressionParams.property));
    if (_.isString(value)) {
      return value.toString().startsWith(getValue(expressionParams.value));
    }
    return false;
  },
  getText(expressionParams: ICondition | IDescribedCondition) {
    return `${getDescription(expressionParams.property)} starts with ${getDescription(
      expressionParams.value,
    )}`;
  },
};

export const regex: IOperatorDefinition = {
  ID: ConditionOperator.RX,
  text: "matches regex",
  evaluate(entity: object, expressionParams: ICondition | IDescribedCondition) {
    const rx = new RegExp(getValue(expressionParams.value), "i");
    return Boolean(rx.exec(_.get(entity, getValue(expressionParams.property))));
  },
  getText(expressionParams: ICondition | IDescribedCondition) {
    return `${getDescription(expressionParams.property)} matches regex ${getDescription(
      expressionParams.value,
    )}`;
  },
};

export const daysAfter: IOperatorDefinition = {
  ID: ConditionOperator.DAF,
  text: "days after",
  evaluate(entity: object, expressionParams: ICondition | IDescribedCondition) {
    const now = moment().utc().startOf("day");
    const value = _.get(entity, getValue(expressionParams.property));
    if (!value) return false;
    const event = moment(value).utc().startOf("day").add(getValue(expressionParams.value), "days");

    return !event || now >= event;
  },
  getText(expressionParams: ICondition | IDescribedCondition) {
    return `${getDescription(expressionParams.value)} days after ${getDescription(
      expressionParams.property,
    )}`;
  },
};

export const daysWithin: IOperatorDefinition = {
  ID: ConditionOperator.DIN,
  text: "days within",
  evaluate(entity: object, expressionParams: ICondition | IDescribedCondition) {
    const now = moment().utc().startOf("day");
    const value = _.get(entity, getValue(expressionParams.property));
    if (!value) return false;
    const event = moment(value).utc().startOf("day").add(getValue(expressionParams.value), "days");

    return !!event && now < event;
  },
  getText(expressionParams: ICondition | IDescribedCondition) {
    return `${getDescription(expressionParams.value)} days within ${getDescription(
      expressionParams.property,
    )}`;
  },
};

export const exists: IOperatorDefinition = {
  ID: ConditionOperator.EXISTS,
  text: "property exists",
  evaluate(entity: object, expressionParams: ICondition | IDescribedCondition) {
    const value = _.get(entity, getValue(expressionParams.property));
    return !(value === undefined || value === null);
  },
  getText(expressionParams: ICondition | IDescribedCondition) {
    return `${getDescription(expressionParams.property)} exists`;
  },
};

export const notExists: IOperatorDefinition = {
  ID: ConditionOperator.NOTEXISTS,
  text: "property not exists",
  evaluate(entity: object, expressionParams: ICondition | IDescribedCondition) {
    const value = _.get(entity, getValue(expressionParams.property));
    return value === undefined || value === null;
  },
  getText(expressionParams: ICondition | IDescribedCondition) {
    return `${getDescription(expressionParams.property)} not exists`;
  },
};

export const includes: IOperatorDefinition = {
  ID: ConditionOperator.INCLUDES,
  text: "includes",
  evaluate(entity: object, expressionParams: ICondition | IDescribedCondition) {
    const values = _.get(entity, getValue(expressionParams.property));
    return !!_.includes(values, expressionParams.value);
  },
  getText(expressionParams: ICondition | IDescribedCondition) {
    return `${getDescription(expressionParams.property)} includes ${getValue(
      expressionParams.property,
    )}`;
  },
};

export const notIncludes: IOperatorDefinition = {
  ID: ConditionOperator.NOTINCLUDES,
  text: "not includes",
  evaluate(entity: object, expressionParams: ICondition | IDescribedCondition) {
    const values = _.get(entity, getValue(expressionParams.property));
    return !_.includes(values, expressionParams.value);
  },
  getText(expressionParams: ICondition | IDescribedCondition) {
    return `${getDescription(expressionParams.property)} not includes ${getValue(
      expressionParams.property,
    )}`;
  },
};

export const containsEntry: IOperatorDefinition = {
  ID: ConditionOperator.CONTAINSENTRY,
  text: "contains entry",
  evaluate(entity: object, expressionParams: ICondition | IDescribedCondition) {
    const values = _.get(entity, getValue(expressionParams.property));
    return _.some<string[]>(values, expressionParams.value);
  },
  getText(expressionParams: ICondition | IDescribedCondition) {
    return `${getDescription(expressionParams.property)} contains value ${getValue(
      expressionParams.property,
    )}`;
  },
};

export const notContainsEntry: IOperatorDefinition = {
  ID: ConditionOperator.NOTCONTAINSENTRY,
  text: "not contains entry",
  evaluate(entity: object, expressionParams: ICondition | IDescribedCondition) {
    const values = _.get(entity, getValue(expressionParams.property));
    return !_.some<string[]>(values, expressionParams.value);
  },
  getText(expressionParams: ICondition | IDescribedCondition) {
    return `${getDescription(expressionParams.property)} not contains value ${getValue(
      expressionParams.property,
    )}`;
  },
};

export const inObjectOfList: IOperatorDefinition = {
  ID: ConditionOperator.INOBJSOFLIST,
  text: "in objects of list",
  evaluate(entity: object, expressionParams: ICondition | IDescribedCondition) {
    const values = _.get(entity, getValue(expressionParams.property));
    try {
      assert(typeof expressionParams.value === "string", "Value must be a string");
      assert(Array.isArray(values), "Property must be an array");

      const found = (item) => {
        console.log(typeof item, item);
        return (expressionParams.value as string) in item;
      };
      return values.every(found);
    } catch {
      return true;
    }
  },
  getText(expressionParams: ICondition | IDescribedCondition) {
    return `${getDescription(expressionParams.property)} contains value ${getValue(
      expressionParams.property,
    )}`;
  },
};

export const notInObjectOfList: IOperatorDefinition = {
  ID: ConditionOperator.NINOBJSOFLIST,
  text: "in objects of list",
  evaluate(entity: object, expressionParams: ICondition | IDescribedCondition) {
    const values = _.get(entity, getValue(expressionParams.property), []);
    try {
      assert(typeof expressionParams.value === "string", "Value must be a string");
      assert(Array.isArray(values), "Property must be an array");

      const notFound = (item) => {
        const isFound = (expressionParams.value as string) in item;
        return !isFound;
      };
      return values.every(notFound);
    } catch {
      return true;
    }
  },
  getText(expressionParams: ICondition | IDescribedCondition) {
    return `${getDescription(expressionParams.property)} contains value ${getValue(
      expressionParams.property,
    )}`;
  },
};

export const allConditions = _.keyBy(
  [
    daysAfter,
    daysWithin,
    exists,
    notExists,
    eq,
    neq,
    gt,
    gte,
    length,
    lt,
    lte,
    of,
    nof,
    startsWith,
    regex,
    includes,
    notIncludes,
    containsEntry,
    notContainsEntry,
    inObjectOfList,
    notInObjectOfList,
  ],
  "ID",
);

export class Conditions {
  private static evaluate(entity: object, condition: ICondition | IDescribedCondition) {
    const matchingCondition = allConditions[condition.operator];
    if (matchingCondition) {
      return matchingCondition.evaluate(entity, condition);
    } else throw new Error(`"${condition.operator}" is an invalid condition.`);
  }

  public static match(entity: object, rule: IEvaluation | IDescribedEvaluation): boolean {
    switch (rule.if) {
      case LogicalOperator.All: {
        return (rule.conditions as any).every((condition: any) =>
          Conditions.evaluate(entity, condition),
        );
      }
      case LogicalOperator.Any: {
        return rule.conditions.some((condition: any) => Conditions.evaluate(entity, condition));
      }
      case LogicalOperator.None: {
        return !rule.conditions.some((condition: any) => Conditions.evaluate(entity, condition));
      }
      default: {
        throw new Error('"If" value must be one of "all", "any", or "none".');
      }
    }
  }
}
