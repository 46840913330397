import { useMemo } from "react";
import gql from "graphql-tag";
import { get } from "lodash";
import LogRocket from "logrocket";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import { useLazyQuery, useQuery } from "react-apollo";
import { Redirect } from "react-router-dom";
import styled from "styled-components";

import { featureFlagsSet, useLazyFlag } from "@core/feature-flags/clients";
import { Spin } from "@core/ui-legacy";

import AuthUserContext from "../common/contexts/AuthUserContext";
import ERPConnectorsContext from "../common/contexts/ERPConnectorsContext";
import UserOrganizationsContext from "../common/contexts/UserOrganizationsContext";
import ExpenseBulkActionProvider from "../common/ExpenseBulkActionContext";
import ExpenseLockProvider from "../common/ExpenseLockProvider";
import { PusherProvider } from "../common/PusherContext";
import { isUserInRoles, UserRoles } from "../common/roles";
import useFronteggTenantSwitchInterceptor from "../common/useFronteggTenantSwitchInterceptor";
import { userHasFeatures } from "../common/userHasFeatures";
import { ErrorPage } from "../components/ErrorBoundary";
import { initiateMetrics } from "../metrics";
import { ORG_TRAVEL_MANAGEMENT_COMPANY_PARTNER } from "../services/graphql/fragments";
import { GET_ERP_CONNECTORS, GET_EXPENSE_FIELDS, GET_USER_ORGS } from "../services/graphql/queries";
import API, { API_URL, retrieveToken } from "../services/rest/api";
import { initDatadogSession } from "../utils/datadog";
import FeatureFlags from "../utils/featureFlags";
import * as Routes from "../utils/routes";
import storage from "../utils/storage";
import { HRISOrgConfig } from "./HRISOrgConfig";
import { IdentifyFeatureFlagsProvider } from "./IdentifyFeatureFlagsProvider";

const { FM } = UserRoles;
const GET_ME = gql`
  {
    me {
      ID
      fullName
      firstName
      lastName
      roles
      loginID
      avatarBackgroundColor
      reimbursementPolicy
      fraudEnrolled
      billingAddress {
        address
        address2
        city
        state
        postalCode
        country
        isVerified
        isChanged
        timestamp
      }
      delegateOf {
        ID
        avatarURI
        avatarBackgroundColor
        firstName
        fullName @client
        lastName
      }
      cardItems {
        ID
        statusCode
        last4
        typeLabel
        reissued {
          reasonCode
        }
        cardInfo {
          ID
          activeState
        }
        cardFormat
      }
      costCenters {
        ID
      }
      defaultCostCenter {
        ID
      }
      toggles
      flags
      phone
      expenseHubConfiguredFields
      organization {
        ID
        name
        timeZone
        createdOn
        currencyCode
        partnerFields {
          spotnana {
            ...OrgTMCPartnerFragment
          }
          test {
            ...OrgTMCPartnerFragment
          }
        }
        sandbox
        chatbotConfig {
          orgID
          developerName
          siteURL
          scrtURL
        }
        shippingAddress {
          address1
          address2
          city
          state
          postalCode
          country
        }
        cardPrograms {
          ID
          name
          active
          issueCards
          expirationYYMM
        }
      }
      integrationInfo {
        spotnana {
          externalID
          persona
        }
      }
    }
    costCenters @connection(key: "costCenterList") {
      ID
      name
    }
    expenseTypes {
      ID
      name
      isDeleted
    }
  }
  ${ORG_TRAVEL_MANAGEMENT_COMPANY_PARTNER}
`;

const SpinStyled = styled(Spin)`
  display: flex !important;
  flex: 1 !important;
  align-items: center !important;
  justify-content: center !important;
  min-height: 100% !important;
`;

const BaseLayout = ({ children, authority, featureFlags, sandboxRoute }) => {
  const [
    getERPConnectors,
    { data: erpConnectorsData = {}, loading: loadingConnectors, refetch: refetchERPConnectors },
  ] = useLazyQuery(GET_ERP_CONNECTORS);
  const hasFlag = useLazyFlag();
  const [
    getUserOrganizations,
    {
      data: userOrganizations = {},
      loading: loadingUserOrganizations,
      refetch: refetchUserOrganizations,
    },
  ] = useLazyQuery(GET_USER_ORGS);

  const [getExpenseFields, { loading: loadingExpenseFields }] = useLazyQuery(GET_EXPENSE_FIELDS);

  const {
    data = {},
    loading,
    error,
  } = useQuery(GET_ME, {
    onCompleted: (res) => {
      const toggles = get(res, "me.toggles", []);
      const roles = get(res, "me.roles", []);
      moment.tz.setDefault(res.me.organization.timeZone);
      if (isUserInRoles(roles, [FM])) {
        getERPConnectors();
        getExpenseFields();
      }
      if (userHasFeatures(toggles, [FeatureFlags.MULTI_ENTITY])) {
        getUserOrganizations();
      }
      initiateMetrics(res.me);

      if (sessionStorage.getItem("tmc-redirect")) {
        const { partnerFields } = res.me.organization;
        let ssoLink;
        // we really should be looking this up somehow
        if (partnerFields?.spotnana) {
          const token = retrieveToken();
          const SAMLRequest = sessionStorage.getItem("SAMLRequest");
          const relayState = sessionStorage.getItem("relayState");
          if (!token) {
            API.logout();
            return Promise.reject(new Error("Authentication failed. Please log in and try again."));
          }
          ssoLink = `${API_URL}/oauth/v3.0/saml/sso?SAMLRequest=${SAMLRequest}&RelayState=${relayState}&token=${token?.access_token}`;
        }

        // clean up after ourselves not matter what
        sessionStorage.removeItem("tmc-redirect");
        sessionStorage.removeItem("SAMLRequest");
        sessionStorage.removeItem("relayState");

        // redirect if we know enough too
        if (ssoLink) {
          setTimeout(() => window.open(ssoLink, "_self"));
        }
      }

      LogRocket.identify(res.me.ID, {
        name: res.me.fullName,
        email: res.me.loginID,
      });

      initDatadogSession(res.me);
    },
  });

  // Handle org switch when user is authenticated via Frontegg.
  // We need to reload the page here versus places where the switch function is called because FronteggProvider does not
  // render anything when switching tenants, this results in state loss and possible visual bugs.
  // Using the interceptor here allows us to capture the moment where Frontegg updated its user and Center did not.
  const { tenantSwitchInProgress } = useFronteggTenantSwitchInterceptor(data?.me?.organization?.ID);

  const erpContext = useMemo(
    () => ({
      erpConnectors: erpConnectorsData?.erpConnectors,
      refetchERPConnectors,
    }),
    [erpConnectorsData?.erpConnectors, refetchERPConnectors],
  );

  const userOrganizationsContext = useMemo(
    () => ({
      userOrganizations: userOrganizations?.userOrgs ?? [],
      currentOrg: data?.me?.organization,
      refetchUserOrganizations,
    }),
    [data?.me?.organization, refetchUserOrganizations, userOrganizations],
  );

  if (error) {
    window.console.error(error);
    try {
      // In some scenarios, a user's auth state will be both
      // authenticated and not authenticated (schrodinger's auth)
      // This seems to happen when apollo cache shows the user is authenticated
      // but the auth value in storage has the following
      // {"Message": "User is not authorized to access this resource with an explicit deny","expires_on":"2024-01-18T13:48:41Z"}
      // To work around this issue until a proper fix is found, we check for this condition
      // and remove the auth value in storage
      const sessionAuth = JSON.parse(storage.getItem("auth"));
      if (sessionAuth && typeof sessionAuth === "object" && !sessionAuth.access_token) {
        storage.removeItem("auth");
        window.location.reload();
      }
      return null;
    } catch (e) {
      window.console.error("Failed to parse auth", e);
    }
    return <ErrorPage />;
  }

  if (
    loading ||
    loadingConnectors ||
    loadingExpenseFields ||
    loadingUserOrganizations ||
    tenantSwitchInProgress
  ) {
    return <SpinStyled spinning size="large" />;
  }

  const isUserAuthorized = isUserInRoles(data.me.roles, authority);

  const hasFeatures =
    featureFlags?.reduce((acc, flag) => {
      if (featureFlagsSet.has(flag)) {
        return acc && hasFlag(flag);
      }
      return acc && userHasFeatures(data.me.toggles, [flag]);
    }, true) ?? true;

  const hasAccess =
    isUserAuthorized &&
    hasFeatures &&
    ((sandboxRoute && data.me.organization.sandbox) || !sandboxRoute);

  if (hasAccess) {
    return (
      <AuthUserContext.Provider value={data.me}>
        <IdentifyFeatureFlagsProvider me={data.me} />
        <PusherProvider>
          <ExpenseLockProvider>
            <ExpenseBulkActionProvider>
              <HRISOrgConfig orgId={data.me?.organization?.ID} />
              <ERPConnectorsContext.Provider value={erpContext}>
                <UserOrganizationsContext.Provider value={userOrganizationsContext}>
                  {children(data)}
                </UserOrganizationsContext.Provider>
              </ERPConnectorsContext.Provider>
            </ExpenseBulkActionProvider>
          </ExpenseLockProvider>
        </PusherProvider>
      </AuthUserContext.Provider>
    );
  }
  return <Redirect exact to={Routes.HOME} />;
};

BaseLayout.propTypes = {
  children: PropTypes.func,
  authority: PropTypes.array,
  featureFlags: PropTypes.array,
  sandboxRoute: PropTypes.bool,
};

export default BaseLayout;
