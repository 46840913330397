/* eslint-disable no-bitwise */
/* 
Gets a random color for the user avatar based on their userID turned into a number.
*/

import colors from "../themes/colors";

const AVATAR_COLORS = [
  "#001C3D", //  colors.midnight
  colors.dark_gray,
  colors.placeholder_gray,
  colors.river_blue,
  colors.classic_blue,
  colors.providence_blue,
  colors.eggplant_purple,
  colors.passion_purple,
  "#1763E5", // colors.royal
  colors.vintage_maroon,
  colors.sunset_red,
  colors.salmon,
  colors.bayberry_green,
  colors.jade_green,
  colors.surf_green,
];

/*
 * @param {string} string to get color from (usually userID)
 * @return {Color} color for the user avatar
 */
function getColorFromString(string) {
  let hash = 0;
  let result = AVATAR_COLORS[hash];
  try {
    if (string === undefined || string.length === 0) return result;

    for (let i = 0; i < string.length; i++) {
      const ch = string.codePointAt(i);
      hash = (hash << 5) - hash + ch;
      hash &= hash;
    }
    hash = Math.abs(hash);
    result = AVATAR_COLORS[hash % (AVATAR_COLORS.length - 1)];

    return result;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error("Error generating Avatar color:", { err, string });
    return result;
  }
}

export { getColorFromString, AVATAR_COLORS };
