import { featureFlagsRecord } from "@core/feature-flags/clients";

import "../typedef";

import { isThemeConfigBlockListSubDomain } from "../utils";
import FeatureFlags from "../utils/featureFlags";
import { isUserInRoles, UserRoles } from "./roles";
import { userHasFeatures } from "./userHasFeatures";

const { ALL, FM, EA, SP, IV, TM, TA, Traveler, GuestTraveler } = UserRoles;

const ldMenuFlagsMap = {
  [featureFlagsRecord.releaseTravelInsights]: featureFlagsRecord.releaseTravelInsights,
};
export const menuFlags = Object.values(ldMenuFlagsMap);

const menuData = [
  {
    id: "expense-hub-link",
    name: "Expense Hub",
    icon: null,
    path: "expensehub",
    authority: [FM],
  },
  {
    id: "my-approvals-link",
    name: "My Approvals",
    icon: null,
    path: "myapprovals",
    customAccess: (authUser) =>
      !hasAccessToGuestTraveler(authUser) && isUserExpenseApprover(authUser),
  },
  {
    id: "my-approvals-guest-traveler-link",
    name: "My Approvals",
    icon: null,
    path: "myapprovals",
    customAccess: hasAccessToGuestTraveler,
    children: [
      {
        id: "my-approvals-cards-link",
        name: "Cards",
        icon: null,
        path: "cards",
        authority: [FM],
      },
      {
        id: "my-approvals-expenses-link",
        name: "Expenses",
        icon: null,
        path: "expenses",
        authority: [FM],
      },
    ],
  },
  {
    id: "insights-link",
    name: "Insights",
    icon: null,
    path: "insights",
    authority: [FM, IV],
    disableForFeatureFlags: [ldMenuFlagsMap["release-travel-insights"]],
  },
  {
    id: "insights-expenses-travel-link",
    name: "Insights",
    icon: null,
    path: "insight",
    authority: [FM, IV],
    enableForFeatureFlags: [featureFlagsRecord.releaseTravelInsights],
    children: [
      {
        id: "insights-expenses-link",
        name: "Expenses",
        icon: null,
        path: "expenses",
        authority: [FM, IV],
      },
      {
        id: "insights-travel-link",
        name: "Travel",
        icon: null,
        path: "travel/overview",
        authority: [FM, IV],
      },
    ],
  },
  {
    id: "mycard-link",
    name: "My Card",
    icon: null,
    path: "mycard",
    authority: [SP],
    disableForFeatureFlags: [FeatureFlags.VIRTUAL_MULTI_CARD],
    children: [
      {
        id: "mycard-overview-link",
        name: "Overview",
        icon: null,
        path: "overview",
        authority: [SP],
        disableForFeatureFlags: [FeatureFlags.VIRTUAL_MULTI_CARD],
      },
      {
        id: "spend-mycards-link",
        name: "Cards",
        icon: null,
        path: "cards",
        customAccess: isSpendCardsEnabled,
      },
      {
        id: "mycard-expenses-link",
        name: "Expenses",
        icon: null,
        path: "expenses",
        authority: [SP],
      },
      {
        id: "mycard-trips-link",
        name: "Trips",
        icon: null,
        path: "trips",
        authority: [FM, TM, TA, Traveler],
        enableForFeatureFlags: [FeatureFlags.TRIPS_HUB],
      },
    ],
  },
  {
    id: "spend-link",
    name: "Spend",
    icon: null,
    path: "spend",
    authority: [SP],
    enableForFeatureFlags: [FeatureFlags.VIRTUAL_MULTI_CARD],
    children: [
      {
        id: "spend-mycards-link",
        name: "Cards",
        icon: null,
        path: "cards",
        customAccess: isSpendCardsEnabled,
      },
      {
        id: "spend-expenses-link",
        name: "Expenses",
        icon: null,
        path: "expenses",
        authority: [SP],
      },
      {
        id: "spend-trips-link",
        name: "Trips",
        icon: null,
        path: "trips",
        authority: [FM, TM, TA, Traveler],
        enableForFeatureFlags: [FeatureFlags.TRIPS_HUB],
      },
    ],
  },
  {
    id: "travel-link",
    name: "Travel",
    icon: null,
    path: "travel",
    customAccess: isBookTravelEnabled,
    children: [
      {
        id: "book-travel-link",
        name: "Book Travel",
        icon: null,
        path: "book-travel",
        authority: [SP, FM, TA, Traveler],
      },
      {
        id: "guest-travelers-link",
        name: "Guest Travelers",
        icon: null,
        path: "guesttravelers",
        authority: [TM, TA],
        disableForFeatureFlags: [FeatureFlags.VIRTUAL_MULTI_CARD],
        enableForFeatureFlags: [FeatureFlags.TRAVEL_GUEST_TRAVELER],
      },
      {
        id: "guest-travelers-link",
        name: "Guests",
        icon: null,
        path: "guesttravelers",
        authority: [TM, TA],
        enableForFeatureFlags: [
          FeatureFlags.TRAVEL_GUEST_TRAVELER,
          FeatureFlags.VIRTUAL_MULTI_CARD,
        ],
      },
    ],
  },
  {
    id: "centeraccount-link",
    name: "Account",
    icon: null,
    path: "centeraccount",
    authority: [FM],
    children: [
      {
        id: "centeraccount-summary-link",
        name: "Summary",
        icon: null,
        path: "summary",
        authority: [FM],
      },
      {
        id: "centeraccount-transactions-link",
        name: "Transactions",
        icon: null,
        path: "transactions",
        authority: [FM],
      },
      {
        id: "centeraccount-statements-link",
        name: "Statements",
        icon: null,
        path: "statements",
        authority: [FM],
      },
    ],
  },
  {
    id: "administration-link",
    name: "Admin",
    icon: null,
    path: "admin",
    customAccess: isFmAndTraveler,
    children: [
      {
        id: "administration-users-link",
        name: "Users",
        icon: null,
        path: "users",
        authority: [FM],
      },
      {
        id: "administration-costcenters-link",
        name: "Cost Centers",
        icon: null,
        path: "costcenters",
        authority: [FM],
      },
      {
        id: "administration-expensetypes-link",
        name: "Expense Types",
        icon: null,
        path: "expensetypes",
        authority: [FM],
      },
      {
        id: "administration-generalledger-link",
        name: "GL Accounts",
        icon: null,
        path: "glaccounts",
        authority: [FM],
      },
      {
        id: "administration-expenseforms-link",
        name: "Expense Forms",
        icon: null,
        path: "expenseforms",
        authority: [FM],
      },
      {
        id: "administration-expensefields-link",
        name: "Expense Fields",
        icon: null,
        path: "expensefields",
        authority: [FM],
      },
      {
        id: "administration-integrations-link",
        name: "Integrations",
        icon: null,
        path: "integrations",
        authority: [FM],
      },
      {
        id: "hris",
        name: "HR Connect",
        icon: null,
        path: "hrconnect",
        authority: [FM],
        enableForFeatureFlags: [featureFlagsRecord.releaseHrConnect],
      },
      {
        id: "administration-rules-customapproval-link",
        name: "Approval Rules",
        icon: null,
        path: "rules/customapprovals",
        authority: [FM],
      },
      {
        id: "administration-sftp-link",
        name: "SFTP",
        icon: null,
        path: "sftp",
        authority: [FM],
      },
      {
        id: "administration-reimbursements-link",
        name: "Reimbursements",
        icon: null,
        path: "reimbursements",
        authority: [FM],
        enableForFeatureFlags: [FeatureFlags.REIMBURSEMENTS],
        children: [
          {
            id: "administration-reimbursements-policy-link",
            name: "Policy",
            icon: null,
            path: "policy",
            authority: [FM],
          },
          {
            id: "administration-reimbursements-transfers-link",
            name: "Transfers",
            icon: null,
            path: "transfers",
            authority: [FM],
          },
        ],
      },
      {
        id: "administration-notifications-link",
        name: "Notifications",
        icon: null,
        path: "notifications",
        authority: [FM],
      },
      {
        id: "administration-travel-link",
        name: "Travel",
        path: "travel",
        icon: null,
        authority: [FM, TM],
        isSideBar: true,
        children: [
          {
            id: "administration-travel-details-link",
            name: "Travel Details",
            icon: null,
            path: "traveldetails",
            authority: [FM, TM],
            enableForFeatureFlags: [FeatureFlags.TRAVEL_DETAILS],
          },
          {
            id: "administration-travel-policy-link",
            name: "Travel Policy",
            icon: null,
            path: "travelpolicy",
            authority: [TM],
            enableForFeatureFlags: [FeatureFlags.TRAVEL_POLICY],
          },
          {
            id: "administration-traveler-profiles-link",
            name: "Traveler Profiles",
            icon: null,
            path: "travelerprofiles",
            authority: [TM],
            enableForFeatureFlags: [FeatureFlags.TRAVELER_PROFILES],
          },
          {
            id: "administration-triptypes-link",
            name: "Trip Types",
            icon: null,
            path: "triptypes",
            authority: [TM],
            enableForFeatureFlags: [FeatureFlags.DEPRECATED],
          },
        ],
      },
      {
        id: "administration-export-configuration-link",
        name: "Export Configuration",
        icon: null,
        path: "exportconfiguration",
        authority: [FM],
        enableForFeatureFlags: [FeatureFlags.EXPORT_CONFIGURATION],
      },
      {
        id: "administration-login-sso-link",
        name: "Login/SSO",
        icon: null,
        path: "loginsso",
        authority: [FM],
      },
      {
        id: "cards-link",
        name: "Cards",
        icon: null,
        path: "cards",
        authority: [FM],
      },
      ...(isThemeConfigBlockListSubDomain()
        ? []
        : [
            {
              id: "administration-theme",
              name: "Theme",
              icon: null,
              path: "theme",
              authority: [FM],
              enableForFeatureFlags: [FeatureFlags.THEME_CONFIG],
            },
          ]),
    ],
  },
];

/* eslint no-useless-escape:0 */
const reg =
  /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;
const isUrl = (path) => reg.test(path);

function formatter(data, parentPath = "/", parentAuthority) {
  return data.map((item) => {
    let { path } = item;
    if (!isUrl(path)) {
      path = parentPath + item.path;
    }
    const result = {
      ...item,
      path,
      authority: item.authority || parentAuthority,
    };
    if (item.children) {
      result.children = formatter(item.children, `${parentPath}${item.path}/`, item.authority).sort(
        (a, b) => {
          if (typeof a.name === "string" && typeof b.name === "string") {
            return a.name.localeCompare(b.name);
          }

          return 0;
        },
      );
    }
    return result;
  });
}

/**
 * Orders the menu data based on order argument
 * @param {OrderedMenuLink[]} [order] - ordered list of nav-link paths
 * @param {object} [authUser] - current user needed to check access
 * @returns {*[]} - ordered menuData
 */
const orderMenuData = (order, authUser) => {
  if (!order || !authUser) return menuData;
  const filteredMenu = menuData
    .filter((menuItem) => !menuItem.customAccess || menuItem.customAccess(authUser))
    .map((menuItem) => (menuItem.customize ? menuItem.customize(authUser, menuItem) : menuItem));

  return order
    .map(({ name, path, target, type }) => {
      if (type === "INTERNAL") {
        return filteredMenu.find((item) => item.path === path);
      }

      return {
        id: path,
        name,
        icon: null,
        path,
        authority: [ALL],
        target: target === "BLANK" ? "_blank" : "_self",
      };
    })
    .filter(Boolean);
};

/**
 * Builds the orderable nav menu list
 * @param {OrderedMenuLink[]} [order] - ordered list of nav-link paths
 * @param {object} [authUser] - current user needed to check access
 * @returns {*[]} - formatted nav menu items
 */
export const getMenuDataWithChildren = (order, authUser) =>
  formatter(orderMenuData(order, authUser));

const isBookLinkEnabledByTraveler = (authUser) => {
  const roles = [TM, TA, Traveler];
  const flags = [FeatureFlags.TRAVELER];
  return isUserInRoles(authUser.roles, roles) && userHasFeatures(authUser.toggles, flags);
};

const isGuestTravelerWithSpotnanaAccess = (authUser) =>
  isUserInRoles(authUser.roles, [GuestTraveler]) &&
  authUser?.integrationInfo?.spotnana?.persona === "EMPLOYEE";

function isBookTravelEnabled(authUser) {
  const hasSSOLink = !!authUser.organization?.partnerFields?.spotnana?.ssoLink;
  if (!hasSSOLink) {
    return hasSSOLink;
  }
  const hasAccess =
    isBookLinkEnabledByTraveler(authUser) || isGuestTravelerWithSpotnanaAccess(authUser);
  return hasSSOLink && hasAccess;
}

function isFmAndTraveler(authUser) {
  return (
    isUserInRoles(authUser.roles, [FM]) ||
    (isUserInRoles(authUser.roles, [TM]) &&
      userHasFeatures(authUser.toggles, [FeatureFlags.TRAVELER]))
  );
}

function hasAccessToGuestTraveler(authUser) {
  return (
    isUserInRoles(authUser.roles, [FM]) &&
    userHasFeatures(authUser.toggles, [FeatureFlags.TRAVEL_GUEST_TRAVELER])
  );
}

function isUserExpenseApprover(authUser) {
  return isUserInRoles(authUser.roles, [FM, EA]);
}

export function isSpendCardsEnabled(authUser, delegateUser) {
  if (!userHasFeatures(authUser.toggles, [FeatureFlags.VIRTUAL_MULTI_CARD])) {
    return false;
  }

  if (!isUserInRoles(authUser.roles, [SP])) {
    return false;
  }

  const delegateOf = authUser.delegateOf ?? [];
  if (delegateOf.length === 0 || !delegateUser) {
    return true;
  }

  return delegateOf.every((user) => delegateUser.ID !== user.ID);
}
