import PropTypes from "prop-types";
import styled from "styled-components";

import { Button, Icon, Progress } from "@core/ui-legacy";
import Colors from "@core/ui-legacy/themes/colors";
import { sansProps } from "@core/ui-legacy/utils/styledSansProps";

const FlexContainer = styled.div`
  background-color: ${Colors.white};
  display: flex;
  padding: 1rem;
`;

const ProgressWrapper = styled.div`
  width: 100%;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
`;

const ProgressText = styled.p`
  font-size: 0.75rem;
  color: ${Colors.monumental};
  margin-bottom: 0;
`;

const JustifyContentBetween = styled.div`
  width: 100%;
  justify-content: space-between;
  display: flex;
`;

const LabelStyled = sansProps(styled, { isError: true })("label")`
  line-height: 1;
  color: ${({ isError }) => (isError ? `${Colors.danger_red}` : `${Colors.midnight}`)};
`;

const SpinIconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SpinIcon = styled(Icon)`
  font-size: 1.25rem !important;
  color: ${Colors.monumental} !important;
  display: inline;
  margin-right: 1.25rem !important;
`;

const ButtonStyled = styled(Button)`
  height: auto !important;
  padding-right: 0 !important;
  line-height: 1 !important;

  &:not(.ant-btn-background-ghost, [disabled]) {
    color: ${Colors.link_blue} !important;
  }
`;

const SyncProgress = ({
  message,
  isError,
  progressText,
  onViewDetails,
  percent,
  className,
  ...rest
}) => (
  <FlexContainer className={className} {...rest}>
    <SpinIconContainer>
      <SpinIcon type="sync" spin={percent < 100} />
    </SpinIconContainer>
    <ProgressWrapper>
      <JustifyContentBetween>
        <LabelStyled isError={isError} htmlFor="sync-progress">
          {message}
        </LabelStyled>{" "}
        {onViewDetails && (
          <ButtonStyled type="link" id="sync-view-details-btn" onClick={onViewDetails}>
            View details
          </ButtonStyled>
        )}
      </JustifyContentBetween>
      <Progress
        id="sync-progress"
        percent={percent}
        showInfo={false}
        strokeColor={`${Colors.link_blue}`}
      />
      <ProgressText>{progressText}</ProgressText>
    </ProgressWrapper>
  </FlexContainer>
);

SyncProgress.propTypes = {
  message: PropTypes.string,
  isError: PropTypes.bool,
  progressText: PropTypes.string,
  onViewDetails: PropTypes.func,
  percent: PropTypes.number,
  className: PropTypes.any,
};

SyncProgress.defaultProps = {
  percent: 0,
};

export default SyncProgress;
