import { type Dispatch } from "react";

import { Text } from "@core/ui";

import type { AddressInput } from "../../types";

type SuggestionProps = {
  originalAddress: AddressInput;
  selection: "suggested" | "original";
  setSelection: Dispatch<React.SetStateAction<"suggested" | "original">>;
  suggestedAddress?: AddressInput;
};

export const Suggestion = ({
  originalAddress,
  suggestedAddress,
  selection,
  setSelection,
}: SuggestionProps) => {
  return (
    <>
      <div
        role="checkbox"
        aria-checked={selection === "original"}
        tabIndex={-1}
        onClick={() => setSelection("original")}
        onKeyDown={() => setSelection("original")}
        className={`mt-3 grid cursor-pointer grid-cols-[auto,6fr,1fr] gap-3 rounded border-[1.5px] border-solid ${
          selection === "suggested" ? "border-[#F0F0F0]" : "border-primary-400"
        } px-3 py-4`}
      >
        <input
          type="radio"
          checked={selection === "original"}
          readOnly
          className="size-4 self-center accent-primary-500"
        />
        <div>
          <Text>Original address</Text>
          <Text variant="medium" className="text-[#606060]">
            {`${originalAddress.line1}${
              originalAddress.line2 ? ` ${originalAddress.line2}` : ""
            }, ${originalAddress.city} ${originalAddress.state}, ${originalAddress.zipCode}, ${
              originalAddress.country
            }`}
          </Text>
        </div>
      </div>
      <div
        role="checkbox"
        aria-checked={selection === "suggested"}
        tabIndex={-1}
        onClick={() => setSelection("suggested")}
        onKeyDown={() => setSelection("suggested")}
        className={`mt-3 grid cursor-pointer grid-cols-[auto,6fr,1fr] gap-3 rounded border-[1.5px] border-solid ${
          selection === "original" ? "border-[#F0F0F0]" : "border-primary-400"
        } px-3 py-4`}
      >
        <input
          type="radio"
          checked={selection === "suggested"}
          readOnly
          className="size-4 self-center  accent-primary-500"
        />
        <div>
          <Text>Suggested address</Text>
          <Text variant="medium" className="text-[#606060]">
            {`${suggestedAddress?.line1}${
              suggestedAddress?.line2 ? ` ${suggestedAddress?.line2}` : ""
            }, ${suggestedAddress?.city} ${suggestedAddress?.state}, ${suggestedAddress?.zipCode}, ${suggestedAddress?.country}`}
          </Text>
        </div>
      </div>
    </>
  );
};
