import { useQuery } from "@tanstack/react-query";

import { getOrgConfig, orgConfigQueryKey, useHasHRIS } from "../services/rest/hris";

export const HRISOrgConfig = (props: Parameters<typeof orgConfigQueryKey>[0]) => {
  // Fetch Org Config Query for HRIS as soon as the user logs in
  const hasHRIS = useHasHRIS();
  useQuery({
    queryKey: orgConfigQueryKey(props),
    enabled: hasHRIS,
    queryFn: getOrgConfig,
  });
  return null;
};
