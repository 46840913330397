import styled from "styled-components";

import Colors from "@core/ui-legacy/themes/colors";

import Link from "../Link";

const DrillDownLink = styled(Link)`
  color: ${Colors.midnight};

  button {
    padding: 0;
  }

  :hover {
    text-decoration: underline;
    opacity: 1 !important;
  }

  & > *:hover {
    text-decoration: underline;
    opacity: 1 !important;
  }
`;

export default DrillDownLink;
