import type { IconProps } from "antd/lib/icon";
import type { Location } from "history";
import styled from "styled-components";

import { featureFlagsRecord, type FeatureFlag } from "@core/feature-flags/clients";
import Colors from "@core/ui-legacy/themes/colors";

import { ReactComponent as AirplaneIcon } from "../assets/imgs/airplane_outlined.svg";
import { ReactComponent as YesReceiptIcon } from "../assets/imgs/yes-receipt.svg";
import SpendSideNavTitle from "../components/SpendSideNavTitle";
import MDFeatureFlags, { type FeatureFlag as MDFeatureFlag } from "../utils/featureFlags";
import { isSpendCardsEnabled } from "./menu";
import { UserRoles, type UserRole } from "./roles";
import { userHasFeatures } from "./userHasFeatures";

const { FM, SP, TM, TA, Traveler } = UserRoles;

export const MY_APPROVALS_EXPENSES_ID = "my-approvals-expenses";
export const MY_APPROVALS_CARDS_ID = "my-approvals-cards";

const AirplaneIconStyled = styled(AirplaneIcon)`
  color: ${Colors.bg_gray};
`;

export type SideNavItem = {
  id: string;
  name: string | JSX.Element;
  path: string;
  authority?: UserRole[];
  children?: SideNavItem[];
  collapsable?: boolean | ((toggles: MDFeatureFlag[]) => boolean);
  customAccess?: (authUser, delegateOf) => boolean;
  disableForFeatureFlags?: MDFeatureFlag[];
  enableForFeatureFlags?: (MDFeatureFlag | FeatureFlag)[];
  icon?: null | string | IconProps["component"];
};

const sideNavData: SideNavItem[] = [
  {
    id: "mycard-link",
    name: "My Card",
    icon: null,
    path: "mycard",
    authority: [SP],
    disableForFeatureFlags: [MDFeatureFlags.VIRTUAL_MULTI_CARD],
    children: [
      {
        id: "mycard-overview-link",
        name: "Overview",
        icon: null,
        path: "overview",
        authority: [SP],
      },
      {
        id: "mycard-expenses-link",
        name: "Expenses",
        icon: null,
        path: "expenses",
        authority: [SP],
      },
      {
        id: "mycard-trips-link",
        name: "Trips",
        icon: null,
        path: "trips",
        authority: [FM, TM, TA, Traveler],
        enableForFeatureFlags: [MDFeatureFlags.TRIPS_HUB],
      },
    ],
  },
  {
    id: "spend-link",
    name: <SpendSideNavTitle />,
    icon: null,
    path: "spend",
    enableForFeatureFlags: [MDFeatureFlags.VIRTUAL_MULTI_CARD],
    authority: [SP],
    collapsable: (toggles) => userHasFeatures(toggles, [MDFeatureFlags.VIRTUAL_MULTI_CARD]),
    children: [
      {
        id: "spend-mycards-link",
        name: "Cards",
        icon: "credit-card",
        path: "cards",
        customAccess: isSpendCardsEnabled,
      },
      {
        id: "spend-expenses-link",
        name: "Expenses",
        icon: YesReceiptIcon,
        path: "expenses",
        authority: [SP],
      },
      {
        id: "spend-trips-link",
        name: "Trips",
        icon: AirplaneIconStyled,
        path: "trips",
        authority: [FM, TM, TA, Traveler],
        enableForFeatureFlags: [MDFeatureFlags.TRIPS_HUB],
      },
    ],
  },
  {
    id: "travel-link",
    name: "Travel",
    icon: null,
    path: "travel",
    authority: [SP],
    children: [
      {
        id: "book-travel-link",
        name: "Book Travel",
        icon: null,
        path: "booktravel",
        authority: [SP, FM, TM, TA, Traveler],
      },
      {
        id: "guest-travelers-link",
        name: "Guest Travelers",
        icon: null,
        path: "guesttravelers",
        authority: [TM, TA],
        disableForFeatureFlags: [MDFeatureFlags.VIRTUAL_MULTI_CARD],
        enableForFeatureFlags: [MDFeatureFlags.TRAVEL_GUEST_TRAVELER],
      },
      {
        id: "guest-travelers-link",
        name: "Guests",
        icon: null,
        path: "guesttravelers",
        authority: [TM, TA],
        enableForFeatureFlags: [
          MDFeatureFlags.TRAVEL_GUEST_TRAVELER,
          MDFeatureFlags.VIRTUAL_MULTI_CARD,
        ],
      },
    ],
  },
  {
    id: "centeraccount-link",
    name: "Account",
    icon: null,
    path: "centeraccount",
    authority: [FM],
    children: [
      {
        id: "centeraccount-summary-link",
        name: "Summary",
        icon: null,
        path: "summary",
        authority: [FM],
      },
      {
        id: "centeraccount-transactions-link",
        name: "Transactions",
        icon: null,
        path: "transactions",
        authority: [FM],
      },
      {
        id: "centeraccount-statements-link",
        name: "Statements",
        icon: null,
        path: "statements",
        authority: [FM],
      },
    ],
  },
  {
    id: "administration-link",
    name: "Admin",
    icon: null,
    path: "admin",
    authority: [FM],
    children: [
      {
        id: "administration-users-link",
        name: "Users",
        icon: null,
        path: "users",
        authority: [FM],
      },
      {
        id: "administration-costcenters-link",
        name: "Cost Centers",
        icon: null,
        path: "costcenters",
        authority: [FM],
      },
      {
        id: "administration-expensetypes-link",
        name: "Expense Types",
        icon: null,
        path: "expensetypes",
        authority: [FM],
      },
      {
        id: "administration-generalledger-link",
        name: "GL Accounts",
        icon: null,
        path: "glaccounts",
        authority: [FM],
      },
      {
        id: "administration-expenseforms-link",
        name: "Expense Forms",
        icon: null,
        path: "expenseforms",
        authority: [FM],
      },
      {
        id: "administration-expensefields-link",
        name: "Expense Fields",
        icon: null,
        path: "expensefields",
        authority: [FM],
      },
      {
        id: "administration-integrations-link",
        name: "Integrations",
        icon: null,
        path: "integrations",
        authority: [FM],
      },
      {
        id: "hris-link",
        name: "HR Connect",
        icon: null,
        path: "hrconnect",
        authority: [FM],
        enableForFeatureFlags: [featureFlagsRecord.releaseHrConnect],
      },
      {
        id: "administration-rules-customapproval-link",
        name: "Approval Rules",
        icon: null,
        path: "rules/customapprovals",
        authority: [FM],
      },
      {
        id: "administration-sftp-link",
        name: "SFTP",
        icon: null,
        path: "sftp",
        authority: [FM],
      },

      {
        id: "administration-reimbursements-link",
        name: "Reimbursements",
        icon: null,
        path: "reimbursements",
        authority: [FM],
        enableForFeatureFlags: [MDFeatureFlags.REIMBURSEMENTS],
        children: [
          {
            id: "administration-reimbursements-policy-link",
            name: "Policy",
            icon: null,
            path: "policy",
            authority: [FM],
            enableForFeatureFlags: [MDFeatureFlags.REIMBURSEMENTS],
          },
          {
            id: "administration-reimbursements-transfers-link",
            name: "Transfers",
            icon: null,
            path: "transfers",
            authority: [FM],
            enableForFeatureFlags: [MDFeatureFlags.REIMBURSEMENTS],
          },
        ],
      },
      {
        id: "administration-notifications-link",
        name: "Notifications",
        icon: null,
        path: "notifications",
        authority: [FM],
      },
      {
        id: "administration-travel-link",
        name: "Travel",
        path: "travel",
        icon: null,
        authority: [FM, TM],
        children: [
          {
            id: "administration-travel-details-link",
            name: "Travel Details",
            icon: null,
            path: "traveldetails",
            authority: [FM, TM],
            enableForFeatureFlags: [MDFeatureFlags.TRAVEL_DETAILS],
          },
          {
            id: "administration-travel-policy-link",
            name: "Travel Policy",
            icon: null,
            path: "travelpolicy",
            authority: [TM],
            enableForFeatureFlags: [MDFeatureFlags.TRAVEL_POLICY],
          },
          {
            id: "administration-traveler-profiles-link",
            name: "Traveler Profiles",
            icon: null,
            path: "travelerprofiles",
            authority: [TM],
            enableForFeatureFlags: [MDFeatureFlags.TRAVELER_PROFILES],
          },
          {
            id: "administration-triptypes-link",
            name: "Trip Types",
            icon: null,
            path: "triptypes",
            authority: [TM],
            enableForFeatureFlags: [MDFeatureFlags.DEPRECATED],
          },
        ],
      },
      {
        id: "administration-export-configuration-link",
        name: "Export Configuration",
        icon: null,
        path: "exportconfiguration",
        authority: [FM],
        enableForFeatureFlags: [MDFeatureFlags.EXPORT_CONFIGURATION],
      },
      {
        id: "administration-login-sso-link",
        name: "Login/SSO",
        icon: null,
        path: "loginsso",
        authority: [FM],
      },
      {
        id: "administration-theme",
        name: "Theme",
        icon: null,
        path: "theme",
        authority: [FM],
        enableForFeatureFlags: [MDFeatureFlags.THEME_CONFIG],
      },
      {
        id: "cards-link",
        name: "Cards",
        icon: null,
        path: "cards",
        authority: [FM],
      },
    ],
  },
  {
    id: "settings-link",
    name: "Settings",
    icon: null,
    path: "settings",
    authority: [SP, Traveler],
    children: [
      {
        id: "settings-reimbursements-link",
        name: "Reimbursements",
        path: "reimbursements",
        authority: [SP],
        enableForFeatureFlags: [MDFeatureFlags.REIMBURSEMENTS],
      },
      {
        id: "settings-accountinfo-link",
        name: "Account info",
        path: "accountinfo",
        authority: [SP],
      },
      {
        id: "settings-travelinfo-link",
        name: "Traveler info",
        path: "travelinfo",
        authority: [TM, TA, Traveler],
        enableForFeatureFlags: [MDFeatureFlags.USER_TRAVEL],
      },
    ],
  },
  {
    id: "my-approvals",
    name: "",
    path: "myapprovals",
    collapsable: true,
    authority: [FM],
    children: [
      {
        id: MY_APPROVALS_CARDS_ID,
        name: "Cards",
        path: "cards",
        authority: [FM],
        icon: "credit-card",
      },
      {
        id: MY_APPROVALS_EXPENSES_ID,
        name: "Expenses",
        path: "expenses",
        authority: [FM],
        icon: YesReceiptIcon,
      },
    ],
  },
];

/* eslint no-useless-escape:0 */
const reg =
  /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;
const isUrl = (path: string) => reg.test(path);

function formatter(
  data: SideNavItem[],
  parentPath = "/",
  parentAuthority?: UserRole[],
): SideNavItem[] {
  return data.map((item) => {
    let { path } = item;
    if (!isUrl(path)) {
      path = parentPath + item.path;
    }
    const result = {
      ...item,
      path,
      authority: item.authority || parentAuthority,
    };
    if (item.children) {
      result.children = formatter(item.children, `${parentPath}${item.path}/`, item.authority);
    }
    return result;
  });
}
export const getSideNavDataForLocation = <T,>(location: Location<T>): SideNavItem[] => {
  const currentItem = getCurrentSideNavItem(location);
  if (currentItem) {
    return (currentItem.children || [currentItem]).sort((a, b) => {
      if (typeof a.name === "string" && typeof b.name === "string") {
        return a.name.localeCompare(b.name);
      }

      return 0;
    });
  }
  return [];
};

export function getCurrentSideNavItem<T>(location: Location<T>): SideNavItem | undefined {
  const menu = formatter(sideNavData);
  const pathSections = location.pathname.split("/");
  const firstPathSection = pathSections.length > 1 ? pathSections[1] : "";
  return menu.find((item) => item.path === `/${firstPathSection}`);
}
