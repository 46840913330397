import { forwardRef, type ComponentPropsWithoutRef, type ElementRef } from "react";
import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";
import { Circle } from "lucide-react";

import { cn } from "../../lib/utils";
import { Text } from "../Text";

export type RadioGroupItemProps = ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item> & {
  label?: string;
};

const RadioGroup = forwardRef<
  ElementRef<typeof RadioGroupPrimitive.Root>,
  ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({ className, ...props }, ref) => {
  return <RadioGroupPrimitive.Root className={cn("grid gap-2", className)} {...props} ref={ref} />;
});
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName;

const RadioGroupItem = forwardRef<ElementRef<typeof RadioGroupPrimitive.Item>, RadioGroupItemProps>(
  ({ className, label, ...props }, ref) => {
    return (
      <div className="flex items-center space-x-2">
        <RadioGroupPrimitive.Item
          ref={ref}
          className={cn(
            "relative flex items-center justify-center aspect-square size-4 rounded-full border border-primary-500 bg-neutral-50 peer",
            "focus:outline-none focus-visible:ring-1 focus-visible:ring-offset-2 focus-visible:ring-primary-400",
            "focus:ring-1 focus:ring-primary-400 focus:ring-offset-2",
            "data-[state=checked]:border-primary-500 data-[state=checked]:text-primary-500",
            "hover:border-primary-600 hover:bg-neutrals-200 hover:data-[state=checked]:text-primary-600",
            "disabled:cursor-not-allowed disabled:opacity-50 disabled:border-text-300 disabled:bg-neutrals-200",
            "disabled:data-[state=checked]:border-primary-400 disabled:data-[state=checked]:text-primary-400 disabled:data-[state=checked]:opacity-50",
            className,
          )}
          {...props}
        >
          <RadioGroupPrimitive.Indicator className="flex items-center justify-center">
            <Circle className="h-2.5 fill-current" />
          </RadioGroupPrimitive.Indicator>
        </RadioGroupPrimitive.Item>
        {label && (
          <Text
            as="label"
            className="leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-50"
            color="text-500"
          >
            {label}
          </Text>
        )}
      </div>
    );
  },
);
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName;

export { RadioGroup, RadioGroupItem };
