import { ContextHolder } from "@frontegg/react";
import decode from "jwt-decode";
import { fromPairs } from "lodash";
import _forEach from "lodash/forEach";
import _merge from "lodash/merge";
import LogRocket from "logrocket";
import moment from "moment";
import { configureRefreshFetch } from "refresh-fetch";
import { z, ZodError } from "zod";

import apiUrl from "../../slotConfig";
import { browserDetection, sleep } from "../../utils";
import { MIME_TYPES } from "../../utils/constants";
import { parseIfJSON } from "../../utils/rules";
import Storage from "../../utils/storage";
import { SET_AUTH } from "../graphql/mutations";
import { GET_JOB, GET_JOB_ITEMS_IMPORT } from "../graphql/queries";

const CORS_PROXY = "https://cors.centercard.com";
export const API_URL = apiUrl || import.meta.env.REACT_APP_BUILD_API;
const CONSOLE_LOG_ENABLED =
  // env bools are strings
  import.meta.env.REACT_APP_CONSOLE_LOG_ENABLED === "true";
const LOG_API_ENABLED =
  // env bools are strings
  import.meta.env.REACT_APP_LOG_API_ENABLED === "true";
const isLocalhost = import.meta.env.DEV;

export const MAX_LOGIN_ATTEMPTS_MESSAGE =
  "You have reached the maximum number of login attempts. Please contact support.";
const MAX_LOGIN_ATTEMPTS_ERROR = `Invalid request. ${MAX_LOGIN_ATTEMPTS_MESSAGE}`;

const LogLevel = {
  error: "error",
  warning: "warn",
  info: "info",
};

// always console.error on localhost
const shouldConsoleLog = CONSOLE_LOG_ENABLED || isLocalhost;
// disable log API on localhost
const shouldLogAPI = LOG_API_ENABLED && !isLocalhost;

let graphqlClient = null;
let graphqlResolvers = null;

const setGraphqlClient = (client, resolvers) => {
  graphqlClient = client;
  graphqlResolvers = resolvers;
};

export const queryConstructor = (params) => {
  let queryString = "";
  const queryParam = [];
  const buildQueryString = (val, key) => {
    if (val !== null && val !== undefined) queryParam.push({ [key]: val });
  };
  _forEach(params, buildQueryString);
  if (queryParam.length > 0) {
    const qParams = queryParam.map((param, idx) => {
      const property = Object.keys(queryParam[idx])[0];
      const q = `${property.toLowerCase()}=${encodeURIComponent(queryParam[idx][property])}`;
      if (idx > 0) return `&${q}`;
      return q;
    });
    queryString = `?${qParams.join("")}`;
  }
  return queryString;
};

export const getUriWithCorsProxy = (uri) => `${CORS_PROXY}/${uri}`;

export const retrieveToken = () => {
  const fronteggUser = ContextHolder.getUser();
  if (fronteggUser?.accessToken) {
    return {
      access_token: fronteggUser.accessToken,
    };
  }

  const auth = Storage.getItem("auth");
  return auth ? JSON.parse(auth) : null;
};

export const cacheToken = (token) => {
  token.expires_on = moment.utc().add(token.expires_in, "seconds").format();
  Storage.setItem("auth", JSON.stringify(token));
};

let recordingURL = "";
LogRocket.getSessionURL((sessionURL) => {
  recordingURL = sessionURL;
});

export const fetchWithToken = async (url, options = {}, token) => {
  if (!token) {
    const auth = retrieveToken();
    if (!auth) {
      logout();
      return Promise.reject(new Error("Authentication failed. Please log in and try again."));
    }
    token = auth?.access_token;
  }

  let optionsWithToken = options;
  if (token != null) {
    optionsWithToken = _merge({}, options, {
      headers: {
        Authorization: `Bearer ${token}`,
        "X-LogRocket-URL": recordingURL,
      },
    });
  }
  return fetch(url, optionsWithToken).then(async (response) => {
    if (response.status !== 403) {
      return response;
    }
    const refreshTokenRequest = await refreshToken();
    // if we can't refresh token - there is something really wrong with the session, for example, when we're blocked
    if (refreshTokenRequest.status === 400) {
      logout();
    }
    return response;
  });
};

const getUserById = async (id) => {
  const response = await fetchWithToken(getUriWithCorsProxy(`${API_URL}/users/v3.0/${id}`), {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": MIME_TYPES.json,
    },
  });

  return response.json();
};

const getUserInfoByEmail = async (email) => {
  const response = await fetch(
    getUriWithCorsProxy(`${API_URL}/oauth/v3.0/saml/sso/userinfo/${email}`),
    {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": MIME_TYPES.json,
      },
    },
  );

  return response.json();
};

const login = async (username, password, doesClientSupportsSSO = false, orgid) => {
  const queryparams = queryConstructor({
    doesClientSupportsSSO,
    orgid,
  });
  const auth = window.btoa(`${username}:${password}`);
  const response = await fetch(getUriWithCorsProxy(`${API_URL}/oauth/v3.0/token${queryparams}`), {
    method: "POST",
    mode: "cors",
    headers: {
      Authorization: `Basic ${auth}`,
      "Content-Type": MIME_TYPES.json,
    },
  });

  if (!response.ok) {
    if (response.status === 403) {
      throw new Error("Invalid email or password. Please check and try logging in again.");
    }
    throw new Error("Oops! There was a problem logging you in. Please try again.");
  }

  const parsedResponse = await response.json();

  if (parsedResponse.shouldRedirectToSSO) {
    window.open(parsedResponse.loginUrl, "_self");
    return;
  }

  cacheToken(parsedResponse);
  // eslint-disable-next-line consistent-return
  return parsedResponse;
};

const verifyMagicLink = async (username, session, code) => {
  const auth = window.btoa(`${username}`);
  const response = await fetch(getUriWithCorsProxy(`${API_URL}/oauth/v3.0/magic-link-verify`), {
    method: "POST",
    mode: "cors",
    headers: {
      Authorization: `Basic ${auth}`,
      "Content-Type": MIME_TYPES.json,
    },
    body: JSON.stringify({
      email: username,
      session,
      code,
    }),
  });

  const parsedResponse = await response.json();

  if (!response.ok) {
    if (response.status === 403) {
      throw new Error("Invalid code. Please check and try logging in again.");
    }
    if (response.status === 400 && parsedResponse.errorMessage === MAX_LOGIN_ATTEMPTS_ERROR) {
      throw new Error(MAX_LOGIN_ATTEMPTS_MESSAGE);
    }
    throw new Error("Oops! There was a problem logging you in. Please try again.");
  }

  if (parsedResponse.shouldRedirectToSSO) {
    window.open(parsedResponse.loginUrl, "_self");
    return;
  }

  cacheToken(parsedResponse);
  // eslint-disable-next-line consistent-return
  return parsedResponse;
};

export const getUserStatus = async ({ email }) => {
  const response = await fetchWithToken(
    getUriWithCorsProxy(`${API_URL}/users/v3.0/status/${email}`),
    {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": MIME_TYPES.json,
      },
    },
  );

  const json = await response.json();

  if (!response.ok) {
    throw new Error(json.errorMessage || json.message);
  }

  const userStatusSchema = z.object({
    status: z.object({
      enabled: z.boolean(),
    }),
  });
  try {
    const result = userStatusSchema.parse(json);
    return result;
  } catch (error) {
    if (error instanceof ZodError) {
      window.console.error("Failed to validate response", error.flatten().fieldErrors);
      throw new Error("Failed to validate response from get user status");
    }
    throw error;
  }
};

export const getLockoutStatus = async ({ email }) => {
  const response = await fetchWithToken(
    getUriWithCorsProxy(`${API_URL}/oauth/v3.0/${email}/lockout-status`),
    {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": MIME_TYPES.json,
      },
    },
  );

  const json = await response.json();

  if (!response.ok) {
    throw new Error(json.errorMessage || json.message);
  }

  const lockoutStatusSchema = z.object({
    locked: z.boolean(),
  });
  try {
    const result = lockoutStatusSchema.parse(json);
    return result;
  } catch (error) {
    if (error instanceof ZodError) {
      window.console.error("Failed to validate response", error.flatten().fieldErrors);
      throw new Error("Failed to validate response from get lockout status");
    }
    throw error;
  }
};

export const enableUser = async ({ loginID }) => {
  const response = await fetchWithToken(getUriWithCorsProxy(`${API_URL}/users/v3.0/enable`), {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": MIME_TYPES.json,
    },
    body: JSON.stringify({
      loginID,
    }),
  });

  if (!response.ok) {
    const json = await response.json();
    throw new Error(json.errorMessage || json.message);
  }
};

export const disableUser = async ({ loginID }) => {
  const response = await fetchWithToken(getUriWithCorsProxy(`${API_URL}/users/v3.0/disable`), {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": MIME_TYPES.json,
    },
    body: JSON.stringify({
      loginID,
    }),
  });

  if (!response.ok) {
    const json = await response.json();
    throw new Error(json.errorMessage || json.message);
  }
};

export const resetUserLockout = async ({ emails }) => {
  const response = await fetchWithToken(
    getUriWithCorsProxy(`${API_URL}/oauth/v3.0/reset-attempts`),
    {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": MIME_TYPES.json,
      },
      body: JSON.stringify({
        emails,
      }),
    },
  );

  const json = await response.json();

  if (!response.ok) {
    throw new Error(json.errorMessage || json.message);
  }

  const resetSchema = z.object({
    failedEmails: z.array(
      z.object({
        email: z.string(),
        error: z.unknown(),
      }),
    ),
    successEmails: z.array(z.string()),
  });
  try {
    const result = resetSchema.parse(json);
    return result;
  } catch (error) {
    if (error instanceof ZodError) {
      window.console.error("Failed to validate response", error.flatten().fieldErrors);
      throw new Error("Failed to validate response for reset attempts");
    }
    throw error;
  }
};

const logInMagicLink = async (username, orgid) => {
  const queryParams = queryConstructor({
    orgid,
  });
  const auth = window.btoa(`${username}`);

  const response = await fetch(
    getUriWithCorsProxy(`${API_URL}/oauth/v3.0/magic-link-login${queryParams}`),
    {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: `Basic ${auth}`,
        "Content-Type": MIME_TYPES.json,
      },
      body: JSON.stringify({
        email: username,
      }),
    },
  );

  const parsedResponse = await response.json();

  if (!response.ok) {
    if (response.status === 400 && parsedResponse.errorMessage === MAX_LOGIN_ATTEMPTS_ERROR) {
      throw new Error(MAX_LOGIN_ATTEMPTS_MESSAGE);
    }
    throw new Error("Oops! There was a problem logging you in. Please try again.");
  }

  return parsedResponse;
};

const getUserFeatureFlags = async (loginid, orgid) => {
  const queryParams = queryConstructor({
    orgid,
  });
  const response = await fetch(
    getUriWithCorsProxy(`${API_URL}/oauth/v3.0/feature-flags/${loginid}${queryParams}`),
    {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": MIME_TYPES.json,
      },
    },
  );

  if (!response.ok) {
    if (response.status === 403) {
      return { err: "Username or email does not exist", status: 403 };
    }
    return {};
  }

  return response.json();
};

const getSpPublicCert = async (orgID) => {
  const response = await fetchWithToken(
    getUriWithCorsProxy(`${API_URL}/oauth/v3.0/saml/sso/spmetadata/${orgID}/cert`),
    {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": MIME_TYPES.json,
      },
    },
  );
  const json = await response.json();
  if (!response.ok) {
    throw new Error(json.errorMessage || json.message);
  }

  return json;
};

const getLoginSettings = async () => {
  const response = await fetchWithToken(
    getUriWithCorsProxy(`${API_URL}/oauth/v3.0/saml/sso/settings`),
    {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": MIME_TYPES.json,
      },
    },
  );
  const json = await response.json();
  if (!response.ok) {
    throw new Error(json.errorMessage || json.message);
  }

  return json;
};

const updateLoginSettings = async (loginSettings) => {
  const response = await fetchWithToken(
    getUriWithCorsProxy(`${API_URL}/oauth/v3.0/saml/sso/settings`),
    {
      method: "PUT",
      mode: "cors",
      headers: {
        "Content-Type": MIME_TYPES.json,
      },
      body: JSON.stringify(loginSettings),
    },
  );
  if (!response.ok) {
    const json = await response.json();
    throw new Error(json.errorMessage || json.message);
  }
};

const logout = async () => {
  Storage.clear();

  if (ContextHolder.getUser()?.accessToken) {
    const { baseUrl } = ContextHolder.getContext();
    window.location.href = `${baseUrl}/oauth/logout?post_logout_redirect_uri=${window.location.origin}`;

    return;
  }

  graphqlResolvers.data = graphqlResolvers.getData();

  await graphqlClient.mutate({
    mutation: SET_AUTH,
    variables: { isAuthenticated: false },
  });
  graphqlClient.cache.reset();
  graphqlClient.cache.writeData({ data: graphqlResolvers.data });
};

const refreshToken = () => {
  const token = retrieveToken();
  const userID = decode(token?.access_token)["custom:userid"];
  return fetch(getUriWithCorsProxy(`${API_URL}/oauth/v3.0/token`), {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": MIME_TYPES.json,
    },
    body: JSON.stringify({
      grant_type: "refresh_token",
      refresh_token: token ? token.refresh_token : null,
      refresh_token_user_id: userID, // Always pass userID to support parallel sessions
    }),
  });
};

// Passwords

const changePassword = async (userID, password, token) => {
  if (!(userID, password, token)) {
    console.error("Change password requires ( userID, password, token):", {
      userID,
      password,
      token,
    });
  }
  const response = await fetchWithToken(
    getUriWithCorsProxy(`${API_URL}/users/v3.0/${userID}/password`),
    {
      method: "PUT",
      mode: "cors",
      headers: {
        "Content-Type": MIME_TYPES.json,
      },
      body: JSON.stringify({
        password,
      }),
    },
    token,
  );
  if (!response.ok) {
    const json = await response.json();
    throw new Error(json.errorMessage || json.message);
  }
  return Promise.resolve();
};

const forgotPassword = async (email) => {
  if (!email) {
    console.error("Forgot password requires ( email ):", email);
  }
  const response = await fetch(getUriWithCorsProxy(`${API_URL}/users/v3.0/passwordresetemail`), {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": MIME_TYPES.json,
    },
    body: JSON.stringify({
      loginID: email,
    }),
  });

  if (!response.ok) {
    if (response.status === 403) {
      throw new Error(
        "Unable to send reset link. Please verify and try again, or contact support.",
      );
    }
    throw new Error(
      "Oops! There was a problem sending reset password link. Please verify the email is valid and try again.",
    );
  }

  return Promise.resolve();
};

const resetPassword = async (password, code) => {
  if (!(password && code)) {
    console.error("Reset password requires ( password, code ):", {
      password,
      code,
    });
  }
  const response = await fetch(getUriWithCorsProxy(`${API_URL}/users/v3.0/resetpassword`), {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Type": MIME_TYPES.json,
    },
    body: JSON.stringify({
      password,
      code,
    }),
  });

  if (!response.ok) {
    if (response.status === 403 || response.status === 400) {
      const json = await response.json();
      throw new Error(json.errorMessage || json.message);
    }
    throw new Error("Oops! There was a problem resetting password. Please try again.");
  }

  return Promise.resolve();
};

const fetchWithRefresh = configureRefreshFetch({
  fetch: fetchWithToken,
  shouldRefreshToken: (error) => error.status === 401,
  refreshToken: async () => {
    const response = await refreshToken();
    const token = await response.json();
    cacheToken(token);
    return Promise.resolve();
  },
});

// Statements

const downloadStatement = async (resourceId, year, mime) => {
  const throwError = () => {
    throw new Error("An error occurred during the file download.");
  };

  const response1 = await fetchWithRefresh(
    getUriWithCorsProxy(`${API_URL}/reports/v3.0/statements/${year}/${resourceId}/signeduri`),
    {
      mode: "cors",
    },
  );
  if (!response1.ok) {
    throwError();
  }

  const result = await response1.json();
  const response2 = await fetch(`${CORS_PROXY}/${result.URI}`, {
    mode: "cors",
    responseType: "arraybuffer",
    headers: {
      Accept: mime,
    },
  });

  if (!response2.ok) {
    throwError();
  }
  const blob = await response2.arrayBuffer();
  return blob;
};

const uploadReceipt = async (file, expenseId, userId, delegateOf) => {
  const throwError = () => {
    throw new Error("An error occurred during the receipt upload. Please try again.");
  };
  const { type, name, mapscreenshot, size } = file;
  if (size < 1) {
    throwError();
  }
  const queryparams = queryConstructor({
    contentType: type,
    expenseId,
    fileId: type === MIME_TYPES.pdf ? name : null,
    userId,
    delegateOf,
    mapscreenshot: !!mapscreenshot,
  });
  const response1 = await fetchWithRefresh(
    getUriWithCorsProxy(`${API_URL}/receipts/v3.0/uploaduri${queryparams}`),
    {
      mode: "cors",
    },
  );
  if (!response1.ok) {
    throwError();
  }

  const uriUpload = await response1.json();
  const response2 = await fetch(`${CORS_PROXY}/${uriUpload.URI}`, {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Type": type,
    },
    body: file,
  });
  if (!response2.ok) {
    throwError();
  }

  return Promise.resolve(uriUpload);
};

const deleteReceipt = async (expenseId, userId, fileId, delegateOf) => {
  const throwError = () => {
    throw new Error("An error occurred during the receipt deletion. Please try again.");
  };
  const queryparams = queryConstructor({
    expenseId,
    fileId,
    userId,
    delegateOf,
  });
  const response1 = await fetchWithRefresh(
    getUriWithCorsProxy(`${API_URL}/receipts/v3.0/deletionuri${queryparams}`),
    {
      mode: "cors",
    },
  );
  if (!response1.ok) {
    throwError();
  }

  const uriDeletion = await response1.json();
  const response2 = await fetch(`${CORS_PROXY}/${uriDeletion.URI}`, {
    method: "DELETE",
    mode: "cors",
  });
  if (!response2.ok) {
    throwError();
  }

  return Promise.resolve(uriDeletion);
};

/**
 *  Create a Merchant
 *  @param {object} arg
 *  @param {string} arg.merchantName - name of new merchant to add
 *  @returns {Promise<string>} - merchant id
 */
export const createMerchant = async ({ merchantName }) => {
  const response = await fetchWithToken(getUriWithCorsProxy(`${API_URL}/merchants/v3.0`), {
    method: "POST",
    mode: "cors",
    body: JSON.stringify({
      merchantName,
    }),
  });
  if (!response.ok) {
    throw new Error(response.message);
  }
  return response.json();
};

/**
 *  Get a Merchant by ID
 *  @param {string} ID
 *  @returns {Promise<{ID: string; name: string}>} - merchant
 */
export const getMerchant = async (ID) => {
  const response = await fetchWithToken(getUriWithCorsProxy(`${API_URL}/merchants/v3.0/${ID}`), {
    method: "GET",
    mode: "cors",
  });
  if (!response.ok) {
    throw new Error(response.message);
  }
  return response.json();
};

/**
 *  Provision card  for sandbox user
 *  @param {object} arg
 *  @param {string} arg.cardToken - card token for card to provision
 *  @returns {Promise<object>} - response from synthetic card status post
 */
export const provisionCard = async ({ cardToken }) => {
  const response = await fetchWithToken(
    getUriWithCorsProxy(`${API_URL}/utils/v3.0/synthetic/card/${cardToken}/provision`),
    {
      method: "POST",
      mode: "cors",
    },
  );
  if (!response.ok) {
    throw new Error(response.message);
  }
  return response.json();
};

/**
 *  Activate card  for sandbox user
 *  @param {object} arg
 *  @param {string} arg.cardToken - card token for card to provision
 *  @returns {Promise<object>} - response from synthetic card status post
 */
export const activateCard = async ({ cardToken: _cardToken }) => {
  let cardToken = _cardToken;
  if (!cardToken.startsWith("TEST-")) {
    const res = await provisionCard({ cardToken });
    await sleep(2000);
    cardToken = res.cardToken;
  }
  const statusResponse = await fetchWithToken(
    getUriWithCorsProxy(`${API_URL}/utils/v3.0/synthetic/card/${cardToken}/status`),
    {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        status: "Open",
      }),
    },
  );
  if (!statusResponse.ok) {
    throw new Error(statusResponse.message);
  }

  return statusResponse.json();
};

/**
 * Clear all expense data for a sandbox org
 * @returns {Promise<void>}
 */
export const clearExpenseData = async () => {
  const result = await fetchWithToken(getUriWithCorsProxy(`${API_URL}/sandboxes/v3.0/spenddata`), {
    method: "POST",
    mode: "cors",
  });

  if (!result.ok) {
    throw new Error(result.message);
  }
};
/**
 *  Post a synthetic Transaction for sandbox
 *  @param {object} arg
 *  @param {string} arg.orgID - test org ID to post transaction to
 *  @param {string} arg.cardToken - test card token (starts with “TEST-”)
 *  @param {("PRE_AUTHORIZATION" | "AUTHORIZATION" | "POST" | "FORCE POST" | "CREDIT" | "DECLINE")} arg.transactionType - type of transaction
 *  @param {string} arg.merchantName - name of the merchant
 *  @param {string} arg.merchantID - ID of the merchant
 *  @param {number} [arg.preAuthAmount] - amount to pre authorize
 *  @param {number} [arg.postedAmount] - amount to posted
 *  @returns {Promise<object>}
 */
export const postSyntheticTransaction = async ({
  merchantID,
  orgID,
  cardToken,
  transactionType,
  preAuthAmount,
  postedAmount,
  merchantName,
}) => {
  const response = await fetchWithToken(
    getUriWithCorsProxy(`${API_URL}/utils/v3.0/synthetic/webhook`),
    {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        acceptorId: merchantID,
        acceptLocation: merchantName,
        orgID,
        cardToken,
        transactionType,
        preAuthAmount: typeof preAuthAmount === "number" ? preAuthAmount.toString() : undefined,
        postedAmount: typeof postedAmount === "number" ? postedAmount.toString() : undefined,
      }),
    },
  );
  if (!response.ok) {
    throw new Error(response.message);
  }
  return response;
};

const logError = async (level, logObject) => {
  const throwError = () => {
    throw new Error("An error occurred during logging.");
  };
  if (logObject && !logObject.details) logObject.details = {};
  logObject.details.userAgent = navigator.userAgent;
  logObject.details.browser = browserDetection();
  logObject.details.browserUrl = window.location.href;
  if (shouldConsoleLog) {
    console.error(level, logObject);
  }
  if (shouldLogAPI) {
    const response = await fetchWithRefresh(
      getUriWithCorsProxy(`${API_URL}/utils/v3.0/log/${level}`),
      {
        method: "POST",
        mode: "cors",
        body: JSON.stringify(logObject),
      },
    );
    if (!response.ok) {
      throwError();
    }
    return response;
  }
  return Promise.resolve();
};

const downloadFileFromURI = async (
  uri,
  mime,
  shouldPrependCorsProxy = true,
  signal = undefined,
) => {
  const throwError = () => {
    throw new Error("An error occurred during the file download.");
  };
  const url = shouldPrependCorsProxy ? getUriWithCorsProxy(uri) : uri;

  const response = await fetch(url, {
    mode: shouldPrependCorsProxy ? "cors" : "no-cors",
    responseType: "arraybuffer",
    headers: {
      Accept: mime,
    },
    signal,
  });
  if (!response.ok) {
    throwError();
  }

  const blob = await response.arrayBuffer();
  return Promise.resolve(blob);
};

const sendReminderEmails = async (reminderType, customMessage, searchBarFilter) => {
  const throwError = () => {
    throw new Error("An error occurred sending reminder emails.");
  };
  if (!reminderType || !customMessage) {
    console.error("Missing fields on sendReminderEmails()", {
      reminderType,
      customMessage,
    });
    return Promise.reject();
  }
  const queryParams = new URLSearchParams({
    searchbarfilter: searchBarFilter,
  }).toString();
  const response = await fetchWithRefresh(
    getUriWithCorsProxy(
      `${API_URL}/emails/v3.0/reminders/expenses/${reminderType.toLowerCase()}?${queryParams}`,
    ),
    {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({ customMessage }),
    },
  );
  if (!response.ok) {
    throwError();
    return Promise.reject();
  }
  return Promise.resolve(response);
};

const sendWelcomeEmail = async (userId) => {
  const throwError = () => {
    throw new Error("An error occurred sending welcome email.");
  };
  if (!userId) {
    console.error("Missing fields on sendWelcomeEmail()", {
      userId,
    });
    return Promise.reject();
  }
  const response = await fetchWithRefresh(
    getUriWithCorsProxy(`${API_URL}/users/v3.0/${userId}/welcomeemail`),
    {
      method: "POST",
      mode: "cors",
    },
  );
  if (!response.ok) {
    throwError();
    return Promise.reject();
  }
  return Promise.resolve(response);
};

const sendReplacementCardEmail = async (userId) => {
  if (!userId) {
    console.error("Missing fields on sendReplacementCardEmail()", { userId });
    throw new Error("Missing userId");
  }

  const response = await fetchWithRefresh(
    getUriWithCorsProxy(`${API_URL}/users/v3.0/${userId}/cardreplacement`),
    {
      method: "POST",
      mode: "cors",
    },
  );

  if (!response.ok) {
    throw new Error("An error occurred sending replacement email for virtual card.");
  }

  return response;
};

const sendIssueVCEmail = async (userId) => {
  if (!userId) {
    console.error("Missing fields on sendIssueVCEmail()", { userId });
    throw new Error("Missing userId");
  }

  const response = await fetchWithRefresh(
    getUriWithCorsProxy(`${API_URL}/users/v3.0/${userId}/issuevcemail`),
    {
      method: "POST",
      mode: "cors",
    },
  );

  if (!response.ok) {
    throw new Error("An error occurred sending email about issuing new virtual card.");
  }

  return response;
};

const uploadMappingRulesFile = async ({ file, setProgress, ruleID, setValidationError }) => {
  setValidationError(null);
  const response1 = await fetchWithRefresh(
    getUriWithCorsProxy(`${API_URL}/policies/v3.0/rules/items/uploaduri?contenttype=text/csv`),
    {
      mode: "cors",
    },
  );
  if (!response1.ok) {
    const error = await response1.json();
    throw new Error(error.errorMessage || "Error while uploading a file.");
  }
  await setProgress(25);
  const uriUpload = await response1.json();
  const response2 = await fetch(`${CORS_PROXY}/${uriUpload.URI}`, {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Type": "text/csv",
    },
    body: file,
  });
  if (!response2.ok) {
    const error = await response2.json();
    throw new Error(error.errorMessage || "Error while uploading a file.");
  }
  await setProgress(50);
  const response3 = await fetchWithRefresh(
    getUriWithCorsProxy(`${API_URL}/jobs/v3.0/policies/import`),
    {
      mode: "cors",
      method: "POST",
      body: JSON.stringify({
        fileName: uriUpload.fileID,
        ruleID,
        type: "verify",
      }),
    },
  );
  if (!response3.ok) {
    const error = await response3.json();
    throw new Error(error.errorMessage || "Error while uploading a file.");
  }
  const jobID = await response3.json();
  await setProgress(75);
  const job = await checkJobStatus({
    jobID: jobID.ID,
    setValidationError,
    isValidationStep: true,
  });
  if (job.message) {
    throw new Error(job.message || "Error while uploading a file.");
  }
  await setProgress(99);
  return uriUpload;
};

const importJobSteps = {
  importUsers: "importUsers",
  importDefaultApprover: "importDefaultApprover",
  processDelegates: "processDelegates",
  orderCards: "orderCards",
  processEmail: "processEmail",
  createTmcUsers: "createTmcUsers",
  updateUsersData: "updateUsersData",
};

const checkJobStatus = async ({
  jobID,
  onError = () => null,
  onSuccess = () => null,
  setValidationError = () => null,
  onCallback = () => null,
  isReturnCount,
  isValidationStep,
}) => {
  if (jobID) {
    try {
      const {
        data: { job },
      } = await graphqlClient.query({
        query: isReturnCount ? GET_JOB_ITEMS_IMPORT : GET_JOB,
        variables: {
          id: jobID,
        },
        fetchPolicy: "network-only",
      });
      if (job.status === "active" || job.status === "pending") {
        await sleep(2000);
        return checkJobStatus({
          jobID,
          onError,
          onSuccess,
          setValidationError,
          onCallback,
          isReturnCount,
          isValidationStep,
        });
      }
      if (job.status === "failed") {
        const error = JSON.parse(job.error);
        let message = "";
        if (error.message) {
          message = error.message;
        } else if (error.body) {
          try {
            const body = typeof error.body === "string" ? JSON.parse(error.body) : error.body;
            message = body.errorMessage || body;

            if (typeof message === "object") {
              setValidationError(message);
              message = isValidationStep ? "" : message.message;
            }
          } catch {
            // in case we have error body as object, we have error file link and we should allow user to import
            message = typeof error.body === "string" ? error.body : "";
            if (typeof error.body === "object") {
              setValidationError({ ...error.body, usersCount: job.usersCount });
            }
          }
        }
        if (message) throw new Error(message || "Error while uploading a file.");
      }
      if (job.status === "complete") {
        onSuccess(job.usersCount);
      }
      await onCallback();
      return job;
    } catch (e) {
      onError(e);
      return e;
    }
  }
  const error = { message: "Missing required parameter 'ID'." };
  onError(error);
  return error;
};

const checkJobStatusCostCenter = async ({
  jobID,
  onError = () => null,
  onSuccess = () => null,
  setValidationError = () => null,
}) => {
  if (jobID) {
    try {
      const {
        data: { job },
      } = await graphqlClient.query({
        query: GET_JOB_ITEMS_IMPORT,
        variables: {
          id: jobID,
        },
        fetchPolicy: "network-only",
      });
      if (job.status === "active" || job.status === "pending") {
        await sleep(2000);
        return checkJobStatusCostCenter({
          jobID,
          onError,
          onSuccess,
          setValidationError,
        });
      }
      if (job.status === "failed") {
        const error = JSON.parse(job.error);
        try {
          const parsedErrorBody =
            typeof error.body === "string" ? JSON.parse(error.body) : error.body;
          if (typeof parsedErrorBody === "object") {
            if (parsedErrorBody?.message?.includes("Invalid request. ")) {
              const replase = new RegExp('"?Invalid request. (.+\\w)"?');
              parsedErrorBody.message = parsedErrorBody.message.replace(replase, "$1");
            } else if (parsedErrorBody.errorMessage) {
              parsedErrorBody.message = parsedErrorBody.errorMessage;
              delete parsedErrorBody.errorMessage;
            }
            setValidationError(parsedErrorBody);
          } else if (typeof parsedErrorBody === "string") {
            if (parsedErrorBody.includes('"')) {
              onError({ message: parsedErrorBody.replace(/^"(.*)"$/g) });
            } else onError({ message: parsedErrorBody });
          }
        } catch {
          if (typeof error.body === "object") {
            setValidationError({ ...error.body });
          } else onError({ message: error.body });
        }
      }
      if (job.status === "complete") {
        onSuccess(job.costCentersCount);
      }
      return job;
    } catch (e) {
      onError(e);
      return e;
    }
  }
  const error = { message: "Missing required parameter 'ID'." };
  onError(error);
  return error;
};

const checkImportUsersJobStatus = async ({
  jobID,
  onError = () => null,
  onSuccess = () => null,
  setValidationError = () => null,
  checkCardOrderingProcess = () => null,
  isReturnCount,
  importCompleted = false,
}) => {
  if (jobID) {
    try {
      const {
        data: { job },
      } = await graphqlClient.query({
        query: GET_JOB_ITEMS_IMPORT,
        variables: {
          id: jobID,
        },
        fetchPolicy: "network-only",
      });

      const progressStatuses = new Set(["active", "pending"]);
      const completeStatuses = ["complete", "completeWithWarning"];
      if (job.operations) {
        const operationsMap = fromPairs(job.operations.map((step) => [step.name, step]));
        const importUsersStep = operationsMap[importJobSteps.importUsers];
        const importDelegatesStep = operationsMap[importJobSteps.processDelegates];
        const importDefaultApproverStep = operationsMap[importJobSteps.importDefaultApprover];
        const orderCardsStep = operationsMap[importJobSteps.orderCards];
        const updateUsersDataStep = operationsMap[importJobSteps.updateUsersData];

        if (!["noOperation", "pending"].includes(orderCardsStep.status)) {
          checkCardOrderingProcess({
            status: orderCardsStep.status,
            progress: JSON.parse(orderCardsStep.progress),
            jobStatus: job.status,
            response: orderCardsStep.response,
          });
        }
        if (
          !importCompleted &&
          !progressStatuses.has(importUsersStep.status) &&
          !progressStatuses.has(importDelegatesStep.status) &&
          !progressStatuses.has(importDefaultApproverStep.status) &&
          !progressStatuses.has(updateUsersDataStep.status)
        ) {
          const IUStepResult =
            typeof importUsersStep.response === "string"
              ? JSON.parse(importUsersStep.response)
              : importUsersStep.response;
          const IDStepResult =
            typeof importDelegatesStep.response === "string"
              ? JSON.parse(importDelegatesStep.response)
              : importDelegatesStep.response;
          const DAStepResult =
            typeof importDefaultApproverStep.response === "string"
              ? JSON.parse(importDefaultApproverStep.response)
              : importDefaultApproverStep.response;
          const usersCount = IUStepResult?.usersCount || 0;
          const downloadURI =
            DAStepResult.downloadURI || IDStepResult.downloadURI || IUStepResult.downloadURI;
          const message = DAStepResult.message || IDStepResult.message || IUStepResult.message;
          const { criticalErrRawCount } = IUStepResult;
          if (
            (importUsersStep.status === "completeWithWarning" ||
              importDelegatesStep.status === "completeWithWarning" ||
              importDefaultApproverStep.status === "completeWithWarning") &&
            downloadURI
          ) {
            setValidationError({
              downloadURI,
              message,
              criticalErrRawCount,
              usersCount,
            });
          }
          if (
            (completeStatuses.includes(importUsersStep.status) &&
              completeStatuses.includes(importDelegatesStep.status) &&
              completeStatuses.includes(importDefaultApproverStep.status) &&
              [...completeStatuses, "noOperation"].includes(updateUsersDataStep.status)) ||
            completeStatuses.includes(job.status)
          ) {
            onSuccess(usersCount);
            return checkImportUsersJobStatus({
              jobID,
              onError,
              onSuccess,
              setValidationError,
              isReturnCount,
              checkCardOrderingProcess,
              importCompleted: true,
            });
          }
        }
      }
      if (progressStatuses.has(job.status)) {
        await sleep(5000);
        return checkImportUsersJobStatus({
          jobID,
          onError,
          onSuccess,
          setValidationError,
          importCompleted,
          isReturnCount,
          checkCardOrderingProcess,
        });
      }
      if (job.status === "failed") {
        const error = JSON.parse(job.error);
        let message = "";
        if (error.message) {
          message = error.message;
        } else if (error.body) {
          try {
            message = JSON.parse(error.body).errorMessage;
            if (typeof message === "object") {
              setValidationError(message);
              message = message.message;
            }
          } catch {
            // in case we have error body as object, we have error file link and we should allow user to import
            message = typeof error.body === "string" ? error.body : "";
            if (typeof error.body === "object") {
              setValidationError({ ...error.body, usersCount: job.usersCount });
            }
          }
        }
        throw new Error(message || "Error while uploading a file.");
      }
      return job;
    } catch (e) {
      onError(e);
      return e;
    }
  }
  const error = { message: "Missing required parameter 'ID'." };
  onError(error);
  return error;
};

const checkImportListItemsJobStatus = async ({
  jobID,
  isExpenseTypesImport,
  onError = () => null,
  onSuccess = () => null,
  setValidationError = () => null,
  onCallback = () => null,
}) => {
  if (jobID) {
    try {
      const {
        data: { job },
      } = await graphqlClient.query({
        query: GET_JOB_ITEMS_IMPORT,
        variables: {
          id: jobID,
        },
        fetchPolicy: "network-only",
      });
      if (job.status === "active" || job.status === "pending") {
        await sleep(2000);
        return checkImportListItemsJobStatus({
          jobID,
          isExpenseTypesImport,
          onError,
          onSuccess,
          setValidationError,
          onCallback,
        });
      }
      if (job.status === "failed") {
        const error = JSON.parse(job.error);
        const errorBody = parseIfJSON(error.body);
        if (errorBody?.downloadURI) {
          setValidationError(errorBody);
        } else {
          if (typeof errorBody === "string") {
            throw new Error(errorBody);
          }

          if (errorBody?.errorMessage) {
            throw new Error(errorBody.errorMessage);
          }

          if (isExpenseTypesImport) {
            throw new Error("Error while importing expense types.");
          }

          throw new Error("Error while importing list items.");
        }
      }
      if (job.status === "complete") {
        onSuccess();
      }
      await onCallback();
      return job;
    } catch (e) {
      onError(e);
      return e;
    }
  }
  const error = { message: "Missing required parameter 'ID'." };
  onError(error);
  return error;
};

const uploadFieldListItemsFile = async ({
  file,
  setProgress,
  fieldID,
  expenseTypesImport,
  setValidationError,
}) => {
  if (setValidationError) setValidationError(null);
  const response1 = await fetchWithRefresh(
    getUriWithCorsProxy(
      `${API_URL}/forms/v3.0/fields/${fieldID}/values/uploaduri?contenttype=text/csv`,
    ),
    {
      mode: "cors",
    },
  );
  if (!response1.ok) {
    const error = await response1.json();
    throw new Error(error.errorMessage || "Error while uploading a file.");
  }
  await setProgress(25);
  const uriUpload = await response1.json();
  const response2 = await fetch(`${CORS_PROXY}/${uriUpload.URI}`, {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Type": "text/csv",
    },
    body: file,
  });
  if (!response2.ok) {
    const error = await response2.json();
    throw new Error(error.errorMessage || "Error while uploading a file.");
  }
  await setProgress(50);
  const response3 = await fetchWithRefresh(
    getUriWithCorsProxy(`${API_URL}/jobs/v3.0/forms/values/import`),
    {
      mode: "cors",
      method: "POST",
      body: JSON.stringify({
        fileName: uriUpload.fileID,
        fieldID,
        expenseTypesImport,
        type: "verify",
      }),
    },
  );
  if (!response3.ok) {
    const error = await response3.json();
    throw new Error(error.errorMessage || "Error while uploading a file.");
  }
  const jobID = await response3.json();
  await setProgress(75);
  const job = await checkImportListItemsJobStatus({
    jobID: jobID.ID,
    isExpenseTypesImport: expenseTypesImport,
  });
  if (job.message) {
    throw new Error(job.message || "Error while uploading a file.");
  }
  await setProgress(99);
  return Promise.resolve({
    uriUpload,
    importListItemsWithVisibilityRules: job.importListItemsWithVisibilityRules,
  });
};

const exportListItems = async (
  fieldID,
  expenseTypesExport = false,
  includeVisibilityRules = false,
) => {
  const queryParams = queryConstructor({
    expenseTypesExport,
    includeVisibilityRules,
  });
  const response = await fetchWithRefresh(
    getUriWithCorsProxy(`${API_URL}/forms/v3.0/fields/${fieldID}/values/export${queryParams}`),
    {
      mode: "cors",
      responseType: "text/csv",
    },
  );
  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.errorMessage || "Error while downloading file.");
  }
  let listItems = "";
  try {
    listItems = await response.json();
  } catch (err) {
    logError(LogLevel.error, {
      message: "Failed to read export list items response.",
      details: {
        error: err,
        fieldID,
      },
    });
  }
  return Promise.resolve(listItems);
};

const exportMappingRules = async (ruleID) => {
  const queryParams = queryConstructor({
    ruleID,
  });
  const response = await fetchWithRefresh(
    getUriWithCorsProxy(`${API_URL}/jobs/v3.0/policies/ruleitems/export${queryParams}`),
    {
      mode: "cors",
      responseType: "text/csv",
    },
  );
  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.errorMessage || "Error while downloading file.");
  }
  const jobID = await response.json();
  const job = await checkJobStatus({
    jobID: jobID.ID,
  });
  if (job.message) {
    throw new Error(job.message || "Error while exporting rule items.");
  }
  return { downloadURI: job.downloadURI };
};

const exportCostCenters = async () => {
  const response = await fetchWithRefresh(
    getUriWithCorsProxy(`${API_URL}/costcenters/v3.0/export?archived=false`),
    {
      mode: "cors",
      responseType: "text/csv",
    },
  );
  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.errorMessage || "Error while downloading file.");
  }
  let costCenters = "";
  try {
    costCenters = await response.json();
  } catch (err) {
    logError(LogLevel.error, {
      message: "Failed to read export cost centers response.",
      details: {
        error: err,
      },
    });
  }
  return costCenters;
};

const uploadUsersFile = async ({
  file,
  setProgress = () => {},
  setValidationError = () => {},
  setValidationJobID = () => {},
}) => {
  setValidationError(null);
  const response1 = await fetchWithRefresh(
    getUriWithCorsProxy(
      `${API_URL}/users/v3.0/uploaduri?contenttype=text/csv&filename=${file?.name}`,
    ),
    {
      method: "GET",
      mode: "cors",
    },
  );
  if (!response1.ok) {
    const error = await response1.json();
    throw new Error(error.errorMessage || "Error while uploading a file.");
  }
  await setProgress(25);
  const uriUpload = await response1.json();
  const response2 = await fetch(`${CORS_PROXY}/${uriUpload.URI}`, {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Type": "text/csv",
    },
    body: file,
  });
  if (!response2.ok) {
    const error = await response2.json();
    throw new Error(error.errorMessage || "Error while uploading a file.");
  }
  await setProgress(50);
  const response3 = await fetchWithRefresh(
    getUriWithCorsProxy(`${API_URL}/jobs/v3.0/users/import`),
    {
      mode: "cors",
      method: "POST",
      body: JSON.stringify({
        fileName: uriUpload.fileID,
        type: "verify",
      }),
    },
  );
  if (!response3.ok) {
    const error = await response3.json();
    throw new Error(
      typeof error.errorMessage === "string" ? error.errorMessage : "Error while uploading a file.",
    );
  }
  const jobID = await response3.json();
  setValidationJobID(jobID);
  await setProgress(75);
  const job = await checkJobStatus({
    jobID: jobID.ID,
    setValidationError,
    isValidationStep: true,
  });
  if (job.message) {
    throw new Error(job.message || "Error while uploading a file.");
  }
  await setProgress(99);
  return uriUpload;
};

const exportUsers = async () => {
  const exportUsersResponse = await fetchWithRefresh(
    getUriWithCorsProxy(`${API_URL}/jobs/v3.0/users/export`),
    {
      mode: "cors",
      responseType: "text/csv",
    },
  );
  if (!exportUsersResponse.ok) {
    const error = await exportUsersResponse.json();
    throw new Error(error.errorMessage || "Error while downloading file.");
  }
  const jobID = await exportUsersResponse.json();
  const job = await checkJobStatus({
    jobID: jobID.ID,
  });
  if (job.message) {
    throw new Error(job.message || "Error while exporting users.");
  }
  return { downloadURI: job.downloadURI };
};

const uploadCostCentersFile = async ({ file, setProgress, setValidationError, onError }) => {
  setValidationError(null);
  const gettingUri = await fetchWithRefresh(
    getUriWithCorsProxy(
      `${API_URL}/costcenters/v3.0/uploaduri?contenttype=text/csv&filename=${file.name}`,
    ),
    {
      method: "GET",
      mode: "cors",
    },
  );
  if (!gettingUri.ok) {
    const error = await gettingUri.json();
    throw new Error(error.errorMessage || "Error while uploading a file.");
  }
  await setProgress(25);
  const uriUpload = await gettingUri.json();
  const loadingFile = await fetch(`${CORS_PROXY}/${uriUpload.URI}`, {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Type": "text/csv",
    },
    body: file,
  });
  if (!loadingFile.ok) {
    const error = await loadingFile.json();
    throw new Error(error.errorMessage || "Error while uploading a file.");
  }
  await setProgress(50);
  const importCostCenters = await fetchWithRefresh(
    getUriWithCorsProxy(`${API_URL}/jobs/v3.0/costcenters/import`),
    {
      mode: "cors",
      method: "POST",
      body: JSON.stringify({
        fileName: uriUpload.fileID,
        type: "verify",
      }),
    },
  );
  if (!importCostCenters.ok) {
    const error = await importCostCenters.json();
    throw new Error(
      typeof error.errorMessage === "string" ? error.errorMessage : "Error while uploading a file.",
    );
  }
  const jobID = await importCostCenters.json();
  await setProgress(75);
  await checkJobStatusCostCenter({
    jobID: jobID.ID,
    setValidationError,
    onError,
  });
  await setProgress(99);
  return uriUpload;
};

// Multi Entity
const switchUserOrg = async (orgID) => {
  const response = await fetchWithToken(getUriWithCorsProxy(`${API_URL}/oauth/v3.0/switch-org`), {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": MIME_TYPES.json,
    },
    body: JSON.stringify({
      orgID,
    }),
  });

  return response.json();
};

const errorMessage = (err, status) => ({
  errorMessage: err,
  status,
});

const triggerManualDimensionSync = async ({ orgID, dimension }) => {
  try {
    const response = await fetchWithToken(
      getUriWithCorsProxy(`${API_URL}/tools/v3.0/erp/${orgID}/sync/${dimension}`),
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": MIME_TYPES.json,
        },
      },
    );

    if (response.status === 404) {
      return Promise.resolve(null);
    }

    if (!response.ok) {
      const { error } = await response.json();
      return Promise.reject(errorMessage(error, response.status));
    }

    return await response.json();
  } catch (error) {
    window.console.error(error);
    throw error;
  }
};

export default {
  login,
  magicLinkLogin: logInMagicLink,
  magicLinkVerify: verifyMagicLink,
  logout,
  refreshToken,
  changePassword,
  forgotPassword,
  resetPassword,
  getUserInfoByEmail,
  getSpPublicCert,
  getLoginSettings,
  updateLoginSettings,
  downloadStatement,
  uploadReceipt,
  deleteReceipt,
  downloadFileFromURI,
  logError,
  LogLevel,
  sendReminderEmails,
  sendWelcomeEmail,
  sendReplacementCardEmail,
  sendIssueVCEmail,
  setGraphqlClient,
  uploadFieldListItemsFile,
  uploadMappingRulesFile,
  manuallySyncDimension: triggerManualDimensionSync,
  exportListItems,
  exportMappingRules,
  checkJobStatus,
  checkJobStatusCostCenter,
  checkImportUsersJobStatus,
  exportCostCenters,
  uploadUsersFile,
  exportUsers,
  uploadCostCentersFile,
  checkImportListItemsJobStatus,
  getUserById,
  switchUserOrg,
  getUserFeatureFlags,
};
