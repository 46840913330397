/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from "prop-types";
import { Draggable } from "react-beautiful-dnd";
import styled from "styled-components";

import { ReactComponent as DraggableSvg } from "../../assets/imgs/draggable.svg";
import Colors from "../../themes/colors";

const DragHandle = styled(DraggableSvg)`
  height: 1rem;
`;

export const WrapDragHandler = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

const WrapDraggableItem = styled.div`
  background-color: ${Colors.white};
  box-shadow: ${({ isDragging }) => (isDragging ? "0 0 5px 5px rgba(0,0,0,0.1)" : undefined)};
`;

const DraggableItem = styled.div`
  position: relative;
  padding-left: 1.5rem;
`;

const ListItemDraggable = ({ children, index, ...rest }) => (
  <Draggable key={`list-${index}`} draggableId={`list-${index}`} index={index}>
    {(provided, snapshot) => (
      <WrapDraggableItem
        ref={provided.innerRef}
        {...provided.draggableProps}
        isDragging={snapshot.isDragging}
        {...rest}
      >
        <DraggableItem>
          <WrapDragHandler {...provided.dragHandleProps}>
            <DragHandle role="img" />
          </WrapDragHandler>
          {children}
        </DraggableItem>
      </WrapDraggableItem>
    )}
  </Draggable>
);

ListItemDraggable.propTypes = {
  children: PropTypes.any,
  index: PropTypes.number,
};

export default ListItemDraggable;
