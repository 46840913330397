import { useMemo } from "react";
import PropTypes from "prop-types";

import InputNumber from "../InputNumber";

const parseInput = (value, re = /[^\d.]/g) => value.replace(re, "");

const formatInput = (value, formatNumberParams) => {
  const { distanceUnits } = formatNumberParams || {};
  return `${value} ${distanceUnits}`;
};

const InputDistanceNumber = ({ formatNumberParams, formatter, parser, ...props }) => {
  useMemo(() => formatInput(null, formatNumberParams), [formatNumberParams]);

  // eslint-disable-next-line react/prop-types
  const { onBlur } = props;
  let onBlurShim;
  if (onBlur)
    onBlurShim = (e) => {
      onBlur(parser(e.target.value));
    };

  const { precision } = formatNumberParams || {};

  return (
    <InputNumber
      formatter={(value) => formatInput(value, formatNumberParams)}
      parser={parseInput}
      precision={precision}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      onBlur={onBlurShim}
    />
  );
};

InputDistanceNumber.propTypes = {
  formatter: PropTypes.func,
  parser: PropTypes.func,
  formatNumberParams: PropTypes.shape({
    distanceUnits: PropTypes.string,
    precision: PropTypes.number,
  }),
};

InputDistanceNumber.defaultProps = {
  formatNumberParams: {
    distanceUnits: "mi",
    precision: 2,
  },
  formatter: formatInput,
  parser: parseInput,
};

export default InputDistanceNumber;
