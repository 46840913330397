import { useCallback, useEffect, useMemo, useState } from "react";
import gql from "graphql-tag";
import _ from "lodash";
import { useQuery } from "react-apollo";

import BalanceStyled from "../../../features/CardsPage/BalanceStyled";
import CardholderStyled from "../../../features/CardsPage/CardholderStyled";
import {
  DrawerStyled,
  HeadingStyled,
  ItemWrapper,
} from "../../../features/CardsPage/styledComponents";
import { cardHasCustomName } from "../../../utils";
import { ASYNC_JOB, ASYNC_JOB_STATUS } from "../../../utils/asyncJobs";

export const GET_CARDS_ASYNC_JOBS = gql`
  query {
    cardsAsyncJob @client {
      cards {
        ID
        cardReferenceID
        cardholderID
        last4
        cardProgramInstanceID
        statusCode
        limit
        cardFormat
        balance
        firstName
        lastName
        cardholder {
          firstName
          lastName
        }
        isAdminLocked
      }
      activeJob
    }
  }
`;

type Operation = {
  ID: string;
  status: string;
};

type Job = {
  ID: string;
  downloadURI: null;
  error: null;
  jobType: string;
  operations: Operation[];
  status: string;
  timeStamp: null;
  warning: null;
};

type CardsSidePanelProps = {
  job: Job;
  onCompletedClose: () => void;
};

const { Pending, Active } = ASYNC_JOB_STATUS;

const CardsSidePanel = ({ job, onCompletedClose }: CardsSidePanelProps) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const { data: cardsAsyncJobsData } = useQuery(GET_CARDS_ASYNC_JOBS);
  const cardsAsyncJobs = _.get(cardsAsyncJobsData, "cardsAsyncJob.cards", []);
  const failedCards = useMemo(() => {
    if (![Pending, Active].includes(job.status)) {
      return cardsAsyncJobs.filter(
        (card) =>
          job?.operations.find(
            (operation) => operation.ID === card.ID && operation.status === "failed",
          ),
      );
    }
    return [];
  }, [cardsAsyncJobs, job]);

  const onOpenDrawer = useCallback(() => {
    setOpenDrawer(true);
  }, []);

  useEffect(() => {
    window.addEventListener("openCardsSidePanel", onOpenDrawer);
    return () => window.removeEventListener("openCardsSidePanel", onOpenDrawer);
  }, [onOpenDrawer]);

  const handelOnClose = () => {
    setOpenDrawer(false);
    onCompletedClose();
  };

  const drawerText = useMemo(() => {
    switch (job?.jobType) {
      case ASYNC_JOB.CardsLimitChange:
        return {
          header: `${failedCards.length} of ${cardsAsyncJobs.length} card limits not changed`,
          title: "The following cards were unable to be changed due to an error. Please try again.",
        };
      case ASYNC_JOB.CardsLock:
        return {
          header: `${failedCards.length} of ${cardsAsyncJobs.length} cards not locked`,
          title:
            "The following cards were unable to be locked at this time. Please try again later.",
        };
      case ASYNC_JOB.CardsUnlock:
        return {
          header: `${failedCards.length} of ${cardsAsyncJobs.length} cards not unlocked`,
          title:
            "The following cards were unable to be unlocked at this time. Please try again later.",
        };
      case ASYNC_JOB.CardsClose:
      default:
        return {
          header: `${failedCards.length} of ${cardsAsyncJobs.length} cards not closed`,
          title:
            "The following cards were unable to be unlocked at this time. Please try again later.",
        };
    }
  }, [cardsAsyncJobs.length, failedCards.length, job]);

  return (
    <DrawerStyled
      {...{
        title: <HeadingStyled> {drawerText.header}</HeadingStyled>,
        placement: "right",
        mask: false,
        width: 540,
        visible: openDrawer,
        onClose: handelOnClose,
      }}
    >
      <p>{drawerText.title}</p>
      {failedCards.map((card) => (
        <ItemWrapper {...{ key: card.ID }}>
          <CardholderStyled {...{ card, hasCustomName: cardHasCustomName(card) }} />
          <BalanceStyled {...{ card }} />
        </ItemWrapper>
      ))}
    </DrawerStyled>
  );
};

export default CardsSidePanel;
