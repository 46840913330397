/* eslint-disable function-name/starts-with-verb */
/* eslint-disable no-console */
import _ from "lodash";

const GOOGLE_MAPS_TILE_SIZE = 256;

class TripRenderer {
  mapInstance;

  overlay;

  /**
   * @type { string } - string that represents what unit system is used, this is on of ['km', 'mi']
   */
  unitSystem;

  // Routes from Maps Directions service, raw data to be handled
  routeDirections;

  // Route data to display on the map
  markers; // for staring point, destination and waypoints

  /**
   * Polylines and tooltips with distance
   * @type { Array<{ tooltip, line, bounds, distance }> }
   */
  routes;

  previousBounds;

  // Map services
  DirectionService;

  Geocoder;

  Marker;

  MapTypeId;

  event;

  // happened events
  isBoundsChanged = false;

  static #ROUTE_POLY_OPTIONS = {
    strokeOpacity: 0.7,
    strokeWeight: 5,
    strokeColor: "#9e9e9e",
  };

  static #MAIN_ROUTE_POLY_OPTIONS = {
    strokeOpacity: 1,
    strokeWeight: 5,
    strokeColor: "#148aff",
  };

  constructor({ map, mapServices, unitSystem, alternativeRouteEnabled, onChange }) {
    this.markers = [];
    this.routes = [];

    this.onChange = onChange;
    this.mapInstance = map;
    this.alternativeRouteEnabled = alternativeRouteEnabled;
    this.unitSystem = unitSystem;

    // services
    this.DirectionsService = new mapServices.DirectionsService();
    this.Geocoder = new mapServices.Geocoder();
    this.Marker = mapServices.Marker;
    this.Polyline = mapServices.Polyline;
    this.InfoWindow = mapServices.InfoWindow;
    this.UnitSystem = mapServices.UnitSystem;
    this.TravelMode = mapServices.TravelMode;
    this.MapTypeId = mapServices.MapTypeId;
    this.event = mapServices.event;

    this.overlay = new mapServices.OverlayView();
    this.overlay.draw = () => {};
    this.overlay.setMap(this.mapInstance);

    // Subscribe to the event in order to know when it's needed to wait for tiles to load
    this.mapInstance.addListener("bounds_changed", () => {
      if (!this.isBoundsChanged) {
        this.isBoundsChanged = true;
      }
    });
  }

  /**
   * Removes all elements from the map
   * @returns { void }
   */
  clearMap() {
    this.markers.forEach((marker) => marker.setMap(null));
    this.routes.forEach(({ tooltip, line }) => {
      tooltip.close();
      line.setMap(null);
    });

    this.markers = [];
    this.routes = [];
  }

  /**
   * Render all route data on the map including polylines, tooltips with distances and markers for waypoints
   * @returns { Promise<void> }
   */
  async addAllDataToMap() {
    if (!_.isEmpty(this.routes)) {
      const lastRouteData = this.routes.at(-1);
      console.log(`Subscribed to domready event at ${new Date().toISOString()}`);
      const waitingForDOMReady = new Promise((resolve) => {
        this.event.addListenerOnce(lastRouteData.tooltip, "domready", () => {
          resolve();
          console.log(`domready event fired at ${new Date().toISOString()}`);
        });
      });
      console.log(`Subscribed to visible event at ${new Date().toISOString()}`);
      const waitingForTooltipVisible = new Promise((resolve) => {
        this.event.addListenerOnce(lastRouteData.tooltip, "visible", () => {
          resolve();
          console.log(`visible event fired at ${new Date().toISOString()}`);
        });
      });

      // Show markers for waypoints
      this.markers.forEach((marker) => marker.setMap(this.mapInstance));
      this.routes.forEach(({ tooltip, line }) => {
        // Show the tooltip on the map
        tooltip.open(this.mapInstance);
        // And show this route polyline on the map
        line.setMap(this.mapInstance);
      });

      const mainRoute = this.routes[0];
      if (mainRoute) {
        // Set the viewport to contain the route bounds
        this.mapInstance.fitBounds(mainRoute.bounds);
        // Pan the map by the minimum amount necessary to contain the route
        this.mapInstance.panToBounds(mainRoute.bounds);
      }

      // Wait until markers are displayed on the map
      await Promise.all([waitingForDOMReady, waitingForTooltipVisible]);

      // get current bounds of the map
      this.previousBounds = mainRoute.bounds;

      // as an option we can get bounds from the map, but if you do that way then please take into account that bounds of map and bounds of route on the map have different coords
      // this.previousBounds = this.mapInstance.getBounds();
    }
  }

  /**
   * Moves route with the specified ID to the first
   * @param { string } routeID - ID of route to get first
   * @returns { void }
   */
  async selectMainRoute(routeDirection) {
    const routeToBeFirstIndex = this.routeDirections.findIndex(
      ({ overview_polyline }) => overview_polyline === routeDirection.overview_polyline,
    );
    if (routeToBeFirstIndex > -1) {
      const [routeToBeFirst] = this.routeDirections.splice(routeToBeFirstIndex, 1);
      this.routeDirections.unshift(routeToBeFirst);
    }

    // Remove routes from the map
    this.clearMap();

    await this.renderRoutes(this.routeDirections);

    if (this.onChange) {
      // The main/selected route is always the first one
      const selectedRoute = this.routes[0];
      if (selectedRoute) {
        this.onChange(selectedRoute.distance);
      }
    }
  }

  /**
   * Renders route that is received from the params on the map
   * This is called whenever any of the inputs are updated
   * @param {{ origin, destination, waypoints }} initialRouteData - object that represents route, has next format { origin, destination, waypoints }
   * @param {{ waitForTilesLoaded: boolean }} options - object that represents render options
   * @returns { void }
   */
  async findAndRenderRoutesInternal(initialRouteData, options) {
    // Clear the map from previous routes that may be already displayed there
    this.clearMap();

    // Do not render anything if received route has no enough data for that
    if (!initialRouteData.origin || !initialRouteData.destination) {
      return;
    }

    // Find route by points from passed data
    this.routeDirections = await this.findRoutes(initialRouteData);

    // if routes not found then distance have to be reset with 0
    let newDistance = 0;
    console.log(`Reset new distance to ${newDistance}`);

    if (_.isArray(this.routeDirections) && !_.isEmpty(this.routeDirections)) {
      // Render all on the map
      console.log(`Rendering found routes started at ${new Date().toISOString()}`);
      await this.renderRoutes(this.routeDirections, options);
      console.log(`Rendering found routes finished at ${new Date().toISOString()}`);
      // the main/selected route is always the first one
      const selectedRoute = this.routes[0];
      if (selectedRoute) {
        newDistance = selectedRoute.distance;
        console.log(`New distance calculated by route: ${newDistance}`);
      }
    }

    if (this.onChange) {
      this.onChange(newDistance);
    }
  }

  findAndRenderRoutes = _.debounce(this.findAndRenderRoutesInternal, 1000);

  /**
   * Renders route that is received from the params on the map
   * This is called whenever any of the inputs are updated
   * @param {{ origin, destination, waypoints }} initialRouteData - object that represents route, has next format { origin, destination, waypoints }
   * @param {{ waitForTilesLoaded: boolean }} options - object that represents render options
   * @returns { Promise<void> }
   */
  async renderRoutes(routesFromDirectionService, options) {
    const mapEventsToWait = [];

    this.freezeMap(true);

    if (_.isArray(routesFromDirectionService) && !_.isEmpty(routesFromDirectionService)) {
      // Generate markers for waypoints
      this.markers = this.generateMarkersForRoute(routesFromDirectionService[0]);

      // Map needs route data in specific format so let's prepare them
      this.routes = this.routesToMapObjects(routesFromDirectionService);

      // To catch the case where tilesloaded didn't fire.
      // Observe the bounds_changed event and check if the difference (in screen-pixels)
      // between the previous bounds and the current bounds is less than 256 (that's the size of a tile).
      // When it does you may assume that there are no tiles to load, trigger the tilesloaded event immediately.
      let needToWaitForTilesLoaded = false;
      let needToWaitForIdle = false;

      // new bounds are the ones from the next main route
      const newBounds = this.routes[0]?.bounds;
      if (newBounds) {
        if (_.isEmpty(this.previousBounds)) {
          needToWaitForTilesLoaded = true;
          needToWaitForIdle = true;
        } else {
          const boundsDifference = this.getBoundsDifference(this.previousBounds, newBounds);
          if (boundsDifference > GOOGLE_MAPS_TILE_SIZE) {
            needToWaitForTilesLoaded = true;
          }
          if (this.isBoundsChanged && boundsDifference > 0) {
            needToWaitForIdle = true;
          }
        }

        if (_.isBoolean(options?.waitForTilesLoaded)) {
          // override this variable with the one came from parameters because it has higher priority
          needToWaitForTilesLoaded = options?.waitForTilesLoaded;
        }

        if (needToWaitForTilesLoaded) {
          // sometimes even if bounds offset is enough big to make the map load new tiles
          // it doesn't happen due to tiles those have to be loaded are already loaded and stored in the memory (maybe from previous loadings)
          // since there is no way to clear map tiles, therefore it's needed to change map type from roadmap to another one
          // and then return it back to road map in order to make the map clear already loaded tiles and start loading them again
          this.mapInstance.setMapTypeId("none");
          setTimeout(() => {
            this.mapInstance.setMapTypeId(this.MapTypeId.ROADMAP);
          });
          // logs were added for https://centerid.atlassian.net/browse/CV3-22553
          // issue wasn't reproducible so we had to add more logs to find more details
          console.log(`Subscribed to tilesloaded event at ${new Date().toISOString()}`);
          mapEventsToWait.push(
            new Promise((resolve) => {
              this.event.addListenerOnce(this.mapInstance, "tilesloaded", () => {
                resolve();
                console.log(`tilesloaded event fired at ${new Date().toISOString()}`);
              });
            }),
          );
        }
        if (needToWaitForIdle) {
          console.log(`Subscribed to idle event at ${new Date().toISOString()}`);
          mapEventsToWait.push(
            new Promise((resolve) => {
              this.event.addListenerOnce(this.mapInstance, "idle", () => {
                resolve();
                console.log(`idle event fired at ${new Date().toISOString()}`);
              });
            }),
          );
        }
      }

      // Show all data on the map
      try {
        await Promise.all([
          this.addAllDataToMap(),
          Promise.race([
            new Promise((resolve, reject) =>
              // eslint-disable-next-line no-promise-executor-return
              setTimeout(() => {
                // tilesloaded and idle events didn't happen in 15 seconds after rendering started
                // in most cases it means that something went wrong with the google map
                // we interrupt rendering and continue work with an expense
                // fix for https://centerid.atlassian.net/browse/CV3-22553;

                reject(
                  new Error(
                    `Warning! Didn't wait for the map to finish rendering routes.  ${new Date().toISOString()}`,
                  ),
                );
              }, 15000),
            ),
            Promise.all([...mapEventsToWait]),
          ]),
        ]);
      } catch (e) {
        console.warn(e.message);
      }

      this.isBoundsChanged = false;
    }
  }

  /**
   * Find possible routes from origin points using Maps Directions service
   * @param {{ origin, destination, waypoints }} origins - object with origin points
   * @returns { Array<{ legs: Array<Leg> }> } array with routes
   */
  async findRoutes({ origin, destination, waypoints }) {
    const request = {
      waypoints,
      destination,
      origin,
      travelMode: this.TravelMode.DRIVING,
      unitSystem: this.UnitSystem.IMPERIAL,
      provideRouteAlternatives: this.alternativeRouteEnabled,
      avoidTolls: false,
    };

    return new Promise((resolve, reject) => {
      this.DirectionsService.route(request, (response, status) => {
        console.log("Direction service route", {
          request,
          response,
          status,
        });
        if (status === "ZERO_RESULTS") {
          return resolve([]);
        }
        if (status !== "OK" || !_.first(response?.routes)) {
          const error = new Error(
            `Direction service failed with: ${status}, and result: ${JSON.stringify(response)}`,
          );
          return reject(error);
        }
        return resolve(response.routes);
      });
    });
  }

  /**
   * Receives a route from Maps Directions service and calculates distance in Miles
   * @param {{ legs: Array<Leg> }} route - route from Maps Directions service
   * @returns number - distance in Miles
   */
  static routeToMiles(route) {
    const METERS_TO_MILES = 6.21371e-4;
    return _.sumBy(route.legs, (leg) => leg.distance.value) * METERS_TO_MILES;
  }

  /**
   * Receives a route from Maps Directions service and calculates distance in Kilometers
   * @param {{ legs: Array<Leg> }} route - route from Maps Directions service
   * @returns number - distance in Kilometers
   */
  static routeToKilometers(route) {
    return _.sumBy(route.legs, (leg) => leg.distance.value) / 1000;
  }

  /**
   * Singles out coords for all points on the route
   * @param {{ legs: Array<Leg> }} route - route from Maps Directions service
   * @returns { Array } array with coords of all points on the route
   */
  static routeToCoords(route) {
    return _(route.legs)
      .flatMap((leg) => leg.steps)
      .flatMap((step) => step.path)
      .value();
  }

  /**
   * Calculates the difference (in screen-pixels) between the northeast between prev and next bounds
   * @param {*} prevBounds - bounds from the previous time when route is rendered
   * @param {*} nextBounds - current bounds of the map
   * @returns { number } - integer that represents max from X and Y difference of values for bounds
   */
  getBoundsDifference(prevBounds, nextBounds) {
    const prevNE = prevBounds.getNorthEast();
    const newNE = nextBounds.getNorthEast();
    const prevPoint = this.overlay.getProjection().fromLatLngToContainerPixel(prevNE); // or fromLatLngToDivPixel
    const newPoint = this.overlay.getProjection().fromLatLngToContainerPixel(newNE); // or fromLatLngToDivPixel
    const xDiff = Math.abs(prevPoint.x - newPoint.x);
    const yDiff = Math.abs(prevPoint.y - newPoint.y);
    return Math.max(xDiff, yDiff);
  }

  /**
   * Gets distance value by route directions
   * @param {{ legs: Array<Leg> }} routeDirection - route from Maps Directions service
   * @param { string } unitSystem - string that represents what unit system is used, this is on of ['km', 'mi'], uses miles by default
   * @returns { string } - string distance value
   */
  static getRouteDistance(routeDirection, unitSystem) {
    let distance;
    switch (unitSystem) {
      case "km": {
        distance = TripRenderer.routeToKilometers(routeDirection).toFixed(1);
        break;
      }
      // eslint-disable-next-line unicorn/no-useless-switch-case
      case "mi":
      default: {
        distance = TripRenderer.routeToMiles(routeDirection).toFixed(1);
        break;
      }
    }
    return distance;
  }

  /**
   * Denies an user to be able tomake the map to be panned or zoomed by user gestures
   * @param { boolean } freeze - indicates of map should handle user gestures or not
   * @returns { void }
   */
  freezeMap(freeze) {
    if (this.mapInstance) {
      this.mapInstance.setOptions({
        gestureHandling: freeze ? "none" : "auto",
      });
    }
  }

  /**
   * Renders only specified route on the map and hides the rest of routes
   * It doesn't affect to the Markers for waypoints on the route
   * @param { (routes: Array<{ tooltip, line, bounds, distance }>) => Array<{ tooltip, line, bounds, distance }> } selectFn - function that accepts all routes on the map and returns only those that should be displayed
   * @param {{ waitForTilesLoaded: boolean }} options - object that represents render options
   * @returns { Promise<void> } - Promise is resolved after Map finishes render of routes
   */
  async renderSpecifiedRoutesOnly(selectFn, options) {
    if (_.isFunction(selectFn)) {
      // Hide all existing routes on the map
      this.routes.forEach(({ tooltip, line }) => {
        tooltip.close();
        line.setMap(null);
      });

      // Filter routes to be displayed
      const routesToRender = selectFn(this.routes);

      // And show these routes on the map
      if (_.isArray(routesToRender) && !_.isEmpty(routesToRender)) {
        // Subscribe to events for the last route in order to know when exactly the route is added on the map and visible
        const lastRouteData = routesToRender.at(-1);
        const mapEventsToWait = [];
        if (lastRouteData) {
          const waitingForDOMReady = new Promise((resolve) => {
            this.event.addListenerOnce(lastRouteData.tooltip, "domready", resolve);
          });
          mapEventsToWait.push(waitingForDOMReady);

          const waitingForTooltipVisible = new Promise((resolve) => {
            this.event.addListenerOnce(lastRouteData.tooltip, "visible", resolve);
          });
          mapEventsToWait.push(waitingForTooltipVisible);

          if (_.isBoolean(options?.waitForTilesLoaded) && options?.waitForTilesLoaded === true) {
            const waitingForTilesLoaded = new Promise((resolve) => {
              this.event.addListenerOnce(this.mapInstance, "tilesloaded", resolve);
            });
            mapEventsToWait.push(waitingForTilesLoaded);
          }
          routesToRender.forEach(({ tooltip, line }) => {
            // Show the tooltip on the map
            tooltip.open(this.mapInstance);
            // And show this route polyline on the map
            line.setMap(this.mapInstance);
          });

          await Promise.all(mapEventsToWait);
        }
      }
    }
  }

  /**
   * Generates markers with letters for map starting point, destination and all waypoints
   * @param {{ legs: Array<Leg> }} route - route from Maps Directions service
   * @returns { Array } array with markers
   */
  generateMarkersForRoute(route) {
    const startLocation = route.legs[0].start_location;
    const waypointsAndEndLocation = route.legs.map((leg) => leg.end_location);

    const allRouteStopLocations = [startLocation, ...waypointsAndEndLocation];

    return allRouteStopLocations.map((location, index) => {
      const markerOptions = {
        position: location,
        optimized: false,
        zIndex: index + 100,
        // this gives each marker a label starting from 'A' through 'Z'
        label: String.fromCodePoint(0x41 + (index % 26)),
      };
      const marker = new this.Marker(markerOptions);
      return marker;
    });
  }

  /**
   * Generates route objects that are suitable to show on the map for given route
   * @param {{ legs: Array<Leg> }} route - object that is a result of work of Maps Destinations service
   * @param { number } zIndex integer that represents zIndex compared to other polys on the map
   * @param { boolean } isSelectedRoute boolean indicates if passed route is the main and it have to be highligthed with specific styles, Default: false
   * @returns {{ tooltip, line, bounds }} where tooltip represents tooltip window with route distance, and line is a route polyline
   */
  routeToMapObjects(routeDirection, zIndex, isSelectedRoute = false) {
    const polyOptions = {
      path: routeDirection.overview_path,
      clickable: !isSelectedRoute,
      ...(isSelectedRoute
        ? TripRenderer.#MAIN_ROUTE_POLY_OPTIONS
        : TripRenderer.#ROUTE_POLY_OPTIONS),
      zIndex,
    };

    const polyline = new this.Polyline(polyOptions);
    const coords = TripRenderer.routeToCoords(routeDirection);

    // Point to show tooltip (marker) with distance on the route
    // Show it at the middle of the route polyline
    const middlePointCoords = coords[Math.floor((coords.length - 1) / 2)];

    const distance = TripRenderer.getRouteDistance(routeDirection, this.unitSystem);
    // Prepare the title to show on the tooltip window
    const tooltipWindowOptions = {
      headerDisabled: true,
      content: `Distance: ${distance} ${this.unitSystem}`,
      position: middlePointCoords,
      zIndex,
    };

    // Create tooltip for route distance
    const tooltip = new this.InfoWindow(tooltipWindowOptions);

    const routeData = {
      tooltip,
      line: polyline,
      distance,
      bounds: routeDirection.bounds,
    };

    // Subscribing to click event for polylines for all unselected routes
    if (!isSelectedRoute) {
      polyline.addListener("click", async () => {
        await this.selectMainRoute(routeDirection);
      });
    }

    return routeData;
  }

  /**
   * Generates route objects that are suitable to show on the map for given route
   * If routes array consists more than one route, then the first route gets the main one and highlighted with blue color for polyline
   * @param { Array<{ legs: Array<Leg> }> } routes - object that is a result of work of Maps Destinations service
   * @returns { Array<{ tooltip, line, bounds, distance }> } where tooltip represents tooltip window with route distance, and line is a route polyline
   */
  routesToMapObjects(routes) {
    // Main route is the selected one
    const mainRoute = _.first(routes);

    const mainRouteData = this.routeToMapObjects(mainRoute, routes.length, true);
    const alternativeRoutesData = _.tail(routes).map((route, index) =>
      this.routeToMapObjects(route, index),
    );

    return [mainRouteData, ...alternativeRoutesData];
  }
}

export default TripRenderer;
