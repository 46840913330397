import type { ComponentProps } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { DayPicker } from "react-day-picker";

import { cn } from "../../lib/utils";
import { buttonVariants } from "../Button";

export type CalendarProps = ComponentProps<typeof DayPicker>;

const Calendar = ({ showOutsideDays = true, classNames, ...props }: CalendarProps) => {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className="p-3"
      classNames={{
        months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
        month: "space-y-4",
        caption: "flex justify-center pt-1 relative items-center",
        caption_label: "text-base",
        nav: "space-x-1 flex items-center",
        nav_button: cn(
          buttonVariants({ variant: "ghost", icon: "only" }),
          "h-7 w-7 bg-transparent border border-solid p-0 opacity-60 hover:opacity-100 focus:opacity-100 border-neutrals-300 rounded-md",
        ),
        nav_button_previous: "absolute left-1",
        nav_button_next: "absolute right-1",
        table: "w-full border-collapse",
        head_row: "flex justify-around",
        head_cell:
          "flex items-center justify-center text-text-300 rounded-md w-9 text-md font-normal",
        row: "flex justify-between w-full mt-2",
        cell: "text-center text-sm relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-range-end)]:bg-neutrals-200 [&:has([aria-selected].day-range-start)]:rounded-l-md  [&:has([aria-selected].day-range-start)]:bg-neutrals-200 [&:has([aria-selected])]:bg-neutrals-200 [&:has([aria-selected])]:text-text-500",
        day: cn(
          buttonVariants({ variant: "secondary" }),
          "h-9 w-9 p-0 text-text-500",
          "border-transparent bg-transparent",
          "focus:ring-0 focus:ring-neutrals-50",
        ),
        day_range_start:
          "day-range-start aria-selected:bg-primary-500 text-text-50 aria-selected:text-text-50 rounded-md",
        day_range_end:
          "day-range-end aria-selected:bg-primary-500 focus:ring-0 aria-selected:text-text-50 rounded-md",
        day_selected:
          "day-selected text-text-50 bg-primary-500 hover:bg-primary-500 hover:text-text-50 focus:bg-primary-500 focus:text-text-50",
        day_today: "text-text-50 bg-primary-500 [&:not(aria-selected)]:bg-neutrals-200",
        day_outside:
          "day-outside text-neutrals-500 opacity-50 aria-selected:bg-neutrals-100/50 aria-selected:text-text-300 aria-selected:opacity-300",
        day_disabled: "text-text-300 opacity-50",
        day_range_middle:
          "day-range-middle bg-neutrals-200 text-text-500 aria-selected:bg-neutrals-200 aria-selected:text-text-500",
        day_hidden: "invisible",
        ...classNames,
      }}
      components={{
        IconLeft: () => <ChevronLeft className="size-4" />,
        IconRight: () => <ChevronRight className="size-4" />,
      }}
      {...props}
    />
  );
};
Calendar.displayName = "Calendar";

export { Calendar };
