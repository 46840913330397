import styled from "styled-components";

import { Icon } from "@core/ui-legacy";
import Colors from "@core/ui-legacy/themes/colors";

export const IconStyled = styled(Icon)`
  font-size: 1.125rem;
  vertical-align: 0 !important;
`;

export const LoadingIcon = styled(IconStyled)`
  color: #658dc6 !important;
`;

export const ErrorIcon = styled(IconStyled)`
  color: ${Colors.danger_red} !important;
`;

export const WarningIcon = styled(IconStyled)`
  color: ${Colors.monumental} !important;
`;

export const SuccessIcon = styled(IconStyled)`
  color: ${Colors.success_green} !important;
`;

export const PendingIcon = styled(IconStyled)`
  color: ${Colors.center_yellow} !important;
`;
