import { forwardRef, type ComponentPropsWithoutRef, type ElementRef } from "react";
import * as SwitchPrimitives from "@radix-ui/react-switch";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "../../lib/utils";
import { Text } from "../Text";

const switchBaseStyles =
  "inline-block shrink-0 cursor-pointer items-center rounded-full border-transparent p-0 transition-colors duration-300 disabled:cursor-not-allowed disabled:opacity-50 focus:ring-2 focus:ring-primary-400 focus:ring-offset-2 focus-visible:ring-2 focus-visible:ring-primary-400 focus-visible:ring-offset-2";

const switchVariants = cva(switchBaseStyles, {
  variants: {
    size: {
      large: "h-6 w-11",
      small: "h-5 w-9",
    },
  },
  defaultVariants: {
    size: "small",
  },
});

const thumbBaseStyles =
  "pointer-events-none block rounded-full bg-white shadow-lg ring-0 transition-transform";

const thumbVariants = cva(thumbBaseStyles, {
  variants: {
    size: {
      large:
        "size-5 translate-y-[.25px] data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0",
      small:
        "size-4 translate-y-[.25px] data-[state=checked]:translate-x-4 data-[state=unchecked]:translate-x-0",
    },
  },
  defaultVariants: {
    size: "small",
  },
});

export type SwitchProps = ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> &
  VariantProps<typeof switchVariants> & {
    label?: string;
    labelPosition?: "left" | "right";
  };

const Switch = forwardRef<ElementRef<typeof SwitchPrimitives.Root>, SwitchProps>(
  ({ className, size, label, labelPosition = "right", ...props }, ref) => (
    <div className={cn("flex items-center", "justify-start")}>
      {labelPosition === "left" && label && <Text className="mr-2">{label}</Text>}
      <SwitchPrimitives.Root
        className={cn(
          switchVariants({ size }),
          className,
          "data-[state=checked]:bg-primary-400 data-[state=unchecked]:bg-neutrals-400 hover:data-[state=checked]:bg-primary-450 hover:data-[state=unchecked]:bg-neutrals-450",
        )}
        {...props}
        ref={ref}
      >
        <SwitchPrimitives.Thumb className={cn(thumbVariants({ size }))} />
      </SwitchPrimitives.Root>
      {labelPosition === "right" && label && <Text className="ml-2">{label}</Text>}
    </div>
  ),
);

export { Switch };
