import { PureComponent } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

export const FOOTER_TOOLBAR_HEIGHT_PX = 72;

const Toolbar = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  right: 0;
  height: ${FOOTER_TOOLBAR_HEIGHT_PX}px;
  line-height: 72px;
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 10%);
  background: #fff;
  padding: 0 24px;
  z-index: 9;

  &::after {
    content: "";
    display: block;
    clear: both;
  }
`;

const LeftSide = styled.div`
  float: left;

  button + button,
  span + span {
    margin-left: 8px;
  }
`;

const RightSide = styled.div`
  float: right;
`;

class FooterToolbar extends PureComponent {
  render() {
    const { children, extra, ...restProps } = this.props;
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Toolbar {...restProps}>
        <LeftSide>{children}</LeftSide>
        <RightSide>{extra}</RightSide>
      </Toolbar>
    );
  }
}

FooterToolbar.propTypes = {
  children: PropTypes.any,
  extra: PropTypes.any,
};

export default FooterToolbar;
