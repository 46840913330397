import type { ICardProgram } from "../features/UserInfoPage/types";
import type { FeatureFlag } from "../utils/featureFlags";
import type { UserRole } from "./roles";

export type CardFormat = "physical" | "virtual";
export type CardBehavior = "standard" | "singleTransaction" | "neverReset";
export type CardLimitFrequency = "Monthly" | "Never resets";

export type CardStatus =
  | "IssuedInactive"
  | "Open"
  | "Lost"
  | "Stolen"
  | "NoWithdrawals"
  | "Closed"
  | "LostCardNotCaptured"
  | "StolenCardNotCaptured"
  | "Inactive"
  | "Reissued"
  | "FraudBlocked";

export type CardRequestStatus = "inReview" | "approved" | "denied";

export enum CardReissuedReasonCode {
  DAMAGED = "damaged",
  EXPIRED = "expired",
  LOST = "lost",
  STOLEN = "stolen",
}

export type ScheduledCardEventType = "admin-lock" | "admin-unlock" | "close" | "limit-change";

export type ScheduledCardEvent = {
  effectiveDate: string;
  id: string;
  type: ScheduledCardEventType;
  endDate?: string;
  limit?: number;
  originalLimit?: number;
};

export type CardType = {
  ID: string;
  balance: number;
  cardFormat: CardFormat;
  cardInfo: CardInfo;
  cardholderID: string;
  expirationYYMM: string;
  firstName: string;
  isProvisioning: boolean;
  lastName: string;
  limit: number;
  statusCode: CardStatus;
  cardBehavior?: CardBehavior;
  cardProgramInstanceID?: string;
  cardholder?: Cardholder;
  isActive?: boolean;
  isAdminLocked?: boolean;
  isPendingClose?: boolean;
  last4?: string;
  limitDescriptionCard?: string;
  newStatus?: string;
  orgID?: string;
  reissued?: { reasonCode: CardReissuedReasonCode };
  scheduledEvents?: ScheduledCardEvent[];
  status?: string;
  statusLabel?: string;
  type?: string;
  typeLabel?: string;
};

export type CardInfo = {
  ID: string;
  activeState: boolean;
  canUnlock: boolean;
  isClosed: boolean;
  isLocked: boolean;
  isRequested: boolean;
  hasReissued?: boolean;
  reissuedReason?: string | null;
};

export type BillingAddress = {
  address: string;
  city: string;
  country: string;
  postalCode: string;
  state: string;
  address2?: string;
  isChanged?: boolean;
  isVerified?: boolean;
  timestamp?: string;
};

export type CostCenterReference = {
  ID: string;
  name: string;
};

export type Cardholder = {
  ID: string;
  billingAddress: BillingAddress;
  defaultCostCenter: CostCenterReference;
  firstName: string;
  fraudEnrolled: boolean;
  fullName: string;
  lastName: string;
  roles: string[];
  phone?: string;
};

type Spotnana = {
  icon: string;
  label: string;
  legalEntityID: string;
  name: string;
  orgID: string;
  ssoLink: string;
  urlPolicyiFrame: string;
};

type PartnerFields = {
  spotnana?: Spotnana;
};

export type UserReference = {
  ID: string;
  fullName: string;
};

export type DefaultApprover = {
  ID: string;
  name: string;
  ruleID?: string;
};

export type DefaultApproverSchedule = {
  defaultApprover: DefaultApprover;
  effectiveDate: string;
  fromEffectiveDate: boolean;
};

export type UserType = {
  ID: string;
  billingAddress: BillingAddress;
  defaultApprover: DefaultApprover;
  defaultCostCenter: CostCenterReference;
  emailAddress: string;
  emailStatus: string;
  firstName: string;
  fraudEnrolled: boolean;
  fullName: string;
  lastName: string;
  loginRestricted: boolean;
  organization: {
    cardPrograms: ICardProgram[];
    currencyCode: string;
    partnerFields: PartnerFields;
    shippingAddress: {
      address1: string;
      address2: string;
    };
    ID?: string;
  };
  reimbursementPolicy: string;
  roles: UserRole[];
  toggles: FeatureFlag[];
  travelArrangerOfInfo: {
    costCenters: string[];
    users: string[];
  };
  archived?: boolean;
  defaultApproverSchedule?: DefaultApproverSchedule[];
  delegateOf?: UserReference[];
  insightsViewerOf?: { costCenters: CostCenterReference[] };
  integrationInfo?: { spotnana?: { persona: unknown } };
  travelArrangerOf?: UserReference[];
};

export const SorterDirectionType = {
  ascend: "ascend",
  descend: "descend",
};

export const SortDirectionType = {
  asc: "asc",
  desc: "desc",
};

export type DeepPartial<T> = T extends Record<string, unknown>
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;
