/* eslint-disable id-length */
/* eslint-disable no-param-reassign */
/* eslint-disable unicorn/prefer-array-some */
import { useEffect, useMemo, useRef, useState } from "react";
import { Select as AntSelect } from "antd";
import _ from "lodash";
import PropTypes from "prop-types";
import styled from "styled-components";

const { Option, OptGroup } = AntSelect;

const SelectStyled = styled(AntSelect)`
  .ant-select-selection__choice {
    background-color: rgb(0 79 224 / 10%) !important;
    border-color: rgb(0 79 224 / 10%) !important;
  }
`;

// height antd Option in px
// it should be synced with actual option height
const optionHeight = 32;

const Select = ({
  getScrollableArea,
  loadMoreItems,
  loadMoreItemsOffset,
  loading,
  notFoundContent,
  clearSelection,
  highlightedFirstSearchedOption,
  defaultActiveFirstOption,
  onInputKeyDown,
  saveOnTabClick,
  saveOnBlur,
  onBlur,
  onSearch,
  placeholder,
  children,
  isFocusOnPopup,
  initialValue,
  optionKey,
  clearSearch = true,
  useValueInsteadActiveItemForPlaceholder,
  disabled,
  isGrouped,
  addNewOnBlur,
  ...rest
}) => {
  const [open, setOpen] = useState(rest.defaultOpen);
  const [searchValue, setSearchValue] = useState("");
  const [activeItem, setActiveItem] = useState(null);
  const refToSelect = useRef(null);
  const rcSelect = refToSelect.current?.rcSelect;
  const selectTriggerRef = rcSelect?.selectTriggerRef;

  const closeMenu = () => {
    setOpen(false);
  };

  // ComponentDidMount
  useEffect(() => {
    if (getScrollableArea) {
      getScrollableArea().addEventListener("scroll", closeMenu);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ComponentWillUnmount
  useEffect(
    () => () => {
      if (getScrollableArea && getScrollableArea()) {
        getScrollableArea().removeEventListener("scroll", closeMenu);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const loadMoreOffset = useMemo(() => loadMoreItemsOffset * optionHeight, [loadMoreItemsOffset]);

  useEffect(() => {
    if (clearSelection && rcSelect) {
      rcSelect.fireChange([]);
      rcSelect.setOpenState(false, {
        needFocus: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearSelection]);

  useEffect(() => {
    if (disabled) {
      closeMenu();
    }
  }, [disabled]);

  const onScroll = (event) => {
    const { target } = event;
    if (
      !loading &&
      target.scrollTop + target.offsetHeight >= target.scrollHeight - loadMoreOffset
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      loadMoreItems && loadMoreItems();
    }
  };

  return (
    <SelectStyled
      ref={refToSelect}
      notFoundContent={!loading && notFoundContent}
      disabled={!!disabled}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      open={open}
      onPopupScroll={onScroll}
      onSearch={(search) => {
        setActiveItem(null);
        if (onSearch) onSearch(search);
        if (highlightedFirstSearchedOption) setSearchValue(search);
        // undefined, null and 0 dont pass
        if (isFocusOnPopup?.current === false) isFocusOnPopup.current = true;
      }}
      onBlur={(e) => {
        if (children.find((c) => c.key === "Add new") && addNewOnBlur) {
          rcSelect?.fireChange([searchValue]);
          rcSelect?.fireSelect(searchValue);
        } else if (
          // undefined, null and 0 dont pass
          isFocusOnPopup?.current === false &&
          saveOnBlur &&
          selectTriggerRef?.getPopupDOMNode() &&
          open
        ) {
          // if we leave focus on popup and click out and initialValue is not nil, we will save initial value (initial value is value from expense)
          const value = initialValue?.[optionKey] ?? initialValue;
          // check if option exist in the list (case for deactivated fields that still exist in expense)
          const isOptionInTheList = !!children.find((child) => child.key === value);
          if (!isOptionInTheList) return;
          rcSelect.fireChange([value]);
          rcSelect.fireSelect(value);
          setActiveItem(null);
        } else if (
          !_.isNil(activeItem) &&
          saveOnBlur &&
          selectTriggerRef?.getPopupDOMNode() &&
          open &&
          useValueInsteadActiveItemForPlaceholder &&
          activeItem.ID
        ) {
          rcSelect.fireChange([activeItem]);
          rcSelect.fireSelect(activeItem);
          setActiveItem(null);
        } else if (
          !_.isNil(activeItem) &&
          saveOnBlur &&
          selectTriggerRef?.getPopupDOMNode() &&
          open &&
          (activeItem?.["0-menu-"] || initialValue)
        ) {
          const value = activeItem?.["0-menu-"] ?? initialValue?.[optionKey] ?? initialValue;

          rcSelect.fireChange([value]);
          rcSelect.fireSelect(value);
          setActiveItem(null);
        } else if (onBlur) onBlur(e, searchValue);
      }}
      onInputKeyDown={(e) => {
        if (saveOnTabClick && e.key === "Tab" && isFocusOnPopup?.current) {
          rcSelect?.inputRef?.dispatchEvent(
            new KeyboardEvent("keydown", {
              key: "Enter",
              keyCode: 13,
              which: 13,
              bubbles: true,
            }),
          );
          setActiveItem(null);
          setSearchValue(null);
        }
        // up/down arrows
        if (e.key === "ArrowDown" || e.key === "ArrowUp") {
          const store = selectTriggerRef?.dropdownMenuRef?.menuRef?.store;
          if (
            highlightedFirstSearchedOption &&
            activeItem &&
            saveOnBlur &&
            // undefined, null and 0 dont pass
            isFocusOnPopup?.current === false
          ) {
            store?.setState({ activeKey: activeItem });
            isFocusOnPopup.current = true;
          }
          const ID = store?.getState().activeKey["0-menu-"];
          const name = children.find((child) => child.key === ID)?.props?.title;
          // useValueInsteadActiveItemForPlaceholder needs for displaying name in input instead of ID
          setActiveItem(
            useValueInsteadActiveItemForPlaceholder ? { name, ID } : store?.getState().activeKey,
          );
        }
        if (onInputKeyDown) onInputKeyDown(e);
      }}
      onDropdownVisibleChange={(isOpen) => {
        setOpen(isOpen);
        if (selectTriggerRef?.getPopupDOMNode() && highlightedFirstSearchedOption && !isOpen) {
          setSearchValue("");
        }
      }}
      loading={loading}
      defaultActiveFirstOption={
        defaultActiveFirstOption || (highlightedFirstSearchedOption && !!searchValue && clearSearch)
      }
      placeholder={(() => {
        let filledFieldPlaceholder;
        if (isGrouped && highlightedFirstSearchedOption) {
          let groupedChildren = [];
          children.forEach((c) => {
            // eslint-disable-next-line unicorn/prefer-spread
            groupedChildren = groupedChildren.concat(c?.props.children);
          });

          filledFieldPlaceholder = groupedChildren?.find((v) => v.key === activeItem?.["0-menu-"])
            ?.props?.title;

          return filledFieldPlaceholder || placeholder;
        }
        if (highlightedFirstSearchedOption) {
          filledFieldPlaceholder =
            children?.find((v) => v.key === activeItem?.["0-menu-"])?.props?.title ||
            activeItem?.name;
        }
        return filledFieldPlaceholder || placeholder;
      })()}
      /* eslint-disable-next-line react/no-children-prop */
      children={children}
    />
  );
};

Select.propTypes = {
  // eslint-disable-next-line unicorn/consistent-destructuring
  ...AntSelect.propTypes,
  getScrollableArea: PropTypes.func,
  loadMoreItems: PropTypes.func,
  loadMoreItemsOffset: PropTypes.number,
  notFoundContent: PropTypes.string,
  clearSelection: PropTypes.bool,
  isGrouped: PropTypes.bool,
  addNewOnBlur: PropTypes.bool,
};

Select.defaultProps = {
  // eslint-disable-next-line unicorn/consistent-destructuring
  ...AntSelect.defaultProps,
  defaultActiveFirstOption: false,
  saveOnBlur: false,
  highlightedFirstSearchedOption: false,
  loadMoreItemsOffset: 1,
  notFoundContent: "No results found",
  saveOnTabClick: false,
  isGrouped: false,
  addNewOnBlur: false,
};

Select.Option = Option;
Select.OptGroup = OptGroup;

export default Select;
