import { useContext } from "react";
import styled from "styled-components";

import { sansProps } from "@core/ui-legacy/utils/styledSansProps";

import { ReactComponent as LogoSmall } from "../../assets/imgs/center-logo-small.svg";
import { ReactComponent as LogoBig } from "../../assets/imgs/center-logo.svg";
import { ReactComponent as Dot } from "../../assets/imgs/dot.svg";
import AuthUserContext from "../../common/contexts/AuthUserContext";
import { userHasFeatures } from "../../common/userHasFeatures";
import featureFlags from "../../utils/featureFlags";

const getLogoStyled = (Logo) => sansProps(styled, {
  size: true,
  color: true,
  mr: true,
})(Logo)`
  .st0 {
    fill: ${({ color }) => color};
  }
  height: ${({ size }) => (size ? `${size}rem` : undefined)};
  margin-right: ${({ mr }) => (mr ? `${mr}rem` : undefined)};
`;

const LogoBigStyled = getLogoStyled(LogoBig);
const LogoSmallStyled = getLogoStyled(LogoSmall);

const D = ({ size }) => size + 0.5;
const R = ({ size }) => 0.5 * size + 0.25;

const CubeStyled = sansProps(styled, { size: true })("div")`
  width: ${D}rem;
  height: ${D}rem;
  margin: -0.25rem;
  perspective: 5rem;
  transition: transform 1s;

  .cube {
    width: ${D}rem;
    height: ${D}rem;
    position: relative;
    transform-style: preserve-3d;
    transform: translateZ(-${R}rem);
    animation: spinCube 8s 0s infinite cubic-bezier(0.5, 0, 0.5, 1) paused;
  }

  .face {
    position: absolute;
    width: ${D}rem;
    height: ${D}rem;
    padding: 0.25rem;
    background-color: white;
  }

  &:hover {
    transform: scale(2) translate(0.25rem, 0.25rem);

    .cube {
      animation-play-state: running;
    }
  }

  .front {
    transform: rotateY(0deg) translateZ(${R}rem);
  }
  .right {
    transform: rotateY(90deg) translateZ(${R}rem);
  }
  .back {
    transform: rotateY(180deg) translateZ(${R}rem);
  }
  .left {
    transform: rotateY(-90deg) translateZ(${R}rem);
  }
  .top {
    transform: rotateX(90deg) translateZ(${R}rem);
  }
  .bottom {
    transform: rotateX(-90deg) translateZ(${R}rem);
  }

  @keyframes spinCube {
    0%,
    10% {
      transform: translateZ(-${R}rem) rotateX(0deg) rotateY(0deg);
    }
    90%,
    100% {
      transform: translateZ(-${R}rem) rotateX(360deg) rotateY(360deg);
    }
  }
`;

const renderCube = (renderLogo, { size }) => (
  <CubeStyled size={size}>
    <div className="cube">
      <div className="face front">{renderLogo()}</div>
      <div className="face back">???</div>
      <div className="face right">{renderLogo()}</div>
      <div className="face left">{renderLogo()}</div>
      <div className="face top">{renderLogo()}</div>
      <div className="face bottom">{renderLogo()}</div>
    </div>
  </CubeStyled>
);

const S = ({ size }) => size;
const S2 = ({ size }) => 0.66 * size;

const DotsStyled = sansProps(styled, { size: true })("div")`
  width: ${S}rem;
  height: ${S}rem;
  overflow: hidden;
  border-radius: 50%;
  position: relative;
  transition: transform 1s;

  .face {
    position: absolute;
    width: ${S}rem;
    height: ${S}rem;
    background-color: transparent;
    opacity: 0;
    transition: opacity 1s;
    animation: spin 12s linear 0s infinite paused;
  }

  .center {
    position: absolute;
    width: ${S}rem;
    height: ${S}rem;
    animation: spinCenter 12s linear 0s infinite paused;
  }

  &:hover {
    transform: scale(2) translate(0.25rem, 0.25rem);

    .center {
      animation-play-state: running;
    }

    .face {
      opacity: 1;
      animation-play-state: running;
    }
  }

  .right {
    right: -${S2}rem;
  }
  .left {
    left: -${S2}rem;
  }
  .top {
    top: -${S2}rem;
  }
  .bottom {
    bottom: -${S2}rem;
  }

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes spinCenter {
    0% {
      transform: rotate(0deg);
    }
    0% {
      transform: rotate(360deg);
    }
    5% {
      transform: rotate(357deg);
    }
    7.5% {
      transform: rotate(351deg);
    }
    10% {
      transform: rotate(339deg);
    }
    12.5% {
      transform: rotate(315deg);
    }
    15% {
      transform: rotate(291deg);
    }
    17.5% {
      transform: rotate(279deg);
    }
    20% {
      transform: rotate(273deg);
    }
    25% {
      transform: rotate(270deg);
    }
    30% {
      transform: rotate(267deg);
    }
    32.5% {
      transform: rotate(261deg);
    }
    35% {
      transform: rotate(249deg);
    }
    37.5% {
      transform: rotate(225deg);
    }
    40% {
      transform: rotate(201deg);
    }
    42.5% {
      transform: rotate(189deg);
    }
    45% {
      transform: rotate(183deg);
    }
    50% {
      transform: rotate(180deg);
    }
    55% {
      transform: rotate(177deg);
    }
    57.5% {
      transform: rotate(171deg);
    }
    60% {
      transform: rotate(159deg);
    }
    62.5% {
      transform: rotate(135deg);
    }
    65% {
      transform: rotate(111deg);
    }
    67.5% {
      transform: rotate(99deg);
    }
    70% {
      transform: rotate(93deg);
    }
    75% {
      transform: rotate(90deg);
    }
    80% {
      transform: rotate(87deg);
    }
    82.5% {
      transform: rotate(81deg);
    }
    85% {
      transform: rotate(69deg);
    }
    87.5% {
      transform: rotate(45deg);
    }
    90% {
      transform: rotate(21deg);
    }
    92.5% {
      transform: rotate(9deg);
    }
    95% {
      transform: rotate(3deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`;

const renderDots = (renderLogo, { size }) => (
  <DotsStyled size={size}>
    <div className="center">{renderLogo()}</div>
    <div className="face right">
      <Dot />
    </div>
    <div className="face left">
      <Dot />
    </div>
    <div className="face top">
      <Dot />
    </div>
    <div className="face bottom">
      <Dot />
    </div>
  </DotsStyled>
);

const CenterLogo = ({ isSmall, noAnimation, ...props }) => {
  const { toggles } = useContext(AuthUserContext);

  const useCube = userHasFeatures(toggles, [featureFlags.ANIMATED_LOGO_CUBE]);
  const useDots = userHasFeatures(toggles, [featureFlags.ANIMATED_LOGO_DOTS]);

  const Logo = isSmall ? LogoSmallStyled : LogoBigStyled;

  const renderLogo = () => <Logo role="img" aria-label="Center" {...props} />;

  if (isSmall) {
    if (useCube && !noAnimation) {
      return renderCube(renderLogo, props);
    }

    if (useDots && !noAnimation) {
      return renderDots(renderLogo, props);
    }
  }

  return renderLogo();
};

export default CenterLogo;
