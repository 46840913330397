import { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Modal, Wizard } from "@core/ui-legacy";

import ActivateCard from "./ActivateCard";
import SuccessPhoneAndFraud from "./ActiveFraud";
import EnableFraudAlerts from "./AddPhone";
import ConnectCard from "./ConnectCard";
import FraudInfo from "./FraudInfo";
import SuccessCard from "./SuccessCard";
// preloading
import "../../assets/imgs/CenterCard_Front.svg";
import "../../assets/imgs/CenterCard_Back.svg";

const CardModal = styled(Modal)`
  .ant-modal-body {
    background-color: #fff;
    min-height: 12.5rem;
  }

  .ant-modal-header {
    padding: 1.5rem 0 0.5rem;
  }
`;

const ActivateCardDialog = ({
  userID,
  phone,
  fraudEnrolled,
  cardsToActivate,
  isModalOpen,
  setIsModalOpen,
  handleSuccessful,
}) => {
  const [localCardsToActivate, setLocalCardsToActivate] = useState(cardsToActivate);

  const handleOk = () => {
    setIsModalOpen(false);
    handleSuccessful();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const renderWizard = () => (
    <Wizard>
      {[
        {
          content: <ActivateCard onCancel={handleCancel} />,
        },
        {
          content: (
            <ConnectCard
              phone={phone}
              cardsToActivate={localCardsToActivate}
              setCardsToActivate={setLocalCardsToActivate}
              onCancel={handleCancel}
            />
          ),
        },
        {
          content: (
            <SuccessCard
              cardsToActivate={localCardsToActivate}
              phone={phone}
              onCancel={handleCancel}
            />
          ),
        },
        {
          content: (
            <SuccessPhoneAndFraud
              fraudEnrolled={fraudEnrolled}
              phone={phone}
              onCancel={handleCancel}
            />
          ),
        },
        {
          content: <EnableFraudAlerts userID={userID} onCancel={handleCancel} />,
        },
        {
          content: <FraudInfo onCancel={handleCancel} />,
        },
      ]}
    </Wizard>
  );

  return (
    <>
      {isModalOpen && (
        <CardModal onOk={handleOk} onCancel={handleCancel} width="667px" visible footer={null}>
          {renderWizard()}
        </CardModal>
      )}
    </>
  );
};

ActivateCardDialog.propTypes = {
  userID: PropTypes.string,
  phone: PropTypes.string,
  fraudEnrolled: PropTypes.bool,
  cardsToActivate: PropTypes.array,
  isModalOpen: PropTypes.bool,
  setIsModalOpen: PropTypes.func,
  handleSuccessful: PropTypes.func,
};

export default ActivateCardDialog;
