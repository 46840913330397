import { useCallback, useContext, useMemo, useState } from "react";
import { useAuth, useAuthActions } from "@frontegg/react";
import { useMutation } from "react-apollo";
import { withRouter } from "react-router-dom";

import { Notifier } from "@core/ui-legacy";
import FullScreenSpin from "@core/ui-legacy/components/FullScreenSpin";

import UserOrganizationsContext from "../../common/contexts/UserOrganizationsContext";
import withAuthUser from "../../common/core-refactor/withUserAuth";
import { SWITCH_ORG } from "../../services/graphql/mutations";
import { cacheToken } from "../../services/rest/api";
import {
  CurrentOrg,
  DropdownArrow,
  MenuItemContent,
  MenuItemStyled,
  MenuStyled,
  SearchBoxStyled,
  SearchIconStyled,
  SearchMenuItemStyled,
  StyledDropdown,
  Wrapper,
} from "./styles";
import type { ISwitchOrg, IUserOrganizationsContext } from "./types";
import { disableEvents, reloadCommonRoute } from "./utils";

const WRAPPER_ID = "organization-switch-wrapper";

type IProps = {
  isMobile?: boolean;
};

const OrganizationSwitch = ({ isMobile }: IProps) => {
  const { userOrganizations, currentOrg } =
    useContext<IUserOrganizationsContext>(UserOrganizationsContext);

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [switchOrg] = useMutation<ISwitchOrg>(SWITCH_ORG);
  const { switchTenant } = useAuthActions();
  const { isAuthenticated: isAuthenticatedViaFrontegg } = useAuth();

  const handleFronteggTenantSwitch = useCallback(
    (orgID: string) => {
      setIsLoading(true);
      switchTenant({
        tenantId: orgID,
        callback: (_data, error) => {
          if (!error) {
            return;
          }

          setIsLoading(false);
          Notifier.notification({
            type: "error",
            message: "Switch organization",
            description: `Error while switching organization.`,
          });
        },
      });
    },
    [switchTenant],
  );

  const handleSwitchOrg = async (orgID: string) => {
    try {
      if (orgID === currentOrg.ID) {
        return;
      }

      if (isAuthenticatedViaFrontegg) {
        handleFronteggTenantSwitch(orgID);

        return;
      }

      setIsLoading(true);

      const { data } = await switchOrg({
        variables: {
          orgID,
        },
      });

      if (data?.switchOrg) {
        cacheToken(data?.switchOrg);
        reloadCommonRoute();
      }
    } catch {
      setIsLoading(false);
      Notifier.notification({
        type: "error",
        message: "Switch organization",
        description: `Error while switching organization.`,
      });
    }
  };

  const RenderMenuContent = (props) => {
    const [searchWord, setSearchWord] = useState<string>("");

    const handleSearch = (e) => {
      setSearchWord(e.trim());
    };

    const orgOptions = useMemo(() => {
      if (!searchWord) {
        return userOrganizations;
      }
      return userOrganizations.filter((org) => {
        const orgNameLowercase = org.orgName.toLowerCase();
        const searchWordLowercase = searchWord.toLowerCase();
        return orgNameLowercase.includes(searchWordLowercase);
      });
    }, [searchWord]);

    if (isLoading) return <FullScreenSpin spinning={isLoading} size="large" />;

    return (
      <>
        <SearchMenuItemStyled key="org-search" {...props} onItemHover={disableEvents}>
          <SearchBoxStyled
            {...{
              iconOption: "suffix",
              onSearch: handleSearch,
              value: searchWord,
              iconComponent: <SearchIconStyled />,
            }}
          />
        </SearchMenuItemStyled>

        {orgOptions.length ? (
          orgOptions.map((org) => {
            const isActive = currentOrg.ID === org.orgID;
            return (
              <MenuItemStyled
                {...props}
                {...{
                  key: org.userID,
                  onClick: async () => {
                    await handleSwitchOrg(org.orgID);
                  },
                  ...(isActive && { isactive: "true" }),
                }}
                onItemHover={disableEvents}
              >
                <MenuItemContent>{org.orgName}</MenuItemContent>
              </MenuItemStyled>
            );
          })
        ) : (
          <MenuItemStyled
            key="org-not-found"
            {...props}
            onItemHover={disableEvents}
            notfound="true"
          >
            <MenuItemContent>Organizations not found</MenuItemContent>
          </MenuItemStyled>
        )}
      </>
    );
  };

  const renderMenu = () => (
    <MenuStyled {...{ mode: "vertical" }}>
      <RenderMenuContent />
    </MenuStyled>
  );

  if (userOrganizations.length <= 1) {
    return <Wrapper {...{ isMobile }}>{currentOrg.name}</Wrapper>;
  }

  return (
    <Wrapper {...{ isMobile }}>
      <StyledDropdown
        {...{
          overlay: renderMenu(),
          placement: "bottomRight",
          getPopupContainer: () => document.getElementById(WRAPPER_ID)!,
          visible: isOpen || isLoading,
          onVisibleChange: setIsOpen,
        }}
      >
        <span>
          <CurrentOrg {...{ id: WRAPPER_ID }}>
            {currentOrg.name} <DropdownArrow />
          </CurrentOrg>
        </span>
      </StyledDropdown>
    </Wrapper>
  );
};

export default withRouter(withAuthUser(OrganizationSwitch));
