import { createContext, useCallback, useContext, useMemo, type ReactNode } from "react";
import { noop } from "lodash";

import type { IAuthUser } from "../features/UserInfoPage/types";
import AuthUserContext from "./contexts/AuthUserContext";
import type { SideNavItem } from "./sideNavMenu";
import usePersistentStorage from "./usePersistentStorage";

type SideNavContextProviderProps = {
  children: ReactNode;
  sideNavItem: SideNavItem;
};

export const ToggleSideNavCollapsedContext = createContext<() => void>(noop);
export const SideNavCollapsedContext = createContext<boolean>(false);

export const SideNavCollapseContextProvider = ({
  sideNavItem,
  children,
}: SideNavContextProviderProps) => {
  const authUser = useContext<IAuthUser>(AuthUserContext);
  const [collapsed, setCollapsed] = usePersistentStorage("siderCollapsed", false);

  const collapsable = useMemo(() => {
    if (!sideNavItem) {
      return false;
    }

    if (typeof sideNavItem.collapsable === "function") {
      return sideNavItem.collapsable(authUser.toggles);
    }

    return sideNavItem.collapsable ?? false;
  }, [authUser.toggles, sideNavItem]);

  const handleToggle = useCallback(() => {
    setCollapsed((prevCollapsed) => !prevCollapsed);
  }, [setCollapsed]);

  return (
    <SideNavCollapsedContext.Provider {...{ value: collapsed && collapsable }}>
      <ToggleSideNavCollapsedContext.Provider {...{ value: handleToggle }}>
        {children}
      </ToggleSideNavCollapsedContext.Provider>
    </SideNavCollapsedContext.Provider>
  );
};

export const useSideNavCollapsed = () => useContext(SideNavCollapsedContext);

export const useToggleSideNavCollapsed = () => useContext(ToggleSideNavCollapsedContext);
