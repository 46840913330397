import { forwardRef, type ButtonHTMLAttributes, type MouseEvent } from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "../../lib/utils";
import * as Icons from "../Icon/icons";
import { Text } from "../Text";

type IconPosition = NonNullable<VariantProps<typeof buttonVariants>["icon"]>;
export type ButtonVariant = NonNullable<VariantProps<typeof buttonVariants>["variant"]>;

const baseStyles =
  "border-transparent focus:ring-2 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 inline-flex items-center justify-center whitespace-nowrap";

const buttonVariants = cva(baseStyles, {
  variants: {
    variant: {
      primary: "bg-primary-500 text-text-50 hover:bg-primary-550 focus:ring-primary-500",
      secondary: "bg-neutrals-200 text-primary-500 hover:bg-neutrals-250 focus:ring-primary-500",
      ghost:
        "bg-transparent text-primary-500 hover:bg-neutrals-200 focus:ring-primary-500 disabled:bg-transparent",
      link: "border-0 bg-transparent text-primary-400 hover:bg-transparent hover:underline focus:ring-primary-400 focus:ring-offset-0",
      destructive: "bg-neutrals-200 text-danger-600 hover:bg-danger-350 focus:ring-danger-500",
      subtle:
        "border-transparent  bg-neutrals-500 text-neutral-50 hover:bg-neutrals-600 focus:ring-primary-500",
    },
    size: {
      large: "h-10 px-4 py-2",
      medium: "h-8 px-4 py-2",
      small: "h-4 px-3 py-1",
    },
    shape: {
      rectangular: "rounded-[0.25rem]",
      pill: "rounded-[6.25rem]",
      square: "rounded-[0.25rem]",
      circle: "rounded-full",
    },
    icon: {
      left: "flex-row space-x-2",
      right: "flex-row space-x-2",
      only: "justify-center p-0",
      both: "w-[12.5rem] flex-row justify-between space-x-2",
    },
    loading: {
      true: "cursor-not-allowed opacity-70",
      false: "",
    },
    iconOnlySize: {
      large: "size-10",
      medium: "size-8",
      small: "size-5",
    },
  },
  compoundVariants: [
    { icon: "only", size: "large", class: "size-10" },
    { icon: "only", size: "medium", class: "size-8" },
    { icon: "only", size: "small", class: "size-5" },
    { size: ["small", "medium", "large"], variant: "link", class: "m-1 px-0" },
  ],
  defaultVariants: {
    variant: "primary",
    size: "medium",
    shape: "rectangular",
    loading: false,
  },
});

export type ButtonProps = {
  asChild?: boolean;
  disabled?: boolean;
  iconName?: keyof typeof Icons;
  iconNameRight?: keyof typeof Icons;
  iconPosition?: IconPosition;
  isLoading?: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void | Promise<void>;
  text?: string;
} & ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonVariants>;

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant,
      size,
      shape,
      iconName,
      iconNameRight,
      iconPosition,
      isLoading,
      text,
      asChild = false,
      disabled = false,
      onClick,
      ...props
    },

    ref,
  ) => {
    const Comp = asChild ? Slot : "button";
    // eslint-disable-next-line import/namespace
    const LucideIconLeft = iconName ? Icons[iconName] : null;
    // eslint-disable-next-line import/namespace
    const LucideIconRight = iconNameRight ? Icons[iconNameRight] : null;
    const LoaderIcon = Icons.LoaderCircle;
    const iconSize = size === "small" ? 12 : 18;

    return (
      <Comp
        className={cn(
          buttonVariants({
            variant,
            size,
            shape,
            icon: iconPosition,
            loading: isLoading,
          }),
          text && "min-w-[5rem]",
        )}
        ref={ref}
        disabled={isLoading || disabled}
        onClick={onClick}
        {...props}
      >
        {isLoading ? (
          <LoaderIcon className="mr-2 animate-spin fill-none text-inherit" size={18} />
        ) : (
          iconPosition === "left" &&
          LucideIconLeft && <LucideIconLeft className="fill-none text-inherit" size={18} />
        )}
        {iconPosition === "both" ? (
          <>
            <div className="flex items-center space-x-2">
              {LucideIconLeft && <LucideIconLeft className="fill-none text-inherit" size={18} />}
              <Text ellipsis className={cn(iconName ? "max-w-[7rem]" : "max-w-[8.5rem]")}>
                {text}
              </Text>
            </div>
            {LucideIconRight && (
              <LucideIconRight className="ml-2 fill-none text-inherit" size={18} />
            )}
          </>
        ) : (
          <>
            {text && <Text className="my-0">{text}</Text>}
            {iconPosition === "right" && LucideIconRight && (
              <LucideIconRight className="ml-2 fill-none text-inherit" size={18} />
            )}
          </>
        )}
        {iconPosition === "only" && LucideIconLeft && (
          <LucideIconLeft className="fill-none text-inherit" size={iconSize} />
        )}
      </Comp>
    );
  },
);

export { Button, buttonVariants };
