import SimpleBar from "simplebar-react";
import styled, { css } from "styled-components";

import { Heading } from "@core/ui-legacy";
import Colors from "@core/ui-legacy/themes/colors";

import { ReactComponent as DoubleLeftIcon } from "../../assets/imgs/double-left-icon.svg";
import { SIDER_COLLAPSED_WIDTH_PX } from "../../layouts/DashboardLayout";
import { totalHeight } from "../TopNav";

export const HeadingStyled = styled(Heading)`
  padding: 0 1.5rem 0 2.5rem;
  margin-bottom: 1rem;
  white-space: nowrap;
  overflow: hidden;
  border-left: 0.25rem solid transparent;
`;

export const CollapseContainer = styled.div<{ $collapsed: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ $collapsed }) => ($collapsed ? "center" : "flex-end")};
  cursor: pointer;
  height: 2.8125rem;
  padding: ${({ $collapsed }) => ($collapsed ? "0.75rem 0" : "0.75rem")};
  width: ${({ $collapsed }) => $collapsed && "3.125rem"};
`;

export const DoubleLeftIconStyled = styled(DoubleLeftIcon)<{
  $collapsed: boolean;
}>`
  color: ${Colors.bg_gray};
  transition: all 0.35s;

  ${({ $collapsed }) =>
    $collapsed &&
    css`
      transform: rotate(180deg);
    `}
`;

export const Container = styled.div<{
  $collapsable?: boolean;
  $collapsed?: boolean;
  $overlayDropdown?: boolean;
}>`
  width: ${({ $collapsed }) => ($collapsed ? `${SIDER_COLLAPSED_WIDTH_PX}px` : "15.625rem")};
  position: fixed;
  z-index: ${({ $overlayDropdown }) => ($overlayDropdown ? 2 : 1)};
  height: ${totalHeight};
  overflow: visible;

  .ant-dropdown {
    z-index: 2;

    .ant-dropdown-menu {
      max-height: 33vh;
      overflow: auto;
      border-radius: 4px;
      border: 1px solid ${Colors.fog_gray};
      box-shadow: 0 0.125rem 0.625rem 0 rgb(0 0 0 / 10%);
      padding: 0.75rem 0;

      .ant-dropdown-menu-item {
        padding: 0.5rem 1rem;
      }

      .ant-dropdown-menu-item:hover {
        font-weight: 600;
        background-color: ${Colors.sky};
      }
    }
  }
`;

export const SimpleBarStyled = styled(SimpleBar)<{
  $collapsable?: boolean;
  $collapsed?: boolean;
}>`
  overflow-x: hidden;
  width: ${({ $collapsed }) => ($collapsed ? `${SIDER_COLLAPSED_WIDTH_PX}px` : "15.625rem")};
  ${({ $collapsable }) =>
    !$collapsable &&
    css`
      padding: 2.5rem 0;
    `}
  max-height: ${totalHeight}
`;
