import { useContext, useEffect } from "react";
import PropTypes from "prop-types";

import { Affix } from "@core/ui-legacy";

import { ReactComponent as HelpButtonImage } from "../../assets/imgs/help-button.svg";
import AuthUserContext from "../../common/contexts/AuthUserContext";
import { isUserInRoles, UserRoles } from "../../common/roles";
import { userHasFeatures } from "../../common/userHasFeatures";
import FeatureFlags from "../../utils/featureFlags";
import { CONTACT_SUPPORT } from "../../utils/routes";

const loadChatbotScript = ({ orgID, developerName, siteURL, scrtURL }, userID, userRoles) => {
  const isTravel = isUserInRoles(userRoles, [UserRoles.Traveler]);

  const chatbotScript = document.createElement("script");
  chatbotScript.id = "chatbot-script";
  chatbotScript.type = "text/javascript";
  chatbotScript.src = `${siteURL}/assets/js/bootstrap.min.js`;
  chatbotScript.onload = () => {
    try {
      const bootstrap = window.embeddedservice_bootstrap;
      if (!bootstrap) {
        console.error("Could not find embeddedservice_bootstrap");

        return;
      }

      bootstrap.settings.language = "en";
      window.addEventListener("onEmbeddedMessagingReady", () => {
        bootstrap.prechatAPI.setHiddenPrechatFields({
          Travel: isTravel ? "true" : "false",
          CenterUserID: userID,
        });
      });
      bootstrap.init(orgID, developerName, siteURL, {
        scrt2URL: scrtURL,
      });
    } catch (err) {
      console.error("Error loading Embedded Messaging:", err);
    }
  };

  document.body.appendChild(chatbotScript);
};

const HelpButton = ({ isHeightFixed }) => {
  const {
    ID,
    toggles,
    roles,
    organization: { chatbotConfig },
  } = useContext(AuthUserContext);

  const chatbotEnabled =
    !import.meta.env.DEV && chatbotConfig && userHasFeatures(toggles, [FeatureFlags.CHATBOT]);

  useEffect(() => {
    const chatbotScriptExists = !!document.querySelector("#chatbot-script");
    if (chatbotEnabled && !chatbotScriptExists) {
      loadChatbotScript(chatbotConfig, ID, roles);
    }
  }, [ID, chatbotConfig, chatbotEnabled, roles]);
  const marginTopAdded = chatbotEnabled ? "5.625rem" : "4.75rem";
  const marginTop = isHeightFixed ? "0" : marginTopAdded;

  if (chatbotEnabled) {
    return (
      <div
        style={{
          marginTop,
        }}
      />
    );
  }

  return (
    <>
      <div
        style={{
          marginTop,
        }}
      />
      <Affix
        style={{
          position: "fixed",
          bottom: "0.31rem",
          right: "0.625rem",
          zIndex: 999,
        }}
      >
        <a href={CONTACT_SUPPORT} target="_blank" rel="noopener noreferrer">
          <HelpButtonImage role="img" aria-label="Help button" />
        </a>
      </Affix>
    </>
  );
};

HelpButton.propTypes = {
  isHeightFixed: PropTypes.bool,
};

export default HelpButton;
