import Colors from "@core/ui-legacy/themes/colors";

import { FIELD_UI_TYPES, HARDCODED_FIELD_UI_TYPES } from "../../utils/constants";
import { parseIfJSON } from "../../utils/rules";

const nameMap = {
  "OOP Transaction Details": "Transaction Details",
  "Trip Details": "Travel Details",
};

const optionKeyMap = {
  expenseTypeID: "ID",
  costCenterID: "ID",
};

const readPropertyMap = {
  costCenterID: "costCenter",
  expenseTypeID: "expenseType",
  "merchant.name": "merchant",
};

const styleMap = {
  receipt: "display: none !important;",
  "fields.forex.originatingCurrency.amountRaw": `
    vertical-align: top;
    width: 40% !important;
    max-width: 12rem;
  `,
  "fields.forex.originatingCurrency.name": `
    vertical-align: top;
    width: 60% !important;
    max-width: 15rem;
  `,
  "fields.travel.travelerID": `
    border-bottom: 1px solid ${Colors.lunar_gray};
    margin-bottom: 1rem;
    padding-bottom: 1rem;
  `,
};

const uiTypeMap = {
  "customFields.distance": FIELD_UI_TYPES.inputDistance,
  "fields.mileage.distance": FIELD_UI_TYPES.inputDistance,
  "customFields.isRoundTrip": FIELD_UI_TYPES.switch,
  "fields.mileage.isRoundTrip": FIELD_UI_TYPES.switch,

  attendees: HARDCODED_FIELD_UI_TYPES.attendees,
  "fields.forex.originatingCurrency.name": HARDCODED_FIELD_UI_TYPES.currencyName,
  "customFields.distanceMode": HARDCODED_FIELD_UI_TYPES.distanceMode,
  "fields.mileage.distanceMode": HARDCODED_FIELD_UI_TYPES.distanceMode,
  "fields.travel.travelerID": HARDCODED_FIELD_UI_TYPES.traveler,
  "fields.travel.tripID": HARDCODED_FIELD_UI_TYPES.trip,
  "fields.travel.isTrip": HARDCODED_FIELD_UI_TYPES.istrip,
  "merchant.name": HARDCODED_FIELD_UI_TYPES.merchant,
  purpose: HARDCODED_FIELD_UI_TYPES.purpose,
  receipt: HARDCODED_FIELD_UI_TYPES.receipt,
};

export const evaluatedExpenseFormResolverBase = {
  fields: (form) =>
    form.fields?.map((field) => ({
      ...field,
      expenseID: form.expenseID,
    })),
};

export const evaluatedExpenseFormFieldResolverBase = {
  name: (field) => nameMap[field._name] ?? field._name,
  optionKey: (field) => optionKeyMap[field.property] ?? "name",
  readProperty: (field) => readPropertyMap[field.property] ?? field.property,
  style: (field) => styleMap[field.property] ?? "",
  uiType: ({ property, _uiType, type, _name }) => {
    if (type === "Message") {
      return FIELD_UI_TYPES.message;
    }
    // name is probably not right property, especially if editable
    // TODO: add uiType and/or property to booking section inside Form definition on cloud
    if (type === "Section" && _name === "Transaction Details") {
      return HARDCODED_FIELD_UI_TYPES.forexSection;
    }
    if (type === "Section" && _name === "Booking") {
      return HARDCODED_FIELD_UI_TYPES.bookingSection;
    }

    const defaultUIType = type === "Section" ? FIELD_UI_TYPES.section : FIELD_UI_TYPES.inputText;

    return uiTypeMap[property] ?? _uiType ?? defaultUIType;
  },
  values: (field) =>
    typeof field?._values === "string"
      ? JSON.parse(field?._values).map((value) => ({
          ID: value.name,
          ...value,
        }))
      : field?._values,
  fields: (form) =>
    form.fields?.map((field) => ({
      ...field,
      formID: form.ID,
      expenseID: form.expenseID,
    })),
  defaultValue: ({ _defaultValue }) => parseIfJSON(_defaultValue),
};
