import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { Empty, Icon } from "@core/ui-legacy";
import Colors from "@core/ui-legacy/themes/colors";

import AuthUserContext from "../../common/contexts/AuthUserContext";
import type { IAuthUser } from "../../features/UserInfoPage/types";
import {
  getEmployeesToDeactivate,
  getEmployeesToDeactivateQueryKey,
  getEmployeesToOnboard,
  getEmployeesToOnboardQueryKey,
  getOrgConfig,
  orgConfigQueryKey,
  useHasHRIS,
} from "../../services/rest/hris";
import { HRIS, USERS } from "../../utils/routes";

const IconWrapper = styled.div`
  background-color: #b9c4d3;
  border-radius: 999px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  width: 2rem;
`;

const AlertWrapper = styled(Link)`
  display: grid;
  grid-template-columns: auto 1fr;
  color: ${Colors.midnight};
  column-gap: 1rem;
  padding: 0.75rem 0;
  border-bottom: 1px solid ${Colors.splits_gray};
`;

const StyledEmpty = styled(Empty)`
  background: ${Colors.bg_gray};
  border-radius: 0.5rem;
  padding: 1.5rem;
`;

const P = styled.p`
  font-size: 1rem;
  font-style: normal;
  font-weight: 350;
  line-height: 1.5;
  margin-bottom: 0;
`;

type HRISAlertsProps = {
  onClose: () => void;
};

export const HRISAlerts = ({ onClose }: HRISAlertsProps) => {
  const { organization } = useContext<IAuthUser>(AuthUserContext);
  const orgId = organization?.ID;
  const hasHRIS = useHasHRIS();
  const { data: orgConfig } = useQuery({
    queryKey: orgConfigQueryKey({ orgId }),
    enabled: hasHRIS,
    queryFn: getOrgConfig,
  });

  const { data: onboardUsers } = useQuery({
    queryKey: getEmployeesToOnboardQueryKey({ orgId }),
    enabled: hasHRIS,
    queryFn: getEmployeesToOnboard,
  });

  const { data: deactivateUsers } = useQuery({
    queryKey: getEmployeesToDeactivateQueryKey({ orgId }),
    enabled: hasHRIS,
    queryFn: getEmployeesToDeactivate,
  });

  const count = (onboardUsers?.length ?? 0) + (deactivateUsers?.length ?? 0);

  const isDisconnected = orgConfig?.status === "RELINK_NEEDED";

  if (!count && !isDisconnected) {
    return <StyledEmpty description="No team updates" />;
  }

  return (
    <>
      {isDisconnected && (
        <AlertWrapper to={{ pathname: HRIS }}>
          <IconWrapper>
            <Icon type="team" />
          </IconWrapper>
          <P>
            {orgConfig?.integrationName} is disconnected. Please re-authenticate to continue using
            this integration.
          </P>
        </AlertWrapper>
      )}
      <AlertWrapper to={{ pathname: USERS, state: { openHRISDrawer: true } }} onClick={onClose}>
        <IconWrapper>
          <Icon type="team" />
        </IconWrapper>
        <P>
          You have {count} team updates from {orgConfig?.integrationName} pending your review.
        </P>
      </AlertWrapper>
    </>
  );
};
